import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import { Column } from 'react-table';
import queryStringParser from 'query-string';
import Config from 'config';
import type Misc from 'types/misc';
import type { ContactSummary } from 'types/models';
import type { FetchAllParams } from 'api/contacts';
import apiContacts from 'api/contacts';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import organizationStore from 'stores/Organization';
import userRightsStore from 'stores/UserRights';
import authStore from 'stores/Auth';
import contactsFiltersStore from 'stores/FilterStores/ContactsFilters';
import useContextualTranslation from 'hooks/useContextualTranslation';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import LinkSwitch from 'components/LinkSwitch';
import ListContactsView from 'components/ListContacts/View';
import { checkIsAllowed } from 'components/ExternallyMonitored';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import { RightsRole } from 'types/models';
import ContactEditPage, { RouteParams } from '../ContactEdit';
import ContactsFilters from './Filters';
import ContactsColumns from './Columns';
import BulkActions from './Actions/Bulk';

const Contacts = (): JSX.Element => {
  const history = useHistory();
  const { editType } = useParams<RouteParams>();
  const { search } = useLocation();
  const { customerId, contactId } = queryStringParser.parse(search);
  const [contactViewId, setContactViewId] = useState<number | null>(() => (
    parseInt(((!Array.isArray(contactId) && contactId) || '0'), 10) || null
  ));

  const { currentOrganization, selectedBusinessUnitsIdentifier, isExternallyMonitored } = organizationStore;
  const { type, contactRoles } = currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const { rights } = userRightsStore;
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);
  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes(RightsRole.ROLE_SUPER_ADMINISTRATOR)));

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<ContactSummary>>(
    defaultFetchOptions,
  );

  const {
    filters,
    resetAllFilters,
    addOrUpdateFilters,
    removeFilter,
  } = contactsFiltersStore;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: selectedBusinessUnitsIdentifier }];
  }, [filters, selectedBusinessUnitsIdentifier]);

  const {
    status: fetchStatus,
    data,
    serverPagination,
    error,
    isFetching,
    refetch,
  } = useFetchPaginated<FetchAllParams, ContactSummary>(
    {
      cacheKey: 'contacts',
      organizationId: currentOrganization?.id,
      params: {
        page: fetchOptions.pageIndex,
        organizationReference: currentOrganization?.reference,
        pageSize: fetchOptions.pageSize,
        locale: user?.locale,
        filtering,
        sort: fetchOptions.sort,
      },
    },
    apiContacts.all,
    { enabled: !!currentOrganization },
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback(
    (message: string) => {
      showToast(message, 'success');
      refetch();
    },
    [showToast, refetch],
  );

  const handleActionError = useCallback(
    (message: string) => { showToast(message, 'error'); },
    [showToast],
  );

  const columns = useMemo<Column<ContactSummary>[]>(
    () => ContactsColumns(ct, handleActionDone, handleActionError, setContactViewId),
    [ct, handleActionDone, handleActionError],
  );

  const filtersList = useMemo<Misc.FilterDeclarationItem[]>(
    () => ContactsFilters(t, ct, contactRoles, customerId ? `${customerId}` : undefined),
    [t, ct, contactRoles, customerId],
  );

  // FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle.
  /*const exportLink = useMemo<string>(
    () => ExportLink(fetchOptions, filters, currentOrganization?.reference || null, true),
    [currentOrganization, fetchOptions, filters],
  );*/

  const handleSwitchList = useCallback(
    (listing: string) => {
      history.push(`/${listing}?${getFiltersQueryString(filters)}}`);
    },
    [filters, history],
  );

  const switchListing = (
    editType ? null : (
      <LinkSwitch
        valueLabels={[
          { value: 'customers', label: ct('common:customers') },
          { value: 'contacts', label: t('common:contacts') },
        ]}
        defaultValue="contacts"
        onChange={handleSwitchList}
      />
    )
  );

  const [showModal, hideModal] = useModal(() => {
    return contactViewId ? (
      <ListContactsView
        contactId={contactViewId}
        onClose={hideModal}
        onActionDone={handleActionDone}
        onActionError={handleActionError}
      />
    ) : null;
  }, [contactViewId, handleActionDone, handleActionError]);

  useEffect(() => {
    if (contactViewId) {
      showModal();
    }
  }, [contactViewId, showModal]);

  const totalResults = useMemo(() => (
    filters.length > 0 ? serverPagination?.totalRecords : null
  ), [filters, serverPagination]);

  return (
    <>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
      <PageList
        className="Contacts"
        count={totalResults}
        isFetching={isFetching}
        title={t('contacts:contacts')}
        actions={switchListing}
      >
        <DataTable<ContactSummary>
          columns={columns}
          data={data}
          serverPagination={serverPagination}
          filtersList={filtersList}
          fetchData={setFetchOptions}
          filtering={filters}
          onChangeFilters={addOrUpdateFilters}
          onRemoveFilter={removeFilter}
          isLoading={isFetching && fetchStatus === 'loading'}
          error={error}
          noDataFoundMessage={t<string>('contacts:no-data-customer')}
          withNoDataDrawing
          bulkGender="m"
          withActions={
            rights !== null && checkIsAllowed(false, isExternallyMonitored, isSuperAdmin)
          }
          bulkActions={(
            <BulkActions
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          )}
        />
        {editType && (
          <ContactEditPage
            onDone={handleActionDone}
            finallyRedirectTo="/contacts"
          />
        )}
      </PageList>
    </>
  );
};

export default observer(Contacts);
