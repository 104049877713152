import './index.scss';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import TooltipContext, { TooltipData } from './context';

type Props = {
  children: ReactNode,
};

const defaultData = {
  show: false,
  x: 0,
  y: 0,
  isCentered: true,
};

const TooltipProvider = ({ children }: Props): JSX.Element => {
  const [tooltipData, setTooltipData] = useState<TooltipData>(defaultData);
  const [content, setContent] = useState<JSX.Element | null>(null);

  const showTooltip = useCallback(
    (x: number, y: number, newContent: JSX.Element | null, isCentered: boolean = true) => {
      setTooltipData({
        show: true,
        x,
        y,
        isCentered,
      });
      setContent(newContent);
    },
    [],
  );

  const hideTooltip = useCallback(() => {
    setTooltipData(defaultData);
    setContent(null);
  }, []);

  const contextValue = useMemo(
    () => ({ showTooltip, hideTooltip }),
    [showTooltip, hideTooltip],
  );

  const {
    show,
    x,
    y,
    isCentered,
  } = tooltipData;

  const classNames = useMemo(() => (
    classnames('Tooltip', { 'Tooltip--centered': isCentered })
  ), [isCentered]);

  return (
    <TooltipContext.Provider value={contextValue}>
      {children}
      {show && ReactDOM.createPortal(
        <div className={classNames} style={{ left: x, top: y }}>
          {content}
        </div>,
        document.body,
      )}
    </TooltipContext.Provider>
  );
};

export default TooltipProvider;
