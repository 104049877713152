import type Misc from 'types/misc';

const getFiltersQueryString = (
  filters: Misc.Filter[],
  allowedNames: string[] = ['search'],
): string => (
  filters
    .filter(({ name }) => allowedNames.includes(name))
    .map(({ name, value }) => `${name}=${value}`)
    .join('&')
);

export default getFiltersQueryString;
