import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import snakeCase from 'lodash/snakeCase';
import userRightsStore from 'stores/UserRights';
import type { DataBlock, TaskBlock } from 'types/models';
import type { ValidationErrors } from 'types/errors';
import ModalForm from 'components/ModalForm';
import type { ModalFormData } from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormGroupWithActions from 'components/FormGroupWithActions';
import { checkIsAllowed } from 'components/UserRights';
import FormControl from 'components/FormControl';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import Confirm from 'components/Confirm';
import ErrorMessage from 'components/ErrorMessage';
import TagLink from 'components/TagLink';
import apiTemplateBlocks from 'api/template-blocks';
import { RightsCategory } from 'types/models';

type Props = {
  listData?: DataBlock[],
  defaultData?: DataBlock,
  indexData?: number,
  id?: number,
  edit?: boolean,
  onDone(message: string): void,
  onError(message: string): void,
  onClose(): void,
};

const ModalTask = (props: Props): JSX.Element => {
  const { onClose, onError, defaultData, listData, edit = false, onDone, indexData, id } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const { rights } = userRightsStore;

  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const { put, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const mapFormData = useCallback(
    (rawData: ModalFormData): TaskBlock => ({
      name: rawData.name as string,
      description: rawData.description as string,
      identifier: snakeCase(rawData.name as string),
    }),
    [],
  );

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization || !id || !listData || indexData === undefined) {
        return;
      }

      const data = mapFormData(formData);
      const payloadListData = apiTemplateBlocks.buildPayloadListData(listData, indexData, edit ? 'edit' : 'create', data);
      const result = await put(apiTemplateBlocks.updateUrl(id), { tasks: payloadListData });

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }
      closeSelf();
      if (edit) {
        onDone(t('models:toast.success.edit-model', { name: formData?.name }));
        return;
      }
      onDone(t('models:toast.success.create-model', { name: formData?.name }));
    },
    [closeSelf, currentOrganization, edit, id, indexData, isMountedRef, listData, mapFormData, onDone, put, t],
  );

  const handleDelete = useCallback(async () => {
    setShowDeleteConfirm(false);
    if (!currentOrganization || !id || !listData || indexData === undefined) {
      return;
    }

    const payloadListData = apiTemplateBlocks.buildPayloadListData(listData, indexData, 'delete');
    const response = await put(apiTemplateBlocks.updateUrl(id), { tasks: payloadListData });
    if (isMountedRef.current && response) {
      onDone(t('models:toast.success.delete-model', { name: defaultData?.name }));
      return;
    }
    onError(t('models:toast.error.delete-model', { name: defaultData?.name }));
  }, [currentOrganization, defaultData?.name, id, indexData, isMountedRef, listData, onDone, onError, put, t]);

  return (
    <ModalForm
      isOpened
      onSave={handleSubmit}
      onCancel={closeSelf}
      title={t('models:modal-task-title')}
      hasDeleteButton={edit}
      buttonsDisabled={rights === null || !checkIsAllowed('UPDATE', RightsCategory.PREFERENCES_MODELS)}
      onDelete={() => { setShowDeleteConfirm(true); }}
    >
      {error && (<ErrorMessage error={error} />)}
      <FormFieldset>
        <FormGroup label={t('models:short-description')} mandatory>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            placeholder={t('models:name-placeholder')}
            defaultValue={defaultData?.name ?? ''}
            isInvalid={!!validationErrors?.name}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroupWithActions
          label={t('models:task-content')}
          action={<TagLink />}
        >
          <FormControl
            type="textarea"
            name="description"
            autoComplete="off"
            defaultValue={defaultData?.description ?? ''}
            isInvalid={!!validationErrors?.description}
          />
        </FormGroupWithActions>
      </FormFieldset>
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
      />
      <Confirm
        titleModal={t('common:remove')}
        text={t('models:confirm-delete')}
        variant="danger"
        confirmButtonText={t('common:remove')}
        cancelButtonText={t('common:cancel')}
        entityName={defaultData?.name}
        isShow={showDeleteConfirm}
        onConfirm={handleDelete}
        onCancel={() => { setShowDeleteConfirm(false); }}
      />
    </ModalForm>
  );
};

export default observer(ModalTask);
