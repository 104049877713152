import './index.scss';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import type { Client, DataBlock } from 'types/models';
import { Channel } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormDatePicker from 'components/FormDatePicker';
import Wysiwyg from 'components/Wysiwyg';
import FormSelect from 'components/FormSelect';
import FormGroupWithActions from 'components/FormGroupWithActions';
import organizationStore from 'stores/Organization';
import getI18nChannel from 'utils/getI18nChannel';
import FormControlSMSContent from 'components/FormControlSMSContent';
import ScenarioGroupDropdown from 'components/ScenarioGroupDropdown';
import FormCheck from 'components/FormCheck';
import TagLink from 'components/TagLink';

type Props = {
  channel: Channel,
  customerId?: Client['id'] | null,
  errors?: ValidationErrors | null,
  template?: DataBlock,
  immediateDispatch: boolean,
};

const CreateReminderEditForm = (props: Props): JSX.Element => {
  const { channel, errors, template, customerId, immediateDispatch } = props;
  const { t } = useTranslation();
  const { contactRoles } = organizationStore.currentOrganization!;
  const [name, setName] = useState<string>(template?.name || '');
  const [description, setDescription] = useState<string>(template?.description || '');
  const [subject, setSubject] = useState<string>(template?.subject || '');
  const [content, setContent] = useState<string>(template?.content || '');

  useEffect(() => {
    setName(template?.name || '');
    setDescription(template?.description || '');
    setSubject(template?.subject || '');
    setContent(template?.content || '');
  }, [template]);

  return (
    <div className="CreateReminderEditForm">
      <div className="CreateReminderEditForm__main">
        <FormFieldset>
          <FormGroup
            label={t('reminders:sending-date')}
            className="CreateReminderEditForm__date"
            mandatory
            error={errors?.startAt}
          >
            <FormDatePicker
              name="startAt"
              minDate={new Date()}
              isInvalid={!!errors?.startAt}
            />
          </FormGroup>
          <FormGroup label={t('reminders:short-description')} error={errors?.name} mandatory>
            <FormControl
              type="text"
              name="name"
              autoComplete="off"
              placeholder={t<string>('reminders:placeholder.short-description')}
              isInvalid={!!errors?.name}
              value={name}
              onChange={setName}
            />
          </FormGroup>
        </FormFieldset>
        <FormFieldset>
          <FormGroup label={t('reminders:description')} error={errors?.description}>
            <FormControl
              type="text"
              name="description"
              autoComplete="off"
              placeholder={t<string>('reminders:placeholder.description', { channel: t(getI18nChannel(channel)) })}
              isInvalid={!!errors?.description}
              value={description}
              onChange={setDescription}
            />
          </FormGroup>
        </FormFieldset>
        <div className="CreateReminderEditForm__fieldset">
          {!immediateDispatch && (
            <Fragment>
              <FormGroup
                className="CreateReminderEditForm__need-validation"
                label={t('reminders:need-validation')}
                error={errors?.withValidation}
              >
                <FormCheck name="withValidation" value="1"/>
              </FormGroup>
              <FormGroup
                className="CreateReminderEditForm__static"
                label={t('reminders:is-static')}
                error={errors?.static}
              >
                <FormCheck name="static" value="1"/>
              </FormGroup>
            </Fragment>
          )}
          <ScenarioGroupDropdown customerId={customerId} />
        </div>
      </div>
      {contactRoles && (
        <FormFieldset>
          <FormGroup label={t('reminders:recipients')} mandatory error={errors?.roles}>
            <FormSelect
              name="roles"
              placeholder={t('common:please-choose')}
              selectOptions={contactRoles?.map(
                ({ identifier, value }) => ({ label: value, value: identifier }),
              )}
              isInvalid={!!errors?.roles}
              isMultiple
            />
          </FormGroup>
        </FormFieldset>
      )}
      {channel === Channel.EMAIL && (
        <FormFieldset>
          <FormGroupWithActions
            error={errors?.subject}
            label={t<string>('reminders:email-subject')}
          >
            <FormControl
              type="text"
              name="subject"
              autoComplete="off"
              isInvalid={!!errors?.subject}
              value={subject}
              onChange={setSubject}
              placeholder={t<string>('reminders:placeholder.subject')}
            />
          </FormGroupWithActions>
        </FormFieldset>
      )}
      <FormFieldset>
        <FormGroupWithActions
          label={channel === Channel.EMAIL ? t<string>('reminders:email-content') : t<string>('reminders:content')}
          mandatory
          className="CreateReminderEditForm__content"
          action={<TagLink />}
          error={errors?.content}
        >
          {channel !== Channel.SMS && (
            <Wysiwyg
              name="content"
              defaultValue={template?.content || ''}
              replaceValue={template?.content}
              toBePrinted={channel !== Channel.EMAIL}
              onChange={setContent}
            />
          )}
          {channel === Channel.SMS && (
            <FormControlSMSContent
              name="content"
              value={content}
              onChange={setContent}
            />
          )}
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default observer(CreateReminderEditForm);
