import { runInAction, makeAutoObservable } from 'mobx';
import apiUsers from 'api/users';
import type { UserRights } from 'types/models';
import ResponseError from 'utils/errors';

const LOCAL_STORAGE_RIGHTS_KEY = 'userRights';

const initialRights: UserRights[] | null = JSON.parse(
  window.localStorage.getItem(LOCAL_STORAGE_RIGHTS_KEY) || 'null',
);

class UserRightsStore {
  rights: UserRights[] | null = initialRights;

  error: ResponseError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const userRights = await apiUsers.fetchRights();
      if (!userRights) {
        return;
      }

      window.localStorage.setItem(LOCAL_STORAGE_RIGHTS_KEY, JSON.stringify(userRights));

      runInAction(() => {
        this.rights = userRights;
      });
    } catch (error) {
      const { code, message, details } = error as ResponseError;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    }
  }
}

export default new UserRightsStore();
export { UserRightsStore };
