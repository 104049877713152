import type Misc from 'types/misc';
import type { ValidationErrors } from 'types/errors';
import type { Currency, CurrencyCode } from './Currency';
import type { PhaseId } from './Phase';
import type { NoteSearch, NoteSummary } from './Note';
import type { ClientIdentifiers } from './Client';
import type { Eav } from './EAV';
import type { Imputation } from './Imputation';
import type { ScenarioIdentifiers } from './Scenario';

export enum DebitStatus {
  // - Statut "en cours"
  IN_PROGRESS = 'in_progress',
  // - Statut "actif"
  ACTIVATE = 'activate',
  // - Statut "payé"
  PAID = 'paid',
  // - Statut "inactive"
  CANCEL = 'cancel',
  // - Statut "promesse"
  PROMISE = 'promise',
  // - Statut "contentieux"
  LITIGATED = 'litigated',
  // - Statut "échoué"
  FAILED = 'failed',
}

export enum DebitAction {
  MUTE = 'mute',
  UNMUTE = 'unmute',
  LITIGATE = 'litigated',
  CANCEL_LITIGATE = 'cancel-litigate',
  REMOVE = 'remove',
  DISABLE = 'disable',
  ACTIVATE = 'activate',
}

export enum DebitType {
  DEBIT = 'DEBIT',
  REJECT = 'REJECT',
  SETTLEMENT = 'SETTLEMENT',
}

export type DebitMuted = {
  at: string,
  userName: string,
};

export type DebitPlan = {
  name: string,
  identifier: string,
  startAt: string,
};

export type DebitPayments = {
  paymentAssignedAmount: number,
  creditReference: string,
  paymentPaidAt: string,
};

export type Debit = {
  id: number,
  reference: string,
  organization: number,
  plans: DebitPlan[],
  amount: number,
  initialCost: number,
  currency: CurrencyCode,
  issueAt: string,
  dueAt: string,
  createdAt: string,
  litigatedAt: string | null,
  paidAt: string | null,
  nextSending: string | null,
  lastSending: string | null,
  invoice: string | null,
  invoiceName: string | null,
  subject: string,
  isActive: boolean,
  muted: DebitMuted | null,
  status: DebitStatus,
  phase: PhaseId,
  client: ClientIdentifiers,
  token: string,
  user: Misc.IdName[] | null,
  note: NoteSummary | null,
  eav: Eav[] | null,
  imputations: Imputation[],
  payments?: DebitPayments[],
  type: DebitType,
  canMarkAsPaid: boolean,
  promiseToPayAt: string | null,
  isDownloadableFromS3: boolean,
  hasDunningAssignment: boolean,
};

export type DebitForList = {
  id: Debit['id'],
  reference: Debit['reference'],
  amount: Debit['amount'],
  initialCost: Debit['initialCost'],
  currency: Debit['currency'],
  status: Debit['status'],
  phase: Debit['phase'],
  plans: Debit['plans'],
  dueAt: Debit['dueAt'],
  nextSending: Debit['nextSending'],
  lastSending: Debit['nextSending'],
  invoiceName: Debit['invoiceName'],
  token: Debit['token'],
  client: ClientIdentifiers,
  muted: Debit['muted'],
  isActive: Debit['isActive'],
  note: Debit['note'],
  promiseToPayAt: Debit['promiseToPayAt'],
};

export type DebitDraft = {
  importId: string,
  importLogId: string,
  client?: string,
  clientId?: number,
  dueAt: string | null,
  amount?: number,
  currency: CurrencyCode,
  reference?: string,
  subject: string | null,
  destType: string | null,
  debours: string | null,
  invoice: string,
  eav: Eav[],
  errors: ValidationErrors,
};

export type DebitPost = {
  organization: string,
  client: string,
  createdBy: string,
  identifier: string,
  label: string,
  amount: string | null,
  currency: string,
  dueDate: string,
  issueDate: string | null,
  isActive: boolean,
  muted: boolean,
  invoice: string,
  eavs?: any,
  eavsForm?: Eav[],
  promiseToPayAt?: string,
};

export type DebitPut = { status: DebitStatus } | { muted: boolean };

export type DebitSummary = {
  id: Debit['id'],
  identifier: Debit['reference'],
  code: Debit['reference'],
  values: Debit['eav'],
  createdAt: Misc.DateTimezone,
  updatedAt: Misc.DateTimezone,
  status: Debit['status'],
  amount: Debit['amount'],
  initialCost: Debit['initialCost'],
  mutedAt: Misc.DateTimezone | null,
  enabled: boolean,
  phase: Debit['phase'],
  invoiceName: Debit['invoiceName'],
  invoiceFilePath: Debit['invoice'],
  dueDate: Misc.DateTimezone,
  promiseToPayDate: Misc.DateTimezone | null,
  dunningStepAction: Misc.DateTimezone | null,
  currency: Currency,
  note: NoteSearch | null,
  scenario: ScenarioIdentifiers | null,
  client: ClientIdentifiers,
  token: Debit['token'],
};

export type DebitSummaryStep = {
  id: number,
  identifier: string,
  dueAt: string,
  isActive: boolean,
};

export type DebitIdentifiers = {
  id: Debit['id'],
  identifier: Debit['reference'],
  label: string,
};
