import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import classnames from 'classnames';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import type { Client } from 'types/models';
import apiClients, { getCustomerFilter } from 'api/clients';
import apiNotifications from 'api/notifications';
import CreateReminder from 'components/CreateReminder';
import Misc from 'types/misc';
import FormCheck from 'components/FormCheck';
import CustomerCreateTaskForm from 'pages/Customer/Modals/CreateTask';
import { addJobToStorage, connectWebSocket, getRunningJob, JobExecution, removeJobFromStorage, RunningJob } from 'utils/webSocket';

type Props = {
  filters?: Misc.Filter[],
  totalRecords: number,
  pageSize: number,
  selection?: Client['id'][],
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const CustomersBulkActions = (props: Props): JSX.Element | null => {
  const { filters, totalRecords, pageSize, selection, onActionDone, onActionError } = props;
  const { currentOrganization, attributes } = organizationStore;
  const [action, setAction] = useState<'mute' | 'unmute' | 'remove'>();
  const [confirmVariant, setConfirmVariant] = useState<'primary' | 'danger'>();
  const [confirmButtonText, setConfirmButtonText] = useState<string>();
  const [runningJob, setRunningJob] = useState<RunningJob | null>(null);
  const { user } = authStore;
  const { type } = currentOrganization!;
  const [isAllSelected, setAllSelected] = useState<boolean>(false);
  const { error, isLoading, post } = useApiRequest();
  const { t } = useContextualTranslation(type);

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  useEffect(() => {
    if (error) {
      hideConfirm();
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const selectionCount = useMemo(() => {
    if (isAllSelected) {
      return totalRecords;
    }

    if (selection) {
      return selection?.length;
    }

    return 0;
  }, [totalRecords, isAllSelected, selection]);

  const selectionFilter = useMemo(() => {
    if (isAllSelected) {
      return filters;
    }

    if (selection) {
      return [{
        name: 'identifier',
        value : selection.map(String),
      }];
    }

    return [];
  }, [filters, isAllSelected, selection]);

  const handleConfirmDelete = useCallback(() => {
    setConfirmVariant('danger');
    setConfirmButtonText(t<string>('common:remove'));
    showConfirm(
      t('common:remove'),
      t('clients:actions.confirm.bulk.remove', { count: selectionCount }),
    );
    setAction('remove');
  }, [showConfirm, t, selectionCount]);

  const handleConfirmMute = useCallback((mute: boolean) => {
    setConfirmVariant(mute ? 'danger' : 'primary');
    setConfirmButtonText(t<string>(mute ? 'clients:set-mute' : 'common:validate'));
    showConfirm(
      t(`clients:${mute ? 'set-mute' : 'un-mute'}`),
      t(`clients:actions.confirm.bulk.${mute ? 'mute' : 'unmute'}`, { count: selectionCount }),
    );
    setAction(mute ? 'mute' : 'unmute');
  }, [showConfirm, t, selectionCount]);

  useEffect(() => {
    const handleNotifications = async () => {
      const { notifications } = await apiNotifications.fetchNotifications();

      const tasks = notifications
        .filter(({ actionType, viewed }) => actionType === 'mass_action' && !viewed)
        .map(async ({ message, type: notifType, id, url }) => {
          const jobId = url.replace(/^\D+/g, '');
          if (runningJob?.jobId === jobId ) {
            setRunningJob(null);
          }

          if (notifType === 'error') {
            onActionError(message);
          } else {
            onActionDone(message);
          }

          removeJobFromStorage(jobId);
          return apiNotifications.deleteNotification(id);
        });

      await Promise.all(tasks);
    };
    handleNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningJob]);

  useEffect(() => {
    let ws: WebSocket | null = null;

    const initiateWebSocket = async () => {
      try {
        const webSocket = await connectWebSocket(async (data) => {
          if (typeof data === 'object' && data.jobExecution) {
            setRunningJob({ jobId: String(data.jobExecution) });
          }
        });
        ws = webSocket;
      } catch (e:any) {
        throw new Error(e);
      }
    };

    initiateWebSocket();

    const getStorageJobs = async () => {
      const isRunningJob = getRunningJob();
      if (isRunningJob) {
        setRunningJob(isRunningJob);
      }
    };

    getStorageJobs();

    return () => {
      if (ws) {
        ws.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBulkAction = useCallback(async (
    response: JobExecution | undefined,
    actionType: 'delete' | 'mute' | 'unmute' | 'reminder' | 'task',
  ) => {
    const id = response?.jobExecutionId ? String(response.jobExecutionId) : null;
    if (id) {
      addJobToStorage(actionType, id);
      setRunningJob({ type: actionType, jobId: id });
    }
  }, []);

  const handleSubmitDelete = useCallback(async () => {
    const response = await post(
      apiClients.bulkUrl,
      {
        channel: currentOrganization?.reference,
        locale: user?.locale,
        filters: getCustomerFilter(attributes.client, selectionFilter),
        actionType: 'delete',
        actionData: null,
      },
    );

    hideConfirm();
    if (response) {
      handleBulkAction(response as JobExecution, 'delete');
    }
  }, [
    selectionFilter,
    hideConfirm,
    handleBulkAction,
    attributes.client,
    currentOrganization?.reference,
    user?.locale,
    post,
  ]);

  const handleSubmitMute = useCallback(async (mute: boolean) => {
    const response = await post(
      apiClients.bulkUrl,
      {
        channel: currentOrganization?.reference,
        locale: user?.locale,
        filters: getCustomerFilter(attributes.client, selectionFilter),
        actionType: 'mute',
        actionData: [{ field:'isMuted', value:mute }],
      },
    );

    hideConfirm();
    if (response) {
      handleBulkAction(response as JobExecution, mute ? 'mute' : 'unmute');
    }
  }, [
    selectionFilter,
    hideConfirm,
    handleBulkAction,
    attributes.client,
    currentOrganization?.reference,
    user?.locale,
    post,
  ]);

  const handleSubmit = useCallback(() => {
    if (action === 'mute') {
      handleSubmitMute(true);
      return;
    }

    if (action === 'unmute') {
      handleSubmitMute(false);
      return;
    }

    handleSubmitDelete();
  }, [action, handleSubmitDelete, handleSubmitMute]);

  const [handleCreateTask, hideCreateTask] = useModal(() => (
    <CustomerCreateTaskForm
      totalRecords={selectionCount}
      filters={selectionFilter}
      onClose={hideCreateTask}
      onDone={onActionDone}
      onResponse={(response: JobExecution) => {
        handleBulkAction(response, 'task');
      }}
    />
  ), [onActionDone, selectionFilter, selectionCount, handleBulkAction]);

  const [showCreateReminder, hideCreateReminder] = useModal(() => (
    <CreateReminder
      totalRecords={selectionCount}
      filters={selectionFilter}
      onDone={onActionDone}
      onClose={hideCreateReminder}
      onResponse={(response: JobExecution) => {
        handleBulkAction(response, 'reminder');
      }}
    />
  ), [onActionDone, selectionFilter, selectionCount, handleBulkAction]);

  if (!selection?.length && !runningJob) {
    return null;
  }

  const classNames = classnames('CustomersBulkActions', {
    'CustomersBulkActions--process': !!runningJob,
  });

  return (
    <Fragment>
      <div className={classNames}>
        {isLoading && !runningJob && <Loading text={t('common:saving')} isOverlay />}
        {runningJob && (
          <Loading
            text={t(`clients:actions.toast.bulk-processing.${runningJob.type ?? 'unknown'}`)}
          />
        )}
        {!runningJob &&
          <Fragment>
            <Button
              variant="outline-light"
              onClick={handleConfirmDelete}
              className="CustomersBulkActions__delete"
              disabled={isLoading}
            >
              {t('common:remove')}
            </Button>
            <Button
              variant="outline-light"
              onClick={handleCreateTask}
              className="CustomersBulkActions__createTask"
              disabled={isLoading}
            >
              {t('clients:create-task')}
            </Button>
            <Button
              variant="outline-light"
              onClick={showCreateReminder}
              className="CustomersBulkActions__sendReminder"
              disabled={isLoading}
            >
              {t('clients:send-reminder')}
            </Button>
            <Button
              variant="outline-light"
              onClick={() => handleConfirmMute(true)}
              className="CustomersBulkActions__muteDunning"
              disabled={isLoading}
            >
              {t('clients:set-mute')}
            </Button>
            <Button
              variant="outline-light"
              onClick={() => handleConfirmMute(false)}
              className="CustomersBulkActions__unmuteDunning"
              disabled={isLoading}
            >
              {t('clients:unmute-dunning')}
            </Button>
          </Fragment>
        }
        <Confirm
          titleModal={confirmTitle}
          text={confirmText}
          isShow={isConfirmShowed}
          onConfirm={handleSubmit}
          onCancel={hideConfirm}
          variant={confirmVariant}
          confirmButtonText={confirmButtonText}
        />
      </div>
      {totalRecords > pageSize &&
        <div className="CustomersSelectAll">
          {t('clients:select-all-customers', { count: totalRecords })}
          <FormCheck
            checked={isAllSelected}
            onChange={setAllSelected}
          />
        </div>
      }
    </Fragment>
  );
};

export default observer(CustomersBulkActions);
