import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import authStore from 'stores/Auth';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import type { UserPassword } from 'api/users';
import type { User } from 'types/models';
import apiUsers from 'api/users';
import PasswordEditForm from './PasswordEditForm';

type Props = {
  onClose(): void,
  onShowToast(message: string, type: ToastStatus): void,
};

const ModalEditPassword = ({ onClose, onShowToast }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = authStore;
  const isMountedRef = useIsMountedRef();

  const { put, isLoading, cancel, error } = useApiRequest();

  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);

  const mapFormData = useCallback(
    (rawData: ModalFormData): UserPassword => ({
      password: rawData?.passwordActive as string,
      newPassword: rawData?.passwordNew as string,
      repeatedPassword: rawData?.passwordNewRepeat as string,
    }),
    [],
  );

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !user) {
        return;
      }

      const result = await put<User>(
        apiUsers.updatePassword(user.id),
        mapFormData(formData),
      );

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (result?.id) {
        onShowToast(t('users:toast.success.password-update'), 'success');
        onClose();
      }
    },
    [user, mapFormData, put, isMountedRef, onShowToast, onClose, t],
  );

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <ModalForm
      title={t('users:edit-password')}
      className="ModalEditPassword"
      isOpened
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <PasswordEditForm errors={validationErrors} />
    </ModalForm>
  );
};

export default ModalEditPassword;
