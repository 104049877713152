import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import SelectSearch, { fuzzySearch, SelectSearchOption } from 'react-select-search';
import countryStore from 'stores/Countries';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { DefaultValue, getDefaultValue } from './utils';

type Props = {
  name: string,
  onSelect?(name: string, newValue: string | null): void,
  placeholder?: DefaultTFuncReturn,
  defaultValue?: DefaultValue,
  value?: string | string[] | null,
  entityValue?: 'id' | 'reference',
  disabled?: boolean,
  className?: string,
  isMultiple?: boolean,
  isInvalid?: boolean,
  withClearButton?: boolean,
};

const FormSelectCountry = (props: Props): JSX.Element => {
  const {
    name,
    onSelect,
    placeholder,
    defaultValue,
    value: controlValue,
    disabled = false,
    className,
    isMultiple = false,
    isInvalid = false,
    withClearButton = true,
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const hasRefetched = useRef<boolean>(false);

  const [value, setValue] = useState<string | string[]>('');
  const [cleared, setCleared] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectSearchOption[] | null>(null);

  const isSearchable = useMemo(() => (!!options && options.length > 3), [options]);

  const { isBusy, countriesOptions, fetchAll } = countryStore;

  useEffect(() => {
    if (!countriesOptions && !isBusy && !hasRefetched.current) {
      setIsLoading(true);
      fetchAll();
      hasRefetched.current = true;
      return;
    }

    if (countriesOptions) {
      setIsLoading(false);
      setOptions(countriesOptions.map((country) => ({
        name: country.label,
        value: country.value,
      })));
    }
  }, [countriesOptions, fetchAll, isBusy]);

  useEffect(() => {
    if (!defaultValue || value.length > 0) {
      return;
    }
    const initValue = getDefaultValue(defaultValue, options || []);
    setValue(initValue);
    if (onSelect) {
      onSelect(name, Array.isArray(initValue) ? initValue.join(',') : initValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  useEffect(() => {
    if (controlValue === undefined || value === controlValue) {
      return;
    }
    if (cleared) {
      setCleared(false);
    }
    setValue(controlValue === null ? '' : controlValue);
  }, [value, controlValue, cleared]);

  const handleChange = useCallback(
    (selectedValue: string | string[]) => {
      setValue(selectedValue);
      const newValue = (Array.isArray(selectedValue)) ? selectedValue.join(',') : selectedValue;

      if (inputRef?.current) {
        // - Ceci permet de déclencher un onChange au niveau du <form> parent
        const inputValSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        inputValSetter?.call(inputRef.current, newValue);
        inputRef.current.dispatchEvent(
          new Event('change', { bubbles: true }),
        );
      }

      if (onSelect) {
        onSelect(name, newValue);
      }
    },
    [name, onSelect],
  );

  const handleClear = useCallback(() => {
    setCleared(true);
    setValue('');
    if (onSelect) {
      onSelect(name, null);
    }
  }, [name, onSelect]);

  const classNames = classnames('FormSelectCountry', className, {
    'FormSelectCountry--has-value': withClearButton && value.length > 0,
    'FormSelectCountry--is-loading': isLoading,
    'FormSelectCountry--is-invalid': isInvalid,
  });

  return (
    <div className={classNames}>
      {options === null && (
        <Loading />
      )}
      {options !== null && (
        <SelectSearch
          onChange={handleChange}
          disabled={disabled}
          value={value}
          options={options}
          search={isSearchable}
          placeholder={placeholder ?? undefined}
          multiple={isMultiple}
          printOptions="on-focus"
          filterOptions={fuzzySearch}
        />
      )}
      <input
        type="text"
        value={value}
        name={name}
        ref={inputRef}
        onChange={() => {}}
        className="FormSelectCountry__hidden-field"
      />
      {withClearButton && value.length > 0 && (
        <Button
          variant="light"
          onClick={handleClear}
          className="FormSelectCountry__clear"
        >
          <Icon name="close-small" />
        </Button>
      )}
    </div>
  );
};

export default observer(FormSelectCountry);
