import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

const NotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="NotFound">
      <h1 className="NotFound__title">{t('common:page-not-found')}</h1>
      <p className="NotFound__text">
        {t('common:page-may-be-wip')}
      </p>
      <Link to="/">
        <Button variant="black">{t('common:back-to-home')}</Button>
      </Link>
    </div>
  );
};

export default NotFound;
