import { Analytics, OrganizationAnalytics } from 'types/models';
import getI18nAnalytics from 'utils/getI18nAnalytics';
import getColorAnalytics from 'utils/getColorAnalytics';
import formatIntlDate from 'utils/formatIntlDate';
import Misc from 'types/misc';

type HasDateProperty = { date: string };

const hasDateProperty = (obj: any): obj is HasDateProperty => 'date' in obj;

const getChartData = (
  included: (key: string | Analytics) => boolean,
  data: OrganizationAnalytics['reminders'] |
  OrganizationAnalytics['tasks'] |
  OrganizationAnalytics['paymentDelay'] |
  OrganizationAnalytics['recoveryRate'],
  t: (key: string) => string,
  type: Misc.AnalyticsChartData['datasets'][0]['type'] = 'bar',
  label: Analytics | null = null,
): Misc.AnalyticsChartData => {

  return data.reduce(
    (result, item) => {
      if (hasDateProperty(item)) {
        result.labels?.push(formatIntlDate(item.date, 'monthYear'));
      }

      Object.entries(item).forEach(([key, value]) => {
        if (included(key)) {
          key = key === 'value' ? label! : key;
          const category = t(getI18nAnalytics(key as Analytics));
          const existingDataset = result.datasets.find((dataset) =>
            dataset.label?.includes(category),
          );

          if (existingDataset) {
            existingDataset.data.push(value as number);
          } else {
            result.datasets.push({
              data: [value as number],
              label: category,
              backgroundColor: getColorAnalytics(key as Analytics),
              yAxisID: 'yAxis',
              type: type,
              ...(type === 'line' && {
                fill: true,
                borderJoinStyle: 'round' as const,
                pointRadius: 7,
                pointHoverRadius: 10,
                pointBorderWidth: 2,
                borderColor: '#0076FF',
                borderWidth: 2,
              }),
            });
          }
        }
      });

      return result;
    },
    { labels: [], datasets: [] } as Misc.AnalyticsChartData,
  );
};

export default getChartData;
