import './index.scss';
import type { DebitOrCredit } from 'types/models';

type Props = {
  data: DebitOrCredit,
};

const CustomerAccountDetailsSubjectCell = ({ data }: Props): JSX.Element => {
  const { subject } = data;

  return (
    <div className="CustomerAccountDetailsSubjectCell">
      {subject && subject.length > 0 && (
        <p className="CustomerAccountDetailsSubjectCell__text">
          {subject}
        </p>
      )}
    </div>
  );
};

export default CustomerAccountDetailsSubjectCell;
