import './index.scss';
import Resizable from 'components/Resizable';

type Props = {
  portalID: string,
};

const ActionsSide = ({ portalID }: Props): JSX.Element => (
  <Resizable
    className="ActionsDoneSide"
    localStorageKey="ActionsSidebar"
    max={480}
    min={340}
  >
    <div id={portalID} />
  </Resizable>
);

export default ActionsSide;
