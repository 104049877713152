import './index.scss';
import { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Loading from 'components/Loading';
import type { ToastStatus } from './useToast';

interface Props {
  text: string,
  status?: ToastStatus,
  isShow: boolean,
  onClose: () => void,
}

const ToastNotification = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const {
    text,
    status = 'info',
    isShow,
    onClose,
  } = props;

  const delay = useMemo(() => {
    const multiplier = status === 'error' ? 2 : 1;
    return 4000 * multiplier;
  }, [status]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isShow && status !== 'process') {
      timeoutId = setTimeout(() => { onClose(); }, delay);
    }
    return () => { clearTimeout(timeoutId); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  const classNames = classnames('ToastNotification', {
    'ToastNotification--primary': status === 'primary',
    'ToastNotification--info': status === 'info',
    'ToastNotification--success': status === 'success',
    'ToastNotification--warning': status === 'warning',
    'ToastNotification--process': status === 'process',
    'ToastNotification--error': status === 'error',
  });

  return (
    <div className={classNames}>
      <div className="ToastNotification__wrapper">
        <div className="ToastNotification__content">
          {status === 'process' && <Loading hasNoText />}
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: text }} />
          {status !== 'process' &&
            <Button
              variant="outline-light"
              className="ToastNotification__close-button"
              onClick={onClose}
            >
              {t('common:close')}
            </Button>
          }
        </div>
      </div>
    </div>
  );
};

export default ToastNotification;
