import { useCallback, useState, Fragment } from 'react';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import Collaborators from './Collaborators';
import Invited from './Invited';
import SettingsCollaboratorsPerimeters from './Perimeters';

const SettingsCollaborators = (): JSX.Element => {
  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const [reloadTick, setReloadTick] = useState<number>(0);

  const handleReload = useCallback(() => {
    setReloadTick(reloadTick + 1);
  }, [reloadTick]);

  return (
    <Fragment>
      <Collaborators
        onShowToast={showToast}
        onInvitationDone={handleReload}
        reloadTick={reloadTick}
      />
      <Invited
        onShowToast={showToast}
        reloadTick={reloadTick}
      />
      <SettingsCollaboratorsPerimeters
        onShowToast={showToast}
        onReload={handleReload}
        reloadTick={reloadTick}
      />
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </Fragment>
  );
};

export default SettingsCollaborators;
