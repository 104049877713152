import Misc from 'types/misc';

const getI18nOperatorFilter = (operator: Misc.FilterOperators): string => {
  const i18nOperators = [
    { name: '=', translation: 'common:operators.equals' },
    { name: '!=', translation: 'common:operators.different-from' },
    { name: '>', translation: 'common:operators.superior-to' },
    { name: '<', translation: 'common:operators.inferior-to' },
    { name: '>=', translation: 'common:operators.strictly-superior-to' },
    { name: '<=', translation: 'common:operators.strictly-inferior-to' },
    { name: 'IN', translation: 'common:operators.in' },
    { name: 'NOT IN', translation: 'common:operators.not-in' },
    { name: 'CONTAINS', translation: 'common:operators.contains' },
    { name: 'DOES NOT CONTAIN', translation: 'common:operators.does-not-contain' },
    { name: 'BETWEEN', translation: 'common:operators.between' },
    { name: 'NOT BETWEEN', translation: 'common:operators.not between' },
    { name: 'PERIOD', translation: 'common:operators.period' },
  ];

  return i18nOperators.find(({ name }) => name === operator)?.translation || 'common:unknown';
};

export default getI18nOperatorFilter;
