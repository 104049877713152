import './index.scss';
import classnames from 'classnames';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import type { ImportLine } from 'types/models';
import { ImportLineStatus } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import getI18nReportLineStatus from 'utils/getI18nReportLineStatus';
import formatIntlDate from 'utils/formatIntlDate';
import LineError from './LineError';

type Props = {
  line: ImportLine,
};

const ImportsReportLineItem = ({ line }: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);

  const className = classnames('ImportsReportLineItem', {
    'ImportsReportLineItem--error': line.status === ImportLineStatus.RESULT_ERROR,
    'ImportsReportLineItem--updated': line.status === ImportLineStatus.RESULT_UPDATED,
    'ImportsReportLineItem--created': line.status === ImportLineStatus.RESULT_CREATED,
  });

  const reference = useMemo(() => {
    if (line.type.startsWith('PAYMENT')) {
      return line.payment?.reference;
    }
    return line.type.startsWith('PAY_REQUEST')
      ? line.debit.reference
      : line.client.reference;
  }, [line]);

  const withCustomer = useMemo(() => (
    [ImportLineStatus.RESULT_UPDATED, ImportLineStatus.RESULT_CREATED].includes(line.status)
  ), [line]);

  return (
    <div className={className}>
      <p className="ImportsReportLineItem__line-text">{t('reports:line-description')} {line.line}</p>
      <div className="ImportsReportLineItem__status">
        <h5 className="ImportsReportLineItem__status__text">{ct(getI18nReportLineStatus(line.status))}</h5>
      </div>
      <p className="ImportsReportLineItem__date">{t('reports:finished-at')} {formatIntlDate(line.processedAt, 'dateTimeLong')}</p>
      {line.status !== ImportLineStatus.SKIPPED && (
        <p className="ImportsReportLineItem__reference">{t('common:reference')}: {reference}</p>
      )}
      {withCustomer && (
        <p className="ImportsReportLineItem__client-info">
          {`${ct('common:client')}: ${line.client.name} `}
          <span className="ImportsReportLineItem__client-info__reference">
            ({line.client.reference})
          </span>
        </p>
      )}
      {line.status === ImportLineStatus.RESULT_ERROR && (
        <div className="ImportsReportLineItem__error">
          {line.errors.map((error) => (
            <LineError
              key={line.line}
              error={error}
              debitReference={line.debit.reference}
              customerReference={line.client.reference}
              type={line.type}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(ImportsReportLineItem);
