import './index.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import AuthStore from 'stores/Auth';
import Icon from 'components/Icon';
import Option from './Option';

const MenuOptions = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = AuthStore;
  const locale = user?.locale ?? 'fr';

  return (
    <div className="MenuOptions">
      <div className="MenuOptions__menu">
        <h1 className="MenuOptions__menu__title">
          {t('home:first-steps')}
        </h1>
        <p className="MenuOptions__menu__subtitle">
          {t('home:no-panic')}
        </p>
      </div>
      <div className="MenuOptions__options">
        <Option
          icon={<Icon name="apple" />}
          title={t('home:discover-demo')}
          link={`https://demo.dunforce.com/login?email=demo_${locale}@dunforce.com&password=demo`}
        />
        <Option
          icon={<Icon name="organization" />}
          title={t('home:start-organization')}
          link="/organization/new"
        />
      </div>
    </div>
  );
};

export default observer(MenuOptions);
