import './index.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useTooltip from 'components/Tooltip/useTooltip';
import LinkOrNot from 'components/LinkOrNot';

type Props = {
  link: {
    to: string,
    label: string,
    disabled?: boolean,
  },
};

const SettingsNavItemLink = ({ link }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { to, label, disabled = false } = link;

  const tooltip = useTooltip(
    <span className="SettingsNavItemLink__tooltip">
      {t('common:menu.please-select-organization')}
    </span>,
    'center',
    !disabled,
  );

  const classNames = classnames('SettingsNavItemLink', {
    'SettingsNavItemLink--active': to === pathname,
    'SettingsNavItemLink--disabled': disabled,
  });

  return (
    <li
      key={to}
      className={classNames}
      ref={tooltip}
    >
      <LinkOrNot to={to} className="SettingsNavItemLink__link" disabled={disabled}>
        {label}
      </LinkOrNot>
    </li>
  );
};

export default SettingsNavItemLink;
