import { useCallback, useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { Debit } from 'types/models';
import { DebitAction, DebitStatus, RightsRole } from 'types/models';
import PromiseToPay from 'pages/Debits/Actions/Row/PromiseToPay';
import ActionsToggle from 'pages/Debits/Actions/Row/Toggle';
import getToggleActionI18n from 'pages/Debits/Actions/Row/getToggleActionI18n';
import AddCredit from 'pages/Debits/Actions/Row/AddCredit';
import CreateTaskForm from 'pages/Debit/Modals/CreateTask';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import useContextualTranslation from 'hooks/useContextualTranslation';
import CreateReminder from 'components/CreateReminder';
import BlockSection from 'components/BlockSection';
import ActionsGroup from 'components/ActionsGroup';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import Config from 'config';
import MoreActions from './More';

type Props = {
  data: Debit,
  onDone(message: string): void,
  onError(message: string): void,
};

const DebitSideActions = ({ data, onDone, onError }: Props): JSX.Element | null => {
  const history = useHistory();
  const { type } = organizationStore.currentOrganization!;
  const isUserReader = authStore.user?.roles.includes(RightsRole.ROLE_READER);
  const { t, ct } = useContextualTranslation(type);

  const [showCreateTaskModal, hideCreateTaskModal] = useModal(() => (
    <CreateTaskForm
      onDone={onDone}
      onClose={hideCreateTaskModal}
      debitData={data}
    />
  ), [onDone, data]);

  const [showCreateReminder, hideCreateReminder] = useModal(() => (
    <CreateReminder
      debitData={data}
      onDone={onDone}
      onClose={hideCreateReminder}
    />
  ), [data, onDone]);

  const handleToggleActionDone = useCallback((action: DebitAction) => {
    if (data === null) {
      return;
    }
    const { reference } = data;
    const { toastText: toggleToastText } = getToggleActionI18n(t, ct, reference)[action];
    onDone(toggleToastText);
    if (action === DebitAction.REMOVE) {
      history.push('/debits');
    }
  }, [data, t, ct, onDone, history]);

  const {
    id,
    reference,
    muted,
    status,
    client,
    amount,
    promiseToPayAt,
    invoice,
    isDownloadableFromS3,
    hasDunningAssignment,
  } = data;

  const isPaid = status === DebitStatus.PAID;
  const isLitigated = status === DebitStatus.LITIGATED;
  const isNotActive = status === DebitStatus.CANCEL;
  const isActive = !isNotActive;
  const showMoreActions = !isPaid && isActive;

  const moreActions = useMemo(() => (
    <MoreActions
      data={data}
      onToggleDone={handleToggleActionDone}
      onDone={onDone}
      onError={onError}
    />
  ), [data, handleToggleActionDone, onDone, onError]);

  if (isUserReader) {
    return null;
  }

  return (
    <BlockSection title={t('common:commands')}>
      <ActionsGroup moreActions={showMoreActions ? moreActions : null}>
        {isLitigated && (
          <ActionsToggle
            id={id}
            reference={reference}
            muted={muted?.at ?? null}
            action={DebitAction.CANCEL_LITIGATE}
            buttonIcon="undo"
            onDone={handleToggleActionDone}
            onError={onError}
          />
        )}
        {!isPaid && isActive && (
          <AddCredit
            reference={reference}
            customerIdentifier={client.id}
            maxAmount={amount}
            onDone={onDone}
          />
        )}
        {(!isPaid && isActive) && (
          <PromiseToPay
            id={id}
            reference={reference}
            defaultValue={promiseToPayAt}
            onLoadingChange={() => {}}
            onDone={onDone}
          />
        )}
        {hasDunningAssignment && (
          <ActionButton
            title={t('debits:add-task')}
            onClick={showCreateTaskModal}
            icon={<Icon name="list" />}
          />
        )}
        {hasDunningAssignment && (
          <ActionButton
            title={t('debits:add-dunning')}
            onClick={showCreateReminder}
            icon={<Icon name="message" />}
          />
        )}
        {invoice && (
          <a
            href={isDownloadableFromS3 ? invoice : `${Config.DOWNLOAD_BASE_URL}${invoice}`}
            className="DebitSide__link"
            target="_blank"
            rel="noopener noreferrer"
            title={ct<string>('common:download')}
          >
            <ActionButton
              title={ct('debits:download-receipt')}
              onClick={() => { }}
              icon={<Icon name="arrow-down" />}
            />
          </a>
        )}
        {!showMoreActions && moreActions}
        {isNotActive && (
          <ActionsToggle
            id={id}
            reference={reference}
            muted={muted?.at ?? null}
            action={DebitAction.REMOVE}
            buttonIcon="warning-circle"
            buttonVariant="danger"
            onDone={handleToggleActionDone}
            onError={onError}
          />
        )}
      </ActionsGroup>
    </BlockSection>
  );
};

export default observer(DebitSideActions);
