import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import authStore from 'stores/Auth';
import BrandLogo from 'components/BrandLogo';
import HeaderNav from './Nav';
import MenuToggle from './MenuToggle';
import OrganizationSelector from './OrganizationSelector';
import UserMenu from './UserMenu';
import Search from './Search';
import CreateMenu from './CreateMenu';

const Header = (): JSX.Element => {
  const { pathname } = useLocation();
  const { user } = authStore;
  const { organizations } = user || { organizations: null };
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  useEffect(() => {
    setIsSideMenuOpen(false);
  }, [pathname]);

  const handleSideMenuToggle = useCallback(() => {
    setIsSideMenuOpen(!isSideMenuOpen);
  }, [isSideMenuOpen]);

  const navClassNames = classnames('Header__navigation', {
    'Header__navigation--displayed': isSideMenuOpen,
  });

  return (
    <header className="Header">
      <div className="Header__left">
        <BrandLogo />
        <MenuToggle onToggle={handleSideMenuToggle} />
      </div>
      <div className={navClassNames}>
        <HeaderNav onClose={handleSideMenuToggle} />
      </div>
      <div className="Header__right">
        {(organizations && organizations.length > 0) && (
          <OrganizationSelector organizations={organizations} />
        )}
        <UserMenu />
        <Search />
        <CreateMenu />
      </div>
    </header>
  );
};

export default observer(Header);
