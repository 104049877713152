import { ImportItemType } from 'types/models';

const getI18nTypeImport = (type: ImportItemType): string => {
  const i18nTypeImport = {
    [ImportItemType.DEBIT]: 'imports:type-import.debit',
    [ImportItemType.CLIENT]: 'imports:type-import.client',
    [ImportItemType.CONTACT]: 'imports:type-import.contact',
    [ImportItemType.CREDIT]: 'imports:type-import.credit',
    [ImportItemType.OTHER]: 'imports:type-import.other',
    [ImportItemType.INVOICE]: 'imports:type-import.invoice',
    [ImportItemType.PLAN]: 'imports:type-import.plan',
    [ImportItemType.EXTRA]: 'imports:type-import.extra',
    [ImportItemType.APP_INVOICE]: 'imports:type-import.invoice',
    [ImportItemType.APP_CSV]: 'imports:type-import.csv-import',
  };

  return i18nTypeImport[type];
};

export default getI18nTypeImport;
