import './index.scss';
import { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { DebitType, type Debit } from 'types/models';
import apiDebits from 'api/debits';
import type { FetchOneParams } from 'api/debits';
import useFetch from 'hooks/useFetch2';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import DebitSide from './Side';
import DebitHeader from './Header';
import DebitContent from './Content';

export type RouteParams = {
  debitId?: string,
};

const DebitPage = (): JSX.Element | null => {
  const { debitId } = useParams<RouteParams>();
  const location = useLocation();
  const queryParams = (new URLSearchParams(location.search)).get('type');
  const debitType = queryParams === 'settlement' ? DebitType.SETTLEMENT : DebitType.DEBIT;
  const [reloadTick, setReloadTick] = useState<number>(0);

  const { data: debitData, refetch } = useFetch<FetchOneParams, Debit>(
    {
      cacheKey: 'debit',
      id: parseInt(debitId!),
      debitType,
    },
    apiDebits.one,
    { enabled: !!debitId },
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback(
    (message: string) => {
      showToast(message, 'success');
      setReloadTick(reloadTick + 1);
      refetch();
    },
    [showToast, reloadTick, refetch],
  );

  const handleActionError = useCallback(
    (message: string) => {
      showToast(message, 'error');
    },
    [showToast],
  );

  if (!debitId) {
    return null;
  }

  return (
    <div className="Debit">
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
      <div className="Debit__content">
        <div className="Debit__content__main-view">
          <DebitHeader debit={debitData} />
          {debitData && (
            <DebitContent
              debit={debitData}
              reloadTick={reloadTick}
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          )}
        </div>
        <DebitSide
          reloadTick={reloadTick}
          onActionDone={handleActionDone}
          onActionError={handleActionError}
          debitData={debitData}
        />
      </div>
    </div>
  );
};

export default observer(DebitPage);
