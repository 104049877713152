import './index.scss';
import { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import Config from 'config';
import type Misc from 'types/misc';
import type { Import } from 'types/models';
import type { FetchAllImportsHistory } from 'api/imports';
import apiImports from 'api/imports';
import useFetchPaginated from 'hooks/useFetchPaginated2';
import ImportsHistoryFiltersStore from 'stores/FilterStores/ImportsHistoryFilters';
import organizationStore from 'stores/Organization';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import ImportsHistoryFilters from './Filters';
import ImportsHistoryColumns from './Columns';

const ImportsHistory = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'file', desc: false },
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<Import>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    resetAllFilters,
    removeFilter,
  } = ImportsHistoryFiltersStore;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const {
    data,
    serverPagination,
    error,
    isFetching,
    isLoading,
  } = useFetchPaginated<FetchAllImportsHistory, Import>(
    {
      cacheKey: 'importsHistory',
      organizationId: currentOrganization?.id,
      params: {
        page: fetchOptions.pageIndex,
        pageSize: fetchOptions.pageSize,
        filtering: filters,
      },
    },
    apiImports.allImportsHistory,
    { enabled: !!currentOrganization },
  );

  const columns = useMemo<Column<Import>[]>(
    () => ImportsHistoryColumns(t),
    [t],
  );

  const filtersList = useMemo<Misc.FilterDeclarationItem[]>(
    () => ImportsHistoryFilters(t),
    [t],
  );

  return (
    <PageList
      className="ImportsHistory"
      title={t('imports:history')}
    >
      <DataTable<Import>
        columns={columns}
        data={data}
        serverPagination={serverPagination}
        defaultSortBy={defaultFetchOptions.sort}
        filtering={filters}
        fetchData={setFetchOptions}
        onChangeFilters={addOrUpdateFilters}
        onRemoveFilter={removeFilter}
        filtersList={filtersList}
        isLoading={isFetching || isLoading}
        error={error}
        withActions={false}
      />
    </PageList>
  );
};

export default observer(ImportsHistory);
