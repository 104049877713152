import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Column } from 'react-table';
import type Misc from 'types/misc';
import type { DebitDraft } from 'types/models';
import type { FetchAllParams } from 'api/debits';
import apiDebits from 'api/debits';
import Config from 'config';
import organizationStore from 'stores/Organization';
import useFetchPaginated from 'hooks/useFetchPaginated2';
import useContextualTranslation from 'hooks/useContextualTranslation';
import DebitsToCompleteFilters from 'stores/FilterStores/DebitsToCompleteFilters';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import DebitToCompleteColumns from './Columns';
import Bulk from './Actions/Bulk';

const DebitsToComplete = (): JSX.Element => {
  const { id, type } = organizationStore.currentOrganization ?? {
    id: undefined,
    type: null,
  };
  const { ct } = useContextualTranslation(type);

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'paidAt', desc: true },
  };

  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<DebitDraft>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    resetAllFilters,
    removeFilter,
  } = DebitsToCompleteFilters;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    data,
    error,
    serverPagination,
    isLoading,
    refetch,
  } = useFetchPaginated<FetchAllParams, DebitDraft>(
    {
      cacheKey: 'debits',
      organizationId: id,
      params: {
        page: fetchOptions.pageIndex + 1,
        filtering: filters,
      },
    },
    apiDebits.allDrafts,
  );

  const handleActionDone = useCallback(
    (message: string) => {
      showToast(message, 'success');
      refetch();
    },
    [refetch, showToast],
  );

  const handleActionError = useCallback(
    (message: string) => {
      showToast(message, 'error');
    },
    [showToast],
  );

  const columns = useMemo<Column<DebitDraft>[]>(
    () => DebitToCompleteColumns(ct, handleActionDone, handleActionError),
    [ct, handleActionDone, handleActionError],
  );

  return (
    <PageList
      className="DebitsToComplete"
      title={ct('payrequests-draft:title')}
    >
      <DataTable<DebitDraft>
        columns={columns}
        data={data}
        serverPagination={serverPagination}
        isLoading={isLoading}
        error={error}
        withNoDataDrawing
        defaultSortBy={defaultFetchOptions.sort}
        filtering={filters}
        fetchData={setFetchOptions}
        onRemoveFilter={removeFilter}
        onChangeFilters={addOrUpdateFilters}
        withActions
        idAccessor="importId"
        bulkActions={(
          <Bulk
            onActionDone={handleActionDone}
            onActionError={handleActionError}
          />
        )}
      />
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </PageList>
  );
};

export default observer(DebitsToComplete);
