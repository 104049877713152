import './index.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Note } from 'types/models';
import recomposeName from 'utils/recomposeName';
import formatIntlDate from 'utils/formatIntlDate';
import Button from 'components/Button';

interface Props {
  data: Note,
  withInvoice: boolean,
  canEdit: boolean,
  onEdit(id: number): void,
}

const ListNotesItem = ({ data, withInvoice, canEdit, onEdit }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { id, user, date, content: message, debit } = data;

  const classNames = classnames('ListNotesItem', {
    'ListNotesItem--editable': canEdit,
  });

  return (
    <li className={classNames}>
      <p className="ListNotesItem__message">
        {!canEdit ? message : (
          <Button
            variant="link"
            className="ListNotesItem__message__link"
            onClick={() => { onEdit(id); }}
            title={t('common:edit')}
          >
            {message}
          </Button>
        )}
      </p>
      {withInvoice && debit && (
        <Link to={`/debit/${debit.id}`} className="ListNotesItem__link">
          {t('common:bill')} {debit.reference}
        </Link>
      )}
      <p className="ListNotesItem__details">
        {recomposeName(user)}, {formatIntlDate(date, 'dateTimeFullShortMonth')}
      </p>
    </li>
  );
};

export default ListNotesItem;
