import './index.scss';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useApiRequest from 'hooks/useApiRequest';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import organizationStore from 'stores/Organization';
import FormRadios from 'components/FormRadios';
import config from 'config';
import { FormRadioValue } from 'components/FormRadios/Item';
import getI18nCreditAttribution from 'utils/getI18nCreditAttribution';
import apiOrganization from 'api/organization';
import SettingsSection from '../../Section';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
};

const Sending = ({ onShowToast }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization, refresh } = organizationStore;
  const { paymentAttribution } = currentOrganization!;

  const { put } = useApiRequest();

  const handleSubmitCreditAttribution = useCallback(async (newValue: FormRadioValue) => {
    if (!currentOrganization) {
      return;
    }

    const result = await put(
      apiOrganization.updateUrl(currentOrganization.id),
      { flowAttribution: newValue.toString() },
    );
    if (result?.errors) {
      onShowToast(t('errors:unknown-retry'), 'error');
      return;
    }
    onShowToast(t('customization:payment-attribution-updated'), 'success');
    await refresh();
  }, [put, currentOrganization, onShowToast, t, refresh]);

  return (
    <SettingsSection title={t('customization:payments-imputation')} className="CreditsImputation">
      <FormRadios
        name="paymentAttribution"
        defaultValue={paymentAttribution || undefined}
        items={config.ORGANIZATION_PAYMENT_ATTRIBUTION.map((attribution) => ({
          value: attribution, label: t(getI18nCreditAttribution(attribution)),
        }))}
        onChange={handleSubmitCreditAttribution}
      />
    </SettingsSection>
  );
};

export default observer(Sending);
