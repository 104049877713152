import './index.scss';
import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import type { DefaultTFuncReturn } from 'i18next';

export type FormRadioValue = string | number;

type Props = {
  value: FormRadioValue,
  onClick(value: FormRadioValue): void,
  checked: boolean,
  name: string,
  id?: string,
  label?: DefaultTFuncReturn,
  disabled?: boolean,
};

/**
 * @visibleName FormRadiosItem
 */
const FormRadiosItem = (props: Props): JSX.Element => {
  const { value, onClick, checked, name, id, label = null, disabled } = props;

  const handleClick = useCallback(
    () => {
      if (disabled) {
        return;
      }
      onClick(value);
    },
    [disabled, onClick, value],
  );

  const classNames = useMemo(() => (
    classnames('FormRadiosItem', {
      'FormRadiosItem--disabled': disabled,
    })
  ), [disabled]);

  return (
    <div className={classNames}>
      <input
        id={`${id || 'form-radio-item'}-${value}`}
        name={name}
        type="radio"
        value={value}
        className="FormRadiosItem__input"
        disabled={disabled}
        checked={checked}
        readOnly
      />
      <label
        htmlFor={`${id || 'form-radio-item'}-${value}`}
        className="FormRadiosItem__label"
        role="presentation"
        onClick={handleClick}
      >
        {label}
      </label>
    </div>
  );
};

export default FormRadiosItem;
