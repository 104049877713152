import type Misc from 'types/misc';

const getI18nTypeOrganization = (typeId: Misc.OrganizationType): string => {
  const typesI18n = {
    COMPANY: 'organizations:company',
    INDIVIDUAL: 'organizations:individual',
  };

  if (!Object.keys(typesI18n).includes(typeId)) {
    return 'organizations:unknown-type';
  }

  return typesI18n[typeId as Misc.OrganizationType];
};

export default getI18nTypeOrganization;
