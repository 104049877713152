import './index.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CreditStatus, RightsRole } from 'types/models';
import type { ClientCredit } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nCreditStatus from 'utils/getI18nCreditStatus';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import useTooltip from 'components/Tooltip/useTooltip';
import Button from 'components/Button';

type Props = {
  data: ClientCredit,
  showEditCreditModal(id: number): void,
  showCreditModal(id: number): void,
};

const CustomerAccountDetailsStatusCreditCell = ({ data, showEditCreditModal, showCreditModal }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { id: creditId, status, imputations, amountNotAssigned } = data;
  const { currency } = organizationStore.currentOrganization!;
  const isUserReader = authStore.user?.roles.includes(RightsRole.ROLE_READER);
  const isImputable = status !== CreditStatus.FAILED;
  const isImputed = imputations && imputations.length > 0 && amountNotAssigned < 0.0001;

  const tooltipAmountLeft = useTooltip<HTMLSpanElement>(
    <div className="CustomerAccountDetailsStatusCreditCell__tooltip">
      {t('credits:amount-left', {
        amount: formatIntlNumber(Math.abs(amountNotAssigned), currency, 2),
      })}
    </div>,
  );

  if (!isImputable) {
    return (
      <Fragment>
        {status === CreditStatus.FAILED ? t(getI18nCreditStatus(CreditStatus.FAILED)) : t('credits:payment-not-assignable')}
      </Fragment>
    );
  }

  if (isImputed) {
    return (
      <div
        className="CustomerAccountDetailsStatusCreditCell CustomerAccountDetailsStatusCreditCell--imputed"
        onClick={() => { showCreditModal(creditId); }}
        role="button"
        title={t('credits:see-payment-imputations') ?? undefined}
      >
        {t('credits:imputed')}
      </div>
    );
  }

  return (
    <div className="CustomerAccountDetailsStatusCreditCell" ref={tooltipAmountLeft} >
      <Button
        onClick={() => { showEditCreditModal(creditId); }}
        variant={isUserReader ? 'outline' : 'outline-white'}
        disabled={isUserReader}
      >
        {t('credits:payment-not-assigned')}
      </Button>
    </div>
  );
};

export default CustomerAccountDetailsStatusCreditCell;
