import './index.scss';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import authStore from 'stores/Auth';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import setNewLang from './setLang';

const FreshDesk = (): JSX.Element => {
  const { t } = useTranslation();
  const { locale } = authStore.user || config.DEFAULT_LOCALE;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openWidget = useCallback(() => {
    // @ts-ignore
    if (typeof window.FreshworksWidget === 'function') {
      // @ts-ignore
      window.FreshworksWidget('open');
    }
  }, []);

  const toggleWidget = useCallback(() => {
    if (isLoaded) {
      openWidget();
      return;
    }

    setIsLoading(true);
    setNewLang(locale, () => {
      setIsLoaded(true);
      openWidget();
      setIsLoading(false);
    });
  }, [isLoaded, locale, openWidget]);

  return (
    <Button className="FreshDesk" variant="list" onClick={toggleWidget}>
      <Icon name="comment" /> {t('common:support')}
      {isLoading && <Loading hasNoText />}
    </Button>
  );
};

export default observer(FreshDesk);
