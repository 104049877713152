import './index.scss';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import classnames from 'classnames';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import type { ErrorValidationMessageType } from 'components/ErrorValidationMessage';

type Props = {
  label?: DefaultTFuncReturn,
  controlId?: string,
  className?: string,
  mandatory?: boolean,
  action?: JSX.Element,
  secondaryAction?: JSX.Element,
  tertiaryAction?: JSX.Element,
  error?: ErrorValidationMessageType,
  shouldDisplayErrorUnderInput?: boolean,
  children: ReactNode,
};

const FormGroupWithActions = (props: Props): JSX.Element => {
  const {
    label,
    controlId,
    className,
    mandatory,
    action,
    secondaryAction,
    tertiaryAction,
    shouldDisplayErrorUnderInput = false,
    children,
    error,
  } = props;

  const classNames = useMemo(() => (
    classnames('FormGroupWithActions', className, {
      'FormGroupWithActions--mandatory': mandatory,
      'FormGroupWithActions--with-error-under-input': shouldDisplayErrorUnderInput && error,
    })
  ), [className, error, mandatory, shouldDisplayErrorUnderInput]);

  return (
    <div className={classNames}>
      <div className="FormGroupWithActions__titles">
        {label && (
          <label htmlFor={controlId} className="FormGroupWithActions__titles__label">
            {label}
          </label>
        )}
        <div className="FormGroupWithActions__titles__actions">
          {!shouldDisplayErrorUnderInput && <ErrorValidationMessage error={error} />}
          {action}
          {secondaryAction}
          {tertiaryAction}
        </div>
      </div>
      {children}
      {shouldDisplayErrorUnderInput && <ErrorValidationMessage error={error} />}
    </div>
  );
};

export default FormGroupWithActions;
