import './index.scss';
import classnames from 'classnames';
import { useCallback, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import FormRadiosItem, { FormRadioValue } from 'components/FormRadios/Item';

type Props = {
  colors: string[],
  name: string,
  defaultValue?: string,
  className?: string,
  disabled?: boolean,
  onChange?: (newValue: string) => void,
};

const FormColorPicker = ({ name, colors, className, defaultValue, onChange, disabled } : Props): JSX.Element => {
  const [selectedColor, setSelectedColor] = useState<FormRadioValue | undefined>(defaultValue || colors[0]);
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useOnclickOutside(() => { setIsActive(false); });

  const handleClick = useCallback((newValue: FormRadioValue) => {
    setSelectedColor(newValue);
    setIsActive(false);
    if (onChange) {
      onChange(newValue as string);
    }
  }, [onChange]);

  const handleClickToggle = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const classNames = classnames('FormColorPicker', className, {
    'FormColorPicker--active': isActive,
    'FormColorPicker--disabled': disabled,
  });

  return (
    <div className={classNames}>
      <div
        className="FormColorPicker__selected"
        style={{ backgroundColor: selectedColor as string }}
        aria-label="Selected Color"
        onClick={handleClickToggle}
      />
      <div className="FormColorPicker__list" ref={ref}>
        {colors.map((color, index) => (
          <div
            key={color}
            className="FormColorPicker__item"
            style={{ ['--color' as string]: color }}
          >
            <FormRadiosItem
              key={`form-radio-item-${name}-${index}`}
              id={`form-radio-item-${name}-${index}`}
              value={color}
              onClick={handleClick}
              checked={color === selectedColor}
              name={name}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormColorPicker;
