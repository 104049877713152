import './index.scss';
import type { ReactNode } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import TooltipProvider from 'components/Tooltip';
import useTooltip from 'components/Tooltip/useTooltip';
import HelpLink from 'components/HelpLink';
import WarningIcon from './WarningIcon';

interface Props {
  title: string,
  isOpened: boolean,
  subTitle?: DefaultTFuncReturn,
  icon?: ReactNode,
  headerActions?: ReactNode,
  className?: string,
  isLoading?: boolean,
  hasWarning?: boolean,
  onClose: () => void,
  helpHeaderText?: DefaultTFuncReturn,
  helpHeaderLink?: { [key: string]: string },
  children: ReactNode,
  totalRecords?: string,
}

const Modal = (props: Props): JSX.Element | null => {
  const {
    title,
    isOpened,
    subTitle,
    icon,
    headerActions,
    className,
    isLoading,
    hasWarning = false,
    onClose,
    children,
    totalRecords,
    helpHeaderText,
    helpHeaderLink,
  } = props;

  const [show, setShow] = useState<boolean>(false);

  const tooltip = useTooltip(
    <div className="Modal__tooltip">{helpHeaderText}</div>,
    'center',
  );

  useEffect(() => {
    setShow(isOpened);
  }, [isOpened]);

  const classNames = classnames('Modal', className, {
    'Modal--hidden': !show,
  });

  if (!isOpened) {
    return null;
  }

  return (
    <div className={classNames}>
      <div className="Modal__overlay" />
      <div className="Modal__wrapper">
        <div className="Modal__page">
          <header className="Modal__header">
            {icon}
            <div className="Modal__header__titles">
              <h1 className="Modal__title">
                {title}
                {totalRecords &&
                  <strong className="Modal__totalRecords">{totalRecords}</strong>
                }
                {helpHeaderText && (
                  <TooltipProvider>
                    <span className="Modal__header__helper" ref={tooltip}>
                      <Icon name="help-circle" />
                    </span>
                  </TooltipProvider>
                )}
                {helpHeaderLink &&
                  <HelpLink article={helpHeaderLink} />
                }
              </h1>
              {subTitle && <h2 className="Modal__subtitle">{subTitle}</h2>}
            </div>
            {headerActions && (
              <div className="Modal__header__actions">
                {headerActions}
              </div>
            )}
            {isLoading && <Loading hasNoText />}
            {hasWarning && <WarningIcon />}
            <Button
              variant="outline"
              className="Modal__header__close"
              onClick={onClose}
            >
              <Icon name="close" />
            </Button>
          </header>
          <div className="Modal__content">
            <div className="Modal__content__wrapper">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
