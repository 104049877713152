import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { ManagerType } from 'types/models';
import { RightsCategoryType } from 'types/models';
import customerContext from 'pages/Customer/customerContext';
import CreditEditModal from 'components/CreditEditModal';
import { UserRightsManagerController } from 'components/UserRights';
import Button from 'components/Button';

type Props = {
  managers: ManagerType[] | null | undefined,
};

const CustomerActionNewPayment = ({ managers }: Props): JSX.Element => {
  const { data, onActionDone } = useContext(customerContext);
  const { t } = useTranslation();

  const handleActionDone = useCallback((title: string, message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showEditPaymentModal, hideEditModal] = useModal(() => (
    <CreditEditModal
      customerIdentifier={data?.id}
      onDone={handleActionDone}
      onClose={hideEditModal}
    />
  ), [data, handleActionDone]);

  return (
    <UserRightsManagerController
      action="CREATE"
      categoryType={RightsCategoryType.PAYMENTS}
      managers={managers}
    >
      <Button
        variant="list"
        onClick={showEditPaymentModal}
        className="CustomerActionNewPayment"
      >
        {t('clients:new-payment')}
      </Button>
    </UserRightsManagerController>
  );
};

export default CustomerActionNewPayment;
