import './index.scss';
import React from 'react';
import organizationStore from 'stores/Organization';
import NoOrganisationMessage from 'components/NoOrganizationMessage';
import ImportsFileHeader from './Header';
import ImportsFileNav from './Nav';
import ImportsFileWizard from './Wizard';

const ImportsFile = () => {
  const { currentOrganization } = organizationStore;

  if (!currentOrganization) {
    return <NoOrganisationMessage />;
  }

  return (
    <div className="ImportsFile">
      <ImportsFileHeader />
      <div className="ImportsFile__content">
        <div className="ImportsFile__side-view">
          <ImportsFileNav />
        </div>
        <div className="ImportsFile__main-view">
          <ImportsFileWizard />
        </div>
      </div>
    </div>
  );
};

export default ImportsFile;
