import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import Icon from 'components/Icon';

const GoBackButton = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="GoBackButton">
      <Button variant="outline" title={t('common:back')} onClick={history.goBack}>
        <Icon name="chevron-left" />
      </Button>
    </div>
  );
};

export default GoBackButton;
