import './index.scss';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import authStore from 'stores/Auth';
import Alert from 'components/Alert';
import Modal from 'components/Modal';
import Button from 'components/Button';

type Props = {
  titleModal: string,
  text: string,
  entityName?: string | null,
  isShow: boolean,
  onConfirm(): void,
  onCancel(): void,
  variant?: 'primary' | 'danger',
  confirmButtonText?: DefaultTFuncReturn,
  cancelButtonText?: DefaultTFuncReturn,
  isDemoSafe?: boolean,
  helpHeaderText?: DefaultTFuncReturn,
};

const Confirm = (props: Props): JSX.Element => {
  const {
    titleModal,
    text,
    entityName = null,
    isShow,
    onConfirm,
    onCancel,
    variant = 'primary',
    confirmButtonText,
    cancelButtonText,
    isDemoSafe = false,
    helpHeaderText,
  } = props;
  const { t } = useTranslation();
  const { isDemo } = authStore;

  const handleConfirm = useCallback(() => {
    if (!isDemo || isDemoSafe) {
      onConfirm();
    }
  }, [isDemo, isDemoSafe, onConfirm]);

  return (
    <Modal
      isOpened={isShow}
      onClose={onCancel}
      title={titleModal}
      helpHeaderText={helpHeaderText}
      className="Confirm"
    >
      {/* eslint-disable-next-line react/no-danger */}
      <p className="Confirm__text" dangerouslySetInnerHTML={{ __html: text }} />
      {entityName && <p className="Confirm__entity-name">{entityName}</p>}
      <div className="Confirm__footer">
        {isDemo && (
          <Alert variant="warning">
            {t('common:demo.warning')} {t('common:demo.you-cannot-perform-this-action')}
          </Alert>
        )}
        <Button variant="outline-black" onClick={onCancel}>
          {cancelButtonText || t('common:cancel')}
        </Button>
        <Button variant={variant} onClick={handleConfirm} disabled={isDemo && !isDemoSafe}>
          {confirmButtonText || t('common:confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(Confirm);
