import type Misc from 'types/misc';

const getContactRole = (roleId: string, contactRoles: Misc.IdIdentifierValue[] | null) => {
  if (!contactRoles) {
    return roleId;
  }

  const foundRole = contactRoles.find((role: Misc.IdIdentifierValue) => role.identifier === roleId);

  return foundRole?.value || roleId;
};

export default getContactRole;
