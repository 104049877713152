import './index.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import type { DataBlock } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormDatePicker from 'components/FormDatePicker';

type Props = {
  defaultData?: DataBlock | null,
  errors?: ValidationErrors | null,
};

const DebitCreateTaskForm = ({ defaultData, errors }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>(defaultData?.name || '');
  const [description, setDescription] = useState<string>(defaultData?.description || '');

  useEffect(() => {
    setName(defaultData?.name || '');
    setDescription(defaultData?.description || '');
  }, [defaultData]);

  return (
    <div className="DebitCreateTaskForm">
      <FormFieldset>
        <FormGroup
          label={t('common:planned-at')}
          className="DebitCreateTaskForm__date"
          mandatory
          error={errors?.startAt}
        >
          <FormDatePicker
            name="startAt"
            minDate={new Date()}
            isInvalid={!!errors?.startAt}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('reminders:task-name')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            value={name}
            onChange={setName}
            placeholder={t('reminders:placeholder.task-name')}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('reminders:task-description')} error={errors?.description}>
          <FormControl
            type="textarea"
            name="description"
            autoComplete="off"
            value={description}
            placeholder={t('reminders:placeholder.task-description')}
            onChange={setDescription}
            isInvalid={!!errors?.description}
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default observer(DebitCreateTaskForm);
