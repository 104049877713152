import React from 'react';
import type { TFunction } from 'i18next';
import Button from 'components/Button';

const SettingsPlansPlanActionButtons = (
  t: TFunction,
  showModalEditPlan: () => void,
  showModalDeletePlan: () => void,
  showModalDuplicatePlan: () => void,
):React.ReactNode[] => [
  <Button variant="list" onClick={showModalDuplicatePlan}>
    {t<string>('plans:duplicate-plan')}
  </Button>,
  <Button variant="list" onClick={showModalEditPlan}>
    {t<string>('plans:edit-plan-settings')}
  </Button>,
  <Button variant="list-danger" onClick={showModalDeletePlan}>
    {t<string>('common:remove')}
  </Button>,
];

export default SettingsPlansPlanActionButtons;
