import type Misc from 'types/misc';
import type { ClientIdentifiers } from './Client';
import type { Imputation, ImputationIdentifiers } from './Imputation';
import type { Currency, CurrencyCode } from './Currency';
import { Eav } from './EAV';
import { DebitIdentifiers } from './Debit';
import { DebitCreditEntity } from './DebitOrCredit';
import type { ManagerType } from './UserRights';

export enum CreditType {
  PAYMENT = 'payment',
  REFUND = 'refund',
  CREDIT_NOTE = 'credit_note',
}

export enum PaymentMethod {
  OTHER = 'other',
  CARD = 'card',
  CHECK = 'check',
  TRANSFER = 'transfer',
  DIRECT_DEBIT = 'direct_debit',
  CASH = 'cash',
}

export enum CreditStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  REFUNDED = 'refunded',
  PENDING = 'pending',
}

export enum CreditOrigin {
  API = 'api',
  FRONT = 'front',
  IMPORT = 'import',
}

export type CreditForList = {
  id: number,
  reference: string,
  method: PaymentMethod,
  status: CreditStatus,
  totalAmount: number,
  amountNotAssigned: number,
  paidAt: string,
  currency: CurrencyCode,
  subject: string | null,
  customer: ClientIdentifiers,
  imputations: Imputation[] | null,
  managers: Misc.IdName[] | null,
};

export type Credit = {
  id: number,
  reference: string,
  amountNotAssigned: number,
  totalAmountPayment: number
  subject: string | null,
  paidAt: string
  currency: CurrencyCode,
  type: CreditType,
  paymentMethod: PaymentMethod,
  origin: CreditOrigin,
  client: {
    id: number,
    reference: string,
    name: string,
  },
  imputations?: Imputation[],
  status: CreditStatus,
  users?: ManagerType[],
};

export type CreditPost = {
  identifier: string,
  amount: string,
  label: string,
  client: string,
  createdBy: string,
  organization: string,
  paidAt: string,
  currency: CurrencyCode | string,
  type: CreditType,
  paymentMethod: PaymentMethod,
  origin: CreditOrigin,
};

export type CreditIdentifiers = {
  id: Credit['id'],
  reference: Credit['reference'],
  amount: string,
};

export type CreditSummary = {
  id: Credit['id'],
  identifier: Credit['reference'],
  code: Credit['reference'],
  values: Eav[] | null,
  createdAt: Misc.DateTimezone,
  updatedAt: Misc.DateTimezone,
  status: Credit['status'],
  amount: Credit['totalAmountPayment'],
  label: string,
  origin: Credit['origin'],
  enabled: boolean,
  paymentMethod: Credit['paymentMethod'],
  paidAt: Misc.DateTimezone,
  imputations: ImputationIdentifiers[],
  debits: DebitIdentifiers[],
  currency: Currency,
  client: ClientIdentifiers,
};

export type ClientCredit = {
  entity: DebitCreditEntity.CREDIT,
  id: Credit['id'],
  reference: Credit['reference'],
  date: Credit['paidAt'],
  // TODO Trouver dans la v2 ce qui remplace balance
  balance: number,
  totalAmountPayment: Credit['totalAmountPayment'],
  // amount n'est pas utilisé dans un crédit mais il faut mettre le champ pour que ça s'affiche dans le tableau du detail du compte
  amount: Credit['totalAmountPayment'],
  amountNotAssigned: Credit['amountNotAssigned'],
  subject: Credit['subject'],
  currency: Credit['currency'],
  type: Credit['type'],
  paymentMethod: Credit['paymentMethod'],
  status: Credit['status'],
  imputations: Imputation[] | null,
};
