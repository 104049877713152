import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectorsType, AllOperators, DaysOperators, MonthsOperators } from 'types/models';
import Misc from 'types/misc';
import type { SelectorsFields } from 'types/models';
import getI18nOperatorComparison from 'utils/getI18nOperatorComparison';
import { getAttributeValue, getFieldDescription } from '../ModalSelector/Form/Conditions/utils';

type Props = {
  condition: SelectorsFields,
};

const ConditionSentence = ({ condition }: Props): string => {
  const { t } = useTranslation();
  const [sentence, setSentence] = useState<string>('');

  const generateSentence = useCallback(() => {
    let { firstField, operator, secondField, thirdField } = condition;

    if (!firstField) {
      firstField = getAttributeValue(secondField!);
    }

    // deuxième champ
    const secondFieldData = getFieldDescription(firstField!, t);
    const secondFieldOption = secondFieldData?.options?.find((option) => String(option.value) === String(secondField));
    const secondFieldText = secondFieldOption?.label ?? secondField;

    // premier champ
    const withFirstFieldText = !!firstField && !['FREE_FIELD', 'DEBIT'].includes(firstField) && !!secondFieldOption;
    const firstFieldText = withFirstFieldText ? t(`selectors:selector-types.${SelectorsType[firstField!]}`) : '';

    // l'opérateur
    let operatorText = t(getI18nOperatorComparison(operator as AllOperators));

    // troisième champ
    let thirdFieldText;
    let isDays = Object.values(DaysOperators).includes(operator as DaysOperators);
    let isMonths = Object.values(MonthsOperators).includes(operator as MonthsOperators);
    if (isDays || isMonths) {
      const days = Math.abs(parseInt(String(thirdField), 10));
      const sign = String(thirdField).startsWith('-') ? '-' : '+';
      const period = isDays ? 'days' : 'months';
      thirdFieldText = `${sign}${t(`selectors:selector-types.${period}-count`, { count: days })}`;
      operatorText += ` ${t('selectors:selector-types.date')}`;
    } else {
      const thirdFieldData = getFieldDescription(secondField, t);
      const thirdFieldOptions : Misc.ValueLabel[] = thirdFieldData?.options
        ?? (thirdFieldData?.optionsBy && operator ? thirdFieldData.optionsBy[operator] || thirdFieldData.optionsBy.default : []);

      thirdFieldText = (thirdFieldData?.isMultiple
        ? String(thirdField).split(',').map((value) => thirdFieldOptions.find((opt) => String(opt.value) === value.trim())?.label ?? value.trim())
        : [thirdFieldOptions?.find((opt) => String(opt.value) === String(thirdField))?.label ?? thirdField]
      ).join(', ');
    }

    setSentence(`${firstFieldText} ${secondFieldText} ${operatorText} ${thirdFieldText}`);
  }, [condition, t]);

  useEffect(() => {
    generateSentence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return sentence ?? '';
};
export default ConditionSentence;
