import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import type { ValidationErrors } from 'types/errors';
import type { Client, ClientPost, ClientType } from 'types/models';
import organizationStore from 'stores/Organization';
import formatEavsValues from 'utils/formatEavsValues';
import useFetch from 'hooks/useFetch2';
import apiClients from 'api/clients';
import apiOrganization from 'api/organization';
import type { FetchOneParams } from 'api/clients';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ModalFormData } from 'components/ModalForm';
import apiUsers from 'api/users';
import Loading from 'components/Loading';
import CustomerForm from './Form';

type Props = {
  editType: 'new' | 'edit',
  customerId: number | undefined,
  onDone(title: string, message: string): void,
  onClose(): void,
  shouldRedirectToCreatedEntity?: boolean,
  finallyRedirectTo?: string,
  defaultDenomination?: string,
};

const CustomerEditModal = (props: Props): JSX.Element => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { attributes, currentOrganization } = organizationStore;
  const { id: organizationId, reference, type } = currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const cache = useQueryClient();
  const {
    editType,
    customerId,
    onDone,
    onClose,
    shouldRedirectToCreatedEntity = false,
    finallyRedirectTo,
    defaultDenomination,
  } = props;

  const isNew = useMemo(() => editType === 'new', [editType]);

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const initialData = useRef<ClientPost | null>(null);

  const { post, put, isLoading: isSaving } = useApiRequest();

  const { data: customerData, error, isLoading: isLoadingClient } = useFetch<FetchOneParams, Client>(
    { cacheKey: 'client', id: customerId! },
    apiClients.one,
    { enabled: !!customerId },
  );

  const mapFormData = useCallback((rawData: ModalFormData): ClientPost | null => {
    const eavsResult = formatEavsValues(attributes.client ?? [], rawData);
    const usersResult = (rawData.users as string | undefined)?.split(',').filter((user) => user !== '').map((user) => apiUsers.resourceUrl(user)) ?? [];
    return {
      organization: apiOrganization.resourceUrl(organizationId),
      type: rawData.type as ClientType,
      identifier: rawData.identifier as string,
      denomination: rawData.denomination as string,
      eavs: eavsResult,
      users: usersResult,
    };
  }, [organizationId, attributes]);

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    if (finallyRedirectTo) {
      setTimeout(() => {
        history.push(finallyRedirectTo);
      }, 300);
    }
  }, [onClose, history, finallyRedirectTo]);

  useEffect(() => {
    const { code } = error || { code: 0 };
    if (code === 404 || code === 2) {
      closeSelf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    // @TODO: Ici on ignore la clé `users`, car cette clé est toujours vide
    // dans les données initiales, à cause du fetch des users différé, dans le
    // component FormSelect async (se trouvant dans le formulaire).
    const { users: noop1, ...initData } = initialData.current || { users: [] };
    const { users: noop2, ...changedData } = data;
    setHasChanges(!equal(initData, changedData));
  }, [initialData, mapFormData]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const handleEmailError = useCallback(() => {
    setValidationErrors({
      email: { code: 4, message: t('email-not-valid') },
    });
  }, [t]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !reference) {
      return;
    }

    setValidationErrors(null);

    const { identifier, denomination } = formData as { [key: string]: string };

    if (!denomination) {
      setValidationErrors({ denomination: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (!identifier) {
      setValidationErrors({ identifier: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    const payloadUpdateClient = { ...data, assignations: data.users, users: undefined, eavs: undefined };
    const payloadUpdateClientEavs = { values: data.eavs };

    let result;
    let resultEavs;
    let haveEavsToUpdate = data.eavs && Object.keys(data.eavs).length > 0;
    // d'abord on met à jour le client sans les eavs
    if (isNew) {
      result = await post<Client>(apiClients.createUrl, payloadUpdateClient);
    } else if (customerData) {
      result = await put<Client>(apiClients.updateUrl(customerData.id), payloadUpdateClient);
    }

    if (haveEavsToUpdate && result?.id) {
      // ensuite on met à jour les eavs
      resultEavs = await put<Client>(
        apiClients.updateClientEavsUrl(result?.id),
        payloadUpdateClientEavs,
      );
    }

    const cacheToInvalidate = [
      ['customer', reference, customerId],
      ['actionsFuture'],
      ['actionsDone'],
      ['clientEavs', customerId],
      ['clientAllEavs', customerId],
    ];

    cacheToInvalidate.forEach((cacheKey) => {
      cache.invalidateQueries({ queryKey: cacheKey });
    });

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result?.identifier && ((haveEavsToUpdate && resultEavs?.identifier) || !haveEavsToUpdate)) {
      onDone(
        ct(
          isNew
            ? 'clients:actions.toast.created-reference'
            : 'clients:actions.toast.modified-reference',
          { reference: result.identifier },
        ),
        reference,
      );
      closeSelf();
      if (shouldRedirectToCreatedEntity) {
        history.push(`/customer/${result.id}`);
      }
    }
  }, [
    reference,
    mapFormData,
    isNew,
    customerData,
    isMountedRef,
    post,
    put,
    cache,
    customerId,
    onDone,
    ct,
    closeSelf,
    shouldRedirectToCreatedEntity,
    history,
    t,
  ]);

  return (
    <ModalForm
      className="CustomerEdit"
      title={isNew ? ct('common:new-client') : ct('common:edit-client')}
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onEmailError={handleEmailError}
      onCancel={handleCancel}
      isFetched={!isLoadingClient}
      isLoading={(isLoadingClient && !!customerId) || isSaving}
    >
      {error && customerId && <ErrorMessage error={error} />}
      {isLoadingClient && (
        <Loading />
      )}
      {!isLoadingClient && (
        <CustomerForm
          defaultData={customerData}
          defaultDenomination={defaultDenomination}
          isCreate={isNew}
          errors={validationErrors}
        />
      )}
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t<string>('common:close-form')}
        cancelButtonText={t<string>('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(CustomerEditModal);
