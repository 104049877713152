import './index.scss';
import Resizable from 'components/Resizable';

type Props = {
  sidebar: JSX.Element,
  children: JSX.Element
};

const CustomerMainLayout = ({ sidebar, children }: Props): JSX.Element => (
    <div className="CustomerMainLayout">
      <Resizable
        className="CustomerMainLayout__side-view"
        localStorageKey="customerSidebar"
        max={480}
        min={290}
      >
        {sidebar}
      </Resizable>
      <div className="CustomerMainLayout__main-view">
        {children}
      </div>
    </div>
);

export default CustomerMainLayout;
