import parseIsoDate from 'utils/parseIsoDate';

const dateIsToday = (dateStr: string): boolean => {
  const date = parseIsoDate(dateStr);
  if (!date) {
    return false;
  }

  const now = new Date();
  return (
    date.getFullYear() === now.getFullYear()
    && date.getMonth() === now.getMonth()
    && date.getDate() === now.getDate()
  );
};

export default dateIsToday;
