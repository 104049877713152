import { EavCategory } from 'types/models';

const getEavAddAttributeLabel = (eavCategory: EavCategory) => {
  const eavCategoryTable = {
    [EavCategory.CLIENT]: 'attributes:add-attribute-client',
    [EavCategory.CONTACT]: 'attributes:add-attribute-contact',
    [EavCategory.DEBIT]: 'attributes:add-attribute-debit',
    [EavCategory.CREDIT]: 'attributes:add-attribute-credit',
  };

  return eavCategoryTable[eavCategory];
};

export default getEavAddAttributeLabel;
