import { makeAutoObservable, runInAction } from 'mobx';
import apiOrganization from 'api/organization';
import apiBusinessUnits from 'api/business-units';
import apiRoleAliases from 'api/roleAliases';
import apiUsers from 'api/users';
import type { ResourceAttribute } from 'api/organization';
import type { Organization, OrganizationNotifications, BusinessUnit, OrganizationEAVs, RoleAlias, User } from 'types/models';
import ResponseError from 'utils/errors';

const initialOrganization: Organization = JSON.parse(
  window.localStorage.getItem('currentOrganization') || 'null',
);

const initialbusinessUnits: BusinessUnit[] = JSON.parse(
  window.localStorage.getItem('businessUnits') || 'null',
);

const initialBusinessUnitsSelection: Array<BusinessUnit['id']> = JSON.parse(
  window.localStorage.getItem('selectedBusinessUnits') || 'null',
);

const initialAttributesSelection: OrganizationEAVs = JSON.parse(
  window.localStorage.getItem('attributes') || 'null',
);

const initialManagersSelection: User[] = JSON.parse(
  window.localStorage.getItem('managers') || 'null',
);

const initialRoleAliases: RoleAlias[] = JSON.parse(
  window.localStorage.getItem('roleAliases') || 'null',
);

class OrganizationStore {
  currentId: number | null = initialOrganization?.id ?? null;

  currentOrganization: Organization | null = initialOrganization ?? null;

  businessUnits: BusinessUnit[] = initialbusinessUnits ?? [];

  selectedBusinessUnits: Array<BusinessUnit['id']> = initialBusinessUnitsSelection ?? [];

  attributes: OrganizationEAVs = initialAttributesSelection ?? { client: [], contact: [], debit: [], credit: [] };

  managers: User[] = initialManagersSelection ?? [];

  notificationsEventSource: EventSource | null = null;

  notifications: OrganizationNotifications | null = null;

  roleAliases: RoleAlias[] | null = initialRoleAliases ?? null;

  isBusy: boolean = false;

  error: ResponseError | null = null;

  constructor() {
    makeAutoObservable(this);
    this.subscribeToNotifications();
  }

  // Nécessaire pour faire un "fix" en urgence sur les imports PDF des organisations Sage
  get isSageOrganization() {
    return this.currentOrganization?.reference === 'sage'
      || this.currentOrganization?.reference === 'sagea';
  }

  get isExternallyMonitored() {
    // A adapter avec la récupération de la propriété adaptée du back-end lorsqu'elle sera en place
    return this.currentOrganization?.reference === 'sage'
      || this.currentOrganization?.reference === 'sagea';
  }

  get walletSelection() {
    return this.selectedBusinessUnits.map((value) => value.toString());
  }

  get selectedBusinessUnitsIdentifier() {
    return this.selectedBusinessUnits.map((value) =>this.businessUnits
      .find((businessUnit) => businessUnit.id === value)?.identifier ?? '',
    );
  }

  async fetchOrganization() {
    if (!this.currentId) {
      return;
    }

    this.isBusy = true;
    this.error = null;

    try {
      const organization = await apiOrganization.getOne(this.currentId);

      window.localStorage.setItem('currentOrganization', JSON.stringify(organization));

      runInAction(() => {
        this.currentOrganization = organization;
        this.subscribeToNotifications();
      });
    } catch (error) {
      const { code, message, details } = error as ResponseError;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
      });
    }
  }

  async fetchManagers() {
    if (!this.currentId) {
      return;
    }

    try {
      this.isBusy = true;

      const managers = await apiUsers.all({ organizationId : this.currentId });

      window.localStorage.setItem('managers', JSON.stringify(managers));

      runInAction(() => {
        this.managers = managers;
      });
    } catch (error) {
      const { code, message, details } = error as ResponseError;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
      });
    }
  }

  async fetchBusinessUnits() {
    if (!this.currentId) {
      return;
    }

    try {
      this.isBusy = true;

      const businessUnits = await apiBusinessUnits.all({ id : this.currentId });

      window.localStorage.setItem('businessUnits', JSON.stringify(businessUnits));

      runInAction(() => {
        this.businessUnits = businessUnits;
      });
    } catch (error) {
      const { code, message, details } = error as ResponseError;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
      });
    }
  }

  async fetchAttributes(resource?: ResourceAttribute) {
    if (!this.currentId) {
      return;
    }

    try {
      this.isBusy = true;
      const getAttributes = (resourceName: ResourceAttribute) =>
        apiOrganization.getAttributes(this.currentId!, resourceName);

      if (resource) {
        const newAttributes = await getAttributes(resource);
        runInAction(() => {
          this.attributes = { ...this.attributes, [resource]: newAttributes };
          window.localStorage.setItem('attributes', JSON.stringify(this.attributes));
        });
      } else {
        const [client, contact, debit, credit] = await Promise.all([
          getAttributes('client'),
          getAttributes('contact'),
          getAttributes('debit'),
          getAttributes('credit'),
        ]);

        runInAction(() => {
          this.attributes = { client, contact, debit, credit };
          window.localStorage.setItem('attributes', JSON.stringify(this.attributes));
        });
      }

    } catch (error) {
      const { code, message, details } = error as ResponseError;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
      });
    }
  }

  async fetchRoleAliases() {
    if (!this.currentId) {
      return;
    }

    try {
      this.isBusy = true;

      const roleAliases = await apiRoleAliases.all({ id: this.currentId });

      runInAction(() => {
        this.roleAliases = roleAliases;
        window.localStorage.setItem('roleAliases', JSON.stringify(this.roleAliases));
      });
    } catch (error) {
      const { code, message, details } = error as ResponseError;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
      });
    }
  }

  refresh = async () => {
    await this.fetchOrganization();
    await this.fetchBusinessUnits();
    await this.fetchAttributes();
    await this.fetchManagers();
    await this.fetchRoleAliases();
  };

  setCurrent = async (id: Organization['id']) => {
    if (this.isBusy || id === this.currentOrganization?.id) {
      return null;
    }

    this.currentId = id;
    this.businessUnits = [];
    this.selectedBusinessUnits = [];
    this.roleAliases = [];
    this.managers = [];
    this.attributes = {
      client: [],
      contact: [],
      debit: [],
      credit: [],
    };
    window.localStorage.removeItem('businessUnits');
    window.localStorage.removeItem('selectedBusinessUnits');
    window.localStorage.removeItem('attributes');
    window.localStorage.removeItem('managers');
    window.localStorage.removeItem('roleAliases');

    await this.fetchOrganization();
    await this.fetchBusinessUnits();
    await this.fetchAttributes();
    await this.fetchManagers();
    await this.fetchRoleAliases();
  };

  resetCurrent() {
    this.currentOrganization = null;
  }

  toggleSelectedBusinessUnit = (walletId: BusinessUnit['id']) => {
    if (this.selectedBusinessUnits.includes(walletId)) {
      this.selectedBusinessUnits = this.selectedBusinessUnits.filter((id) => id !== walletId);
    } else {
      this.selectedBusinessUnits.push(walletId);
    }

    window.localStorage.setItem('selectedBusinessUnits', JSON.stringify(this.selectedBusinessUnits));
  };

  subscribeToNotifications() {
    if (!this.currentId) {
      return;
    }

    if (this.notificationsEventSource) {
      this.notificationsEventSource.close();
      this.notificationsEventSource = null;
    }

    // TODO Desactiver pour le moment car trop de logs
    /*this.notificationsEventSource = subscribeToMercure<OrganizationNotifications>(
      `notifications/${this.currentId}`,
      (data: OrganizationNotifications) => {
        this.notifications = data;
      },
    );*/
  }
}

export default new OrganizationStore();
export { OrganizationStore };
