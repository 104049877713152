import './index.scss';
import { observer } from 'mobx-react';
import getContactRole from 'utils/getContactRole';
import organizationStore from 'stores/Organization';

type Props = {
  missingRoles: string[] | null,
};

const HistoryStepContactsMissingRoles = ({ missingRoles }: Props): JSX.Element => {
  const { contactRoles } = organizationStore.currentOrganization!;

  if (!missingRoles || missingRoles.length === 0) {
    return (
      <div className="HistoryStepContactMissingRoles">-</div>
    );
  }

  return (
    <div className="HistoryStepContactMissingRoles">
      <div className="HistoryStepContactMissingRoles__missing-roles">
        {missingRoles.map((missingRole, index) => (
          <span
            key={missingRole}
            className="HistoryStepContactMissingRoles__missing-roles__item"
          >
            {(index ? ', ' : '')}{getContactRole(missingRole, contactRoles)}
          </span>
        ))}
      </div>
    </div>
  );
};

export default observer(HistoryStepContactsMissingRoles);
