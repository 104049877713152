import type { TFunction } from 'i18next';
import type { EavConstructor, OrganizationEAVs, OrganizationEavs, User } from 'types/models';
import { EAVType, EavCategory, SelectorsRefDateType } from 'types/models';
import organizationStore from 'stores/Organization';

/**
 * Retourne un label traduit pour une valeur donnée.
 *
 * @param value La valeur dont on veut le label
 * @param eavs Les EAVs de l'organisation pour quand la valeur est un client ou un débit
 * @param t La fonctione de traduction
 * @returns Le label (mot ou phrase) traduit pour la valeur donnée.
 */
const getLabelFromIdentifier = (value: string, eavs: OrganizationEavs | null, t: TFunction) => {
  if ((!value || (!value.startsWith('{EAV_') && !value.startsWith('{date_'))) || !eavs) {
    return value;
  }
  if (value.startsWith('{EAV_CLIENT')) {
    const eavName = value.substring(12, value.length - 1);
    return eavs.client.find(({ identifier }) => identifier === eavName)?.label || value;
  }
  if (value.startsWith('{EAV_DEBIT')) {
    const eavName = value.substring(16, value.length - 1);
    return eavs.debit.find(({ identifier }) => identifier === eavName)?.label || value;
  }

  if (value.startsWith('{date_') && value.length > 15) {
    const period = (value[6] === '+') ? '+' : '-';
    const slicedAttribute = value.slice(7, -5).split(`months${period}`);
    if (slicedAttribute.length === 2) {
      return [
        (period === '+' ? t('common:within') : t('common:prior-to')),
        slicedAttribute[0],
        t('common:months'),
        t('common:and'),
        slicedAttribute[1],
        t('common:days'),
      ].join(' ');
    }
  }
  return value;
};

/**
 * Transforme les IDS de gestionnaires en noms de gestionnaires
 *
 * @param value Les Ids des gestionnaire (dans une string, séparées par des virgules)
 * @returns Les noms des gestionnaires (dans une string, séparées par des virgules)
 */
const getManagersNames = (value: string, allManagers: User[] | null | undefined): string => {
  const names = value.split(',')
    .map((managerId) => {
      const manager = allManagers?.find(({ id }: User) => id.toString() === managerId);
      return `${manager?.firstName} ${manager?.lastName}`;
    })
    .filter((name) => name.length > 1);
  return names.join(', ');
};

/**
 * Obtenir le mapping des EAVs
 *
 * @returns mapping des EAVs
 */
const eavMaping = (): { [key: string]: EavConstructor[] } => {
  const { attributes } = organizationStore;
  const map: { [key: string]: EavConstructor[] } = {};

  Object.values(EavCategory).forEach((key) => {
    const dateEAV = (attributes as OrganizationEAVs)[key]?.filter(({ type }) => type === EAVType.DATE) ?? [];
    map[`EAV_${key.toUpperCase()}`] = dateEAV;
  });

  return map;
};

/**
 * Obtenir le label Eav à partir d'un identifiant donné
 *
 * @param identifier identifiant de EAV
 * @returns idenlabeltifiant de EAV
 */
const getEavLabel = (identifier: string) => {
  const { attributes } = organizationStore;
  return Object.values(attributes)
    .flatMap((eavConstructors) => eavConstructors)
    .find((eav) => eav.identifier === identifier)?.label;
};

/**
 * obtenir le texte de la date de référence
 *
 * @param value valeur d'origine (api)
 * @returns texte de la date de référence
 */
const getReferenceDate = (value: string) => {
  let attribute, date, eav;

  if (value.startsWith('date_manual_')) {
    attribute = 'DATE_MANUAL';
    date = new Date(value.replace('date_manual_', ''));
  } else if (value.startsWith('date_eav_')) {
    const identifier = value.replace('date_eav_', '');

    const foundEav = Object.entries(eavMaping()).find(([, eavs]) =>
      eavs.some((attr) => attr.identifier === identifier),
    );

    if (foundEav) {
      attribute = foundEav[0];
    }

    eav = identifier;
  } else {
    attribute = value.toUpperCase();
  }

  return { attribute: attribute as SelectorsRefDateType, date, eav };
};

export { getLabelFromIdentifier, getManagersNames, eavMaping, getReferenceDate, getEavLabel };
