import { PhaseId } from 'types/models';

const getI18nPhase = (phaseId: string): string => {
  const phasesI18n = {
    [PhaseId.UPCOMING]: 'phases:upcoming',
    [PhaseId.PENDING]: 'phases:near',
    [PhaseId.ALMOST_DUE]: 'phases:in-progress',
    [PhaseId.DUE]: 'phases:overdue',
    [PhaseId.OVERDUE]: 'phases:late',
    [PhaseId.LATE]: 'phases:suffering',
    [PhaseId.DEFAULT]: 'phases:unpaid',
    [PhaseId.INACTIVE]: 'phases:inactive',
  };

  if (!Object.keys(phasesI18n).includes(phaseId)) {
    return 'phases:unknown-phase';
  }

  return phasesI18n[phaseId as PhaseId];
};

export default getI18nPhase;
