import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { DebitType, type Debit, type EAVValue } from 'types/models';
import useFetch from 'hooks/useFetch2';
import organizationStore from 'stores/Organization';
import formatIntlDate from 'utils/formatIntlDate';
import { getAllEavsValues } from 'utils/getCustomerEavs';
import { FetchEAVValues } from 'api/debits';
import ErrorMessage from 'components/ErrorMessage';
import apiDebits from 'api/debits';

type Props = {
  data: Debit,
};

const DebitSideInfos = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { attributes: organizationAttributes } = organizationStore;

  const {
    data: eavsDebit,
    error,
  } = useFetch<FetchEAVValues, EAVValue[]>(
    {
      cacheKey: 'debitAllEavs',
      id: data.id,
    },
    apiDebits.fetchEavs,
    { enabled: !!data.id && data.type !== DebitType.SETTLEMENT },
  );

  const attributes = useMemo(() => (
    getAllEavsValues(eavsDebit, organizationAttributes.debit)
  ), [eavsDebit, organizationAttributes.debit]);

  return (
    <dl className="DebitSideInfos">
      {error && <ErrorMessage error={error} />}
      <div className="DebitSideInfos__item">
        <dt className="DebitSideInfos__label">
          {t('debits:issue-date')}
        </dt>
        <dd className="DebitSideInfos__value">
          {formatIntlDate(data.issueAt, 'dateLong')}
        </dd>
      </div>
      {attributes.map(({ identifier, label, value }) => (
        <div className="DebitSideInfos__item" key={identifier}>
          <dt className="DebitSideInfos__label">
            {label}
          </dt>
          <dd className="DebitSideInfos__value">
            {value ?? '-'}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default observer(DebitSideInfos);
