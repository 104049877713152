import './index.scss';
import { useCallback, useState } from 'react';
import classnames from 'classnames';
import type { DefaultTFuncReturn } from 'i18next';
import type { Accept } from 'react-dropzone';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Alert from 'components/Alert';
import Icon from '../Icon';

type Props = {
  onDrop(files: File[]): void,
  accept: Accept,
  hasAddedFiles?: boolean,
  maxFiles?: number,
  customInstructions?: DefaultTFuncReturn,
  withInstructions?: boolean,
  filename?: string,
};

const Dropzone = (props: Props): JSX.Element => {
  const {
    onDrop,
    accept,
    hasAddedFiles,
    maxFiles,
    customInstructions,
    withInstructions = true,
    filename,
  } = props;

  const { t } = useTranslation();
  const [hasRejected, setHasRejected] = useState<boolean>(false);

  const handleDropRejected = useCallback(() => {
    if (!hasRejected) {
      setHasRejected(true);
    }
  }, [hasRejected]);

  const handleDrop = useCallback((files: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length === 0 && hasRejected) {
      setHasRejected(false);
    }
    onDrop(files);
  }, [hasRejected, onDrop]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    useFsAccessApi: true,
    accept,
    onDropRejected: handleDropRejected,
    maxFiles,
  });

  const className = classnames('Dropzone__content', {
    'Dropzone__content--has-added-files': hasAddedFiles || maxFiles === 1,
  });

  return (
    <>
      {hasRejected && <Alert variant="error">{t('imports:wrong-file-format')}</Alert>}
      <div className="Dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={className}>
          {(withInstructions && !isDragActive && !hasAddedFiles) && (
            <p className="Dropzone__content__text">
              {customInstructions || t('common:dropzone.content')}
            </p>
          )}
          {!filename && <Icon name="arrow-up-circle" className="Dropzone__content__icon" />}
          {filename && (
            <p className="Dropzone__content__filename">
              {filename}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Dropzone;
