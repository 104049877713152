import type { IOptions } from 'sanitize-html';
import sanitizeHtml from 'sanitize-html';

const sanitizeOptions: IOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(
    ['img'],
  ),
  allowedAttributes: {
    div: ['style'],
    p: ['style'],
    a: ['href', 'name', 'target'],
    img: ['src', 'style'],
    span: ['style'],
    table: ['style'],
    tr: ['style'],
    th: ['style', 'bgcolor'],
    td: ['style', 'bgcolor'],
  },
};

export default sanitizeOptions;
