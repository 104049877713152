/**
 * Récupère l'URL de la ressource d'API pour modifier le contactRole.
 *
 * @param id L'ID du contactRole
 * @returns URL du PUT.
 */
const updateUrl = (id: number) => `organization_contact_roles/${id}`;

/**
 * Récupère l'URL de la ressource API pour mapper une association de contactRole.
 *
 * @param id L'ID du client
 * @returns URL de l'association.
 */
const resourceUrl = (id: string | number) => `/api/organization_contact_roles/${id}`;

export default { updateUrl, resourceUrl };
