import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';

type Props = {
  name?: string,
  defaultValue?: boolean,
  value?: boolean,
  label?: string | null,
  onChange?(value: boolean): void,
  className?: string,
};

const FormSwitch = (props: Props): JSX.Element => {
  const { name, defaultValue, value, label, onChange, className } = props;
  const [isOn, setIsOn] = useState<boolean>(!!defaultValue);

  useEffect(() => {
    if (value !== undefined) {
      setIsOn(value);
    }
  }, [value]);

  const handleClick = useCallback(() => {
    setIsOn(!isOn);

    if (onChange) {
      onChange(!isOn);
    }
  }, [onChange, isOn]);

  const classNames = classnames('FormSwitch', className, {
    'FormSwitch--active': (value !== undefined) ? value : isOn,
  });

  return (
    <div className={classNames}>
      <button type="button" className="FormSwitch__button" onClick={handleClick}>
        <span className="FormSwitch__toggle">
          <Icon name="check" className="FormSwitch__toggle__icon" />
        </span>
      </button>
      {!!label && <div className="FormSwitch__label">{label}</div>}
      {!!name && <input type="hidden" value={isOn ? '1' : '0'} name={name} />}
    </div>
  );
};

export default FormSwitch;
