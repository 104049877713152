import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import ItemLink from './ItemLink';

const SettingsNav = (): JSX.Element => {
  const { t } = useTranslation();
  const { user: authUser } = authStore;
  const { currentOrganization } = organizationStore;

  const hasOrganizations = (
    authUser?.organizations
    && authUser.organizations.length !== 0
    && !!currentOrganization
  );

  const navLinks = useMemo(() => ([
    {
      title: t('users:nav.title.information'),
      links: [
        {
          to: '/preferences',
          label: t('users:nav.link.my-profile'),
        },
        {
          to: '/preferences/organization',
          label: t('users:nav.link.organization'),
          disabled: !hasOrganizations,
        },
        {
          to: '/preferences/collaborators',
          label: t('users:nav.link.collaborators'),
          disabled: !hasOrganizations,
        },
        {
          to: '/preferences/customization',
          label: t('users:nav.link.customization'),
          disabled: !hasOrganizations,
        },
        {
          to: '/preferences/api',
          label: t('users:nav.link.api'),
        },
      ],
    },
    {
      title: t('users:nav.title.automation'),
      links: [
        {
          to: '/preferences/templates',
          label: t('users:nav.link.models'),
          disabled: !hasOrganizations,
        },
        {
          to: '/preferences/attributes',
          label: t('users:nav.link.attributes'),
          disabled: !hasOrganizations,
        },
        {
          to: '/preferences/scenarios',
          label: t('users:nav.link.scenarios'),
          disabled: !hasOrganizations,
        },
        {
          to: '/preferences/selectors',
          label: t('users:nav.link.scenario-selectors'),
          disabled: !hasOrganizations,
        },
      ],
    },
  ]), [t, hasOrganizations]);

  return (
    <div className="SettingsNav">
      {navLinks.map(({ title, links }) => (
        <div className="SettingsNav__item" key={title}>
          <div className="SettingsNav__item__title">{title}</div>
          <ul className="SettingsNav__item__list">
            {links.map((link) => (
              <ItemLink key={link.to} link={link} />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default observer(SettingsNav);
