import type { TFunction } from 'i18next';
import type { StepAction } from 'types/models';
import { StepActionEntity } from 'types/models';
import parseIsoDate from 'utils/parseIsoDate';
import formatIntlDate from 'utils/formatIntlDate';

const getI18nHistoryStepDate = (
  t: TFunction,
  data: StepAction,
  shouldChangePlannedToValidated: boolean = false,
): string => {
  const { entity } = data;

  const getIntlFormat = (dateReference: string) => {
    const yearNow = new Date().getFullYear();
    return parseIsoDate(dateReference)?.getFullYear() !== yearNow ? 'dayMonthYearShort' : 'dayMonthShort';
  };

  if (data.cancel) {
    // - Tâche annulée
    if (entity === StepActionEntity.TASK) {
      return t('common:task-remove-at-date', {
        date: formatIntlDate(data.cancel.at, getIntlFormat(data.cancel.at)),
      });
    }

    // - Relance annulée
    return t('common:remove-at-date', {
      date: formatIntlDate(data.cancel.at, getIntlFormat(data.cancel.at)),
    });
  }

  // - Relance mise en silencieux
  if (data.mute) {
    return t('common:done-at-date', {
      date: formatIntlDate(data.mute.at, getIntlFormat(data.mute.at)),
    });
  }

  // - Relance n'étant plus en silencieux
  if (data.unmute) {
    return t('common:done-at-date', {
      date: formatIntlDate(data.unmute.at, getIntlFormat(data.unmute.at)),
    });
  }

  if (entity === StepActionEntity.REMINDER) {
    const isValidated = (
      shouldChangePlannedToValidated
      && data.doneAt === null
      && data.validation
    );

    // - Relance validée
    if (isValidated) {
      return t('common:validated-at-date', {
        date: formatIntlDate(data.validation?.at, getIntlFormat(data.validation?.at || '')),
      });
    }

    // - Relance envoyée
    if (data.doneAt) {
      return t('common:sent-at-date', {
        date: formatIntlDate(data.doneAt, getIntlFormat(data.doneAt)),
      });
    }
  }

  // - Tâche réalisée
  if (entity === StepActionEntity.TASK && data.validation) {
    return t('common:task-done-at-date', {
      date: formatIntlDate(data.validation.at, getIntlFormat(data.validation.at)),
    });
  }

  if ([StepActionEntity.REMINDER, StepActionEntity.TASK].includes(entity)) {
    return t('common:planned-at-date', {
      date: formatIntlDate(data.at?.at, getIntlFormat(data.at?.at)),
    });
  }

  return formatIntlDate(data.at?.at, getIntlFormat(data.at?.at));
};

export default getI18nHistoryStepDate;
