import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsType } from 'types/models';
import PageList from 'components/PageList';
import FormSelect from 'components/FormSelect';
import Activity from './Activity';
import Risk from './Risk';
import Finance from './Finance';

const Analysis = (): JSX.Element => {
  const { t } = useTranslation();

  const [category, setCategory] = useState(AnalyticsType.ACTIVITY);

  const handleChange = useCallback((name: string, newValue: string | null) => {
    setCategory(newValue as AnalyticsType);
  }, []);

  return (
    <PageList title={t('analysis:title')} className="Analysis">
      <FormSelect
        name="analysisCategory"
        value={category}
        withClearButton={false}
        onSelect={handleChange}
        placeholder={t('analysis:select-category')}
        selectOptions={[
          { label: t('analysis:categories.finance'), value: AnalyticsType.FINANCE },
          { label: t('analysis:categories.activity'), value: AnalyticsType.ACTIVITY },
          { label: t('analysis:categories.risk'), value: AnalyticsType.RISK },
        ]}
      />
      {category === AnalyticsType.FINANCE && (
        <Finance />
      )}
      {category === AnalyticsType.ACTIVITY && (
        <Activity />
      )}
      {category === AnalyticsType.RISK && (
        <Risk />
      )}
    </PageList>
  );
};

export default Analysis;
