import './index.scss';
import React from 'react';
import classnames from 'classnames';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';

interface Props {
  variant?: 'default' | 'searching',
  text?: DefaultTFuncReturn,
  hasNoText?: boolean,
  isOverlay?: boolean,
}

const Loading: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    variant = 'default',
    text = undefined,
    hasNoText = false,
    isOverlay = false,
  } = props;

  let message = t('common:loading');
  if (variant === 'searching') {
    message = t('common:searching');
  }

  if (text) {
    message = text;
  }

  const className = classnames('Loading', {
    'Loading--overlayed': isOverlay,
    'Loading--no-text': hasNoText,
  });

  return (
    <div className={className}>
      <div className="Loading__content">
        <Icon name="loading" className="Loading__icon" spin />
        {!hasNoText && (
          <span className="Loading__text">
            {message}...
          </span>
        )}
      </div>
    </div>
  );
};

export default Loading;
