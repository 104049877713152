import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import InputGroup from 'components/InputGroup';
import FormControl from 'components/FormControl';
import Loading from 'components/Loading';
import Icon from 'components/Icon';

type Props = {
  placeholder: string,
  minCharacters?: number,
  isLoading?: boolean,
  disabled?: boolean,
  initialSearchTerm?: string,
  onChange(searchTerm: string | null): void,
  onFocus?(options: { isLongEnough: boolean }): void,
  onReset?(): void,
};

const SearchInput = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const tooltipTarget = useRef(null);
  const {
    placeholder,
    minCharacters = 3,
    isLoading = false,
    disabled = false,
    onChange,
    onFocus,
    onReset,
    initialSearchTerm,
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm || '');
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleFocus = useCallback(
    (direction: 'in' | 'out') => () => {
      if (onFocus && direction === 'in') {
        onFocus({ isLongEnough: searchTerm.length >= minCharacters });
      }
    },
    [onFocus, searchTerm, minCharacters],
  );

  const handleChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      if (onReset && value.length === 0) {
        onReset();
      }
    },
    [onReset],
  );

  useDebouncedEffect(() => {
    setShowTooltip(searchTerm.length > 0 && searchTerm.length < minCharacters);
    if (searchTerm.length >= minCharacters) {
      onChange(searchTerm);
    } else {
      onChange(null);
    }
  }, 600, [searchTerm]);

  useEffect(() => {
    if (initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
    }
  }, [initialSearchTerm]);

  return (
    <InputGroup
      className="SearchInput"
      helper={isLoading ? <Loading hasNoText /> : <Icon name="search" />}
    >
      <FormControl
        ref={tooltipTarget}
        type="search"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        onFocus={handleFocus('in')}
        onBlur={handleFocus('out')}
        disabled={disabled}
      />
      {showTooltip && (
        <div className="SearchInput__tooltip">
          {t('common:search-bar.type-min-characters', { count: minCharacters })}
        </div>
      )}
    </InputGroup>
  );
};

export default SearchInput;
