import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { customAlphabet } from 'nanoid';
import {
  HistoryActivityTag,
  HistoryActivityType,
  StepActionEntity,
  type Debit,
  type HistoryActivity,
} from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';
import DebitInfos from './Infos';
import DebitActionsCollapse from './Actions';

type Props = {
  debit: Debit;
  onActionDone(message: string): void;
  onActionError(message: string): void;
  reloadTick: number;
};

const DebitContent = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { debit, onActionDone, onActionError } = props;
  const { currency } = organizationStore.currentOrganization!;

  const historyActivities = useMemo(() => {
    const history: HistoryActivity[] = [];
    const activity = {
      entity: StepActionEntity.ACTIVITY,
      tag: HistoryActivityTag.UNKNOWN,
      content: '',
      timestamp: '',
    };

    const randomID = () => (
      parseInt(customAlphabet('1234567890')(), 10)
    );

    debit.payments?.forEach((payment) => {
      history.push({
        ...activity,
        id: randomID(),
        type: HistoryActivityType.CREDIT,
        options: [],
        translation: t('debits:payment-log', {
          reference: payment.creditReference,
          amount: formatIntlNumber(payment.paymentAssignedAmount, currency),
        }),
        loggedAt: payment.paymentPaidAt || '',
      });
    });

    history.push({
      ...activity,
      id: randomID(),
      options: [],
      type: HistoryActivityType.DEBIT,
      translation: t('debits:creation-log'),
      loggedAt: debit.createdAt,
    });

    return history;
  }, [debit, currency, t]);

  return (
    <div className="DebitContent">
      <DebitInfos debit={debit} onActionDone={onActionDone} />
      <DebitActionsCollapse
        historyActivity={historyActivities}
        onActionDone={onActionDone}
        onActionError={onActionError}
      />
    </div>
  );
};

export default observer(DebitContent);
