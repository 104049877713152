import type { Collection } from 'types/api';
import type { Organization, RoleUser } from 'types/models';
import requester from 'utils/requester';

export type FetchAllParams = { id: Organization['id'] | undefined };

/**
 * Récupère une liste des Roles.
 *
 * @param params Paramètres de la requête (organization ID).
 * @returns Une collection de Roles.
 */
const all = async ({ id }: FetchAllParams): Promise<RoleUser[]> => {
  if (!id) {
    throw new Error('FetchAllRoles: Missing organization ID.');
  }

  const { data } = await requester.get<Collection<RoleUser>>('roles');
  return data['hydra:member'];
};

/**
 * Récupère l'URL de la ressource d'API pour mapper une association de roles.
 *
 * @param id L'ID du role
 * @returns URL du role.
 */
const resourceUrl = (id: string | number) => `/api/roles/${id}`;

export default { all, resourceUrl };
