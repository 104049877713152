import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StepAction } from 'types/models';
import { HistoryStepStatus } from 'types/models';
import getI18nHistoryStepDate from './utils/getI18nHistoryStepDate';
import getI18nHistoryStepExtraInfo from './utils/getI18nHistoryStepExtraInfo';

type Props = {
  data: StepAction,
  isWaiting: boolean,
  isMissingContact: boolean,
  incompleteContactRoles: boolean,
  isFailedSent: boolean,
  failSentReason: string | null,
  isEvent: boolean,
  showRelativeDateRef: boolean,
  shouldChangePlannedToValidated?: boolean,
};

const HistoryStepDate = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    data,
    isMissingContact,
    incompleteContactRoles,
    isFailedSent,
    failSentReason,
    isEvent,
    showRelativeDateRef = false,
    shouldChangePlannedToValidated = false,
  } = props;
  const { status, errorMessage: error, cancel } = data;

  const i18nDate = useMemo(() => (
    getI18nHistoryStepDate(t, data, shouldChangePlannedToValidated)
  ), [data, shouldChangePlannedToValidated, t]);

  const offsetDisplay = useMemo(() => {
    if (!data.at) {
      return null;
    }

    const { offset } = data.at;
    if (!offset) {
      return offset === 0
        ? `(${t('days-first-letter')})`
        : null;
    }
    return (offset > 0)
      ? `(${t('days-first-letter')}+${offset})`
      : `(${t('days-first-letter')}−${Math.abs(offset)})`;
  }, [data, t]);

  const errorMessage = useMemo(() => {
    if (
      error !== null &&
      [HistoryStepStatus.ERROR, HistoryStepStatus.WARNING].includes(status)
    ) {
      return error.split('\n').map( (item, key) =>
        <div key={key}>{item}</div>,
      );
    }

    if (isFailedSent && !isMissingContact) {
      return failSentReason ?? t('common:sent-fail');
    }

    if ((isMissingContact || incompleteContactRoles) && !isEvent && cancel === null) {
      return incompleteContactRoles ? t('common:incomplete-contact') : t('common:missing-contact');
    }

    return null;
  }, [
    isMissingContact,
    incompleteContactRoles,
    isFailedSent,
    failSentReason,
    isEvent,
    status,
    error,
    cancel,
    t,
  ]);

  const extraInfos = useMemo(() => (
    getI18nHistoryStepExtraInfo(t, data)
  ), [t, data]);

  return (
    <div className="HistoryStepDate">
      <p className="HistoryStepDate__date">
        {i18nDate} {showRelativeDateRef ? offsetDisplay : null}
      </p>
      {errorMessage && (
        <div className="HistoryStepDate__error-message HistoryStepDate__error-message--danger">
          {errorMessage}
        </div>
      )}
      {(!errorMessage && extraInfos !== null) && (
        <div className="HistoryStepDate__info">
          {extraInfos}
        </div>
      )}
    </div>
  );
};

export default HistoryStepDate;
