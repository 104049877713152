import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { HistoryStepActionbWebHook } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import dateIsToday from 'utils/dateIsToday';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';

type Props = {
  data: HistoryStepActionbWebHook,
};

const HomeHistoryItem = ({ data }: Props): JSX.Element => {
  const { doneAt, name, client } = data;
  const { denomination, identifier } = client;
  const { t } = useTranslation();

  const dateDisplay = useMemo<string>(() => {
    if (!doneAt) {
      return '';
    }
    if (dateIsToday(doneAt)) {
      return t('common:today-shortened');
    }
    if (dateIsLastTwoDays(doneAt)) {
      return t('common:yesterday-shortened');
    }
    return formatIntlDate(doneAt, 'dayMonthShort');
  }, [doneAt, t]);

  return (
    <li className="HomeHistoryItem">
      <div className="HomeHistoryItem__date">
        {dateDisplay}
      </div>
      <div className="HomeHistoryItem__content">
        <div className="HomeHistoryItem__content__title">
          {name}
        </div>
        <div className="HomeHistoryItem__content__data">
          <Link to={`/customer/${client.id}/summary`} className="HomeHistoryItem__content__link">
          {denomination}{identifier !== denomination ? ` (${identifier})` : ''}
          </Link>
        </div>
      </div>
    </li>
  );
};

export default HomeHistoryItem;
