import { AxiosResponse } from 'axios';
import type { Collection } from 'types/api';
import Misc from 'types/misc';
import type { Organization, Selector } from 'types/models';
import requester from 'utils/requester';

export type FetchAllParams = {
  organization: Organization['id'] | undefined,
  filters: Misc.Filter[],
};

const getMappingFromFormToApi = (name: string) => {
  const map = {
    group: 'dunningScenario.group.id',
    search: 'label',

  } as Record<string, string>;

  return map[name] ?? name;
};

const all = async ({ organization, filters }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllSelectors: Missing organization.');
  }

  const queryData = new URLSearchParams();
  queryData.append('order[sortOrder]', 'asc');

  const group = filters?.find((o) => o.name === 'group');
  if (group && group.value === 'debt') {
    queryData.append('exists[dunningScenario.group]', 'false');
  }

  if (filters && filters.length > 0) {
    filters
      .filter(({ value }) => value !== '' && value !== 'debt')
      .forEach(({ name, value }) => {
        queryData.append(
          getMappingFromFormToApi(name),
          value as string,
        );
      });
  }

  const { data } = await requester.get<Collection<Selector>>(
    `organizations/${organization}/selectors?${queryData.toString()}`,
  );
  return data['hydra:member'];
};

/**
 * URL de la ressource d'API pour la création d'un Selector (POST).
 */
const createUrl = 'selector_definitions';

/**
 * Récupère l'URL de la ressource d'API pour modifier le selector.
 *
 * @param id L'ID du selector
 * @returns URL du PUT.
 */
const updateUrl = (id: Selector['id']) => `selector_definitions/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour supprimer le selector.
 *
 * @param id L'ID du selector
 * @returns URL du DELETE.
 */
const deleteUrl = (id: Selector['id']) => `selector_definitions/${id}`;

/**
 * URL de la ressource d'API pour importer un fichier yaml.
 */
const importUrl = 'selector_definitions/import';

/**
 * Récupère le template yaml avec les données actuelles
 *
 * @returns le template yaml
 */
const getTemplate = async (reference: Organization['reference']): Promise<AxiosResponse<Blob>> => (
  requester.get(`selector_definitions/export?organizations=${reference}`, { responseType: 'blob' })
);

export default { all, createUrl, updateUrl, deleteUrl, getTemplate, importUrl };
