import './index.scss';
import classnames from 'classnames';
import { HeaderGroup } from 'react-table';
import { SelectionHookResult } from 'hooks/useSelection';
import FormCheck from 'components/FormCheck';
import SortLabel from './SortLabel';

type Props<DataType extends object = {}> = {
  headerGroups: HeaderGroup<DataType>[],
  select: SelectionHookResult,
  withSelectAll: boolean,
};

const DataTableHead = <DataType extends object = {}>(
  props: Props<DataType>,
): JSX.Element | null => {
  const { headerGroups, select, withSelectAll } = props;
  const { toggleSelectAll, isAllSelected } = select;

  return (
    <thead className="DataTableHead">
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {withSelectAll && (
            <th role="columnheader" className="DataTableHead__cell">
              <FormCheck
                onChange={toggleSelectAll}
                checked={isAllSelected}
              />
            </th>
          )}
          {headerGroup.headers.map((column) => {
            // @ts-ignore (car le typage de `react-table` est incomplet...)
            const { title, ...sortByProps } = column.getSortByToggleProps();
            return (
              <th
                {...column.getHeaderProps(sortByProps)}
                className={classnames('DataTableHead__cell', {
                  'DataTableHead__cell--sortable': !column.disableSortBy,
                  'DataTableHead__cell--sorted': column.isSorted,
                })}
              >
                {column.render('Header')}
                {!column.disableSortBy && (
                  <SortLabel<DataType>
                    isSorted={column.isSorted}
                    isSortedDesc={column.isSortedDesc}
                    sortType={column.sortType}
                  />
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export default DataTableHead;
