import './index.scss';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import apiScenarios from 'api/scenarios';
import organizationStore from 'stores/Organization';
import DropdownActions from 'components/DropdownActions';
import Button from 'components/Button';
import Icon from 'components/Icon';
import useConfirm from 'components/Confirm/useConfirm';
import Confirm from 'components/Confirm';
import useApiRequest from 'hooks/useApiRequest';
import type { Scenario } from 'types/models';
import ModalEditScenario from './ModalEdit';
import SettingsPlansPlanActionButtons from './Buttons';
import ScenarioDetails from './Details';
import AddActionButton from './AddActionButton';

type Props = {
  scenario: Scenario,
  detailsOpened: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onShowModalDuplicatePlan(planName: string): void,
  onToggleDetails(): void,
};

const SettingsScenario = (props: Props): JSX.Element => {
  const {
    scenario,
    detailsOpened,
    onActionDone,
    onActionError,
    onToggleDetails,
    onShowModalDuplicatePlan,
  } = props;
  const { name, identifier, id } = scenario;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const { remove } = useApiRequest();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    if (!currentOrganization) {
      return;
    }

    const response = await remove(apiScenarios.deleteUrl(id));
    // la reponse est egale a empty string si ca a reussi, null sinon
    if (response !== null) {
      onActionDone(t('plans:toast.success.deleted-plan', { name }));
      return;
    }
    onActionError(t('plans:toast.error.delete-plan', { name }));
  }, [hideConfirm, currentOrganization, remove, id, onActionError, t, name, onActionDone]);

  const handleActionDone = useCallback((message: string) => {
    onActionDone(message);
  }, [onActionDone]);

  const [showModalEditScenario, hideModalEditScenario] = useModal(() => (
    <ModalEditScenario
      scenario={scenario}
      onClose={hideModalEditScenario}
      onActionDone={handleActionDone}
      onActionError={onActionError}
    />
  ), [scenario, handleActionDone, onActionError]);

  const showModalDeletePlan = useCallback(() => {
    showConfirm(t('plans:remove'), t('plans:confirm-remove'));
  }, [showConfirm, t]);

  const handleDuplicate = useCallback(() => {
    onShowModalDuplicatePlan(identifier);
  }, [identifier, onShowModalDuplicatePlan]);

  const actions = useMemo(() => (
    SettingsPlansPlanActionButtons(t, showModalEditScenario, showModalDeletePlan, handleDuplicate)
  ), [handleDuplicate, showModalDeletePlan, showModalEditScenario, t]);

  return (
    <div className="SettingsScenario">
      <div className="SettingsScenario__header">
        <div className="SettingsScenario__header__titles">
          <h4 className="SettingsScenario__header__titles__name">{name}</h4>
          <div className="SettingsScenario__header__titles__subtitles">
            <p className="SettingsScenario__header__titles__subtitles__identifier">{identifier}</p>
          </div>
        </div>
        <div className="SettingsScenario__header__buttons">
          {detailsOpened && (
            <AddActionButton
              onActionDone={handleActionDone}
              onActionError={onActionError}
              scenario={scenario}
            />
          )}
          <DropdownActions actions={actions} />
          <Button onClick={onToggleDetails} variant="light" className="SettingsScenario__header__buttons__button">
            <Icon name={detailsOpened ? 'caret-up' : 'caret-down'} />
          </Button>
        </div>
      </div>
      {detailsOpened && (
        <div className="SettingsScenario__details">
          <ScenarioDetails
            onActionDone={handleActionDone}
            onActionError={onActionError}
            scenario={scenario}
          />
        </div>
      )}
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default SettingsScenario;
