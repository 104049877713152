import './index.scss';
import { useMemo, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import type { Column, Row } from 'react-table';
import { EavCategory } from 'types/models';
import type { EavConstructor } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import apiAttributes from 'api/attributes';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import SortableList from 'components/SortableList';
import getEavDescriptionLabel from 'utils/getEavDescriptionLabel';
import AttributesColumns from '../Columns';

type Props = {
  type: EavCategory,
  isLoading: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const AttributesListing = ({ onActionDone, onActionError, type, isLoading }: Props): JSX.Element => {
  const { currentOrganization, attributes } = organizationStore;
  const { type: organizationType } = currentOrganization!;
  const { ct, t } = useContextualTranslation(organizationType);
  const { put, isLoading: isReordering } = useApiRequest();
  const [renderTick, setRenderTick] = useState(1);

  const currentData = useMemo<EavConstructor[]>(() => {
    switch (type) {
      case EavCategory.CLIENT:
        return attributes.client;
      case EavCategory.CONTACT:
        return attributes.contact;
      case EavCategory.CREDIT:
        return attributes.credit;
      case EavCategory.DEBIT:
        return attributes.debit;
      default:
        return [];
    }
  }, [attributes, type]);

  const sortedData = useMemo<EavConstructor[]>(() => {
    const data = [...currentData];
    data.sort(({ label: labelA, position: posA }, { label: labelB, position: posB }) => {
      if (posA === posB) {
        return labelA.localeCompare(labelB);
      }
      return posA! - posB!;
    });
    return data;
  }, [currentData]);

  const handleActionDone = useCallback((message: string) => {
    setRenderTick((lastTick) => lastTick + 1);
    onActionDone(message);
  }, [onActionDone]);

  const columns = useMemo<Column<EavConstructor>[]>(() => (
    AttributesColumns(t, onActionError, handleActionDone, type)
  ), [t, onActionError, handleActionDone, type]);

  const getRowClassName = useCallback((row: Row<EavConstructor>) => {
    const { overviewDisplay } = row.original;
    if (overviewDisplay) {
      return 'AttributesListing__row AttributesListing__row--overview-displayed';
    }
    return 'AttributesListing__row';
  }, []);

  const handleReorder = useCallback(async (newDataList: EavConstructor[], newDragPosition: number) => {
    if (!currentOrganization) {
      return;
    }

    const saveData = {
      sortOrder: newDragPosition + 1,
    };

    const currentEAV = newDataList[newDragPosition];

    try {
      const response = await put(
        apiAttributes.updateUrl(currentEAV.id),
        saveData,
      );
      if (!response) {
        throw new Error();
      }
      onActionDone(t('attributes:toast.success.reordering'));
    } catch {
      onActionError(t('attributes:toast.error.reordering'));
    }
  }, [currentOrganization, put, onActionDone, t, onActionError]);

  const hasAttributes = sortedData.length > 0;

  return (
    <div className="AttributesListing">
      <p className="AttributesListing__description">
        {ct(getEavDescriptionLabel(type))}
      </p>
      {!hasAttributes && (
        <p className="AttributesListing__no-data">{ct('attributes:no-data')}</p>
      )}
      {hasAttributes && (
        <SortableList<EavConstructor, 'id'>
          key={renderTick}
          savedData={sortedData}
          columns={columns}
          onReorder={handleReorder}
          getRowClassName={getRowClassName}
          isSaving={isReordering || isLoading}
        />
      )}
      {hasAttributes && type === EavCategory.CLIENT && (
        <p className="AttributesListing__help">{t('attributes:you-can-reorder-to-sort')}</p>
      )}
    </div>
  );
};

export default observer(AttributesListing);
