import { ImportLineStatus } from 'types/models';

const getI18nReportLineStatus = (type: ImportLineStatus): string => {
  const i18nType = {
    [ImportLineStatus.RESULT_ERROR]: 'reports:line-status.result-error',
    [ImportLineStatus.RESULT_TO_PROCESS]: 'reports:line-status.result-to-process',
    [ImportLineStatus.RESULT_CREATED]: 'reports:line-status.result-created',
    [ImportLineStatus.RESULT_UPDATED]: 'reports:line-status.result-updated',
    [ImportLineStatus.SKIPPED]: 'reports:line-status.result-skipped',
  };

  return i18nType[type];
};

export default getI18nReportLineStatus;
