import './index.scss';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import { DebitStatus, DebitType, PhaseId } from 'types/models';
import type { Debit } from 'types/models';
import Badge from 'components/Badge';
import getI18nPhase from 'utils/getI18nPhase';
import getRelativeDaysCount from 'utils/getRelativeDaysCount';
import getI18nStatus from 'utils/getI18nStatus';
import GoBackButton from 'components/GoBackButton';
import Icon from 'components/Icon';

interface Props {
  debit: Debit | null | undefined,
}

const DebitHeader = ({ debit }: Props): JSX.Element => {
  const { t } = useTranslation();

  const dayDiff = useMemo<string>(() => {
    if (!debit) {
      return '';
    }
    const diffDay = 0 - getRelativeDaysCount(debit.dueAt);
    const diffDayText = diffDay > 0 ? `+${diffDay}` : diffDay;
    return `(J${diffDayText})`;
  }, [debit]);

  if (!debit) {
    return (
      <div className="DebitHeader">
        <div className="DebitHeader__main">
          <div className="DebitHeader__links"><GoBackButton /></div>
          <div className="DebitHeader__infos"><Loading /></div>
        </div>
      </div>
    );
  }

  const { reference, phase, status, muted, client, type } = debit;

  return (
    <div className="DebitHeader">
      <div className="DebitHeader__main">
        <div className="DebitHeader__links">
          <GoBackButton />
        </div>
        <div className="DebitHeader__infos">
            <h1 className="DebitHeader__infos__name">
              {reference}
            </h1>
            {type === DebitType.SETTLEMENT &&
              <Badge variant={PhaseId.LATE}>
                {t('debits:settlement-debit')}
              </Badge>
            }
            {type !== DebitType.SETTLEMENT && (
              (phase && status === DebitStatus.IN_PROGRESS) ? (
                <Badge variant={phase} title={t(getI18nPhase(phase))}>
                  {t(getI18nPhase(phase))} {dayDiff}
                </Badge>
              ) : (
                <Badge variant={status}>
                  {t(getI18nStatus(status))}
                </Badge>
              )
            )}
            {muted && (
              <div className="DebitHeader__infos__extra">
                <Icon name="bell-muted" />
              </div>
            )}
        </div>
      </div>
      <div className="DebitHeader__customer">
        <Link
          to={`/customer/${client.id}`}
          className="DebitHeader__customer__link"
        >
          <p className="DebitHeader__customer__name">
            {client.denomination}
          </p>
          <p className="DebitHeader__customer__reference">
            {client.identifier}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default DebitHeader;
