import './index.scss';
import classnames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { DefaultTFuncReturn } from 'i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import useMountedEffect from 'hooks/useMountedEffect';
import Misc from 'types/misc';
import Icon from 'components/Icon';
import Button from 'components/Button';

enum SearchInputFilter {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

type Props = {
  className?: string,
  placeholder?: DefaultTFuncReturn,
  currentFilters: Misc.Filter[],
  onChange(filter: Misc.Filter): void,
  onRemove?: (name: string) => void,
};

const SearchInputAdvanced = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { onChange, placeholder, className, currentFilters, onRemove } = props;
  const [selectedType, setSelectedType] = useState<SearchInputFilter | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [IsDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const listRef = useOnclickOutside(() => setIsDropdownOpen(false));
  const inputRef = useRef<HTMLInputElement>(null);

  useMountedEffect(() => {
    const includeFilter = currentFilters.find(({ name }) => name === SearchInputFilter.INCLUDE);
    const excludeFilter = currentFilters.find(({ name }) => name === SearchInputFilter.EXCLUDE);
    if (includeFilter && excludeFilter && selectedType && onRemove) {
      onRemove(
        selectedType === SearchInputFilter.INCLUDE ?
          SearchInputFilter.EXCLUDE : SearchInputFilter.INCLUDE,
      );
    }

    const defaultFilter = currentFilters.find(({ name }) =>
      name === SearchInputFilter.INCLUDE || name === SearchInputFilter.EXCLUDE,
    );

    if (!selectedValue && !selectedType) {
      setSelectedValue(defaultFilter?.value as string | null ?? null);

      if (defaultFilter?.name) {
        setSelectedType(defaultFilter?.name as SearchInputFilter);
      }
    }

    if (!defaultFilter?.name && !defaultFilter?.value) {
      setSelectedValue(null);
    }
  }, [currentFilters]);

  useMountedEffect(() => {
    if (selectedValue && selectedType) {
      onChange({ name: selectedType, value: selectedValue });
    }

    if (selectedType && inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, selectedType]);

  const handleAddValue = useCallback(() => {
    setSelectedValue(inputValue);
    setInputValue('');
    if (!selectedType) {
      setSelectedType(SearchInputFilter.INCLUDE);
    }
  }, [selectedType, inputValue]);

  const handleRemoveValue = useCallback(() => {
    const lastFilter = currentFilters.find(({ name }) =>
      name === SearchInputFilter.INCLUDE || name === SearchInputFilter.EXCLUDE,
    )?.name;

    if (lastFilter && onRemove) {
      onRemove(lastFilter);
    }

    setSelectedValue(null);
  }, [currentFilters, onRemove]);

  const showList = useMemo(
    () => (IsDropdownOpen && (!selectedType || !selectedValue)) || (!selectedType && selectedValue),
    [IsDropdownOpen, selectedType, selectedValue],
  );

  const classNames = classnames('SearchInputAdvanced', className, {
    'SearchInputAdvanced--focus': isFocus,
    'SearchInputAdvanced--typing': inputValue && !selectedValue,
  });

  return (
    <div className={classNames} ref={listRef}>
      <Icon name="search" />
      {selectedType &&
        <Button
          variant="light"
          className="SearchInputAdvanced__tag"
          onClick={() => { setSelectedType(null); }}
        >
          <span className="SearchInputAdvanced__tag__text">
            {t(`common:search-types.${selectedType}`)}
          </span>
          <Icon name="close-small" />
        </Button>
      }
      {selectedValue &&
        <Button
          variant="light"
          className="SearchInputAdvanced__tag"
          onClick={handleRemoveValue}
        >
          <span title={selectedValue} className="SearchInputAdvanced__tag__text">
            {selectedValue}
          </span>
          <Icon name="close-small" />
        </Button>
      }
      {!selectedValue &&
        <input
          onFocus={() => {
            setIsFocus(true);
            setIsDropdownOpen(true);
          }}
          onChange={(e) => {
            setInputValue(e.currentTarget.value);
          }}
          onBlur={() => {
            setIsFocus(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddValue();
            }
          }}
          className="SearchInputAdvanced__input"
          placeholder={placeholder as string}
          value={inputValue}
          ref={inputRef}
        />
      }
      {showList &&
        <ul className="SearchInputAdvanced__options">
          {!selectedType && !inputValue &&
            Object.values(SearchInputFilter).map((val) => (
              <li key={val} onClick={() => setSelectedType(val)}>
                {t(`common:search-types.${val}`)}
              </li>
            ))
          }
          {inputValue && !selectedValue &&
            <li onClick={handleAddValue}>
              {[
                t('common:searching'),
                t(`common:search-types.${selectedType ?? SearchInputFilter.INCLUDE}`).toLowerCase(),
                ':',
                inputValue,
              ].join(' ')}
            </li>
          }
        </ul>
      }
    </div>
  );
};

export default SearchInputAdvanced;
