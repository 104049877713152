type WindowType =
  Window
  & typeof globalThis
  & {
    analytics: {
      page: (name: string, param: {}) => void,
      identify: (id: number, params: {}) => void,
    }
  };

export const analyticsLogin = async (id: number, name: string, email: string) => {
  const { analytics } = (window as WindowType);
  if (!analytics) {
    return;
  }
  analytics.identify(id, {
    name,
    email,
  });
};

export const analyticsNavigate = (name: string, url: string, referrer: string) => {
  const { analytics } = (window as WindowType);
  if (!analytics) {
    return;
  }
  analytics.page(name, {
    url,
    referrer,
  });
};
