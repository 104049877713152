import './index.scss';
import { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { FetchAllHistoryForCustomerParams } from 'api/historySteps';
import apiHistorySteps from 'api/historySteps';
import useFetchPaginated from 'hooks/useFetchPaginated2';
import { StepActionTypeFilter } from 'types/models';
import type { ClientHistory } from 'types/models';
import customerContext from 'pages/Customer/customerContext';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import CustomerLastActivitiesItem from './Item';

const CustomerLastActivities = (): JSX.Element => {
  const { data: customer, reloadTick } = useContext(customerContext);
  const customerId = customer?.id;
  const { t } = useTranslation();
  const typeValues = [
    StepActionTypeFilter.REMINDER_SENT,
    StepActionTypeFilter.TASK_COMPLETED,
    StepActionTypeFilter.TASK_CANCELED,
    StepActionTypeFilter.REMINDER_CANCELED,
    StepActionTypeFilter.REMINDER_SENT_ERROR,
    'pay_request',
    'payment',
    'data',
  ].join(',');

  const {
    isLoading,
    data,
    refetch,
  } = useFetchPaginated<FetchAllHistoryForCustomerParams, ClientHistory>(
    {
      cacheKey: 'customerHistory',
      params: {
        customerId,
        page: 1,
        pageSize: 5,
        filtering: [{
          name: 'type',
          value: typeValues,
        }],
      },
    },
    apiHistorySteps.allHistoryForCustomer,
    { enabled: !!customerId, staleTime: 5000 },
  );

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const count = useMemo(() => data?.length ?? 0, [data]);

  return (
    <div className="CustomerLastActivities">
      <div className="CustomerLastActivities__header">
        <h3 className="CustomerLastActivities__title">
          {t('common:last-activities')}
        </h3>
        {isLoading && <Loading hasNoText />}
        {count > 0 && (
          <div className="CustomerLastActivities__count">{count}</div>
        )}
        <Link
          to={`/customer/${customerId}/history`}
          className="CustomerLastActivities__link"
        >
          {t('common:all-activities')}
          <Icon name="chevron-right-small" className="CustomerLastActivities__link__icon" />
        </Link>
      </div>
      {!isLoading && count === 0 && (
        <p className="CustomerLastActivities__no-data">
          {t('common:no-activity')}
        </p>
      )}
      {(data && data.length > 0) && (
        <ul className="CustomerLastActivities__list">
          {data.map((item) => (
            <CustomerLastActivitiesItem key={item.id} data={item} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default observer(CustomerLastActivities);
