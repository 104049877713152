import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Column } from 'react-table';
import { useModal } from 'react-modal-hook';
import type { Client, ScenarioSummary } from 'types/models';
import apiOrganization, { FetchClientParams } from 'api/organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import ScenarioDetails from 'components/ScenarioDetails';
import DataTable from 'components/DataTable';
import useFetch from 'hooks/useFetch2';
import MainDebtorsColumns from './Columns';

const MainDebtors = (): JSX.Element => {
  const { currentOrganization, selectedBusinessUnitsIdentifier } = organizationStore;
  const [previewScenario, setPreviewScenario] = useState<ScenarioSummary | null>(null);
  const { currency, type } = currentOrganization!;
  const { ct } = useContextualTranslation(type);

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return [];
    }
    return [{ name: 'businessUnits', value: selectedBusinessUnitsIdentifier }];
  }, [selectedBusinessUnitsIdentifier]);

  const {
    status: fetchStatus,
    data,
    error,
    isFetching,
  } = useFetch<FetchClientParams, Client[]>(
    {
      cacheKey: 'debtors',
      id: currentOrganization?.id,
      filtering,
      limit: 10,
    },
    apiOrganization.getClientsOrganization,
    { enabled: !!currentOrganization },
  );

  const [showScenarioDetails, hideScenarioDetails] = useModal(() => (
    previewScenario ? (
      <ScenarioDetails
        scenario={previewScenario.id}
        referenceDate={previewScenario.referenceDate as string | null}
        referenceDateOrigin={previewScenario.referenceDateOrigin}
        onClose={hideScenarioDetails}
      />
    ) : null
  ), [previewScenario]);

  const showScenarioModal = useCallback((scenario: ScenarioSummary) => {
    setPreviewScenario(scenario);
    showScenarioDetails();
  }, [showScenarioDetails]);

  const columns = useMemo<Column<Client>[]>(
    () => MainDebtorsColumns(ct, currency, showScenarioModal),
    [ct, currency, showScenarioModal],
  );

  return (
    <div className="MainDebtors">
      <DataTable<Client>
        columns={columns}
        data={data}
        serverPagination={null}
        defaultSortBy={{ id: 'id', desc: false }}
        withPagination={false}
        fetchData={() => { }}
        isLoading={isFetching && fetchStatus === 'loading'}
        error={error}
        withActions={false}
        noHeader
        noDataFoundMessage={ct('clients:no-data-was-found')}
      />
    </div>
  );
};

export default observer(MainDebtors);
