import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { DataBlock } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import getI18nChannel from 'utils/getI18nChannel';
import DropdownActions from 'components/DropdownActions';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import apiTemplateBlocks from 'api/template-blocks';
import { Channel, RightsCategory, StepActionEntity } from 'types/models';
import StepActionIcon from 'components/StepActionIcon';
import ModalTask from '../ModalTask';
import ModalReminder from '../ModalReminder';

type Props = {
  id: number,
  indexData: number,
  listActions: DataBlock[],
  action: DataBlock,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  type: 'reminder' | 'task',
};

const TemplateBlocksAction = (props: Props): JSX.Element => {
  const { listActions, action, onActionError, onActionDone, type, id, indexData } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const { put } = useApiRequest();

  const [showModalReminder, hideModalReminder] = useModal(() => (
    <ModalReminder
      edit
      onDone={onActionDone}
      onError={onActionError}
      channel={action.channel || Channel.EMAIL}
      onClose={hideModalReminder}
      defaultData={action}
      listData={listActions}
      id={id}
      indexData={indexData}
    />
  ), [onActionDone, onActionError, action, listActions, id, indexData]);

  const [showModalTask, hideModalTask] = useModal(() => (
    <ModalTask
      edit
      onDone={onActionDone}
      onError={onActionError}
      onClose={hideModalTask}
      defaultData={action}
      listData={listActions}
      id={id}
      indexData={indexData}
    />
  ), [listActions, action, onActionDone, onActionError, id, indexData]);

  const handleEdit = useCallback(() => {
    if (type === 'reminder') {
      showModalReminder();
      return;
    }
    showModalTask();
  }, [showModalReminder, showModalTask, type]);

  const handleDelete = useCallback(async () => {
    setShowDeleteConfirm(false);
    if (!currentOrganization || !listActions) {
      return;
    }

    const payloadListData = apiTemplateBlocks.buildPayloadListData(listActions, indexData, 'delete');
    const payload: Record<string, DataBlock[]> = {};
    if (type === 'reminder') {
      payload.reminders = payloadListData;
    } else {
      payload.tasks = payloadListData;
    }
    const response = await put(apiTemplateBlocks.updateUrl(id), payload);

    if (isMountedRef.current && response && !response.errors) {
      onActionDone(t('models:toast.success.delete-model', { name: action.name }));
      return;
    }
    onActionError(t('models:toast.error.delete-model', { name: action.name }));
  }, [action.name, currentOrganization, id, indexData, isMountedRef, listActions, onActionDone, onActionError, put, t, type]);

  const actions = useMemo(() => [
    <Button variant="list" onClick={handleEdit}>{t('common:edit')}</Button>,
    <ButtonWithUserRights
      action="DELETE"
      category={RightsCategory.PREFERENCES_MODELS}
      variant="list-danger"
      onClick={() => { setShowDeleteConfirm(true); }}
    >
      {t('common:remove')}
    </ButtonWithUserRights>,
  ], [handleEdit, t]);

  return (
    <div className="TemplateBlocksAction">
      <div className="TemplateBlocksAction__main">
        <div className="TemplateBlocksAction__main__icon">
          <StepActionIcon
            entity={type === 'task' ? StepActionEntity.TASK : StepActionEntity.REMINDER}
            channel={action.channel || Channel.EMAIL}
          />
        </div>
        <div className="TemplateBlocksAction__main__description">
          <h4 className="TemplateBlocksAction__main__description__title">{action.name}</h4>
          <p className="TemplateBlocksAction__main__description__channel">
            {action.channel ? t(getI18nChannel(action?.channel)) : ''}
          </p>
        </div>
      </div>
      <DropdownActions
        actions={actions}
      />
      <Confirm
        titleModal={t('common:remove')}
        text={t('models:confirm-delete')}
        variant="danger"
        confirmButtonText={t('common:remove')}
        cancelButtonText={t('common:cancel')}
        entityName={action.name}
        isShow={showDeleteConfirm}
        onConfirm={handleDelete}
        onCancel={() => { setShowDeleteConfirm(false); }}
      />
    </div>
  );
};

export default observer(TemplateBlocksAction);
