import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';

const BackOfficeHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="BackOfficeHeader">
      <div className="BackOfficeHeader__links">
        <GoBackButton />
      </div>
      <div className="BackOfficeHeader__infos">
        <h1 className="BackOfficeHeader__infos__name">
          {t('common:menu.user:back-office')}
        </h1>
      </div>
    </div>
  );
};

export default BackOfficeHeader;
