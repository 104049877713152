import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import getI18nActionSelectStatus from 'utils/getI18nActionSelectStatus';
import SelectGroupItem from 'components/SelectGroupItem';
import SelectGroupCheckAll from 'components/SelectGroupCheckAll';
import { StepActionTypeFilter } from 'types/models';

export const TODO_FILTERS = [
  StepActionTypeFilter.TASK_PENDING,
  StepActionTypeFilter.REMINDER_TO_VALIDATE,
  StepActionTypeFilter.REMINDER_TO_COMPLETE,
];

const TodoFilter = ({ filter, getFilterValue, onChange }: Misc.FilterCustomComponent): JSX.Element => {
  const { t } = useTranslation();

  const getFilterArray = useCallback((key: string): string[] => {
    const filterValue = getFilterValue(key);
    if (!filterValue) {
      return [];
    }
    return Array.isArray(filterValue) ? filterValue : [filterValue];
  }, [getFilterValue]);

  const isChecked = useCallback((key: string) => {
    const collapseValue = getFilterArray(filter.key);
    return collapseValue.includes(key);
  }, [getFilterArray, filter]);

  const isAllChecked = useMemo(() => {
    const collapseValue = getFilterArray(filter.key);
    return TODO_FILTERS.every((value) => collapseValue.includes(value));
  }, [getFilterArray, filter]);

  const setChecked = useCallback((key: string, checked: boolean) => {
    const collapseValue = getFilterArray(filter.key);
    const newCollapseValue = [...collapseValue.filter((value) => value !== key)];
    if (checked) {
      newCollapseValue.push(key);
    }
    onChange({ name: filter.key, value: newCollapseValue });
  }, [filter, getFilterArray, onChange]);

  const handleOnChange = useCallback((newIsAllChecked: boolean) => {
    const collapseValue = getFilterArray(filter.key);
    if (newIsAllChecked) {
      onChange({
        name: filter.key,
        value: [
          ...collapseValue.filter((value) => !TODO_FILTERS.includes(value as StepActionTypeFilter)),
          ...TODO_FILTERS,
        ],
      });
      return;
    }
    const newCollapseValue = [
      ...collapseValue.filter((value) => !TODO_FILTERS.includes(value as StepActionTypeFilter)),
    ];
    onChange({ name: filter.key, value: newCollapseValue });
  }, [filter, getFilterArray, onChange]);

  return (
    <SelectGroupCheckAll
      isAllChecked={isAllChecked}
      onChange={handleOnChange}
      name={t('actions:by-category') || ''}
      title={t('actions:actions-todo')}
      subtitle={t('actions:required-actions')}
      isDefaultOpen
      withSpacing
    >
      {TODO_FILTERS.map((filterItem) => (
        <SelectGroupItem
          key={filterItem}
          filterKey={filterItem}
          title={t(getI18nActionSelectStatus(filterItem))}
          isChecked={isChecked}
          onCheck={setChecked}
        />
      ))}
    </SelectGroupCheckAll>
  );
};

export default TodoFilter;
