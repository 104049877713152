import { useCallback, useContext, useRef } from 'react';
import TooltipContext from './context';

export type TooltipPosition = 'center' | 'statBlock' | 'note' | 'clamped';

const useTooltip = <T extends HTMLElement>(
  content: JSX.Element,
  position: TooltipPosition = 'center',
  disabled: boolean = false,
) => {
  const context = useContext(TooltipContext);
  const ref = useRef<T>();

  const handleMouseEnter = useCallback((event: MouseEvent) => {
    if (disabled) {
      return;
    }

    const { currentTarget, screenX } = event;
    const targetBox = (currentTarget as T).getBoundingClientRect();
    const { x, y, width, height } = targetBox;

    let positionX;
    let positionY;
    switch (position) {
      case 'center':
        positionX = x + (width / 2);
        positionY = y + height;
        break;
      case 'statBlock':
        positionX = x + (width - 30);
        positionY = y + (height - 35);
        break;
      case 'note':
        positionX = x - (200 - 20);
        positionY = y + (height + 5);
        break;
      case 'clamped':
        positionX = (window.innerWidth * 0.8) < screenX
          ? x - width - 50
          : x + (width - 30);
        positionY = (window.innerWidth * 0.8) < screenX
          ? y + height
          : y + (height - 35);
        break;
      default:
        positionX = x;
        positionY = y + height;
    }

    context.showTooltip(positionX, positionY, content, position === 'center');
  }, [disabled, context, content, position]);

  const handleMouseLeave = useCallback(() => {
    context.hideTooltip();
  }, [context]);

  const refCallback = useCallback<(node?: null | T) => void>((node) => {
    if (ref.current) {
      ref.current.removeEventListener('mouseenter', handleMouseEnter);
      ref.current.removeEventListener('mouseleave', handleMouseLeave);
    }

    ref.current = node || undefined;

    if (ref.current) {
      ref.current.addEventListener('mouseenter', handleMouseEnter);
      ref.current.addEventListener('mouseleave', handleMouseLeave);
    }
  }, [handleMouseEnter, handleMouseLeave]);

  return refCallback;
};

export default useTooltip;
