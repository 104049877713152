import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import type { DebitOrCredit } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';

type Props = {
  data: DebitOrCredit,
};

const CustomerAccountDetailsBalanceCell = ({ data }: Props): JSX.Element | null => {
  const { balance, currency } = data;
  const { currency: organizationCurrency } = organizationStore.currentOrganization!;

  const displayedBalance = useMemo(() => (
    formatIntlNumber(balance, currency || organizationCurrency)
  ), [balance, currency, organizationCurrency]);

  if (balance === null) {
    return null;
  }

  return (
    <div className="CustomerAccountDetailsBalanceCell">
      {displayedBalance}
    </div>
  );
};

export default observer(CustomerAccountDetailsBalanceCell);
