import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import classnames from 'classnames';
import type { FormRadioValue } from './Item';
import FormRadiosItem from './Item';

export type FormRadioItemData = {
  value: FormRadioValue,
  label?: DefaultTFuncReturn,
  id?: string,
  disabled?: boolean,
};

type Props = {
  items: FormRadioItemData[],
  name: string,
  disabled?: boolean,
  className?: string,
  onChange?: (newValue: FormRadioValue) => void,
  value?: FormRadioValue,
  defaultValue?: FormRadioValue,
};

const FormRadios = (props: Props): JSX.Element => {
  const {
    items,
    name,
    disabled,
    className,
    onChange,
    value: currentValue,
    defaultValue,
  } = props;

  const [checkedValue, setCheckedValue] = useState<FormRadioValue | undefined>(defaultValue);

  useEffect(() => {
    if (currentValue !== undefined) {
      setCheckedValue(currentValue);
    }
  }, [currentValue]);

  const handleClick = useCallback((newValue: FormRadioValue) => {
    if (disabled) {
      return;
    }

    setCheckedValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }, [disabled, onChange]);

  const classNames = useMemo(() => (
    classnames('FormRadios', className, {
      'FormRadios--disabled': disabled,
    })
  ), [className, disabled]);

  return (
    <div className={classNames}>
      {items.map((itemData: FormRadioItemData) => (
        <FormRadiosItem
          key={`${itemData.id || 'form-radio-item'}-${itemData.value}`}
          value={itemData.value}
          onClick={handleClick}
          checked={itemData.value === checkedValue}
          name={name}
          id={itemData.id}
          label={itemData.label}
          disabled={disabled || itemData.disabled}
        />
      ))}
    </div>
  );
};

export default FormRadios;
