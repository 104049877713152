import './index.scss';
import { useMemo, useCallback, useEffect, useState, useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { Column, Row } from 'react-table';
import { CreditStatus, DebitCreditEntity } from 'types/models';
import type { DebitOrCredit } from 'types/models';
import type { ClientAccountDetailsFilters, FetchDebitsAndCreditsParams } from 'api/clients';
import Config from 'config';
import useContextualTranslation from 'hooks/useContextualTranslation';
import apiCustomers from 'api/clients';
import organizationStore from 'stores/Organization';
import CreditEditModal from 'components/CreditEditModal';
import CreditViewModal from 'components/CreditViewModal';
import DataTable from 'components/DataTable';
import Pagination from 'components/Pagination';
import ExportButton from 'components/ExportButton';
import useFetchPaginated from 'hooks/useFetchPaginated2';
import customerContext from '../../../customerContext';
import detailsColumns from './Columns';
import DetailsFilters from './Filters';
import ModalDownloadDetails from './ModalDownloadDetails';

const DEFAULT_FILTERS: ClientAccountDetailsFilters = {
  debits: true,
  credits: true,
  current: true,
  balanced: true,
  future: false,
};

const CustomerAccountDetails = (): JSX.Element => {
  const { data: customer, reloadTick, onActionDone } = useContext(customerContext);
  const customerId = customer?.id;
  const { id: organizationId, type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const [pageSize, setPageSize] = useState<number>(Config.PAGE_SIZES[0]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [filters, setFilters] = useState<ClientAccountDetailsFilters>(DEFAULT_FILTERS);
  const editCreditId = useRef<number | null>(null);
  const viewCreditId = useRef<number | null>(null);

  const {
    isLoading,
    data,
    error,
    refetch,
    serverPagination,
  } = useFetchPaginated<FetchDebitsAndCreditsParams, DebitOrCredit>(
    {
      cacheKey: 'customerDebitsAndCredits',
      organizationId: organizationId,
      params: {
        customerId: customerId!,
        page: pageIndex,
        pageSize: pageSize,
        filters,
        filtering: [],
      },
    },
    apiCustomers.allDebitsAndCredits,
    { enabled: !!customerId },
  );

  const handleNextPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex + 1);
  }, []);

  const handlePreviousPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex - 1);
  }, []);

  const handleGotoPage = useCallback((page: number) => {
    setPageIndex(page);
  }, []);

  const handleChangeFilters = useCallback((newFilters: ClientAccountDetailsFilters) => {
    setFilters(newFilters);
  }, []);

  const totalRecords = useMemo(() => (
    serverPagination.totalRecords
  ), [serverPagination.totalRecords]);

  const pageCount = useMemo(() => (
    Math.ceil(totalRecords / pageSize)
  ), [totalRecords, pageSize]);

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const getRowClassName = useCallback((row: Row<DebitOrCredit>) => {
    const { entity, status } = row.original;
    if (entity === DebitCreditEntity.CREDIT && status === CreditStatus.FAILED) {
      return 'CustomerAccountDetails__list__row--failed-credit';
    }
    if (entity === DebitCreditEntity.DEBIT && row.original.type === 'REJECT') {
      return 'CustomerAccountDetails__list__row--litigated';
    }
    return '';
  }, []);

  const handleActionDone = useCallback((title: string, message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
    refetch();
  }, [onActionDone, refetch]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <CreditEditModal
      creditId={editCreditId.current ?? undefined}
      onDone={handleActionDone}
      onClose={hideEditModal}
    />
  ), [handleActionDone, editCreditId]);

  const showEditCreditModal = useCallback((creditId: number) => {
    editCreditId.current = creditId;
    showEditModal();
  }, [showEditModal]);

  const [showViewModal, hideViewModal] = useModal(() => {
    if (!viewCreditId.current) {
      return null;
    }

    const showEdit = (creditId: number) => {
      hideViewModal();
      showEditCreditModal(creditId);
    };

    return (
      <CreditViewModal
        creditId={viewCreditId.current}
        onClose={hideViewModal}
        onClickEdit={showEdit}
      />
    );
  }, [showEditCreditModal]);

  const showCreditModal = useCallback((id: number) => {
    viewCreditId.current = id;
    showViewModal();
  }, [showViewModal]);

  const handleDownloadDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showDownloadDetailModal, hideDownloadDetailModal] = useModal(() => (
    <ModalDownloadDetails
      customerId={customerId!}
      onDone={() => handleDownloadDone(t('common:export-success'))}
      onClose={hideDownloadDetailModal}
    />
  ), [customerId, handleDownloadDone, t]);

  const columns = useMemo<Column<DebitOrCredit>[]>(() => {
    let displayedColumns = detailsColumns(ct, showEditCreditModal, showCreditModal, filters.balanced);

    const hideBalance = (
      filters.debits === false
      || filters.credits === false
      || filters.current === false
      || filters.balanced === false
    );
    if (hideBalance) {
      displayedColumns = displayedColumns.filter(({ accessor }) => accessor !== 'balance');
    }
    const hideRemaining = filters.balanced === false;
    if (!hideRemaining) {
      displayedColumns = displayedColumns.filter(({ accessor }) => accessor !== 'amountNotAssigned');
    }

    return displayedColumns;
  }, [ct, showEditCreditModal, showCreditModal, filters]);

  return (
    <div className="CustomerAccountDetails">
      <div className="CustomerAccountDetails__header">
        <h3 className="CustomerAccountDetails__header__title">
          {t('debits:account-details')}
        </h3>
        <div className="CustomerAccountDetails__header__links">
          <ExportButton label={t('common:download')} onClick={showDownloadDetailModal} />
        </div>
      </div>
      <div className="CustomerAccountDetails__filters">
        <DetailsFilters filters={filters} onChange={handleChangeFilters} />
      </div>
      <div className="CustomerAccountDetails__list">
        <DataTable<DebitOrCredit>
          columns={columns}
          data={data}
          getRowClassName={getRowClassName}
          serverPagination={null}
          defaultSortBy={{ id: 'date', desc: true }}
          fetchData={() => {}}
          isLoading={isLoading}
          error={error}
          withActions={false}
          noDataFoundMessage={ct('debits:no-data')}
          noHeader
        />
        {(totalRecords && pageCount > 0) ? (
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < (pageCount - 1)}
            pageCount={pageCount}
            totalRecords={totalRecords}
            nextPage={handleNextPage}
            previousPage={handlePreviousPage}
            onChangePageSize={setPageSize}
            onGotoPage={handleGotoPage}
          />
        ) : ''}
      </div>
    </div>
  );
};

export default observer(CustomerAccountDetails);
