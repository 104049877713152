import { AxiosResponse } from 'axios';
import Misc from 'types/misc';
import type { Collection } from 'types/api';
import type { DataBlock, Organization, ReminderBlock, TaskBlock, TemplateBlocks } from 'types/models';
import requester from 'utils/requester';

export type FetchOneParams = {
  organization: string | undefined,
  type: 'dispatches' | 'reminders' | 'tasks',
  identifier: string,
};

export type FetchAllParams = {
  organization: Organization['id'] | undefined,
};

const all = async ({ organization }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllBlocks: Missing organization.');
  }

  const { data } = await requester.get<Collection<TemplateBlocks>>(
    `organizations/${organization}/blocks`,
  );
  return data['hydra:member'].length > 0 ? data['hydra:member'][0] : null;
};

/**
 * Récupère l'URL de la ressource d'API pour modifier le block.
 *
 * @param id L'ID du block de l'organization
 * @returns URL du PUT.
 */
const updateUrl = (id: TemplateBlocks['id']) => `blocks/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour importer un fichier yaml.
 *
 * @returns URL utiliser pour l'import.
 */
const importUrl = 'blocks/import';

/**
 * Retourne une liste modifiée pour les tasks ou reminders selon l'action
 *
 * @returns Liste modifiée selon l'action
 */
const buildPayloadListData = (dataList: DataBlock[], index: number, actionModal: Misc.ActionModal, data?: TaskBlock | ReminderBlock) => {
  const result: DataBlock[] = [...dataList];
  switch (actionModal) {
    case 'create':
      if (data) {
        result.push(data);
      }
      break;
    case 'edit':
      if (data) {
        result[index] = data;
      }
      break;
    case 'delete':
      result.splice(index, 1);
      break;
  }
  return result;
};

/**
 * Récupère le template yaml avec les données actuelles
 *
 * @returns le template yaml
 */
const getTemplate = async (reference: Organization['reference']): Promise<AxiosResponse<Blob>> => (
  requester.get(`blocks/export?organizations=${reference}`, { responseType: 'blob' })
);
export default { all, updateUrl, buildPayloadListData, getTemplate, importUrl };
