import './index.scss';
import type { SyntheticEvent } from 'react';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import type { ValidationErrors } from 'types/errors';
import config from 'config';
import authStore from 'stores/Auth';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';
import FormFieldset from 'components/FormFieldset';
import FormSelect from 'components/FormSelect';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import type { SignupData } from 'api/boarding';
import usePasswordValidator from 'hooks/usePasswordValidator';
import PasswordHelper from 'components/PasswordHelper';
import FormGroupPassword from 'components/FormGroupPassword';

type Props = {
  onSubmit: (data: SignupData | null) => void,
  isLoading?: boolean,
  errors: ValidationErrors | null,
};

const SignUpForm = ({ onSubmit, isLoading = false, errors }: Props): JSX.Element => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement | null>(null);
  const { helperText, valid, password, setPassword } = usePasswordValidator();

  const getFormData = useCallback((): SignupData | null => {
    if (!formRef.current) {
      return null;
    }

    const formData = new FormData(formRef.current);
    return Object.fromEntries(formData.entries()) as SignupData;
  }, []);

  const handleSubmit = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit(getFormData());
  }, [getFormData, isLoading, onSubmit]);

  return (
    <form className="SignUpForm" onSubmit={handleSubmit} ref={formRef}>
      <FormFieldset>
        <FormGroup
          label={t('common:first-name')}
          controlId="registerFirstName"
          error={errors?.firstName}
        >
          <FormControl
            id="registerFirstName"
            name="firstName"
            isInvalid={!!errors?.firstName}
          />
        </FormGroup>
        <FormGroup label={t('common:last-name')} controlId="registerLastName" error={errors?.lastName}>
          <FormControl
            id="registerLastName"
            name="lastName"
            isInvalid={!!errors?.lastName}
          />
        </FormGroup>
      </FormFieldset>
      <div hidden>
        <FormSelect
          name="language"
          selectOptions={config.LANGUAGES}
          value={i18next.language || 'en'}
          isInvalid={!!errors?.language}
        />
      </div>
      <FormGroup controlId="registerEmail" label={t('common:email')} error={errors?.email}>
        <FormControl
          type="email"
          name="email"
          id="registerEmail"
          placeholder={t('register:enter-email')}
          isInvalid={!!errors?.email}
        />
      </FormGroup>
      <FormFieldset>
        <FormGroup label={t('common:phone')} controlId="registerPhone" error={errors?.phone}>
          <FormControl
            name="phone"
            id="registerPhone"
            placeholder={t('register:placeholder-phone')}
            isInvalid={!!errors?.phone}
          />
        </FormGroup>
      </FormFieldset>
      <FormGroupPassword
        label={t('common:password')}
        name="password"
        value={password}
        onChange={setPassword}
        error={errors?.password}
      />
      <PasswordHelper validations={helperText} valid={valid} />
      <ErrorValidationMessage error={errors?.language} />
      <Button
        variant="primary"
        type="submit"
        className="SignUpForm__submit"
      >
        {isLoading && <Loading />}
        {!isLoading && t('register:sign-up')}
      </Button>
      <p className="SignUpForm__terms">
        {t('register:accepting-terms')}
        <a
          href={`https://www.dunforce.com/${authStore.user?.locale || 'en'}/terms-conditions/`}
          target="blank"
          className="SignUpForm__terms__link"
        >
          {t('register:terms')}
        </a>
      </p>
    </form>
  );
};

export default SignUpForm;
