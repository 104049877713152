import Wysiwyg from 'components/Wysiwyg';

import type { StepAction } from 'types/models';
import { Channel } from 'types/models';

type Props = {
  reminder: StepAction,
  onChange(newValue: string): void,
};

const ReminderEditFormContentEdit = ({ reminder, onChange }: Props): JSX.Element | null => {
  return (
    <div className="ReminderEditFormContentEdit">
      <Wysiwyg
        name="content"
        defaultValue={reminder.content ?? ''}
        toBePrinted={reminder.channel !== Channel.EMAIL}
        onChange={onChange}
      />
    </div>
  );
};

export default ReminderEditFormContentEdit;
