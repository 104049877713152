import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { DebitSummary } from 'types/models';
import { DebitStatus } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';
import NoteIcon from 'components/NoteIcon';
import PhaseDate from 'components/PhaseDate';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import RowActions from '../Actions/Row';

const DebitsColumns = (
  ct: TFunction,
  t: TFunction,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
): Column<DebitSummary>[] => [
  {
    Header: ct<string>('debits:reference'),
    accessor: 'code',
    Cell: ({ row, value }) => (
      <Fragment>
        <div className="Debits__columns__reference">
          <Link
            to={`/debit/${row.original.id}`}
            className="Debits__columns__link"
          >
            {value}
          </Link>
        </div>
        <ul className="Debits__columns__reference__plans">
          {row.original.scenario && (
            <li className="Debits__columns__reference__plans__plan" key={row.original.scenario.identifier}>
              {row.original.scenario.label}{!!row.original.scenario.identifier && ` (${row.original.scenario.identifier})`}
            </li>
          )}
        </ul>
      </Fragment>
    ),
  },
  {
    Header: ct<string>('common:client'),
    accessor: 'client',
    Cell: ({ value }) => {
      if (!value) {
        return (
          <div className="Debits__columns__client">
            {t('common:not-specified')}
          </div>
        );
      }

      return (
        <div className="Debits__columns__client">
          <Link to={`/customer/${value.id}`}>
            {value?.denomination}
          </Link>
          {value.denomination !== value.identifier && (
            <div className="Debits__columns__client__ref">
              {value.identifier}
            </div>
          )}
        </div>
      );
    },
  },
  {
    Header: ct<string>('common:amount'),
    accessor: 'amount',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ row, value }) => (
      <Fragment>
        {formatIntlNumber(
          row.original.status === DebitStatus.PAID ? row.original.initialCost : value,
          row.original.currency.code ?? organizationStore.currentOrganization!.currency,
        )}
      </Fragment>
    ),
  },
  {
    Header: ct<string>('common:deadline'),
    accessor: 'dueDate',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ row, value }) => {
      const { status } = row.original;
      const statusId = (status !== DebitStatus.IN_PROGRESS) ? status : undefined;
      return <PhaseDate date={value.date} phaseId={row.original.phase} statusId={statusId} />;
    },
  },
  {
    Header: '',
    accessor: 'note',
    disableSortBy: true,
    Cell: ({ value }) => (
      <div className="Debits__columns__note">
        {value?.id && <NoteIcon note={value} />}
      </div>
    ),
  },
  {
    Header: ct<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row, value: id }) => (
      <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <RowActions
            id={id}
            client={row.original.client.id}
            downloadPath={row.original.invoiceFilePath}
            reference={row.original.identifier}
            muted={row.original.mutedAt?.date ?? null}
            defaultPromiseToPay={row.original.promiseToPayDate?.date ?? null}
            currency={row.original.currency.code}
            onActionDone={onActionDone}
            onActionError={onActionError}
            status={row.original.status}
          />
      </ExternallyMonitoredController>
    ),
  },
];

export default DebitsColumns;
