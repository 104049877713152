import { useTranslation } from 'react-i18next';
import { DebitStatus } from 'types/models';
import type { ClientDebit } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import Badge from 'components/Badge';

type Props = {
  data: ClientDebit,
};

const CustomerAccountDetailsStatusDebitCell = ({ data }: Props): JSX.Element | string => {
  const { t } = useTranslation();
  const { status, dueAt, phase, litigatedAt, type } = data;

  if (type === 'REJECT') {
    return t<string>('debits:status.failed');
  }

  if (status === DebitStatus.PAID) {
    return t<string>('debits:status.paid');
  }

  if (status === DebitStatus.CANCEL) {
    return t<string>('debits:status.cancelled');
  }

  if (litigatedAt) {
    return (
      <Badge variant="LITIGATED" title={formatIntlDate(litigatedAt, 'dateLong')}>
        {t('debits:status.litigated')}
      </Badge>
    );
  }

  return (
    <Badge variant={phase!}>
      {t('debits:due-at')} {formatIntlDate(dueAt, 'dateLong')}
    </Badge>
  );
};

export default CustomerAccountDetailsStatusDebitCell;
