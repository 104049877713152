import './index.scss';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import config from 'config';
import Misc from 'types/misc';
import type { ValidationErrors } from 'types/errors';
import type { DataBlock, StepAction } from 'types/models';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import ModalTemplateBlocks from 'components/ModalTemplateBlocks';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import apiHistorySteps from 'api/historySteps';
import { JobExecution } from 'utils/webSocket';
import apiClients, { getCustomerFilter } from 'api/clients';
import apiScenarioGroup from 'api/scenarioGroup';
import CreateTaskForm from './Form';

interface Props {
  filters?: Misc.Filter[],
  totalRecords?: number,
  onDone(title: string, message: string): void,
  onClose(): void,
  onResponse?(response: JobExecution): void,
  customerId?: number,
}

const CustomerCreateTask = (props: Props): JSX.Element => {
  const { onDone, onClose, onResponse, customerId, filters, totalRecords } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { user } = authStore;
  const { currentOrganization, attributes } = organizationStore;
  const isBulkCreate: boolean = !!filters;
  const [formDataTemp, setFormDataTemp] = useState<ModalFormData | null>();
  const [showBulkConfirm, setShowBulkConfirm] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [model, setModel] = useState<DataBlock | undefined>();
  const { post, isLoading, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const [showModalTemplateBlocks, hideModalTemplateBlocks] = useModal(() => (
    <ModalTemplateBlocks
      type="task"
      onClose={hideModalTemplateBlocks}
      onSelect={setModel}
    />
  ), []);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    setValidationErrors(null);

    const { startAt, name: taskName } = formData as { [key: string]: string };

    if (!taskName) {
      setValidationErrors({ name: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (!startAt) {
      setValidationErrors({ startAt: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (isBulkCreate && !formDataTemp) {
      setFormDataTemp(formData);
      setShowBulkConfirm(true);
      return;
    }

    const data = {
      name: formData.name as string,
      description: formData.description as string,
      startAt: formData.startAt as string,
      client: customerId ? apiClients.resourceUrl(customerId) : undefined,
      group: formData.group ? apiScenarioGroup.resourceUrl(parseInt(formData.group as string, 10)) : undefined,
      static: formData.static === '1',
    };

    let result;
    if (isBulkCreate && onResponse) {
      result = await post<StepAction>(
        apiClients.bulkUrl,
        {
          channel: currentOrganization?.reference,
          locale: user?.locale,
          filters: getCustomerFilter(attributes.client, filters),
          actionType: 'create_task',
          actionData: data,
        },
      );
      onResponse(result as unknown as JobExecution);
    } else {
      result = await post<StepAction>(apiHistorySteps.createTaskUrl, data);
    }

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result?.name && !isBulkCreate) {
      const { name } = result;
      onDone('', t('reminders:freetask.toast.created', { name }));
    }

    if (result === null) {
      return;
    }

    closeSelf();
  }, [
    t,
    currentOrganization,
    customerId,
    post,
    isMountedRef,
    closeSelf,
    onDone,
    onResponse,
    filters,
    isBulkCreate,
    formDataTemp,
    attributes.client,
    user?.locale,
  ]);

  const handleBulkConfirm = useCallback(async () => {
    if (formDataTemp) {
      setShowBulkConfirm(false);
      await handleSubmit(formDataTemp);
    }
  }, [handleSubmit, formDataTemp]);

  const handleBulkCancel = useCallback(() => {
    setShowBulkConfirm(false);
    setFormDataTemp(null);
  }, []);

  return (
    <ModalForm
      isOpened
      className="CustomerCreateTask"
      helpHeaderLink={config.FRESHDESK_ACTIONS_FORMS}
      title={t('reminders:freetask.create')}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      totalRecords={
        totalRecords ? t('clients:total-records', { count: totalRecords }) as string : undefined
      }
      headerActions={(
        <Button variant="link" onClick={showModalTemplateBlocks}>{t('models:import')}</Button>
      )}
    >
      <CreateTaskForm
        errors={validationErrors}
        defaultData={model}
        customerId={customerId}
      />
      {error && (<ErrorMessage error={error} />)}
      <Confirm
        titleModal={t('reminders:freetask.create')}
        text={t('clients:actions.confirm.bulk.add-task', { count: totalRecords })}
        isShow={showBulkConfirm}
        onConfirm={handleBulkConfirm}
        onCancel={handleBulkCancel}
        variant="danger"
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(CustomerCreateTask);
