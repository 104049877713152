import { PaymentMethod } from 'types/models';

const getI18nPaymentMethod = (paymentMethod: PaymentMethod): string => {
  const i18nPaymentMethod = {
    [PaymentMethod.OTHER]: 'credits:method.other',
    [PaymentMethod.CARD]: 'credits:method.card',
    [PaymentMethod.CHECK]: 'credits:method.check',
    [PaymentMethod.TRANSFER]: 'credits:method.transfer',
    [PaymentMethod.DIRECT_DEBIT]: 'credits:method.direct-debit',
    [PaymentMethod.CASH]: 'credits:method.cash',
  };

  return i18nPaymentMethod[paymentMethod] || 'common:unknown';
};

export default getI18nPaymentMethod;
