import './index.scss';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import organizationStore from 'stores/Organization';
import apiOrganization from 'api/organization';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import type { Organization, OrganizationPeriodicTasks } from 'types/models';
import FormGroup from 'components/FormGroup';
import useApiRequest from 'hooks/useApiRequest';
import FormSelectMinimal from 'components/FormSelectMinimal';

type Props = {
  data: OrganizationPeriodicTasks,
  onShowToast(message: string, status: ToastStatus): void,
};

const TIMETABLE = [
  ['mon', 'tue', 'wed'],
  ['thu', 'fri', 'sat'],
  ['sun', 'holidays'],
];

const INITIAL_FORM_DATA: Record<string, string> = {
  '0': '',
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': '',
  '6': '',
};

const SendingSchedule = ({ onShowToast, data }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const { schedule, scheduledTimeAtHolidays } = data;
  const { put } = useApiRequest();

  const [formData, setFormData] = useState({
    reminders : schedule?.reminders || INITIAL_FORM_DATA,
    scheduledTimeAtHolidays : scheduledTimeAtHolidays ?? null,
  });

  const getDayOptions = useCallback((day?: string) => {
    const options = [...Array(24)].map((_, time) => ({
      value: day ? `0 ${time} * * ${day}` : `${String(time).padStart(2, '0')}:00`,
      label: `${time}h`,
    }));
    return [...options, { value: '', label: t('common:no') }];
  }, [t]);

  const handleItemChange = useCallback( async (name: string, newValue: string | null) => {
    if (!currentOrganization) {
      return;
    }

    const payload: OrganizationPeriodicTasks = {};
    if (name === 'scheduledTimeAtHolidays') {
      const holidaysTime = newValue && newValue !== '' ? newValue : null;
      payload[name] = holidaysTime;
      setFormData((prevFormData) => ({
        ...prevFormData,
        scheduledTimeAtHolidays: holidaysTime,
      }));
    } else {
      formData.reminders[parseInt(name)] = newValue!;
      payload.schedule = { reminders: {
        ...(Object.keys(formData.reminders).reduce((acc, key) => {
          if (formData.reminders[key] !== '') {
            acc[key] = formData.reminders[key];
          }
          return acc;
        }, {} as Record<string, string>)),
      } };
      setFormData((prevFormData) => ({
        ...prevFormData,
        reminders: payload.schedule!.reminders,
      }));
    }

    const result = await put<Organization>(apiOrganization.updatePeriodicTasksUrl(currentOrganization.id), payload);

    if (result?.id) {
      onShowToast(t('users:toast.success.settings-update'), 'success');
      return;
    }
    onShowToast(t('errors:unknown-retry'), 'error');
  }, [onShowToast, formData, put, currentOrganization, t]);

  return (
    <div className="SendingSchedule">
      {TIMETABLE.map((group, groupIndex) => (
        <div key={groupIndex} className="wrapper">
          {group.map((day, index) => (
            <FormGroup key={index} label={t(`common:dates.${day}`)}>
              <FormSelectMinimal
                onSelect={handleItemChange}
                defaultValue={
                  day === 'holidays'
                    ? ( formData.scheduledTimeAtHolidays ?? '' )
                    : ( formData.reminders[groupIndex * 3 + index] ?? '' )
                }
                name={day === 'holidays' ? 'scheduledTimeAtHolidays' : (groupIndex * 3 + index).toString()}
                selectOptions={
                  day === 'holidays' ? getDayOptions() : getDayOptions(day)
                }
              />
            </FormGroup>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SendingSchedule;
