import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import classnames from 'classnames';

type Props = {
  id?: string,
  name?: string,
  value?: string | number,
  label?: DefaultTFuncReturn,
  disabled?: boolean,
  className?: string,
  onChange?(isChecked: boolean): void,
  checked?: boolean,
  defaultChecked?: boolean,
};

const FormCheck = (props: Props): JSX.Element => {
  const {
    id,
    name,
    value,
    label = null,
    disabled,
    className,
    onChange,
    checked,
    defaultChecked = false,
  } = props;

  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }

    setIsChecked(!isChecked);

    if (onChange) {
      onChange(!isChecked);
    }
  }, [disabled, isChecked, onChange]);

  const classNames = classnames('FormCheck', className, {
    'FormCheck--disabled': disabled,
  });

  return (
    <div className={classNames}>
      <input
        id={id}
        name={name}
        value={value}
        type="checkbox"
        className="FormCheck__input"
        disabled={disabled}
        checked={isChecked}
        readOnly
      />
      <label
        htmlFor={id}
        className="FormCheck__label"
        role="presentation"
        onClick={handleClick}
      >
        {label}
      </label>
    </div>
  );
};

export default FormCheck;
