import { useCallback, useEffect, useRef, useState } from 'react';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import type { ValidationErrors } from 'types/errors';
import authStore from 'stores/Auth';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import { PHONE_REGEXP } from 'utils/validator';
import MyProfileEditForm from './EditForm';

export type UserUpdate = {
  firstName: string,
  lastName: string,
  namePrefix: string,
  phone: string,
};

type Props = {
  onClose(): void,
  onShowToast(message: string, type: ToastStatus): void,
};

const ModalEditMyProfile = ({ onClose, onShowToast }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = authStore;
  const isMountedRef = useIsMountedRef();

  const initialData = useRef<UserUpdate | null>(null);

  const { put, isLoading, cancel, error } = useApiRequest();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const mapFormData = useCallback(
    (rawData: ModalFormData): UserUpdate => ({
      firstName: rawData?.firstName as string,
      lastName: rawData?.lastName as string,
      namePrefix: rawData?.civility as string,
      phone: rawData?.phone as string,
    }),
    [],
  );

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    setHasChanges(
      !!formData
      && !equal(initialData.current, mapFormData(formData)),
    );
  }, [initialData, mapFormData]);

  const handleEmailError = useCallback(() => {
    setValidationErrors({
      email: { code: 4, message: t('email-not-valid') },
    });
  }, [t]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !user) {
      return;
    }

    setValidationErrors(null);

    // validation
    const isValid = PHONE_REGEXP.test(formData.phone as string);
    if (!isValid) {
      setValidationErrors({ phone: { code: -1, message: t('validators:phone.not-valid') } });
      return;
    }

    const result = await put<Misc.IdName>(`users/${user.id}`, mapFormData(formData));

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result?.id) {
      authStore.fetchUser();
      onShowToast(t('users:toast.success.settings-update'), 'success');
      onClose();
    }
  }, [user, mapFormData, put, isMountedRef, onShowToast, onClose, t]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <ModalForm
      title={`${user?.firstName} ${user?.lastName ?? user?.email}`}
      className="ModalEditMyProfile"
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onEmailError={handleEmailError}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <MyProfileEditForm
        defaultData={user}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalEditMyProfile);
