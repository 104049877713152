import './index.scss';

type Props = {
  count?: number | null,
};

const Notification = ({ count }: Props): JSX.Element | null => (
  count ? (
    <div className="Notification">
      <span className="Notification__count">{count}</span>
    </div>
  ) : null
);

export default Notification;
