export enum RightsCategoryType {
  CLIENTS = 'CLIENTS',
  ACTIONS = 'ACTIONS',
  PAYREQUESTS = 'PAYREQUESTS',
  CONTACTS = 'CONTACTS',
  PAYMENTS = 'PAYMENTS',
}

export type RightsAction = 'CREATE' | 'DELETE' | 'READ' | 'UPDATE';

export enum RightsRole {
  ROLE_SUPER_ADMINISTRATOR = 'ROLE_SUPER_ADMINISTRATOR',
  ROLE_OWNER = 'ROLE_OWNER',
  ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR',
  ROLE_SUPERVISOR = 'ROLE_SUPERVISOR',
  ROLE_COORDINATOR = 'ROLE_COORDINATOR',
  ROLE_ACCOUNT_MANAGER = 'ROLE_ACCOUNT_MANAGER',
  ROLE_READER = 'ROLE_READER',
  ROLE_DEMO = 'ROLE_DEMO',
  ROLE_USER = 'ROLE_USER',
}

export enum RightsCategory {
  BACK_OFFICE = 'BACK_OFFICE',
  DASHBOARD = 'DASHBOARD',
  ANALYSES = 'ANALYSES',
  ABONNEMENT = 'ABONNEMENT',
  ORGANIZATION_COLLABORATORS_ROLE_ADMINISTRATOR = 'ORGANIZATION_COLLABORATORS_ROLE_ADMINISTRATOR',
  ORGANIZATION_COLLABORATORS_ROLE_COORDINATOR = 'ORGANIZATION_COLLABORATORS_ROLE_COORDINATOR',
  ORGANIZATION_COLLABORATORS_ROLE_ACCOUNT_MANAGER = 'ORGANIZATION_COLLABORATORS_ROLE_ACCOUNT_MANAGER',
  ORGANIZATION = 'ORGANIZATION',
  SETTINGS_ORGANIZATION_MODE_PRODUCTION = 'SETTINGS_ORGANIZATION_MODE_PRODUCTION',
  PREFERENCES_CUSTOMIZATION_SEND = 'PREFERENCES_CUSTOMIZATION_SEND',
  PREFERENCES_CUSTOMIZATION_ROLES = 'PREFERENCES_CUSTOMIZATION_ROLES',
  PREFERENCES_MODELS = 'PREFERENCES_MODELS',
  PREFERENCES_ATTRIBUTES = 'PREFERENCES_ATTRIBUTES',
  PREFERENCES_PLANS = 'PREFERENCES_PLANS',
  PREFERENCES_SELECTORS = 'PREFERENCES_SELECTORS',
  CLIENTS_ASSIGNED_SOMEONE = 'CLIENTS_ASSIGNED_SOMEONE',
  ACTIONS_ASSIGNED_SOMEONE = 'ACTIONS_ASSIGNED_SOMEONE',
  PAYREQUESTS_ASSIGNED_SOMEONE = 'PAYREQUESTS_ASSIGNED_SOMEONE',
  CONTACTS_ASSIGNED_SOMEONE = 'CONTACTS_ASSIGNED_SOMEONE',
  PAYMENTS_ASSIGNED_SOMEONE = 'PAYMENTS_ASSIGNED_SOMEONE',
  CLIENTS_UNASSIGNED = 'CLIENTS_UNASSIGNED',
  ACTIONS_UNASSIGNED = 'ACTIONS_UNASSIGNED',
  PAYREQUESTS_UNASSIGNED = 'PAYREQUESTS_UNASSIGNED',
  CONTACTS_UNASSIGNED = 'CONTACTS_UNASSIGNED',
  PAYMENTS_UNASSIGNED = 'PAYMENTS_UNASSIGNED',
  CLIENTS_ASSIGNED_TO_ME = 'CLIENTS_ASSIGNED_TO_ME',
  ACTIONS_ASSIGNED_TO_ME = 'ACTIONS_ASSIGNED_TO_ME',
  PAYREQUESTS_ASSIGNED_TO_ME = 'PAYREQUESTS_ASSIGNED_TO_ME',
  CONTACTS_ASSIGNED_TO_ME = 'CONTACTS_ASSIGNED_TO_ME',
  PAYMENTS_ASSIGNED_TO_ME = 'PAYMENTS_ASSIGNED_TO_ME',
  IMPORTS = 'IMPORTS',
  UNKNOWN_UNASSIGNED = 'UNKNOWN_UNASSIGNED',
}

export type ManagerType = {
  name: string,
  identifier: string,
  id: number,
};

export type UserRights = {
  categories: RightsCategory[],
  rights: {
    CREATE: RightsRole[],
    DELETE: RightsRole[],
    READ: RightsRole[],
    UPDATE: RightsRole[],
  },
};

export type Role = {
  id: number,
  code: UserRights,
  label: string,
};

export type RoleUser = {
  id: number,
  role: RightsRole,
  label: string,
  type: string,
};
