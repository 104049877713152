import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { ValidationErrors } from 'types/errors';
import type { DebitDraft } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelectCustomerWithDefault from 'components/FormSelectCustomer/WithDefault';
import FormDatePicker from 'components/FormDatePicker';
import PDFViewer from 'components/PDFViewer';
import FormAmountCurrency from 'components/FormAmountCurrency';
import FormEavs from 'components/FormEavs';
import CustomerEdit from 'components/CustomerEdit';
import FormGroupWithActions from 'components/FormGroupWithActions';
import Button from 'components/Button';

type Props = {
  data?: DebitDraft,
  errors?: ValidationErrors | null,
  pdf: string | null,
  onActionDone(message: string): void,
};

const DraftInvoiceForm = ((props: Props): JSX.Element => {
  const { errors, data, pdf, onActionDone } = props;
  const {
    reference,
    currency: organizationCurrency,
    type,
    eavs,
  } = organizationStore.currentOrganization!;

  const { t, ct } = useContextualTranslation(type);

  const [refreshTick, setRefreshTick] = useState<number>(0);

  const handleAddCustomerDone = useCallback((
    message: string,
    hideModal: () => void,
    value: number,
  ) => {
    hideModal();
    onActionDone(message);
    setRefreshTick(value + 1);
  }, [onActionDone]);

  const customerInitialError = useMemo(() => (
    !errors ? data?.errors?.client : undefined
  ), [errors, data]);

  const [showCustomerModal, hideCustomerModal] = useModal(() => (
    <CustomerEdit
      editType="new"
      onDone={(message) => { handleAddCustomerDone(message, hideCustomerModal, refreshTick); }}
      customerId={undefined}
      defaultDenomination={data?.client}
      onClose={hideCustomerModal}
    />
  ), [handleAddCustomerDone, refreshTick, data]);

  return (
    <div className="DraftInvoiceForm">
      <div className="DraftInvoiceForm__form">
        <div className="DraftInvoiceForm__form__fields">
          <FormGroupWithActions
            label={ct('common:client')}
            mandatory
            shouldDisplayErrorUnderInput
            error={customerInitialError || errors?.client}
            action={data?.errors?.client && (
              <Button
                variant="link"
                onClick={showCustomerModal}
                withCaretIcon
                className="DraftInvoiceForm__form__fields__create-client"
              >
                {t('common:create')}
              </Button>
            )}
          >
            <FormSelectCustomerWithDefault
              name="customerReference"
              customerId={!customerInitialError ? data?.clientId : undefined}
              refreshTick={refreshTick}
              isInvalid={!!customerInitialError || !!errors?.client}
            />
          </FormGroupWithActions>
          <FormGroup
            label={t('debits:reference')}
            mandatory
            shouldDisplayErrorUnderInput
            error={(!errors && data?.errors?.reference) || errors?.reference}
          >
            <FormControl
              name="reference"
              autoComplete="off"
              defaultValue={data?.reference}
              isInvalid={!!((!errors && data?.errors?.reference) || errors?.reference)}
            />
          </FormGroup>
          <FormAmountCurrency
            shouldDisplayErrorUnderInput
            defaultAmount={data?.amount}
            defaultCurrency={data?.currency || organizationCurrency}
            amountError={(!errors && data?.errors?.amount) || errors?.amount}
            currencyError={(!errors && data?.errors?.currency) || errors?.currency}
          />
          <FormGroup
            label={t('common:due-at')}
            shouldDisplayErrorUnderInput
            mandatory
            error={(!errors && data?.errors?.dueAt) || errors?.dueAt}
          >
            <FormDatePicker
              name="dueAt"
              defaultValue={data?.dueAt}
              isInvalid={!!((!errors && data?.errors?.dueAt) || errors?.dueAt)}
            />
          </FormGroup>
          <FormGroup
            shouldDisplayErrorUnderInput
            label={t('debits:subject')}
            error={(!errors && data?.errors?.subject) || errors?.subject}
          >
            <FormControl
              name="subject"
              autoComplete="off"
              defaultValue={data?.subject || ''}
              isInvalid={!!(errors?.subject || (!errors && data?.errors?.subject))}
            />
          </FormGroup>
        </div>
        {pdf && (
          <PDFViewer
            base64PDF={pdf}
            scale={0.5}
            downloadUrl={`${reference}/import/${data?.importLogId}`}
            isClickable
          />
        )}
      </div>
      <div>
        {eavs && eavs.debit?.length > 0 && (
          <FormEavs
            eavs={eavs.debit}
            defaultValues={data?.eav}
            errors={(
              errors?.eav || (!errors && data?.errors?.eav)
            ) as unknown as ValidationErrors}
          />
        )}
      </div>
    </div>
  );
});

export default observer(DraftInvoiceForm);
