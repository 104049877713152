import type Misc from 'types/misc';
import type { ClientSummary, Organization, User, ClientType } from 'types/models';
import type { SearchResult } from 'types/api';
import apiClients from './clients';

export type DebitResult = {
  id: number,
  reference: string,
};

export type CustomerResult = {
  id: number,
  type: ClientType,
  identifier: string,
  denomination: string,
};

export type ContactResult = {
  id: number,
  firstName: string,
  lastName: string,
  identifier: string,
};

export interface SearchResultsData {
  clientsCount: number,
  clients: CustomerResult[],
  // FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle.
  /* debitsCount: number,
  debits: DebitResult[],
  credits: DebitResult[],
  creditsCount: number,
  contactsCount: number,
  contacts: ContactResult[], */
}

export type FetchAllParams = {
  page?: number | undefined,
  pageSize?: number,
  organizationId?: Organization['id'],
  organizationReference?: Organization['reference'] | undefined,
  locale?: User['locale'] | undefined,
  searchTerm: string,
  filtering: Misc.Filter[],
};

type FetchAllResults = [
  SearchResult<ClientSummary>,
  // FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle.
  /* SearchResult<ContactSummary>,
  SearchResult<DebitSummary>,
  SearchResult<CreditSummary>, */
];

const fetchAll = async ({ organizationReference, organizationId, locale, searchTerm, filtering }: FetchAllParams): Promise<SearchResultsData> => {
  if (!organizationReference) {
    throw new Error('GlobalSearch: Missing organization.');
  }

  if (!searchTerm) {
    throw new Error('GlobalSearch: No search term provided');
  }

  const safeSearchTerm = searchTerm.trim();
  if (safeSearchTerm === '') {
    throw new Error('GlobalSearch: No search term provided (trimmed)');
  }

  const filters = [
    { name: 'search', value: safeSearchTerm },
  ] as Misc.Filter[];

  if (filtering.length > 0) {
    filters.push(...filtering);
  }

  const page = 0;
  const pageSize = 25;

  const promises = [
    apiClients.all(organizationId, {
      organizationReference,
      locale,
      attributes: [],
      page,
      pageSize,
      filtering: filters,
    }),
    // FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle.
    /*
    apiContacts.all(organizationId, {
      organizationReference,
      locale,
      page,
      pageSize,
      filtering: filters,
    }),
    apiDebits.all(organizationId, {
      organizationReference,
      locale,
      page,
      pageSize,
      filtering: filters,
    }),
    apiCredits.all(organizationId, {
      organizationReference,
      locale,
      page,
      pageSize,
      filtering: filters,
    }),*/
  ];

  const [clients] = await Promise.all(promises) as FetchAllResults;

  return {
    clients: clients.items.map(({ id, identifier, denomination, type }) => ({
      id,
      identifier,
      denomination: denomination ?? '',
      type,
    })),
    clientsCount: clients.matches_count,
    // FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle.
    /* debits: debits.items.map(({ id, identifier }) => ({
      id,
      reference: identifier,
    })),
    debitsCount: debits.matches_count,
    contacts: contacts.items.map(({ id, identifier, firstName, lastName }) => ({
      id,
      identifier,
      firstName: firstName ?? '',
      lastName: lastName ?? '',
    })),
    contactsCount: contacts.matches_count,
    credits: credits.items.map(({ id, identifier }) => ({
      id,
      reference: identifier,
    })),
    creditsCount: credits.matches_count,*/
  };
};

export default fetchAll;
