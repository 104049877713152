import { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import organizationStore from 'stores/Organization';
import apiUsers from 'api/users';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import type { Organization } from 'types/models';
import apiOrganization from 'api/organization';
import SendingCustomerPortalEditForm from './Form';

interface Props {
  data?: boolean,
  title: string,
  name: string,
  onClose: () => void,
  onShowToast: (message: string, status: ToastStatus) => void,
}

const SendingCustomerPortalEdit = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();

  const {
    data,
    title,
    name,
    onClose,
    onShowToast,
  } = props;

  const {
    put,
    isLoading,
    cancel,
    error,
  } = useApiRequest();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(!!formData);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [currentOrganization]);

  const handleSubmit = useCallback((async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }
    const payload = {
      allowViewPayer: formData.sendViewPayer === '1',
      referent: apiUsers.resourceUrl(formData.referent as string),
    };
    const result = await put<Organization>(apiOrganization.updateUrl(currentOrganization.id), payload);

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result?.id) {
      organizationStore.refresh();
      onClose();
      onShowToast(t('users:toast.success.settings-update'), 'success');
    }
  }), [currentOrganization, isMountedRef, onClose, onShowToast, put, t]);

  return (
    <ModalForm
      title={title}
      className="SendingActionsEdit"
      isOpened
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <p>{t('payer:actions.send-payer')}</p>
      <SendingCustomerPortalEditForm
        defaultData={data}
        name={name}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(SendingCustomerPortalEdit);
