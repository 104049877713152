import './index.scss';
import type { ValidationErrors } from 'types/errors';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import Wysiwyg from 'components/Wysiwyg';
import FormControl from 'components/FormControl';

type Props = {
  defaultData?: string | null,
  type: 'pdf' | 'text' | 'img',
  name: string,
  errors?: ValidationErrors | null,
};

const SendingActionsEditForm = (props: Props): JSX.Element => {
  const { defaultData, type, name, errors } = props;

  return (
    <div className="SendingActionsEditForm">
      <FormFieldset>
        <FormGroup error={errors?.name}>
          {type === 'pdf' || type === 'img' ? (
            <FormControl type="file" name={name} acceptFiles={type === 'pdf' ? '.pdf' : 'image/*'} />
          ) : (
            <Wysiwyg name={name} defaultValue={defaultData || ''} />
          )}
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default SendingActionsEditForm;
