import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import dateFormatIso from 'utils/dateFormatIso';
import Icon from 'components/Icon';

type Props = {
  name: string,
  onChange?(name: string, value: string): void,
  value?: string,
  defaultValue?: string | undefined | null,
  className?: string,
  minDate?: Date,
  maxDate?: Date,
  withClearIcon?: boolean,
  isInvalid?: boolean,
};

const FormDatePicker = (props: Props): JSX.Element => {
  const {
    name,
    onChange,
    value,
    defaultValue,
    className,
    minDate,
    maxDate,
    withClearIcon = false,
    isInvalid = false,
  } = props;

  const [date, setDate] = useState<Date | [Date, Date] | undefined>(() => {
    if (value) {
      return new Date(value);
    }

    if (defaultValue === undefined) {
      return new Date();
    }

    if (defaultValue) {
      return new Date(defaultValue);
    }
  });

  useEffect(() => {
    if (!value) {
      return;
    }

    setDate(new Date(value));
  }, [value]);

  const handleChange = useCallback((newDate: Date | [Date, Date]) => {
    setDate(newDate);
  }, []);

  useEffect(() => {
    let newValue = '';
    if (date) {
      newValue = dateFormatIso(Array.isArray(date) ? date[0] : date);
    }
    if (onChange) {
      onChange(name, newValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const classNames = classnames('FormDatePicker', className, {
    'FormDatePicker--is-invalid': isInvalid,
  });

  return (
    <div className={classNames}>
      <DatePicker
        name={name}
        onChange={handleChange}
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        calendarIcon={<Icon name="calendar" />}
        clearIcon={withClearIcon ? <Icon name="close" /> : null}
      />
    </div>
  );
};

export default FormDatePicker;
