import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import FormControl from 'components/FormControl';
import { ValidationErrors } from 'types/errors';
import { ActionPost, ScenarioFrequency } from 'types/models';

type Props = {
  defaultData?: ActionPost | null,
  errors?: ValidationErrors | null,
  onChange?(repeat: ActionPost['repeat']): void,
};

const ActionRepeatFieldset = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { defaultData, errors, onChange } = props;
  const [interval, setInterval] = useState<number | undefined>(
    defaultData?.repeat?.day || undefined,
  );
  const [frequency, setFrequency] = useState<number | undefined>(
    defaultData?.repeat?.frequency || undefined,
  );
  const [repeat, setRepeat] = useState<ScenarioFrequency>(
    defaultData?.repeat?.frequency
      ? ScenarioFrequency.LIMITED
      : defaultData?.repeat?.day
        ? ScenarioFrequency.UNLIMITED
        : ScenarioFrequency.NEVER,
  );

  const handleFormChange = useCallback((
    newRepeat: ScenarioFrequency = repeat,
    newInterval: number | undefined = interval,
    newFrequency: number | undefined = frequency,
  ) => {
    if (
      (ScenarioFrequency.LIMITED && newInterval === undefined) ||
      (ScenarioFrequency.UNLIMITED && newInterval === undefined && newFrequency === undefined)
    ) {
      return;
    }

    onChange?.(newRepeat !== ScenarioFrequency.NEVER ? {
      day: newInterval ?? null,
      frequency: newRepeat === ScenarioFrequency.UNLIMITED ? 0 : (newFrequency ?? null),
    } : null);
  }, [repeat, interval, frequency, onChange]);

  const handleRepeatChange = useCallback((_: string, newValue: string | null) => {
    const newRepeat = newValue as ScenarioFrequency;
    setRepeat(newRepeat);
    handleFormChange(newRepeat);
  }, [handleFormChange]);

  const handleIntervalChange = useCallback((newValue: string) => {
    const valueToInt = parseInt(newValue, 10);
    const newInterval = isNaN(valueToInt) ? undefined : valueToInt;
    setInterval(newInterval);
    handleFormChange(repeat, newInterval);
  }, [repeat, handleFormChange]);

  const handleFrequencyChange = useCallback((newValue: string) => {
    const valueToInt = parseInt(newValue, 10);
    const newFrequency = isNaN(valueToInt) ? undefined : valueToInt;
    setFrequency(newFrequency);
    handleFormChange(repeat, interval, newFrequency);
  }, [repeat, interval, handleFormChange]);

  return (
    <div className="ActionRepeatFieldset">
      <FormGroup label={t('plans:plan-repeat')}>
        <FormSelect
          name="repeat"
          value={repeat}
          onSelect={handleRepeatChange}
          withClearButton={false}
          selectOptions={[
            { label: t('plans:frequency-never'), value: ScenarioFrequency.NEVER },
            { label: t('plans:frequency-unlimited'), value: ScenarioFrequency.UNLIMITED },
            { label: t('plans:frequency-limited'), value: ScenarioFrequency.LIMITED },
          ]}
        />
      </FormGroup>
      {repeat === ScenarioFrequency.LIMITED && (
        <FormGroup label={t('plans:frequency')} error={errors?.frequency}>
          <FormControl
            type="number"
            min={1}
            name="frequency"
            defaultValue={frequency}
            isInvalid={!!errors?.frequency}
            onChange={handleFrequencyChange}
          />
        </FormGroup>
      )}
      {repeat !== ScenarioFrequency.NEVER && (
        <FormGroup label={t('plans:frequency-interval')} error={errors?.interval}>
          <FormControl
            type="number"
            min={1}
            name="interval"
            defaultValue={interval}
            isInvalid={!!errors?.interval}
            onChange={handleIntervalChange}
          />
        </FormGroup>
      )}
    </div>
  );
};

export default ActionRepeatFieldset;
