import './index.scss';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import MenuOptions from './MenuOptions';

const FirstTime = (): JSX.Element => {
  const { t } = useTranslation();

  const [showModal, hideModal] = useModal(() => (
    <Modal
      isOpened
      onClose={hideModal}
      title={t('home:video-title')}
    >
      <iframe
        className="FirstTime__intro__video"
        title="test"
        src="https://player.vimeo.com/video/518649850"
        width="670"
        height="390"
        allowFullScreen
      />
    </Modal>
  ), [t]);

  return (
    <div className="FirstTime">
      <div className="FirstTime__intro">
        <Button
          variant="list"
          className="FirstTime__intro__show-video"
          onClick={showModal}
        >
          <Icon name="play" />
          <div className="FirstTime__intro__show-video__description">
            <p className="FirstTime__intro__show-video__description__title">
              {t('home:video-title')}
            </p>
          </div>
        </Button>
      </div>
      <MenuOptions />
    </div>
  );
};

export default FirstTime;
