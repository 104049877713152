import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import type Misc from 'types/misc';
import { Channel, Scenario, type ScenarioTemplateReminder } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';
import HistoryStepDetailsItem from 'components/HistoryStep/Details/Item';
import ScenarioStepContentPreview from 'components/ScenarioStepContentPreview';
import Button from 'components/Button';

type Props = {
  action: ScenarioTemplateReminder,
  type: Misc.ActionType,
  scenario: Scenario,
  onShowEdit(): void,
};

const ScenarioDetailsActionCollapse = ({ action, type, scenario, onShowEdit }: Props): JSX.Element => {
  const { contactRoles } = organizationStore.currentOrganization!;
  const { t } = useTranslation();
  const isTask = type.toUpperCase() === 'TASK';

  const [showPreviewContentModal, hidePreviewContentModal] = useModal(() => (
    <ScenarioStepContentPreview
      scenarioId={scenario.id}
      content={action.content}
      channel={action?.channel || Channel.EMAIL}
      onClose={hidePreviewContentModal}
    />
  ), [action?.channel, action.content, scenario.id]);

  return (
    <div className="ScenarioDetailsAction__collapse">
      <div className="ScenarioDetailsAction__infos">
        <HistoryStepDetailsItem label={t('reminders:description')}>
          {action.description}
        </HistoryStepDetailsItem>
        {!isTask && (
          <Fragment>
            {!!action?.channel && (
              <HistoryStepDetailsItem label={t('reminders:channel')}>
                {t(getI18nChannel(action?.channel))}
              </HistoryStepDetailsItem>
            )}
            <HistoryStepDetailsItem label={t('reminders:recipients')}>
              {action.contacts
                .map((id) => contactRoles?.find((obj) => obj.identifier === id)?.value)
                .filter((value) => value)
                .join(', ')
              }
            </HistoryStepDetailsItem>
          </Fragment>
        )}
      </div>
      {!isTask && (
        <div className="ScenarioDetailsAction__infos">
          {action.subject && (
            <HistoryStepDetailsItem label={t('reminders:subject')}>
              {action.subject}
            </HistoryStepDetailsItem>
          )}
          {action.content && (
            <Fragment>
              <HistoryStepDetailsItem label={t('reminders:content')}>
                <div dangerouslySetInnerHTML={{ __html: action.content }} />
              </HistoryStepDetailsItem>
              <div className="ScenarioDetailsAction__infos__actions">
                <Button
                  variant="light"
                  small
                  title={t('common:edit')}
                  onClick={onShowEdit}
                >
                  {t('common:edit')}
                </Button>
                {action.channel !== Channel.SMS && (
                  <Button variant="light" small onClick={showPreviewContentModal}>
                    {t('common:preview')}
                  </Button>
                )}
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default ScenarioDetailsActionCollapse;
