import './index.scss';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import useTooltip from 'components/Tooltip/useTooltip';

const ModalWarningIcon = (): JSX.Element => {
  const { t } = useTranslation();

  const tooltipTarget = useTooltip<HTMLSpanElement>(
    <div className="ModalWarningIcon__tooltip">
      {t('common:form-contains-changes')}
    </div>,
  );

  return (
    <span ref={tooltipTarget} className="ModalWarningIcon">
      <Icon name="warning-circle" />
    </span>
  );
};

export default ModalWarningIcon;
