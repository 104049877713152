import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import useApiRequest from 'hooks/useApiRequest';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import type { ScenarioGroup, Selector } from 'types/models';
import apiSelectors from 'api/selectors';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';
import { RightsCategory } from 'types/models';
import ModalSelector from '../../ModalSelector';

type Props = {
  id: string,
  defaultData: Selector,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  group?: ScenarioGroup,
};

const SelectorsRowActions = (props: Props): JSX.Element => {
  const { id, defaultData, onActionDone, onActionError, group } = props;
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { error, isLoading, remove } = useApiRequest();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('common:remove'), ct('selectors:confirm-remove', { id }));
  }, [showConfirm, ct, t, id]);

  const [showModalEditSelector, hideModalEditSelector] = useModal(() => (
    <ModalSelector
      onClose={hideModalEditSelector}
      onActionDone={onActionDone}
      onActionError={onActionError}
      defaultData={defaultData}
      group={group}
      edit
    />
  ), [defaultData, onActionDone, onActionError, group]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const result = await remove(apiSelectors.deleteUrl(defaultData.id));
    if (result !== '') {
      onActionError(t('selectors:toast.error.delete'));
      return;
    }
    onActionDone(ct('selectors:toast.success.delete', { name: id }));
  }, [hideConfirm, remove, defaultData.id, onActionDone, ct, id, onActionError, t]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('selectors:toast.error.delete', { id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const actions = useMemo(() => [
    <ButtonWithUserRights
      action="UPDATE"
      category={RightsCategory.PREFERENCES_SELECTORS}
      variant="list"
      onClick={showModalEditSelector}
      title={t('common:edit')}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:edit')}
    </ButtonWithUserRights>,
    <ButtonWithUserRights
      action="DELETE"
      category={RightsCategory.PREFERENCES_SELECTORS}
      variant="list-danger"
      onClick={handleConfirmDelete}
      title={t('common:remove')}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:remove')}
    </ButtonWithUserRights>,
  ], [handleConfirmDelete, isLoading, showModalEditSelector, t]);

  return (
    <div className="Selectors__rowActions">
      <DropdownActions actions={actions} />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={id}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default observer(SelectorsRowActions);
