import './index.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BlockSection from 'components/BlockSection';
import Collapse from 'components/Collapse';
import type { HistoryActivity as HistoryActivityType } from 'types/models';
import HistoryActivity from 'components/HistoryActivity';

interface Props {
  historyActivity: HistoryActivityType[],
  shouldSuggestCreateAction?: boolean,
  onActionDone?: (message: string) => void,
  onActionError?: (message: string) => void,
  isLoading?: boolean,
  link?: string,
}

const DebitActionsCollapse = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    onActionDone = () => {},
    historyActivity,
    onActionError = () => {},
    isLoading = false,
    link,
  } = props;

  return (
    <div className="DebitActionsCollapse">
      <Collapse
        isDefaultOpen
        updateOpenWhenDefaultIsUpdated
        header={(
          <BlockSection
            title={t('debits:steps-group-history')}
            count={historyActivity ? historyActivity.length : undefined}
            isLoading={isLoading}
          >
            {link && <Link to={link}>{t('debits:go-to-list')}</Link>}
          </BlockSection>
        )}
      >
        <ul className="DebitActionsCollapse__list">
          {historyActivity?.map((history) => (
            <div className="DebitActionsCollapse__list__step" key={history.id}>
              <div className="DebitActionsCollapse__list__step__content">
              <HistoryActivity
                standalone
                data={history}
                onActionDone={onActionDone}
                onActionError={onActionError}
              />
              </div>
            </div>
          ))}
        </ul>
      </Collapse>
    </div>
  );
};

export default DebitActionsCollapse;
