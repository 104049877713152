import type { Collection } from 'types/api';
import type { Client, Debit, Note } from 'types/models';
import requester from 'utils/requester';

export type FetchAllForDebitParams = {
  id: Debit['id'] | undefined,
};

export type FetchAllForCustomerParams = {
  id: Client['id'] | undefined,
};

/**
 * Récupère une liste des notes du debit.
 *
 * @param id ID du debit.
 * @returns Une collection de notes.
 */
const allForDebit = async (
  { id }: FetchAllForDebitParams,
): Promise<Note[]> => {
  if (!id) {
    throw new Error('AllNotesForDebit: Missing Debit ID.');
  }

  const { data } = await requester.get<Collection<Note>>(
    `debits/${id}/notes`,
  );
  return data['hydra:member'];
};

/**
 * Récupère une liste des notes du client.
 *
 * @param id ID du client.
 * @returns Une collection de notes.
 */
const allForCustomer = async (
  { id }: FetchAllForCustomerParams,
): Promise<Note[]> => {
  if (!id) {
    throw new Error('AllNotesForCustomer: Missing Client ID.');
  }

  const { data } = await requester.get<Collection<Note>>(
    `clients/${id}/notes`,
  );
  return data['hydra:member'];
};

/**
 * URL de la ressource d'API pour la création d'une Note (POST).
 */
const createUrl = 'notes';

/**
 * Récupère l'URL de la ressource d'API pour modifier la note.
 *
 * @param id L'ID de la note
 * @returns URL du PUT.
 */
const updateUrl = (id: Note['id']) => `notes/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour supprimer la note.
 *
 * @param id L'ID de la note
 * @returns URL du DELETE.
 */
const deleteUrl = (id: Note['id']) => `notes/${id}`;

export default { allForCustomer, allForDebit, createUrl, updateUrl, deleteUrl };
