import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import dateFormatIso from 'utils/dateFormatIso';
import FormGroup from 'components/FormGroup';
import Icon from 'components/Icon';

type Props = {
  className?: string,
  filter: Misc.FilterDeclarationItem,
  onChange(name: string, value: string | string[]): void,
};

const FilterDateRangeInput = ({ filter, onChange, className }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const waitingApply = useRef<{ start: boolean; end: boolean }>({ start: true, end: true });

  const handleStartDateChange = useCallback((newDate: Date | undefined) => {
    waitingApply.current.start = true;
    setStartDate(newDate);
    if (newDate && endDate && endDate < newDate) {
      setEndDate(undefined);
    }
  }, [endDate]);

  const handleEndDateChange = useCallback((newDate: Date | undefined) => {
    waitingApply.current.end = true;
    setEndDate(newDate);
  }, []);

  const maxEndDate = useMemo(() => {
    if (!startDate) {
      return undefined;
    }

    const result = new Date(startDate);
    result.setDate(result.getDate() + 60);
    return result;
  }, [startDate]);

  useDebouncedEffect(() => {
    let newValue : string | string[] = '';
    if (startDate && endDate) {
      newValue = [dateFormatIso(startDate), dateFormatIso(endDate)];
    }
    onChange(filter.key, newValue);
  }, 300, [startDate, endDate]);

  return (
    <Fragment>
      <FormGroup className={className} label={t('common:select-period.from')}>
        <DatePicker
          onChange={handleStartDateChange}
          calendarIcon={<Icon name="calendar" />}
          clearIcon={null}
          value={startDate}
        />
      </FormGroup>
      <FormGroup className={className} label={t('common:select-period.to')}>
        <DatePicker
          onChange={handleEndDateChange}
          calendarIcon={<Icon name="calendar" />}
          clearIcon={null}
          value={endDate}
          minDate={startDate}
          maxDate={maxEndDate}
        />
      </FormGroup>
    </Fragment>
  );
};

export default FilterDateRangeInput;
