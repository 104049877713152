import { EavCategory } from 'types/models';

const getEavAddEditTitleLabel = (eavCategory: EavCategory, isEdit: boolean) => {
  if (isEdit) {
    const eavEditTable = {
      [EavCategory.CLIENT]: 'attributes:edit-client',
      [EavCategory.CONTACT]: 'attributes:edit-contact',
      [EavCategory.DEBIT]: 'attributes:edit-debit',
      [EavCategory.CREDIT]: 'attributes:edit-credit',
    };

    return eavEditTable[eavCategory];
  }
  const eavCreateTable = {
    [EavCategory.CLIENT]: 'attributes:add-client',
    [EavCategory.CONTACT]: 'attributes:add-contact',
    [EavCategory.DEBIT]: 'attributes:add-debit',
    [EavCategory.CREDIT]: 'attributes:add-credit',
  };

  return eavCreateTable[eavCategory];
};

export default getEavAddEditTitleLabel;
