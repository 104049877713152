import './index.scss';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';

const SettingsHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="SettingsHeader">
      <div className="SettingsHeader__links">
        <GoBackButton />
      </div>
      <div className="SettingsHeader__infos">
        <h1 className="SettingsHeader__infos__name">
          {t('common:menu.user:preferences')}
        </h1>
      </div>
    </div>
  );
};

export default SettingsHeader;
