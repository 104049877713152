import './index.scss';
import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import Icon from 'components/Icon';

type Props = {
  header: ReactNode,
  children: ReactNode,
  className?: string,
  isDefaultOpen?: boolean,
  updateOpenWhenDefaultIsUpdated?: boolean,
};

const Collapse: FC<Props> = (props) => {
  const { header, className, children, isDefaultOpen, updateOpenWhenDefaultIsUpdated } = props;
  const [open, setOpen] = useState<boolean>(!!isDefaultOpen);
  const oldIsDefaultOpened = useRef<boolean>(!!isDefaultOpen);

  useEffect(() => {
    if (updateOpenWhenDefaultIsUpdated
      && oldIsDefaultOpened.current !== isDefaultOpen) {
      setOpen(!!isDefaultOpen);
    }
  }, [isDefaultOpen, updateOpenWhenDefaultIsUpdated]);

  const classNames = classnames('Collapse', {
    'Collapse--open': open,
  }, className);

  const handleClickToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className={classNames}>
      <Button className="Collapse__header" onClick={handleClickToggle} variant="list">
        <div className="Collapse__header__content">
          {header}
        </div>
        <div className="Collapse__header__icon">
          <Icon name={open ? 'chevron-up' : 'chevron-down'} />
        </div>
      </Button>
      <div className="Collapse__content">
        {children}
      </div>
    </div>
  );
};

export default Collapse;
