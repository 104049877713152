import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import classnames from 'classnames';
import Icon from 'components/Icon';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import dateFormatIso from 'utils/dateFormatIso';
import { SelectorDateSign, SelectorDateType, SelectorPeriod } from 'types/models';

type Props = {
  name: string,
  operator?: string | null,
  onChangeValue(value: string | null): void,
  onDaysOperator(value: boolean): void,
  onMonthsOperator(value: boolean): void,
  defaultValue?: string | null,
  className?: string,
  isInvalid?: boolean,
};

const ConditionDate = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    onChangeValue,
    onDaysOperator,
    onMonthsOperator,
    defaultValue,
    operator,
    className,
    isInvalid,
  } = props;

  const [type, setType] = useState<string>();
  const [days, setDays] = useState<string>();
  const [sign, setSign] = useState<SelectorDateSign | null>(SelectorDateSign.PLUS);
  const [period, setPeriod] = useState<SelectorPeriod | null>(SelectorPeriod.DAYS);
  const [date, setDate] = useState<Date | [Date, Date] | undefined>();

  useEffect(() => {
    if (!defaultValue) {
      setDate(undefined);
      setDays('');
      return;
    }

    if (/^(\d{4}-\d{2}-\d{2})$/.test(defaultValue)) {
      setDate(new Date(defaultValue));
      setType(SelectorDateType.SPECIFIC_DATE);
      return;
    }

    const isMonth = operator ? operator.endsWith('_MONTHS') : period === SelectorPeriod.MONTHS;

    setType(SelectorDateType.DATE);
    setSign(defaultValue.startsWith('-') ? SelectorDateSign.MINUS : SelectorDateSign.PLUS);
    setPeriod(isMonth ? SelectorPeriod.MONTHS : SelectorPeriod.DAYS);
    setDays(defaultValue.replace(/[+-]/g, ''));

    if (isMonth) {
      onMonthsOperator(true);
      return;
    }

    onDaysOperator(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handelDateChange = useCallback((newDate: Date) => {
    setDate(newDate);
    onChangeValue(newDate ? dateFormatIso(newDate) : null);
  }, [onChangeValue]);

  const handelPeriodChange = useCallback((_name: string, newPeriod: string | null) => {
    if (newPeriod) {
      setPeriod(newPeriod as SelectorPeriod | null);
      onDaysOperator(newPeriod === SelectorPeriod.DAYS);
      onMonthsOperator(newPeriod === SelectorPeriod.MONTHS);
    }
  }, [onDaysOperator, onMonthsOperator]);

  const handelTypeChange = useCallback((_name: string, newType: string | null) => {
    if (newType) {
      setDate(undefined);
      setDays('');
      setType(newType);
      onDaysOperator(newType === SelectorDateType.DATE && period === SelectorPeriod.DAYS);
      onMonthsOperator(newType === SelectorDateType.DATE && period === SelectorPeriod.MONTHS);
      onChangeValue(null);
    }
  }, [onDaysOperator, onMonthsOperator, onChangeValue, period]);

  const handelSignChange = useCallback((_name: string, newSign: string | null) => {
    setSign(newSign as SelectorDateSign | null);
    if (!days) {
      onChangeValue(null);
      return;
    }
    const signValue = newSign === SelectorDateSign.PLUS ? '+' : '-';
    onChangeValue(`${signValue}${days}`);
  }, [onChangeValue, days]);

  const handelDaysChange = useCallback((newDays: string | null) => {
    setDays(newDays ?? '');

    if (!sign || !newDays) {
      onChangeValue(null);
      return;
    }
    const signValue = sign === SelectorDateSign.PLUS ? '+' : '-';
    onChangeValue(`${signValue}${newDays}`);
  }, [onChangeValue, sign]);

  const classNames = classnames('ConditionDate', className, {
    'ConditionDate--is-invalid': isInvalid,
  });

  return (
    <div className={classNames}>
      <FormSelect
        name='date_type'
        defaultValue={type}
        onSelect={handelTypeChange}
        withClearButton={false}
        placeholder={t('selectors:choose-option')}
        selectOptions={Object.values(SelectorDateType).map((val) => ({
          value: val,
          label: t(`selectors:selector-types.${val}`),
        }))}
      />
      {type === SelectorDateType.SPECIFIC_DATE && (
        <div className="ConditionDate__calendar">
          <DatePicker
            value={date}
            onChange={handelDateChange}
            calendarIcon={<Icon name="calendar" />}
            clearIcon={<Icon name="close" />}
          />
        </div>
      )}
      {(!!type && type !== SelectorDateType.SPECIFIC_DATE) && (
        <div className="ConditionDate__days">
          <FormSelect
            name="sign"
            className="ConditionDate__days__sign"
            defaultValue={sign}
            withClearButton={false}
            onSelect={handelSignChange}
            selectOptions={Object.values(SelectorDateSign).map((val) => ({
              value: val,
              label: t(`selectors:selector-types.${val}`),
            }))}
          />
          <FormControl
            type="number"
            name="number"
            placeholder={t('selectors:selector-types.number')}
            step={1}
            value={days}
            className="ConditionDate__days__value"
            autoComplete="off"
            onChange={handelDaysChange}
          />
          <FormSelect
            name="sign"
            className="ConditionDate__days__period"
            defaultValue={period}
            withClearButton={false}
            onSelect={handelPeriodChange}
            selectOptions={Object.values(SelectorPeriod).map((val) => ({
              value: val,
              label: t(`selectors:selector-types.${val}`),
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default ConditionDate;
