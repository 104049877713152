import './index.scss';
import classnames from 'classnames';
import type { DefaultTFuncReturn } from 'i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import useTooltip from 'components/Tooltip/useTooltip';
import Icon from 'components/Icon';

type Props = {
  to: string,
  title: string,
  isExact?: boolean,
  forceActive?: boolean,
  disabled?: boolean,
  textHover?: DefaultTFuncReturn,
  hasNotification?: boolean,
};

const HeaderNavItem = (props: Props): JSX.Element => {
  const {
    to,
    title,
    isExact = false,
    forceActive = false,
    disabled = false,
    textHover,
    hasNotification = false,
  } = props;

  const tooltip = useTooltip(
    <span className="HeaderNavItem__link__tooltip">{textHover}</span>,
    'center',
    !textHover,
  );

  const isActive = useRouteMatch({
    path: to,
    exact: isExact,
  });

  const classNames = classnames('HeaderNavItem', {
    'HeaderNavItem--active': isActive || forceActive,
    'HeaderNavItem--disabled': disabled,
  });

  return (
    <li className={classNames}>
      {(isActive || forceActive) && (
        <div className="HeaderNavItem__active-indicator" />
      )}
      {hasNotification && (
        <Icon name="dot" className="HeaderNavItem__notification" />
      )}
      <Link
        ref={tooltip}
        to={to}
        className="HeaderNavItem__link"
        onClick={(e) => disabled && e.preventDefault()}
      >
        {title}
      </Link>
    </li>
  );
};

export default HeaderNavItem;
