import apiNotifications from 'api/notifications';
import authStore from 'stores/Auth';
import Config from 'config';

export type RunningJob = {
  type?: string;
  jobId: string;
};

export type JobExecution = {
  jobExecutionId: number;
};

export const connectWebSocket = async ( onMessage: (data:any) => void ): Promise<WebSocket> => {
  const { user } = authStore;

  if (!user) {
    throw new Error('connectWebSocket: User not authenticated.');
  }

  if (!Config.WEBSOCKET_URL) {
    throw new Error('connectWebSocket: Missing web socket url.');
  }

  const ticket = await apiNotifications.fetchWebSocketTicket();
  const socket = new WebSocket(`${Config.WEBSOCKET_URL}/socket/connect?ticket=${ticket}`);

  socket.onopen = () => {
    socket.send(JSON.stringify([5, 'dunforce/ping']));
    socket?.send(JSON.stringify([5, `dunforce/${user.id}/notifications`]));
  };

  socket.onmessage = (event) => {
    const parsedData = JSON.parse(event.data);
    if (parsedData[1] === `dunforce/${user.id}/notifications`) {
      onMessage(parsedData[2]);
    }
  };

  socket.onerror = () => {
    throw new Error('connectWebSocket: Something went wrong');
  };

  return socket;
};

export const addJobToStorage = (type: string, jobId: string) => {
  const runningJobs = JSON.parse(localStorage.getItem('runningJobs') || '[]');
  runningJobs.push({ type, jobId });
  localStorage.setItem('runningJobs', JSON.stringify(runningJobs));
};

export const removeJobFromStorage = (jobId: string) => {
  const runningJobs: RunningJob[] = JSON.parse(localStorage.getItem('runningJobs') || '[]');
  const updatedJobs = runningJobs.filter(({ jobId: id }) => id !== jobId);
  localStorage.setItem('runningJobs', JSON.stringify(updatedJobs));
};

export const getRunningJob = (): RunningJob | null => {
  const runningJobs: RunningJob[] = JSON.parse(localStorage.getItem('runningJobs') || '[]');
  return runningJobs.length ? runningJobs[0] : null;
};
