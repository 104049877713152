import './index.scss';
import PublicLayout from 'layout/PublicLayout';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import apiBoarding from 'api/boarding';
import ForgottenPasswordForm from './Form';

const ForgottenPassword = (): JSX.Element => {
  const { t } = useTranslation();

  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const handleSubmit = useCallback(async (emailToSend: string) => {
    setHasError(false);
    setEmail(emailToSend);
    try {
      await apiBoarding.resetPassword(emailToSend);
      setSentEmail(true);
    } catch {
      setHasError(true);
    }
  }, []);

  return (
    <PublicLayout>
      <div className="ForgottenPassword">
        <div className="ForgottenPassword__form">
          <h2 className="ForgottenPassword__title">{t('forgotten-password:title')}</h2>
          {sentEmail && (
            <div className="ForgottenPassword__description">
              <h3>{t('forgotten-password:email-sent', { email })}</h3>
              <p className="ForgottenPassword__description__text">
                {t('forgotten-password:email-sent-description')}
              </p>
              <p className="ForgottenPassword__description__text">
                {t('forgotten-password:if-email-not-received')}
              </p>
              <Link to="/login">{t('forgotten-password:go-to-login')}</Link>
            </div>
          )}
          {!sentEmail && (
            <ForgottenPasswordForm
              onSubmit={handleSubmit}
              hasError={hasError}
            />
          )}
        </div>
      </div>
    </PublicLayout>
  );
};

export default observer(ForgottenPassword);
