import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type HelperTextRule = {
  id: number,
  valid: boolean,
  label: string,
};

const usePasswordValidator = (initialPassword = '') => {
  const { t } = useTranslation();
  const [password, setPassword] = useState(initialPassword);
  const helperText: HelperTextRule[] = useMemo(
    () => [
      {
        id: 1,
        valid: /[a-z]/.test(password),
        label: t('validators:password:one-lowercase'),
      },
      {
        id: 2,
        valid: /[A-Z]/.test(password),
        label: t('validators:password:one-uppercase'),
      },
      {
        id: 3,
        valid: /[0-9]/.test(password),
        label: t('validators:password:one-number'),
      },
      {
        id: 4,
        valid: /\W|_/.test(password),
        label: t('validators:password:one-special-character'),
      },
      {
        id: 5,
        valid: password.length > 7,
        label: t('validators:password:minimum-length'),
      },
    ],
    [password, t],
  );

  const validValue = useMemo(() => !helperText.some(({ valid }) => valid === false), [
    helperText,
  ]);

  return { helperText, valid: validValue, password, setPassword };
};

export default usePasswordValidator;
