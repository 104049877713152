import { useEffect, useState } from 'react';
import { DefaultValue, getDefaultValue } from 'components/FormSelect/utils';
import useSelectCustomer from './useSelectCustomer';
import FormSelectCustomerShell, { CommonProps } from './Shell';

type Props = CommonProps & {
  withId?: boolean,
  defaultValue?: DefaultValue,
};

const FormSelectCustomer = (props: Props): JSX.Element => {
  const {
    withId = true,
    name,
    defaultValue,
    placeholder,
    value,
    className,
    isInvalid,
    withClearButton,
    onSelect,
  } = props;

  const [selectedValue, setSelectedValue] = useState<string>('');

  const { getOptions, getDefaultOption, options } = useSelectCustomer(
    setSelectedValue,
    { withId, defaultValue },
  );

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    if (options.length === 0) {
      getDefaultOption();
      return;
    }

    const initValue = getDefaultValue(defaultValue, options);
    setSelectedValue(Array.isArray(initValue) ? initValue.join(',') : initValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  return (
    <FormSelectCustomerShell
      withId={withId}
      name={name}
      placeholder={placeholder}
      value={value}
      className={className}
      isInvalid={isInvalid}
      withClearButton={withClearButton}
      getOptions={getOptions}
      options={options}
      valueToSelect={selectedValue}
      onSelect={onSelect}
    />
  );
};

export default FormSelectCustomer;
