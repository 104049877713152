import './index.scss';
import { useMemo } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import classnames from 'classnames';
import FormCheck from 'components/FormCheck';

export type FormCheckboxValue = string | number;

export type FormCheckboxItemData = {
  value: FormCheckboxValue,
  label?: DefaultTFuncReturn,
  id?: string,
  disabled?: boolean,
  checked?: boolean,
};

type Props = {
  items: FormCheckboxItemData[],
  name: string,
  disabled?: boolean,
  className?: string,
  onChange?: (newValue: FormCheckboxValue) => void,
  value?: FormCheckboxValue,
  defaultValue?: FormCheckboxValue,
};

const FormCheckboxGroup = (props: Props): JSX.Element => {
  const {
    items,
    name,
    disabled,
    className,
  } = props;

  const classNames = useMemo(() => (
    classnames('FormCheckboxGroup', className, {
      'FormCheckboxGroup--disabled': disabled,
    })
  ), [className, disabled]);

  return (
    <div className={classNames}>
      {items.map((itemData: FormCheckboxItemData) => (
        <FormCheck
          key={`${itemData.id || 'form-check-item'}-${itemData.value}`}
          className='FormCheckboxGroupItem'
          value={itemData.value}
          name={name}
          id={itemData.id}
          checked={itemData.checked}
          label={itemData.label}
          disabled={disabled || itemData.disabled}
        />
      ))}
    </div>
  );
};

export default FormCheckboxGroup;
