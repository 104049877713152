import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import FormSelect from 'components/FormSelect';
import Button from 'components/Button';
import Icon from 'components/Icon';
import DataTableFilterItem from 'components/DataTable/Filters/Item';
import FormControl from 'components/FormControl';
import getI18nOperatorFilter from 'utils/getI18nOperatorFilter';

type Props = {
  filters: Misc.FilterDeclarationItem[],
  defaultValue: Misc.Filter,
  removable: boolean,
  onRemove: () => void,
  onChange(filter: Misc.Filter): void,
  getFilterValue: (filterKey: string) => string | string[] | null,
};

const FilterAdvancedFieldItem = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<string | null>();
  const [selectedOperator, setSelectedOperator] = useState<Misc.FilterOperators | null>();
  const [selectedValue, setSelectedValue] = useState<Misc.FilterValue>();
  const {
    filters,
    defaultValue,
    onRemove,
    removable,
    onChange,
    getFilterValue,
  } = props;

  const currentFilter = useMemo(() => (
    filters.find(({ key }) => key === selectedOption) || null
  ), [filters, selectedOption]);

  useEffect(() => {
    if (!selectedOperator || !selectedOption) {
      return;
    }

    if (
      defaultValue.name !== selectedOption ||
      defaultValue.operator !== selectedOperator ||
      defaultValue.value !== selectedValue
    ) {
      onChange({
        name: selectedOption,
        operator: selectedOperator,
        value: defaultValue.name === selectedOption && selectedValue ? selectedValue : null,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, selectedOperator, selectedValue]);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    setSelectedOption(!!defaultValue.name ? defaultValue.name : null);
    setSelectedValue(defaultValue.value);
    setSelectedOperator(defaultValue.operator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleChangeOption = useCallback((_name: string, newValue: string | null) => {
    setSelectedOption(newValue);
    setSelectedOperator(
      filters.find(({ key }) => key === newValue)?.operators?.[0] || null,
    );
  }, [filters]);

  const handleChangeOperator = useCallback((_name: string, newValue: string | null) => {
    setSelectedOperator(newValue as Misc.FilterOperators);
  }, []);

  return (
    <div className="FilterAdvancedFieldItem">
      <FormSelect
        name="options"
        className="FilterAdvancedFieldItem__options"
        placeholder={t('common:select')}
        defaultValue={selectedOption}
        onSelect={handleChangeOption}
        withClearButton={false}
        selectOptions={(filters ?? []).map(
          ({ key, title }) => ({ label: title as string, value: key }),
        )}
      />
      <FormSelect
        name="operators"
        className="FilterAdvancedFieldItem__operators"
        placeholder={t('common:select')}
        onSelect={handleChangeOperator}
        defaultValue={selectedOperator}
        withClearButton={false}
        selectOptions={(currentFilter?.operators ?? []).map(
          (operator) => ({
            label: t(getI18nOperatorFilter(operator)),
            value: operator,
          }),
        )}
      />
      {currentFilter ?
        <DataTableFilterItem
          filter={currentFilter}
          currentValue={selectedValue}
          getFilterValue={getFilterValue}
          onChange={(filter: Misc.Filter) => setSelectedValue(filter.value)}
          firstIsDefault={true}
          showLabel={false}
        /> :
        <FormControl
          className="FilterAdvancedFieldItem__readOnly"
          type="text"
          readOnly
        />
      }
      {removable && (
        <Button
          className="FilterAdvancedFieldItem__remove"
          variant="link"
          onClick={() => { onRemove(); }}
        >
          <Icon name="minus-circle" />
        </Button>
      )}
    </div>
  );
};

export default FilterAdvancedFieldItem;
