import './index.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import Config from 'config';
import currenciesStore from 'stores/Currencies';
import type { ValidationErrors } from 'types/errors';
import type { Currency, Organization } from 'types/models';
import getI18nTypeOrganization from 'utils/getI18nTypeOrganization';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import Loading from 'components/Loading';

type Props = {
  defaultData: Organization | null,
  errors?: ValidationErrors | null,
};

const CurrentOrganizationForm = ({ defaultData, errors }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currencies, currenciesOptions, isFetched, isBusy, fetchAll } = currenciesStore;
  const { currency } = defaultData ?? {};

  useMemo(() => {
    if (!currenciesOptions && !isBusy && !isFetched) {
      fetchAll();
    }
  }, [currenciesOptions, fetchAll, isBusy, isFetched]);

  const currentCurrency = useMemo<Currency | undefined>(
    () => currencies?.find(
      (currencyData: Currency) => currencyData.code === currency,
    ),
    [currencies, currency],
  );

  const typeOptions = useMemo(() => (
    Config.TYPES_ORGANIZATION.map((type) => ({
      label: t(getI18nTypeOrganization(type)),
      value: type,
    }))
  ), [t]);

  return (
    <div className="CurrentOrganizationForm">
      <FormFieldset>
        <FormGroup label={t('organizations:corporate-name')} error={errors?.name}>
          <FormControl
            name="name"
            autoComplete="off"
            defaultValue={defaultData?.corporateName || ''}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
        <FormGroup label={t('organizations:legal-id')} error={errors?.legalId}>
          <FormControl
            name="legalId"
            autoComplete="off"
            defaultValue={defaultData?.legalId || ''}
            isInvalid={!!errors?.legalId}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('organizations:currency')} error={errors?.currency}>
          {!currentCurrency && (<Loading />)}
          {currentCurrency && (<FormSelect
            name="currency"
            placeholder={t('common:please-choose')}
            selectOptions={currenciesOptions}
            defaultValue={currentCurrency.id}
            isInvalid={!!errors?.currency}
          />)}
        </FormGroup>
        <FormGroup label={t('organizations:type')} error={errors?.type}>
          <FormSelect
            name="type"
            placeholder={t('common:please-choose')}
            selectOptions={typeOptions}
            defaultValue={defaultData?.type || ''}
            isInvalid={!!errors?.type}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('organizations:address')} error={errors?.address}>
          <FormControl
            name="address"
            autoComplete="off"
            defaultValue={defaultData?.address1 || ''}
            isInvalid={!!errors?.address}
          />
        </FormGroup>
        <FormGroup label={t('organizations:city')} error={errors?.city}>
          <FormControl
            name="city"
            autoComplete="off"
            defaultValue={defaultData?.city || ''}
            isInvalid={!!errors?.city}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('organizations:postal-code')} error={errors?.postalCode}>
          <FormControl
            name="postalCode"
            autoComplete="off"
            defaultValue={defaultData?.postalCode || ''}
            isInvalid={!!errors?.postalCode}
          />
        </FormGroup>
        <FormGroup label={t('organizations:country')} error={errors?.country}>
          <FormControl
            name="country"
            autoComplete="off"
            // On met que la France pour l'instant
            defaultValue="FR"
            isInvalid={!!errors?.country}
            disabled
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default observer(CurrentOrganizationForm);
