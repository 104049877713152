import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import Button from 'components/Button';
import Icon from 'components/Icon';
import CreditEditModal from 'components/CreditEditModal';
import type { Client } from 'types/models';

type Props = {
  reference: string,
  maxAmount: number,
  customerIdentifier: Client['id'],
  onDone(title: string, message: string): void,
};

const DebitsRowActionAddCredit = (props: Props): JSX.Element => {
  const { reference, customerIdentifier, onDone, maxAmount } = props;
  const { t } = useTranslation();

  const [showEditCreditModal, hideEditModal] = useModal(() => (
    <CreditEditModal
      customerIdentifier={customerIdentifier}
      onDone={onDone}
      onClose={hideEditModal}
      maxAmount={maxAmount}
      assignedTo={reference}
    />
  ), [customerIdentifier, maxAmount, onDone, reference]);

  return (
    <Button
      variant="list"
      onClick={showEditCreditModal}
      className="DebitsRowActionAddCredit"
    >
      <Icon name="plus-circle" />
      {t('debits:add-payment')}
    </Button>
  );
};

export default DebitsRowActionAddCredit;
