import './index.scss';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import Button from 'components/Button';

type Props = {
  moreActions?: JSX.Element | null,
  children: ReactNode,
};

const ActionsGroup = ({ moreActions, children }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [othersOpen, setOthersOpen] = useState<boolean>(false);

  const toggleOthers = useCallback(() => {
    setOthersOpen(!othersOpen);
  }, [othersOpen]);

  return (
    <div className="ActionsGroup">
      {children}
      {!!moreActions && (
        <div className="ActionsGroup__others">
          <Button
            variant="list"
            onClick={toggleOthers}
            title={t<string>('common:more-actions')}
          >
            {t('common:more-actions')}
            <Icon name="dots" />
          </Button>
        </div>
      )}
      {othersOpen && (
        <div className="ActionsGroup__others__collapse">
          {moreActions}
        </div>
      )}
    </div>
  );
};

export default ActionsGroup;
