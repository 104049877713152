import './index.scss';
import type { MouseEvent, ReactNode } from 'react';
import { useCallback } from 'react';
import Icon from 'components/Icon';

interface Props {
  onShowView(): void,
  children: ReactNode,
}

const ListItemButton = ({ onShowView, children }: Props): JSX.Element => {
  const handleClick = useCallback((event: MouseEvent<HTMLLIElement | HTMLAnchorElement>) => {
    if (event.target instanceof HTMLAnchorElement) {
      return;
    }
    onShowView();
  }, [onShowView]);

  return (
    <li className="ListItemButton" onClick={handleClick} role="button">
      <div className="ListItemButton__body">
        {children}
      </div>
      <div className="ListItemButton__button">
        <Icon name="chevron-right" />
      </div>
    </li>
  );
};

export default ListItemButton;
