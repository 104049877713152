import requester from 'utils/requester';
import type { Collection } from 'types/api';
import type { Currency } from 'types/models';

const all = async (): Promise<Currency[]> => {
  const queryData = new URLSearchParams();
  queryData.append('activated', '1');
  const { data } = await requester.get<Collection<Currency>>(`currencies?${queryData.toString()}`);
  return data['hydra:member'];
};

/**
 * Récupère l'URL de la ressource API pour mapper une association de currency.
 *
 * @param id L'ID d'currency
 * @returns URL de l'association.
 */
const resourceUrl = (id: Currency['id']) => `/api/currencies/${id}`;

export default { all, resourceUrl };
