import './index.scss';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';
import { ImportItemStep } from 'types/models';
import { ReportContext } from '../..';
import LoadingOrRedirect from './LoadingOrRedirect';

const ReportHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const report = useContext(ReportContext);

  const isSteps = useMemo(() => (
    !!report?.stepExecutions.filter((file) => (
      (Object.values(ImportItemStep) as string[]).includes(file.label)
    )).length
  ), [report?.stepExecutions]);

  return (
    <div className="ReportHeader">
      <div className="ReportHeader__links">
        <GoBackButton />
      </div>
      {!report && (
        <LoadingOrRedirect />
      )}
      {report && (
        <div className="ReportHeader__infos">
          <h1 className="ReportHeader__infos__name" title={isSteps ? report.fileName : undefined}>
            {isSteps ? (
              report.fileName
            ) : (
              report.importType || `${t('reports:import')} ${report.fileExtension.toUpperCase()}`
            )}
          </h1>
        </div>
      )}
    </div>
  );
};

export default ReportHeader;
