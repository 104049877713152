import './index.scss';
import { useContext, useMemo } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import getI18nReportStatus from 'utils/getI18nReportStatus';
import { ImportItemStatus } from 'types/models';
import { ReportContext } from '../..';
import SecondaryInfos from './SecondaryInfos';

const ImportsReportInfos = (): JSX.Element | null => {
  const { currentOrganization } = organizationStore;
  const { type } = currentOrganization!;
  const { t } = useContextualTranslation(type);
  const report = useContext(ReportContext);

  const className = classnames('ImportsReportInfos', {
    'ImportsReportInfos--warning': report?.stepExecutions.at(-1)?.warnings.length,
    'ImportsReportInfos--danger': (
      report?.status === ImportItemStatus.FAILED ||
      report?.stepExecutions.at(-1)?.failures.length ||
      report?.stepExecutions.at(-1)?.errors.length
    ),
  });

  const status = useMemo(() => {
    if (!report) {
      return null;
    }

    if ( report.status === ImportItemStatus.FAILED ) {
      return t(getI18nReportStatus(ImportItemStatus.FAILED));
    }
    return t(getI18nReportStatus(report.status));

  }, [report, t]);

  if (!report) {
    return null;
  }

  return (
    <div className={className}>
      <div className="ImportsReportInfos__section">
        <h4 className="ImportsReportInfos__section__header">
          {t('reports:type')}
        </h4>
        <p className="ImportsReportInfos__section__title">
          {report.label || report.type}
        </p>
      </div>
      <div className="ImportsReportInfos__section">
        <h4 className="ImportsReportInfos__section__header">
          {t('common:status')}
        </h4>
        <p className="ImportsReportInfos__section__status">
          {status}
        </p>
      </div>
      <SecondaryInfos report={report} />
    </div>
  );
};

export default observer(ImportsReportInfos);
