import './index.scss';
import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import FormGroup from 'components/FormGroup';
import FormRadios from 'components/FormRadios';
import type { FormRadioValue } from 'components/FormRadios/Item';

type Props = {
  filter: Misc.FilterDeclarationItem,
  defaultValue: string | null,
  onChange(name: string, value: string | null): void,
  className?: string,
};

const FilterYesNo = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    filter,
    defaultValue,
    onChange,
    className,
  } = props;

  const handleChange = useCallback(
    (newValue: FormRadioValue) => {
      onChange(filter.key, newValue as string);
    },
    [filter, onChange],
  );

  const classNames = useMemo(
    () => classnames('FiltersItemYesNo', className),
    [className],
  );

  const radioItems = useMemo(() => [
    {
      value: '',
      label: t('common:any'),
      id: `radio-${filter.key}-all`,
    },
    {
      value: '1',
      label: t('common:yes'),
      id: `radio-${filter.key}-yes`,
    },
    {
      value: '0',
      label: t('common:no'),
      id: `radio-${filter.key}-no`,
    },
  ], [t, filter]);

  return (
    <FormGroup label={filter.title} className={classNames}>
      <FormRadios
        name={filter.key}
        items={radioItems}
        defaultValue={defaultValue || ''}
        onChange={handleChange}
      />
    </FormGroup>
  );
};

export default FilterYesNo;
