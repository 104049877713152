import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { Debit } from 'types/models';
import { Channel } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';
import type { ModalFormData } from 'components/ModalForm';
import Misc from 'types/misc';
import { JobExecution } from 'utils/webSocket';
import ModalForm from 'components/ModalForm';
import useIsMountedRef from 'hooks/useIsMountedRef';
import FormSelect from 'components/FormSelect';
import FormGroup from 'components/FormGroup';
import ChannelIcon from 'components/ChannelIcon';
import Icon from 'components/Icon';
import CreateReminderEdit from './Edit';

type Props = {
  filters?: Misc.Filter[],
  totalRecords?: number,
  debitData?: Debit | null,
  customerId?: number | null,
  onSelectAction?(type: string): void,
  onDone(message: string): void,
  onResponse?(response: JobExecution): void,
  onClose(): void,
};

const CreateReminder = (props: Props): JSX.Element => {
  const { debitData, customerId, onDone, onClose, onResponse, onSelectAction, filters, totalRecords } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const [channel, setChannel] = useState<Misc.ActionType | Channel>(Channel.SMS);
  const [icon, setIcon] = useState<Misc.ActionType | Channel>(Channel.SMS);

  const closeSelf = useCallback(() => {
    if (isMountedRef.current) {
      onClose();
    }
  }, [isMountedRef, onClose]);

  const [showReminderEdit, hideReminderEdit] = useModal(() => (
    <CreateReminderEdit
      totalRecords={totalRecords}
      filters={filters}
      debitData={debitData}
      customerId={customerId}
      channel={channel as Channel}
      onDone={onDone}
      onResponse={onResponse}
      onClose={() => { hideReminderEdit(); closeSelf(); }}
    />
  ), [debitData, customerId, onResponse, channel, onDone, closeSelf, filters, totalRecords]);

  const handleChooseChannel = useCallback((formData: ModalFormData | null) => {
    if (!formData || !isMountedRef.current) {
      return;
    }

    if (onSelectAction) {
      onSelectAction(formData.channel as Misc.ActionType);
      closeSelf();
      return;
    }

    setChannel(formData.channel as Channel);
    showReminderEdit();
  }, [isMountedRef, showReminderEdit, onSelectAction, closeSelf]);

  const channels = useMemo(() => {
    const actions: { value: Misc.ActionType | Channel; label: string; }[] = [];

    if (onSelectAction) {
      actions.push({
        value: 'TASK',
        label: t('common:task'),
      });
    }

    actions.push(...Object.values(Channel).map((value) => ({
      value,
      label: t('reminders:reminder-by-channel', { channel: t(getI18nChannel(value)) }),
    })));

    setIcon(actions[0].value);

    return actions;
  }, [t, onSelectAction]);

  return (
    <ModalForm
      isOpened
      className="CreateReminder"
      title={onSelectAction ? t('plans:add-action') : t('reminders:reminder.create')}
      onSave={handleChooseChannel}
      onCancel={closeSelf}
      saveText={t<string>('common:next')}
    >
      <FormGroup
        label={onSelectAction ? t('reminders:select-action') : t('reminders:reminder.select-channel')}
        className="CreateReminder__select"
      >
        {icon === 'TASK' ?
          <Icon name="list" className="ChannelIcon" /> :
          <ChannelIcon channel={icon as Channel} />
        }
        <FormSelect
          name="channel"
          selectOptions={channels}
          defaultValue={channels[0].value}
          withClearButton={false}
          searchable={false}
          onSelect={(_name, newIcon) => setIcon(newIcon as Misc.ActionType)}
        />
      </FormGroup>
    </ModalForm>
  );
};

export default CreateReminder;
