import './index.scss';
import React from 'react';
import classnames from 'classnames';
import { SortByFn } from 'react-table';
import Icon from '../../../Icon';

type Props<DataType extends object = {}> = {
  isSorted: boolean,
  isSortedDesc?: boolean,
  sortType?: string | SortByFn<DataType>,
};

const DataTableHeadSortLabel = <DataType extends object = {}>(
  props: Props<DataType>,
): JSX.Element | null => {
  const { isSorted, isSortedDesc, sortType } = props;

  const sortIconName = React.useMemo(() => {
    if (!isSorted) {
      return 'sort';
    }

    if (sortType === 'alphanumeric') {
      return isSortedDesc ? 'sort-alpha-down' : 'sort-alpha-up';
    }

    if (sortType === 'basic') {
      return isSortedDesc ? 'sort-numeric-down' : 'sort-numeric-up';
    }

    if (sortType === 'datetime') {
      return isSortedDesc ? 'sort-amount-down' : 'sort-amount-up';
    }

    return 'sort';
  }, [isSorted, isSortedDesc, sortType]);

  const classNames = classnames('DataTableHeadSortLabel', {
    'DataTableHeadSortLabel--sorted': isSorted,
  });

  return (
    <span className={classNames}>
      <Icon name={sortIconName} />
    </span>
  );
};

export default DataTableHeadSortLabel;
