import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import CreditEditModal from 'components/CreditEditModal';

export type RouteParams = {
  editType: 'new' | 'edit',
  creditId?: string,
};

type Props = {
  onDone: (title: string, message: string) => void,
  onClose(): void,
};

const CreditEdit = ({ onDone, onClose }: Props): JSX.Element | null => {
  const { creditId } = useParams<RouteParams>();

  const [showModal, hideModal] = useModal(() => {
    const handleClose = () => {
      hideModal();
      onClose();
    };

    return (
      <CreditEditModal
        creditId={parseInt(creditId || '')}
        onDone={onDone}
        onClose={handleClose}
      />
    );
  }, [creditId, onDone, onClose]);

  useEffect(() => {
    showModal();
    return () => {
      hideModal();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CreditEdit;
