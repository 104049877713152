import uniq from 'lodash/uniq';
import type { TFunction } from 'i18next';
import Config from 'config';
import organizationStore from 'stores/Organization';
import currenciesStore from 'stores/Currencies';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';
import {
  ComparisonOperators,
  SelectorCreditAttribute,
  SelectorDebitAttribute,
  SelectorDebitAttributeType,
  SelectorDebtSelector,
  SelectorSystemClientAttribute,
  SelectorSystemClientAttributeType,
  EqualityOperators,
  ListOperators,
  SelectorsFieldsData,
  OrganizationEAVs,
  SelectorsEntity,
  SelectorsType,
  SelectorsFieldsTypes,
  FirstSelectorField,
  Currency,
  DebitOperators,
} from 'types/models';
import { getAttributeFromEAVValue } from 'utils/getAttributeFromEAVValue';

/**
 * Récupère la description d'un champ.
 * @param field Le champ pour lequel obtenir la description.
 * @param t La fonction de traduction.
 * @returns La description du champ.
 */
const getFieldDescription = (field: string | null, t: TFunction): SelectorsFieldsData | null => {
  if (!field) {
    return null;
  }

  const { attributes, businessUnits, managers } = organizationStore;
  const { currencies } = currenciesStore;

  const fieldEndsWith = (array: string[]) => (
    array.some((suffix) => field!.endsWith(suffix))
  );

  if (fieldEndsWith(['_totalDebt}', '_dueDebt}', '_amount}'])) {
    field = 'number';
  } else if (fieldEndsWith(['_dueDate}', '_createdAt}', '_paidAt}'])) {
    field = 'date';
  } else if (fieldEndsWith(['_subject}'])) {
    field = 'text';
  } else if (fieldEndsWith(['_dispatch}'])) {
    field = 'boolean';
  }

  if (field.startsWith('{EAV_')) {
    const attributeType = getAttributeFromEAVValue(field, attributes)?.type;
    field = attributeType?.replace('eav_attr_', '') ?? field;
  }

  switch (field) {
    case 'FIRST_FIELD': return {
      type: SelectorsFieldsTypes.SELECT,
      options: Object.entries(SelectorsType).map(([key, value]) => ({
        value: key,
        label: t(`selectors:selector-types.${value}`),
      })),
    };
    case 'CLIENT': return {
      type: SelectorsFieldsTypes.SELECT,
      options: Object.entries(SelectorSystemClientAttribute).map(
        ([key, value]) => ({
          value: `{CLIENT_${key}}`,
          label: t(`selectors:selector-types.${value}`),
        }),
      ),
    };
    case 'EAV_CLIENT': return {
      type: SelectorsFieldsTypes.SELECT,
      placeholder: t('selectors:choose-attribute') as string,
      options: ((attributes as OrganizationEAVs)?.client ?? []).map(({ identifier, label }) => ({
        value: `{EAV_CLIENT_${identifier}}`,
        label,
      })),
    };
    case 'EAV_DEBIT': return {
      type: SelectorsFieldsTypes.SELECT,
      placeholder: t('selectors:choose-attribute') as string,
      options: [
        ...((attributes as OrganizationEAVs)?.debit ?? []).map(({ identifier, label }) => ({
          value: `{EAV_DEBIT_${identifier}}`,
          label,
        })),
        ...Object.entries(SelectorDebitAttribute).map(
          ([key, value]) => ({
            value: `{DEBIT_${key}}`,
            label: t(`selectors:selector-types.${value}`),
          }),
        ),
      ],
    };
    case 'EAV_CREDIT': return {
      type: SelectorsFieldsTypes.SELECT,
      placeholder: t('selectors:choose-attribute') as string,
      options: [
        ...((attributes as OrganizationEAVs)?.credit ?? []).map(({ identifier, label }) => ({
          value: `{EAV_CREDIT_${identifier}}`,
          label,
        })),
        ...Object.entries(SelectorCreditAttribute).map(
          ([key, value]) => ({
            value: `{CREDIT_${key}}`,
            label: t(`selectors:selector-types.${value}`),
          }),
        ),
      ],
    };
    case 'EAV_CONTACT': return {
      type: SelectorsFieldsTypes.SELECT,
      placeholder: t('selectors:choose-attribute') as string,
      options: ((attributes as OrganizationEAVs)?.contact ?? []).map(({ identifier, label }) => ({
        value: `{EAV_CONTACT_${identifier}}`,
        label,
      })),
    };
    case 'DEBIT': return {
      type: SelectorsFieldsTypes.SELECT,
      options: Object.entries(SelectorDebtSelector).map(
        ([key, value]) => ({
          value: `{DEBIT_${key}}`,
          label: t(`selectors:selector-types.${value}`),
        }),
      ),
    };
    case 'FREE_FIELD': return {
      placeholder: '',
      type: SelectorsFieldsTypes.TEXT,
    };
    case '{CLIENT_businessUnit}': return {
      type: SelectorsFieldsTypes.SELECT,
      options: (businessUnits ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
      operators: Object.values(EqualityOperators),
    };
    case '{CLIENT_assignations}':
      return {
        type: SelectorsFieldsTypes.SELECT,
        options: managers?.map(({ id, firstName, lastName }) => ({
          value: id.toString(),
          label: `${firstName || ''} ${lastName || ''}`,
        })),
        operators: Object.values(ListOperators),
        isMultiple: true,
      };
    case '{CLIENT_type}': return {
      type: SelectorsFieldsTypes.SELECT,
      options: Object.entries(SelectorSystemClientAttributeType).map(
        ([key, value]) => ({
          value: key,
          label: t(`selectors:selector-types.${value}`),
        }),
      ),
      operators: Object.values(EqualityOperators),
    };
    case '{DEBIT_type}': return {
      type: SelectorsFieldsTypes.SELECT,
      optionsBy: {
        FILTER_BY : [
          { value: 'true', label: t('common:yes') },
          { value: 'false', label: t('common:no') },
        ],
        default : Object.entries(SelectorDebitAttributeType).map(
          ([key, value]) => ({
            value: key,
            label: t(`selectors:selector-types.${value}`),
          }),
        ),
      },
      operators: [
        ...Object.values(EqualityOperators),
        ...Object.values(DebitOperators),
      ],
    };
    case '{DEBIT_lastActive}': return {
      type: SelectorsFieldsTypes.SELECT,
      options: [
        { value: 'true', label: t('selectors:selector-types.last-active-yes') },
        { value: 'false', label: t('selectors:selector-types.last-active-no') },
      ],
      operators: Object.values(EqualityOperators),
    };
    case '{CREDIT_paymentMethod}': return {
      type: SelectorsFieldsTypes.SELECT,
      options: Config.PAYMENT_METHODS.map((value) => (
        { value, label: t(getI18nPaymentMethod(value)) }
      )),
      operators: Object.values(EqualityOperators),
    };
    case '{DEBIT_currency}':
      return {
        type: SelectorsFieldsTypes.SELECT,
        options: currencies?.map((currencyData: Currency) => ({
          label: currencyData.code,
          value: currencyData.id,
        })),
        operators: Object.values(EqualityOperators),
      };
    case 'boolean': return {
      type: SelectorsFieldsTypes.SELECT,
      options: [
        { value: 'true', label: t('common:yes') },
        { value: 'false', label: t('common:no') },
      ],
      operators: Object.values(EqualityOperators),
    };
    case 'date': return {
      type: SelectorsFieldsTypes.DATE,
      operators: [
        ...Object.values(EqualityOperators),
        ...Object.values(ComparisonOperators),
      ],
    };
    case 'number': return {
      type: SelectorsFieldsTypes.NUMBER,
      placeholder:  '',
      operators: [
        ...Object.values(EqualityOperators),
        ...Object.values(ComparisonOperators),
      ],
    };
    case 'text': return {
      type: SelectorsFieldsTypes.TEXT,
      placeholder:  '',
      operators: Object.values(EqualityOperators),
    };
    default : return {
      type: SelectorsFieldsTypes.TEXT,
      placeholder:  '',
      operators: [
        ...Object.values(EqualityOperators),
        ...Object.values(ComparisonOperators),
      ],
    };
  }
};

/**
 * Obtient la valeur d'attribut à partir d'une chaîne d'attribut.
 * @param attribute La chaîne d'attribut.
 * @returns La valeur d'attribut correspondante.
 */
const getAttributeValue = (attribute?:string) => {
  if (!attribute) {
    return null;
  }

  const entities = uniq([...Object.values(SelectorsEntity), ...Object.keys(SelectorsType)]);
  const enumKeysRegex = new RegExp(`{(${entities.join('|')})_(.*)}`);
  const [, firstFieldValue, secondFieldValue ] = attribute.match(enumKeysRegex) ?? [];

  // si DEBIT, CREDIT ou CONTACT ajouter EAV_, en excluant DEBT et le CLIENT
  const isDebt = Object.keys(SelectorDebtSelector).includes(secondFieldValue);
  const isEAV = Object.values(SelectorsEntity).slice(1).includes(firstFieldValue as SelectorsEntity);
  const selector = !isDebt && isEAV ? `EAV_${firstFieldValue}` : firstFieldValue;
  // Si le sélecteur n'est pas trouvé, utiliser le champ libre
  return (Object.keys(SelectorsType).includes(selector) ? selector : 'FREE_FIELD') as FirstSelectorField;
};

/**
 * Convertir une chaîne de caractères en son type approprié.
 * @param value La chaîne.
 * @returns la valeur convertie.
 */
const getSelectorValue = (value: any): boolean | number | string | null => {
  if (typeof value !== 'string') {
    return value;
  }

  if (/^(true|false)$/i.test(value)) {
    return value.toLowerCase() === 'true';
  }

  if (!isNaN(Number(value))) {
    return Number(value);
  }

  return value;
};

export { getFieldDescription, getAttributeValue, getSelectorValue };
