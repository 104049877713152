import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useMemo } from 'react';
import type { UseQueryOptions } from 'react-query';
import type { SearchResult } from 'types/api';
import type { Organization } from 'types/models';
import type ResponseError from 'utils/errors';
import type Misc from 'types/misc';
import Config from 'config';
import queryDefaultConfig from 'config/reactQuery';

export type FetchAllParams = {
  page?: number | undefined,
  filtering?: Misc.Filter[],
};

export type FetchAllOptions<Params extends FetchAllParams> = {
  cacheKey: string,
  organizationId?: Organization['id'] | undefined,
  params?: Params,
};

export type RequestFunction<Params extends FetchAllParams, TResult extends {}> = (
  organizationId: Organization['id'] | undefined,
  params?: Params,
) => Promise<SearchResult<TResult>> | null;

export type FetchPaginatedConfig<TResult> = UseQueryOptions<SearchResult<TResult> | null, ResponseError> & {
  usePrefetching?: boolean
};

const useFetchPaginated = <Params extends FetchAllParams, TResult extends {}>(
  options: FetchAllOptions<Params>,
  requestFunction: RequestFunction<Params, TResult>,
  config?: FetchPaginatedConfig<TResult>,
) => {
  const cache = useQueryClient();

  const paginatedDefaultConfig = { ...queryDefaultConfig, keepPreviousData: true };
  const { organizationId, params } = options;

  const response = useQuery<SearchResult<TResult> | null, ResponseError>(
    Object.values(options),
    () => requestFunction(organizationId, params),
    config ? { ...paginatedDefaultConfig, ...config } : paginatedDefaultConfig,
  );

  const {
    status,
    data: result,
    error,
    isFetching,
    isLoading,
    refetch,
  } = response;

  const currentPage = params?.page ?? 1;

  const totalRecords = useMemo(() => (
    result ? result.matches_count : 0
  ), [result]);

  const totalPages = useMemo(() => (
    Math.ceil(totalRecords / Config.DEFAULT_PAGE_SIZE)
  ), [totalRecords]);

  const { usePrefetching } = config ?? { usePrefetching: false };

  useEffect(() => {
    if (usePrefetching === false || !result) {
      return;
    }

    if (currentPage >= totalPages) {
      return;
    }

    const nextRequestParams: FetchAllOptions<Params> = {
      ...options,
      // FIXME: Corriger l'erreur de typage
      // @ts-expect-error
      params: { ...(options.params ?? {}), page: currentPage + 1 },
    };

    cache.prefetchQuery(Object.values(nextRequestParams), () => (
      requestFunction(organizationId, nextRequestParams.params)
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usePrefetching, result, requestFunction, options]);

  const serverPagination = {
    page: currentPage,
    recordsPerPage: Config.DEFAULT_PAGE_SIZE,
    totalPages,
    totalRecords,
  };

  const data = result ? result.items ?? null : null;

  return {
    status,
    data,
    serverPagination,
    error,
    refetch,
    isFetching,
    isLoading,
  };
};

export default useFetchPaginated;
