import './index.scss';
import { Fragment } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { UserOrganization } from 'types/models';
import organizationStore from 'stores/Organization';
import Dropdown from 'components/Dropdown';
import Loading from 'components/Loading';
import OrganizationSelectorItem from './Item';

type Props = {
  organizations: UserOrganization[],
};

const OrganizationSelector = ({ organizations }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization, isBusy } = organizationStore;
  const { corporateName, isInProduction } = currentOrganization ?? {
    corporateName: null,
    isInProduction: false,
  };

  const classNames = classnames('OrganizationSelector', {
    'OrganizationSelector--nothing-selected': !currentOrganization,
  });

  return (
    <Dropdown
      variant="outline-black"
      className={classNames}
      toggleTitle={t('common:menu.current-organization.select')}
      toggle={(
        <Fragment>
          {isBusy && <Loading hasNoText />}
          {!currentOrganization && t('common:menu.current-organization.no-organization')}
          {currentOrganization && (
            <div className="OrganizationSelector__selected">
              <span className="OrganizationSelector__selected__name">
                {corporateName}
              </span>
              {!isInProduction && (
                <span className="OrganizationSelector__selected__not-prod">
                  {t('common:demo-organization')}
                </span>
              )}
            </div>
          )}
        </Fragment>
      )}
    >
      <div className="OrganizationSelector__menu">
        {organizations.map((organization) => (
          <OrganizationSelectorItem key={organization.id} organization={organization} />
        ))}
      </div>
    </Dropdown>
  );
};

export default observer(OrganizationSelector);
