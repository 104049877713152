import { useEffect } from 'react';
import { useQueryClient, useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import type ResponseError from 'utils/errors';
import type Misc from 'types/misc';
import defaultConfig from 'config/reactQuery';
import type { RequestFunction, BaseFetchOptions } from './useFetch';

export type KeyPaginationParams<FetchAllOptions> = BaseFetchOptions & FetchAllOptions;
export type BaseFetchAllOptions = { fetchOptions: Misc.PaginatedFetchArgs };
export type FetchPaginatedConfig<TResult extends {}> = UseQueryOptions<
AxiosResponse<Misc.Listing<TResult>> | null, ResponseError
> & { usePrefetching?: boolean };

const useFetchPaginated = <FetchAllOptions extends BaseFetchAllOptions, TResult extends {}>(
  params: KeyPaginationParams<FetchAllOptions>,
  requestFunction: RequestFunction<Misc.Listing<TResult>>,
  config?: FetchPaginatedConfig<TResult>,
) => {
  const cache = useQueryClient();

  const paginatedDefaultConfig = { ...defaultConfig, keepPreviousData: true };

  const response = useQuery<AxiosResponse<Misc.Listing<TResult>> | null, ResponseError>(
    Object.values(params),
    () => requestFunction(params),
    config ? { ...paginatedDefaultConfig, ...config } : paginatedDefaultConfig,
  );

  const {
    status,
    data: fetchedData,
    error,
    isFetching,
    isLoading,
    refetch,
  } = response;

  const { usePrefetching } = config ?? { usePrefetching: false };

  useEffect(() => {
    if (usePrefetching === false || !fetchedData?.data?.pagination) {
      return;
    }

    const fetchOptions = params.fetchOptions ?? { pageIndex: 0 };
    const totalPages = Number(fetchedData.data.pagination.totalPages);
    const currentPageIndex = Number(fetchOptions.pageIndex);
    if (currentPageIndex >= totalPages) {
      return;
    }

    const nextRequestParams = {
      ...params,
      fetchOptions: { ...fetchOptions, pageIndex: currentPageIndex + 1 },
    };
    cache.prefetchQuery(Object.values(nextRequestParams), () => requestFunction(nextRequestParams));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usePrefetching, fetchedData, requestFunction, params]);

  const data = fetchedData ? fetchedData.data?.records ?? null : null;
  const serverPagination = fetchedData ? fetchedData.data?.pagination ?? null : null;

  return {
    status,
    data,
    serverPagination,
    error,
    refetch,
    isFetching,
    isLoading,
  };
};

export default useFetchPaginated;
