import type { TFunction } from 'i18next';
import type Misc from 'types/misc';
import Config from 'config';
import getI18nStatusImport from 'utils/getI18nStatusImport';

const ImportsHistoryFilters = (t: TFunction): Misc.FilterDeclarationItem[] => [
  {
    title: t('imports:type'),
    key: 'extension',
    type: 'select',
    selectData: Config.TYPES_EXTENSIONS.map((type) => (
      { value: type, label: type }
    )),
  },
  {
    title: t('imports:status'),
    key: 'status',
    type: 'select',
    selectData: Config.IMPORT_STATUSES.map((status) => (
      { value: status, label: t(getI18nStatusImport(status)) }
    )),
  },
];

export default ImportsHistoryFilters;
