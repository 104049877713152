import './index.scss';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import { EAVType, EavCategory } from 'types/models';
import type { EavConstructor } from 'types/models';
import getEavTypeLabel from 'utils/getEavTypeLabel';
import AttributesRowActions from './RowActions';

const AttributesColumns = (
  t: TFunction,
  onActionError: (message: string) => void,
  onActionDone: (message: string) => void,
  type: EavCategory,
): Column<EavConstructor>[] => [
  {
    Header: t<string>('attributes:list-headings.attribute'),
    accessor: 'identifier',
    disableSortBy: true,
    Cell: ({ value, row }) => (
      <div>
        <h4 className="AttributesColumn__title">{row.original.label}</h4>
        <p className="AttributesColumn__values">{value}</p>
      </div>
    ),
  },
  {
    Header: t<string>('attributes:list-headings.required'),
    accessor: 'required',
    disableSortBy: true,
    Cell: ({ value }) => (
      <p className="AttributesColumn__title">
        {value ? t<string>('common:yes') : t<string>('common:no')}
      </p>
    ),
  },
  {
    Header: t<string>('attributes:list-headings.format'),
    accessor: 'type',
    disableSortBy: true,
    Cell: ({ value: eavFormat }) => (
      <div>
        <p className="AttributesColumn__values-title">
          {t(getEavTypeLabel(eavFormat as EAVType))}
        </p>
      </div>
    ),
  },
  {
    Header: t<string>('common:commands'),
    disableSortBy: true,
    Cell: ({ row }) => (
      <AttributesRowActions
        attributesData={row.original}
        type={type}
        id={row.original.identifier}
        onActionDone={onActionDone}
        onActionError={onActionError}
      />
    ),
  },
];

export default AttributesColumns;
