import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import Button from 'components/Button';
import { UserRightsController, UserRightsSwitch } from 'components/UserRights';
import BrandLogo from 'components/BrandLogo';
import Icon from 'components/Icon';
import { RightsCategory } from 'types/models';
import HeaderNavItem from './Item';

type Props = {
  onClose(): void
};

const HeaderNav = ({ onClose }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const { user } = authStore;
  const { reference, type } = organizationStore.currentOrganization ?? {
    id: undefined,
    reference: undefined,
    type: null,
  };
  const { t, ct } = useContextualTranslation(type);

  const isPaymentRequestActive = useMemo(() => (
    pathname.startsWith('/debits')
    || pathname.startsWith('/debit')
    || pathname.startsWith('/credits')
  ), [pathname]);

  const isCustomersActive = useMemo(() => (
    pathname.startsWith('/contacts') || pathname.startsWith('/customer')
  ), [pathname]);

  const isActionsActive = useMemo(() => (
    pathname.startsWith('/actions-future') || pathname.startsWith('/actions-done')
  ), [pathname]);

  const hasOrganizations = (user?.organizations && user.organizations.length > 0);

  return (
    <div className="HeaderNav">
      <div className="HeaderNav__header">
        <BrandLogo />
        <Button variant="outline" className="HeaderNav__close" onClick={onClose}>
          <Icon name="close" />
        </Button>
      </div>
      <ul className="HeaderNav__items">
        <HeaderNavItem
          to="/"
          title={t('common:menu.home')}
          isExact
        />
        <HeaderNavItem
          to="/debits"
          title={ct('common:menu.bills')}
          forceActive={isPaymentRequestActive}
          disabled={!hasOrganizations || !reference}
          textHover={!reference ? t('common:menu.please-select-organization') : undefined}
        />
        <HeaderNavItem
          to="/customers"
          title={ct('common:menu.customers')}
          forceActive={isCustomersActive}
          disabled={!hasOrganizations || !reference}
          textHover={!reference ? t('common:menu.please-select-organization') : undefined}
        />
        <div className="HeaderNav__items__separator" />
        <HeaderNavItem
          to="/actions-future"
          title={t('common:menu.actions')}
          forceActive={isActionsActive}
          disabled={!hasOrganizations || !reference}
          textHover={!reference ? t('common:menu.please-select-organization') : undefined}
        />
        <UserRightsSwitch>
          <UserRightsController action="READ" category={RightsCategory.ANALYSES}>
            <HeaderNavItem
              to="/analysis"
              title={t('common:menu.analysis')}
              isExact
              disabled={!hasOrganizations || !reference}
              textHover={!reference ? t('common:menu.please-select-organization') : undefined}
            />
          </UserRightsController>
          <UserRightsController displayedWhenNotAllowed action="READ" category={RightsCategory.ANALYSES}>
            <HeaderNavItem
              to="/analysis"
              title={t('common:menu.analysis')}
              textHover={!reference ? t('common:menu.please-select-organization') : t('common:not-allowed')}
              isExact
              disabled
            />
          </UserRightsController>
        </UserRightsSwitch>
      </ul>
    </div>
  );
};

export default observer(HeaderNav);
