import { EavCategory } from 'types/models';

const getEavDescriptionLabel = (eavCategory: EavCategory) => {
  const eavCategoryTable = {
    [EavCategory.CLIENT]: 'attributes:description-client',
    [EavCategory.CONTACT]: 'attributes:description-contact',
    [EavCategory.DEBIT]: 'attributes:description-debit',
    [EavCategory.CREDIT]: 'attributes:description-credit',
  };

  return eavCategoryTable[eavCategory];
};

export default getEavDescriptionLabel;
