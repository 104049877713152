import './index.scss';
import { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { ManagerType } from 'types/models';
import { RightsCategoryType } from 'types/models';
import recomposeName from 'utils/recomposeName';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import apiClients from 'api/clients';
import useContextualTranslation from 'hooks/useContextualTranslation';
import customerContext from 'pages/Customer/customerContext';
import { UserRightsManagerController } from 'components/UserRights';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import Icon from 'components/Icon';

type Props = {
  managers: ManagerType[] | null | undefined,
  onError(message: string): void,
};

const CustomerActionDelete = (props: Props): JSX.Element | null => {
  const { data } = useContext(customerContext);
  const { managers, onError } = props;
  const history = useHistory();
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const { remove, isLoading: isDeleting } = useApiRequest();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleConfirmDelete = useCallback(() => {
    if (isDeleting) {
      return;
    }
    showConfirm(ct('clients:remove'), ct('clients:actions.confirm.remove'));
  }, [isDeleting, showConfirm, ct]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    if (!data) {
      return;
    }

    const response = await remove(apiClients.deleteUrl(data.id));
    if (response === null) {
      onError(t('errors:unknown-retry'));
      return;
    }

    history.push('/customers');
  }, [hideConfirm, remove, data, onError, t, history]);

  if (!data) {
    return null;
  }

  return (
    <UserRightsManagerController
      action="DELETE"
      categoryType={RightsCategoryType.CLIENTS}
      managers={managers}
    >
      <Button
        variant="list-danger"
        onClick={handleConfirmDelete}
        className="CustomerActionDelete"
      >
        {isDeleting && (
          <Icon name="loading" className="CustomerActionDelete__loading" spin />
        )}
        {t('clients:remove')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={recomposeName(data)}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={ct('clients:remove')}
      />
    </UserRightsManagerController>
  );
};

export default observer(CustomerActionDelete);
