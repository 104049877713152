import { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import equal from 'deep-equal';
import apiScenarios from 'api/scenarios';
import { ScenarioFrequency, type ActionPost, type DataBlock, type Scenario, type ScenarioTemplateTask } from 'types/models';
import type { ValidationErrors } from 'types/errors';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import Button from 'components/Button';
import ModalTemplateBlocks from 'components/ModalTemplateBlocks';
import TaskForm from './Form';

interface Props {
  index?: number,
  task: ActionPost | null,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  onClose: () => void,
  onDelete?(): void,
  edit?: boolean,
  scenario: Scenario,
}

const ModalAddTask = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const {
    index = 0,
    task,
    onActionDone,
    onActionError,
    onClose,
    onDelete,
    edit = false,
    scenario,
  } = props;

  const { put, isLoading } = useApiRequest();

  const { currentOrganization } = organizationStore;

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [model, setModel] = useState<DataBlock | undefined>();

  const initialData = useRef<ActionPost>({
    name: task?.name || '',
    description: task?.description || '',
    day: task?.day || 0,
    type: 'TASK',
    repeat: {
      day: null,
      frequency: null,
    },
  });

  const mapFormData = useCallback(
    (rawData: ModalFormData): ActionPost | null => {
      if (!currentOrganization) {
        return null;
      }

      return {
        name: rawData.name as string,
        description: rawData.description as string,
        day: parseInt(rawData.day as string, 10),
        type: 'TASK',
        repeat: {
          day: parseInt(rawData.interval as string, 10),
          frequency: parseInt(rawData.frequency as string, 10),
        },
      };
    },
    [currentOrganization],
  );

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(
        !!formData && !equal(initialData.current, mapFormData(formData)),
      );
    },
    [initialData, mapFormData],
  );

  const [showModalTemplateBlocks, hideModalTemplateBlocks] = useModal(() => (
    <ModalTemplateBlocks
      type="task"
      onClose={hideModalTemplateBlocks}
      onSelect={setModel}
    />
  ), []);

  const handleSubmitCreateOrEdit = useCallback(async (formData: ModalFormData | null) => {
    if (!currentOrganization || (!task && edit) || !formData || isLoading) {
      return;
    }
    let day = parseInt(formData.days as string, 10);
    const date: string = formData.date as string;
    day = date.startsWith('before') ? -day : day;
    const body: ScenarioTemplateTask = {
      name: formData.name as string,
      day,
      description: formData.description as string,
      repeat: formData.repeat !== ScenarioFrequency.NEVER ? {
        day: parseInt(formData.interval as string, 10),
        frequency: formData.repeat === ScenarioFrequency.UNLIMITED ? 0 : parseInt(formData.frequency as string, 10),
      } : null,
    };
    const payloadList = apiScenarios.buildPayloadListData(scenario.tasks, index, edit ? 'edit' : 'create', body);
    const result = await put(apiScenarios.updateUrl(scenario.id), { tasks: payloadList });
    if (!result || result.errors) {
      onActionError(t(edit ? 'plans:toast.error.edit-action' : 'plans:toast.error.add-action'));
      return;
    }
    onActionDone(t(
      edit ? 'plans:toast.success.edited-action' : 'plans:toast.success.added-action',
      { name: task?.name },
    ));
    onClose();
  }, [currentOrganization, task, edit, isLoading, scenario.tasks, scenario.id, index, put, onActionDone, t, onClose, onActionError]);

  const handleSubmitDelete = useCallback(() => {
    if (!onDelete) {
      return;
    }

    onDelete();
    closeSelf();
  }, [onDelete, closeSelf]);

  return (
    <ModalForm
      isOpened
      className="ModalAddTask"
      title={edit ? t('reminders:freetask:edit') : t('plans:add-task')}
      subTitle={task?.name ?? ''}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmitCreateOrEdit}
      onCancel={handleCancel}
      hasDeleteButton={edit}
      onDelete={handleSubmitDelete}
      headerActions={(
        <Button variant="link" onClick={showModalTemplateBlocks}>{t('models:import')}</Button>
      )}
    >
      <TaskForm
        defaultData={model ? { ...model, day: 0, type: 'TASK' } : task}
        dateReference={scenario.dateReferenceIsToday}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalAddTask);
