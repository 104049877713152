import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { DataBlock, Debit, StepAction, TaskPost } from 'types/models';
import type { ValidationErrors } from 'types/errors';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import ModalTemplateBlocks from 'components/ModalTemplateBlocks';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import apiDebits from 'api/debits';
import apiHistorySteps from 'api/historySteps';
import DebitCreateTaskForm from './Form';

type Props = {
  onDone(message: string): void,
  onClose(): void,
  debitData: Debit | null,
};

const DebitCreateTask = ({ onDone, onClose, debitData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();

  const { currentOrganization } = organizationStore;

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [model, setModel] = useState<DataBlock | undefined>();

  const { post, isLoading, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const mapFormData = useCallback(
    (rawData: ModalFormData): TaskPost => ({
      name: rawData.name as string,
      description: rawData.description as string,
      startAt: rawData.startAt as string,
      debit: debitData ? apiDebits.resourceUrl(debitData.id) : undefined,
    }),
    [debitData],
  );

  const [showModalTemplateBlocks, hideModalTemplateBlocks] = useModal(() => (
    <ModalTemplateBlocks
      type="task"
      onClose={hideModalTemplateBlocks}
      onSelect={setModel}
    />
  ), []);

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      const data = mapFormData(formData);
      const result = await post<StepAction>(apiHistorySteps.createTaskUrl, data);

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (!result?.id) {
        return;
      }

      closeSelf();
      const { name } = result;
      onDone(t('reminders:freetask.toast.created', { name }));
    },
    [currentOrganization, post, mapFormData, isMountedRef, onDone, t, closeSelf],
  );

  return (
    <ModalForm
      isOpened
      className="DebitCreateTask"
      title={t('reminders:freetask.create')}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      headerActions={(
        <Button variant="link" onClick={showModalTemplateBlocks}>{t('models:import')}</Button>
      )}
    >
      {error && (<ErrorMessage error={error} />)}
      <DebitCreateTaskForm
        errors={validationErrors}
        defaultData={model}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(DebitCreateTask);
