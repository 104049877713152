import { runInAction, makeAutoObservable } from 'mobx';
import apiCountries from 'api/countries';
import type { Country } from 'types/models';
import ResponseError from 'utils/errors';

class CountriesStore {
  countries: Country[] | null = null;

  isFetched: boolean = false;

  isBusy: boolean = false;

  error: ResponseError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get countriesOptions() {
    return this.countries?.map((countryData) => ({
      label: countryData.label,
      value: countryData.alpha2Code,
    }));
  }

  fetchAll = async () => {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.error = null;

    try {
      const data = await apiCountries.all();
      if (!data) {
        return;
      }

      runInAction(() => {
        this.countries = data;
        this.isFetched = true;
      });
    } catch (error) {
      const { code, message, details } = error as ResponseError;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
      });
    }
  };
}

export default new CountriesStore();
export { CountriesStore };
