import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormColorPicker from 'components/FormColorPicker';
import FormControl from 'components/FormControl';
import FormGroup from 'components/FormGroup';
import config from 'config';
import { ValidationErrors } from 'types/errors';
import { ScenarioGroup } from 'types/models';
import Button from 'components/Button';
import Icon from 'components/Icon';
import FormFieldset from 'components/FormFieldset';

type Props = {
  defaultData?: ScenarioGroup,
  errors?: ValidationErrors,
  showLabel?: boolean,
  isEdit?: boolean,
  canRemove?: boolean,
  isDisabled?: boolean,
  onChange?: (formData: ScenarioGroup | null) => void,
  onDelete?: (group: ScenarioGroup) => void,
};

const ScenarioGroupForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    defaultData,
    onChange,
    showLabel,
    errors,
    isEdit,
    isDisabled,
    canRemove,
    onDelete,
  } = props;

  const [formData, setFormData] = useState(defaultData ?? null);

  const handleChange = useCallback((name: string, value: string | null) => {
    setFormData((prevData) => ({ ...prevData!, [name]: value }));
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(formData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div className='ScenarioGroupForm'>
    <FormFieldset>
      <FormGroup
        label={showLabel ? t('plans:group-identifier') : null}
        error={errors?.identifier}
      >
        <FormControl
          name="identifier"
          defaultValue={formData?.identifier}
          isInvalid={!!errors?.identifier}
          disabled={isEdit || isDisabled}
          onChange={(value) => { handleChange('identifier', value); }}
        />
      </FormGroup>
      <FormGroup
        label={showLabel ? t('plans:group-name') : null}
        error={errors?.name}
      >
        <FormControl
          name="name"
          defaultValue={formData?.name}
          isInvalid={!!errors?.name}
          disabled={isDisabled}
          onChange={(value) => { handleChange('name', value); }}
        />
        <FormColorPicker
          name="color"
          className="ScenarioGroupForm__color"
          colors={config.SCENARIO_COLORS as string[]}
          defaultValue={formData?.color}
          disabled={isDisabled}
          onChange={(value) => { handleChange('color', value); }}
        />
        {canRemove && onDelete &&
          <Button
            className="ScenarioGroupForm__remove"
            variant="link"
            onClick={() => { onDelete(formData!); }}
          >
            <Icon name="minus-circle" />
          </Button>
        }
      </FormGroup>
    </FormFieldset>
    </div>
  );
};

export default ScenarioGroupForm;
