import './index.scss';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from 'components/FormControl';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormGroupWithActions from 'components/FormGroupWithActions';
import FormSelect from 'components/FormSelect';
import TagLink from 'components/TagLink';
import type { ValidationErrors } from 'types/errors';
import type { ActionPost } from 'types/models';
import { ScenarioDateReference } from 'types/models';
import ActionRepeatFieldset from '../../ActionRepeatFieldset';
import getComparableString from '../../utils';

type Props = {
  defaultData: ActionPost | null,
  errors?: ValidationErrors | null,
  dateReference: ScenarioDateReference,
};

const ScenarioModalAddTaskForm = ({ defaultData, errors, dateReference }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [name, setName] = useState<string | undefined>(defaultData?.name || '');
  const oldDefaultData = useRef(getComparableString(defaultData));
  const [description, setDescription] = useState<string | undefined>(
    defaultData?.description || '',
  );

  useEffect(() => {
    if (!defaultData || oldDefaultData.current === getComparableString(defaultData)) {
      return;
    }
    oldDefaultData.current = getComparableString(defaultData);
    setName(defaultData.name || '');
    setDescription(defaultData.description || '');
  }, [defaultData]);

  return (
    <div className="ScenarioModalAddTaskForm">
      <FormFieldset>
        <FormGroup label={t('plans:planned')} error={errors?.startAt}>
          <div className="ScenarioModalAddTaskForm__days">
            <FormControl
              type="number"
              min={0}
              name="days"
              defaultValue={defaultData?.day !== undefined ? Math.abs(defaultData?.day) : undefined}
              isInvalid={!!errors?.startAt}
            />
            <FormSelect
              name="date"
              selectOptions={[
                { label: t('plans:after-reference-date'), value: `after-${dateReference}` },
                { label: t('plans:before-reference-date'), value: `before-${dateReference}` },
              ]}
              isInvalid={!!errors?.date}
              defaultValue={defaultData && defaultData.day < 0 ? `before-${dateReference}` : `after-${dateReference}`}
              withClearButton={false}
            />
          </div>
        </FormGroup>
        <FormGroup label={t('reminders:task-name')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            value={name}
            onChange={setName}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroupWithActions
          label={t('reminders:task-description')}
          mandatory
          error={errors?.description}
          action={<TagLink />}
        >
          <FormControl
            type="textarea"
            name="description"
            autoComplete="off"
            value={description}
            placeholder={t('reminders:placeholder.task-description')}
            onChange={setDescription}
            isInvalid={!!errors?.description}
          />
        </FormGroupWithActions>
      </FormFieldset>
      <ActionRepeatFieldset
        defaultData={defaultData}
        errors={errors}
      />
    </div>
  );
};

export default ScenarioModalAddTaskForm;
