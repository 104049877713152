import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import type { Client, EAVValue, Eav } from 'types/models';
import { ClientType } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import apiClients, { FetchOneParams } from 'api/clients';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormRadios from 'components/FormRadios';
import type { FormRadioValue } from 'components/FormRadios/Item';
import FormEavs from 'components/FormEavs';
import Loading from 'components/Loading';
import FormSelectManager from 'components/FormSelectManager';
import { clientUserToManagerType } from 'utils/mapping/user-manager';
import ErrorMessage from 'components/ErrorMessage';
import { getAllEavsValues } from 'utils/getCustomerEavs';
import useFetch from 'hooks/useFetch2';

type Props = {
  defaultData?: Client | null,
  defaultDenomination?: string,
  isCreate?: boolean,
  errors?: ValidationErrors | null,
};

const CustomerEditForm = (props: Props): JSX.Element => {
  const {
    isCreate = true,
    defaultData = null,
    errors = null,
    defaultDenomination,
  } = props;
  const { attributes: eavs, currentOrganization } = organizationStore;
  const { type } = currentOrganization!;
  const { t, ct } = useContextualTranslation(type);

  const [clientType, setClientType] = useState<ClientType>(
    defaultData?.type || ClientType.COMPANY,
  );

  const handleChangeType = useCallback((newValue: FormRadioValue) => {
    setClientType(newValue as ClientType);
  }, []);

  useEffect(() => {
    if (defaultData) {
      setClientType(defaultData.type);
    }
  }, [defaultData]);

  const managers = useMemo(() => {
    return defaultData?.users ? defaultData.users.map((user) => clientUserToManagerType(user)) : [];
  }, [defaultData?.users]);

  const radioItems = useMemo(() => (
    Object.entries(ClientType).map(([key, value]) => ({
      label: t(`clients:type-${key.toLowerCase()}`),
      value: value,
      id: `client-type-${key.toLowerCase()}`,
    }))
  ), [t]);

  const {
    data: eavsClient,
    error: errorEavClients,
    isLoading: eavsIsLoading,
  } = useFetch<FetchOneParams, EAVValue[]>(
    {
      cacheKey: 'clientAllEavs',
      id: defaultData?.id!,
    },
    apiClients.fetchEavs,
    { enabled: !!defaultData?.id },
  );

  const defaultEavs = useMemo(() => (
    getAllEavsValues(eavsClient, eavs.client)
  ), [eavsClient, eavs]);

  if (!isCreate && !defaultData) {
    return <Loading />;
  }

  return (
    <div className="CustomerEditForm">
      {isCreate && (
        <FormFieldset>
          <FormGroup label={ct('clients:type')} mandatory error={errors?.type}>
            <FormRadios
              name="type"
              items={radioItems}
              value={clientType}
              onChange={handleChangeType}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormGroup label={t('common:name')} mandatory error={errors?.denomination}>
        <FormControl
          name="denomination"
          autoComplete="off"
          defaultValue={defaultData?.denomination || defaultDenomination || ''}
          isInvalid={!!errors?.denomination}
        />
      </FormGroup>
      <FormGroup label={t('common:reference')} mandatory error={errors?.identifier}>
        <FormControl
          name="identifier"
          autoComplete="off"
          defaultValue={defaultData?.identifier || ''}
          isInvalid={!!errors?.identifier}
        />
      </FormGroup>
      <FormGroup label={t('common:managers')} error={errors?.users}>
        <FormSelectManager
          name="users"
          placeholder={t('common:please-choose')}
          entityValue="id"
          defaultValue={managers}
          isInvalid={!!errors?.users}
          isMultiple
          withClearButton
        />
        <div className="CustomerEditForm__help">
          {t('clients:let-empty-to-accept-all')}
        </div>
      </FormGroup>
      {errorEavClients && <ErrorMessage error={errorEavClients} />}
      {!eavsIsLoading && eavs && eavs.client?.length > 0 && (
        <FormEavs
          eavs={eavs.client}
          defaultValues={defaultEavs?.map(({ identifier, value }) => (
            { identifier, value }
          )) as Eav[]}
          errors={errors?.eavs as unknown as ValidationErrors}
        />
      )}
    </div>
  );
};

export default observer(CustomerEditForm);
