import './index.scss';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { StepActionTypeFilter } from 'types/models';
import type { OrganizationStats } from 'types/models';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import BlockSection from 'components/BlockSection';
import Stats from './Stats';
import MainDebtors from './MainDebtors';
import MyActions from './MyActions';
import HomeHistory from './History';

type Props = {
  stats: OrganizationStats,
};

const HomeContent = ({ stats }: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const [countActions, setCountActions] = useState<number>();
  const [countActionsDone, setCountActionsDone] = useState<number>();
  const actionTypes = [
    StepActionTypeFilter.TASK_COMPLETED,
    StepActionTypeFilter.REMINDER_COMPLETED_AUTO,
    StepActionTypeFilter.REMINDER_COMPLETED_MANUAL,
  ].join(',');

  const handleCountActions = useCallback((count: number) => (
    setCountActions(count)
  ), []);

  const handleCountActionsDone = useCallback((count: number) => (
    setCountActionsDone(count)
  ), []);

  return (
    <div className="HomeContent">
      <div className="HomeContent__main">
        <BlockSection
          title={t('home:my-synthesis')}
          linkHref="/debits"
          linkText={ct('home:all-bills')}
        >
          <Stats data={stats} />
        </BlockSection>
        <BlockSection
          title={t('home:main-debtors')}
          linkHref="/customers"
          linkText={ct('home:all-customers')}
        >
          <MainDebtors />
        </BlockSection>
      </div>
      <div className="HomeContent__side">
        <BlockSection
          title={t('home:my-actions')}
          linkHref="/actions-future"
          linkText={t('home:all-actions')}
          count={countActions}
        >
          <MyActions onCountActions={handleCountActions} />
        </BlockSection>
        <BlockSection
          title={t('home:actions-done')}
          linkHref={`/actions-done?type=${actionTypes}`}
          linkText={t('home:all-actions')}
          count={countActionsDone}
        >
          <HomeHistory onCountActions={handleCountActionsDone} />
        </BlockSection>
      </div>
    </div>
  );
};

export default observer(HomeContent);
