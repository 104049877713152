import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Misc from 'types/misc';
import type { DebitSummary } from 'types/models';
import { DebitStatus, RightsRole } from 'types/models';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import userRightsStore from 'stores/UserRights';
import DebitsFilters from 'stores/FilterStores/DebitsFilters';
import type { FetchAllParams } from 'api/debits';
import apiDebits from 'api/debits';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import LinkSwitch from 'components/LinkSwitch';
import { checkIsAllowed } from 'components/ExternallyMonitored';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import DebitEditPage from '../DebitEdit';
import DebitsContext from './context';
import DebitFilters from './Filters';
import DebitColumns from './Columns';
import BulkActions from './Actions/Bulk';
import StatusSelect from './StatusSelect';

export type RouteParams = {
  status?: DebitStatus,
  debitRef?: string,
};

const Debits = (): JSX.Element => {
  const { status, debitRef } = useParams<RouteParams>();
  const history = useHistory();
  const { currentOrganization, selectedBusinessUnitsIdentifier, isExternallyMonitored } = organizationStore;
  const { type } = currentOrganization!;
  const { rights } = userRightsStore;
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);
  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes(RightsRole.ROLE_SUPER_ADMINISTRATOR)));
  const { ct } = useContextualTranslation(type);
  const { t } = useTranslation();
  const [actionsCount, setActionsCount] = useState<number>(0);
  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<DebitSummary>>(
    defaultFetchOptions,
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = DebitsFilters;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: selectedBusinessUnitsIdentifier }];
  }, [filters, selectedBusinessUnitsIdentifier]);

  const {
    data,
    serverPagination,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchPaginated<FetchAllParams, DebitSummary>(
    {
      cacheKey: 'debits',
      organizationId: currentOrganization?.id,
      params: {
        page: fetchOptions.pageIndex,
        organizationReference: currentOrganization?.reference,
        pageSize: fetchOptions.pageSize,
        locale: user?.locale,
        filtering,
        sort: fetchOptions.sort,
      },
    },
    apiDebits.all,
    { enabled: !!currentOrganization },
  );

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    setActionsCount(actionsCount + 1);
    refetch();
  }, [showToast, refetch, setActionsCount, actionsCount]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const columns = useMemo<Column<DebitSummary>[]>(
    () => DebitColumns(ct, t, handleActionDone, handleActionError),
    [ct, t, handleActionDone, handleActionError],
  );

  const filtersList = useMemo<Misc.FilterDeclarationItem[]>(
    () => DebitFilters(ct, {
      status: status as DebitStatus,
    }),
    [ct, status],
  );

  const totalResults = useMemo(() => (
    filters.length > 0 ? serverPagination?.totalRecords : null
  ), [filters, serverPagination]);

  const handleStatusSelector = useCallback((newStatus: DebitStatus) => {
    setFetchOptions({ ...fetchOptions, sort: { id: 'created_at', desc: newStatus !== DebitStatus.ACTIVATE } });
    addOrUpdateFilters([{ name: 'status', value: newStatus }]);
  }, [addOrUpdateFilters, fetchOptions]);

  // FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle.
  /*const exportLink = useMemo<string>(
    () => ExportLink(fetchOptions, filters, currentOrganization?.reference || null),
    [currentOrganization, fetchOptions, filters],
  );*/

  const currentStatus = useMemo(() => (
    filters.find(({ name }) => name === 'status')?.value as DebitStatus || 'ALL'
  ), [filters]);

  const handleSwitchChange = useCallback((listing: string) => {
    if (!listing.startsWith('debits')) {
      history.push(`/${listing}?${getFiltersQueryString(filters, ['search', 'client'])}`);
    }
  }, [history, filters]);

  const switchListing = (
    <LinkSwitch
      valueLabels={[
        { value: 'debits', label: ct('debits:bills') },
        { value: 'credits', label: ct('debits:payments') },
      ]}
      defaultValue="debits"
      onChange={handleSwitchChange}
    />
  );

  return (
    <DebitsContext.Provider value={{ currentStatus }}>
      <PageList
        className="Debits"
        count={totalResults}
        isFetching={isFetching}
        title={ct('debits:title')}
        actions={switchListing}
      >
        <DataTable<DebitSummary>
          columns={columns}
          data={data}
          serverPagination={serverPagination}
          filtering={filters}
          onChangeFilters={addOrUpdateFilters}
          onRemoveFilter={removeFilter}
          statusSelector={(
            <StatusSelect
              refreshTick={actionsCount}
              status={currentStatus}
              onSetFilters={handleStatusSelector}
            />
          )}
          filtersList={filtersList}
          fetchData={setFetchOptions}
          isLoading={isLoading}
          noDataFoundMessage={ct('debits:no-data')}
          error={error}
          withNoDataDrawing
          withActions={
            rights !== null && checkIsAllowed(false, isExternallyMonitored, isSuperAdmin)
          }
          bulkActions={(
            <BulkActions
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          )}
        />
        {debitRef && (
          <DebitEditPage
            onDone={handleActionDone}
            onClose={() => { }}
            finallyRedirectTo="/debits/IN_PROGRESS"
          />
        )}
        <ToastNotification
          text={toastText}
          status={toastStatus}
          isShow={isToastShowed}
          onClose={hideToast}
        />
      </PageList>
    </DebitsContext.Provider>
  );
};

export default observer(Debits);
