import './index.scss';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import config from 'config';
import type Misc from 'types/misc';
import { ImportItemType } from 'types/models';
import organizationStore from 'stores/Organization';
import TooltipProvider from 'components/Tooltip';
import useTooltip from 'components/Tooltip/useTooltip';
import FormCheck from 'components/FormCheck';
import Icon from 'components/Icon';
import DownloadLink from 'components/DownloadLink';
import HelpLink from 'components/HelpLink';
import ImportsFileWizardNav from './Nav';
import ImportsFileWizardStepHeader from './Header';
import ImportsFileWizardStepChoiceType from './ChoiceType';
import ImportsFileWizardStepChoiceFiles from './ChoiceFiles';
import filesReducer from './reducer';

export type RouteParams = {
  type: 'csv-xlsx' | 'pdf',
};

const ImportsFileWizardStep = (): JSX.Element => {
  const { t } = useTranslation();
  const { type } = useParams<RouteParams>();
  const { reference } = organizationStore.currentOrganization!;

  const [stepNumber, setStepNumber] = useState<number>(type === 'pdf' ? 2 : 1);
  const [choices, setChoices] = useState<Misc.ImportWizardChoices | null>(null);
  const [files, dispatchFiles] = useReducer(filesReducer, []);
  const [dispatch, setDispatch] = useState<boolean>(false);

  const tooltipDispatch = useTooltip(
    <div className="ImportsFileWizardStep__tooltip">{t('imports:dispatch-description')}</div>,
    'clamped',
  );

  useEffect(() => {
    setStepNumber(type === 'pdf' ? 2 : 1);
    setChoices(type === 'pdf' ? { type: ImportItemType.INVOICE, files: null } : null);
    setDispatch(false);
  }, [type]);

  const handleSelectType = useCallback((choice: ImportItemType) => () => {
    setChoices({ type: choice, files: null });
  }, []);

  const getNavLink = useMemo(() => {
    const choice = choices?.type === 'debit' ? 'PAY_REQUEST' : 'PAY_REQUEST_STATUSES';
    return `${reference}/empty/${!choices?.type?.startsWith('PAY_REQUEST')
      ? choices?.type
      : choice}`;
  }, [choices, reference]);

  const handleNavigateNext = useCallback(() => {
    if (stepNumber < 3) {
      setStepNumber(stepNumber + 1);
    }
  }, [stepNumber]);

  const handleNavigatePrevious = useCallback(() => {
    if (stepNumber === 0) {
      return;
    }
    setStepNumber(stepNumber - 1);
  }, [stepNumber]);

  const handleNavigateTo = useCallback((step: number) => {
    if (step <= 0 || step >= 3) {
      return;
    }
    setStepNumber(step);
  }, []);

  const stepInfo = useMemo(() => {
    const steps = [
      {
        step: 1,
        title: t('imports:wizard.choice-type.type-file'),
        action: (
          <HelpLink article={config.FRESHDESK_IMPORT} />
        ),
        description: t('imports:wizard.choice-type.type-description'),
        content: <ImportsFileWizardStepChoiceType
          onSelectType={handleSelectType}
          type={type}
          choices={choices}
        />,
      },
      {
        step: 2,
        title: t('imports:wizard.choice-file.upload'),
        description: t('imports:wizard.choice-file.upload-description'),
        link: (
          <DownloadLink path={getNavLink}>
            {t('imports:download-template')}
          </DownloadLink>
        ),
        content: (
          <ImportsFileWizardStepChoiceFiles
            dispatch={dispatch}
            fileType={choices?.type || null}
            step={stepNumber}
            type={type}
            files={files}
            dispatchFiles={dispatchFiles}
          />
        ),
      },
    ];

    return stepNumber < 2 && type !== 'pdf' ? steps[0] : steps[1];
  }, [t, handleSelectType, type, choices, getNavLink, files, dispatch, stepNumber]);

  return (
    <div className="ImportsFileWizardStep">
      <ImportsFileWizardStepHeader
        type={type}
        choices={choices}
        countFiles={stepNumber === 3 ? files.length : 0}
      />

      <div className="ImportsFileWizardStep__content">
        <div className="ImportsFileWizardStep__side-view">

          <div className="ImportsFileWizardStep__details">
            <div className="ImportsFileWizardStep__details__step-number">
              {type === 'pdf' ? stepInfo.step - 1 : stepInfo.step}
            </div>
            <div className="ImportsFileWizardStep__details__title">
              {stepInfo.title} {stepInfo.action}
            </div>
          </div>

          <div className="ImportsFileWizardStep__description">
            {stepInfo.description} {type === 'csv-xlsx' && stepInfo?.link}
          </div>
          <div className="ImportsFileWizardStep__options">
            {stepNumber === 2 && type === 'pdf' && (
              <div className="ImportsFileWizardStep__options__check">
                <FormCheck
                  label=""
                  checked={dispatch}
                  onChange={setDispatch}
                />
                <p className="ImportsFileWizardStep__options__check__label">{t('imports:without-dispatch')}</p>
                <TooltipProvider>
                  <span ref={tooltipDispatch} className="ImportsFileWizardStep__options__check__tooltip">
                    <Icon name="help-circle" />
                  </span>
                </TooltipProvider>
              </div>
            )}
            {stepNumber > 2 && type === 'pdf' && (
              <p>{t('imports:without-dispatch')} : {dispatch ? t('common:yes') : t('common:no')}</p>
            )}
          </div>
        </div>
        <div className="ImportsFileWizardStep__main-view">
          {stepInfo.content}
        </div>
      </div>
      <ImportsFileWizardNav
        type={type}
        stepNumber={stepNumber}
        onNext={handleNavigateNext}
        onPrevious={handleNavigatePrevious}
        onNavigateTo={handleNavigateTo}
        hasSelectedType={choices?.type !== undefined}
        hasSelectedFiles={files.length > 0}
      />
    </div>
  );
};

export default ImportsFileWizardStep;
