import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import apiDebits from 'api/debits';
import { DebitAction } from 'types/models';
import type { Debit, DebitPut } from 'types/models';
import type Misc from 'types/misc';
import getBulkToggleActionI18n from '../getBulkToggleActionI18n';
import UnMuted from '../../Row/UnMuted';

type Props = {
  selection: Debit['id'][],
  action: DebitAction,
  payload?: DebitPut,
  onLoading(isLoading: boolean): void,
  onDone(action: DebitAction): void,
  onError(message: string): void,
};

const DebitsBulkActionsToggle = (props: Props): JSX.Element => {
  const { selection, action, payload, onLoading, onDone, onError } = props;
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const [isShowUnmutedModal, setIsShowUnmutedModal] = useState(false);

  const isMountedRef = useIsMountedRef();
  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const {
    patch,
    remove,
    error,
    isLoading,
  } = useApiRequest();

  const actionI18n = useMemo(() => (
    getBulkToggleActionI18n(t, ct, selection.length)[action]
  ), [t, ct, selection, action]);

  const handleClickAction = useCallback(() => {
    const { title, confirmText: text } = actionI18n;
    if (action === DebitAction.UNMUTE) {
      setIsShowUnmutedModal(true);
      return;
    }

    showConfirm(title, text);
  }, [action, actionI18n, showConfirm]);

  const doActionPut = useCallback(async () => {
    const formData = selection
      .map((reference) => JSON.stringify({
        identifier:reference,
        ...(payload ?? { status: action }),
      }))
      .join('\n');

    const result = await patch<Misc.BulkStatusCode>(
      apiDebits.bulkUpdateUrl,
      formData,
    );

    if (result && isMountedRef.current) {
      onDone(action);
    }
  }, [action, selection, payload, patch, isMountedRef, onDone]);

  const doActionDelete = useCallback(async () => {
    await remove(apiDebits.bulkDeleteUrl(selection));
    if (isMountedRef.current) {
      onDone(DebitAction.REMOVE);
    }
  }, [selection, remove, isMountedRef, onDone]);

  const handleSubmit = useCallback(() => {
    hideConfirm();
    if (action === DebitAction.REMOVE) {
      doActionDelete();
    } else {
      doActionPut();
    }
  }, [hideConfirm, action, doActionPut, doActionDelete]);

  useEffect(() => {
    if (error) { onError(error.message); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    onLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Fragment>
      <Button
        variant="outline-light"
        onClick={handleClickAction}
        className={classnames('DebitsBulkActionsToggle', {
          'DebitsBulkActionsToggle--delete': action === DebitAction.REMOVE,
        })}
        disabled={isLoading}
      >
        {actionI18n.title}
      </Button>
      <UnMuted
        onDone={onDone}
        onClose={() => { setIsShowUnmutedModal(false); }}
        isShow={isShowUnmutedModal}
        title={actionI18n.title}
        text={actionI18n.confirmText}
        bulk={selection}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmit}
        onCancel={hideConfirm}
        variant={action === DebitAction.REMOVE ? 'danger' : 'primary'}
        confirmButtonText={actionI18n.title}
      />
    </Fragment>
  );
};

export default observer(DebitsBulkActionsToggle);
