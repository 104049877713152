import { Fragment } from 'react';
import { Column } from 'react-table';
import type { TFunction } from 'i18next';
import type Misc from 'types/misc';
import getI18nReportStatus from 'utils/getI18nReportStatus';
import formatSize from 'utils/formatSize';
import Icon from 'components/Icon';
import { ImportItemStatus } from 'types/models';

const FilesColumns = (
  t: TFunction,
  step: number,
  error: boolean,
): Column<Misc.FileImport>[] => [
  {
    Header: '',
    accessor: 'id',
    disableSortBy: true,
    Cell: () => <span />,
  },
  {
    Header: t<string>('imports:wizard.file-reference'),
    accessor: 'name',
    disableSortBy: true,
    Cell: ({ value }) => <h3 className="ImportsFileWizardStepChoiceFiles__columns__name">{value}</h3>,
  },
  {
    Header: t<string>('common:status'),
    accessor: 'status',
    disableSortBy: true,
    Cell: ({ value }) => (
      <>
        {step === 3 && !error && <Icon name="loading-line" spin />}
        {step === 3 && error && t<string>(getI18nReportStatus(ImportItemStatus.FAILED))}
        {step === 2 && t<string>(getI18nReportStatus(value))}
      </>
    ),
  },
  {
    Header: t<string>('reports:format'),
    accessor: 'format',
    disableSortBy: true,
    Cell: ({ value }) => <Fragment>{value.toUpperCase()}</Fragment>,
  },
  {
    Header: t<string>('reports:size'),
    accessor: 'size',
    disableSortBy: true,
    Cell: ({ value }) => <Fragment>{formatSize(value)}</Fragment>,
  },
  {
    Header: t<string>('common:user'),
    accessor: 'user',
    disableSortBy: true,
    Cell: ({ value }) => <Fragment>{value.name}</Fragment>,
  },
];

export default FilesColumns;
