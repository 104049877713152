import { useCallback, useEffect, useRef, useState } from 'react';
import Axios, { Canceler } from 'axios';
import Request from 'utils/request';
import requester from 'utils/requester';
import ResponseError from 'utils/errors';
import type { ValidationErrors } from 'types/errors';
import useIsMountedRef from './useIsMountedRef';

const { CancelToken } = Axios;

const useApiRequest = () => {
  const cancelRef = useRef<Canceler | null>(null);
  const isMountedRef = useIsMountedRef();

  const [error, setError] = useState<ResponseError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => (
    () => { // - ComponentWillUnmount
      if (cancelRef.current) {
        cancelRef.current('Cancel while unmount component');
      }
    }
  ), []);

  const cancel = useCallback(() => {
    setError(null);
    if (cancelRef.current) {
      cancelRef.current('Manual cancel');
    }
  }, []);

  const resetError = useCallback(() => {
    setError(null);
    setIsLoading(false);
  }, []);

  const createCanceler = useCallback(
    (cancelFn: Canceler) => (message: string | undefined) => {
      cancelFn(message);
    }, [],
  );

  const get = useCallback(async <ResultType>(url: string) => {
    if (isLoading) {
      return null;
    }

    setError(null);
    setIsLoading(true);
    let result = null;

    try {
      const { data, status } = await Request.get<ResultType>(
        url,
        {
          cancelToken: new CancelToken((cancelFn) => {
            cancelRef.current = createCanceler(cancelFn);
          }),
        },
      );
      result = data;
      if (status === 403) {
        throw new ResponseError(403, 'unauthorized');
      }
    } catch (err: unknown) {
      if (isMountedRef.current) {
        setError(err as ResponseError);
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }

    return result;
  }, [isLoading, isMountedRef, createCanceler]);

  const post = useCallback(async <ResultType = {}>(url: string, payload: {}) => {
    if (isLoading) {
      return null;
    }

    setError(null);
    setIsLoading(true);
    let result = null;

    try {
      const { data, status } = await requester.post<ResultType & { errors?: ValidationErrors }>(
        url,
        payload,
        {
          cancelToken: new CancelToken((cancelFn) => {
            cancelRef.current = createCanceler(cancelFn);
          }),
        },
      );
      result = data;
      if (status === 403) {
        throw new ResponseError(403, 'unauthorized');
      }
      if (data?.errors || status === 422) {
        throw new ResponseError(400, 'validation-error');
      }
    } catch (err) {
      if (isMountedRef.current) {
        setError(err as ResponseError);
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }

    return result;
  }, [isLoading, isMountedRef, createCanceler]);

  const postFormData = useCallback(async <ResultType = {}>(url: string, formData: FormData) => {
    if (isLoading) {
      return null;
    }

    setError(null);
    setIsLoading(true);
    let result = null;

    try {
      const { data, status } = await requester.post<ResultType & { errors?: ValidationErrors }>(
        url,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          cancelToken: new CancelToken((cancelFn) => {
            cancelRef.current = createCanceler(cancelFn);
          }),
        },
      );
      result = data;
      if (status === 403) {
        throw new ResponseError(403, 'unauthorized');
      }
      if (data.errors || status === 422) {
        throw new ResponseError(400, 'validation-error');
      }
    } catch (err) {
      if (isMountedRef.current) {
        setError(err as ResponseError);
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }

    return result;
  }, [isLoading, isMountedRef, createCanceler]);

  const put = useCallback(async <ResultType>(url: string, payload?: {}) => {
    if (isLoading) {
      return null;
    }

    setError(null);
    setIsLoading(true);
    let result = null;

    try {
      const { data, status } = await requester.put<ResultType & { errors?: ValidationErrors }>(
        url,
        payload,
        {
          cancelToken: new CancelToken((cancelFn) => {
            cancelRef.current = createCanceler(cancelFn);
          }),
        },
      );
      result = data;
      if (status === 403) {
        throw new ResponseError(403, 'unauthorized');
      }
      if (data.errors || status === 422) {
        throw new ResponseError(400, 'validation-error');
      }
    } catch (err) {
      if (isMountedRef.current) {
        setError(err as ResponseError);
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }

    return result;
  }, [isLoading, isMountedRef, createCanceler]);

  const remove = useCallback(async <ResultType>(url: string) => {
    if (isLoading) {
      return null;
    }

    setError(null);
    setIsLoading(true);
    let result = null;

    try {
      const { data, status } = await requester.delete<ResultType>(
        url,
        {
          cancelToken: new CancelToken((cancelFn) => {
            cancelRef.current = createCanceler(cancelFn);
          }),
        },
      );
      result = data;
      if (status === 403) {
        throw new ResponseError(403, 'unauthorized');
      }
    } catch (err) {
      if (isMountedRef.current) {
        setError(err as ResponseError);
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }

    return result;
  }, [isLoading, isMountedRef, createCanceler]);

  const patch = useCallback(async <ResultType = {}>(url: string, payload: {}) => {
    if (isLoading) {
      return null;
    }

    setError(null);
    setIsLoading(true);
    let result = null;

    try {
      const { data, status } = await requester.patch<ResultType & { errors?: ValidationErrors }>(
        url,
        payload,
        {
          headers: { 'Content-Type': 'application/vnd.dunforce.collection+json' },
          cancelToken: new CancelToken((cancelFn) => {
            cancelRef.current = createCanceler(cancelFn);
          }),
        },
      );
      result = data;
      if (status === 403) {
        throw new ResponseError(403, 'unauthorized');
      }
      if (data?.errors) {
        throw new ResponseError(400, 'validation-error');
      }
    } catch (err) {
      if (isMountedRef.current) {
        setError(err as ResponseError);
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }

    return result;
  }, [isLoading, isMountedRef, createCanceler]);

  return {
    get,
    post,
    postFormData,
    put,
    remove,
    patch,
    cancel,
    error,
    resetError,
    isLoading,
  };
};

export default useApiRequest;
