import './index.scss';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ACCEPT_FILES_TYPE } from 'config';
import apiImports from 'api/imports';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import type Misc from 'types/misc';
import type { Import, ImportItemType } from 'types/models';
import Dropzone from 'components/Dropzone';
import ErrorMessage from 'components/ErrorMessage';
import NoOrganizationMessage from 'components/NoOrganizationMessage';
import Loading from 'components/Loading';
import DataTable from 'components/DataTable';
import { ReducerAction } from '../reducer';
import FilesColumns from './Columns';
import BulkActions from './Bulk';

type Props = {
  type: 'pdf' | 'csv-xlsx',
  fileType: ImportItemType | null,
  files: Misc.FileImport[],
  dispatchFiles: React.Dispatch<ReducerAction>,
  step: number,
  dispatch: boolean,
};

const ImportsFileWizardStepChoiceFiles = (props: Props): JSX.Element => {
  const { type, dispatchFiles, files, fileType, step, dispatch } = props;
  const { user } = authStore;
  const { currentOrganization } = organizationStore;
  const history = useHistory();
  const { t } = useTranslation();
  const { postFormData, isLoading, error } = useApiRequest();

  const acceptedFiles = useMemo(() => {
    if (type === 'csv-xlsx') {
      return ACCEPT_FILES_TYPE.SHEET;
    }

    if (type === 'pdf') {
      return ACCEPT_FILES_TYPE.PDF;
    }

    return ACCEPT_FILES_TYPE.ANY;
  }, [type]);

  const handleDrop = useCallback((filesUpload: File[]) => {
    if (!user) {
      return;
    }

    dispatchFiles({
      type: 'add',
      filesUpload,
      user,
    });
  }, [dispatchFiles, user]);

  const columns = useMemo<Column<Misc.FileImport>[]>(
    () => FilesColumns(t, step, error !== null),
    [error, step, t],
  );

  const deleteId = useCallback((idsToDelete: string[]) => {
    if (!user) {
      return;
    }

    dispatchFiles({
      type: 'remove',
      user,
      idsToDelete,
    });
  }, [dispatchFiles, user]);

  const postFiles = useCallback(async () => {
    if (!fileType) {
      return;
    }
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file.originalFileData);
    });
    formData.append('type', fileType);
    formData.append('organization', currentOrganization?.reference as string);
    formData.append('sendDispatches', !dispatch ? '1' : '0');

    const result = await postFormData<Import[]>(
      apiImports.allImportsData,
      formData,
    );
    if (!result) {
      return;
    }
    history.push('/imports/reports', { doneUploading: files.length });
  }, [files, fileType, dispatch, postFormData, currentOrganization, history]);

  useEffect(() => {
    if (step === 3) {
      postFiles();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <div className="ImportsFileWizardStepChoiceFiles">
      {error && <ErrorMessage error={error} />}
      {!currentOrganization && <NoOrganizationMessage />}
      {step === 2 && (
        <Dropzone onDrop={handleDrop} accept={acceptedFiles} hasAddedFiles={files.length > 0} />
      )}
      {step === 3 && isLoading && <Loading text={t('imports:uploading-files')} />}
      <div className="ImportsFileWizardStepChoiceFiles__box">
        {files.length > 0 && (
          <>
            <h2 className="ImportsFileWizardStepChoiceFiles__box__title">
              {t('imports:pending-files')}
            </h2>
            <DataTable<Misc.FileImport>
              columns={columns}
              data={files}
              noHeader
              serverPagination={null}
              isLoading={false}
              fetchData={() => { }}
              error={null}
              defaultSortBy={{ id: 'id', desc: false }}
              bulkActions={(
                <BulkActions
                  onDeleteId={deleteId}
                />
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default observer(ImportsFileWizardStepChoiceFiles);
