import './index.scss';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import formatIntlDate from 'utils/formatIntlDate';
import type { ContactSummaryStep, Channel } from 'types/models';
import organizationStore from 'stores/Organization';
import getContactRole from 'utils/getContactRole';
import getI18nChannel from 'utils/getI18nChannel';

type Props = {
  contacts: ContactSummaryStep[] | null,
  channel: Channel | null,
};

const HistoryStepContactsSentTo = ({ contacts, channel }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { contactRoles } = organizationStore.currentOrganization!;

  const getItemclassName = useCallback((sentAt: string | null) => classNames('HistoryStepContactsSentTo__item', {
    'HistoryStepContactsSentTo__item--danger': sentAt === null,
  }), []);

  return (
    <div className="HistoryStepContactsSentTo">
      {contacts && contacts.length > 0 ? (
        <div className="HistoryStepContactsSentTo__summary">
          {contacts.map((contact) => (
            <div className={getItemclassName(contact.sentAt)} key={contact.id}>
              <p className="HistoryStepContactsSentTo__name">{contact.name}</p>
              {contact.sentAt && (
                <p className="HistoryStepContactsSentTo__role">
                  {getContactRole(contact.contactRole, contactRoles)}
                </p>
              )}
              {contact.sendTo && (
                <p className="HistoryStepContactsSentTo__send-to">{contact.sendTo}</p>
              )}
              {!contact.sentAt && channel && (
                <p className="HistoryStepContactsSentTo__date-sent">
                  {t('reminders:error-channel', { channel: t(getI18nChannel(channel)) })}
                </p>
              )}
              {contact.receivedAt && (
                <p className="HistoryStepContactsSentTo__date-received">
                  {t('common:received-at-date', { date: formatIntlDate(contact.receivedAt, 'dateLong') })}
                </p>
              )}
              {contact.trackingCode && (
                <p className="HistoryStepContactsSentTo__tracking-code">
                  {t('common:tracking-code')} <strong>{contact.trackingCode}</strong>
                </p>
              )}
              {contact.deliveryMessage && (
                <p className="HistoryStepContactsSentTo__delivery-message">
                  {contact.deliveryMessage}
                </p>
              )}
            </div>
          ))}
        </div>
      ) : '-'}
    </div>
  );
};

export default observer(HistoryStepContactsSentTo);
