import './index.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { Client, DataBlock } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormDatePicker from 'components/FormDatePicker';
import ScenarioGroupDropdown from 'components/ScenarioGroupDropdown';
import FormGroupWithActions from 'components/FormGroupWithActions';
import FormCheck from 'components/FormCheck';

interface Props {
  defaultData?: DataBlock | null,
  errors?: ValidationErrors | null,
  customerId?: Client['id'] | null,
}

const CustomerCreateTaskForm = ({ defaultData, errors, customerId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(defaultData?.name || '');
  const [description, setDescription] = useState<string>(defaultData?.description || '');

  useEffect(() => {
    setName(defaultData?.name || '');
    setDescription(defaultData?.description || '');
  }, [defaultData]);

  return (
    <div className="CustomerCreateTaskForm">
      <FormFieldset>
        <FormGroupWithActions
          label={t('reminders:task-name')}
          mandatory
          error={errors?.name}
        >
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            value={name}
            onChange={setName}
            placeholder={t('reminders:placeholder.task-name')}
            isInvalid={!!errors?.name}
          />
        </FormGroupWithActions>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          label={t('common:planned-at')}
          className="CustomerCreateTaskForm__date"
          mandatory
          error={errors?.startAt}
        >
          <FormDatePicker
            name="startAt"
            minDate={new Date()}
            isInvalid={!!errors?.startAt}
          />
        </FormGroup>
        <FormGroup
          className="CustomerCreateTaskForm__static"
          label={t('reminders:is-static')}
          error={errors?.static}
        >
          <FormCheck
            name="static"
            value="1"
          />
        </FormGroup>
        <ScenarioGroupDropdown customerId={customerId} />
      </FormFieldset>
      <FormFieldset>
        <FormGroupWithActions
          label={t('reminders:task-description')}
          error={errors?.description}
        >
          <FormControl
            type="textarea"
            name="description"
            autoComplete="off"
            value={description}
            placeholder={t('reminders:placeholder.task-description')}
            onChange={setDescription}
            isInvalid={!!errors?.description}
          />
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default CustomerCreateTaskForm;
