import './index.scss';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { Payer } from 'types/models';
import { SelectionState } from 'hooks/useSelection';
import useFetch from 'hooks/useFetch';
import { fetchOne, FetchOneParams } from 'api/payer';
import useToast from 'components/ToastNotification/useToast';
import Loading from 'components/Loading';
import ToastNotification from 'components/ToastNotification';
import NoResultsFound from 'components/NoResultsFound';
import Invoices from './Invoices';
import Summary from './Summary';

export type RouteParams = {
  token?: string,
};

const PayerPage = (): JSX.Element => {
  const { token } = useParams<RouteParams>();
  const [invoiceSelection, setInvoiceSelection] = useState<Payer[]>([]);

  const { data, isLoading, error } = useFetch<FetchOneParams, Payer>(
    { cacheKey: 'payer', token },
    fetchOne,
    { retry: 1 },
  );

  const handleSelect = useCallback((selection: SelectionState) => {
    if (!data) {
      return;
    }
    if (selection.length === 0) {
      setInvoiceSelection([]);
      return;
    }

    setInvoiceSelection([data]);
  }, [data]);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
  }, [showToast]);

  return (
    <div className="PayerPage">
      {data !== null && (
        <Invoices
          data={data}
          error={error}
          isLoading={isLoading}
          onSelectInvoices={handleSelect}
          onActionDone={handleActionDone}
        />
      )}
      {isLoading && <Loading isOverlay />}
      {!data && !isLoading && <NoResultsFound withoutSubtitle />}
      <Summary
        invoices={invoiceSelection}
      />
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default PayerPage;
