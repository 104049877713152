import { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import type { Debit } from 'types/models';
import { DebitStatus, DebitAction } from 'types/models';
import CreditNote from 'pages/Debits/Actions/Row/CreditNote';
import ActionNote from 'pages/Debits/Actions/Row/Note';
import ActionsToggle from 'pages/Debits/Actions/Row/Toggle';
import organizationStore from 'stores/Organization';
import currenciesStore from 'stores/Currencies';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';

type Props = {
  data: Debit,
  onToggleDone(action: DebitAction): void,
  onDone(message: string): void,
  onError(message: string): void,
};

const DebitSideMoreActions = (props: Props): JSX.Element | null => {
  const { data, onToggleDone, onDone, onError } = props;
  const { currency } = organizationStore.currentOrganization!;
  const { currencies } = currenciesStore;
  const { id, client, reference, muted } = data;

  const currencyModel = useMemo(() => (
    currencies?.find((currencyItem) => (
      currencyItem.code === data.currency || currencyItem.code === currency
    ))
  ), [currencies, data, currency]);

  const isActive = data?.status !== DebitStatus.CANCEL;
  const isPaid = data?.status === DebitStatus.PAID;
  const isInProgress = data?.status === DebitStatus.IN_PROGRESS;

  return (
    <Fragment>
      <ActionNote
        id={id}
        client={client.id}
        reference={reference}
        onLoadingChange={() => {}}
        onDone={onDone}
      />
      {isInProgress && (
        <Fragment>
          <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
            <ActionsToggle
              id={id}
              reference={reference}
              muted={muted?.at ?? null}
              action={muted ? DebitAction.UNMUTE : DebitAction.MUTE}
              buttonIcon={muted ? 'bell' : 'bell-muted'}
              onDone={onToggleDone}
              onError={onError}
            />
          </ExternallyMonitoredController>
          <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
            <ActionsToggle
              id={id}
              reference={reference}
              muted={muted?.at ?? null}
              action={DebitAction.LITIGATE}
              buttonIcon="warning-circle"
              onDone={onToggleDone}
              onError={onError}
            />
          </ExternallyMonitoredController>
        </Fragment>
      )}
      {!isPaid && isActive && (
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <CreditNote
            id={id}
            customerId={client.id}
            reference={reference}
            currency={currencyModel}
            onLoadingChange={() => { }}
            onDone={onDone}
          />
        </ExternallyMonitoredController>
      )}
      {!isActive && (
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <ActionsToggle
            id={id}
            reference={reference}
            muted={muted?.at ?? null}
            action={DebitAction.ACTIVATE}
            onDone={onToggleDone}
            onError={onError}
          />
        </ExternallyMonitoredController>
      )}
      {isActive && (
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <ActionsToggle
            id={id}
            reference={reference}
            muted={muted?.at ?? null}
            action={DebitAction.DISABLE}
            buttonVariant="danger"
            onDone={onToggleDone}
            onError={onError}
          />
        </ExternallyMonitoredController>
      )}
    </Fragment>
  );
};

export default observer(DebitSideMoreActions);
