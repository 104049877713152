import './index.scss';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiScenarios from 'api/scenarios';
import FormControl from 'components/FormControl';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import type { Scenario } from 'types/models';

type Props = {
  scenario: Scenario,
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const ScenarioModalEdit = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { onClose, onActionDone, onActionError, scenario } = props;
  const { name, id } = scenario;
  const { currentOrganization } = organizationStore;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isMountedRef = useIsMountedRef();
  const { put } = useApiRequest();

  const handleSubmit = async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization || !isMountedRef.current) {
      return;
    }
    setIsSaving(true);

    const result = await put(apiScenarios.updateUrl(id), { label: formData.name });
    setIsSaving(false);

    if (!result) {
      onActionError(t('plans:toast.error.edit-plan'));
      return;
    }

    onActionDone(t('plans:toast.success.edited-plan', { name: formData.name }));
    onClose();
  };

  return (
    <ModalForm
      isOpened
      className="ScenarioModalEdit"
      onSave={handleSubmit}
      onCancel={onClose}
      title={t('plans:title-edit-plan')}
      isLoading={isSaving}
    >
      <FormFieldset>
        <FormGroup>
          <FormControl
            placeholder={t('plans:placeholder.name')}
            name="name"
            defaultValue={name}
          />
        </FormGroup>
      </FormFieldset>
    </ModalForm>
  );
};

export default observer(ScenarioModalEdit);
