import './index.scss';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import type { HealthCheckResponse, JobExecution, Organization } from 'types/models';
import { OrganizationSendingMode } from 'types/models';
import { RightsCategory } from 'types/models';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import formatIntlDate from 'utils/formatIntlDate';
import apiOrganization from 'api/organization';
import FormSelectMinimal from 'components/FormSelectMinimal';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';

type Props = {
  defaultValue: OrganizationSendingMode,
  lastExecutedAt: string | null,
  timeZone?: string,
  onShowToast(message: string, status: ToastStatus): void,
};

const SendingMode = (props: Props): JSX.Element | null => {
  const { onShowToast, defaultValue, lastExecutedAt, timeZone } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const [isLoading, setLoading] = useState<boolean>(false);
  const jobExecutionId = useRef<number>();
  const [lastSent, setLastSent] = useState<string | null>(
    lastExecutedAt ? formatIntlDate(lastExecutedAt, 'dateTimeShort') : null,
  );

  const {
    post,
    put,
    get,
    error,
    resetError,
  } = useApiRequest();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleConfirmSend = useCallback(() => {
    if (currentOrganization?.isInProduction) {
      showConfirm(t('customization:confirm.send-confirm-title'), t('customization:confirm.send-confirm-caption'));
      return;
    }

    onShowToast(t('customization:is-demo'), 'error');
  }, [showConfirm, currentOrganization?.isInProduction, onShowToast, t]);

  const sendingModeOptions = useMemo(() => (
    Object.values(OrganizationSendingMode).map((sendingMode) => ({
      value: sendingMode,
      label: t(`customization:sending-mode.${sendingMode}`),
    }))), [t]);

  const handleModeChange = useCallback( async (name: string, newValue: string | null) => {
    if (!currentOrganization) {
      return;
    }

    const result = await put<Organization>(
      apiOrganization.updatePeriodicTasksUrl(currentOrganization.id),
      { [name]: newValue },
    );

    if (result) {
      organizationStore.refresh();
      onShowToast(t('users:toast.success.settings-update'), 'success');
      return;
    }
    onShowToast(t('errors:unknown-retry'), 'error');
  }, [currentOrganization, onShowToast, put, t]);

  const handleSendNow = useCallback(async () => {
    hideConfirm();
    setLoading(true);

    const result = await post<JobExecution | {}>(
      apiOrganization.executePeriodicTasks(currentOrganization!.id),
      {},
    );

    if (result && result.hasOwnProperty('id')) {
      const id = (result as JobExecution).id;
      jobExecutionId.current = id;
      localStorage.setItem('jobExecutionId', id.toString());
      return;
    }

    if (result && Object.keys(result).length === 0) {
      const { locale } = authStore.user || config.DEFAULT_LOCALE;
      const safeLocale = locale.length < 2 ? config.DEFAULT_LOCALE.locale : locale;
      onShowToast(t('customization:send-successfully'), 'success');
      const intlObject = new Intl.DateTimeFormat(
        safeLocale.replace('_', '-'),
        {
          day: '2-digit',
          month: 'long',
          hour: '2-digit',
          minute: '2-digit',
          ...(timeZone && { timeZone }),
        },
      );
      setLastSent(intlObject.format(new Date()));
    }

    setLoading(false);
  }, [onShowToast, t, post, currentOrganization, timeZone, hideConfirm]);

  useEffect(() => {
    const storedJobId = localStorage.getItem('jobExecutionId');
    if (storedJobId) {
      jobExecutionId.current = parseInt(storedJobId, 10);
    }

    let intervalId: NodeJS.Timeout;
    const reset = () => {
      clearInterval(intervalId);
      jobExecutionId.current = undefined;
      setLoading(false);
      localStorage.removeItem('jobExecutionId');
    };

    const checkSendingStatus = async () => {
      if (!jobExecutionId.current) {
        return;
      }

      setLoading(true);
      const healthCheckResponse = await get<HealthCheckResponse>(
        apiOrganization.periodicTasksJobStatus(jobExecutionId.current),
      );

      if (!healthCheckResponse) {
        reset();
        return;
      }

      const { status, healthCheckTime } = healthCheckResponse;

      switch (status) {
        case 'FAILED':
          onShowToast(t('customization:sending-failed'), 'error');
          reset();
          break;
        case 'COMPLETED':
          onShowToast(t('customization:send-successfully'), 'success');
          setLastSent(formatIntlDate(healthCheckTime.date, 'dateHour'));
          reset();
          break;
      }
    };

    // Vérifier le statut toutes les 5 secondes
    intervalId = setInterval(checkSendingStatus, 5000);

    // Cleanup lors du démontage du composant
    return () => { clearInterval(intervalId); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobExecutionId]);

  return (
  <div className="SendingMode">
    <div className="SendingMode__content">
      <div className="SendingMode__content__value">
        <FormSelectMinimal
          onSelect={handleModeChange}
          defaultValue={defaultValue}
          name="mode"
          selectOptions={sendingModeOptions}
        />
      </div>
      <ButtonWithUserRights
        action="UPDATE"
        category={RightsCategory.PREFERENCES_CUSTOMIZATION_SEND}
        variant="link"
        className={classnames('SendingMode__content__button-send-now', {
          'SendingMode__content__button-send-now--disabled': isLoading,
        })}
        onClick={handleConfirmSend}
      >
        {isLoading && <Loading hasNoText={true} />}
        <span className='SendingMode__content__button-send-now__text'>
          {t('customization:list-sending.send-now')}
        </span>
      </ButtonWithUserRights>
      {(!isLoading && !!lastSent) &&
        <div className="SendingMode__content__last-sent">
        {`${t('customization:list-sending.last-sent', { date: lastSent } )}`}
        </div>
      }
    </div>
    {!!error && <ErrorMessage error={error} onClose={resetError} />}
    <Confirm
      titleModal={confirmTitle}
      text={confirmText}
      isShow={isConfirmShowed}
      onConfirm={handleSendNow}
      onCancel={hideConfirm}
      variant="primary"
      confirmButtonText={t('common:validate')}
    />
  </div>
  );
};

export default observer(SendingMode);
