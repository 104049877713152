import type { TFunction } from 'i18next';
import Misc from 'types/misc';
import { Channel } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';

const CustomersFilters = (
  t: TFunction,
): Misc.FilterDeclarationItem[] => [
  {
    title: t('clients:filters.manager'),
    emptyText: t('clients:filters.all-managers'),
    key: 'assigned_user',
    type: 'selectManager',
    entityValue: 'reference',
    isNotRemovable: true,
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('clients:filters.dunning-plans'),
    key: 'assigned_scenario',
    type: 'selectScenario',
    emptyText: t('clients:filters.all-dunning-plans'),
    isNotRemovable: true,
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('clients:filters.virtual-dunning-plans'),
    key: 'assigned_virtual_scenario',
    type: 'selectScenario',
    emptyText: t('clients:filters.all-dunning-plans'),
    isNotRemovable: true,
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('clients:filters.dunning-plans-group'),
    key: 'scenario_group',
    type: 'selectScenarioGroup',
    emptyText: t('clients:filters.all-dunning-plans-group'),
    isNotRemovable: true,
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('clients:filters.total-debt'),
    key: 'total_debt',
    type: 'numberInput',
    withCurrencyAddon: true,
    operators: [
      '=',
      '!=',
      '>',
      '<',
    ],
  },
  {
    title: t('clients:filters.total-due-debt'),
    key: 'total_due_debt',
    type: 'numberInput',
    withCurrencyAddon: true,
    operators: [
      '=',
      '!=',
      '>',
      '<',
    ],
  },
  {
    title: t('clients:filters.nb-debit-indebted'),
    key: 'nb_debit_indebted',
    type: 'numberInput',
    operators: [
      '=',
      '!=',
      '>',
      '<',
    ],
  },
  {
    title: t('clients:filters.oldest-debit'),
    key: 'oldest_debit',
    type: 'dateInput',
    operators: [
      '>',
      '<',
    ],
  },
  {
    title: t('clients:filters.next-action'),
    type: 'select',
    key: 'dunning_next_action',
    selectData: [
      { value: '1', label: t('clients:filters.with-next-action') },
    ],
    emptyText: t('common:please-choose'),
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('clients:filters.dunning-action'),
    key: 'dunning_action',
    type: 'select',
    isNotRemovable: true,
    selectData: [
      { value: 'less-than-month', label: t('clients:filters.less-than-month') },
      { value: 'more-than-month', label: t('clients:filters.more-than-month') },
    ],
    operators: [
      'PERIOD',
    ],
  },
  {
    title: t('common:channel'),
    emptyText: t('common:filters-selected.all-channels'),
    key: 'dunning_action_channel',
    type: 'select',
    isNotRemovable: true,
    selectData: Object.values(Channel).map((status) => (
      { value: status, label: t(getI18nChannel(status)) }
    )),
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('clients:filters.attribute'),
    key: 'attributes',
    type: 'selectAttributes',
    emptyText: t('common:please-choose'),
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('clients:filters.notes'),
    type: 'select',
    key: 'has_note',
    selectData: [
      { value: '1', label: t('clients:filters.with-notes') },
    ],
    emptyText: t('common:please-choose'),
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('clients:filters.muted'),
    type: 'select',
    key: 'is_muted',
    selectData: [
      { value: '1', label: t('clients:filters.is-muted') },
    ],
    emptyText: t('common:please-choose'),
    isNotRemovable: true,
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: t('common:searching'),
    key: 'search',
    type: 'custom',
    isHidden: true,
  },
  {
    title: t('common:clients'),
    type: 'select',
    key: 'active',
    isHidden: true,
    selectData: [
      { value: '1', label: t('clients:filters.with-debt') },
      { value: '0', label: t('common:tabs-status-all') },
    ],
  },
];

export default CustomersFilters;
