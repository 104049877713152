import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isHistoryActivity, type ClientHistory, HistoryActivity, isStepAction, StepAction, StepActionEntity } from 'types/models';
import dateIsToday from 'utils/dateIsToday';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import formatIntlDate from 'utils/formatIntlDate';

type Props = {
  data: ClientHistory,
};

const CustomerLastActivitiesItem = ({ data }: Props): JSX.Element => {
  const historyActivityItem = isHistoryActivity(data) ? data as HistoryActivity : null;
  const stepActionItem = isStepAction(data) ? data as StepAction : null;
  const { t } = useTranslation();
  const debit = stepActionItem ? stepActionItem.debit : null;

  const date = useMemo<string>(() => {
    let displayDate = '';
    if (stepActionItem) {
      const { doneAt, cancel, validation, at } = stepActionItem;
      displayDate = doneAt ?? cancel?.at ?? validation?.at ?? at?.at;

      if (dateIsToday(displayDate)) {
        return t('common:today-shortened');
      }
      if (dateIsLastTwoDays(displayDate)) {
        return t('common:yesterday-shortened');
      }
    } else if (historyActivityItem) {
      displayDate = historyActivityItem.loggedAt;
    }

    return formatIntlDate(displayDate, 'dayMonthShort');
  }, [historyActivityItem, stepActionItem, t]);

  const contentTitle = useMemo<string>(() => {
    if (stepActionItem) {
      return (stepActionItem.entity === StepActionEntity.TASK ? stepActionItem.name : stepActionItem.description) ?? '';
    }

    if (historyActivityItem) {
      const planChange = [
        'step_action.assignment.switch_plan',
        'step_action.assignment.switch_plan_detailed',
      ];

      if (planChange.includes(historyActivityItem.key ?? '')) {
        return t('plans:plan-change');
      }

      return historyActivityItem.translation ?? '';
    }
    return '';
  }, [historyActivityItem, stepActionItem, t]);

  return (
    <li className="CustomerLastActivitiesItem">
      <div className="CustomerLastActivitiesItem__date">
        {date}
      </div>
      <div className="CustomerLastActivitiesItem__content">
        <div className="CustomerLastActivitiesItem__content__title">
          {contentTitle.replace(/@([^@]+)@/, '')}
        </div>
        <div className="CustomerLastActivitiesItem__content__data">
          {debit && (
            <Link
              to={`/debit/${debit.id}`}
              className="CustomerLastActivitiesItem__content__link"
            >
              {debit.identifier}
            </Link>
          )}
        </div>
      </div>
    </li>
  );
};

export default CustomerLastActivitiesItem;
