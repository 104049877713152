import './index.scss';
import PublicLayout from 'layout/PublicLayout';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ResponseError from 'utils/errors';
import apiBoarding, { FetchCheckResetPasswordTokenParams } from 'api/boarding';
import useFetch from 'hooks/useFetch2';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import ResetPasswordForm from './Form';

type RouteParams = {
  authorizationCode: string,
};

const ResetPassword = (): JSX.Element => {
  const { authorizationCode } = useParams<RouteParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { token } = queryString.parse(location.search);

  const [error, setError] = useState<ResponseError | null>(null);

  const { isLoading, data, error: errorCheckToken } = useFetch<FetchCheckResetPasswordTokenParams, boolean>(
    { cacheKey: 'checkResetPasswordToken', token: token as string },
    apiBoarding.checkResetPasswordToken,
    { retry: false },
  );

  useEffect(() => {
    if (!isLoading && errorCheckToken) {
      history.push('/forgotten-password');
    }
  }, [errorCheckToken, history, isLoading]);

  const handleSubmit = useCallback(async (passwordNew: string, passwordNewRepeat: string) => {
    try {
      await apiBoarding.confirmPasswordReset(passwordNew, passwordNewRepeat, authorizationCode, token as string);
      history.push('/login', { passwordChanged: true });
    } catch (err) {
      setError(err as ResponseError);
    }
  }, [authorizationCode, history, token]);

  return (
    <PublicLayout>
      <div className="ResetPassword">
        <div className="ResetPassword__form">
          <h2 className="ResetPassword__title">{t('forgotten-password:title')}</h2>
          {(!isLoading && !errorCheckToken && data) && (
            <ResetPasswordForm
              onSubmit={handleSubmit}
              error={error?.message || null}
            />
          )}
          {isLoading && <Loading hasNoText />}
          <div className="ResetPassword__errors">
            {errorCheckToken && <ErrorMessage error={errorCheckToken} />}
            {error && <ErrorMessage error={error} />}
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default observer(ResetPassword);
