import type { Collection } from 'types/api';
import type { Organization, User, UserRights } from 'types/models';
import requester from 'utils/requester';

export type FetchAllParams = {
  organizationId: number | undefined,
};

export type UserPassword = {
  password: string,
  newPassword: string,
  repeatedPassword: string,
};

export type FetchAllSearchHistoryParams = {
  organizationReference?: Organization['reference'],
};

/**
 * Récupère une liste de users.
 *
 * @param id Id de l'organization.
 * @returns Une liste de users.
 */
const all = async ({ organizationId }: FetchAllParams): Promise<User[]> => {
  if (!organizationId) {
    throw new Error('FetchAllUsers: Missing organization id.');
  }

  const { data } = await requester.get<Collection<User>>(
    `organizations/${organizationId}/users`,
  );
  return data['hydra:member'];
};

const fetchRights = async () => {
  const { data } = await requester.get<Collection<UserRights>>('user_rights');
  return data['hydra:member'].length > 0 ? data['hydra:member'] : [];
};

/**
 * Récupère l'URL de la ressource d'API pour modifier l'user.
 *
 * @param id L'ID du block de l'user
 * @returns URL du PUT.
 */
const updateUrl = (id: User['id']) => `users/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour modifier le mot de passe de l'utilisateur.
 *
 * @param id L'ID de l'utilisateur
 * @returns URL du PUT.
 */
const updatePassword = (id: User['id']) => `users/${id}/password`;

/**
 * Récupère l'URL de la ressource API pour mapper une association de user.
 *
 * @param id L'ID d'user
 * @returns URL de l'association.
 */
const resourceUrl = (id: string | number) => `/api/users/${id}`;

const getEntity = () => {
  const pathname = window.location.pathname.split('/')[1];
  switch (pathname) {
    case 'customers':
      return 'client';
    case 'contacts':
      return 'contact';
    case 'debits':
      return 'debit';
    case 'credits':
      return 'credit';
    case 'actions-future':
      return 'step_action';
    case 'actions-done':
      return 'step_action_history';
    default:
      return null;
  }
};

/**
 * Récupère l'URL de la ressource API pour modifier le log de recherche.
 *
 * @param entity Le nom de l'entité de la grille
 * @returns URL du POST.
 */
const updateSearchLogUrl = (organizationReference?: Organization['reference']) => {
  const entity = getEntity();
  if (!organizationReference) {
    throw new Error('SearchLog: Missing organization reference.');
  }

  if (!entity) {
    throw new Error('SearchLog: No grid entity provided');
  }

  const queryData = new URLSearchParams();
  queryData.append('gridName', entity);
  queryData.append('organization', organizationReference);

  return `users/query_search_logs?${queryData.toString()}`;
};

/**
 * Récupère une liste de l'historique des recherches de l'utilisateur.
 *
 * @param entity Le nom de l'entité de la grille
 * @returns une liste de l'historique.
 */
const allSearchLog = async ({ organizationReference }: FetchAllSearchHistoryParams): Promise<string[]> => {
  const entity = getEntity();
  if (!organizationReference) {
    throw new Error('SearchLog: Missing organization reference.');
  }

  if (!entity) {
    throw new Error('SearchLog: No grid entity provided');
  }

  const queryData = new URLSearchParams();
  queryData.append('gridName', entity);
  queryData.append('organization', organizationReference);

  const { data } = await requester.get(
    `users/query_search_logs?${queryData.toString()}`,
  );
  return data;
};

export default {
  all,
  fetchRights,
  resourceUrl,
  updateUrl,
  updatePassword,
  updateSearchLogUrl,
  allSearchLog,
};
