import { ButtonView, createDropdown, InputTextView, ListItemView, ListView, Plugin } from 'ckeditor5';
import organizationStore from 'stores/Organization';

export default class TagsPlugin extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add('tags', (locale) => {
      const dropdownView = createDropdown(locale);

      const searchView = new InputTextView(locale);
      searchView.set({ placeholder: 'Rechercher' });

      const listView = new ListView(locale);
      const tags = organizationStore.currentOrganization?.tags ?? [];
      const tagsOptions = tags
        .sort((a, b) => a.identifier.localeCompare(b.identifier))
        .map(({ identifier }) => identifier);

      const updateList = (filter = '') => {
        listView.items.clear();

        tagsOptions
          .filter((option) => option.toLowerCase().includes(filter.toLowerCase()))
          .forEach((option) => {
            const listItemView = new ListItemView(locale);
            const buttonView = new ButtonView(locale);

            buttonView.set({
              label: option,
              withText: true,
              tooltip: true,
            });

            buttonView.on('execute', () => {
              const viewFragment = editor.data.processor.toView(option);
              const modelFragment = editor.data.toModel(viewFragment);
              editor.model.insertContent(modelFragment, editor.model.document.selection);
              dropdownView.isOpen = false;
            });

            listItemView.children.add(buttonView);
            listView.items.add(listItemView);
          });
      };

      updateList();

      searchView.on('input', () => {
        updateList(searchView.element!.value);
      });

      dropdownView.panelView.children.add(searchView);
      dropdownView.panelView.children.add(listView);

      dropdownView.buttonView.set({
        label: 'Tags',
        withText: true,
        class: 'tags-plugin',
      });

      return dropdownView;
    });
  }
}
