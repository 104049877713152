import './index.scss';
import classnames from 'classnames';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import getI18nTypeImport from 'utils/getI18nTypeImport';
import getI18nReportStep from 'utils/getI18nReportStep';
import { ImportItemFormat, ImportItemStatus, ImportItemStep, ImportItemType } from 'types/models';
import { ReportContext } from '../../../..';
import { ReportFileContext } from '..';

const ImportsReportFileHeader = (): JSX.Element | null => {
  const { t } = useTranslation();
  const report = useContext(ReportContext);
  const file = useContext(ReportFileContext);

  if (!file) {
    return null;
  }

  const {
    nbLineDraft: draftCount,
    writeCount: createdCount,
    updateCount: updatedCount,
    filterCount: skippedCount,
    status,
    nbErrorsByFile,
    nbErrorsByLine,
    readCount,
    downloadable,
    presignUrl,
    summary,
    label,
    errors,
    failures,
    warnings,
    job,
  } = file;

  const className = classnames('ImportsReportFileHeader__summary', {
    'ImportsReportFileHeader__summary--warning': warnings.length,
    'ImportsReportFileHeader__summary--error': failures.length || errors.length,
  });

  return (
    <div className="ImportsReportFileHeader">
      <div className="ImportsReportFileHeader__details">
        <div className="ImportsReportFileHeader__infos">
          <Icon name="dot" />
          <h4 className="ImportsReportFileHeader__infos__title">
            {(Object.values(ImportItemStep) as string[]).includes(label) ?
              t(getI18nReportStep(label as ImportItemStep)) : label
            }
          </h4>
          {status !== ImportItemStatus.FAILED &&
            <p className="ImportsReportFileHeader__infos__text">
              {t('reports:line', { count: readCount })}
            </p>
          }
        </div>
          <div className="ImportsReportFileHeader__tabs">
            {!!nbErrorsByLine || !!nbErrorsByFile || status === ImportItemStatus.FAILED && (
              <span
              className="ImportsReportFileHeader__tabs__tab ImportsReportFileHeader__tabs__tab--error"
              >
                {t('reports:error', { count: failures.length + errors.length })}
              </span>
            )}
            {status !== ImportItemStatus.FAILED && (
              <Fragment>
              {createdCount > 0 && (
                <span
                  className='ImportsReportFileHeader__tabs__tab ImportsReportFileHeader__tabs__tab--created'
                >
                  {t('reports:line-created', { count: createdCount })}
                </span>
              )}
              {updatedCount > 0 && (
                <span
                  className="ImportsReportFileHeader__tabs__tab ImportsReportFileHeader__tabs__tab--updated"
                >
                  {t('reports:line-updated', { count: updatedCount })}
                </span>
              )}
              {skippedCount > 0 && (
                <span
                  className="ImportsReportFileHeader__tabs__tab ImportsReportFileHeader__tabs__tab--skipped"
                >
                  {t('reports:line-skipped', { count: skippedCount })}
                </span>
              )}
              </Fragment>
            )}
          </div>
        <div className="ImportsReportFileHeader__actions">
          {(report?.fileExtension === ImportItemFormat.PDF && draftCount > 0) && (
            <Button
              variant="primary"
              href="/debits/draft"
              className="ImportsReportFileHeader__actions__complete"
            >
              {t('common:complete')}
            </Button>
          )}
          <div className="ImportsReportFileHeader__actions__web">
            <h5 className="ImportsReportFileHeader__actions__web__text">
              {(Object.values(ImportItemType) as string[]).includes(job) ?
                t(getI18nTypeImport(job as ImportItemType), 'short') : (job || t('reports:web'))
              }
            </h5>
          </div>
          {downloadable && (
            <Dropdown
              toggle={<Icon name="dots" />}
              variant="outline"
              noIcon
              className="ImportsReportFileHeader__actions__menu"
            >
              <Link
                to={{ pathname: presignUrl }}
                target="_blank"
                className="Button Button--list ImportsReportFileHeader__actions__download"
                >
                {t('reports:download-file')}
              </Link>
            </Dropdown>
          )}
        </div>
      </div>
      {!!Object.values(summary ?? {}).length &&
        <p className={className}>
          {Object.values(summary!)[0]}
        </p>
      }
      {!!errors.length &&
        <p className="ImportsReportFileHeader__summary ImportsReportFileHeader__summary--error">
          {`${t('reports:error-label')}: ${ typeof errors === 'string' ? errors : errors.join(', ')}`}
        </p>
      }
      {!!failures.length &&
        <p className="ImportsReportFileHeader__summary ImportsReportFileHeader__summary--error">
          {`${t('reports:error-label')}: ${ typeof failures === 'string' ? failures : failures.join(', ')}`}
        </p>
      }
    </div>
  );
};

export default observer(ImportsReportFileHeader);
