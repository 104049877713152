import 'ckeditor5/ckeditor5.css';
import './index.scss';
import { observer } from 'mobx-react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor, Autoformat, AutoImage, AutoLink, Bold,
  Essentials, GeneralHtmlSupport, HtmlComment, ImageBlock,
  ImageInline, ImageInsertViaUrl, ImageResize, FontSize,
  ImageStyle, ImageTextAlternative, ImageToolbar, ImageUpload,
  Italic, Link, LinkImage, List, ListProperties, Paragraph,
  SourceEditing, Table, TableCellProperties, TableToolbar,
  TableColumnResize, TableProperties, FontColor, icons,
  TextTransformation, Undo, Underline, ButtonView, Alignment,
  ImageInsert,
} from 'ckeditor5';
import ModalContext from 'components/ModalForm/context';
import TagsPlugin from './plugins/Tags';

type Props = {
  name: string,
  defaultValue: string,
  replaceValue?: string,
  toBePrinted?: boolean,
  onChange?(content: string): void
};

const Wysiwyg = (props: Props): JSX.Element => {
  const editorRef = useRef<ClassicEditor | null>(null);
  const { name, defaultValue, replaceValue, toBePrinted = false, onChange } = props;
  const { sourceView, setSourceView } = useContext(ModalContext);
  const [isLayoutReady, setIsLayoutReady] = useState<boolean>(false);
  const [editorData, setEditorData] = useState(defaultValue || '');

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  useEffect(() => {
    if (replaceValue) {
      setEditorData(replaceValue);
    }
  }, [replaceValue]);

  useEffect(() => {
    if (onChange) {
      onChange(editorData);
    }
  }, [editorData, onChange]);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    const sourceEditing = editorRef.current.plugins.get('SourceEditing');
    sourceEditing.set('isSourceEditingMode', sourceView);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceView]);

  const editorConfiguration = useMemo(() => {
    const allOptions = [
      'undo', 'redo', 'fontSize',
      'bold', 'italic', 'underline',
      'fontColor', 'alignment', 'link',
      {
        icon: icons.bulletedList,
        label: 'lists',
        withText: false,
        items: ['bulletedList', 'numberedList'],
      },
      'insertImageViaUrl',
      'insertTable', 'tableCellProperties',
      'tags', 'sourceEditing',
    ];
    const printOptions = allOptions.filter(
      (optionName) => !['link'].includes(
        typeof optionName === 'string' ? optionName : (optionName as any).label,
      ),
    );

    return {
	  	language: 'fr',
	  	allowedContent : true,
      toolbar: {
        items: toBePrinted ? printOptions : allOptions,
        shouldNotGroupWhenFull: true,
      },
      plugins: [
        Underline, FontColor, FontSize, ImageInsert, Alignment,
        Autoformat, AutoImage, AutoLink, Bold, Essentials,
        GeneralHtmlSupport, HtmlComment, ImageBlock,
        ImageInline, ImageInsertViaUrl, ImageResize, ImageStyle,
        ImageTextAlternative, ImageToolbar, ImageUpload,
        Italic, Link, LinkImage, List, ListProperties, Paragraph,
        SourceEditing, Table, TableCellProperties,
        TableColumnResize, TableProperties, TableToolbar,
        TextTransformation, Undo, TagsPlugin,
      ],
      fontSize: {
        options: [9, 11, 13, 14, 15, 16, 18, 24, 30, 36, 48],
      },
      htmlSupport: {
        allow: [
          {
            name: /^.*$/,
            styles: true,
            attributes: true,
            classes: true,
          },
        ],
      },
      fontColor: {
        documentColors: 0,
        colors: [
          { color: '#000000', label: 'Black' },
          { color: '#ffffff', label: 'White', hasBorder: true },
          { color: '#f7da64', label: 'Yellow' },
          { color: '#f37934', label: 'Orange' },
          { color: '#41a85f', label: 'Green' },
          { color: '#2969b0', label: 'Blue' },
          { color: '#d14841', label: 'Red' },
          { color: '#8555ab', label: 'Purple' },
          { color: '#333333', label: 'Dark Grey' },
          { color: '#666666', label: 'Grey' },
          { color: '#999999', label: 'Light Grey' },
          { color: '#cccccc', label: 'Very Light Grey' },
        ],
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
      },
    };
  }, [toBePrinted]);

  return (
    <div className="Wysiwyg">
      {isLayoutReady &&
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration as any}
          data={editorData}
          onChange={(_event, editor) => {
            let data = editor.getData();
            data = data.replace(/<figure/g, '<figure style="margin: 0 auto"');
            setEditorData(data);
          }}
          onReady={(editor) => {
            editorRef.current = editor;

            if (setSourceView) {
              const sourceEditing = editor.plugins.get('SourceEditing');
              const toolbar = editor.ui.view.toolbar.items;
              (toolbar.get(toolbar.length - 1) as ButtonView).label = 'HTML';

              sourceEditing.on(
                'change:isSourceEditingMode',
                (_eventInfo: unknown, _name: string, value: boolean) => {
                  setSourceView(value);
                },
              );
            }
          }}
        />
      }
      <textarea
        name={name}
        className="Wysiwyg__hidden-field"
        value={editorData}
        readOnly
      />
    </div>
  );
};

export default observer(Wysiwyg);
