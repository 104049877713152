import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import type { ValidationErrors } from 'types/errors';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import FormSelect from 'components/FormSelect';
import getI18nOperatorComparison from 'utils/getI18nOperatorComparison';
import { AllOperators, DaysOperators, MonthsOperators, SelectorsFieldsData, SelectorsFieldsTypes } from 'types/models';
import { getFieldDescription } from '../utils';
import ConditionDate from '../ConditionDate';

type Props = {
  name: string,
  errors?: ValidationErrors | null,
  defaultValue: string | boolean | null,
  operatorValue?: string | null,
  searchDefaultValue?: boolean,
  onChangeValue(newValue: string | boolean | null): void,
  onChangeOperatorValue?(newValue: string | null): void,
  onChangeFirstField?(newValue: string | null): void,
  onChangeSearchDefaultValue?(newValue: boolean): void,
};

const ConditionField = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    name,
    onChangeValue,
    onChangeOperatorValue,
    onChangeFirstField,
    onChangeSearchDefaultValue,
    operatorValue,
    searchDefaultValue,
    defaultValue,
    errors,
  } = props;

  const [fieldData, setFieldData] = useState<SelectorsFieldsData | null>(null);
  const [isDaysOperators, setDaysOperators] = useState<boolean>(false);
  const [isMonthsOperators, setMonthsOperators] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    const data = getFieldDescription(name, t);
    setFieldData(data);
    if (
      searchDefaultValue &&
      defaultValue &&
      onChangeFirstField &&
      data &&
      data.options &&
      !data.options.map(({ value }) => value).includes(String(defaultValue))
    ) {
      onChangeFirstField('FREE_FIELD');
    }
  }, [defaultValue, name, onChangeFirstField, searchDefaultValue, t]);

  useEffect(() => {
    fetchData();
    onChangeValue(defaultValue);
    if (onChangeSearchDefaultValue) {
      onChangeSearchDefaultValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const { type, options, optionsBy, placeholder, operators, isMultiple } = fieldData ?? {};

  const selectOptions = useMemo(() => {
    if (options) {
      return options;
    }

    if (optionsBy && operatorValue) {
      return optionsBy[operatorValue] || optionsBy.default;
    }

    return [];
  }, [operatorValue, options, optionsBy]);

  useEffect(() => {
    const isDate = isDaysOperators || isMonthsOperators;
    const data = isDate ? Object.values(isDaysOperators ? DaysOperators : MonthsOperators) : operators;
    if (
      operatorValue && data &&
      !data.includes(operatorValue) &&
      onChangeOperatorValue
    ) {
      onChangeOperatorValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDaysOperators, isMonthsOperators]);

  const operatorOptions = useMemo(() => {
    const isDate = isDaysOperators || isMonthsOperators;
    const data = isDate ? Object.values(isDaysOperators ? DaysOperators : MonthsOperators) : operators;
    return data?.map((operator) => ({
      value: operator,
      label: t(getI18nOperatorComparison(operator as AllOperators)),
    }));
  }, [isDaysOperators, isMonthsOperators, operators, t]);

  const handleSelectValue = useCallback((_name: string, newValue: string | null) => {
    if (!newValue) {
      onChangeValue(null);
    } else if (Array.isArray(newValue)) {
      onChangeValue(newValue.join(','));
    } else if (newValue === 'true' || newValue === 'false') {
      onChangeValue(newValue === 'true');
    } else {
      onChangeValue(newValue);
    }
  }, [onChangeValue]);

  const fieldProps = {
    name,
    placeholder: placeholder ?? t('selectors:choose-option'),
  };

  const isNullDate = type === SelectorsFieldsTypes.DATE && defaultValue === 'null';

  const defaultSelected = (isMultiple && typeof defaultValue === 'string')
    ? defaultValue.split(',').map((identifier) => ({ identifier }))
    : String(defaultValue);

  const fieldsClassNames = classnames({ 'ModalSelectorConditionsFields__date': type === SelectorsFieldsTypes.DATE });

  return (
    <Fragment>
      {(!!operators && !!onChangeOperatorValue) && (
        <Fragment>
          <FormGroup>
            <FormSelect
              name="OPERATOR"
              placeholder={t('selectors:choose-operator')}
              defaultValue={isNullDate ? null : operatorValue}
              onSelect={(_name, newValue) => { onChangeOperatorValue(newValue); }}
              selectOptions={isNullDate ? [] : operatorOptions}
              withClearButton={false}
              disabled={isNullDate}
            />
            {isNullDate && (
              <p className="ModalSelectorConditions__caption">{t('selectors:choose-option-first')}</p>
            )}
          </FormGroup>
          <div className="ModalSelectorConditions__operator__space"></div>
        </Fragment>
      )}
      <FormGroup className={fieldsClassNames}>
        {type === SelectorsFieldsTypes.SELECT &&
          <FormSelect
            {...fieldProps}
            selectOptions={selectOptions}
            defaultValue={defaultSelected}
            withClearButton={false}
            isMultiple={!!isMultiple}
            onSelect={handleSelectValue}
          />
        }
        {type === SelectorsFieldsTypes.TEXT &&
          <FormControl
            {...fieldProps}
            defaultValue={defaultValue !== 'null' ? defaultValue as string : ''}
            onChange={onChangeValue}
            type="text"
            autoComplete="off"
          />
        }
        {type === SelectorsFieldsTypes.NUMBER &&
          <FormControl
            {...fieldProps}
            defaultValue={defaultValue !== 'null' ? defaultValue as string : ''}
            onChange={onChangeValue}
            type="number"
            step={0.01}
          />
        }
        {type === SelectorsFieldsTypes.DATE &&
          <ConditionDate
            {...fieldProps}
            defaultValue={defaultValue !== 'null' ? defaultValue as string : ''}
            onChangeValue={onChangeValue}
            operator={operatorValue}
            onDaysOperator={setDaysOperators}
            onMonthsOperator={setMonthsOperators}
          />
        }
        <ErrorValidationMessage error={errors?.position} />
      </FormGroup>
    </Fragment>
  );
};

export default ConditionField;
