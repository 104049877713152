import './index.scss';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import Button from 'components/Button';
import useToast from 'components/ToastNotification/useToast';
import SettingsSection from '../Section';
import MyProfile from './MyProfile';
import MyOrganization from './MyOrganization';
import MyProfileModalEdit from './MyProfile/ModalEdit';
import MyOrganizationModalEdit from './MyOrganization/ModalEdit';

const SettingsProfile = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const { user, isBusy } = authStore;
  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  useEffect(() => {
    if (!user) {
      showToast(t('users:toast.error.user-empty'), 'error');
      setTimeout(() => { authStore.logout(); }, 3000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [showMyProfileEditModal, hideMyProfileEditModal] = useModal(() => (
    <MyProfileModalEdit
      onClose={hideMyProfileEditModal}
      onShowToast={showToast}
    />
  ), [showToast]);

  const [showMyOrganizationEditModal, hideMyOrganizationEditModal] = useModal(() => (
    <MyOrganizationModalEdit
      onClose={hideMyOrganizationEditModal}
      onShowToast={showToast}
    />
  ), [showToast]);

  if (!user) {
    return null;
  }

  return (
    <div className="SettingsProfile">
      <SettingsSection
        title={t('users:my-profile')}
        action={(
          <Button onClick={showMyProfileEditModal} variant="link" withCaretIcon>
            {t('common:edit')}
          </Button>
        )}
      >
        <MyProfile
          user={user}
          isBusy={isBusy}
          onShowToast={showToast}
        />
      </SettingsSection>
      {!!currentOrganization && (
        <SettingsSection
          title={t('users:in-my-organization')}
          action={(
            <Button
              onClick={showMyOrganizationEditModal}
              variant="link"
              withCaretIcon
            >
              {t('common:edit')}
            </Button>
          )}
        >
          <MyOrganization onShowToast={showToast} />
        </SettingsSection>
      )}
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsProfile);
