import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import FileDownload from 'js-file-download';
import { parse } from 'yaml';
import { ACCEPT_FILES_TYPE } from 'config';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import apiTemplateBlocks from 'api/template-blocks';
import ModalForm from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import Dropzone from 'components/Dropzone';
import Button from 'components/Button';
import FormCheck from 'components/FormCheck';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import Loading from 'components/Loading';
import useTooltip from 'components/Tooltip/useTooltip';
import Icon from 'components/Icon';

type Props = {
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const ModalEditYAML = (props: Props): JSX.Element | null => {
  const { onClose, onActionDone, onActionError } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const [replace, setReplace] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadIsValid, setUploadIsValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>();

  const { post, isLoading, error } = useApiRequest();

  const tooltipTarget = useTooltip<HTMLSpanElement>(
    <div className="ModalEditYAML__tooltip">
      {t('models:yaml-warning')}
    </div>,
  );

  const handleDrop = useCallback((files: File[]) => {
    setFile(files[0]);
    setUploadIsValid(false);
    setErrors([]);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!currentOrganization || !file) {
      return;
    }

    setErrors([]);
    setUploadIsValid(false);

    if (!replace) {
      // regarde si le yaml est valide seulement
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (e.target && e.target.result) {
          try {
            parse(e.target.result.toString());
            setUploadIsValid(true);
            setErrors([]);
          } catch (exception) {
            setErrors([exception as string]);
          }
        }
      };
      reader.readAsText(file);
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('organization', currentOrganization.reference);

    const result = await post(apiTemplateBlocks.importUrl, formData);
    if (!isMountedRef.current) {
      return;
    }

    if (!result) {
      onActionError(t('errors:unknown-retry'));
      return;
    }

    if (result.errors) {
      setErrors(Array.isArray(result.errors) ? result?.errors as string[] : []);
      return;
    }

    setUploadIsValid(true);
    onActionDone(t('models:toast.success.yaml-model'));
    onClose();
  }, [currentOrganization, file, isMountedRef, onActionError, onActionDone, onClose, post, replace, t]);

  const onDownloadTemplateClick = useCallback(async () => {
    if (!currentOrganization) {
      return;
    }
    const { data } = await apiTemplateBlocks.getTemplate(currentOrganization.reference);
    FileDownload(data, 'template.yaml');
  }, [currentOrganization]);

  if (!currentOrganization) {
    return null;
  }

  return (
    <ModalForm
      isOpened
      className="ModalEditYAML"
      onSave={handleSubmit}
      onCancel={onClose}
      title={t('models:templates-via-yaml')}
    >
      <p className="ModalEditYAML__description">{t('models:description-yaml')}</p>
      <Button className="ModalEditYAML__download" onClick={onDownloadTemplateClick}>{t('models:download-current-file')}</Button>
      <p className="ModalEditYAML__title">{t('models:new-yaml-file')}</p>
      <Dropzone
        onDrop={handleDrop}
        accept={ACCEPT_FILES_TYPE.YAML}
        maxFiles={1}
        withInstructions={false}
        filename={file?.name}
      />
      <ErrorValidationMessage error={errors ? errors[0] : null} />
      {error && error.code !== 400 && (
        <div className="ModalEditYAML__server-error">
          {error.message || t('models:toast.error.yaml-model')}
        </div>
      )}
      {isLoading && <Loading hasNoText />}
      {uploadIsValid && (<p className="ModalEditYAML__valid">{t('models:upload-is-valid', { name: file?.name })}</p>)}
      <FormFieldset>
        <FormCheck onChange={setReplace} checked={replace} />
        <p className="ModalEditYAML__check">{t('models:replace-current-templates')}</p>
        <div ref={tooltipTarget}>
          <Icon name="help-circle" />
        </div>
      </FormFieldset>
    </ModalForm>
  );
};

export default observer(ModalEditYAML);
