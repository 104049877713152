import './index.scss';
import { useMemo } from 'react';
import { ImportItemStep } from 'types/models';
import type { Import } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import formatIntlDate from 'utils/formatIntlDate';

type Props = {
  report: Import,
};

const SecondaryInfos = ({ report }: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { t } = useContextualTranslation(type);
  const { startAt, endAt, stepExecutions: files, author, createdAt } = report;

  const isSteps = useMemo(() => (
    !!report?.stepExecutions.filter((file) => (
      (Object.values(ImportItemStep) as string[]).includes(file.label)
    )).length
  ), [report?.stepExecutions]);

  return (
    <div className="ReportInfosSecondary">
      <div className="ReportInfosSecondary__section">
        <div className="ReportInfosSecondary__section__box">
          <h4 className="ReportInfosSecondary__section__box__title">
            {t('reports:beggining-of-import')}
          </h4>
          <p className="ReportInfosSecondary__section__box__subtitle">
            {formatIntlDate(startAt ?? createdAt, 'dateTimeFullShortMonth')}
          </p>
        </div>
        <div className="ReportInfosSecondary__section__box">
          <h4 className="ReportInfosSecondary__section__box__title">
            {t('reports:ending-of-import')}
          </h4>
          <p className="ReportInfosSecondary__section__box__subtitle">
            {formatIntlDate(endAt ?? createdAt, 'dateTimeFullShortMonth')}
          </p>
        </div>
      </div>
      <div className="ReportInfosSecondary__section">
        <div className="ReportInfosSecondary__section__box">
          <h4 className="ReportInfosSecondary__section__box__title">
            {t('reports:files')}
          </h4>
          <p className="ReportInfosSecondary__section__box__subtitle">
            {isSteps ? 1 : (files.length || 1)}
          </p>
        </div>
        <div className="ReportInfosSecondary__section__box">
          <h4 className="ReportInfosSecondary__section__box__title">
            {t('common:user')}
          </h4>
          <p className="ReportInfosSecondary__section__box__subtitle">
            {author}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecondaryInfos;
