import { useCallback, useState, useRef } from 'react';
import { SelectSearchOption } from 'react-select-search';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import { DefaultValue } from 'components/FormSelect/utils';
import apiCustomers from 'api/clients';
import apiClients from 'api/clients';

type UseSelectCustomerSettings = {
  withId: boolean,
  defaultValue?: DefaultValue,
  refreshTick?: number,
};

const useSelectCustomer = (
  handleChoseValue: (selectedValue: string) => void,
  settings: UseSelectCustomerSettings,
) => {
  const { withId, defaultValue, refreshTick = 0 } = settings;
  const { currentOrganization } = organizationStore;
  const { user } = authStore;
  const [options, setOptions] = useState<SelectSearchOption[]>([]);
  const previousQuery = useRef<string>('');

  const getOptions = useCallback(async (query: string): Promise<SelectSearchOption[]> => {
    if ((!query || query === previousQuery.current) && refreshTick === 0) {
      return options;
    }
    previousQuery.current = query;

    try {
      const result = await apiCustomers.all(
        currentOrganization?.id,
        {
          page: 0,
          pageSize: 30,
          organizationReference: currentOrganization?.reference,
          locale: user?.locale,
          attributes: [],
          filtering: [
            { name: 'search', value: encodeURIComponent(query) },
          ],
        },
      );
      if (!result) {
        setOptions([]);
        return [];
      }

      const optionsData = result.items.map(({ id, denomination: entryName, identifier: reference }) => ({
        name: entryName ?? reference ?? '',
        value: withId ? `${id}` : reference ?? '',
      }));

      setOptions(optionsData);
      return optionsData;
    } catch {
      return [];
    }
  }, [refreshTick, options, currentOrganization?.id, currentOrganization?.reference, user?.locale, withId]);

  const findDefaultOption = useCallback(async () => {
    if (!defaultValue) {
      return;
    }

    const tempOptions = await getOptions(defaultValue.toString());
    if (tempOptions.length > 0) {
      handleChoseValue(tempOptions[0].value.toString());
      return;
    }

    setOptions([{ name: `${defaultValue}`, value: `${defaultValue}` }]);
  }, [defaultValue, getOptions, handleChoseValue]);

  const getDefaultOption = useCallback(
    async () => {
      if (!defaultValue || !currentOrganization) {
        return;
      }

      const result = await apiClients.one({ id: (defaultValue as number) });
      if (result?.denomination) {
        setOptions([{ name: result.denomination, value: withId ? result.id : result.identifier }]);
        handleChoseValue(withId ? `${result.id}` : result.identifier);
      }
    },
    [currentOrganization, defaultValue, handleChoseValue, withId],
  );

  return {
    getOptions,
    options,
    getDefaultOption,
    findDefaultOption,
  };
};

export default useSelectCustomer;
