import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Button from 'components/Button';
import apiContacts from 'api/contacts';
import type { StepAction } from 'types/models';
import { StepActionEntity } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import ContactEditModal from 'components/ContactEditModal';
import config from 'config';
import apiHistorySteps, { StepActionStatus } from 'api/historySteps';

type Props = {
  data: StepAction,
  isMissingContact?: boolean,
  hasIncompleteContactRoles?: boolean,
  isRemoved?: boolean,
  customerId?: number,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onShowEdit(): void,
};

const HistoryStepActions = (props: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const {
    data,
    isMissingContact = false,
    hasIncompleteContactRoles = false,
    isRemoved = false,
    customerId,
    onActionDone,
    onActionError,
    onShowEdit,
  } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { patch, error, isLoading } = useApiRequest();

  const [confirmVariant, setConfirmVariant] = useState<'primary' | 'danger'>();
  const [confirmButtonText, setConfirmButtonText] = useState<string>();
  const [cancelButtonText, setCancelButtonText] = useState<string | undefined>(undefined);
  const [action, setAction] = useState<'activate' | 'validate' | 'remove'>();
  const [contactToEdit, setContactToEdit] = useState<number | null>(null);

  const { code, name, debit, client, entity, incompleteContactRoles } = data;

  const defaultRole = useMemo(() => {
    if (hasIncompleteContactRoles && incompleteContactRoles?.length) {
      return incompleteContactRoles[0];
    }
    return '';
  }, [hasIncompleteContactRoles, incompleteContactRoles]);

  const handleShowCancel = useCallback(() => {
    setConfirmVariant('danger');
    setCancelButtonText(t<string>('common:keep-as-is'));
    setAction('remove');
    if (entity === StepActionEntity.REMINDER) {
      setConfirmButtonText(t<string>('clients:actions.confirm.history.title.remove-reminder'));
      showConfirm(
        t('clients:actions.confirm.history.title.remove-reminder'),
        ct('clients:actions.confirm.history.remove-reminder'),
      );
    } else {
      setConfirmButtonText(t<string>('clients:actions.confirm.history.title.remove'));
      showConfirm(
        t('clients:actions.confirm.history.title.remove'),
        t('clients:actions.confirm.history.remove'),
      );
    }
  }, [showConfirm, t, ct, entity]);

  const handleConfirmValidate = useCallback(() => {
    setConfirmVariant('primary');
    setConfirmButtonText(t<string>('common:validate'));
    setAction('validate');
    showConfirm(
      t('clients:actions.confirm.history.title.validate'),
      ct('clients:actions.confirm.history.validate'),
    );
  }, [showConfirm, t, ct]);

  const handleConfirmActivate = useCallback(() => {
    setConfirmVariant('primary');
    setConfirmButtonText(t<string>('common:validate'));
    setAction('activate');
    showConfirm(
      t('clients:actions.confirm.history.title.activate'),
      t('clients:actions.confirm.history.activate'),
    );
  }, [showConfirm, t]);

  const handleSubmit = useCallback(async () => {
    hideConfirm();

    if (!action) {
      throw new Error('Action non définie.');
    }

    const messageProps = {
      name,
      reference: debit?.identifier,
    };

    const actions = {
      activate: {
        status: StepActionStatus.COMPLETED,
        onDoneText: ct('clients:actions.toast.history.activate', messageProps),
      },
      validate: {
        status: StepActionStatus.VALIDATED,
        onDoneText: ct('clients:actions.toast.history.validate', messageProps),
      },
      remove: {
        status: StepActionStatus.CANCEL,
        onDoneText: ct('clients:actions.toast.history.remove', messageProps),
      },
    };

    if (!actions[action]) {
      throw new Error('Action non reconnue.');
    }

    const { status, onDoneText } = actions[action];
    const response = await patch(apiHistorySteps.updateUrl(code), {
      status,
    });

    if (response !== null) {
      onActionDone(onDoneText);
    }
  }, [hideConfirm, action, name, debit?.identifier, ct, patch, code, onActionDone]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [showEditContactModal, hideEditContactModal] = useModal(() => (
    <ContactEditModal
      editType={contactToEdit ? 'edit' : 'new'}
      onDone={(message) => { onActionDone(message); hideEditContactModal(); }}
      id={contactToEdit}
      defaultCustomerId={client.id}
      defaultRole={hasIncompleteContactRoles ? defaultRole : ''}
      onClose={hideEditContactModal}
      customerId={contactToEdit ? undefined : client.id}
    />
  ), [contactToEdit, onActionDone, client, hasIncompleteContactRoles, defaultRole]);

  useEffect(() => {
    if (!!contactToEdit) {
      showEditContactModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactToEdit]);

  const handleNavigateToAddContact = useCallback(async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setContactToEdit(null);

    const customerIdToFetch = client.id ?? customerId;
    if (!customerIdToFetch) {
      return;
    }

    const contacts = await apiContacts.allForCustomer(
      {
        id: customerIdToFetch,
        params: {
          pageSize: config.DEFAULT_PAGE_SIZE,
          filtering: [
            { name: 'withEav', value: '1' },
            { name: 'withUser', value: '1' },
          ],
        },
      },
    );

    if (!contacts) {
      return;
    }
    const existingContact = contacts.find(
      ({ contactRole }) => contactRole.code === defaultRole,
    );

    if (existingContact) {
      setContactToEdit(existingContact.id);
      return;
    }
    showEditContactModal();
    return;
  }, [customerId, client, showEditContactModal, defaultRole]);

  if (isLoading) {
    return <Loading hasNoText isOverlay />;
  }

  return (
    <div className="HistoryStepActions">
      {(entity === StepActionEntity.REMINDER && hasIncompleteContactRoles && !isRemoved) && (
        <Button
          variant="outline"
          small
          onClick={handleNavigateToAddContact}
          className="HistoryStepActions__action"
        >
          {t('common:complete')}
        </Button>
      )}
      {(entity === StepActionEntity.REMINDER
        && !data.validation?.at
        && data.withValidation
        && !isMissingContact
        && !hasIncompleteContactRoles
        && !isRemoved) && (
          <Button
            variant="primary"
            small
            title={t('common:validate')}
            onClick={handleConfirmValidate}
            className="HistoryStepActions__action"
          >
            <Icon name="check" />
          </Button>
      )}
      {(entity === StepActionEntity.TASK && !data.validation?.at && !isRemoved) && (
        <Button
          variant="primary"
          small
          title={t('common:done')}
          onClick={handleConfirmActivate}
          className="HistoryStepActions__action"
        >
          <Icon name="check" />
        </Button>
      )}
      {(!data.cancel && !data.doneAt) && (
        <Button
          variant="outline"
          title={t('common:cancel')}
          onClick={handleShowCancel}
          className="HistoryStepActions__action"
        >
          <Icon name="close" />
        </Button>
      )}
      {(!data.cancel && !data.doneAt) && (
        <Button
          variant="outline"
          title={t('common:edit')}
          onClick={onShowEdit}
          className="HistoryStepActions__action"
        >
          <Icon name="calendar" />
        </Button>
      )}
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={debit?.identifier}
        isShow={isConfirmShowed}
        onConfirm={handleSubmit}
        onCancel={hideConfirm}
        variant={confirmVariant}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        key={data.id}
      />
    </div>
  );
};

export default observer(HistoryStepActions);
