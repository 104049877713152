import { CreditStatus } from 'types/models';

const getI18nCreditStatus = (creditStatus: CreditStatus): string => {
  const i18nCreditStatus = {
    [CreditStatus.SUCCESS]: 'credits:status.success',
    [CreditStatus.FAILED]: 'credits:status.failed',
    [CreditStatus.REFUNDED]: 'credits:status.refunded',
    [CreditStatus.PENDING]: 'credits:status.pending',
  };

  return i18nCreditStatus[creditStatus] || 'common:unknown';
};

export default getI18nCreditStatus;
