import './index.scss';
import Button from 'components/Button';

type Props = {
  onToggle(): void
};

const MenuToggle = ({ onToggle }: Props): JSX.Element => (
  <Button
    className="MenuToggle"
    onClick={onToggle}
  >
    <span className="MenuToggle__hamburger" />
  </Button>
);

export default MenuToggle;
