import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import InputModal from 'components/InputModal';
import Button from 'components/Button';
import Icon from 'components/Icon';
import type { Debit } from 'types/models';
import { DebitStatus } from 'types/models';
import apiDebits from 'api/debits';

type Props = {
  id: number,
  reference: string,
  defaultValue: string | null,
  onLoadingChange(isLoading: boolean): void,
  onDone(title: string, message: string): void,
};

const DebitsRowActionPromiseToPay = (props: Props): JSX.Element => {
  const { id, reference, defaultValue, onLoadingChange, onDone } = props;
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);

  const { put, error, isLoading } = useApiRequest();

  const handleSubmit = useCallback(
    async (inputModalValue: { [field: string]: string }) => {
      const [statusUpdateResult, dateUpdateResult] = await Promise.all([
        put<Debit>(apiDebits.updateStatusUrl(id), {
          status: DebitStatus.PROMISE,
        }),
        put<Debit>(apiDebits.updateUrl(id), inputModalValue),
      ]);

      if (statusUpdateResult && dateUpdateResult) {
        onDone(
          t('debits:promise-to-pay-defined'),
          ct('debits:actions.toast.promise-to-pay-defined', { reference }),
        );
      } else {
        return;
      }
    },
    [put, id, onDone, t, ct, reference],
  );

  useEffect(
    () => { onLoadingChange(isLoading); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const [showInputModal, hideInputModal] = useModal(() => (
    <InputModal
      isShow
      title={t('debits:edit-promise-to-pay')}
      help={ct('debits:edit-promise-to-pay-for-ref', { reference })}
      type="date"
      name="promiseToPayAt"
      defaultValue={defaultValue}
      isLoading={isLoading}
      error={error}
      minDate={new Date()}
      onSubmit={handleSubmit}
      onClose={hideInputModal}
      onCancel={hideInputModal}
    />
  ), [t, ct, reference, defaultValue, isLoading, error, handleSubmit]);

  return (
    <Button
      variant="list"
      onClick={showInputModal}
      className="DebitsRowActionPromiseToPay"
    >
      <Icon name="flash" />
      {t('debits:edit-promise-to-pay')}
    </Button>
  );
};

export default observer(DebitsRowActionPromiseToPay);
