import './index.scss';
import { useRef } from 'react';
import classnames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import type { Row } from 'react-table';
import type { Identifier } from 'dnd-core';
import Icon from 'components/Icon';
import Loading from 'components/Loading';

type Props<DataType extends object = {}> = {
  id: string | number,
  currentHandlerId: number | null,
  row: Row<DataType>,
  index: number,
  getRowClassName?(row: Row<DataType>): string,
  onMove(dragIndex: number, hoverIndex: number): void,
  onDrop(): void,
  disableSort: boolean,
};

type DragItem = {
  index: number,
  id: string | number,
};

export const ItemTypes = {
  ROW: 'row',
};

const SortableListRow = <DataType extends object = {}>(props: Props<DataType>): JSX.Element => {
  const { id, row, index, getRowClassName, onMove, onDrop, disableSort = false, currentHandlerId } = props;
  const ref = useRef<HTMLTableRowElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.ROW,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: (item: DragItem, monitor) => {
      if (!ref.current || disableSort) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // - Pour ne pas remplacer l'item par lui-même
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = monitor.getClientOffset()!.y - hoverBoundingRect.top;

      // - Pour n'effectuer le déplacement que lorsque le mouvement a atteint
      // - au moins 50% de la hauteur de l'élément lui-même
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ROW,
    item: () => ({ id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() && !disableSort }),
    end: () => { onDrop(); },
  });

  const opacity = isDragging ? 0.1 : 1;
  const isCurrentRow = currentHandlerId === parseInt(id as string, 10);

  drag(drop(ref));

  const classNames = classnames(
    'SortableListRow',
    getRowClassName ? getRowClassName(row) : undefined,
  );

  return (
    <tr ref={ref} style={{ opacity }} className={classNames} data-handler-id={handlerId}>
      <td className="SortableListRow__cell SortableListRow__cell--first">
        {!disableSort && <Icon name="grip-lines" />}
        {isCurrentRow && disableSort && <Loading hasNoText={true} />}
      </td>
      {row.cells.map((cell) => (
        <td {...cell.getCellProps()} className="SortableListRow__cell">
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
};

export default SortableListRow;
