import './index.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoResult } from './noResultsFound.svg';

type Props = {
  withoutSubtitle?: boolean,
};

const NoResultsFound = ({ withoutSubtitle = false }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="NoResultsFoundDrawing">
      <NoResult className="NoResultsFoundDrawing__drawing" />
      <h4>{t('common:no-result')}</h4>
      {!withoutSubtitle && (
        <p className="NoResultsFoundDrawing__subtitle">{t('common:no-corresponding-data')}</p>
      )}
    </div>
  );
};

export default NoResultsFound;
