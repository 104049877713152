import { DebitStatus } from 'types/models';

const getI18nStatus = (statusId: string): string => {
  const statusI18n = {
    [DebitStatus.IN_PROGRESS]: 'debits:status.active',
    [DebitStatus.ACTIVATE]: 'debits:status.active',
    [DebitStatus.PROMISE]: 'debits:promise',
    [DebitStatus.PAID]: 'debits:status.paid',
    [DebitStatus.CANCEL]: 'debits:status.cancelled',
    [DebitStatus.LITIGATED]: 'debits:status.litigated',
    [DebitStatus.FAILED]: 'common:reject',
  };

  if (!Object.keys(statusI18n).includes(statusId)) {
    return 'phases:unknown-phase';
  }

  return statusI18n[statusId as DebitStatus];
};

export default getI18nStatus;
