import './index.scss';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { ModalFormData } from 'components/ModalForm';
import ModalForm from 'components/ModalForm';
import FormGroup from 'components/FormGroup';
import FormRadios from 'components/FormRadios';

type Props = {
  daysCount: number,
  onChange(scenario: 'none' | 'move'): void,
  onResetDate(): void,
  onClose(): void,
};

const HistoryStepReminderAskMoveScenario = (props: Props): JSX.Element => {
  const { daysCount, onChange, onResetDate, onClose } = props;
  const { t } = useTranslation();

  const handleSave = useCallback((formData: ModalFormData | null) => {
    onChange(formData?.moveScenario === 'move' ? 'move' : 'none');
    onClose();
  }, [onChange, onClose]);

  const handleCancel = useCallback(() => {
    onChange('none');
    onResetDate();
    onClose();
  }, [onChange, onResetDate, onClose]);

  return (
    <ModalForm
      className="HistoryStepReminderAskMoveScenario"
      title={t('reminders:modified-date')}
      onSave={handleSave}
      onCancel={handleCancel}
      isOpened
      saveText={t('common:validate')}
    >
      <p className="HistoryStepReminderAskMoveScenario__help">
        {t('reminders:modified-date-intro', { count: daysCount })}
      </p>
      <FormGroup>
        <FormRadios
          name="moveScenario"
          items={[
            { value: 'move', label: t('reminders:move-scenario', { count: daysCount }) },
            { value: 'none', label: t('reminders:dont-move-scenario') },
          ]}
          defaultValue="none"
        />
      </FormGroup>
    </ModalForm>
  );
};

export default HistoryStepReminderAskMoveScenario;
