import { observer } from 'mobx-react';
import { useCallback, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import type { ManagerType } from 'types/models';
import { RightsCategoryType } from 'types/models';
import useContextualTranslation from 'hooks/useContextualTranslation';
import { UserRightsManagerController } from 'components/UserRights';
import customerContext from 'pages/Customer/customerContext';
import organizationStore from 'stores/Organization';
import Button from 'components/Button';
import DebitEdit from 'pages/DebitEdit';
type Props = {
  managers: ManagerType[] | null | undefined,
};

const CustomerActionNewDebit = ({ managers }: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { ct } = useContextualTranslation(type);
  const { data, onActionDone } = useContext(customerContext);

  const handleActionDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showDebitModal, hideDebitModal] = useModal(() => (
    <DebitEdit
      customerId={data?.id}
      onClose={hideDebitModal}
      onDone={(message) => { hideDebitModal(); handleActionDone(message); }}
    />
  ), [data, handleActionDone]);

  return (
    <UserRightsManagerController
      action="CREATE"
      categoryType={RightsCategoryType.PAYREQUESTS}
      managers={managers}
    >
      <Button
        variant="list"
        className="CustomerActionNewDebit"
        onClick={showDebitModal}
      >
        {ct('common:new-bill')}
      </Button>
    </UserRightsManagerController>
  );
};

export default observer(CustomerActionNewDebit);
