import './index.scss';
import { cloneElement } from 'react';
import { TableInstance } from 'react-table';
import { useTranslation } from 'react-i18next';
import { SelectionHookResult } from 'hooks/useSelection';
import Pagination from 'components/Pagination';

type Props<DataType extends object = {}> = {
  tableInstance: TableInstance<DataType>,
  totalRecords: number,
  select: SelectionHookResult,
  bulkActions?: JSX.Element,
  hasMultiplePages: boolean,
  bulkGender: 'f' | 'm',
  displaySelectedCount?: boolean,
};

const DataTableFooter = <DataType extends object = {}>(props: Props<DataType>): JSX.Element => {
  const { t } = useTranslation();
  const {
    tableInstance,
    totalRecords,
    select,
    bulkActions,
    hasMultiplePages,
    bulkGender,
    displaySelectedCount = true,
  } = props;
  const { selection, hasSelection } = select;
  const {
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <>
    <div className="DataTableFooter">
      <div className="DataTableFooter__actions">
        {bulkActions && cloneElement(bulkActions, { selection })}
        {(hasSelection && displaySelectedCount) && (
          <div className="DataTableFooter__selected-count">
            {t(
              bulkGender === 'm' ? 'common:count-selected' : 'common:count-selected-f',
              { count: selection.length },
            )}
          </div>
        )}
      </div>
    </div>
    {hasMultiplePages && !hasSelection && (
      <Pagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        totalRecords={totalRecords}
        nextPage={nextPage}
        previousPage={previousPage}
        onChangePageSize={setPageSize}
        onGotoPage={gotoPage}
      />
    )}
    </>
  );
};

export default DataTableFooter;
