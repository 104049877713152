import type { TFunction } from 'i18next';
import type Misc from 'types/misc';
import { DebitStatus } from 'types/models';

const DebitsFilters = (
  ct: TFunction,
  defaultValues: {
    status: DebitStatus,
    client?: string,
  },
): Misc.FilterDeclarationItem[] => [
  {
    title: ct('common:manager'),
    emptyText: ct('debits:filters.all-managers'),
    key: 'users',
    type: 'selectManager',
    entityValue: 'reference',
  },
  {
    title: ct('common:client'),
    emptyText: ct('debits:filters.all-clients'),
    key: 'client',
    type: 'selectClient',
    defaultValue: defaultValues.client,
  },
  {
    title: ct('common:due-after'),
    key: 'deadline_from',
    type: 'dateInput',
  },
  {
    title: ct('common:due-before'),
    key: 'deadline_to',
    type: 'dateInput',
  },
  {
    title: ct('common:min-amount'),
    key: 'amount_from',
    type: 'numberInput',
  },
  {
    title: ct('common:max-amount'),
    key: 'amount_to',
    type: 'numberInput',
  },
  {
    title: ct('common:status'),
    emptyText: ct('debits:filters.all-deadlines'),
    key: 'status',
    type: 'select',
    defaultValue: defaultValues.status,
    selectData: [
      {
        value: DebitStatus.IN_PROGRESS,
        label: ct('common:tabs-status-active'),
      },
      {
        value: DebitStatus.PAID,
        label: ct('common:tabs-status-paid'),
      },
      {
        value: DebitStatus.CANCEL,
        label: ct('common:tabs-status-cancel'),
      },
      {
        value: DebitStatus.LITIGATED,
        label: ct('common:tabs-status-litigated'),
      },
      {
        value: 'ALL',
        label: ct('common:tabs-status-all'),
      },
    ],
    isNotRemovable: true,
    isHidden: true,
  },
  {
    title: ct('common:searching'),
    key: 'search',
    type: 'custom',
    isHidden: true,
  },
];

export default DebitsFilters;
