import './index.scss';

type Props = {
  percentage: number,
  color: string,
};

const PercentBar = ({ percentage, color }: Props): JSX.Element => (
  <div className="PercentBar">
    <div
      className="PercentBar__progress"
      style={{ width: `${percentage}%`, backgroundColor: color, maxWidth: '100%' }}
    />
  </div>
);

export default PercentBar;
