import './index.scss';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import FreshDesk from 'components/FreshDesk';
import authStore from 'stores/Auth';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import ReportsCount from 'components/ReportsCount';
import ReportsDetailsCounts from 'components/ReportsDetailsCounts';
import { UserRightsController } from 'components/UserRights';
import organizationStore from 'stores/Organization';
import { RightsCategory } from 'types/models';

const UserMenu = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { user } = authStore;
  const { notifications } = organizationStore;

  const handleLogout = useCallback(() => {
    authStore.logout();
  }, []);

  if (!user) {
    return null;
  }

  const defaultImportsCounts = { inProgress: 0, finalized: { success: 0, error: 0, critical: 0 } };
  const { inProgress, finalized } = notifications?.imports || defaultImportsCounts;
  const notificationCount = inProgress + finalized.success + finalized.error + finalized.critical;
  const hasOrganizations = (user?.organizations && user.organizations.length !== 0);

  return (
    <Dropdown
      className="UserMenu"
      variant="outline"
      noIcon
      toggle={(
        <Avatar
          username={user.firstName}
          userAvatar={user.avatar}
          notificationCount={notificationCount}
        />
      )}
    >
      <div className="UserMenu__links">
        <Link to="/preferences" className="UserMenu__links__item">
          <Icon name="user" />{t('common:menu.user.profile')}
        </Link>
        <UserRightsController category={RightsCategory.BACK_OFFICE} action="READ">
          <Link to="/back-office" className="UserMenu__links__item">
            <Icon name="tool" />{t('common:menu.user.back-office')}
          </Link>
        </UserRightsController>
        {hasOrganizations && (
          <>
            <NavLink to="/preferences" className="UserMenu__links__item">
              <Icon name="settings" />{t('common:menu.user.preferences')}
            </NavLink>
            <hr className="UserMenu__links__divider" />
            <UserRightsController action="CREATE" category={RightsCategory.IMPORTS}>
              <NavLink to="/imports" exact className="UserMenu__links__item">
                <Icon name="arrow-up" />{t('common:menu.user.imports')}
              </NavLink>
              <NavLink to="/imports/reports" className="UserMenu__links__item">
                {inProgress > 0 && (
                  <ReportsCount count={inProgress} title={t('imports:file-status.file-processing')} />
                )}
                {inProgress === 0 && <Icon name="text-center" />}
                {t('common:menu.user.imports-reports')}
                <ReportsDetailsCounts data={finalized} />
              </NavLink>
            </UserRightsController>
            <FreshDesk />
          </>
        )}
        <hr className="UserMenu__links__divider" />
        <Button
          variant="list"
          onClick={handleLogout}
          className="UserMenu__links__item UserMenu__links__item--danger"
        >
          {t('common:menu.user.logout')}
        </Button>
      </div>
    </Dropdown>
  );
};

export default observer(UserMenu);
