import './index.scss';
import type { ReactNode, ReactElement, SyntheticEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import checkEmailFormat from 'utils/checkEmailFormat';
import authStore from 'stores/Auth';
import Alert from 'components/Alert';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Loading from 'components/Loading';
import ModalContext from './context';

export type ModalFormData = {
  [key: string]: FormDataEntryValue
};

type Props = {
  className?: string,
  title: string,
  subTitle?: string,
  isOpened: boolean,
  totalRecords?: string,
  icon?: ReactElement,
  onInit?(formData: ModalFormData | null): void,
  onChange?(formData: ModalFormData | null): void,
  hasWarning?: boolean,
  onSave(formData: ModalFormData | null): void,
  onEmailError?(): void,
  onCancel(): void,
  isFetched?: boolean,
  isLoading?: boolean,
  children: ReactNode,
  saveText?: DefaultTFuncReturn,
  hasDeleteButton?: boolean,
  onDelete?(): void,
  buttonsDisabled?: boolean,
  headerActions?: ReactElement,
  footerActions?: ReactElement,
  helpHeaderText?: DefaultTFuncReturn,
  helpHeaderLink?: { [key: string]: string },
};

const ModalForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isDemo } = authStore;
  const formRef = useRef<HTMLFormElement | null>(null);
  const [sourceView, setSourceView] = useState(false);

  const {
    className,
    title,
    subTitle,
    icon,
    isOpened,
    onInit,
    onChange,
    hasWarning = false,
    onSave,
    onEmailError,
    onCancel,
    isFetched,
    isLoading,
    children,
    saveText,
    totalRecords,
    hasDeleteButton = false,
    onDelete,
    buttonsDisabled = false,
    headerActions,
    footerActions,
    helpHeaderText,
    helpHeaderLink,
  } = props;

  const newClassName = classnames('ModalForm', className);

  const getFormData = useCallback(
    (): ModalFormData | null => {
      if (!formRef.current) {
        return null;
      }

      const formData = new FormData(formRef.current);
      const formEntries = Array.from(formData.entries());
      const formObject = formEntries.reduce((obj: any, [key, value]) => {
        // If the key already exists, append the new value with a comma, otherwise just set the value
        obj[key] = obj[key] ? `${obj[key]},${value}` : value;
        return obj;
      }, {});
      return formObject;
    },
    [],
  );

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isDemo) {
        return;
      }

      const data = getFormData();

      if (onEmailError && data && 'email' in data) {
        const { email } = data;
        if (typeof email === 'string' && email.length > 0 && !checkEmailFormat(email)) {
          onEmailError();
          return;
        }
      }

      onSave(data);
    },
    [isDemo, onEmailError, onSave, getFormData],
  );

  const handleChange = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.persist();
      e.preventDefault();
      if (onChange) {
        onChange(getFormData());
      }
    },
    [onChange, getFormData],
  );

  useEffect(() => {
    if (onInit) {
      onInit(getFormData());
    }
  }, [onInit, isFetched, getFormData]);

  return (
    <ModalContext.Provider value={{ sourceView, setSourceView }}>
      <Modal
        isOpened={isOpened}
        title={title}
        icon={icon}
        subTitle={subTitle}
        isLoading={isLoading}
        hasWarning={hasWarning}
        onClose={onCancel}
        totalRecords={totalRecords}
        className={newClassName}
        headerActions={headerActions}
        helpHeaderText={helpHeaderText}
        helpHeaderLink={helpHeaderLink}
      >
        {isDemo && (
          <Alert variant="warning">
            {t('common:demo.warning')} {t('common:demo.form-cannot-be-submitted')}
          </Alert>
        )}
        <form
          className="ModalForm__form"
          onSubmit={handleSubmit}
          ref={formRef}
          onChange={handleChange}
        >
          <div className="ModalForm__content">
            {children}
          </div>
          <div className="ModalForm__footer">
            {footerActions && (
              <div className="ModalForm__footer__extra-actions">
                {footerActions}
              </div>
            )}
            <div className="ModalForm__footer__actions">
              {!isLoading && isDemo && (
                <Alert variant="warning">
                  {t('common:demo.warning')} {t('common:demo.form-cannot-be-submitted')}
                </Alert>
              )}
              <Button variant="outline-black" onClick={onCancel}>{t('common:cancel')}</Button>
              {hasDeleteButton && (
                <Button variant="danger" disabled={buttonsDisabled} onClick={onDelete}>
                  {t('common:remove')}
                </Button>
              )}
              {sourceView ?
                <Button
                  variant='success'
                  onClick={(e) => {
                    e.preventDefault();
                    setSourceView(false);
                  }}
                >
                  {t('common:preview-html')}
                </Button> :
                <Button type="submit" disabled={isDemo || buttonsDisabled}>
                  {isLoading ? <Loading text={t('common:saving')} /> : (saveText || t('common:save'))}
                </Button>
              }
            </div>
          </div>
        </form>
      </Modal>
    </ModalContext.Provider>
  );
};

export default ModalForm;
