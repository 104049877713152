import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Badge from 'components/Badge';
import type { ImportItemStatus } from 'types/models';
import getI18nStatusImport from 'utils/getI18nStatusImport';

type Props = {
  status: ImportItemStatus,
};

const ImportStatus = ({ status }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="ImportStatus">
      <Badge variant={status}>
        {t(getI18nStatusImport(status))}
      </Badge>
    </div>
  );
};

export default observer(ImportStatus);
