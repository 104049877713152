import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { Contact, ManagerType } from 'types/models';
import { RightsCategoryType } from 'types/models';
import type ResponseError from 'utils/errors';
import Button from 'components/Button';
import ContactEditModal from 'components/ContactEditModal';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import { UserRightsManagerController } from 'components/UserRights';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import Icon from 'components/Icon';
import ListContactsItem from './Item';
import ListContactsView from './View';

type Props = {
  customerId: number,
  contacts: Contact[] | null | undefined,
  contactsCount: number,
  fetchContacts(): void,
  error: ResponseError | null,
  cancel?(): void,
  resetError?(): void,
  isLoading: boolean,
  onActionDone?(title: string, message: string): void,
  managers?: ManagerType[] | null,
};

const ListContacts = (props: Props): JSX.Element => {
  const {
    customerId,
    contacts,
    contactsCount,
    managers,
    fetchContacts,
    error,
    cancel,
    onActionDone,
    resetError,
    isLoading,
  } = props;
  const { t } = useTranslation();

  const [contactViewData, setContactViewData] = useState<Contact>();

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback(
    (message: string) => {
      showToast(message, 'success');
      if (fetchContacts) {
        fetchContacts();
      }
      if (onActionDone) {
        onActionDone(message, 'success');
      }
    },
    [showToast, fetchContacts, onActionDone],
  );

  const handleActionError = useCallback(
    (message: string) => {
      showToast(message, 'error');
    },
    [showToast],
  );

  useEffect(() => {
    if (fetchContacts) {
      fetchContacts();
    }
    return () => { if (cancel) { cancel(); } };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showModal, hideModal] = useModal(() => {
    return contactViewData ? (
      <ListContactsView
        contactId={contactViewData.id}
        onClose={hideModal}
        onActionDone={handleActionDone}
        onActionError={handleActionError}
      />
    ) : null;
  }, [contactViewData, handleActionDone, handleActionError]);

  const handleShowView = useCallback(
    (id: number) => () => {
      showModal();

      const foundContact = contacts?.find((contact) => contact.id === id);
      setContactViewData(foundContact);
    },
    [showModal, contacts],
  );

  const [showContactEditModal, hideContactEditModal] = useModal(() => (
    <ContactEditModal
      editType="new-contact"
      customerId={customerId}
      defaultCustomerId={customerId}
      onClose={hideContactEditModal}
      onDone={(message) => { hideContactEditModal(); handleActionDone(message); }}
    />
  ), [customerId, handleActionDone]);

  return (
    <div className="ListContacts">
      <div className="ListContacts__header">
        <span className="ListContacts__title">
          {t('contacts:contacts')}
        </span>
        <span className="ListContacts__contacts-count">
          {contactsCount ?? (!!contacts && contacts.length)}
        </span>
        {!!contacts && contacts.length > 0 && (
          <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
            <UserRightsManagerController action="CREATE" categoryType={RightsCategoryType.CONTACTS} managers={managers}>
              <Button
                className="ListContacts__link-manage"
                variant="link"
                onClick={showContactEditModal}
              >
                {t('common:create')}
              </Button>
            </UserRightsManagerController>
          </ExternallyMonitoredController>
        )}
        {(contactsCount > 5) && (
          <Link
            to={`/contacts?client=${customerId}`}
            className="ListContacts__link-manage"
          >
            {t('common:all')} <Icon name="chevron-right-small" />
          </Link>
        )}
      </div>
      {error && <ErrorMessage error={error} onClose={resetError} />}
      {isLoading && <Loading hasNoText />}
      {contacts && contacts.length > 0 && (
        <ul className="ListContacts__list">
          {contacts.map((contact) => (
            <ListContactsItem
              key={contact.id}
              data={contact}
              onShowView={handleShowView(contact.id)}
            />
          ))}
        </ul>
      )}
      {!isLoading && contacts && contacts.length === 0 && (
        <div className="ListContacts__add-contact">
          <p className="ListContacts__add-contact__text">
            {t('contacts:no-data-customer')}
          </p>
          <UserRightsManagerController action="CREATE" categoryType={RightsCategoryType.CONTACTS} managers={managers}>
            <Link
              to={`/customer/${customerId}/infos-contacts?dialog=new-contact`}
              className="ListContacts__add-contact__button"
            >
              <Icon name="user" />{t('contacts:add')}
            </Link>
          </UserRightsManagerController>
        </div>
      )}
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(ListContacts);
