import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Loading from 'components/Loading';

const LoadingOrRedirect = (): JSX.Element => {
  const [isRedirect, setIsRedirect] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsRedirect(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (isRedirect) {
    return <Redirect to="/imports/reports" />;
  }

  return <Loading />;
};

export default LoadingOrRedirect;
