import './index.scss';
import { Link } from 'react-router-dom';
import type { StepAction } from 'types/models';
import ListItemButton from 'components/ListItemButton';
import StepActionIcon from 'components/StepActionIcon';

type Props = {
  data: StepAction,
  onShowView(): void,
};

const HomeMyActionsItem = ({ data, onShowView }: Props): JSX.Element => {
  const { name, debit, client, channel, entity } = data;
  const { id: clientId, identifier, denomination } = client;

  return (
    <ListItemButton onShowView={onShowView}>
      <div className="HomeMyActionsItem__header">
        <StepActionIcon entity={entity} channel={channel} />
        <div className="HomeMyActionsItem__name">
          {name}
          <div className="HomeMyActionsItem__pay-request">
            {!!debit && (
              <Link
                to={`/debit/${debit.id}`}
                className="HomeMyActionsItem__pay-request__link"
              >
                {debit.identifier},
              </Link>
            )}
            <Link
              to={`/customer/${clientId}`}
              className="HomeMyActionsItem__pay-request__client"
              onClick={(e) => { e.stopPropagation(); }}
            >
              {denomination}{identifier !== denomination ? ` (${identifier})` : ''}
            </Link>
          </div>
        </div>
      </div>
    </ListItemButton>
  );
};

export default HomeMyActionsItem;
