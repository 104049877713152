import './index.scss';
import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import customerContext from '../customerContext';
import CustomerMainMenu from './Menu';
import CustomerSummary from './Summary';
import CustomerHistory from './History';
import CustomerInfosContacts from './InfosContacts';

const CustomerMain = (): JSX.Element | null => {
  const { data } = useContext(customerContext);
  if (!data) {
    return null;
  }

  const basePath = `/customer/${data.id}`;

  return (
    <div className="CustomerMain">
      <CustomerMainMenu basePath={basePath} />
      <div className="CustomerMain__content">
        <Switch>
          <Route exact path={basePath}>
            <Redirect to={`${basePath}/summary`} />
          </Route>
          <Route path={`${basePath}/summary`}>
            <CustomerSummary />
          </Route>
          <Route path={`${basePath}/history`}>
            <CustomerHistory />
          </Route>
          <Route path={`${basePath}/infos-contacts`}>
            <CustomerInfosContacts />
          </Route>
          <Route path={`${basePath}/*`}>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default CustomerMain;
