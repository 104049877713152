import './index.scss';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import apiContacts from 'api/contacts';
import type { Contact } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';

type Props = {
  selection?: Contact['id'][],
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
};
const ContactsBulkActions = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { selection, onActionDone, onActionError } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { remove, error, isLoading } = useApiRequest();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(
      t('common:remove'),
      t('contacts:actions.confirm.bulk-remove', { count: selection?.length }),
    );
  }, [showConfirm, t, selection]);

  const handleSubmitDelete = useCallback(async () => {
    if (!selection?.length) {
      return;
    }

    const response = await remove(apiContacts.bulkDeleteUrl(selection));

    hideConfirm();
    if (response) {
      onActionDone(
        t('contacts:actions.toast.bulk-deleted', { count: selection?.length }),
      );
    }
  }, [selection, remove, hideConfirm, onActionDone, t]);

  useEffect(() => {
    if (error) {
      hideConfirm();
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (!selection?.length) {
    return null;
  }

  return (
    <div className="ContactsBulkActions">
      {isLoading && <Loading text={t('common:saving')} isOverlay />}
      <Button
        variant="outline-light"
        onClick={handleConfirmDelete}
        className="ContactsBulkActions__delete"
        disabled={isLoading}
      >
        {t('common:remove')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default ContactsBulkActions;
