import type { TFunction } from 'i18next';
import { DebitAction } from 'types/models';

const getToggleActionsI18n = (t: TFunction, ct: TFunction, reference: string) => ({
  [DebitAction.MUTE]: {
    title: t('debits:actions.mute'),
    confirmText: ct('debits:actions.confirm.mute', { reference }),
    toastText: ct('debits:actions.toast.mute', { reference }),
  },
  [DebitAction.UNMUTE]: {
    title: t('debits:actions.unmute'),
    confirmText: ct('debits:actions.confirm.unmute', { reference }),
    toastText: ct('debits:actions.toast.unmute', { reference }),
  },
  [DebitAction.REMOVE]: {
    title: t('debits:remove'),
    confirmText: ct('debits:actions.confirm.remove', { reference }),
    toastText: ct('debits:actions.toast.remove', { reference }),
  },
  [DebitAction.LITIGATE]: {
    title: t('debits:set-litigate'),
    confirmText: ct('debits:actions.confirm.set-litigate', { reference }),
    toastText: ct('debits:actions.toast.set-litigate', { reference }),
  },
  [DebitAction.CANCEL_LITIGATE]: {
    title: t('debits:cancel-litigate'),
    confirmText: ct('debits:actions.confirm.cancel-litigate', { reference }),
    toastText: ct('debits:actions.toast.cancel-litigate', { reference }),
  },
  [DebitAction.DISABLE]: {
    title: t('debits:disable'),
    confirmText: ct('debits:actions.confirm.disable', { reference }),
    toastText: ct('debits:actions.toast.disable', { reference }),
  },
  [DebitAction.ACTIVATE]: {
    title: t('debits:reactivate'),
    confirmText: ct('debits:actions.confirm.reactivate', { reference }),
    toastText: ct('debits:actions.toast.reactivate', { reference }),
  },
});

export default getToggleActionsI18n;
