import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import Misc from 'types/misc';
import FormEavItem from 'components/FormEavs/Item';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';

type Props = {
  filter: Misc.FilterDeclarationItem,
  defaultValue: string | string[] | null,
  value?: string | string[] | null,
  onChange(name: string, value: string | null): void,
  onItemFirstOption?(firstOption?: string | null | number): void,
};

const FilterAttributes = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { filter, defaultValue, onChange, onItemFirstOption } = props;
  const { client: eavs } = organizationStore.attributes;
  const [name, setName] = useState<string | null>();
  const [attribute, setAttribute] = useState<string | null>();

  useEffect(() => {
    if (onItemFirstOption) {
      onItemFirstOption(eavs.length > 0 ? `${eavs[0].identifier}:` : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eavs]);

  useEffect(() => {
    if (defaultValue) {
      const [defaultName, defaultAttribute] = (defaultValue as string).split(':');
      setName(defaultName ?? null);
      setAttribute(defaultAttribute !== '' ? defaultAttribute : null);
    }
  }, [defaultValue]);

  const handleChangeName = useCallback((_name: string, newName: string[] | string | null) => {
    onChange(filter.key, `${newName}:`);
    setName(newName as string);
    setAttribute(null);
  }, [onChange, filter.key]);

  const handleAttributeChange = useCallback((newAttribute: string) => {
    onChange(filter.key, `${name ?? ''}:${newAttribute}`);
    setAttribute(newAttribute);
  }, [name, onChange, filter.key]);

  const eav = useMemo(() => {
    return eavs.find(({ identifier }) => identifier === name);
  }, [eavs, name]);

  return (
    <Fragment>
      <FormSelect
        name={filter.key}
        className="FormEavItem__key"
        defaultValue={name}
        onSelect={handleChangeName}
        placeholder={filter.emptyText}
        withClearButton={false}
        selectOptions={eavs.map(({ label, identifier }) => ({
          label,
          value: identifier,
        }))}
      />
      {eav ?
        <FormEavItem
          eav={eav}
          label={t('clients:filters.attribute-value') as string}
          onChange={handleAttributeChange}
          defaultValue={attribute}
        /> :
        <FormControl
          type="text"
          readOnly
        />
      }
    </Fragment>
  );
};

export default FilterAttributes;
