import './index.scss';
import { useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import type { User } from 'types/models';
import type { FetchAllParams } from 'api/collaborator';
import type { Collection } from 'types/api';
import apiCollaborators from 'api/collaborator';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch2';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import DataTable from 'components/DataTable';
import CollaboratorsColumns from './Columns';

type Props = {
  onShowToast: (message: string, status: ToastStatus) => void,
  reloadTick: number,
};

const CollaboratorsTable = ({ onShowToast, reloadTick }: Props):JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    data,
    isFetching,
    refetch,
  } = useFetch<FetchAllParams, Collection<User>>(
    {
      cacheKey: 'organizationCollaborators',
      organization: currentOrganization?.reference,
    },
    apiCollaborators.all,
    { enabled: !!currentOrganization },
  );

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const onActionDone = useCallback((message: string) => {
    refetch();
    organizationStore.refresh();
    onShowToast(message, 'success');
  }, [refetch, onShowToast]);

  const columns = useMemo<Column<User>[]>(
    () => CollaboratorsColumns(
      t, onActionDone,
      (message: string) => onShowToast(message, 'error'),
    ),
    [t, onActionDone, onShowToast],
  );

  if (!currentOrganization) {
    return null;
  }

  return (
    <div className="CollaboratorsTable">
      {(!data || data['hydra:totalItems'] === 0) && (
        <p className="CollaboratorsTable__no-collaborator">
          {t('collaborators:no-collaborator')}
        </p>
      )}
      {(data && data['hydra:totalItems'] > 0) && (
        <DataTable<User>
          columns={columns}
          data={data['hydra:member']}
          serverPagination={null}
          defaultSortBy={{ id: 'id', desc: false }}
          fetchData={() => { }}
          isLoading={isFetching}
          error={null}
          noHeader
          withActions={false}
        />
      )}
    </div>
  );
};

export default observer(CollaboratorsTable);
