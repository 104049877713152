import './index.scss';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import type { ValidationErrorItem, ValidationErrors } from 'types/errors';
import type { Currency, Debit, Imputation } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Icon from 'components/Icon';
import SelectDebitForCustomer from 'components/SelectDebitForCustomer';
import FormAmountCurrency from 'components/FormAmountCurrency';

type Props = {
  errors?: ValidationErrors | null,
  index: number,
  defaultValue: Imputation,
  amount: string | number | null,
  allDebits?: Debit[],
  currentCurrency?: Currency,
  onChangeDebit(newValue: string, type: string, index: number): void,
  onRemoveDebit(refOrindex: string | number): void,
};

const ImputationItem = (props: Props): JSX.Element => {
  const {
    index,
    errors,
    defaultValue,
    amount,
    allDebits,
    currentCurrency,
    onChangeDebit,
    onRemoveDebit,
  } = props;
  const { t } = useTranslation();
  const [ error, setError ] = useState<ValidationErrorItem | null>();

  const imputation = useMemo(() => (
    defaultValue && allDebits?.find(({ reference }) => (
      reference === (defaultValue.reference || defaultValue.debitIdentifier)
    )) || null
  ), [defaultValue, allDebits]);

  return (
    <div className="ImputationItem">
      <Button
        className="ImputationItem__remove"
        variant="link"
        onClick={() => onRemoveDebit(defaultValue.reference || defaultValue.debitIdentifier || index)}
      >
        <Icon name="minus-circle" />
      </Button>
      <FormFieldset>
        <FormGroup label={t('common:bill')} mandatory error={errors?.debit}>
          {allDebits ? (
            <SelectDebitForCustomer
              name={`debits-reference-${index}`}
              defaultValue={defaultValue.reference || defaultValue.debitIdentifier || ''}
              onChange={(newReference: string) => {
                onChangeDebit(newReference, 'reference', index);
              }}
              isInvalid={!!errors?.debit}
              defaultOptions={allDebits.map(
                ({ reference }) => ({ name: reference, value: reference }),
              )}
            />
          ) : (
            <div className="ImputationItem__wrapper">
              <div className="ImputationItem__wrapper__form">
                <FormControl
                  type="text"
                  name={`debits-reference-${index}`}
                  autoComplete="off"
                  defaultValue={defaultValue.reference || defaultValue.debitIdentifier || ''}
                  onChange={(newValue) => {
                    onChangeDebit(newValue, 'reference', index);
                  }}
                  isInvalid={!!errors?.debit}
                />
              </div>
            </div>
          )}
        </FormGroup>
        <FormAmountCurrency
          amountFormName={`debits-amountAssigned-${index}`}
          defaultAmount={defaultValue.paymentAssignedAmount ?? ''}
          amountError={error}
          onChangeAmount={(newValue) => {
            onChangeDebit(newValue, 'amount', index);
            setError( imputation && Number.parseFloat(newValue) > Math.abs(imputation.amount) ?
              { code: -1, message: t('credits:errors.greater-than-debit') } : null,
            );
          }}
          currency={currentCurrency?.code}
          hasCurrencyDisabled
          amount={amount || ''}
        />
      </FormFieldset>
    </div>
  );
};

export default ImputationItem;
