import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import NoOrganizationMessage from 'components/NoOrganizationMessage';
import BackOfficeSection from '../Section';

const MyOrganization: React.FC = () => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  if (!currentOrganization) {
    return <NoOrganizationMessage />;
  }

  return (
    <div className="MyOrganization">
      <BackOfficeSection title={t('users:nav.title.my-organization')}>
        {t('common:reference')}: {currentOrganization.reference}
      </BackOfficeSection>
    </div>
  );
};

export default observer(MyOrganization);
