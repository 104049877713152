import './index.scss';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Client, ScenarioGroup } from 'types/models';
import type { FetchAllGroupsParams } from 'api/scenarioGroup';
import useFetch from 'hooks/useFetch2';
import apiClients, { FetchOneParams } from 'api/clients';
import apiScenarioGroup from 'api/scenarioGroup';
import organizationStore from 'stores/Organization';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';

interface Props {
  defaultValue?: ScenarioGroup['id'],
  customerId?: Client['id'] | null,
}

const ScenarioGroupDropdown = ({ defaultValue, customerId }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { id } = organizationStore.currentOrganization!;
  const [currentGroup, setCurrentGroup] = useState<ScenarioGroup['id'] | undefined>(defaultValue);

  const { data: scenarioGroups } = useFetch<FetchAllGroupsParams, ScenarioGroup[]>(
    { cacheKey: 'scenarioGroups', organizationId: id },
    apiScenarioGroup.all,
    { enabled: !!id },
  );

  /* FIXME: Créer un point d'accès qui récupère les groupes par ID
    d'utilisateur au lieu de faire deux requêtes à l'API */
  const { data: customer } = useFetch<FetchOneParams, Client>(
    { cacheKey: 'client', id: customerId! },
    apiClients.one,
    { enabled: !!customerId },
  );

  const groupOptions = useMemo(() => [
    { name: t('selectors:debt'), id: 0 },
    /* Filtre les groupes de scénarios pour n'inclure que ceux
      qui sont pertinents pour les scénarios du client */
    ...(scenarioGroups ?? []).filter((item) =>
      (customer?.scenario ?? []).some((scenarios) =>
        scenarios.some((scenario) =>
          (item.scenarios ?? []).some((itemScenario) => itemScenario.id === scenario.id),
        ),
      ),
    ),
  ], [scenarioGroups, customer?.scenario, t]);

  if (groupOptions.length <= 1) {
    return null;
  }

  return (
    <FormGroup
      className="ScenarioGroupDropdown"
      label={t('reminders:group')}
    >
      <Dropdown
        chevronIcon
        variant="link"
        className="ScenarioGroupDropdown__list"
        toggle={scenarioGroups?.find(({ id: groupId }) => (
          groupId === currentGroup))?.name ?? t('selectors:debt')
        }
      >
        {groupOptions.map(({ id: groupId, name: label }) => (
          <Button
            variant="list"
            key={`group-${groupId}`}
            className={classnames('ScenarioGroupDropdown__list__item', {
              'ScenarioGroupDropdown__list__item--active': currentGroup === groupId,
            })}
            onClick={() => { setCurrentGroup(groupId); }}
          >
            {label}
          </Button>
        ))}
      </Dropdown>
      <input type="hidden" name="group" value={currentGroup ?? ''} />
    </FormGroup>
  );
};

export default observer(ScenarioGroupDropdown);
