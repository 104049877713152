import './index.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import classnames from 'classnames';
import type { ModalFormData } from 'components/ModalForm';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ErrorMessage from 'components/ErrorMessage';
import Button from 'components/Button';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';
import apiClients from 'api/clients';
import { DebitAction, UnmutedScenario } from 'types/models';
import type { Client } from 'types/models';
import type { CustomerMuteButtonProps } from '../index';
import CustomerUnMuteForm from './Form';

const UnMuteButton = (props: CustomerMuteButtonProps): JSX.Element => {
  const { customerData, onActionDone, showText = false } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { put, error } = useApiRequest();

  const [showModal, hideModal] = useModal(() => {
    const handleSubmit = async (formData: ModalFormData | null) => {
      if (!formData) {
        return;
      }

      const data = {
        status: DebitAction.UNMUTE,
        deferredStepActions : formData.scenario === UnmutedScenario.MOVE,
      };
      const result = await put<Client>(apiClients.updateStatusUrl(customerData.id), data);
      if (!result || !isMountedRef.current) {
        return;
      }

      if (onActionDone) {
        onActionDone(t('clients:actions.toast.un-muted-success', { reference: customerData.identifier }), '');
        hideModal();
      }
    };

    return (
      <ModalForm
        isOpened
        title={t('clients:un-mute')}
        onSave={handleSubmit}
        onCancel={hideModal}
      >
        {error && <ErrorMessage error={error} />}
        <CustomerUnMuteForm
          text={t('clients:actions.confirm.un-mute', { reference: customerData.identifier })}
          lastMutedAt={customerData.lastMutedAt?.date} />
      </ModalForm>
    );
  }, [customerData, error, isMountedRef, put, onActionDone, t]);

  const classNames = classnames('CustomerMuteButtonUnMute', {
    'CustomerMuteButtonUnMute--with-text': showText,
  });

  return (
    <Fragment>
      <Button
        className={classNames}
        variant="list"
        title={showText ? '' : t('clients:un-mute')}
        onClick={showModal}
      >
        <Icon name="bell-muted" className="CustomerMuteButtonUnMute__icon" />
        {showText && t('clients:un-mute')}
      </Button>
    </Fragment>
  );
};

export default UnMuteButton;
