import { useTranslation } from 'react-i18next';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import SettingsSection from '../../Section';
import InvitedTable from './InvitedTable';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
  reloadTick: number
};

const SettingsCollaboratorsInvited = ({ onShowToast, reloadTick }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <SettingsSection title={t('collaborators:collaborators-invited')}>
      <InvitedTable onShowToast={onShowToast} reloadTick={reloadTick} />
    </SettingsSection>
  );
};

export default SettingsCollaboratorsInvited;
