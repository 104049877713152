import type { Collection } from 'types/api';
import type { Locale } from 'types/models';
import requester from 'utils/requester';

const getAll = async (): Promise<Locale[]> => {
  const { data } = await requester.get<Collection<Locale>>('locales?activated=1');
  return data['hydra:member'];
};

/**
 * Récupère l'URL de la ressource d'API pour mapper une association de locale.
 *
 * @param id L'ID de l'organization
 * @returns URL de l'association.
 */
const resourceUrl = (id: Locale['id']) => `/api/locales/${id}`;

export default { getAll, resourceUrl };
