import './index.scss';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownActions from 'components/DropdownActions';
import DownloadLink from 'components/DownloadLink';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import RowActionPromiseToPay from './PromiseToPay';

interface Props {
  id: number,
  reference: string,
  downloadPath?: string,
  onActionDone: (message: string) => void,
}

const PayerInvoicesRowActions = (props: Props): JSX.Element => {
  const { id, reference, downloadPath, onActionDone } = props;
  const { t } = useTranslation();
  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(false);

  const otherActions = useMemo(() => {
    const baseActions = [
      <RowActionPromiseToPay
        id={id}
        reference={reference}
        onLoadingChange={setIsDropdownLoading}
        onDone={onActionDone}
      />,
    ];

    if (!downloadPath) {
      return baseActions;
    }

    return [
      ...baseActions,
      <DownloadLink path={downloadPath} withAuthentication={false}>
        <ActionButton
          title={t('debits:download-receipt')}
          onClick={() => { }}
          icon={<Icon name="arrow-down" />}
        />
      </DownloadLink>,
    ];
  }, [downloadPath, id, onActionDone, reference, t]);

  return (
    <div className="PayerInvoicesRowActions">
      <DropdownActions
        isLoading={isDropdownLoading}
        actions={otherActions}
      />
    </div>
  );
};

export default PayerInvoicesRowActions;
