import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import Sending from './Sending';
import Roles from './Roles';
import CreditsImputation from './CreditsImputation';

const SettingsCustomization = (): JSX.Element => {
  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  return (
    <>
      <Sending onShowToast={showToast} />
      <Roles onShowToast={showToast} />
      <CreditsImputation onShowToast={showToast} />
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </>
  );
};

export default SettingsCustomization;
