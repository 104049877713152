import './index.scss';
import { useMemo } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { ValidationErrorItem } from 'types/errors';

export type ErrorValidationMessageType = ValidationErrorItem | string | undefined | null;

type Props = {
  error: ErrorValidationMessageType,
  fieldName?: DefaultTFuncReturn,
};

const ERRORS_CODES = {
  EMPTY: 0,
  ERROR: 1,
  MISSING: 2,
  DUPLICATED: 3,
  CREATED: 4,
  UPDATED: 5,
  DELETED: 6,
  OK: 7,
};

const ErrorValidationMessage = ({ error, fieldName }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const text = useMemo(() => {
    if (!error) {
      return null;
    }

    if (typeof error === 'string') {
      return error;
    }

    const { code, message } = error;

    switch (code) {
      case ERRORS_CODES.MISSING:
        return t('errors:validation.required-field');
      case ERRORS_CODES.ERROR:
        return t('errors:validation.value-not-valid');
      case ERRORS_CODES.EMPTY:
        return t('errors:validation.value-does-not-exist');
      case ERRORS_CODES.DUPLICATED:
        return t('errors:validation.resource-already-exists');
      default:
        return message;
    }
  }, [t, error]);

  if (!text) {
    return null;
  }

  return (
    <div className="ErrorValidationMessage">
      {fieldName !== undefined && `${fieldName}: `}{text}
    </div>
  );
};

export default ErrorValidationMessage;
