import type Misc from 'types/misc';

const getI18nCreditAttribution = (
  creditAttribution: Misc.OrganizationPaymentAttribution,
): string => {
  if (creditAttribution === 'AUTOMATIC_NEWER') {
    return 'customization:payment-attributions.automatic-newer';
  } if (creditAttribution === 'AUTOMATIC_OLDER') {
    return 'customization:payment-attributions.automatic-older';
  }
  return 'customization:payment-attributions.manual';
};

export default getI18nCreditAttribution;
