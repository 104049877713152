import { EavCategory } from 'types/models';

const getEavCategoryTitleLabel = (eavCategory: EavCategory) => {
  const eavCategoryTable = {
    [EavCategory.CLIENT]: 'attributes:clients-attributes',
    [EavCategory.CONTACT]: 'attributes:contacts-attributes',
    [EavCategory.DEBIT]: 'attributes:debits-attributes',
    [EavCategory.CREDIT]: 'attributes:credits-attributes',
  };

  return eavCategoryTable[eavCategory];
};

export default getEavCategoryTitleLabel;
