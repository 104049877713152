import type Misc from 'types/misc';
import type { ImportNotifications } from './Notifications';
import type { EavConstructor } from './EAV';
import type { CurrencyCode } from './Currency';

export type OrganizationStats = {
  countUnpaidDebit: number,
  countUnpaidDebitFuture: number,
  countUnpaidDebitPeriods: {
    inf90: number,
    inf100: number,
    inf3010: number,
    inf6030: number,
    inf9060: number,
    sup010: number,
    sup30: number,
    sup1030: number,
  },
  countUnpaidDebitRecent: number,
  countUnpaidDebitSup30: number,
  dso: {
    total: number,
    evolution: number,
  },
  ratioUnpaidDebitPeriods: {
    inf90: number,
    inf100: number,
    inf3010: number,
    inf6030: number,
    inf9060: number,
    sup010: number,
    sup30: number,
    sup1030: number,
  },
  sumUnpaidDebit: number,
  sumUnpaidDebitFuture: number,
  sumUnpaidDebitFutureRatio: number,
  sumUnpaidDebitPeriods: {
    inf90: number,
    inf100: number,
    inf3010: number,
    inf6030: number,
    inf9060: number,
    sup010: number,
    sup30: number,
    sup1030: number,
  },
  sumUnpaidDebitRecent: number,
  sumUnpaidDebitRecentRatio: number,
  sumUnpaidDebitSup30: number,
  sumUnpaidDebitSup30Ratio: number,
  totalRecovered: Misc.TotalRecoveredByYear[],
  unpaidRate: number,
  totalClients: number,
  totalActiveClients: number,
  totalInProgressDebits: number,
  totalPaidDebits: number,
  totalDebits: number,
};

export enum Analytics {
  SMS = 'sms',
  EMAILS = 'emails',
  LETTERS = 'letters',
  REGISTERED_LETTERS = 'registered_letters',
  AUTOMATIC_REMINDERS = 'automatic_reminders',
  VALIDATION_REMINDERS = 'validation_reminders',
  PLANNED_TASKS = 'planned_tasks',
  VALIDATED_TASKS = 'validated_tasks',
  CANCELLED_TASKS = 'cancelled_tasks',
  PENDING_TASKS = 'pending_tasks',
  AVERAGE_DEBT = 'average_debt',
  BT_1_AD3 = 'bt_1_ad3',
  BT_AD3_AD2 = 'bt_ad3_ad2',
  BT_AD2_AD = 'bt_ad2_ad',
  BT_AD_ADX2 = 'bt_ad_adx2',
  SUP_ADX2 = 'sup_adx2',
  NON_ECHUE_OVER_30D = 'non_echue_over_30d',
  NON_ECHUE_UNDER_30D = 'non_echue_under_30d',
  ECHUE_UNDER_30D = 'echue_under_30d',
  ECHUE_30_60D = 'echue_30_60d',
  ECHUE_60_90D = 'echue_60_90d',
  ECHUE_90_120D = 'echue_90_120d',
  ECHUE_OVER_120D = 'echue_over_120d',
  RECOVERY_RATE = 'recoveryRate',
  MONTANT = 'montant',
  CLIENT = 'client',
  DEBIT = 'debit',
  DAYS = 'days',
}

export enum AnalyticsType {
  RISK = 'risk',
  ACTIVITY = 'activity',
  FINANCE = 'finance',
}

export type OrganizationAnalytics = {
  reminders: {
    date: string;
    [Analytics.SMS]: number;
    [Analytics.EMAILS]: number;
    [Analytics.LETTERS]: number;
    [Analytics.REGISTERED_LETTERS]: number;
  }[];
  tasks: {
    date: string;
    [Analytics.AUTOMATIC_REMINDERS]: number;
    [Analytics.VALIDATION_REMINDERS]: number;
    [Analytics.PLANNED_TASKS]: number;
    [Analytics.VALIDATED_TASKS]: number;
    [Analytics.CANCELLED_TASKS]: number;
    [Analytics.PENDING_TASKS]: number;
  }[];
  debtDistribution: {
    [Analytics.AVERAGE_DEBT]?: number;
    [Analytics.BT_1_AD3]: number;
    [Analytics.BT_AD3_AD2]: number;
    [Analytics.BT_AD2_AD]: number;
    [Analytics.BT_AD_ADX2]: number;
    [Analytics.SUP_ADX2]: number;
  };
  paymentDelay: { date: string; days: number }[];
  recoveryRate: { date: string; value: number }[];
  agedBalance:{
    [Analytics.NON_ECHUE_OVER_30D]: BalanceDetails;
    [Analytics.NON_ECHUE_UNDER_30D]: BalanceDetails;
    [Analytics.ECHUE_UNDER_30D]: BalanceDetails;
    [Analytics.ECHUE_30_60D]: BalanceDetails;
    [Analytics.ECHUE_60_90D]: BalanceDetails;
    [Analytics.ECHUE_90_120D]: BalanceDetails;
    [Analytics.ECHUE_OVER_120D]: BalanceDetails;
  }
};

export type BalanceDetails = {
  [Analytics.CLIENT]: number;
  [Analytics.DEBIT]: number;
  [Analytics.MONTANT]: number;
};

export type OrganizationNotifications = {
  imports: ImportNotifications,
};

export type OrganizationEavs = {
  client: EavConstructor[],
  debit: EavConstructor[],
};

type OrganizationReferent = {
  id: number,
  identifier: string,
  name: string,
  phone: string,
  email: string,
};

export enum OrganizationType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum OrganizationSendingMode {
  MANUAL = 'manual',
  AUTO = 'auto',
}

type PublicHolidayName = { text: string };

type PublicHoliday = {
  startDate: string,
  name: PublicHolidayName[],
};

export type OrganizationPeriodicTasks = {
  id?: number,
  mode?: OrganizationSendingMode,
  scheduledTimeAtHolidays?: string | null,
  timezone?: string,
  schedule?: {
    reminders: Record<string, string>,
  },
  lastExecutedAt?: string,
  holidays?: {
    publicHolidays: PublicHoliday[],
  },
};

export type Organization = {
  id: number,
  reference: string,
  type: OrganizationType,
  corporateName: string | null,
  legalId: number | null,
  sendingEmail: string | null,
  address1: string | null,
  address2: string | null,
  address3: string | null,
  postalCode: string | null,
  city: string,
  country: Misc.Country | null,
  currency: CurrencyCode,
  logo: string | null,
  lastIndexedAt: string | null,
  taskNotifyByEmail: boolean,
  linesOfBusiness: Misc.LinesOfBusiness | null,
  contactRoles: Misc.IdIdentifierValue[] | null,
  periodicTasks: OrganizationPeriodicTasks | null,
  letterHead: string | null,
  signature: string | null,
  signatureEmail: string | null,
  signatureLetter: string | null,
  sendViewPayer: boolean | null,
  referent: OrganizationReferent | null,
  isInProduction: boolean,
  isEnabled: boolean,
  paymentAttribution: Misc.OrganizationPaymentAttribution,
  eavs: OrganizationEavs | null,
  tags: Misc.Identifier[] | null,
};

export type OrganizationSummary = {
  id: Organization['id'],
  reference: Organization['reference'],
  type: Organization['type'],
  corporateName: Organization['corporateName'],
  legalId: Organization['legalId'],
  logo: Organization['logo'],
  isInProduction: Organization['isInProduction'],
  currency: Organization['currency'],
  address1: Organization['address1'],
  address2: Organization['address2'],
  address3: Organization['address3'],
  postalCode: Organization['postalCode'],
  city: Organization['city'],
  country: Organization['country'],
  sendingEmail: Organization['sendingEmail'],
  letterHead: Organization['letterHead'],
  signature: Organization['signature'],
  signatureEmail: Organization['signatureEmail'],
  signatureLetter: Organization['signatureLetter'],
  sendViewPayer: Organization['sendViewPayer'],
  referent: Organization['referent'],
};

export type OrganizationStripe = {
  organizationId: number,
  sourceId: string | null,
  sourceOwnerEmail: string | null,
  clientSecret: string | null,
  customerId: string | null,
};

export type OrganizationMinimal = {
  id: Organization['id'],
  identifier: Organization['reference'],
  label: Organization['corporateName'],
};

export type HealthCheckResponse = {
  status: 'FAILED' | 'IN_PROGRESS' | 'COMPLETED';
  healthCheckTime: Misc.DateTimezone;
};

export type JobExecution = {
  id: number;
  status: number;
  start_time: string;
};
