import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import sanitizeHtmlConfig from 'config/sanitizeHtml';
import { Channel } from 'types/models';
import type { ScenarioStepPreviewContent } from 'types/models';
import type { FetchPreviewParams } from 'api/scenarios';
import apiScenarios from 'api/scenarios';
import Modal from 'components/Modal';
import useFetch from 'hooks/useFetch2';
import PDFViewer from 'components/PDFViewer';
import Loading from 'components/Loading';
import CountCharactersHelper from 'components/CountCharactersHelper';

type Props = {
  scenarioId: number,
  channel: Channel,
  content?: string | null,
  onClose(): void,
};

const ScenarioStepContentPreview = ({ onClose, channel, scenarioId, content }: Props): JSX.Element => {
  const { t } = useTranslation();

  const { data, isLoading } = useFetch<FetchPreviewParams, ScenarioStepPreviewContent>(
    {
      cacheKey: 'previewScenarioContent',
      scenarioId,
      content,
      channel,
    },
    apiScenarios.fetchPreviewContent,
  );

  const sanitizedData = useMemo(() => (
    (data && data.content) ? sanitizeHtml(data.content, sanitizeHtmlConfig) : ''
  ), [data]);

  return (
    <Modal
      isOpened
      className="ScenarioStepContentPreview"
      title={`${t('common:preview')}`}
      onClose={onClose}
    >
      <p className="ScenarioStepContentPreview__warning">
        {t('reminders:warning-missing-parsing')}
      </p>
      {isLoading && <Loading />}
      {!isLoading && (channel === Channel.EMAIL || channel === Channel.SMS) && (
        <div
          className="ScenarioStepContentPreview__email-sms"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizedData }}
        />
      )}
      {!isLoading && (channel === Channel.LETTER_REGISTERED || channel === Channel.LETTER) && data && data.content && (
        <PDFViewer base64PDF={data.content} scale={1.1} />
      )}
      {!isLoading && channel === Channel.SMS && data && (
        <CountCharactersHelper
          number={data.content?.length ?? 0}
          className="ScenarioStepContentPreview__helper"
        />
      )}
    </Modal>
  );
};

export default observer(ScenarioStepContentPreview);
