import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import Button from 'components/Button';
import Icon from 'components/Icon';

export type CommonProps = {
  name: string,
  onSelect?(name: string, newValue: string | null): void,
  withId?: boolean,
  disabled?: boolean,
  placeholder?: DefaultTFuncReturn,
  value?: string | string[] | null,
  className?: string,
  isInvalid?: boolean,
  withClearButton?: boolean,
};

type Props = CommonProps & {
  options: SelectSearchOption[],
  valueToSelect: string,
  getOptions: (query: string) => Promise<SelectSearchOption[]>,
};

const FormSelectCustomerShell = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    name,
    onSelect,
    placeholder = t<string>('common:search-in-select'),
    options,
    getOptions,
    value: controledValue,
    className,
    disabled = false,
    isInvalid = false,
    withClearButton = true,
    valueToSelect,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const previousControledValue = useRef<string | string[]>(controledValue || '');

  const [currentValue, setCurrentValue] = useState<string | string[]>('');
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const handleChange = useCallback((selectedValue: string | string[]) => {
    setCurrentValue(selectedValue);

    if (!inputRef?.current) {
      return;
    }

    const newValue = Array.isArray(selectedValue) ? selectedValue.join(',') : selectedValue;
    // - Ceci permet de déclencher un onChange au niveau du <form> parent
    const inputValSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    )?.set;
    inputValSetter?.call(inputRef.current, newValue);
    inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  }, []);

  useEffect(() => {
    if (valueToSelect) {
      handleChange(valueToSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueToSelect]);

  useEffect(() => {
    if (onSelect) {
      const selectedValue = currentValue === '' ? null : currentValue;
      onSelect(name, Array.isArray(selectedValue) ? selectedValue.join(',') : selectedValue);
    }
  }, [name, currentValue, onSelect]);

  useDebouncedEffect(() => {
    if (controledValue === undefined || previousControledValue.current === controledValue) {
      return;
    }

    previousControledValue.current = controledValue || '';
    handleChange(controledValue === null ? '' : controledValue);
  }, 300, [controledValue, options]);

  const handleClear = useCallback(() => {
    setCurrentValue('');
    setIsFocus(false);
  }, []);

  const classNames = classnames('FormSelectCustomer', className, {
    'FormSelectCustomer--has-value': currentValue?.length > 0,
    'FormSelectCustomer--is-invalid': isInvalid,
    'FormSelectCustomer--with-flying-label-opened': isFocus,
  });

  return (
    <div
      className={classNames}
      onFocus={() => { setIsFocus(true); }}
      onBlur={() => { setIsFocus(false); }}
    >
      <SelectSearch
        debounce={300}
        onChange={handleChange}
        value={currentValue}
        options={options}
        getOptions={getOptions}
        disabled={disabled}
        search
        placeholder={placeholder ?? undefined}
        multiple={false}
        printOptions="auto"
      />
      <input
        type="text"
        value={currentValue}
        name={name}
        ref={inputRef}
        onChange={() => {}}
        className="FormSelectCustomer__hidden-field"
      />
      {withClearButton && currentValue.length > 0 && (
        <Button
          variant="light"
          onClick={handleClear}
          className="FormSelectCustomer__clear"
        >
          <Icon name="close-small" />
        </Button>
      )}
    </div>
  );
};

export default FormSelectCustomerShell;
