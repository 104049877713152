import parseIsoDate from 'utils/parseIsoDate';

const dateIsLastTwoDays = (dateStr: string | null): boolean => {
  const date = parseIsoDate(dateStr);
  if (!date) {
    return false;
  }

  const now = new Date();
  const isToday = (
    date.getFullYear() === now.getFullYear()
    && date.getMonth() === now.getMonth()
    && date.getDate() === now.getDate()
  );
  if (isToday) {
    return true;
  }

  const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  return (
    date.getFullYear() === yesterday.getFullYear()
    && date.getMonth() === yesterday.getMonth()
    && date.getDate() === yesterday.getDate()
  );
};

export default dateIsLastTwoDays;
