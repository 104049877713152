import './index.scss';
import classnames from 'classnames';
import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { StepActionEntity } from 'types/models';
import type { StepAction } from 'types/models';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nChannel from 'utils/getI18nChannel';
import organizationStore from 'stores/Organization';
import ScenarioDetails from 'components/ScenarioDetails/index';
import config from 'config';
import Badge from 'components/Badge';
import Misc from 'types/misc';

type Props = {
  data: StepAction,
  showCustomer: boolean,
  onActionDone(message: string): void,
  onActionError?(message: string): void,
};

const HistoryStepInfosHeader = (props: Props): JSX.Element => {
  const { data, showCustomer } = props;
  const {
    name,
    entity,
    at,
    client,
    channel,
    scenario,
    isEditable,
    scenarioGroup,
  } = data;
  const { t } = useTranslation();
  const { currency } = organizationStore.currentOrganization!;
  const isRecent = useMemo(() => dateIsLastTwoDays(at?.at), [at?.at]);

  const [showScenarioDetails, hideScenarioDetails] = useModal(() => (
    scenario ? (
      <ScenarioDetails
        scenario={scenario.id}
        referenceDate={(scenario.referenceDate as Misc.DateTimezone | null)?.date}
        referenceDateOrigin={scenario.referenceDateOrigin}
        onClose={hideScenarioDetails}
      />
    ) : null
  ), [scenario]);

  const detailsClassNames = classnames('HistoryStepInfosHeader__details');

  return (
    <div className="HistoryStepInfosHeader">
      <div className="HistoryStepInfosHeader__titles">
        <span className="HistoryStepInfosHeader__titles__text">
          {name}
        </span>
        {isEditable && scenario && (
          <span
            className="HistoryStepInfosHeader__titles__scenario"
            onClick={showScenarioDetails}
          >
            {scenario.name}
          </span>
        )}
      </div>
      <div className={detailsClassNames}>
        <Badge variant="NOTE" isRecent={isRecent}>
          {(entity === StepActionEntity.REMINDER && channel) && t(getI18nChannel(channel))}
          {entity === StepActionEntity.TASK && t('common:task')}
        </Badge>
        {scenarioGroup && (
          <Badge variant="CUSTOM" color={scenarioGroup.color ?? config.SCENARIO_COLORS.at(-1)}>
            {scenarioGroup.name}
          </Badge>
        )}
        {showCustomer && (
          <Fragment>
            <Link
              to={`/customer/${client.id}`}
              className="HistoryStepInfosHeader__details__link"
            >
              {client.denomination}{client.identifier !== client.denomination
                ? ` (${client.identifier})`
                : ''}
            </Link>
            <span className="HistoryStepInfosHeader__details__total-amount">
              {formatIntlNumber(client.totalAmount, currency)}
            </span>
            <span className="HistoryStepInfosHeader__details__total-amount-due">
              {formatIntlNumber(client.totalAmountDue, currency)}
            </span>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default HistoryStepInfosHeader;
