import { Fragment } from 'react';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import { DebitStatus, PhaseId } from 'types/models';
import type { Payer } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import PhaseDate from 'components/PhaseDate';
import PDFViewer from 'components/PDFViewer';
import RowActions from './RowActions';

const PayerInvoicesColumns = (
  t: TFunction,
  onActionDone: (message: string) => void,
): Column<Payer>[] => [
  {
    Header: t('debits:reference') as string,
    accessor: 'reference',
    Cell: ({ row, value }) => (
      <div className="PayerInvoices__columns__reference">
        {row.original.invoice && (
          <PDFViewer
            scale={0.1}
            isClickable
            withAuthentication={false}
            base64PDF={row.original.invoice}
            downloadUrl={`${row.original.id}/${row.original.token}`}
          />
        )}
        {value}
      </div>
    ),
  },
  {
    Header: t('common:deadline') as string,
    accessor: 'dueAt',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <PhaseDate date={value} phaseId={PhaseId.DEFAULT} statusId={DebitStatus.IN_PROGRESS} />
    ),
  },
  {
    Header: t('common:amount') as string,
    accessor: 'amount',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ row, value }) => (
      <Fragment>
        {formatIntlNumber(value, row.original.currency)}
      </Fragment>
    ),
  },
  {
    Header: t('common:commands') as string,
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row, value: id }) => (
      <RowActions
        id={id}
        downloadPath={
          row.original.invoice
            ? `${row.original.id}/${row.original.token}`
            : undefined
        }
        reference={row.original.reference}
        onActionDone={onActionDone}
      />
    ),
  },
];

export default PayerInvoicesColumns;
