type AddressInfos = {
  companyAddress?: string | null,
  addressName?: string | null,
  address1?: string | null,
  address2?: string | null,
  address3?: string | null,
  postalCode?: string | null,
  city?: string | null,
  country?: string | null,
};

type Props = {
  data: AddressInfos,
};

const AddressDetails = ({ data }: Props): JSX.Element | null => {
  const address = [
    data.companyAddress,
    data.addressName,
    data.address1,
    data.address2,
    data.address3,
    `${data.postalCode ?? ''} ${data.city ?? ''}`,
    data.country,
  ]
    .filter((item) => item && !!item.trim())
    .map((item, index) => (
      <span key={index}>
        {item}
        {index !== 5 && <br />}
      </span>
    ));

  return <>{address.length ? address : '-'}</>;
};

export default AddressDetails;
