import Button from 'components/Button';
import './index.scss';
import Icon from 'components/Icon';

type Props = {
  label: string,
  onClick: () => void,
};

const ExportButton = ({ label, onClick }: Props): JSX.Element => {
  return (
    <Button className="ExportButton" onClick={onClick}>
      <Icon name="download" />{label}
    </Button>
  );
};

export default ExportButton;
