import { useModal } from 'react-modal-hook';
import type { Debit, DebitPlan } from 'types/models';
import Button from 'components/Button';
import EditPlan from '../../../Modals/EditPlan';

type Props = {
  plan: DebitPlan,
  isUserReader: boolean,
  debit: Debit,
  onActionDone(message: string): void,
};

const DebitContentInfosPlan = ({ plan, isUserReader, debit, onActionDone }: Props): JSX.Element => {
  const { name, identifier } = plan;

  const [showEditPlanModal, hideEditPlanModal] = useModal(() => (
    <EditPlan
      plan={plan}
      payrequestId={debit.id}
      payrequestReference={debit.reference}
      onDone={onActionDone}
      onClose={hideEditPlanModal}
    />
  ), [plan, debit, onActionDone]);

  return (
    <li className="DebitContentInfosPlan">
      {(isUserReader || debit.muted) && (
        <span className="DebitContentInfos__muted-group">
          {name}{identifier && ` (${identifier})`}
        </span>
      )}
      {(!isUserReader && !debit.muted) && (
        <Button variant="link" onClick={showEditPlanModal}>
          {name}{identifier && ` (${identifier})`}
        </Button>
      )}
    </li>
  );
};

export default DebitContentInfosPlan;
