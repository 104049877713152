import type { Collection } from 'types/api';
import Misc from 'types/misc';
import type { Scenario, Organization, ScenarioTemplate, StepAction, ScenarioStepPreviewContent } from 'types/models';
import requester from 'utils/requester';

export type FetchOptionsParams = {
  organization: string | undefined,
};

export type FetchAllParams = {
  page?: number | undefined,
  pageSize?: number,
  filtering?: Misc.Filter[],
};

export type FetchListParams = {
  id: Organization['id'] | undefined,
};

export type FetchOneParams = {
  organization: string | undefined,
  id: number,
};

export type FetchPreviewParams = {
  scenarioId: number,
  channel: StepAction['channel'],
  content?: StepAction['content'],
};

const all = async (
  organization: Organization['id'] | undefined,
  params: FetchAllParams | undefined,
): Promise<Collection<Scenario>> => {
  const { page = 1, pageSize = 100, filtering } = params ?? {
    page: 1,
    pageSize: 100,
  };

  if (!organization) {
    throw new Error('FetchAllScenarios: Missing organization ID.');
  }

  const queryData = new URLSearchParams();
  queryData.append('page', (page ?? 1).toString());
  queryData.append('itemsPerPage', pageSize.toString());
  queryData.append('order[sortOrder]', 'asc');

  const group = filtering?.find((o) => o.name === 'group.id');
  if (group && group.value === 'debt') {
    queryData.append('exists[group]', 'false');
  }

  if (filtering && filtering.length > 0) {
    filtering
      .filter(({ value }) => !!value && value !== 'debt')
      .forEach(({ name, value }) => {
        queryData.append(name, value as string);
      });
  }

  const { data } = await requester.get<Collection<Scenario>>(
    `organizations/${organization}/scenarios?${queryData.toString()}`,
  );
  return data;
};

const one = async ({ organization, id }: FetchOneParams): Promise<Scenario> => {
  if (!organization) {
    throw new Error('FetchOnScenario: Missing organization.');
  }

  const { data } = await requester.get<Scenario>(`scenarios/${id}`);
  return data;
};

/**
 * URL de la ressource d'API pour la création d'un Scenario (POST).
 */
const createUrl = 'scenarios';

/**
 * Récupère l'URL de la ressource d'API pour importer un fichier yaml.
 *
 * @returns URL utiliser pour l'import.
 */
const importUrl = 'scenarios/import';

/**
 * Récupère l'URL de la ressource d'API pour modifier le scenario.
 *
 * @param id L'ID du scenario
 * @returns URL du PUT.
 */
const updateUrl = (id: Scenario['id']) => `scenarios/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour supprimer le scenario.
 *
 * @param id L'ID du scenario
 * @returns URL du DELETE.
 */
const deleteUrl = (id: Scenario['id']) => `scenarios/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour exporter les scénarios.
 *
 * @param id L'ID d'organization
 * @returns URL du DELETE.
 */
const exportUrl = (organizationReference?: Organization['reference']) => {
  if (!organizationReference) {
    throw new Error('getScenariosTemplate: Missing organization ID.');
  }
  const queryData = new URLSearchParams();
  queryData.append('organizations', organizationReference);

  return `scenarios/export?${queryData.toString()}`;
};

/**
 * Retourne une liste modifiée pour les tasks ou reminders ou dispatches selon l'action
 *
 * @returns Liste modifiée selon l'action
 */
const buildPayloadListData = (dataList: ScenarioTemplate[], index: number, actionModal: Misc.ActionModal, data?: ScenarioTemplate) => {
  const result: ScenarioTemplate[] = [...dataList];
  switch (actionModal) {
    case 'create':
      if (data) {
        result.push(data);
      }
      break;
    case 'edit':
      if (data) {
        result[index] = data;
      }
      break;
    case 'delete':
      result.splice(index, 1);
      break;
  }
  return result;
};

/**
 * Récupère l'URL de la ressource d'API pour mapper une association de scenario.
 *
 * @param id L'ID du scenario
 * @returns URL du scenario.
 */
const resourceUrl = (id: Scenario['id']) => `/api/scenarios/${id}`;

const fetchPreviewContent = async ({ scenarioId, channel, content }: FetchPreviewParams) => {
  if (!scenarioId) {
    throw new Error('FetchPreviewContentScenario: Missing id.');
  }

  const { data } = await requester.post<ScenarioStepPreviewContent>(
    `scenarios/${scenarioId}/preview`, {
      channel,
      content: content ?? undefined,
    },
  );

  return data;
};

export default {
  one,
  all,
  exportUrl,
  createUrl,
  importUrl,
  updateUrl,
  deleteUrl,
  resourceUrl,
  buildPayloadListData,
  fetchPreviewContent,
};
