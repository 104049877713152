import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import type { OrganizationAnalytics } from 'types/models';
import Misc from 'types/misc';
import getChartData from '../../utils';
import defaultChartOptions from '../../defaultChartOptions';

type Props = {
  data: OrganizationAnalytics['paymentDelay'],
};

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Filler, ChartDataLabels);

const PaymentDelayChart = ({ data }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState<Misc.AnalyticsChartData>({ labels: [], datasets: [] });

  useEffect(() => {
    setChartData(getChartData(
      (key) => key !== 'date',
      data,
      t,
      'line' as const,
    ));
  }, [data, t]);

  if (!chartData) {
    return null;
  }

  return <div className="Analysis__chart">
      <div className="Analysis__chart__content">
        <Chart
          type="line"
          data={chartData}
          options={{
            ...defaultChartOptions,
            plugins: {
              legend: { display: true },
              datalabels: { display: false },
            },
            scales: {
              yAxis: { beginAtZero: true },
            },
          }}
        />
      </div>
    </div>;
};

export default PaymentDelayChart;
