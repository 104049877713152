import './index.scss';
import { useTranslation } from 'react-i18next';
import DownloadLink from 'components/DownloadLink';
import Icon from 'components/Icon';

type Props = {
  exportLink: string,
};

const Export = ({ exportLink }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DownloadLink className="Export" path={exportLink || ''} withDownload={false}>
      <Icon name="download" />{t('common:download')}
    </DownloadLink>
  );
};

export default Export;
