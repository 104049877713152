import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { ClientSummary, CurrencyCode, ManagerType, ScenarioSummary } from 'types/models';
import { RightsCategoryType } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import ListHistorySummary from 'components/ListHistorySummary';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import { UserRightsManagerController } from 'components/UserRights';
import Button from 'components/Button';
import RowActions from '../Actions/Row';

const CustomersColumns = (
  ct: TFunction,
  currency: CurrencyCode | null,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  showScenarioModal: (scenario: ScenarioSummary) => void,
): Column<ClientSummary>[] => [
  {
    Header: ct<string>('common:client'),
    accessor: 'denomination',
    id: 'denomination',
    Cell: ({ value, row }) => (
      <div className="Customers__columns__name">
        <Link to={`/customer/${row.original.id}`}>
          {value}
        </Link>
        {row.original.identifier !== value && (
          <div className="Customers__columns__ref">{row.original.identifier}</div>
        )}
      </div>
    ),
  },
  {
    Header: ct<string>('common:total-outstanding-amount'),
    accessor: 'statistics',
    id: 'totalToCollect',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <div className="Customers__columns__in-progress">
        {formatIntlNumber(value?.totalToCollect, currency)}
        <div className="Customers__columns__count">
          {ct(
            'common:bills-count',
            { count: (value?.totalUnpaidDebit || 0) },
          )}
        </div>
      </div>
    ),
  },
  {
    Header: ct<string>('common:past-due'),
    accessor: 'statistics',
    id: 'totalDue',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <div className="Customers__columns__late">
        {formatIntlNumber(value?.totalDue, currency)}
        <div className="Customers__columns__count">
          {ct('common:bills-count', { count: value?.totalDueUnpaidDebit })}
        </div>
      </div>
    ),
  },
  {
    Header: ct<string>('common:scenario-debt'),
    accessor: 'scenario',
    sortType: 'basic',
    disableSortBy: true,
    Cell: ({ value }) => (
      <Fragment>
        {/* Cibler le premier scenario qui est considéré sur le group Dettes */}
        {!!value && value[0]?.filter(({ isVirtual }) => !isVirtual).map((scenario) => {
          return (
            <Button
              key={scenario.id}
              variant="link"
              className="Customers__columns__scenario"
              onClick={() => showScenarioModal(scenario)}
            >{scenario.name}</Button>
          );
        })}
      </Fragment>
    ),
  },
  {
    Header: ct<string>('common:history'),
    accessor: 'statistics',
    disableSortBy: true,
    Cell: ({ row }) => {
      const { dunningNextAction, dunningPastAction, mute } = row.original;
      return (
        <ListHistorySummary data={{ nextSending: dunningNextAction?.date ?? null, lastSending: dunningPastAction?.date ?? null, mute }} />
      );
    },
  },
  {
    Header: ct<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row, value: id }) => {
      const managers = row.original.assignedUser?.map((user) => ({
        id: user.id,
        name: `${user.firstname} ${user.lastname}`,
        identifier: user.identifier,
      } as ManagerType)) ?? [];
      return (
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <UserRightsManagerController action="UPDATE" managers={managers} categoryType={RightsCategoryType.CLIENTS}>
            <RowActions
              id={id}
              name={row.original.denomination ?? ''}
              lastNote={row.original.lastClientNote ?? null}
              customerData={row.original}
              onActionDone={onActionDone}
              onActionError={onActionError}
            />
          </UserRightsManagerController>
        </ExternallyMonitoredController>
      );
    },
  },
];

export default CustomersColumns;
