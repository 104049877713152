import './index.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const LoginLinks = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ul className="LoginLinks">
      <li className="LoginLinks__item">
        <a
          href="https://www.dunforce.com"
          className="LoginLinks__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dunforce.com
        </a>
      </li>
      <li className="LoginLinks__item">
        <a
          href="https://www.dunforce.com/fonctionnalites"
          className="LoginLinks__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('login:links.solutions')}
        </a>
      </li>
      <li className="LoginLinks__item">
        <a
          href="https://www.dunforce.com/blog"
          className="LoginLinks__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('login:links.blog')}
        </a>
      </li>
      <li className="LoginLinks__item">
        <a
          href="https://www.dunforce.com/contact"
          className="LoginLinks__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('login:links.contact')}
        </a>
      </li>
    </ul>
  );
};

export default observer(LoginLinks);
