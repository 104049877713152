import {
  runInAction,
  makeAutoObservable,
} from 'mobx';
import Cookies from 'js-cookie';
import i18n from 'config/i18n';
import apiAuth from 'api/auth';
import apiUserProfile from 'api/userProfile';
import history from 'utils/history';
import { analyticsLogin } from 'utils/analytics';
import ResponseError from 'utils/errors';
import type { User } from 'types/models';
import { RightsRole } from 'types/models';
import organizationStore from './Organization';
import userRightsStore from './UserRights';

const initialUser: User | null = (() => {
  try {
    return JSON.parse(window.localStorage.getItem('user') || 'null');
  } catch {
    return null;
  }
})();

class AuthStore {
  isFetched: boolean = !!initialUser;

  isBusy: boolean = false;

  user: User | null = initialUser;

  error: ResponseError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isLogged() {
    return !!this.user;
  }

  get isDemo() {
    return this.user?.roles?.includes(RightsRole.ROLE_DEMO);
  }

  get isSuperAdmin() {
    return this.user?.roles?.includes(RightsRole.ROLE_SUPER_ADMINISTRATOR);
  }

  // ------------------------------------------------------
  // -
  // -    Actions
  // -
  // ------------------------------------------------------

  async login(email: string, password: string) {
    if (this.isBusy) {
      throw new Error('Une opération est déjà en cours.');
    }

    this.isBusy = true;
    this.error = null;

    try {
      const { refreshToken, accessToken } = await apiAuth.login(email, password);

      Cookies.set('accessToken', accessToken);
      Cookies.set('refreshToken', refreshToken);

      runInAction(() => { this.isBusy = false; });

      this.fetchUser();

      if (!userRightsStore.rights) {
        userRightsStore.getAll();
      }
    } catch (error) {
      runInAction(() => {
        this.error = error as ResponseError;
        this.isBusy = false;
      });
    }
  }

  async fetchUser() {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.error = null;

    try {
      const data = await apiUserProfile.currentUser();
      if (!data) {
        throw new Error('No current user found');
      }

      window.localStorage.setItem('user', JSON.stringify(data));
      i18n.changeLanguage(data.locale.substring(0, 2));

      runInAction(() => {
        this.user = data;
        this.isFetched = true;
      });

      if (data.organizations.length === 1) {
        organizationStore.setCurrent(data.organizations[0].id);
      }

      analyticsLogin(data.id, `${data.firstName} ${data.lastName}`, data.email);
    } catch (err) {
      runInAction(() => {
        this.error = err as ResponseError;
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
        this.isFetched = true;
      });
    }
  }

  logout() {
    organizationStore.resetCurrent();

    Cookies.remove('accessToken', { path: '/' });
    Cookies.remove('refreshToken', { path: '/' });
    window.localStorage.removeItem('user');

    runInAction(() => {
      this.user = null;
      this.error = null;
      this.isFetched = false;
    });

    history.push('/login');
  }

  setCurrent = (user: User) => {
    this.user = user;
    window.localStorage.setItem('user', JSON.stringify(user));
  };
}

export default new AuthStore();
export { AuthStore };
