import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import SelectSearch from 'react-select-search';
import type { SelectSearchOption } from 'react-select-search';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading';
import type { DefaultValue } from './utils';
import { getDefaultValue } from './utils';

export type Props = {
  name: string,
  onSelect?: (name: string, newValue: string | null) => void,
  defaultValue?: DefaultValue,
  value?: number | string | string[] | null,
  selectOptions?: Readonly<Misc.ValueLabel[]>,
};

const getPropOptions = (selectOptions: Readonly<Misc.ValueLabel[]> | undefined) => {
  if (!selectOptions) {
    return null;
  }
  return selectOptions.map(({ label, value: optionValue }) => (
    { name: label, value: `${optionValue}` }
  ));
};

const FormSelectMinimal = (props: Props): JSX.Element => {
  const {
    name,
    onSelect,
    defaultValue,
    value: controlValue,
    selectOptions,
  } = props;
  const { currentOrganization } = organizationStore;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState<number | string | string[]>('');
  const [cleared, setCleared] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectSearchOption[] | null>(
    getPropOptions(selectOptions),
  );

  useEffect(() => {
    if (defaultValue && !value) {
      const initValue = getDefaultValue(defaultValue, options || []);
      setValue(initValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  useEffect(() => {
    if (controlValue !== undefined && value !== controlValue) {
      if (cleared) {
        setCleared(false);
      }
      setValue(controlValue === null ? '' : controlValue);
    }
  }, [value, controlValue, cleared]);

  useEffect(() => {
    setOptions(getPropOptions(selectOptions));
  }, [selectOptions, currentOrganization]);

  const handleChange = useCallback(
    (selectedValue: string | string[]) => {
      setValue(selectedValue);
      const newValue = (Array.isArray(selectedValue)) ? selectedValue.join(',') : selectedValue;

      if (inputRef?.current) {
        // - Ceci permet de déclencher un onChange au niveau du <form> parent
        const inputValSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        inputValSetter?.call(inputRef.current, newValue);
        inputRef.current.dispatchEvent(
          new Event('change', { bubbles: true }),
        );
      }

      if (onSelect) {
        onSelect(name, newValue);
      }
    },
    [name, onSelect],
  );

  return (
    <div className='FormSelectMinimal'>
      {options === null ? <Loading /> : (
        <SelectSearch
          onChange={handleChange}
          value={value}
          options={options}
          printOptions="on-focus"
        />
      )}
      <input
        type="text"
        value={value}
        name={name}
        ref={inputRef}
        readOnly
        className="FormSelectMinimal__hidden-field"
      />
    </div>
  );
};

export default observer(FormSelectMinimal);
