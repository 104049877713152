import './index.scss';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import customerContext from 'pages/Customer/customerContext';
import SplitButtonDropdown from 'components/SplitButtonDropdown';
import {
  ExternallyMonitoredSwitch,
  ExternallyMonitoredController,
} from 'components/ExternallyMonitored';
import CustomerEditModal from 'components/CustomerEdit';
import { RightsCategoryType } from 'types/models';
import { clientUserToManagerType } from 'utils/mapping/user-manager';
import CustomerActionCreateNote from './CreateNote';
import CustomerActionNewContact from './NewContact';
import CustomerActionNewDebit from './NewDebit';
import CustomerActionNewCredit from './NewCredit';
import CustomerActionDelete from './Delete';
import CustomerActionMute from './Mute';
import CustomerActionCreateReminder from './CreateReminder';
import CustomerActionCreateTask from './CreateTask';

type Props = {
  onActionError(message: string): void,
};

const CustomerHeaderActions = ({ onActionError }: Props): JSX.Element | null => {
  const { data: customer, onActionDone } = useContext(customerContext);
  const { t } = useTranslation();

  const managers = useMemo(() => {
    return customer?.users ? customer.users.map((user) => clientUserToManagerType(user)) : [];
  }, [customer?.users]);

  const handleEditDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <CustomerEditModal
      onDone={handleEditDone}
      editType="edit"
      customerId={customer?.id}
      onClose={hideEditModal}
    />
  ), [handleEditDone, customer]);

  if (!customer) {
    return null;
  }

  const { hasDunningAssignment } = customer;

  return (
    <div className="CustomerHeaderActions">
      <ExternallyMonitoredSwitch>
        <ExternallyMonitoredController isDisplayedWhenMonitored>
          <CustomerActionCreateNote variant="outline" />
        </ExternallyMonitoredController>
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <SplitButtonDropdown
            onClick={showEditModal}
            title={t('common:edit')}
            action="UPDATE"
            categoryType={RightsCategoryType.CLIENTS}
          >
            {hasDunningAssignment && (<CustomerActionCreateReminder />)}
            {hasDunningAssignment && (<CustomerActionCreateTask />)}
            <CustomerActionCreateNote variant="list" />
            <CustomerActionMute managers={managers} onError={onActionError} />
            <hr className="CustomerHeaderActions__separator" />
            <CustomerActionNewContact managers={managers} />
            <CustomerActionNewDebit managers={managers} />
            <CustomerActionNewCredit managers={managers} />
            <hr className="CustomerHeaderActions__separator" />
            <CustomerActionDelete managers={managers} onError={onActionError} />
          </SplitButtonDropdown>
        </ExternallyMonitoredController>
      </ExternallyMonitoredSwitch>
    </div>
  );
};

export default CustomerHeaderActions;
