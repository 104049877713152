import './index.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { DataBlock } from 'types/models';
import { Channel } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroupWithAction from 'components/FormGroupWithActions';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Wysiwyg from 'components/Wysiwyg';
import getI18nChannel from 'utils/getI18nChannel';
import TagLink from 'components/TagLink';
import FormControlSMSContent from 'components/FormControlSMSContent';

type Props = {
  channel: Channel,
  errors?: ValidationErrors | null,
  defaultData?: DataBlock,
};

const ModalReminderForm = (props: Props): JSX.Element => {
  const { errors, channel, defaultData } = props;
  const { t } = useTranslation();
  const [content, setContent] = useState<string>(defaultData?.content || '');

  return (
    <div className="ModalReminderForm">
      <FormFieldset>
        <FormGroup label={t('models:short-description')} error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            placeholder={t('reminders:placeholder.short-description')}
            defaultValue={defaultData?.name}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('reminders:description')} error={errors?.description}>
          <FormControl
            type="text"
            name="description"
            autoComplete="off"
            defaultValue={defaultData?.description}
            placeholder={t('reminders:placeholder.description', { channel: t(getI18nChannel(channel)) })}
            isInvalid={!!errors?.description}
          />
        </FormGroup>
      </FormFieldset>
      <div className="ModalReminderForm__divider" />
      {channel === Channel.EMAIL && (
        <FormFieldset>
          <FormGroup label={t('reminders:email-subject')} error={errors?.subject}>
            <FormControl
              type="text"
              name="subject"
              autoComplete="off"
              defaultValue={defaultData?.subject}
              isInvalid={!!errors?.subject}
              placeholder={t('reminders:placeholder.subject')}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormFieldset>
        {channel !== Channel.SMS && (
          <FormGroupWithAction
            label={channel === Channel.EMAIL ? t('reminders:email-content') : t('reminders:content')}
            mandatory
            action={<TagLink />}
          >
            <Wysiwyg
              name="content"
              defaultValue={content}
              toBePrinted={channel !== Channel.EMAIL}
              onChange={setContent}
            />
          </FormGroupWithAction>
        )}
        {channel === Channel.SMS && (
          <FormGroupWithAction
            label={t('reminders:content')}
            error={errors?.subject}
            action={<TagLink />}
          >
            <FormControlSMSContent
              name="content"
              value={content}
              onChange={setContent}
              isInvalid={!!errors?.content}
            />
          </FormGroupWithAction>
        )}
      </FormFieldset>
    </div>
  );
};

export default ModalReminderForm;
