import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import type { DebitDraft } from 'types/models';
import DraftInvoiceModal from 'components/DraftInvoiceModal';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';

type Props = {
  id: string,
  data: DebitDraft,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const DebitsToCompleteRowActions = (props: Props): JSX.Element => {
  const { id, data, onActionDone, onActionError } = props;
  const { reference, type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { put, error, isLoading } = useApiRequest();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(ct('common:remove'), ct('payrequests-draft:actions.confirm.remove'));
  }, [showConfirm, ct]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const response = await put(
      `/import/manage/${reference}/${id}/disable-draft`,
    );
    if (response) {
      onActionDone(ct('payrequests-draft:actions.toast.deleted'));
    }
  }, [hideConfirm, put, reference, id, onActionDone, ct]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
  }, [error, onActionError, t]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <DraftInvoiceModal
      onClose={hideEditModal}
      data={data}
      onActionDone={onActionDone}
      onActionError={onActionError}
    />
  ), [data, onActionDone, onActionError]);

  return (
    <>
      <Button
        onClick={showEditModal}
        title={ct('common:complete')}
        disabled={isLoading}
      >
        {isLoading && <Loading hasNoText />}
        {!isLoading && t('common:complete')}
      </Button>
      <Button
        variant="outline"
        onClick={handleConfirmDelete}
        title={ct('clients:remove')}
        disabled={isLoading}
      >
        {isLoading && <Loading hasNoText />}
        {!isLoading && t('common:remove')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={ct('common:remove')}
      />
    </>
  );
};

export default observer(DebitsToCompleteRowActions);
