import type Misc from 'types/misc';

const getFilterValue = (currentFilters: Misc.Filter[]) => (
  (filterName: string): string | string[] | null => {
    const foundFilter = currentFilters.find(({ name }) => name === filterName);
    return foundFilter ? foundFilter.value : null;
  }
);

export default getFilterValue;
