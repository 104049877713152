import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { ValidationErrors } from 'types/errors';
import type { User } from 'types/models';
import authStore from 'stores/Auth';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import type { ModalFormData } from 'components/ModalForm';
import ModalForm from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import apiUsers from 'api/users';
import SignatureEditForm from './SignatureEditForm';

export type UserLetterSignature = {
  signatureLetter: string,
};

export type UserEmailSignature = {
  signatureEmail: string,
};

type UserSignature = UserLetterSignature & UserEmailSignature;

export type SignatureType = 'email' | 'letter';

type Props = {
  collaborator?: User,
  signatureType: SignatureType,
  signature: string | null,
  onClose(): void,
  onShowToast(message: string, type: ToastStatus): void,
};

const ModalEditSignature = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const {
    signatureType,
    signature,
    collaborator,
    onClose,
    onShowToast,
  } = props;

  const user = useMemo(() => collaborator ?? authStore.user, [collaborator]);

  const mapFormData = useCallback(
    (rawData: ModalFormData): UserSignature => ({
      signatureLetter: rawData?.signatureLetter as string,
      signatureEmail: rawData?.signatureEmail as string,
    }),
    [],
  );

  const initialData = useRef<UserSignature | null>(null);

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(!!formData && !equal(initialData.current, mapFormData(formData)));
    },
    [initialData, mapFormData],
  );

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const {
    put,
    isLoading,
    error,
  } = useApiRequest();

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !user) {
        return;
      }

      const result = await put<User>(
        apiUsers.updateUrl(user.id),
        mapFormData(formData),
      );

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (result?.id) {
        if (!collaborator || collaborator?.id === authStore.user?.id ) {
          authStore.setCurrent(result);
        }
        onShowToast(t('users:toast.success.settings-update'), 'success');
        onClose();
      }
    },
    [user, put, mapFormData, isMountedRef, onShowToast, t, onClose, collaborator],
  );

  const handleSignatureChange = useCallback((content: string) => {
    if (!initialData.current) {
      return;
    }

    let initialContent;
    if (signatureType === 'letter') {
      initialContent = initialData.current.signatureLetter;
    } else if (signatureType === 'email') {
      initialContent = initialData.current.signatureEmail;
    }

    setHasChanges(initialContent !== content);
  }, [signatureType]);

  const modalTitle = useMemo(() => (
    signatureType === 'letter' ? t('users:signature-letter') : t('users:signature-email')
  ), [signatureType, t]);

  return (
    <ModalForm
      title={modalTitle}
      className="ModalEditSignature"
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      {signatureType === 'email' && (
        <SignatureEditForm
          name="signatureEmail"
          defaultData={signature}
          errors={validationErrors}
          onChange={handleSignatureChange}
        />
      )}
      {signatureType === 'letter' && (
        <SignatureEditForm
          name="signatureLetter"
          defaultData={signature}
          errors={validationErrors}
          onChange={handleSignatureChange}
        />
      )}
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalEditSignature);
