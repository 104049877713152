import dayjs from 'dayjs';
import { EAVType } from 'types/models';
import i18n from 'config/i18n';
import type { EAVValue, Eav, EavConstructor, OrganizationEAVs } from 'types/models';
import { parseBoolean } from './formatEavsValues';

type DisplayAttribute = {
  id: number,
  label: string,
  value: string,
  identifier?: string,
  useHtml?: boolean,
  position: number | null,
};

const getEavValueFormatted = (type: EAVType, value: any) => {
  switch (type) {
    case EAVType.BOOLEAN:
      const valueTyped = (value as boolean);
      return valueTyped ? i18n.t('common:yes') : i18n.t('common:no');
    case EAVType.STRING:
    case EAVType.NUMBER:
      return value;
    case EAVType.DATE:
      const date = dayjs(value);
      return date.format('YYYY-MM-DD');
  }
};

const getEavValueFormattedForForm = (type: EAVType, value: any) => {
  switch (type) {
    case EAVType.BOOLEAN:
      return parseBoolean(value).toString();
    case EAVType.STRING:
    case EAVType.NUMBER:
      return value;
    case EAVType.DATE:
      const date = dayjs(value);
      return date.format('YYYY-MM-DD');
  }
};

const getAllEavsValues = (
  customerEavs: EAVValue[] | null | undefined,
  allEavs: EavConstructor[] | null,
): DisplayAttribute[] => {
  if (!allEavs) {
    return [];
  }

  if (!customerEavs) {
    const requiredEavs = allEavs.filter(({ required }) => required);
    return requiredEavs.map(({ id, label, position, type, identifier }) => ({
      id,
      label,
      value: '',
      identifier,
      position,
      type,
    }));
  }

  const getValue = (identifier: Eav['identifier'], type: EAVType) => {
    const organizationEav = allEavs.find(({ identifier: _id }) => _id === identifier);
    if (!organizationEav) {
      return null;
    }

    const customerEav = customerEavs.find(({ code: _id }) => _id === identifier);
    if (!customerEav) {
      return null;
    }

    return getEavValueFormatted(type, customerEav.data);
  };

  const eavsList: DisplayAttribute[] = allEavs.map(({ id, identifier, label, position, type, useHtml }) => {
    const defaultValue = type === EAVType.BOOLEAN ? i18n.t('common:no') : '-';
    const value = getValue(identifier, type) ?? defaultValue;
    return { id, label, value, position, identifier, useHtml };
  });

  eavsList.sort(({ label: labelA, position: posA }, { label: labelB, position: posB }) => {
    if (posA === posB) {
      return labelA.localeCompare(labelB);
    }
    return (posA ?? 0) > (posB ?? 0) ? 1 : -1;
  });

  return eavsList;
};

const getOverviewCustomerEavs = (
  customerEavs: EAVValue[] | null | undefined,
  organizationEavs: OrganizationEAVs | null,
): DisplayAttribute[] => {
  if (!organizationEavs) {
    return [];
  }

  const { client: allEavs } = organizationEavs;

  if (!customerEavs) {
    const requiredEavs = allEavs.filter(({ required }) => required).slice(0, 4);
    return requiredEavs.map(({ id, label, position, type }) => ({
      id,
      label,
      value: '',
      position,
      type,
    }));
  }

  const getValue = (identifier: Eav['identifier'], type: EAVType) => {
    const organizationEav = allEavs.find(({ identifier: _id }) => _id === identifier);
    if (!organizationEav) {
      return null;
    }

    const customerEav = customerEavs.find(({ code: _id }) => _id === identifier);
    if (!customerEav) {
      return null;
    }

    return getEavValueFormatted(type, customerEav.data);
  };

  const eavsList: DisplayAttribute[] = allEavs
    .filter(({ overviewDisplay }) => overviewDisplay)
    .map(({ id, identifier, label, position, type, useHtml }) => {
      const defaultValue = type === EAVType.BOOLEAN ? i18n.t('common:no') : '-';
      const value = getValue(identifier, type) ?? defaultValue;
      return { id, label, value, position, useHtml };
    });

  eavsList.sort(({ label: labelA, position: posA }, { label: labelB, position: posB }) => {
    if (posA === posB) {
      return labelA.localeCompare(labelB);
    }
    return (posA ?? 0) > (posB ?? 0) ? 1 : -1;
  });

  return eavsList;
};

export { getAllEavsValues, getOverviewCustomerEavs, getEavValueFormatted, getEavValueFormattedForForm };
