import './index.scss';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { HistoryActivityType, type HistoryActivity as HistoryActivityBO } from 'types/models';
import StepActivityIcon from 'components/StepActivityIcon';
import getHistoryActivityAlert from 'utils/getHistoryActivityAlert';
import HistoryActivityInfosHeader from './InfosHeader';
import HistoryActivityDate from './Date';

type Props = {
  data: HistoryActivityBO,
  standalone?: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const HistoryActivity = (props: Props): JSX.Element => {
  const { data, onActionDone, onActionError, standalone = false } = props;
  const { type, tag } = data;

  const isAlert = useMemo(() => {
    return getHistoryActivityAlert(tag);
  }, [tag]);

  const cache = useQueryClient();

  const handleActionDone = useCallback((message: string) => {
    // - Invalide le cache de toutes les actions
    const cacheToInvalidate = [
      'actionsFuture',
      'actionsDone',
      'debitHistorySteps',
      'notesForDebit',
      'reminderContent',
      'previewContent',
      'customerHistory',
      'customerActions',
      'notesForCustomer',
      'organizationHistorySteps',
      'organizationLastHistory',
      'myActions',
    ];
    cacheToInvalidate.forEach((cacheKey: string) => {
      cache.invalidateQueries({ queryKey: [cacheKey] });
    });

    onActionDone(message);
  }, [cache, onActionDone]);

  const classNames = classnames('HistoryActivity', {
    'HistoryActivity--is-alert': isAlert,
    'HistoryActivity--is-note': type === HistoryActivityType.NOTE,
  });

  return (
    <li className={classNames}>
      <div className="HistoryActivity__infos">
        <StepActivityIcon historyActivity={data} />
        <HistoryActivityInfosHeader
          data={data}
          standalone={standalone}
          onActionDone={handleActionDone}
          onActionError={onActionError}
        />
        <HistoryActivityDate
          standalone={standalone}
          data={data}
        />
      </div>
    </li>
  );
};

export default observer(HistoryActivity);
