import './index.scss';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { StepActionPeriodFilter, StepActionTypeFilter } from 'types/models';
import type { HistoryStepActionbWebHook } from 'types/models';
import apiHistorySteps from 'api/historySteps';
import type { FetchActionsDoneAllParams } from 'api/historySteps';
import organizationStore from 'stores/Organization';
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import authStore from 'stores/Auth';
import HomeHistoryItem from './Item';

type Props = {
  onCountActions(count: number): void,
};

const HomeHistory = ({ onCountActions }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { walletSelection, currentOrganization, attributes } = organizationStore;
  const { user } = authStore;

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return [];
    }
    return [{ name: 'category', value: walletSelection }];
  }, [walletSelection]);

  const {
    isLoading,
    data: lastActionsDone,
    error,
    serverPagination,
  } = useFetchPaginated<FetchActionsDoneAllParams, HistoryStepActionbWebHook>(
    {
      cacheKey: 'lastStepActionsDone',
      organizationId: currentOrganization?.id,
      params: {
        page: 0,
        organizationReference: currentOrganization?.reference,
        pageSize: 5,
        locale: user?.locale,
        filtering: [
          ...filtering,
          { name: 'period', value: StepActionPeriodFilter.LAST_15_DAYS },
          {
            name: 'type',
            value: [
              StepActionTypeFilter.TASK_COMPLETED,
              StepActionTypeFilter.REMINDER_COMPLETED_AUTO,
              StepActionTypeFilter.REMINDER_COMPLETED_MANUAL,
            ],
          },
        ],
        attributes: attributes.client,
      },
    },
    apiHistorySteps.allDone,
  );

  useEffect(() => {
    if (lastActionsDone && serverPagination) {
      onCountActions(serverPagination.totalRecords);
    }
  }, [onCountActions, lastActionsDone, serverPagination]);

  return (
    <div className="HomeHistory">
      {error && <ErrorMessage error={error} />}
      {isLoading && <Loading hasNoText />}
      {lastActionsDone && serverPagination.totalRecords > 0 && (
        <ul className="HomeHistory__list">
          {lastActionsDone.map((event) => (
            <HomeHistoryItem data={event} key={event.id} />
          ))}
        </ul>
      )}
      {!isLoading && lastActionsDone && serverPagination.totalRecords === 0 && (
        <div className="HomeHistory__no-data">
          {t('debits:no-history')}
        </div>
      )}
    </div>
  );
};

export default observer(HomeHistory);
