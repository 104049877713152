import type { Collection } from 'types/api';
import type { Country } from 'types/models';
import requester from 'utils/requester';

const all = async (): Promise<Country[]> => {
  const { data } = await requester.get<Collection<Country>>('countries');
  return data['hydra:member'];
};

export default { all };
