import axios from 'axios';
import type { AxiosResponse } from 'axios';
import Config from 'config';
import ResponseError from 'utils/errors';

type TokenResponse = {
  access_token: string,
  refresh_token: string,
  expires_in: number,
  token_type: 'bearer',
  scope: null,
};

type Tokens = {
  refreshToken: string,
  accessToken: string,
};

const login = async (email: string, password: string): Promise<Tokens> => {
  const credentials = new FormData();
  credentials.append('username', email);
  credentials.append('password', password);
  
  const authUrl = `${Config.BASE_URL}/security/cognito-login`;

  let status: number | undefined = undefined;
  let message: string | undefined = '';

  try {
    // - Ici, on n'utilise pas le requester mais directement Axios, car on ne veut
    // pas des headers d'authentification insérés par requester, ni la gestion des erreurs standard.
    const { data } = await axios.post<FormData, AxiosResponse<TokenResponse>>(authUrl, credentials);
    const { access_token: accessToken, refresh_token: refreshToken } = data;

    return { accessToken, refreshToken };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      status = response?.status;
      message = response?.data['hydra:description'];
    }
    throw new ResponseError(status ?? 500, message ?? 'Unknown error');
  }
};

export default { login };
