import './index.scss';
import { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import config from 'config';
import type { Client, FreeTaskPost, StepAction } from 'types/models';
import { StepActionEntity } from 'types/models';
import type { ValidationErrors } from 'types/errors';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import apiHistorySteps from 'api/historySteps';
import apiScenarioGroup from 'api/scenarioGroup';
import apiDebits from 'api/debits';
import TaskForm from './Form';

type Props = {
  task: StepAction,
  customerId?: Client['id'] | null,
  onDone(title: string, message: string): void,
  onClose(): void,
};

const FreeTaskEdit = ({ task, onDone, onClose, customerId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const { patch, isLoading, error } = useApiRequest();

  const initialData = useRef<FreeTaskPost>({
    name: task.name as string,
    description: task.description as string,
    startAt: task.at?.at,
    type: StepActionEntity.TASK,
    group: task.scenarioGroup ? String(task.scenarioGroup.id) : undefined,
    static: task.isStatic,
  });

  const mapFormData = useCallback((rawData: ModalFormData): FreeTaskPost | null => {
    if (!currentOrganization) {
      return null;
    }

    const data: FreeTaskPost = {
      name: rawData.name as string,
      description: rawData.description as string,
      type: StepActionEntity.TASK,
      group: rawData.group ? apiScenarioGroup.resourceUrl(parseInt(rawData.group as string, 10)) : undefined,
      static: rawData.static === '1',
    };

    if (rawData.withMove === 'move') {
      data.nbPropagationDay = Number(rawData.daysShift);
    } else {
      data.startAt = rawData.startAt as string;
    }

    return data;
  }, [currentOrganization]);

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    const newData = formData ? mapFormData(formData) : null;
    setHasChanges(!equal(initialData.current, newData));
  }, [initialData, mapFormData]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    setValidationErrors(null);

    const { startAt, name: taskName } = formData as { [key: string]: string };

    if (!taskName) {
      setValidationErrors({ name: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (!startAt) {
      setValidationErrors({ startAt: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    const result = await patch<FreeTaskPost>(apiHistorySteps.updateUrl(task.code), data);
    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result === null) {
      return;
    }

    closeSelf();

    const { name } = task;
    onDone(
      t('reminders:actions.toast.edited'),
      t('reminders:actions.toast.edited-name', { name }),
    );
  }, [currentOrganization, patch, mapFormData, isMountedRef, onDone, t, closeSelf, task]);

  return (
    <ModalForm
      isOpened
      className="ReminderEdit"
      title={t('reminders:freetask.edit')}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      helpHeaderLink={config.FRESHDESK_ACTIONS_FORMS}
    >
      {error && <ErrorMessage error={error} />}
      <TaskForm
        defaultData={{
          name: task.name || '',
          description: task.description || '',
          startAt: task.at?.at,
          debit: task.debit?.id ? apiDebits.resourceUrl(task.debit.id) : undefined,
          isStatic: task.isStatic,
        }}
        customerId={customerId}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(FreeTaskEdit);
