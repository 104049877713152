import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'components/Dropdown';
import Loading from 'components/Loading';
import Icon from 'components/Icon';

interface Props {
  actions: React.ReactNode[],
  isLoading?: boolean,
}

const DropdownActions: React.FC<Props> = ({ actions, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      className="DropdownActions"
      variant="outline"
      toggleTitle={t<string>('common:more-actions')}
      toggle={isLoading ? <Loading hasNoText /> : <Icon name="dots" />}
      noIcon
    >
      {actions.map((action, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="DropdownActions__item" key={`action-${index}`}>
          {action}
        </div>
      ))}
    </Dropdown>
  );
};

export default DropdownActions;
