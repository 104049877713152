type HydraView = {
  '@id': string,
  '@type': string,
};

type HydraMapping = {
  '@type': string,
  'variable': string,
  'property': string,
  'required': boolean,
};

export type Collection<TData> = {
  '@context': string,
  '@id': string,
  '@type': 'hydra:Collection',
  'hydra:totalItems': number,
  'hydra:member': TData[],
  'hydra:view': HydraView,
  'hydra:search': {
    '@type': string,
    'hydra:template': string,
    'hydra:variableRepresentation': string,
    'hydra:mapping': HydraMapping[],
  }
};

export type SearchResult<TData> = {
  matches_count: number,
  total_count: number,
  items: TData[],
};

export type OperatorFiltering = 'STARTS WITH' | 'CONTAINS' | 'DOES NOT CONTAIN' | '=' | 'EMPTY' | 'NOT EMPTY' | 'NOT_EMPTY' | '!=' | '<' | '>' | 'BETWEEN' | 'NOT BETWEEN' | '<=' | '>=' | 'IN' | 'NOT_IN' | 'NOT IN';

export type Filter = {
  field: string,
  operator: OperatorFiltering,
  value: number | number[] | string | string[] | boolean | null,
  context: string[], // a changer le type quand on aura plus d'infos
};

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type Sort = {
  field: string,
  direction: SortDirection,
};

export type Search = {
  'channel': string,
  'locale': string,
  'filters': Filter[],
  'page': number,
  'size': number,
};

export type Response<TData> = {
  '@context': string,
  '@id': string,
  '@type': string,
  rawValues: TData,
};
