import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import { DebitCreditEntity } from 'types/models';
import type { DebitOrCredit } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import DocumentCell from './DocumentCell';
import SubjectCell from './SubjectCell';
import AmountCell from './AmountCell';
import BalanceCell from './BalanceCell';
import StatusDebitCell from './StatusDebitCell';
import StatusCreditCell from './StatusCreditCell';

type RowType = {
  row: { original: DebitOrCredit },
};

const DetailsColumns = (
  ct: TFunction,
  showEditCreditModal: (creditId: number) => void,
  showCreditModal: (creditId: number) => void,
  filterBalanced?: boolean | null,
): Column<DebitOrCredit>[] => ([
  {
    Header: ct('common:date') as string,
    accessor: 'date',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      <div className="CustomerAccountDetails__columns__date">
        {formatIntlDate(row.original.date, 'dayMonthYearShort')}
      </div>
    ),
  },
  {
    Header: ct('common:reference') as string,
    accessor: 'reference',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      <DocumentCell
        data={row.original}
        showCreditModal={showCreditModal}
      />
    ),
  },
  {
    Header: ct('credits:subject') as string,
    accessor: 'subject',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      <SubjectCell data={row.original} />
    ),
  },
  {
    Header: ct('common:amount') as string,
    accessor: 'amount',
    disableSortBy: true,
    Cell: ({ row }: RowType) => !filterBalanced ? (
      <AmountCell data={row.original} withoutTooltip />
    ) : <AmountCell data={row.original} />,
  },
  {
    Header: ct('common:remaining') as string,
    accessor: 'amountNotAssigned',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      <AmountCell data={row.original} withoutTooltip useAmountNotAssigned />
    ),
  },
  {
    Header: ct('common:status') as string,
    accessor: 'status',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      row.original.entity === DebitCreditEntity.DEBIT
        ? <StatusDebitCell data={row.original} />
        : <StatusCreditCell
            data={row.original}
            showEditCreditModal={showEditCreditModal}
            showCreditModal={showCreditModal}
          />
    ),
  },
  {
    Header: ct('common:balance') as string,
    accessor: 'balance',
    disableSortBy: true,
    Cell: ({ row }: RowType) => <BalanceCell data={row.original} />,
  },
]);

export default DetailsColumns;
