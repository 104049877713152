import './index.scss';
import { useModal } from 'react-modal-hook';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { observer } from 'mobx-react';
import { RightsCategory, type ScenarioGroup, type Selector } from 'types/models';
import SelectorFilters from 'stores/FilterStores/SelectorFilters';
import FormSelect from 'components/FormSelect';
import DataTable from 'components/DataTable';
import organizationStore from 'stores/Organization';
import { FetchAllGroupsParams } from 'api/scenarioGroup';
import apiScenarioGroup from 'api/scenarioGroup';
import apiSelectors, { FetchAllParams } from 'api/selectors';
import useFetch from 'hooks/useFetch2';
import useToast from 'components/ToastNotification/useToast';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import ToastNotification from 'components/ToastNotification';
import Misc from 'types/misc';
import SettingsSection from '../Section';
import ModalEditYAML from './ModalEditYAML';
import ModalSelector from './ModalSelector';
import SelectorColumns from './Columns';

const SelectorListing = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = organizationStore.currentOrganization!;
  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    filters: filtering,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = SelectorFilters;

  const filters = useMemo(() => {
    if (filtering?.find((o) => o.name === 'group')) {
      return filtering;
    }
    return [...filtering, { name: 'group', value: 'debt' }];
  }, [filtering]);

  const {
    data: scenarioGroups,
  } = useFetch<FetchAllGroupsParams, ScenarioGroup[]>(
    {
      cacheKey: 'scenarioGroups',
      organizationId: id,
    },
    apiScenarioGroup.all,
    {
      enabled: !!id,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data,
    refetch,
    isLoading,
    error: fetchError,
  } = useFetch<FetchAllParams, Selector[]>(
    {
      cacheKey: 'organizationSelectors',
      organization: id,
      filters,
    },
    apiSelectors.all,
    { retry: false, enabled: !!id },
  );

  const handleSelectGroup = useCallback((_name: string, group: string | null) => {
    addOrUpdateFilters([{ name: 'group', value: group }]);
  }, [addOrUpdateFilters]);

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetch();
  }, [refetch, showToast]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const currentGroup = useMemo(() => {
    const group = filters?.find(({ name }) => name === 'group')?.value;
    if (!group) {
      return undefined;
    }

    return scenarioGroups?.find(({ id: groupId }) => groupId === parseInt(group as string, 10));
  }, [scenarioGroups, filters]);

  const columns = useMemo<Column<Selector>[]>(
    () => SelectorColumns(t, handleActionError, handleActionDone, currentGroup),
    [t, handleActionError, handleActionDone, currentGroup],
  );

  const statusSelectorOptions = useMemo(() => [
    { label: t('selectors:debt'), value: 'debt' },
    ...(scenarioGroups || []).map(({ id: groupId, name }) => ({ label: name, value: groupId!.toString() } as Misc.ValueLabel)),
  ], [scenarioGroups, t]);

  const statusSelector = useMemo(() => {
    if (!scenarioGroups) {
      return null;
    }

    return (
      <FormSelect
        selectOptions={statusSelectorOptions}
        defaultValue={filters?.find(({ name }) => name === 'group')?.value?.toString() ?? 'debt'}
        name="select"
        onSelect={handleSelectGroup}
        withClearButton={false}
      />
    );
  }, [scenarioGroups, statusSelectorOptions, filters, handleSelectGroup]);

  const [showModalCreateSelector, hideModalCreateSelector] = useModal(() => (
    <ModalSelector
      group={currentGroup}
      onClose={hideModalCreateSelector}
      onActionDone={handleActionDone}
      onActionError={handleActionError}
    />
  ), [handleActionDone, handleActionError, currentGroup]);

  const [showModalEditYAML, hideModalEditYAML] = useModal(() => (
    <ModalEditYAML
      onClose={hideModalEditYAML}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, showToast]);

  const openAddSelectorModal = useCallback(() => {
    showModalCreateSelector();
  }, [showModalCreateSelector]);

  const selectorsActions = {
    action:(
      <ButtonWithUserRights
        action="CREATE"
        category={RightsCategory.PREFERENCES_SELECTORS}
        withCaretIcon
        onClick={() => { openAddSelectorModal(); }}
        variant="link"
      >
        {t('selectors:add-selector')}
      </ButtonWithUserRights>
    ),
    secondaryAction:(
      <ButtonWithUserRights
        action="CREATE"
        category={RightsCategory.PREFERENCES_SELECTORS}
        onClick={() => { showModalEditYAML(); }}
        variant="link"
        withCaretIcon
      >
        {t('selectors:yaml-edit')}
      </ButtonWithUserRights>
    ),
  };

  return (
    <div className="Selector">
      <SettingsSection
        className="Selectors__section"
        title={t('selectors:scenario-selectors')}
        action={data?.length === 0 ? selectorsActions.action : <></>}
        secondaryAction={data?.length === 0 ? selectorsActions.secondaryAction : <></>}
      >
        <p className="Selectors__description">{t('selectors:description')}</p>
        <div className="Selectors__listing">
            <DataTable<Selector>
              data={data}
              serverPagination={null}
              filtering={filters}
              columns={columns}
              defaultSortBy={{ id: 'identifier', desc: false }}
              withActions={false}
              error={fetchError}
              fetchData={() => { }}
              isLoading={isLoading}
              onChangeFilters={addOrUpdateFilters}
              onRemoveFilter={removeFilter}
              noDataFoundMessage={t('selectors:no-data')}
              changeFilterMessage={t('common:try-modify-filters').toLowerCase()}
              statusSelector={statusSelector}
              noDataFoundInline
              extraActions={(
                <Fragment>
                  {selectorsActions.action}
                  {selectorsActions.secondaryAction}
                </Fragment>
              )}
            />
        </div>
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>

  );
};

export default observer(SelectorListing);
