import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { Column } from 'react-table';
import config from 'config';
import type Misc from 'types/misc';
import { StepActionPeriodFilter, StepActionTypeFilter } from 'types/models';
import type { StepAction } from 'types/models';
import apiHistorySteps from 'api/historySteps';
import type { FetchActionsDoneAllParams } from 'api/historySteps';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import actionsDoneFiltersStore from 'stores/FilterStores/ActionsDoneFilters';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import DataTable from 'components/DataTable';
import PageList from 'components/PageList';
import LinkSwitch from 'components/LinkSwitch';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import authStore from 'stores/Auth';
import filtersSettings from './Filters/settings';
import ActionsSide from './Side';
import ActionsDoneFilters from './Filters';
import ActionsDoneColumns from './Columns';

const DEFAULT_FILTERS = {
  period: StepActionPeriodFilter.LAST_15_DAYS,
  type: [
    StepActionTypeFilter.TASK_COMPLETED,
    StepActionTypeFilter.REMINDER_ERROR,
    StepActionTypeFilter.TASK_CANCELLED,
    StepActionTypeFilter.REMINDER_CANCELLED,
    StepActionTypeFilter.REMINDER_COMPLETED_AUTO,
    StepActionTypeFilter.REMINDER_COMPLETED_MANUAL,
  ],
};

const ActionsDone = () => {
  const history = useHistory();
  const { currentOrganization, selectedBusinessUnitsIdentifier, attributes } = organizationStore;
  const { type } = currentOrganization!;
  const { ct } = useContextualTranslation(type);
  const { user } = authStore;

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: config.DEFAULT_PAGE_SIZE,
    sort: { id: 'at', desc: false },
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<StepAction>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = actionsDoneFiltersStore;

  useEffect(() => {
    resetAllFilters(DEFAULT_FILTERS);
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: selectedBusinessUnitsIdentifier }];
  }, [filters, selectedBusinessUnitsIdentifier]);

  const {
    data: steps,
    serverPagination,
    error,
    isFetching,
    isLoading,
    refetch,
  } = useFetchPaginated<FetchActionsDoneAllParams, StepAction>(
    {
      cacheKey: 'actionsDone',
      organizationId: currentOrganization?.id,
      params: {
        page: fetchOptions.pageIndex,
        organizationReference: currentOrganization?.reference,
        pageSize: fetchOptions.pageSize,
        locale: user?.locale,
        filtering,
        attributes: attributes.client,
      },
    },
    apiHistorySteps.allDone,
    {
      enabled: !!currentOrganization,
      usePrefetching: false,
    },
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetch();
  }, [showToast, refetch]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const columns = useMemo<Column<StepAction>[]>(() => (
    ActionsDoneColumns(ct, handleActionDone, handleActionError)
  ), [ct, handleActionDone, handleActionError]);

  const handleSwitchList = useCallback((listing: string) => {
    if (listing !== 'actions-future') {
      return;
    }
    const queryFilters = getFiltersQueryString(
      filters,
      ['channel', 'manager', 'search', 'client', 'user'],
    );
    history.push(`/${listing}?${queryFilters}`);
  }, [filters, history]);

  const handleOnExport = useCallback(
    async () => {
      const result = apiHistorySteps.exportDoneUrl({
        organizationReference: currentOrganization?.reference,
        locale: user?.locale,
        filtering,
        attributes: attributes.client,
      });
      if (!result) {
        handleActionError(ct('error:unknown-retry'));
      } else {
        handleActionDone(ct('common:export-success'));
      }
    },
    [
      attributes.client,
      currentOrganization?.reference,
      user?.locale,
      filtering,
      handleActionDone,
      handleActionError,
      ct,
    ],
  );

  const filtersList = useMemo<Misc.FilterDeclarationItem[]>(
    () => {
      return ActionsDoneFilters(ct, filters);
    },
    [ct, filters],
  );

  return (
    <div className="ActionsDone">
      <div className="ActionsDone__content">
        <ActionsSide
          portalID="ActionsDoneSidePortal"
        />
        <PageList
          title={ct('actions:actions-done')}
          className="ActionsDoneContent"
          count={serverPagination?.totalRecords}
          actions={(
            <LinkSwitch
              valueLabels={[
                { value: 'actions-future', label: ct('actions:future') },
                { value: 'actions-done', label: ct('actions:done') },
              ]}
              defaultValue="actions-done"
              onChange={handleSwitchList}
            />
          )}
        >
          <DataTable<StepAction>
            columns={columns}
            serverPagination={serverPagination}
            data={steps}
            defaultSortBy={defaultFetchOptions.sort}
            filtersList={filtersList}
            filtersTitle={ct('actions:modal-filter-title-done')}
            filtersDescription={ct('actions:modal-filter-description')}
            filtering={filters}
            onRemoveFilter={removeFilter}
            onChangeFilters={addOrUpdateFilters}
            fetchData={setFetchOptions}
            isLoading={isFetching || isLoading}
            error={error}
            sidePortal={document.getElementById('ActionsDoneSidePortal')}
            filtersSettings={filtersSettings}
            withNoDataDrawing
            withActions={false}
            onExport={handleOnExport}
            withPortalExportLink
          />
        </PageList>
      </div>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(ActionsDone);
