import type { ChartOptions } from 'chart.js';

const defaultChartOptions: ChartOptions<'line' | 'bar'> = {
  maintainAspectRatio: false,
  animation: {
    duration: 200,
  },
  scales: {
    xAxes: {
      ticks: {
        font: { size: 10 },
        textStrokeColor: '#8c8c8c',
      },
    },
    yAxes: {
      ticks: {
        display: false,
      },
    },
  },
};

export default defaultChartOptions;
