import './index.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  dueAt: string,
  isActive: boolean,
};

const DebitDiffDays = ({ dueAt, isActive }: Props): JSX.Element => {
  const { t } = useTranslation();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const debitDate = new Date(dueAt);
  debitDate.setHours(0, 0, 0, 0);
  const oneDayTime = 1000 * 60 * 60 * 24;
  const diffDays = Math.ceil((today.getTime() - debitDate.getTime()) / oneDayTime);

  const classNames = classnames('DebitDiffDays', {
    'DebitDiffDays--due': diffDays >= 0,
    'DebitDiffDays--not-due': diffDays < 0,
    'DebitDiffDays--inactive': !isActive,
  });

  let title = t('common:due');
  if (diffDays < 0) {
    title = t('common:not-due');
  }

  return (
    <div className={classNames} title={title}>
      ({t('common:days-first-letter')}{diffDays > 0 ? '+' : ''}{diffDays})
    </div>
  );
};

export default DebitDiffDays;
