import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import getRelativeDaysCount from 'utils/getRelativeDaysCount';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import formatIntlDate from 'utils/formatIntlDate';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';

type Props = {
  debitId?: number,
  data: {
    nextSending: string | null,
    promiseToPayAt?: string | null,
    lastSending?: string | null,
    mute?: boolean | null,
  },
};

const ListHistorySummary = ({ debitId, data }: Props): JSX.Element | null => {
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const { nextSending, promiseToPayAt, lastSending, mute } = data;

  const daysCount = useMemo(
    () => getRelativeDaysCount(nextSending),
    [nextSending],
  );

  const nextNumber = useMemo(
    () => (lastSending ? 2 : 1),
    [lastSending],
  );

  const dateNext = useMemo(
    () => formatIntlDate(nextSending, 'dateLong'),
    [nextSending],
  );

  const datePromise = useMemo(
    () => formatIntlDate(promiseToPayAt, 'dateLong'),
    [promiseToPayAt],
  );

  const dateLast = useMemo(
    () => formatIntlDate(lastSending, 'dateLong'),
    [lastSending],
  );

  const nextSend = useMemo(() => {
    if (daysCount > 10) {
      return dateNext;
    }

    if (daysCount < 0) {
      return t('common:day-wait', { count: Math.abs(daysCount) });
    }

    return t('common:in-days', { count: daysCount });
  }, [dateNext, daysCount, t]);

  const classNames = classnames('ListHistorySummary', {
    'ListHistorySummary--isWaiting': nextSending && daysCount < 0,
  });

  if (debitId && !nextSending && !lastSending) {
    return null;
  }

  return (
    <div className={classNames}>
      <Dropdown
        variant="light"
        noIcon
        toggle={(
          <>
            {nextSending && (
              <div className="ListHistorySummary__next-sending">
                {nextSend}
              </div>
            )}
            {(!debitId || (debitId && !nextSending && lastSending)) && (
              <div className="ListHistorySummary__other">
                <Icon name="dots-small" />
              </div>
            )}
          </>
        )}
      >
        <div className="ListHistorySummary__content">
          {nextSending && (
            <div className="ListHistorySummary__content__next-sending">
              <h5 className="ListHistorySummary__content__title">
                {daysCount < 1 ? t('common:waiting') : t('debits:upcoming')}
              </h5>
              <span className="ListHistorySummary__content__item-index">
                {nextNumber}
              </span>
              <span className="ListHistorySummary__content__important-value">
                {dateNext}
              </span>
            </div>
          )}
          {mute && (
            <div className="ListHistorySummary__content__no-next-sending">
              {t('clients:client-muted')}
            </div>
          )}
          {!nextSending && !mute && (
            <div className="ListHistorySummary__content__no-next-sending">
              {debitId
                ? ct('debits:no-upcoming-action-for-this-bill')
                : t('actions:no-planned-action')}
            </div>
          )}
          {promiseToPayAt && (
            <div className="ListHistorySummary__content__promise">
              <h5 className="ListHistorySummary__content__title">
                {t('debits:promise')}
              </h5>
              <span className="ListHistorySummary__content__important-value">
                {datePromise}
              </span>
            </div>
          )}
          {lastSending && (
            <div className="ListHistorySummary__content__past">
              <h5 className="ListHistorySummary__content__title">
                {t('debits:past')}
              </h5>
              <span className="ListHistorySummary__content__item-index">
                1
              </span>
              {dateLast}
            </div>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default observer(ListHistorySummary);
