import './index.scss';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import getI18nChannel from 'utils/getI18nChannel';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import FormSelect from 'components/FormSelect';
import FormGroup from 'components/FormGroup';
import { Channel } from 'types/models';

type Props = {
  onClose(): void,
  onChoose(channel: Channel): void,
};

const ModalChooseReminder = ({ onClose, onChoose }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const { isLoading } = useApiRequest();

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      onChoose(formData.roles as Channel);
      onClose();
    },
    [currentOrganization, onChoose, onClose],
  );

  const channels = useMemo(() => Object.values(Channel).map((channel) => ({
    label: t(getI18nChannel(channel)),
    value: channel,
  })), [t]);

  return (
    <ModalForm
      isOpened
      className="ChooseDunning"
      title={t('models:create-reminder-model')}
      onSave={handleSubmit}
      onCancel={onClose}
      isLoading={isLoading}
      saveText="Suivant"
    >
      <FormGroup label={t('reminders:reminder.select-channel')} className="ChooseDunning__select" mandatory>
        <FormSelect
          name="roles"
          selectOptions={channels}
          defaultValue={channels[0].value}
        />
      </FormGroup>
    </ModalForm>
  );
};

export default observer(ModalChooseReminder);
