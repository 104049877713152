import type { TFunction } from 'i18next';
import { DebitAction } from 'types/models';

const getToggleActionsI18n = (t: TFunction, ct: TFunction, count: number) => ({
  [DebitAction.MUTE]: {
    title: t('debits:actions.mute'),
    confirmText: ct('debits:actions.confirm.bulk-mute', { count }),
    toastText: ct('debits:actions.toast.bulk-mute', { count }),
  },
  [DebitAction.UNMUTE]: {
    title: t('debits:actions.unmute'),
    confirmText: ct('debits:actions.confirm.bulk-unmute', { count }),
    toastText: ct('debits:actions.toast.bulk-unmute', { count }),
  },
  [DebitAction.REMOVE]: {
    title: t('debits:remove'),
    confirmText: ct('debits:actions.confirm.bulk-remove', { count }),
    toastText: ct('debits:actions.toast.bulk-remove', { count }),
  },
  [DebitAction.LITIGATE]: {
    title: t('debits:set-litigate'),
    confirmText: ct('debits:actions.confirm.bulk-set-litigate', { count }),
    toastText: ct('debits:actions.toast.bulk-set-litigate', { count }),
  },
  [DebitAction.CANCEL_LITIGATE]: {
    title: t('debits:cancel-litigate'),
    confirmText: ct('debits:actions.confirm.bulk-cancel-litigate', { count }),
    toastText: ct('debits:actions.toast.bulk-cancel-litigate', { count }),
  },
  [DebitAction.DISABLE]: {
    title: t('common:disable'),
    confirmText: ct('debits:actions.confirm.bulk-disable', { count }),
    toastText: ct('debits:actions.toast.bulk-disable', { count }),
  },
  [DebitAction.ACTIVATE]: {
    title: t('debits:reactivate'),
    confirmText: ct('debits:actions.confirm.bulk-reactivate', { count }),
    toastText: ct('debits:actions.toast.bulk-reactivate', { count }),
  },
});

export default getToggleActionsI18n;
