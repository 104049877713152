import './index.scss';
import { isHistoryActivity, type ClientHistory, HistoryActivity as HistoryActivityType, isStepAction, StepAction } from 'types/models';
import type {} from 'types/models';
import HistoryStep from 'components/HistoryStep';
import HistoryActivity from 'components/HistoryActivity';

type Props = {
  title: string,
  items: ClientHistory[],
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const CustomerHistoryDayList = ({ title, items, onActionDone, onActionError }: Props): JSX.Element => (
  <div className="CustomerHistoryDayList">
    <h3 className="CustomerHistoryDayList__title">
      {title}
    </h3>
    <ul className="CustomerHistoryDayList__list">
      {items.map((historyItem) => {
        const historyActivity = isHistoryActivity(historyItem) ? historyItem as HistoryActivityType : null;
        const stepAction = isStepAction(historyItem) ? historyItem as StepAction : null;
        if (stepAction) {
          return (<HistoryStep
            key={`${stepAction.id}-${stepAction.badgeAlias}`}
            data={stepAction}
            onActionDone={onActionDone}
            onActionError={onActionError}
            showStepActions={false}
          />);
        }
        if (historyActivity) {
          return (<HistoryActivity
            key={`${historyActivity.id}-${historyActivity.type}`}
            data={historyActivity}
            onActionDone={onActionDone}
            onActionError={onActionError}
          />);
        }
        return null;
      })}
    </ul>
  </div>
);

export default CustomerHistoryDayList;
