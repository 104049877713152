import { ImportItemStatus } from 'types/models';

const getI18nReportStatus = (status: ImportItemStatus): string => {
  const i18nStatus = {
    [ImportItemStatus.EXECUTING]: 'reports:status:in-progress',
    [ImportItemStatus.IMPORTED]: 'reports:status.success',
    [ImportItemStatus.COMPLETED]: 'reports:status:finalized',
    [ImportItemStatus.FAILED]: 'reports:status:error',
  };

  return i18nStatus[status];
};

export default getI18nReportStatus;
