import './index.scss';
import { useMemo } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import { HistoryActivityType, type HistoryActivity } from 'types/models';

type Props = {
  historyActivity: HistoryActivity,
};

const StepActivityIcon = ({ historyActivity }: Props): JSX.Element | null => {
  const { type } = historyActivity;

  // TODO Confirmer la logique pour savoir si c'est une bill
  const isBill = useMemo(() => (
    type === HistoryActivityType.DEBIT
  ), [type]);

  const isCredit = useMemo(() => (
    type === HistoryActivityType.CREDIT
  ), [type]);

  const isDebit = useMemo(() => (
    type === HistoryActivityType.DEBIT
  ), [type]);

  if (!isCredit && !isDebit) {
    return null;
  }

  const classNames = classnames('StepActivityIcon', {
    'StepActivityIcon--bill': isBill,
    'StepActivityIcon--debit': isDebit,
    'StepActivityIcon--credit': isCredit,
  });

  return (
    <div className={classNames}>
      <div className="StepActivityIcon__icon">
        {isBill && <Icon name="bill" />}
        {isCredit && <Icon name="credit-card" />}
      </div>
    </div>
  );
};

export default StepActivityIcon;
