import type { ImportFileError } from 'types/errors';

export enum ImportItemType {
  DEBIT = 'debit',
  CLIENT = 'client',
  CONTACT = 'contact',
  CREDIT = 'credit',
  OTHER = 'other',
  INVOICE = 'invoice',
  PLAN = 'plan',
  EXTRA = 'extra',
  APP_INVOICE = 'dunforce_invoice_import',
  APP_CSV = 'dunforce_csv_import',
}

export enum ImportItemFormat {
  PDF = 'pdf',
  OTHER = 'other',
  CSV = 'csv/xlsx',
}

export enum ImportItemStatus {
  EXECUTING = 'EXECUTING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IMPORTED = 'IMPORTED',
}

export enum ImportItemStep {
  VALIDATION = 'validation',
  PARSE = 'invoice_import',
  PROCESS = 'invoice_process',
}

export enum ImportLineStatus {
  RESULT_ERROR = 'RESULT_ERROR',
  RESULT_TO_PROCESS = 'RESULT_TO_PROCESS',
  RESULT_CREATED = 'RESULT_CREATED',
  RESULT_UPDATED = 'RESULT_UPDATED',
  SKIPPED = 'SKIPPED',
}

export enum ImportLineVariant {
  ERROR = 'error',
  WARNING = 'warning',
  IN_PROGRESS = 'in-progress',
  DEFAULT = 'default',
}

export type Import = {
  label: string,
  author: string,
  fileName: string,
  createdAt: string,
  endAt: string,
  startAt: string,
  importType: string,
  nbSteps: number,
  presignedUrl: string,
  downloadable: boolean
  type: ImportItemType,
  status: ImportItemStatus,
  stepExecutions: ImportExecutions[],
  fileExtension: ImportItemFormat,
};

export type ImportExecutions = {
  id: number,
  job: string,
  label: string,
  presignUrl: string,
  downloadable: boolean,
  readCount: number,
  writeCount: number,
  filterCount: number,
  warningCount: number,
  nbErrorsByFile: number,
  nbErrorsByLine: number,
  startedAt: string,
  endedAt: string,
  errors: string[] | string,
  summary?: { [key: string]: string },
  status: ImportItemStatus,
  failures: ImportFileError[],
  warnings: ImportFileError[],
  nbLineDraft: number,
  updateCount: number,
};

export type ImportLineError = {
  field: string,
  format: string,
  message: string,
  possibleValues: string,
  value: string,
};

export type ImportLine = {
  line: number,
  status: ImportLineStatus,
  type: ImportItemType,
  errors: ImportLineError[],
  client: {
    id: number,
    reference: string,
    name: string,
  },
  debit: {
    reference: string,
  },
  payment?: {
    id: number,
    reference: string,
  }
  processedAt: string,
};
