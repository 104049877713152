import type Misc from 'types/misc';

const getContactRoleId = (roleId: string, contactRoles: Misc.IdIdentifierValue[] | null) => {
  if (!contactRoles) {
    return null;
  }

  const foundRole = contactRoles.find((role: Misc.IdentifierValue) => role.identifier === roleId);

  return foundRole?.id ?? null;
};

export default getContactRoleId;
