import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  PieController,
  ArcElement,
  CategoryScale,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Analytics, OrganizationAnalytics } from 'types/models';
import organizationStore from 'stores/Organization';
import Misc from 'types/misc';
import getColorAnalytics from 'utils/getColorAnalytics';
import getI18nAnalytics from 'utils/getI18nAnalytics';
import formatIntlNumber from 'utils/formatIntlNumber';

type Props = {
  data: OrganizationAnalytics['debtDistribution'],
};

interface AnalyticsChartData {
  labels: string[];
  datasets: (Omit<Misc.AnalyticsChartData['datasets'][0], 'type'>)[];
}

ChartJS.register(PieController, ArcElement, CategoryScale, Tooltip, Legend, ChartDataLabels);

const DebtDistributionChart = ({ data }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const currency = organizationStore.currentOrganization!.currency;

  const [chartData, setChartData] = useState<AnalyticsChartData>({ labels: [], datasets: [] });

  useEffect(() => {
    setChartData({
      labels: Object.keys(data).reduce((acc, key) => {
        if (key !== Analytics.AVERAGE_DEBT) {
          acc.push(t(getI18nAnalytics(key as Analytics)));
        }
        return acc;
      }, []),
      datasets: [
        {
          data: Object.values(data).filter((value) => value !== data[Analytics.AVERAGE_DEBT]),
          backgroundColor: Object.keys(data).reduce((acc, key) => {
            if (key !== Analytics.AVERAGE_DEBT) {
              acc.push(t(getColorAnalytics(key as Analytics)));
            }
            return acc;
          }, []),
        },
      ],
    });
  }, [data, t]);

  return (
    <div className="Analysis__chart">
      <div className="Analysis__chart__content Analysis__chart__content--smaller">
        <Pie
          data={chartData}
          options={{
            maintainAspectRatio: false,
            layout: {
              padding: 25,
            },
            plugins: {
              legend: {
                display: true,
                position: 'right',
                labels: {
                  generateLabels(chart) {
                    const legends = chart.data;
                    const averageDebt = formatIntlNumber( data[Analytics.AVERAGE_DEBT], currency );
                    if (legends?.labels?.length && legends?.datasets.length) {
                      const defaults = legends?.labels.map((label, i) => {
                        const meta = chart.getDatasetMeta(0);
                        const style = meta.controller.getStyle(i, true);

                        return {
                          text: `${label}`,
                          fillStyle: style.backgroundColor,
                          strokeStyle: style.borderColor,
                          lineWidth: 0,
                          hidden: !chart.getDataVisibility(i),
                          index: i,
                        };
                      });

                      return [{
                        lineWidth: 0,
                        fillStyle: '#fff',
                        text: `${t(getI18nAnalytics(Analytics.AVERAGE_DEBT))} ${averageDebt}`,
                      }, ...defaults];
                    }
                    return [];
                  },
                },
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return formatIntlNumber( context.parsed, currency );
                  },
                },
              },
              datalabels: {
                display: 'auto',
                anchor: 'end',
                formatter: function (value, ctx) {
                  const sum = ctx.dataset.data.reduce((a:number, b) => a + (b as number), 0);
                  const percentage = (sum ? Math.round((value / sum) * 100) : 0) + '%';
                  const price = formatIntlNumber( value, currency );
                  return `${price}\n${percentage}`;
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default DebtDistributionChart;
