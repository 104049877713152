import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { Column, Row } from 'react-table';
import config from 'config';
import type Misc from 'types/misc';
import type { HistoryStep, StepAction } from 'types/models';
import { StepActionPeriodFilter, StepActionEntity, StepActionTypeFilter } from 'types/models';
import apiHistorySteps from 'api/historySteps';
import type { FetchActionsFutureAllParams } from 'api/historySteps';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import actionsFutureFiltersStore from 'stores/FilterStores/ActionsFutureFilters';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import SmallSelect from 'components/SmallSelect';
import DataTable from 'components/DataTable';
import PageList from 'components/PageList';
import LinkSwitch from 'components/LinkSwitch';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import filtersSettings from './Filters/settings';
import ActionsSide from './Side';
import ActionsFutureFilters from './Filters';
import ActionsFutureColumns from './Columns';
import BulkActions from './Bulk';

type SortByOption = 'due_at' | 'client_total_debt' | 'client_due_debt';

const DEFAULT_FILTERS = {
  period: StepActionPeriodFilter.UNTIL_TODAY,
  type: [
    StepActionTypeFilter.REMINDER_AUTO,
    StepActionTypeFilter.REMINDER_PENDING,
    StepActionTypeFilter.REMINDER_VALIDATED,
    StepActionTypeFilter.TASK_PENDING,
    StepActionTypeFilter.REMINDER_TO_VALIDATE,
    StepActionTypeFilter.REMINDER_TO_COMPLETE,
  ],
};

const ActionsFuture = (): JSX.Element => {
  const history = useHistory();
  const { currentOrganization, selectedBusinessUnitsIdentifier, attributes } = organizationStore;
  const { type } = currentOrganization!;
  const { user } = authStore;
  const { ct } = useContextualTranslation(type);
  const [sortBy, setSortBy] = useState('due_at');

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: config.DEFAULT_PAGE_SIZE,
    sort: { id: 'due_at', desc: true },
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<HistoryStep>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = actionsFutureFiltersStore;

  useEffect(() => {
    resetAllFilters(DEFAULT_FILTERS);
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: selectedBusinessUnitsIdentifier }];
  }, [filters, selectedBusinessUnitsIdentifier]);

  const {
    data: steps,
    serverPagination,
    error,
    isFetching,
    isLoading,
    refetch,
  } = useFetchPaginated<FetchActionsFutureAllParams, StepAction>(
    {
      cacheKey: 'actionsFuture',
      organizationId: currentOrganization?.id,
      params: {
        page: fetchOptions.pageIndex,
        organizationReference: currentOrganization?.reference,
        pageSize: fetchOptions.pageSize,
        locale: user?.locale,
        filtering,
        attributes: attributes.client,
        sort: fetchOptions.sort,
      },
    },
    apiHistorySteps.allFuture,
    { enabled: !!currentOrganization, usePrefetching: false },
  );

  const sortByOptions = useMemo<Misc.ValueLabel[]>(() => [
    { value: 'due_at', label: ct('actions:order-by.at') },
    { value: 'client_total_debt', label: ct('actions:order-by.totalAmount') },
    { value: 'client_due_debt', label: ct('actions:order-by.totalAmountDue') },
  ], [ct]);

  const handleSortChange = useCallback((name: string, newSortBy: SortByOption) => {
    setSortBy(newSortBy);

    setFetchOptions((prevOptions) => ({
      ...prevOptions,
      sort: { id: newSortBy, desc: true },
    }));
  }, []);

  const handleFetchData = useCallback((options: Misc.PaginatedFetchArgs<HistoryStep>) => {
    setFetchOptions({
      ...options,
      sort: { id: sortBy, desc: true },
    });
  }, [sortBy]);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetch();
  }, [showToast, refetch]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const handleActionProcess = useCallback((message: string) => {
    showToast(message, 'process');
  }, [showToast]);

  const columns = useMemo<Column<StepAction>[]>(() => (
    ActionsFutureColumns(ct, handleActionDone, handleActionError)
  ), [ct, handleActionDone, handleActionError]);

  const handleSwitchList = useCallback((listing: string) => {
    if (listing !== 'actions-done') {
      return;
    }
    const queryFilters = getFiltersQueryString(
      filters,
      ['channel', 'manager', 'search', 'client', 'user'],
    );
    history.push(`/${listing}?${queryFilters}`);
  }, [filters, history]);

  const hasActions = useCallback(({ original }: Row<StepAction>) => (
    [StepActionEntity.REMINDER, StepActionEntity.TASK].includes(original.entity ?? '')
    && original.doneAt === null
    && original.validation === null
    && original.cancel === null
  ), []);

  const handleOnExport = useCallback(
    async () => {
      const result = apiHistorySteps.exportFutureUrl({
        organizationReference: currentOrganization?.reference,
        locale: user?.locale,
        filtering,
        attributes: attributes.client,
        sort: fetchOptions.sort,
      });
      if (!result) {
        handleActionError(ct('error:unknown-retry'));
      } else {
        handleActionDone(ct('common:export-success'));
      }
    },
    [
      attributes.client,
      currentOrganization?.reference,
      fetchOptions.sort,
      user?.locale,
      filtering,
      handleActionDone,
      handleActionError,
      ct,
    ],
  );

  const filtersList = useMemo<Misc.FilterDeclarationItem[]>(
    () => {
      return ActionsFutureFilters(ct, filters);
    },
    [ct, filters],
  );

  const classNames = classnames('ActionsFuture', {
    'ActionsFuture--sorted-by-total-amount-due': sortBy === 'totalAmountDue',
  });

  return (
    <div className={classNames}>
      <div className="ActionsFuture__content">
        <ActionsSide
          portalID="ActionsFutureSidePortal"
        />
        <PageList
          title={ct('actions:actions-future')}
          className="ActionsFutureContent"
          count={serverPagination?.totalRecords}
          actions={(
            <LinkSwitch
              valueLabels={[
                { value: 'actions-future', label: ct('actions:future') },
                { value: 'actions-done', label: ct('actions:done') },
              ]}
              defaultValue="actions-future"
              onChange={handleSwitchList}
            />
          )}
        >
          {(!isFetching && !isLoading && !!steps && steps.length > 0) && (
            <div className="ActionsFuture__sorting">
              <SmallSelect
                name="orderBy"
                options={sortByOptions}
                onChange={handleSortChange}
                value={sortBy}
              />
            </div>
          )}
          <DataTable<StepAction>
            columns={columns}
            serverPagination={serverPagination}
            data={steps}
            defaultSortBy={defaultFetchOptions.sort}
            filtersList={filtersList}
            filtersTitle={ct('actions:modal-filter-title-future')}
            filtersDescription={ct('actions:modal-filter-description')}
            fetchData={handleFetchData}
            isLoading={isFetching || isLoading}
            error={error}
            sidePortal={document.getElementById('ActionsFutureSidePortal')}
            filtersSettings={filtersSettings}
            filtering={filters}
            onChangeFilters={addOrUpdateFilters}
            onRemoveFilter={removeFilter}
            hasActions={hasActions}
            withNoDataDrawing
            bulkActions={(
              <BulkActions
                onActionDone={handleActionDone}
                onActionError={handleActionError}
                onActionProcess={handleActionProcess}
                onProcessDone={hideToast}
              />
            )}
            onExport={handleOnExport}
            withPortalExportLink
            idAccessor={['id', 'code', 'entity']}
          />
        </PageList>
      </div>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(ActionsFuture);
