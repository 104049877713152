import './index.scss';
import { useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import type { CurrencyCode } from 'types/models';
import { DebitStatus, DebitAction } from 'types/models';
import currenciesStore from 'stores/Currencies';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import DropdownActions from 'components/DropdownActions';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import config from 'config';
import RowActionsToggle from './Toggle';
import getToggleActionI18n from './getToggleActionI18n';
import RowActionNote from './Note';
import RowActionPromiseToPay from './PromiseToPay';
import RowActionCreditNote from './CreditNote';

type Props = {
  id: number,
  client: number,
  reference: string,
  muted: string | null,
  defaultPromiseToPay: string | null,
  currency: CurrencyCode,
  downloadPath?: string | null,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  status: DebitStatus,
};

const DebitsRowActions = (props: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);

  const {
    id,
    reference,
    client,
    muted,
    defaultPromiseToPay,
    currency,
    downloadPath,
    onActionDone,
    onActionError,
    status,
  } = props;

  const { currencies } = currenciesStore;

  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(false);

  const handleToggleActionDone = useCallback(
    (action: DebitAction) => {
      const { toastText } = getToggleActionI18n(t, ct, reference)[action];
      onActionDone(toastText);
    },
    [t, ct, reference, onActionDone],
  );

  const handleActionError = useCallback(
    (message: string) => {
      onActionError(message);
    },
    [onActionError],
  );

  const currencyModel = useMemo(() => (
    currencies?.find((curr) => curr.code === currency)
  ), [currencies, currency]);

  const otherActions = [];

  if (status === DebitStatus.IN_PROGRESS || status === DebitStatus.LITIGATED) {
    otherActions.push(
      <RowActionCreditNote
        id={id}
        customerId={client}
        reference={reference}
        currency={currencyModel}
        onLoadingChange={setIsDropdownLoading}
        onDone={onActionDone}
      />,
    );
  }

  if (status === DebitStatus.IN_PROGRESS) {
    otherActions.push(
      <RowActionPromiseToPay
        id={id}
        reference={reference}
        defaultValue={defaultPromiseToPay}
        onLoadingChange={setIsDropdownLoading}
        onDone={onActionDone}
      />,
      <RowActionsToggle
        id={id}
        reference={reference}
        muted={muted}
        action={DebitAction.LITIGATE}
        buttonIcon="warning-circle"
        onDone={handleToggleActionDone}
        onError={handleActionError}
      />,
    );
  }

  otherActions.push(
    <div className="DebitsRowActions__separated">
      <RowActionNote
        id={id}
        client={client}
        reference={reference}
        onLoadingChange={setIsDropdownLoading}
        onDone={onActionDone}
      />
    </div>,
  );

  if (downloadPath) {
    otherActions.push(
      <a
        href={`${config.DOWNLOAD_BASE_URL}${downloadPath}`}
        className="DebitsRowActions__download-link"
        target="_blank"
        rel="noopener noreferrer"
        title={ct<string>('common:download')}
      >
        <ActionButton
          title={ct('debits:download-receipt')}
          onClick={() => { }}
          icon={<Icon name="arrow-down" />}
        />
      </a>,
    );
  }

  if (status !== DebitStatus.CANCEL) {
    otherActions.push(
      <RowActionsToggle
        id={id}
        reference={reference}
        muted={muted}
        action={DebitAction.DISABLE}
        buttonVariant="list-danger"
        onDone={handleToggleActionDone}
        onError={handleActionError}
      />,
    );
  }

  if (status === DebitStatus.CANCEL) {
    otherActions.push(
      <RowActionsToggle
        id={id}
        reference={reference}
        muted={muted}
        action={DebitAction.ACTIVATE}
        buttonVariant="list"
        onDone={handleToggleActionDone}
        onError={onActionError}
      />,
    );
    otherActions.push(
      <RowActionsToggle
        id={id}
        reference={reference}
        muted={muted}
        action={DebitAction.REMOVE}
        buttonVariant="list-danger"
        onDone={handleToggleActionDone}
        onError={handleActionError}
      />,
    );
  }

  return (
    <div className="DebitsRowActions">
      {status === DebitStatus.LITIGATED && (
        <RowActionsToggle
          id={id}
          reference={reference}
          muted={muted}
          action={DebitAction.CANCEL_LITIGATE}
          isOnlyIcon
          buttonIcon="undo"
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      <DropdownActions
        isLoading={isDropdownLoading}
        actions={otherActions}
      />
    </div>
  );
};

export default observer(DebitsRowActions);
