import './index.scss';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { Note } from 'types/models';
import type { FetchAllForDebitParams } from 'api/notes';
import apiNotes from 'api/notes';
import useFetch from 'hooks/useFetch2';
import ListNotes from 'components/ListNotes';
import CreateNote from './Create';

type Props = {
  paymentRef: string,
  paymentId: number,
  clientId: number,
  reloadTick: number,
  onDone(title: string, messsage: string): void,
};

const DebitSideNotes = (props: Props): JSX.Element => {
  const { paymentRef, paymentId, clientId, reloadTick, onDone } = props;

  const { data: notes, isLoading, error, refetch } = useFetch<FetchAllForDebitParams, Note[]>(
    {
      cacheKey: 'notesForDebit',
      id: paymentId,
    },
    apiNotes.allForDebit,
    { enabled: !!paymentId },
  );

  const handleOnDone = useCallback((message: string) => {
    refetch();
    onDone(message, '');
  }, [onDone, refetch]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <CreateNote
      onClose={hideEditModal}
      onDone={handleOnDone}
      clientId={clientId}
      paymentId={paymentId}
      paymentRef={paymentRef}
    />
  ), [handleOnDone, paymentId, clientId, paymentRef]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTick]);

  return (
    <div className="DebitSideNotes">
      <ListNotes
        onOpenEditModal={showEditModal}
        notes={notes || []}
        error={error}
        resetError={() => {}}
        isLoading={isLoading}
        maxDisplayed={5}
        canEdit={false}
      />
    </div>
  );
};

export default observer(DebitSideNotes);
