import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';

const ImportsFileHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="ImportsFileHeader">
      <div className="ImportsFileHeader__links">
        <GoBackButton />
      </div>
      <div className="ImportsFileHeader__infos">
        <h1 className="ImportsFileHeader__infos__name">
          {t('imports:imports')}
        </h1>
      </div>
    </div>
  );
};

export default ImportsFileHeader;
