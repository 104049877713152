import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Button from 'components/Button';
import FormCheck from 'components/FormCheck';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import formatIntlDate from 'utils/formatIntlDate';
import apiOrganization from 'api/organization';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import { HealthCheckResponse, JobExecution } from 'types/models';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import BackOfficeSection from '../Section';

const Indexation = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const { lastIndexedAt, reference } = currentOrganization || {};
  const [isLoading, setLoading] = useState<boolean>(false);
  const jobExecutionId = useRef<number>();
  const [lastExecution, setLastExecution] = useState<string | null>(
    lastIndexedAt ? formatIntlDate(lastIndexedAt, 'dateTimeShort') : null,
  );

  const {
    post,
    get,
    error,
    resetError,
  } = useApiRequest();

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const [checkboxes, setCheckboxes] = useState<Record<string, boolean>>({
    client: true,
    contact: true,
    debit: true,
    credit: true,
    step_action: true,
    step_action_history: true,
  });

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const entities = Object.entries(checkboxes)
      .filter(([, isChecked]) => isChecked)
      .map(([entity]) => entity);

    const result = await post<{}>(
      apiOrganization.executeIndexation,
      {
        'organizationCode': reference,
        ...{ entities },
      },
    );

    if (result && result.hasOwnProperty('id')) {
      const id = (result as JobExecution).id;
      jobExecutionId.current = id;
      localStorage.setItem('jobExecutionId', id.toString());
      return;
    }

    if (result && Object.keys(result).length === 0) {
      showToast(t('users:toast.success.indexation-request'), 'success');
      setLastExecution(formatIntlDate((new Date()).toISOString(), 'dateTimeShort'));
    }

    setLoading(false);
  }, [reference, showToast, checkboxes, t, post]);

  useEffect(() => {
    const storedJobId = localStorage.getItem('jobExecutionId');
    if (storedJobId) {
      jobExecutionId.current = parseInt(storedJobId, 10);
    }

    let intervalId: NodeJS.Timeout;
    const reset = () => {
      clearInterval(intervalId);
      jobExecutionId.current = undefined;
      setLoading(false);
      localStorage.removeItem('jobExecutionId');
    };

    const checkSendingStatus = async () => {
      if (!jobExecutionId.current) {
        return;
      }

      setLoading(true);
      const healthCheckResponse = await get<HealthCheckResponse>(
        apiOrganization.periodicTasksJobStatus(jobExecutionId.current),
      );

      if (!healthCheckResponse) {
        reset();
        return;
      }

      const { status, healthCheckTime } = healthCheckResponse;

      switch (status) {
        case 'FAILED':
          showToast(t('users:toast.error.indexation-request'), 'error');
          reset();
          break;
        case 'COMPLETED':
          showToast(t('users:toast.success.indexation-request'), 'success');
          setLastExecution(formatIntlDate(healthCheckTime.date, 'dateHour'));
          reset();
          break;
      }
    };

    // Vérifier le statut toutes les 5 secondes
    intervalId = setInterval(checkSendingStatus, 5000);

    // Cleanup lors du démontage du composant
    return () => { clearInterval(intervalId); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobExecutionId]);

  return (
    <div className="Indexation">
      <BackOfficeSection title={t('users:nav.title.indexation')}>
        {!!error &&
          <div className='Indexation__error'>
            <ErrorMessage error={error} onClose={resetError} />
          </div>
        }
        <p className="Indexation__description">
          {t('users:indexation.description')}
        </p>
        <div className="Indexation__filters">
          {Object.entries(checkboxes).map(([entity, isChecked]) => (
            <div key={entity} className="Indexation__filters__item">
              <FormCheck
                label={t(`users:indexation.feilds.${entity}`)}
                name={entity}
                checked={isChecked}
                onChange={() =>
                  setCheckboxes((prevState) => ({
                    ...prevState,
                    [entity]: !prevState[entity],
                  }))
                }
              />
            </div>
          ))}
        </div>
        <div className="Indexation__actions">
          <Button variant="primary" onClick={handleSubmit}>
            {t('common:validate')}
          </Button>
          <div className="Indexation__actions__date">
            {isLoading && <Loading hasNoText={true} />}
            {(!isLoading && !!lastExecution) &&
              <div className="Indexation__actions__date-execution">
                {`${t('common:task-done-at-date', { date: lastExecution } )}`}
              </div>
            }
          </div>
        </div>
      </BackOfficeSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(Indexation);
