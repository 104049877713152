import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type Misc from 'types/misc';
import apiCurrencies from 'api/currencies';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import type { ModalFormData } from 'components/ModalForm';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Loading from 'components/Loading';
import { RightsCategory } from 'types/models';
import SettingsSection from '../Section';
import CurrentOrganizationContent from './Content';
import CurrentOrganizationModalForm from './ModalForm';

export type CurrentOrganization = {
  companyName: string,
  type: Misc.OrganizationType,
  currency: string | null,
  legalIdentifier: string,
  primaryAddress: string,
  postalCode: string,
  city: string,
  countryCode: string,
};

const SettingsCurrentOrganization = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization, isBusy } = organizationStore;

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const mapFormData = useCallback((rawData: ModalFormData): CurrentOrganization => ({
    companyName: rawData?.name as string,
    legalIdentifier: rawData?.legalId as string,
    currency: rawData.currency ? apiCurrencies.resourceUrl(parseInt(rawData.currency as string)) : null,
    type: rawData?.type as Misc.OrganizationType,
    primaryAddress: rawData?.address as string,
    postalCode: rawData?.postalCode as string,
    city: rawData?.city as string,
    countryCode: rawData?.country as string,
  }), []);

  const [showFodalForm, hideModalForm] = useModal(() => (
    currentOrganization ? (
      <CurrentOrganizationModalForm
        mapFormData={mapFormData}
        onClose={hideModalForm}
        onShowToast={showToast}
      />
    ) : null
  ), [currentOrganization, mapFormData, showToast]);

  return (
    <div className="SettingsCurrentOrganization">
      <SettingsSection
        title={t('organizations:organization')}
        action={(
          <ButtonWithUserRights
            onClick={currentOrganization ? showFodalForm : undefined}
            variant="link"
            action="UPDATE"
            category={RightsCategory.ORGANIZATION}
            withCaretIcon
          >
            {t('common:edit')}
          </ButtonWithUserRights>
        )}
      >
        {isBusy && <Loading />}
        <CurrentOrganizationContent onShowToast={showToast} />
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsCurrentOrganization);
