import { AxiosResponse } from 'axios';
import type {
  EavConstructor,
  Organization,
} from 'types/models';
import requester from 'utils/requester';

/**
 * URL de la ressource d'API pour la création d'un EAV (POST).
 */
const createUrl = 'attributes';

/**
 * Récupère l'URL de la ressource d'API pour modifier l'EAV.
 *
 * @param id L'ID de l'EAV
 * @returns URL du PUT.
 */
const updateUrl = (id: EavConstructor['id']) => `attributes/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour supprimer l'EAV.
 *
 * @param id L'ID de l'EAV
 * @returns URL du DELETE.
 */
const deleteUrl = (id: EavConstructor['id']) => `attributes/${id}`;

/**
 * URL de la ressource d'API pour importer un fichier yaml.
 */
const importUrl = 'attributes/import';

/**
 * Récupère le template yaml avec les données actuelles
 *
 * @returns le template yaml
 */
const getTemplate = async (reference: Organization['reference']): Promise<AxiosResponse<Blob>> => (
  requester.get(`attributes/export?organizations=${reference}`, { responseType: 'blob' })
);

export default {
  createUrl,
  updateUrl,
  deleteUrl,
  importUrl,
  getTemplate,
};
