import './index.scss';
import { useCallback } from 'react';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type ResponseError from 'utils/errors';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';

type Props = {
  email: string,
  error: ResponseError | null,
  cooldown: number,
  onChange(newEmail: string): void,
  onSubmit(): void,
  isLoading?: boolean,
  disabled?: boolean,
};

const ConfirmResendForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    onSubmit,
    isLoading = false,
    error,
    onChange,
    disabled,
    email,
    cooldown,
  } = props;

  const handleSubmit = useCallback((e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit();
  }, [isLoading, onSubmit]);

  return (
    <form className="ConfirmResendForm" onSubmit={handleSubmit}>
      <FormGroup controlId="confirmResendEmail" label={t('common:email')}>
        <FormControl
          type="email"
          id="confirmResendEmail"
          placeholder={t('login:enter-email')}
          value={email}
          onChange={onChange}
          isInvalid={!!error}
        />
      </FormGroup>
      {!!error && (
        <h4 className="ConfirmResendForm__error">
          {error.message || t('errors:unknown-retry')}
        </h4>
      )}
      {disabled && (
        <p className="ConfirmResendForm__info">
          {t('confirm-email:wait-before-sending', { time: cooldown })}
        </p>
      )}
      <Button
        variant="primary"
        type="submit"
        className="ConfirmResendForm__submit"
        disabled={disabled}
      >
        {isLoading && <Loading />}
        {!isLoading && t('confirm-email:send-again')}
      </Button>
    </form>
  );
};

export default ConfirmResendForm;
