import './index.scss';
import PublicLayout from 'layout/PublicLayout';
import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import Alert from 'components/Alert';
import authStore from 'stores/Auth';
import LoginForm from './Form';

type LoginRouterState = {
  state: {
    passwordChanged?: boolean,
    confirmedEmail?: boolean,
    error?: boolean,
  }
};

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const {
    email: emailInstantLogin,
    password: passwordInstantLogin,
  } = queryString.parse(location.search);
  const { state: routerState } = location as LoginRouterState;
  const { passwordChanged, confirmedEmail, error: routerError } = routerState || false;

  const { isLogged, isBusy, error } = authStore;

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleSubmit = (email: string, password: string) => {
    authStore.login(email, password);
  };

  useEffect(() => {
    if (isBusy) {
      return;
    }

    const email = Array.isArray(emailInstantLogin) ? emailInstantLogin[0] : emailInstantLogin;
    const password = Array.isArray(passwordInstantLogin) ? passwordInstantLogin[0] : passwordInstantLogin;
    if (!email || !password) {
      return;
    }

    handleSubmit(email, password);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (passwordChanged) {
      showToast(t('login:modified-password'), 'success');
    }
    if (confirmedEmail) {
      showToast(t('login:confirmed-email'), 'success');
    }
    if (routerError) {
      showToast(t('errors:unknown-retry'), 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedEmail, passwordChanged, routerError]);

  useEffect(() => {
    if (isLogged) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, error]);

  return (
    <Fragment>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
      <PublicLayout withLanguageSelection>
        <div className="Login">
          {error && (
            <Alert variant="error">
              <div className="Login__error">
                <div className="Login__error__message">{t('login:error-connect')}</div>
                <div className="Login__error__forget-password">
                  {t('login:need-get-password.part1')}{' '}
                  <Link to="/forgotten-password">{t('login:need-get-password.part2')}</Link>
                </div>
              </div>
            </Alert>
          )}
          <div className="Login__form">
            <h2 className="Login__title">{t('login:title')}</h2>
            <LoginForm
              onSubmit={handleSubmit}
              isLoading={isBusy}
              isInvalid={error !== null && error.code !== 401 && error.code !== 403}
            />
          </div>
        </div>
      </PublicLayout>
    </Fragment>
  );
};

export default observer(Login);
