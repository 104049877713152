import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import type { SelectSearchOption } from 'react-select-search';
import type Misc from 'types/misc';
import type { FetchAllParams } from 'api/selectOptions';
import apiSelectOptions from 'api/selectOptions';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch2';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { DefaultValue, getDefaultValue } from './utils';

export type Props = {
  name: string,
  onSelect?: (name: string, newValue: string | null) => void,
  placeholder?: DefaultTFuncReturn,
  flyingLabel?: DefaultTFuncReturn,
  defaultValue?: DefaultValue,
  value?: number | string | string[] | null,
  disabled?: boolean,
  selectOptions?: Readonly<Misc.ValueLabel[]>,
  fetchEntity?: string,
  fetchEntityQuerystring?: string,
  hasFetchOrganizationPrefix?: boolean,
  entityValue?: 'id' | 'reference' | 'identifier',
  entityNameField?: 'id' | 'reference' | 'name',
  className?: string,
  isMultiple?: boolean,
  isAsync?: boolean,
  isInvalid?: boolean,
  addToFetchedOptions?: Misc.ValueLabel[],
  withClearButton?: boolean,
  searchable?: boolean,
  onItemFirstOption?(firstOption?: string | null | number): void,
};

const getPropOptions = (selectOptions: Readonly<Misc.ValueLabel[]> | undefined) => {
  if (!selectOptions) {
    return null;
  }
  const newOptions = selectOptions.map(
    ({ label, value: optionValue }) => ({ name: label, value: `${optionValue}` }),
  );
  return newOptions;
};

const FormSelect = (props: Props): JSX.Element => {
  const {
    name,
    onSelect,
    placeholder,
    flyingLabel,
    defaultValue,
    disabled = false,
    value: controlValue,
    selectOptions,
    fetchEntity,
    fetchEntityQuerystring = '',
    hasFetchOrganizationPrefix = true,
    entityValue = 'id',
    entityNameField = 'name',
    className,
    isMultiple = false,
    isAsync = false,
    isInvalid = false,
    addToFetchedOptions,
    withClearButton = true,
    searchable = true,
    onItemFirstOption,
  } = props;
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState<number | string | string[]>('');
  const [cleared, setCleared] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectSearchOption[] | null>(
    getPropOptions(selectOptions),
  );
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const isSearchable = useMemo(() => (
    (searchable && (options && options.length > 3)) || false
  ), [options, searchable]);

  const { isLoading, data } = useFetch<FetchAllParams, Misc.IdRefName[]>(
    {
      cacheKey: 'selectOptions',
      organization: currentOrganization?.id,
      options: {
        isAsync,
        fetchEntity,
        hasFetchOrganizationPrefix,
        fetchEntityQuerystring,
      },
    },
    apiSelectOptions.all,
    {
      enabled: !!(isAsync && fetchEntity),
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (onItemFirstOption && selectOptions) {
      onItemFirstOption(selectOptions?.[0]?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions]);

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }
    if ((!data || data.length === 0) && !isLoading && !options) {
      setOptions([{ name: '', value: '', disabled: true }]);
    }

    if (!!data) {
      const optionsToSet = data.map((entry) => ({
        name: `${entry[entityNameField]}`,
        value: `${entry[entityValue]}`,
      }));
      if (addToFetchedOptions) {
        optionsToSet.push(...addToFetchedOptions.map(
          ({ value: valueToSet, label }) => ({
            name: label,
            value: typeof valueToSet === 'number' ? valueToSet.toString() : valueToSet,
          }),
        ));
      }
      setOptions(optionsToSet);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, entityNameField, entityValue, isLoading]);

  useEffect(() => {
    if (defaultValue && !value) {
      const initValue = getDefaultValue(defaultValue, options || []);
      setValue(initValue);
      // if (onSelect) {
      //   onSelect(name, Array.isArray(initValue) ? initValue.join(',') : initValue);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  useEffect(() => {
    if (controlValue !== undefined && value !== controlValue) {
      if (cleared) {
        setCleared(false);
      }
      setValue(controlValue === null ? '' : controlValue);
    }
  }, [value, controlValue, cleared]);

  useEffect(() => {
    if (!isAsync || !fetchEntity) {
      setOptions(getPropOptions(selectOptions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEntity, selectOptions, currentOrganization]);

  const handleChange = useCallback(
    (selectedValue: string | string[]) => {
      setValue(selectedValue);
      const newValue = (Array.isArray(selectedValue)) ? selectedValue.join(',') : selectedValue;

      if (inputRef?.current) {
        // - Ceci permet de déclencher un onChange au niveau du <form> parent
        const inputValSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        inputValSetter?.call(inputRef.current, newValue);
        inputRef.current.dispatchEvent(
          new Event('change', { bubbles: true }),
        );
      }

      if (onSelect) {
        onSelect(name, newValue);
      }
    },
    [name, onSelect],
  );

  const handleClear = useCallback(
    () => {
      setCleared(true);
      setIsFocus(false);
      setValue('');
      if (onSelect) {
        onSelect(name, null);
      }
    },
    [name, onSelect],
  );

  const classNames = classnames('FormSelect', className, {
    'FormSelect--has-value': withClearButton && value,
    'FormSelect--is-loading': isLoading && isAsync,
    'FormSelect--is-invalid': isInvalid,
    'FormSelect--with-flying-label-opened': isFocus,
  });

  return (
    <div
      className={classNames}
      onFocus={() => { setIsFocus(true); }}
      onBlur={() => { setIsFocus(false); }}
    >
      {options === null && (
        <Loading />
      )}
      {options !== null && (
        <SelectSearch
          onChange={handleChange}
          disabled={disabled}
          value={value}
          options={options}
          search={isSearchable}
          placeholder={placeholder as string}
          multiple={isMultiple}
          printOptions="on-focus"
          filterOptions={fuzzySearch}
        />
      )}
      {isSearchable && flyingLabel && (
        <div className="FormSelect__flyingLabel">{flyingLabel}</div>
      )}
      <input
        type="text"
        value={value}
        name={name}
        ref={inputRef}
        readOnly
        className="FormSelect__hidden-field"
      />
      {withClearButton && value && (
        <Button
          variant="light"
          onClick={handleClear}
          className="FormSelect__clear"
        >
          <Icon name="close-small" />
        </Button>
      )}
    </div>
  );
};

export default observer(FormSelect);
