import type { Organization, User } from 'types/models';
import type { Collection } from 'types/api';
import requester from 'utils/requester';

export type FetchAllParams = { organization: Organization['reference'] | undefined };
export type FetchAnswerParams = { token: string };

/**
 * Récupère une liste des collaborators.
 *
 * @param params Paramètres de la requête (organizations.identifier).
 * @returns Une collection de collaborators.
 */
const all = async ({ organization }: FetchAllParams): Promise<Collection<User>> => {
  if ( !organization) {
    throw new Error('FetchAllCollaborators: Missing organization identifier.');
  }

  const queryData = new URLSearchParams();
  queryData.append('organizations.identifier', organization);

  const { data } = await requester.get<Collection<User>>(
    `users?${queryData.toString()}`,
  );
  return data;
};

/**
 * Récupère l'URL de la ressource d'API pour supprimer le collaborateur.
 *
 * @param id L'ID du collaborateur
 * @returns URL du DELETE.
 */
const deleteUrl = (id: User['id']) => `users/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour modifier le collaborateur.
 *
 * @param id L'ID du collaborateur
 * @returns URL du PUT.
 */
const updateUrl = (id: User['id']) => `users/${id}`;

/**
 * Récupère la réponse à une invitation pour un collaborateur.
 *
 * @param params Paramètres de la requête (token).
 * @returns les données de la réponse (Answer).
 */
const fetchAnswer = async ({ token }: FetchAnswerParams): Promise<string> => {
  if ( !token) {
    throw new Error('fetchAnswer: Missing token.');
  }

  const queryData = new URLSearchParams();
  queryData.append('token', token);

  const { data } = await requester.get<string>(
    `invitations/validate?${queryData.toString()}`,
  );
  return data;
};

/**
 * URL de la ressource d'API pour la création d'une réponse à une invitation d'un collaborateur.
 */
const createAnswerUrl = () => 'invitations/confirm';

export default { fetchAnswer, createAnswerUrl, deleteUrl, updateUrl, all };
