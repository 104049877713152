import './index.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import FormControl from 'components/FormControl';
import type { Props as FormControlProps } from 'components/FormControl';

type Props = FormControlProps;

const FormSMSContent = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { value } = props;
  const characterCount = (value as string)?.length ?? 0;
  const SMSCount = Math.ceil(characterCount / 160);

  return (
    <div className="FormSMSContent">
      <FormControl {...props} type="textarea" />
      <div className='FormSMSContent__characters'>
        {t('reminders:sms-characters.count', { count: characterCount })}
        {!!SMSCount && SMSCount > 1 && (
          <Fragment>
            , <span className='FormSMSContent__characters__bill'>
              {t('reminders:sms-characters.bill', { count: SMSCount })}
            </span>
          </Fragment>
        )}
      </div>
    </div>
  );
};
export default observer(FormSMSContent);
