import { useState, useCallback, useMemo, useEffect, Fragment } from 'react';
import { DebitAction, DebitStatus } from 'types/models';
import type { Debit } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import apiDebits from 'api/debits';
import type { ButtonVariant } from 'components/Button';
import getToggleActionsI18n from '../getToggleActionI18n';
import UnMuted from '../UnMuted';

type ButtonIcon =
  | 'bell-muted'
  | 'bell'
  | 'check-circle'
  | 'warning-circle'
  | 'undo';

type Props = {
  id: Debit['id'],
  reference: Debit['reference'],
  muted: string | null,
  action: DebitAction,
  buttonVariant?: ButtonVariant,
  isOnlyIcon?: boolean,
  buttonIcon?: ButtonIcon | null,
  onDone(action: DebitAction): void,
  onError(message: string): void,
};

const DebitsRowActionsToggle = (props: Props): JSX.Element => {
  const {
    id,
    reference,
    muted,
    action,
    buttonVariant = 'list',
    isOnlyIcon = false,
    buttonIcon = null,
    onDone,
    onError,
  } = props;

  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const [isShowUnmutedModal, setIsShowUnmutedModal] = useState(false);
  const isMountedRef = useIsMountedRef();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const {
    put,
    remove,
    error,
    isLoading,
  } = useApiRequest();

  const actionI18n = useMemo(
    () => getToggleActionsI18n(t, ct, reference)[action],
    [t, ct, reference, action],
  );

  const handleClickAction = useCallback(() => {
    const { title, confirmText: text } = actionI18n;
    if (action === DebitAction.UNMUTE) {
      setIsShowUnmutedModal(true);
      return;
    }

    showConfirm(title, text);
  }, [actionI18n, showConfirm, action]);

  const getPayload = (actionToSubmit: DebitAction) => {
    switch (actionToSubmit) {
      case DebitAction.ACTIVATE:
        return { status: DebitStatus.IN_PROGRESS };
      case DebitAction.DISABLE:
        return { status: DebitStatus.CANCEL };
      case DebitAction.LITIGATE:
        return { status: DebitStatus.LITIGATED };
      case DebitAction.CANCEL_LITIGATE:
        return { status: DebitStatus.IN_PROGRESS };
      case DebitAction.MUTE:
        return { muted: true };
      case DebitAction.UNMUTE:
        return { muted: false };
    }
  };

  const isUpdateStatus = (actionToSubmit: DebitAction) => !(actionToSubmit === DebitAction.MUTE || actionToSubmit === DebitAction.UNMUTE);

  const doActionPut = useCallback(async () => {
    const updateUrl = isUpdateStatus(action) ? apiDebits.updateStatusUrl(id) : apiDebits.updateUrl(id);
    const payload = getPayload(action);
    const result = await put<Debit>(updateUrl, payload);

    if (result && isMountedRef.current) {
      onDone(action);
    }
  }, [action, id, put, isMountedRef, onDone]);

  const doActionDelete = useCallback(async () => {
    const response = await remove(apiDebits.deleteUrl(id));
    if (response !== null && isMountedRef.current) {
      onDone(DebitAction.REMOVE);
    }
  }, [id, remove, isMountedRef, onDone]);

  const handleSubmit = useCallback(() => {
    hideConfirm();
    if (action === DebitAction.REMOVE) {
      doActionDelete();
    } else {
      doActionPut();
    }
  }, [hideConfirm, action, doActionPut, doActionDelete]);

  const handleClose = () => {
    if (isMountedRef.current) {
      setIsShowUnmutedModal(false);
    }
  };

  useEffect(() => {
    if (error) { onError(error.message || t('errors:unknown-retry')); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Fragment>
      <Button
        variant={buttonVariant}
        onClick={handleClickAction}
        title={isOnlyIcon ? actionI18n.title : undefined}
        className="RowActionsToggle"
        disabled={isLoading}
      >
        {isLoading && <Loading hasNoText />}
        {(!isLoading && buttonIcon) && <Icon name={buttonIcon} />}
        {!isOnlyIcon && actionI18n.title}
      </Button>
      <UnMuted
        onDone={onDone}
        onClose={handleClose}
        isShow={isShowUnmutedModal}
        title={actionI18n.title}
        text={actionI18n.confirmText}
        reference={reference}
        id={id}
        mutedAt={muted}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={reference}
        isShow={isConfirmShowed}
        onConfirm={handleSubmit}
        onCancel={hideConfirm}
        variant={(action === DebitAction.REMOVE || action === DebitAction.DISABLE) ? 'danger' : 'primary'}
        confirmButtonText={actionI18n.title}
        helpHeaderText={
          (action === DebitAction.MUTE)
            ? t('debits:actions.confirm.mute-group-tooltip')
            : undefined
        }
      />
    </Fragment>
  );
};

export default DebitsRowActionsToggle;
