import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { FetchStatsParams } from 'api/organization';
import type { OrganizationAnalytics } from 'types/models';
import { AnalyticsType } from 'types/models';
import apiOrganization from 'api/organization';
import useFetch from 'hooks/useFetch2';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading';
import GraphSection from '../GraphSection';
import NoDataGraph from '../NoDataGraph';
import PaymentDelayChart from './PaymentDelayChart';
import DebtDistributionChart from './DebtDistribution';

const Risk = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization, selectedBusinessUnitsIdentifier } = organizationStore;

  const filtering = useMemo(() => {
    const type = { name: 'type', value: AnalyticsType.RISK };
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return [type];
    }
    return [
      { name: 'businessUnitCodes', value: selectedBusinessUnitsIdentifier }, type,
    ];
  }, [selectedBusinessUnitsIdentifier]);

  const {
    data,
    isLoading,
  } = useFetch<FetchStatsParams, OrganizationAnalytics>(
    {
      cacheKey: 'activityStat',
      id: currentOrganization?.id,
      filtering,
    },
    apiOrganization.getAnalytics,
    { enabled: !!currentOrganization?.id },
  );

  return (
    isLoading ? <Loading /> :
    <>
      <GraphSection
        title={t('analysis:risk.paymentDelay.title')}
        description={t('analysis:risk.paymentDelay.description')}
      >
        {data?.paymentDelay && (
          <PaymentDelayChart data={data.paymentDelay} />
        )}
        {!data && (
          <NoDataGraph />
        )}
      </GraphSection>
      <GraphSection
        title={t('analysis:risk.debtDistribution.title')}
        description={t('analysis:risk.debtDistribution.description')}
      >
        {data?.debtDistribution && (
          <DebtDistributionChart data={data.debtDistribution} />
        )}
        {!data && (
          <NoDataGraph />
        )}
      </GraphSection>
    </>
  );
};

export default observer(Risk);
