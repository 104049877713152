import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  filePath?: string,
}

const ImportsHistoryRowActions: React.FC<Props> = ({ filePath }) => {
  const { t } = useTranslation();

  return ( filePath &&
    <div className="ImportsHistoryRowActions">
      <Link
        to={{ pathname: filePath }}
        target="_blank"
        className="Button Button--primary ImportsHistoryRowActions__download-link"
        >
        {t('imports:download')}
      </Link>
    </div>
  );
};

export default observer(ImportsHistoryRowActions);
