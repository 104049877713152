const dateFormatIso = (date: Date): string => {
  const month = date.getMonth() + 1;
  const twoDigitsMonth = month < 10 ? `0${month}` : month;

  const day = date.getDate();
  const twoDigitsDay = day < 10 ? `0${day}` : day;

  return `${date.getFullYear()}-${twoDigitsMonth}-${twoDigitsDay}`;
};

export default dateFormatIso;
