import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import WalletItem from './Item';

const OrganizationItemWallet = (): JSX.Element => {
  const { businessUnits, selectedBusinessUnits } = organizationStore;
  const { user } = authStore;

  const isSelected = useCallback((walletId: number) => (
    selectedBusinessUnits.includes(walletId)
  ), [selectedBusinessUnits]);

  const filteredBusinessUnits = useMemo(() => (
    businessUnits.filter(({ identifier }) =>
      user?.businessUnits.some((bu) => bu.identifier === identifier),
    )
  ), [businessUnits, user]);

  return (
    <div className="OrganizationItemWallet">
      {filteredBusinessUnits.map((categoryValue) => (
        <WalletItem
          key={categoryValue.id}
          data={categoryValue}
          isSelected={isSelected(categoryValue.id)}
        />
      ))}
    </div>
  );
};

export default observer(OrganizationItemWallet);
