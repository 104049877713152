import { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import { Channel, TemplateBlocks } from 'types/models';
import { RightsCategory } from 'types/models';
import type { FetchAllParams } from 'api/template-blocks';
import apiTemplateBlocks from 'api/template-blocks';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch2';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import useToast from 'components/ToastNotification/useToast';
import SettingsSection from '../Section';
import ModalTask from './ModalTask';
import ModalChooseDunning from './ModalChooseReminder';
import ModalReminder from './ModalReminder';
import Action from './Action';
import ModalEditYAML from './ModalEditYAML';

const SettingsTemplateBlocks = (): JSX.Element => {
  const { type, id } = organizationStore.currentOrganization!;
  const { ct } = useContextualTranslation(type);

  const { data, refetch } = useFetch<FetchAllParams, TemplateBlocks | null>(
    { cacheKey: 'templateBlocks', organization: id },
    apiTemplateBlocks.all,
    { enabled: !!id },
  );

  const [channel, setChannel] = useState<Channel>(Channel.SMS);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetch();
  }, [refetch, showToast]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const [showModalReminder, hideModalReminder] = useModal(() => (
    <ModalReminder
      listData={data?.reminders}
      onDone={handleActionDone}
      onError={handleActionError}
      channel={channel}
      onClose={hideModalReminder}
      id={data?.id}
      indexData={0}
    />
  ), [channel, data?.id, data?.reminders, handleActionDone, handleActionError]);

  const handleChannelChosen = useCallback((newChannel: Channel) => {
    setChannel(newChannel);
    showModalReminder();
  }, [showModalReminder]);

  const [showModalChooseDunning, hideModalChooseDunning] = useModal(() => (
    <ModalChooseDunning
      onClose={hideModalChooseDunning}
      onChoose={handleChannelChosen}
    />
  ), [handleChannelChosen]);

  const [showModalTask, hideModalTask] = useModal(() => (
    <ModalTask
      id={data?.id}
      listData={data?.tasks}
      indexData={0}
      onClose={hideModalTask}
      onDone={handleActionDone}
      onError={handleActionError}
    />
  ), [data, handleActionDone, handleActionError]);

  const [showYAMLModal, hideYAMLModal] = useModal(() => (
    <ModalEditYAML
      onClose={hideYAMLModal}
      onActionDone={handleActionDone}
      onActionError={handleActionError}
    />
  ), [handleActionDone, handleActionError]);

  return (
    <div className="SettingsTemplateBlocks">
      <SettingsSection
        title={ct('models:reminders-models')}
        action={(
          <ButtonWithUserRights
            action="CREATE"
            category={RightsCategory.PREFERENCES_MODELS}
            onClick={showModalChooseDunning}
            variant="link"
            withCaretIcon
          >
            {ct('models:add-model')}
          </ButtonWithUserRights>
        )}
        secondaryAction={(
          <ButtonWithUserRights
            action="CREATE"
            category={RightsCategory.PREFERENCES_MODELS}
            onClick={showYAMLModal}
            variant="link"
            withCaretIcon
          >
            {ct('models:yaml-edit')}
          </ButtonWithUserRights>
        )}
      >
        {data?.reminders.map((action, index) => (
          <Action
            id={data?.id}
            indexData={index}
            listActions={data.reminders}
            action={action}
            key={`${action.identifier}${index}`}
            type="reminder"
            onActionDone={handleActionDone}
            onActionError={handleActionError}
          />
        ))}
      </SettingsSection>
      <SettingsSection
        title={ct('models:tasks-models')}
        action={(
          <ButtonWithUserRights
            action="CREATE"
            category={RightsCategory.PREFERENCES_MODELS}
            onClick={showModalTask}
            variant="link"
            withCaretIcon
          >
            {ct('models:add-model')}
          </ButtonWithUserRights>
        )}
      >
        {data?.tasks.map((action, index) => (
          <Action
            id={data?.id}
            indexData={index}
            listActions={data.tasks}
            action={action}
            key={`${action.identifier}${index}`}
            type="task"
            onActionDone={handleActionDone}
            onActionError={handleActionError}
          />
        ))}
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsTemplateBlocks);
