import './index.scss';
import { useState, useCallback } from 'react';
import type Misc from 'types/misc';
import type { Client, Note } from 'types/models';
import type { ValidationErrors } from 'types/errors';
import useApiRequest from 'hooks/useApiRequest';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import type { ModalFormData } from 'components/ModalForm';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import ModalForm from 'components/ModalForm';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import FormControl from 'components/FormControl';
import ErrorMessage from 'components/ErrorMessage';
import apiNotes from 'api/notes';
import apiClients from 'api/clients';
import apiUsers from 'api/users';
import apiDebits from 'api/debits';

interface Props {
  show: boolean,
  customer: Client | Misc.IdRef | null,
  editData?: Note | null,
  onDone(title: string, message: string): void,
  onClose(): void,
}

const CustomerEditNote = (props: Props): JSX.Element => {
  const { show, customer, editData, onDone, onClose } = props;
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const { put, post, remove, error, isLoading } = useApiRequest();
  const { user } = authStore;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    setValidationErrors(null);
    if (!formData || !customer) {
      return;
    }

    let { message } = formData;
    message = (message as string).trim();
    const debit = formData.debits ? apiDebits.resourceUrl(Number.parseInt(formData.debits as string, 10)) : null;

    if (!message) {
      setValidationErrors({
        message: {
          code: 2,
          message: t('errors.validation.required-field'),
        },
      });
      return;
    }

    let result;
    if (editData) {
      result = await put<Note>(apiNotes.updateUrl(editData.id), { comment: message, debit });
    } else {
      result = await post<Note>(apiNotes.createUrl, {
        client: apiClients.resourceUrl(customer.id),
        user: apiUsers.resourceUrl(user!.id),
        comment: message,
        type: 'private',
        debit,
      });
    }

    if (!result) {
      return;
    }

    onDone(
      editData ? t('notes:toast.modified') : t('notes:toast.created'),
      editData ? t('notes:toast.modified') : t('notes:toast.created'),
    );
    onClose();
  }, [customer, user, editData, put, post, onDone, t, onClose]);

  const handleConfirmDelete = useCallback(() => {
    showConfirm(
      t('notes:delete'),
      t('notes:confirm-delete'),
    );
  }, [showConfirm, t]);

  const handleSubmitDelete = useCallback(async () => {
    if (!editData || !customer) {
      return;
    }

    const result = await remove(apiNotes.deleteUrl(editData.id));
    if (result === null) {
      return;
    }

    onDone(t('notes:toast.deleted'), t('notes:toast.deleted'));
    onClose();
  }, [remove, editData, customer, onDone, onClose, t]);

  return (
    <ModalForm
      className="CustomerEditNote"
      isOpened={show}
      onSave={handleSubmit}
      onCancel={onClose}
      title={editData ? t('notes:edit') : t('notes:create')}
      isLoading={isLoading}
      headerActions={editData ? (
        <Button variant="link-danger" onClick={handleConfirmDelete}>
          {t('notes:delete')}
        </Button>
      ) : undefined}
    >
      <FormGroup label={t('notes:write')} mandatory error={validationErrors?.message}>
        <FormControl
          type="textarea"
          name="message"
          defaultValue={editData?.content || ''}
          size={6}
        />
      </FormGroup>
      {customer && (
        <div className="CustomerEditNote__pay-request">
          <FormSelect
            name="debits"
            defaultValue={editData?.debit?.id}
            placeholder={ct('notes:associate-to-pay-request')}
            flyingLabel={ct('notes:associate-to-pay-request')}
            fetchEntity={apiDebits.allForCustomerUrl(customer.id)}
            fetchEntityQuerystring="filters[lite]=1"
            hasFetchOrganizationPrefix={false}
            entityNameField="id"
            isAsync
          />
        </div>
      )}
      {error && <ErrorMessage error={error} />}
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('notes:delete')}
      />
    </ModalForm>
  );
};

export default CustomerEditNote;
