import './index.scss';
import { useCallback, useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import Button from 'components/Button';
import Icon from 'components/Icon';

type Props = {
  pageIndex: number,
  pageSize: number,
  canPreviousPage: boolean,
  canNextPage: boolean,
  pageCount: number,
  totalRecords: number,
  nextPage(): void,
  previousPage(): void,
  onChangePageSize(size: number): void,
  onGotoPage(updater: number | ((pageIndex: number) => number)): void,
};

const Pagination = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    pageIndex,
    pageSize = Config.DEFAULT_PAGE_SIZE,
    canPreviousPage,
    canNextPage,
    pageCount,
    totalRecords,
    nextPage,
    previousPage,
    onChangePageSize,
    onGotoPage,
  } = props;

  const [pageSizeIndex, setPageSizeIndex] = useState<number>(
    Config.PAGE_SIZES.indexOf(pageSize),
  );

  const handleChangePageSize = useCallback(
    (direction: 1 | -1) => () => {
      const newIndex = pageSizeIndex + direction;
      if (Config.PAGE_SIZES[newIndex] === undefined) {
        return;
      }
      setPageSizeIndex(newIndex);
      onChangePageSize(Config.PAGE_SIZES[newIndex]);
      onGotoPage(0);
    },
    [pageSizeIndex, onChangePageSize, onGotoPage],
  );

  const minPageSizeReached = useMemo(() => (
    pageSizeIndex === 0
  ), [pageSizeIndex]);

  const maxPageSizeReached = useMemo(() => (
    pageSizeIndex >= Config.PAGE_SIZES.length - 1
  ), [pageSizeIndex]);

  const firstLineNumber = pageIndex * pageSize + 1;

  return (
    <div className="Pagination">
      <div className="Pagination__lines">
        {t('common:display')}
        <Button
          variant="light"
          onClick={handleChangePageSize(-1)}
          className="Pagination__button Pagination__button--first"
          disabled={minPageSizeReached}
        >
          <Icon name="chevron-left" />
        </Button>
        <span className="Pagination__value">
          {Config.PAGE_SIZES[pageSizeIndex]}
        </span>
        <Button
          variant="light"
          onClick={handleChangePageSize(1)}
          className="Pagination__button Pagination__button--last"
          disabled={maxPageSizeReached}
        >
          <Icon name="chevron-right" />
        </Button>
        {t('common:lines')}
      </div>
      <div className="Pagination__pages">
        <Button
          variant="light"
          onClick={() => onGotoPage(0)}
          className="Pagination__button"
          disabled={!canPreviousPage}
        >
          <Icon name="chevron-first" />
        </Button>
        <Button
          variant="light"
          onClick={() => previousPage()}
          className="Pagination__button Pagination__button--first"
          disabled={!canPreviousPage}
        >
          <Icon name="chevron-left" />
        </Button>
        <span className="Pagination__value">
          {pageIndex + 1} {t('common:of')} {pageCount}
        </span>
        <Button
          variant="light"
          onClick={() => nextPage()}
          className="Pagination__button Pagination__button--last"
          disabled={!canNextPage}
        >
          <Icon name="chevron-right" />
        </Button>
        <Button
          variant="light"
          onClick={() => onGotoPage(pageCount - 1)}
          className="Pagination__button"
          disabled={!canNextPage}
        >
          <Icon name="chevron-last" />
        </Button>
      </div>
      <div className="Pagination__summary">
        {t('common:currently')},{' '}
        {firstLineNumber} − {firstLineNumber + pageSize - 1}{' '}
        {t('common:of')}{' '}
        {totalRecords >= 1000 && (
          <Fragment>{t('more-than')}{' '}</Fragment>
        )}
        {totalRecords}{' '}
        {t('common:lines')}
      </div>
    </div>
  );
};

export default Pagination;
