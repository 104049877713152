import './index.scss';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UnmutedScenario } from 'types/models';
import FormRadios from 'components/FormRadios';
import getDaysDiff from 'utils/getDaysDiff';
import type { FormRadioValue } from 'components/FormRadios/Item';

type Props = {
  text: string,
  lastMutedAt?: string,
};

const CustomerUnMuteForm = ({ text, lastMutedAt }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [scenario, setScenario] = useState<FormRadioValue>('standard');

  const mutedDays = useMemo(() => {
    if (!lastMutedAt) {
      return null;
    }

    const daysDiff = getDaysDiff((new Date()).toISOString(), lastMutedAt);

    return Number.parseInt(daysDiff, 10);
  }, [lastMutedAt]);

  const radioItems = useMemo(() => (
    Object.entries(UnmutedScenario).map(([key, value]) => ({
      label: (mutedDays && key.toLowerCase() === UnmutedScenario.MOVE)
        ? t('debits:unmuted-actions.move-days', { mutedDays })
        : t(`debits:unmuted-actions.${key.toLowerCase()}`),
      value: value,
      id: `unmuted-scenarios-${key.toLowerCase()}`,
    }))
  ), [t, mutedDays]);

  return (
    <div className="CustomerUnMuteForm">
      <p className="CustomerUnMuteForm__text">{text}</p>
      <FormRadios
        name="scenario"
        items={radioItems}
        value={scenario}
        onChange={setScenario}
      />
    </div>
  );
};

export default CustomerUnMuteForm;
