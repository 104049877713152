import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import update from 'immutability-helper';
import { useTable } from 'react-table';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { Column, Row } from 'react-table';
import SortableListRow from './Row';

interface WithId {
  id: number | string;
}

type Props<DataType extends WithId, KeyType extends keyof DataType> = {
  columns: Column<DataType>[],
  savedData: DataType[],
  idAccessor?: KeyType,
  onReorder(newDataList: DataType[], newDragPosition: number): void,
  getRowClassName?(row: Row<DataType>): string,
  isSaving: boolean,
};

const SortableList = <DataType extends WithId, KeyType extends keyof DataType>(
  props: Props<DataType, KeyType>,
): JSX.Element => {
  const { columns, savedData = [], idAccessor, onReorder, getRowClassName, isSaving } = props;
  const [data, setData] = useState<DataType[]>(savedData);
  const [newDragPosition, setNewDragPosition] = useState<number>(0);
  const tableInstance = useTable<DataType>({ columns, data, disableSortBy: true });
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = tableInstance;
  const currentHandlerId = useRef<number | null>(null);

  useEffect(() => {
    setData(savedData);
  }, [savedData]);

  const handleItemMove = useCallback((dragIndex: number, hoverIndex: number) => {
    setData((prevData) => update(prevData, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevData[dragIndex] as DataType],
      ],
    }));
    setNewDragPosition(hoverIndex);
  }, []);

  const handleItemDrop = useCallback(() => {
    if (isSaving) {
      return;
    }
    onReorder(data, newDragPosition);
    currentHandlerId.current = data[newDragPosition].id as number;
  }, [isSaving, onReorder, data, newDragPosition]);

  return (
    <table {...getTableProps()}className="SortableList">
      <thead className="SortableList__header">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="SortableList__header__row">
            <th className="SortableList__header__cell SortableList__header__cell--first">&nbsp;</th>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="SortableList__header__cell">
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <DndProvider backend={HTML5Backend}>
        <tbody {...getTableBodyProps()} className="SortableList__body">
          {rows.map((row, index) => {
            prepareRow(row);
            const accessedId = idAccessor ? row.original[idAccessor] : null;
            const rowIdentifier = accessedId ?? row.original.id;
            return (
              <SortableListRow<DataType>
                key={`${rowIdentifier}`}
                id={`${rowIdentifier}`}
                row={row}
                index={index}
                onMove={handleItemMove}
                onDrop={handleItemDrop}
                getRowClassName={getRowClassName}
                disableSort={isSaving}
                currentHandlerId={currentHandlerId.current}
              />
            );
          })}
        </tbody>
      </DndProvider>
    </table>
  );
};

export default SortableList;
