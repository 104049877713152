import './index.scss';
import classnames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { CreditType, CreditStatus, DebitCreditEntity } from 'types/models';
import type { DebitOrCredit } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';
import useTooltip from 'components/Tooltip/useTooltip';

type Props = {
  data: DebitOrCredit,
  withoutTooltip?: boolean,
  useAmountNotAssigned?: boolean,
};

const CustomerAccountDetailsAmountCell = ({ data, withoutTooltip, useAmountNotAssigned }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { entity, status, amountNotAssigned, currency } = data;
  const isFailedCredit = entity === DebitCreditEntity.CREDIT && status === CreditStatus.FAILED;
  const amount = data.amount;
  const isPositiveCredit = entity === DebitCreditEntity.CREDIT && amount > 0;
  const isNegativeCredit = entity === DebitCreditEntity.CREDIT && amount <= 0;
  const isRejectUnpaid = (status === CreditStatus.FAILED || data.type === 'REJECT') && amountNotAssigned > 0;
  const type = entity === DebitCreditEntity.CREDIT ? data.type : '';
  const isRefund = type === CreditType.REFUND;
  const { currency: organizationCurrency } = organizationStore.currentOrganization!;

  const displayedAmount = useMemo(() => {
    const formattedAmount = formatIntlNumber(
      Math.abs(amount),
      currency || organizationCurrency,
    );

    if (isRefund) {
      return `${amount ? '+' : ''}${formattedAmount}`;
    }

    const formattedNotAssigned = formatIntlNumber(
      amountNotAssigned,
      currency || organizationCurrency,
    );

    if (entity === DebitCreditEntity.CREDIT) {
      if (useAmountNotAssigned) {
        return `${amountNotAssigned ? '-' : ''}${formattedNotAssigned}`;
      }

      if (!isFailedCredit) {
        return `${amount ? '-' : ''}${formattedAmount}`;
      }
    }

    if (useAmountNotAssigned) {
      return formattedNotAssigned;
    }

    return formattedAmount;
  }, [
    amount,
    currency,
    organizationCurrency,
    isRefund,
    amountNotAssigned,
    entity,
    useAmountNotAssigned,
    isFailedCredit,
  ]);

  const className = classnames('CustomerAccountDetailsAmountCell', {
    'CustomerAccountDetailsAmountCell--credit-error': isFailedCredit,
    'CustomerAccountDetailsAmountCell--reject-unpaid': isRejectUnpaid,
    'CustomerAccountDetailsAmountCell--credit-positive': isPositiveCredit,
    'CustomerAccountDetailsAmountCell--credit-negative': isNegativeCredit || isRefund,
  });

  const tooltip = useTooltip(
    <div className="CustomerAccountDetailsAmountCell__tooltip">
      <div className="CustomerAccountDetailsAmountCell__tooltip__title">
        {t('common:remaining-balance')}
      </div>
      <div className="CustomerAccountDetailsAmountCell__tooltip__amount">
        {formatIntlNumber(amountNotAssigned, currency || organizationCurrency)}
      </div>
    </div>,
    'center',
    entity !== DebitCreditEntity.DEBIT || amountNotAssigned === 0,
  );

  return (
    <div className={className} ref={withoutTooltip ? undefined : tooltip}>
      {displayedAmount}
    </div>
  );
};

export default observer(CustomerAccountDetailsAmountCell);
