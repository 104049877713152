import type { EffectCallback, DependencyList } from 'react';
import { useEffect, useRef } from 'react';

const useMountedEffect = (
  effect: EffectCallback,
  deps?: DependencyList | undefined,
): void => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      effect();
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useMountedEffect;
