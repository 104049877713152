import './index.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ImportItemFormat, ImportLineVariant } from 'types/models';
import { ImportItemStep } from 'types/models';
import type { Import } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import getI18nReportStep from 'utils/getI18nReportStep';

type Props = {
  variant?: ImportLineVariant,
  className?: string,
  report: Import,
};

const ImportsReportsItem = ({ variant, className, report }: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    stepExecutions: files,
    fileExtension,
    createdAt,
    startAt,
    endAt,
    label,
    fileName,
  } = report;

  const classNames = classnames('ImportsReportsItem', {
    'ImportsReportsItem--warning': variant === ImportLineVariant.WARNING,
    'ImportsReportsItem--in-progress': variant === ImportLineVariant.IN_PROGRESS,
    'ImportsReportsItem--error': variant === ImportLineVariant.ERROR,
  }, className);

  const getButtonVariant = useMemo(() => {
    if (variant === ImportLineVariant.DEFAULT) {
      return 'outline';
    }
    return (variant === ImportLineVariant.ERROR) ? 'danger' : 'black';
  }, [variant]);

  const reportIcon = useMemo<JSX.Element>(() => {
    if (variant === ImportLineVariant.ERROR) {
      return <Icon name="warning-circle" />;
    }
    if (variant === ImportLineVariant.IN_PROGRESS) {
      return <Loading hasNoText />;
    }
    return <Icon name="check-circle" />;
  }, [variant]);

  const reportDate = useMemo(() => {
    const date = variant === ImportLineVariant.IN_PROGRESS ? startAt : endAt;
    return formatIntlDate(date ?? createdAt, 'dateTimeLong');
  }, [startAt, endAt, createdAt, variant]);

  const hasDraft = useMemo(() => (
    (variant === ImportLineVariant.ERROR || variant === ImportLineVariant.WARNING)
      && fileExtension === ImportItemFormat.PDF
      && files.some(({ nbLineDraft }) => nbLineDraft)
  ), [fileExtension, files, variant]);

  const isSteps = useMemo(() => (
    !!files.filter((file) => (
      (Object.values(ImportItemStep) as string[]).includes(file.label)
    )).length
  ), [files]);

  return (
    <div className={classNames}>
      <div className="ImportsReportsItem__icon">
        {reportIcon}
      </div>
      <div className="ImportsReportsItem__infos">
        <p className="ImportsReportsItem__infos__type">
          {`${t('reports:import')} ${fileExtension.toUpperCase()}`}
        </p>
        <p className="ImportsReportsItem__infos__subtitle">
          {t('reports:file', { count: isSteps ? 1 : (files.length || 1) })}
        </p>
      </div>
      <div
        className="ImportsReportsItem__fileName"
        title={isSteps ? fileName : undefined}
      >
        {isSteps ? fileName : (
          files.length === 1 ? files[0]?.label ?? '' : ''
        )}
      </div>
      <div className="ImportsReportsItem__statement">
        <p className="ImportsReportsItem__statement__text">
          {variant === ImportLineVariant.ERROR ?
            t('reports:statement.error') : t('reports:statement.success')
          }
        </p>
        {variant !== ImportLineVariant.ERROR && label && !isSteps &&
          <p className="ImportsReportsItem__statement__label">
            {t('reports:success-label')} : {label}
          </p>
        }
        {variant !== ImportLineVariant.ERROR && isSteps && (
          files.map(({
            readCount: read,
            nbErrorsByLine: error,
            writeCount: created,
            updateCount: updated,
            filterCount: skipped,
            nbErrorsByFile: fileError,
            label: stepName,
          }, index) => (
            <p
              key={index}
              className="ImportsReportsItem__statement__label"
            >
              {(Object.values(ImportItemStep) as string[]).includes(stepName) ?
                t(getI18nReportStep(stepName as ImportItemStep)) : stepName
              } : {[
                t('reports:line', { count: read }),
                !!error && t('reports:error', { count: error || fileError }),
                !!created && t('reports:line-created', { count: created }),
                !!updated && t('reports:line-updated', { count: updated }),
                !!skipped && t('reports:line-skipped', { count: skipped }),
              ].filter((count) => count).join(', ')}
            </p>
          )))
        }
        {files.map(({ errors, id }, index) => ( !!errors.length &&
          <p key={`${id}-${index}`} className="ImportsReportsItem__statement__error">
            {`${t('reports:error-label')}: ${ typeof errors === 'string' ? errors : errors.join(', ')}`}
          </p>
        ))}
        {files.map(({ failures, id }, index) => ( !!failures.length &&
          <p key={`${id}-${index}`} className="ImportsReportsItem__statement__error">
            {`${t('reports:error-label')}: ${ typeof failures === 'string' ? failures : failures.join(', ')}`}
          </p>
        ))}
      </div>
      <div className="ImportsReportsItem__date">
        {reportDate}
      </div>
      <div className="ImportsReportsItem__action">
        {hasDraft && (
          <Button href="/debits/draft" variant={(variant === ImportLineVariant.ERROR && 'outline') || undefined}>
            {t('common:complete')}
          </Button>
        )}
        {variant !== ImportLineVariant.IN_PROGRESS && (
          <Link
            to={{ pathname: '/imports/report', state: { report } }}
            className="ImportsReportsItem__action__link"
          >
            <Button variant={getButtonVariant}>
              {hasDraft ? t('reports:report') : t('reports:see-report')}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default observer(ImportsReportsItem);
