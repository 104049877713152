import { createContext } from 'react';

export type ModalContextValue = {
  sourceView: boolean,
  setSourceView?(value: boolean): void,
};

const defaultValue: ModalContextValue = {
  sourceView: false,
  setSourceView: undefined,
};

const ModalContext = createContext<ModalContextValue>(defaultValue);

export default ModalContext;
