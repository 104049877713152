import { SortingRule } from 'react-table';
import { SortDirection, type Collection, type Filter, type SearchResult } from 'types/api';
import type Misc from 'types/misc';
import type { Client, Contact, ContactSummary, EAVValue, Organization, User } from 'types/models';
import requester from 'utils/requester';

export type FetchAllParams = {
  page?: number | undefined,
  pageSize?: number,
  organizationReference?: Organization['reference'] | undefined,
  locale?: User['locale'] | undefined,
  filtering?: Misc.Filter[],
  sort?: SortingRule<ContactSummary>,
};

export type FetchOneParams = {
  id: Contact['id'],
  filtering?: Misc.Filter[],
};

export type FetchAllForCustomerParams = {
  id: Client['id'] | undefined,
  params?: FetchAllParams | undefined
};

const getMappingFromFormToApi = (name: string) => {
  const map = {
    search: 'full_text',
    role: 'role',
    client: 'client',
    categories: 'business_units',
  } as Record<string, string>;

  return map[name] ?? '';
};

const getFilterValue = (name: string, value: string | string[]): Filter['value'] => {
  const currentValue = name !== 'categories' && (Array.isArray(value) && value.length > 0) ? value[0] : value;
  switch (name) {
    case 'search':
    case 'categories':
      return currentValue;
    case 'role':
    case 'client':
      return [currentValue] as string[];
    default:
      return null;
  }
};

const getFilterOperator = (name: string): Filter['operator'] => {
  switch (name) {
    case 'role':
    case 'client':
    case 'categories':
      return 'IN';
    default:
      return '=';
  }
};

const getFilter = (name: string, value: any): Filter => ({
  field: getMappingFromFormToApi(name),
  operator: getFilterOperator(name),
  value: getFilterValue(name, value),
  context: [],
});

const transformSortForEndpoint = (sort: SortingRule<ContactSummary>) => {
  let field = sort.id;
  switch (sort.id) {
    case 'lastName':
      field = 'last_name';
      break;
    case 'firstName':
      field = 'first_name';
      break;
    case 'clientName':
      field = 'client_denomination';
      break;
    case 'customerReference':
      field = 'code';
      break;
    case 'contactRole':
      field = 'contact_role';
      break;
    case 'email':
      field = 'email';
      break;
  }
  return {
    field,
    direction: sort.desc ? SortDirection.DESC : SortDirection.ASC,
  };
};

const all = async (
  id: Organization['id'] | undefined,
  params: FetchAllParams | undefined,
): Promise<SearchResult<ContactSummary>> => {
  if (!id) {
    throw new Error('FetchAllContacts: Missing organization.');
  }

  const { page = 0, filtering, locale, organizationReference, pageSize, sort } = params ?? { page: 0, filtering: [] };

  if (!organizationReference) {
    throw new Error('FetchAllContacts: Missing organization reference.');
  }

  const filters: Filter[] = filtering ?
    filtering.map(({ name, value }) => getFilter(name, value))
    : [];

  const { data } = await requester.put<SearchResult<ContactSummary>>(
    'contacts',
    {
      channel: organizationReference,
      locale,
      filters,
      page,
      size: pageSize,
      sort: sort ? transformSortForEndpoint(sort) : undefined,
    },
  );

  return data;
};

/**
 * Récupère les données d'un contact.
 *
 * @param id ID du contact.
 * @returns Les données de contact.
 */
const one = async (
  { id, filtering }: FetchOneParams,
): Promise<Contact> => {

  const queryData = new URLSearchParams();

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }: Misc.Filter) => {
      queryData.append(name, (Array.isArray(value) ? value.join(',') : value) ?? '');
    });
  }

  const { data } = await requester.get<Contact>(
    `contacts/${id}?${queryData.toString()}`,
  );

  return data;
};

/**
 * Récupère une liste des contacts du client.
 *
 * @param id Id du client.
 * @param pageSize nombre total de clients par page.
 * @returns Une collection de contact.
 */
const allForCustomer = async (
  { id, params }: FetchAllForCustomerParams,
): Promise<Contact[]> => {
  if (!id) {
    throw new Error('allForCustomerContact: Missing customer ID.');
  }

  const { page, pageSize, filtering } = params ?? {
    page: 1,
    pageSize: 5,
    filtering: [],
  };

  const queryData = new URLSearchParams();
  queryData.append('page', (page ?? 1).toString());
  queryData.append('pageSize', (pageSize ?? 5).toString());
  queryData.append('orderBy', 'role');
  queryData.append('orderDirection', 'asc');

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }: Misc.Filter) => {
      queryData.append(name, (Array.isArray(value) ? value.join(',') : value) ?? '');
    });
  }

  const { data } = await requester.get<Collection<Contact>>(
    `clients/${id}/contacts?${queryData.toString()}`,
  );

  return data['hydra:member'];
};

/**
 * URL de la ressource API pour la création d'un contact
 */
const createUrl = 'contacts';

/**
 * Récupère l'URL de la ressource API pour supprimer le contact.
 *
 * @param id L'ID de contact.
 * @returns URL du DELETE.
 */
const deleteUrl = (id: Contact['id']) => `contacts/${id}`;

/**
 * Récupère l'URL de la ressource API pour modifier le contact.
 *
 * @param id L'ID de contact
 * @returns URL du PUT.
 */
const updateUrl = (id: Contact['id']) => `contacts/${id}`;

/**
 * Récupère l'URL de la ressource API pour mapper une association de contact.
 *
 * @param id L'ID du contact
 * @returns URL de l'association.
 */
const resourceUrl = (id: string | number) => `/api/contacts/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour supprimer list des contacts (Bulk).
 *
 * @param ids L'ID des contacts
 * @returns URL du contact.
 */
const bulkDeleteUrl = (ids: Contact['id'][]) => {
  const queryData = new URLSearchParams();
  queryData.append('ids', ids.join(','));
  return `contacts?${queryData.toString()}`;
};

/**
 * URL de la ressource d'API pour l'update des EAV d'un contact (PUT).
 *
 * @param ids L'ID du contact
 * @returns URL de l'update des EAV d'un contact
 */
const updateContactEavsUrl = (id: Contact['id']) => `contacts/${id}/values`;

/**
 * Récupère les EAV d'un contact
 *
 * @param id ID du contact.
 * @returns les EAV d'un contact.
 */
const fetchEavs = async ({ id }: FetchOneParams): Promise<EAVValue[]> => {
  const { data } = await requester.get<Collection<EAVValue>>(`contacts/${id}/values`);
  return data['hydra:member'];
};

export default {
  all,
  one,
  allForCustomer,
  bulkDeleteUrl,
  resourceUrl,
  createUrl,
  deleteUrl,
  updateUrl,
  fetchEavs,
  updateContactEavsUrl,
};
