import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { EavConstructor, Eav } from 'types/models';
import FormEavItem from './Item';

type Props = {
  eavs: EavConstructor[],
  defaultValues?: Eav[] | null,
  errors: ValidationErrors | null,
  hideErrorWhenHasDefault?: boolean,
};

const FormEavs = (props: Props): JSX.Element => {
  const { eavs, defaultValues, errors, hideErrorWhenHasDefault = false } = props;
  const { t } = useTranslation();

  const eavsWithDefaultValue = useMemo(() => (
    eavs.map((eav) => {
      const value: string | null = defaultValues?.find(
        ({ identifier }) => identifier === eav.identifier,
      )?.value ?? null;

      const defaultValue = value === '-' ? null : value;

      return { ...eav, defaultValue };
    })
  ), [defaultValues, eavs]);

  return (
    <fieldset className="FormEavs">
      <h3 className="FormEavs__title">{t('common:attributes')}</h3>
      <div className="FormEavs__fields">
        {eavsWithDefaultValue.map((eav) => (
          <FormEavItem
            key={eav.identifier}
            eav={eav}
            defaultValue={eav.defaultValue}
            error={errors && (eav.defaultValue === undefined || !hideErrorWhenHasDefault)
              ? errors[eav.identifier]
              : null}
          />
        ))}
      </div>
    </fieldset>
  );
};

export default FormEavs;
