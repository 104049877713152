import { Link } from 'react-router-dom';
import type { DebitSummaryStep } from 'types/models';
import DebitDiffDays from 'components/DebitDiffDays';

type Props = {
  data: DebitSummaryStep,
  withDiffDays?: boolean,
};

const HistoryStepDebit = ({ data, withDiffDays }: Props): JSX.Element => (
  <div className="HistoryStepDebit">
    <Link to={`/debit/${data.id}`}>{data.identifier}</Link>
    {withDiffDays && (
      <DebitDiffDays dueAt={data.dueAt} isActive={data.isActive} />
    )}
  </div>
);

export default HistoryStepDebit;
