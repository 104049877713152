import './index.scss';
import { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Loading from 'components/Loading';
import type { Debit } from 'types/models';
import { DebitStatus, DebitAction } from 'types/models';
import DebitsContext from '../../context';
import getBulkToggleActionI18n from './getBulkToggleActionI18n';
import BulkActionsToggle from './Toggle';
import BulkActionPromiseToPay from './PromiseToPay';

type Props = {
  selection?: Debit['id'][],
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const DebitBulkActions = (props: Props): JSX.Element | null => {
  const { selection, onActionDone, onActionError } = props;
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const { currentStatus } = useContext(DebitsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleToggleActionDone = useCallback(
    (action: DebitAction) => {
      const { toastText } = getBulkToggleActionI18n(t, ct, selection?.length || 0)[action];
      onActionDone(toastText);
    },
    [t, ct, selection, onActionDone],
  );

  const handleActionLoading = useCallback(
    (isLoad: boolean) => { setIsLoading(isLoad); },
    [],
  );

  const handleActionError = useCallback(
    (message: string) => {
      onActionError(message);
    },
    [onActionError],
  );

  if (!selection?.length) {
    return null;
  }

  const classNames = classnames('DebitBulkActions', {
    'DebitBulkActions--loading': isLoading,
  });

  return (
    <div className={classNames}>
      {isLoading && <Loading text={t('common:saving')} />}
      {currentStatus === DebitStatus.IN_PROGRESS && (
        <>
          <BulkActionsToggle
            selection={selection}
            action={DebitAction.UNMUTE}
            payload={{ muted: false }}
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
          <BulkActionsToggle
            selection={selection}
            action={DebitAction.MUTE}
            payload={{ muted: true }}
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
          <BulkActionPromiseToPay
            selection={selection}
            onLoading={handleActionLoading}
            onDone={onActionDone}
          />
          <BulkActionsToggle
            selection={selection}
            action={DebitAction.LITIGATE}
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
        </>
      )}
      {(currentStatus === DebitStatus.LITIGATED) && (
        <BulkActionsToggle
          selection={selection}
          action={DebitAction.CANCEL_LITIGATE}
          payload={{ status: DebitStatus.IN_PROGRESS }}
          onLoading={handleActionLoading}
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      {currentStatus !== DebitStatus.CANCEL && (
        <BulkActionsToggle
          selection={selection}
          action={DebitAction.DISABLE}
          payload={{ status: DebitStatus.CANCEL }}
          onLoading={handleActionLoading}
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      {currentStatus === DebitStatus.CANCEL && (
        <>
          <BulkActionsToggle
            selection={selection}
            action={DebitAction.ACTIVATE}
            payload={{ status: DebitStatus.IN_PROGRESS }}
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
          <BulkActionsToggle
            selection={selection}
            action={DebitAction.REMOVE}
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
        </>
      )}
    </div>
  );
};

export default observer(DebitBulkActions);
