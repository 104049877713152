import Misc from 'types/misc';
import { Channel } from './HistoryStep';

export enum ScenarioDateReference {
  MANUAL = 'manual',
  DEBIT_OLDEST = 'debit_oldest',
  DEBIT_NEWEST = 'debit_newest',
  SCENARIO_CREATED_AT = 'scenario_created_at',
  EVENT_LITIGATION = 'event_litigation',
  EVENT_REJECTED = 'event_rejected',
}

export enum UnmutedScenario {
  MOVE = 'move',
  STANDARD = 'standard',
}

export enum ScenarioFrequency {
  NEVER = 'never',
  UNLIMITED = 'unlimited',
  LIMITED = 'limited',
}

export type ScenarioGroup = {
  id?: number,
  identifier: string,
  name: string,
  color?: string,
  scenarios?: Scenario[],
};

export type ScenarioTemplateTask = {
  code?: string,
  name: string,
  description: string,
  day: number,
  channel?: Channel,
  withValidation?: boolean,
  repeat?: {
    day: number | null,
    frequency: number | null,
  } | null
};

export type ScenarioTemplateDispatch = {
  name: string,
  description: string,
  day: number,
  channel?: Channel,
  contacts: string[],
  withValidation?: boolean,
  subject: string | null,
  content: string,
};

export type ScenarioTemplateReminder = {
  name: string,
  description: string,
  day: number,
  channel?: Channel,
  withValidation?: boolean,
  subject?: string | null,
  content?: string,
  contacts: string[],
  code?: string,
  hash?: string,
  repeat?: {
    day: number | null,
    frequency: number | null,
  } | null
};

export type ScenarioTemplate = ScenarioTemplateDispatch | ScenarioTemplateReminder | ScenarioTemplateTask;

export type Scenario = {
  id: number,
  identifier: string,
  name: string,
  dateReferenceIsToday: ScenarioDateReference,
  tasks: ScenarioTemplateTask[],
  dispatches: ScenarioTemplateDispatch[],
  reminders: ScenarioTemplateReminder[],
  sortOrder: number,
  isDefault: boolean,
};

export type ScenarioSummary = {
  id: number,
  identifier: string,
  name: string,
  isVirtual?: boolean,
  referenceDateOrigin?: string,
  referenceDate?: Misc.DateTimezone | string | null,
};

export type ScenarioIdentifiers = {
  id: number,
  identifier: string,
  label: string,
};

export type ScenarioStepPreviewContent = {
  content: string | null,
};
