import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import apiTemplateBlocks from 'api/template-blocks';
import type { FetchAllParams } from 'api/template-blocks';
import { Channel } from 'types/models';
import type { DataBlock, TemplateBlocks } from 'types/models';
import useFetch from 'hooks/useFetch2';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import ModalTemplateBlocksItem from './Item';

type Props = {
  onClose(): void,
  type: 'reminder' | 'task',
  onSelect(model: DataBlock): void,
  channel?: Channel,
};

const ModalModels = (props: Props): JSX.Element => {
  const { onClose, type, onSelect, channel } = props;
  const { currentOrganization } = organizationStore;
  const { t } = useTranslation();

  const { data, isLoading } = useFetch<FetchAllParams, TemplateBlocks | null>(
    { cacheKey: 'templateBlocks', organization: currentOrganization?.id },
    apiTemplateBlocks.all,
    { enabled: !!currentOrganization, retry: false },
  );

  const handleSelect = useCallback((newModel: DataBlock) => {
    onSelect(newModel);
    onClose();
  }, [onClose, onSelect]);

  const templateBlocks = useMemo(() => {
    if (type === 'reminder') {
      return data?.reminders.filter(
        ({ channel: reminderChannel }) => channel === reminderChannel,
      );
    }
    return data?.tasks;
  }, [channel, data, type]);

  return (
    <Modal
      isOpened
      title={t('models:models')}
      isLoading={isLoading}
      onClose={onClose}
      className="ModalModels"
    >
      {templateBlocks && templateBlocks.map((model) => (
        <ModalTemplateBlocksItem
          model={model}
          key={`${model.name}-${model.identifier}`}
          onSelect={handleSelect}
        />
      ))}
      {!templateBlocks?.length && !isLoading && (
        <p className="ModalModels__no-data">{t('models:no-template-found')}</p>
      )}
      {isLoading && <Loading />}
    </Modal>
  );
};

export default observer(ModalModels);
