import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Button from 'components/Button';
import { ReactComponent as FileSvg } from '../assets/file.svg';

interface Props {
  type: string,
  description: string,
}

const ImportsFileWizardOption: React.FC<Props> = ({ type, description }) => {
  const { t } = useTranslation();

  const FileSvgClassNames = classnames('ImportsFileWizardOption__file-container__file', {
    'ImportsFileWizardOption__file-container__file--csv': type === 'csv',
    'ImportsFileWizardOption__file-container__file--pdf': type === 'pdf',
  });

  return (
    <div className="ImportsFileWizardOption">
      <div className="ImportsFileWizardOption">
        <div className="ImportsFileWizardOption__content">
          <div className="ImportsFileWizardOption__file-container">
            <div className="ImportsFileWizardOption__file-container__file">
              <FileSvg className={FileSvgClassNames} />
              <span className="ImportsFileWizardOption__file-container__file__title">
                {type.toUpperCase()}
              </span>
            </div>
            {type === 'csv' && (
              <div className="ImportsFileWizardOption__file-container__file">
                <FileSvg className="ImportsFileWizardOption__file-container__file--xlsx" />
                <span className="ImportsFileWizardOption__file-container__file__title">
                  XLSX
                </span>
              </div>
            )}
          </div>
          <p className="ImportsFileWizardOption__description">
            {description}
          </p>
          <Button
            href={`imports/new/${type === 'csv' ? 'csv-xlsx' : 'pdf'}`}
            variant="primary"
            className="ImportsFileWizardOption__button"
          >
            {t('imports:wizard.option.csv-xlsx.link')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImportsFileWizardOption;
