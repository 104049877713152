import { ScenarioDateReference } from 'types/models';

const getI18nDayFromReference = (day: number, dateReference: ScenarioDateReference): string => {
  if (day === 0) {
    switch (dateReference) {
      case ScenarioDateReference.DEBIT_NEWEST:
        return 'plans:day-from-reference.debit-newest';
      case ScenarioDateReference.DEBIT_OLDEST:
        return 'plans:day-from-reference.debit-oldest';
      case ScenarioDateReference.EVENT_LITIGATION:
        return 'plans:day-from-reference.event-litigation';
      case ScenarioDateReference.EVENT_REJECTED:
        return 'plans:day-from-reference.event-rejected';
      case ScenarioDateReference.MANUAL:
        return 'plans:day-from-reference.manual';
      default:
        return 'plans:day-from-reference.at-reference-date';
    }
  }

  switch (dateReference) {
    case ScenarioDateReference.DEBIT_NEWEST:
      return (day > 0)
        ? 'plans:day-from-reference.after-debit-newest'
        : 'plans:day-from-reference.before-debit-newest';
    case ScenarioDateReference.DEBIT_OLDEST:
      return (day > 0)
        ? 'plans:day-from-reference.after-debit-oldest'
        : 'plans:day-from-reference.before-debit-oldest';
    case ScenarioDateReference.EVENT_LITIGATION:
      return (day > 0)
        ? 'plans:day-from-reference.after-event-litigation'
        : 'plans:day-from-reference.before-event-litigation';
    case ScenarioDateReference.EVENT_REJECTED:
      return (day > 0)
        ? 'plans:day-from-reference.after-event-rejected'
        : 'plans:day-from-reference.before-event-rejected';
    case ScenarioDateReference.MANUAL:
      return (day > 0)
        ? 'plans:day-from-reference.after-manual'
        : 'plans:day-from-reference.before-manual';
    default:
      return (day > 0)
        ? 'plans:day-from-reference.after-scenario-created-at'
        : 'plans:day-from-reference.before-scenario-created-at';
  }
};

export default getI18nDayFromReference;
