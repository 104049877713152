import './index.scss';
import type { ReactNode } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import NoOrganizationMessage from 'components/NoOrganizationMessage';
import Loading from 'components/Loading';

type Props = {
  title: string,
  count?: number | null,
  isFetching?: boolean,
  className?: string,
  nav?: ReactNode,
  actions?: ReactNode,
  children: ReactNode,
};

const PageList = (props: Props): JSX.Element => {
  const {
    className,
    title,
    count = null,
    isFetching = false,
    nav = null,
    actions = null,
    children,
  } = props;
  const { currentOrganization } = organizationStore;
  const { t } = useTranslation();

  return (
    <div className={classnames('PageList', className)}>
      <header className="PageList__header">
        <h1 className="PageList__title">{title}</h1>
        {nav && <div className="PageList__nav">{nav}</div>}
        <div className="PageList__count">
          {(!isFetching && !!count) && (
            count >= 1000
              ? t('common:more-than-count-results', { count })
              : t('common:results-count', { count })
          )}
          {isFetching && <Loading hasNoText />}
        </div>
        {actions && <div className="PageList__actions">{actions}</div>}
      </header>
      <div className="PageList__content">
        {currentOrganization ? children : <NoOrganizationMessage />}
      </div>
    </div>
  );
};

export default observer(PageList);
