import './index.scss';
import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import organizationStore from 'stores/Organization';
import type { FetchActionsFutureAllParams } from 'api/historySteps';
import apiHistorySteps from 'api/historySteps';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import { StepActionPeriodFilter, StepActionTypeFilter } from 'types/models';
import type { StepAction } from 'types/models';
import authStore from 'stores/Auth';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import HomeMyActionsItem from './Item';
import { ReactComponent as NoAction } from './noAction.svg';

type Props = {
  onCountActions(count: number): void,
};

const HomeMyActions = ({ onCountActions }: Props): JSX.Element => {
  const { walletSelection, currentId, currentOrganization, attributes } = organizationStore;
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = authStore;

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return [];
    }
    return [{ name: 'category', value: walletSelection }];
  }, [walletSelection]);

  const {
    isLoading,
    data: myActions,
    error,
    serverPagination,
  } = useFetchPaginated<FetchActionsFutureAllParams, StepAction>(
    {
      cacheKey: 'myActions',
      organizationId: currentId!,
      params: {
        page: 0,
        organizationReference: currentOrganization?.reference,
        pageSize: 5,
        locale: user?.locale,
        filtering: [
          ...filtering,
          { name: 'period', value: StepActionPeriodFilter.LAST_15_DAYS },
          { name: 'type', value: [
            StepActionTypeFilter.TASK_PENDING,
            StepActionTypeFilter.REMINDER_TO_VALIDATE,
            StepActionTypeFilter.REMINDER_TO_COMPLETE,
            StepActionTypeFilter.REMINDER_PENDING,
          ] },
          { name: 'manager', value: user?.reference?.toString() ?? '' },
        ],
        attributes: attributes.client,
      },
    },
    apiHistorySteps.allFuture,
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (myActions && serverPagination.totalRecords) {
      onCountActions(serverPagination.totalRecords);
    }
  }, [onCountActions, myActions, serverPagination.totalRecords]);

  const handleShowView = useCallback(() => {
    history.push('/actions-future');
  }, [history]);

  return (
    <div className="HomeMyActions">
      {isLoading && <Loading hasNoText />}
      {error && <ErrorMessage error={error} />}
      {!isLoading && myActions && serverPagination.totalRecords > 0 && (
        <ul className="HomeMyActions__list">
          {myActions.map((step) => (
            <HomeMyActionsItem data={step} onShowView={handleShowView} key={step.id} />
          ))}
        </ul>
      )}
      {!isLoading && myActions && (!myActions || serverPagination.totalRecords === 0) && (
        <div className="HomeMyActions__no-data">
          {t('actions:no-action')}
          <div className="HomeMyActions__no-data__drawing">
            <NoAction className="HomeMyActions__no-data__drawing__icon" />
          </div>
          <div className="HomeMyActions__no-data__actions">
            <ActionButton
              icon={<Icon name="list" />}
              title={t('actions:actions-future')}
              onClick={handleShowView}
            />
            <ActionButton
              icon={<Icon name="send" />}
              title={t('actions:actions-done')}
              onClick={() => history.push('/actions-done')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(HomeMyActions);
