import './index.scss';
import classnames from 'classnames';
import Icon from 'components/Icon';
import authStore from 'stores/Auth';
import config from 'config';

interface Props {
  className?: string,
  article: { [key: string]: string },
}

const HelpLink = (props: Props): JSX.Element => {
  const { className, article } = props;
  const { locale } = authStore?.user ?? config.DEFAULT_LOCALE;
  const supportUrl = config.FRESHDESK_LINKS[locale];

  const classNames = classnames('HelpLink', className);

  return (
    <a
      aria-label="help"
      target="_blank"
      rel="noreferrer"
      href={`${supportUrl}${article[locale]}`}
      className={classNames}
    >
      <Icon name="help-circle" />
    </a>
  );
};

export default HelpLink;
