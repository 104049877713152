import './index.scss';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import formatIntlNumber from 'utils/formatIntlNumber';
import useContextualTranslation from 'hooks/useContextualTranslation';

type Props = {
  amount: number,
  count: number,
};

const BillsInProgress = ({ amount, count }: Props): JSX.Element => {
  const { currency, type } = organizationStore.currentOrganization!;
  const { ct } = useContextualTranslation(type);

  return (
    <div className="BillsInProgress">
      <h2 className="BillsInProgress__total">
        {formatIntlNumber(amount, currency, 0)}
      </h2>
      <div className="BillsInProgress__bills">
        {ct('common:bills-in-progress')}
        <span className="BillsInProgress__bills__count">
          {count}
        </span>
      </div>
    </div>
  );
};

export default observer(BillsInProgress);
