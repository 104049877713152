import './index.scss';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import type { CustomerResult } from 'api/globalSearch';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';

type Props = {
  data: CustomerResult[],
  search: string,
};

const SearchResultClients = ({ data, search }: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { ct } = useContextualTranslation(type);

  return (
    <ul className="SearchResultClients">
      {data.map((customer) => {
        const { id, identifier, denomination } = customer;
        return (
          <li key={id} className="SearchResultClients__item">
            <Link
              to={`/customer/${id}`}
              title={ct<string>('common:open-customer-page')}
              className="SearchResultClients__item__link"
            >
              {denomination}{identifier !== denomination ? ` (${identifier})` : ''}
            </Link>
          </li>
        );
      })}
      <li className="SearchResultClients__link-all">
        <Link
          to={`/customers${search ? `?search=${search}` : ''}`}
          className="SearchResultClients__link-all__link"
        >
          {ct('common:menu.search.all-clients')}
        </Link>
      </li>
    </ul>
  );
};

export default observer(SearchResultClients);
