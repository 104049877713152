import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  LineController,
  LineElement,
  CategoryScale,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import type { OrganizationAnalytics } from 'types/models';
import { Analytics } from 'types/models';
import Misc from 'types/misc';
import getColorAnalytics from 'utils/getColorAnalytics';
import getI18nAnalytics from 'utils/getI18nAnalytics';

type Props = {
  data: OrganizationAnalytics['agedBalance'],
};

ChartJS.register(BarController, BarElement, LineController, LineElement, CategoryScale, Tooltip, Legend, ChartDataLabels);

const AgedBalanceChart = ({ data }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState<Misc.AnalyticsChartData>({ labels: [], datasets: [] });

  useEffect(() => {
    const labels = Object.keys(data) as Array<keyof OrganizationAnalytics['agedBalance']>;
    setChartData({
      labels: labels.map((key) => t(getI18nAnalytics(key as Analytics))),
      datasets: [
        {
          label: t(getI18nAnalytics(Analytics.MONTANT)) as string,
          type: 'bar' as const,
          backgroundColor: getColorAnalytics(Analytics.MONTANT),
          data: labels.map((key) => data[key].montant),
          yAxisID: 'barAxis',
          order: 2,
        },
        {
          label: t(getI18nAnalytics(Analytics.CLIENT)) as string,
          type: 'line' as const,
          borderColor: getColorAnalytics(Analytics.CLIENT),
          data: labels.map((key) => data[key].client),
          yAxisID: 'lineAxis',
        },
        {
          label: t(getI18nAnalytics(Analytics.DEBIT)) as string,
          type: 'line' as const,
          borderColor: getColorAnalytics(Analytics.DEBIT),
          data: labels.map((key) => data[key].debit),
          yAxisID: 'lineAxis',
        },
      ],
    });
  }, [data, t]);

  if (!chartData) {
    return null;
  }

  return <div className="Analysis__chart">
      <div className="Analysis__chart__content">
      <Chart
        type="bar"
        data={chartData}
        options={{
          plugins: {
            legend: { display: true },
            datalabels: { display: false },
          },
          scales: {
            barAxis: { beginAtZero: true, position: 'right' },
          },
        }}
      />
      </div>
    </div>;
};

export default AgedBalanceChart;
