import './index.scss';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import customerContext from 'pages/Customer/customerContext';
import CreateReminder from 'components/CreateReminder';
import Button from 'components/Button';
import Icon from 'components/Icon';

type Props = {
  disabled?: boolean,
};

const CustomerActionCreateReminder = ({ disabled }: Props): JSX.Element => {
  const { onActionDone, data } = useContext(customerContext);
  const { t } = useTranslation();

  const handleActionDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showCreateReminder, hideCreateReminder] = useModal(() => (
    <CreateReminder
      customerId={data?.id}
      onDone={handleActionDone}
      onClose={hideCreateReminder}
    />
  ), [data?.id, handleActionDone]);

  return (
    <Button
      variant="list"
      className="CustomerActionCreateReminder"
      onClick={showCreateReminder}
      disabled={!!disabled}
    >
      <Icon name="message" className="CustomerActionCreateReminder__icon" />
      {t('clients:create-reminder')}
    </Button>
  );
};

export default CustomerActionCreateReminder;
