import './index.scss';
import type { ReactNode } from 'react';
import Button from 'components/Button';
import Icon from 'components/Icon';

type Props = {
  title: string
  onAction?: () => void,
  titleAction?: string,
  isActionDisabled?: boolean,
  titleSecondaryAction?: string,
  onSecondaryAction?: () => void,
  children: ReactNode,
};

const BackOfficeSection = (props: Props): JSX.Element => {
  const {
    title,
    onAction,
    titleAction,
    children,
    isActionDisabled = false,
    onSecondaryAction,
    titleSecondaryAction,
  } = props;

  return (
    <div className="BackOfficeSection">
      <div className="BackOfficeSection__header">
        <h2 className="BackOfficeSection__header__title">
          {title}
        </h2>
        {titleSecondaryAction && onSecondaryAction && (
          <Button variant="link" onClick={onSecondaryAction} className="BackOfficeSection__header__link-manage">
            <span className="BackOfficeSection__header__link-manage__title">
              {titleSecondaryAction}
            </span>
            <span className="BackOfficeSection__header__link-manage__icon">
              <Icon name="chevron-right-small" />
            </span>
          </Button>
        )}
        {titleAction && onAction && (
          <Button variant="link" disabled={isActionDisabled} onClick={onAction} className="BackOfficeSection__header__link-manage">
            <span className="BackOfficeSection__header__link-manage__title">
              {titleAction}
            </span>
            <span className="BackOfficeSection__header__link-manage__icon">
              <Icon name="chevron-right-small" />
            </span>
          </Button>
        )}
      </div>
      <div className="BackOfficeSection__infos">
        {children}
      </div>
    </div>
  );
};

export default BackOfficeSection;
