const getEavTypeLabelFromFormat = (eavFormat: RegExp | null) => {
  if (!eavFormat) {
    return 'common:none';
  }
  const eavFormatTable = [
    { format: /^[0-9]+$/, i18n: 'common:number' },
    { format: /^[0-9]+/, i18n: 'common:number' },
    { format: /^\d{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/, i18n: 'common:date-format-ymd' },
    { format: /^\d{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}/, i18n: 'common:date-format-ymd' },
    { format: /^[0-9]+(\.[0-9][0-9]?)?$/, i18n: 'common:decimal-number' },
    { format: /[0-9]+(\.[0-9][0-9]?)?/, i18n: 'common:decimal-number' },
  ];

  return eavFormatTable.find((eav) => eav.format.toString() === eavFormat.toString())?.i18n || 'common:none';
};

export default getEavTypeLabelFromFormat;
