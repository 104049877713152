import { autorun, runInAction, makeAutoObservable } from 'mobx';
import { nanoid } from 'nanoid';
import queryString from 'query-string';
import organizationStore from 'stores/Organization';
import requester from 'utils/requester';
import apiUsers from 'api/users';
import type Misc from 'types/misc';
import type { Organization } from 'types/models';

enum OperatorMap {
  '=' = 'eq',
  '!=' = 'ne',
  '>' = 'gt',
  '<' = 'lt',
  '>=' = 'gte',
  '<=' = 'lte',
  'CONTAINS' = 'contains',
  'DOES NOT CONTAIN' = 'ncontains',
  'IN' = 'in',
  'NOT IN' = 'nin',
  'BETWEEN' = 'between',
  'NOT BETWEEN' = 'nbetween',
  'PERIOD' = 'period',
}

class FilterStore {
  filters: Misc.Filter[] = [];

  firstRun: boolean = false;

  storageName: string;

  shouldSaveToStorage: boolean;

  organizationReference?: Organization['reference'];

  constructor(storageName: string, shouldSaveToStorage: boolean) {
    this.storageName = storageName;
    this.shouldSaveToStorage = shouldSaveToStorage;
    this.organizationReference = organizationStore.currentOrganization?.reference;
    makeAutoObservable(this);
    this.load();
    this.autoSave();
  }

  load = () => {
    const urlFilters = this.getUrlFilters();
    if (urlFilters.length) {
      runInAction(() => {
        this.filters = urlFilters;
      });
      return;
    }

    const storedFilters = localStorage.getItem(this.storageName);
    if (storedFilters) {
      runInAction(() => {
        this.filters = JSON.parse(storedFilters);
      });
    }
  };

  autoSave = () => {
    autorun(() => {
      if (!this.shouldSaveToStorage) {
        return;
      }
      const json = JSON.stringify(this.filters);
      if (!this.firstRun) {
        this.save(json);
      }
      runInAction(() => {
        this.firstRun = false;
      });
    });
  };

  save = (json: string) => {
    localStorage.setItem(this.storageName, json);
  };

  operatorForQueryString(operator: Misc.FilterOperators): string {
    return OperatorMap[operator];
  }

  operatorFormQueryString(operator: string): Misc.FilterOperators {
    return Object.keys(OperatorMap).find((key) =>
      OperatorMap[key as Misc.FilterOperators] === operator,
    ) as Misc.FilterOperators || '==';
  }

  updateUrl = async (filters: Misc.Filter[], storeSearch?: boolean) => {
    const filterObject: Record<string, Misc.FilterValue> = {};

    filters.forEach(({ name, value, operator }) => {
      const valueStr = value?.toString();
      if (!name || valueStr?.length === 0) {
        return;
      }

      const filter = operator ? `${this.operatorForQueryString(operator)}:${valueStr}` : valueStr;
      filterObject[name] = filterObject[name]
        ? `${filterObject[name]},${filter}`
        : `${filter}`;
    });

    const query = queryString.stringify(filterObject);

    if (storeSearch) {
      await requester.post(
        apiUsers.updateSearchLogUrl(this.organizationReference),
        { queryString: query },
      );
    }
    window.history.replaceState(null, '', `?${query}`);
  };

  getUrlFilters = (locationSearch?: string): Misc.Filter[] => {
    const urlParams = queryString.parse(locationSearch ?? window.location.search, { arrayFormat: 'comma' });
    const urlFilters: Misc.Filter[] = [];

    Object.keys(urlParams).forEach((name) => {
      const parsedValue = urlParams[name] || '';
      const values = Array.isArray(parsedValue) ? parsedValue.filter((subValue) => subValue !== null) as string[] : [parsedValue];

      values.forEach((value) => {
        if (value.includes(':')) {
          const [operator, extractedValue, extractedValueEav] = value.split(':');
          urlFilters.push({
            name,
            id: nanoid(10),
            operator: this.operatorFormQueryString(operator),
            value: `${extractedValue}${extractedValueEav ? `:${extractedValueEav}` : ''}`,
          });
        } else {
          const foundFilterIndex = urlFilters.findIndex((item) => item.name === name);
          if (foundFilterIndex !== -1) {
            urlFilters[foundFilterIndex].value = [
              urlFilters[foundFilterIndex].value as string[],
              value,
            ].flat();
          } else {
            urlFilters.push({ id: nanoid(10), name, value });
          }
        }
      });
    });

    return urlFilters;
  };

  addOrUpdateFilters = (newFilters: Misc.Filter[], withSubmit?: boolean) => {
    const filters = [...this.filters];

    newFilters.forEach((item) => {
      const filterAdvanced = 'operator' in item;

      let foundFilterIndex;
      if (filterAdvanced) {
        foundFilterIndex = filters.findIndex(({ id }) => item.id === id);
      } else {
        foundFilterIndex = filters.findIndex(({ name }) => item.name === name);
      }

      if (item.value === null || item.value.length === 0) {
        if (foundFilterIndex > -1) {
          filters.splice(foundFilterIndex, 1);
        }

        return;
      }

      if (foundFilterIndex !== -1) {
        filters[foundFilterIndex] = item;
      } else {
        filters.push(item);
      }
    });

    runInAction(() => {
      this.filters = filters;
      const filterAdvanced = newFilters.some((item) => 'operator' in item);
      this.updateUrl(filters, filterAdvanced && withSubmit);
    });
  };

  removeFilter = (key: string) => {
    const filters = [...this.filters];
    const foundFilterIndex = filters.findIndex(
      ({ id, name }) => id === key || name === key,
    );

    if (foundFilterIndex > -1) {
      filters.splice(foundFilterIndex, 1);
    }

    runInAction(() => {
      this.filters = filters;
      this.updateUrl(filters);
    });
  };

  resetAllFilters = (givenFilters?: Record<string, string | string[]>) => {

    const prepareFilters = () => {
      const defaultFilters: Misc.Filter[] = [];
      if (givenFilters) {
        Object.keys(givenFilters ?? {}).forEach((name) => {
          defaultFilters.push({ id: nanoid(10), name, value: givenFilters[name] });
        });
      }

      const urlFilters = this.getUrlFilters();

      if (!urlFilters.length) {
        return defaultFilters;
      }

      const defaultWithoutUrl = defaultFilters.filter(({ name }) => (
        !urlFilters.find(({ name: urlName }) => name === urlName)
      ));
      return [...urlFilters, ...defaultWithoutUrl];
    };

    const filters: Misc.Filter[] = prepareFilters();

    runInAction(() => {
      this.filters = filters;
      this.updateUrl(filters);
    });
  };
}

export default FilterStore;
