import './index.scss';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode,
};

const FormFieldset = ({ children }: Props): JSX.Element => (
  <div role="group" className="FormFieldset">
    {children}
  </div>
);

export default FormFieldset;
