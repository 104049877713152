import './index.scss';
import { useTranslation } from 'react-i18next';
import type { ReportsNotifications } from 'types/models';
import ReportsCount from 'components/ReportsCount';

type Props = {
  data: ReportsNotifications,
};

const ReportsDetailsCounts = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="ReportsDetailsCounts">
      {data.success > 0 && (
        <ReportsCount count={data.success} variant="success" title={t('reports:status.success')} />
      )}
      {data.error > 0 && (
        <ReportsCount count={data.error} variant="error" title={t('reports:status.error')} />
      )}
      {data.critical > 0 && (
        <ReportsCount count={data.critical} variant="critical" title={t('reports:status.critical')} />
      )}
    </div>
  );
};

export default ReportsDetailsCounts;
