import classnames from 'classnames';
import { Row } from 'react-table';
import { SelectionHookResult, SelectionStateItem } from 'hooks/useSelection';
import FormCheck from 'components/FormCheck';
import DataTableBodyCell from './Cell';

type Props<DataType extends Record<string, any>> = {
  row: Row<DataType>,
  select: SelectionHookResult,
  withActions: boolean,
  hasActions(row: Row<DataType>): boolean,
  getRowClassName?(row: Row<DataType>): string,
  getRowIdentifier: (row: Row<DataType>) => SelectionStateItem,
};

const DataTableBodyRow = <DataType extends Record<string, any>>(props: Props<DataType>): JSX.Element => {
  const {
    row,
    select,
    withActions,
    hasActions,
    getRowClassName,
    getRowIdentifier,
  } = props;
  const { toggleSelect, isSelected } = select;
  const showCheck = hasActions(row);

  const rowIdentifier = getRowIdentifier(row);

  const className = classnames(
    'DataTableBody__row',
    getRowClassName ? getRowClassName(row) : undefined,
    { 'DataTableBody__row--selected': isSelected(rowIdentifier) },
  );

  const firstCellClassName = classnames(
    'DataTableBody__cell',
    { 'DataTableBody__cell--selected': isSelected(rowIdentifier) },
  );

  return (
    <tr className={className}>
      {withActions && (
        <td className={firstCellClassName}>
          {rowIdentifier && showCheck && (
            <FormCheck
              onChange={toggleSelect(rowIdentifier)}
              checked={isSelected(rowIdentifier)}
            />
          )}
        </td>
      )}
      {row.cells.map((cell) => (
        <DataTableBodyCell<DataType>
          {...cell.getCellProps()}
          cell={cell}
          showCheck={showCheck}
          isSelected={isSelected}
          rowIdentifier={rowIdentifier}
        />
      ))}
    </tr>
  );
};

export default DataTableBodyRow;
