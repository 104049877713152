import type { Payer } from 'types/models';
import Request from 'utils/request';

export type FetchOneParams = {
  token?: string,
};

const fetchOne = async ({ token }: FetchOneParams) => {
  if (!token) {
    throw new Error('FetchOnePayer: Missing token.');
  }

  const result = await Request.get<Payer>(`payer/${token}`);
  return result;
};

export { fetchOne };
