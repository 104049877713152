import './index.scss';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { Contact } from 'types/models';
import organizationStore from 'stores/Organization';
import recomposeName from 'utils/recomposeName';
import ContactChannelsIcons from 'components/ContactChannelsIcons';
import Button from 'components/Button';
import ListContactsView from 'components/ListContacts/View';
import getContactRole from 'utils/getContactRole';
import Icon from 'components/Icon';

type Props = {
  contact: Contact,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const DebitPreviewContact = ({ contact, onActionDone, onActionError }: Props): JSX.Element => {
  const { contactRoles } = organizationStore.currentOrganization!;

  const [showViewModal, hideViewModal] = useModal(() => (
    <ListContactsView
      contactId={contact.id}
      onClose={hideViewModal}
      onActionDone={onActionDone}
      onActionError={onActionError}
    />
  ), [contact, onActionDone, onActionError]);

  return (
    <Button variant="list" onClick={showViewModal} className="DebitPreviewContact">
      <div className="DebitPreviewContact__content">
        <div className="DebitPreviewContact__content__description">
          {getContactRole(contact.contactRole.code, contactRoles)}
          <p className="DebitPreviewContact__content__description__name">
            {recomposeName(contact)}
          </p>
        </div>
        <ContactChannelsIcons contact={contact} />
      </div>
      <Icon name="chevron-right" />
    </Button>
  );
};

export default observer(DebitPreviewContact);
