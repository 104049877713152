import './index.scss';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import useIsMountedRef from 'hooks/useIsMountedRef';
import SearchInputAdvanced from 'components/SearchInputAdvanced';
import DataTableFilters from 'components/DataTable/Filters';
import ExportButton from 'components/ExportButton';
import Export from '../../Export';
import getFilterValueFunc from '../getFilterValue';
import FilterItems from '../Items';

type Props = {
  filtersList: Misc.FilterDeclarationItem[],
  currentFilters: Misc.Filter[],
  onApplyFilters(withSubmit?: boolean): void,
  onFilterChange(filter: Misc.Filter): void,
  onCancel(): void,
  container: Element,
  filtersSettings?: Misc.FiltersSettings,
  filtersDescription?: DefaultTFuncReturn,
  filtersTitle?: DefaultTFuncReturn,
  exportLink?: string,
  onRemove?: (name: string) => void,
  onExport?: () => void,
};

const DataTableFiltersSidebar = (props: Props): ReactPortal => {
  const { t } = useTranslation();
  const {
    filtersList,
    currentFilters,
    onApplyFilters,
    onCancel,
    onFilterChange,
    container,
    filtersSettings,
    filtersDescription,
    filtersTitle,
    exportLink,
    onRemove,
    onExport,
  } = props;

  const moreFiltersKeys = filtersSettings?.sideKeys;
  const apply = useRef<boolean>(false);
  const isMounted = useIsMountedRef();

  const handleChangeFilters = useCallback((filter: Misc.Filter) => {
    if (isMounted.current) {
      onFilterChange(filter);
      apply.current = true;
    }
  }, [onFilterChange, isMounted]);

  useEffect(() => {
    if (apply.current) {
      onApplyFilters();
      apply.current = false;
    }
  }, [apply, onApplyFilters]);

  const sidebarFilters = useMemo(() => (
    filtersList.filter((filter) => !moreFiltersKeys || !moreFiltersKeys.includes(filter.key))
  ), [filtersList, moreFiltersKeys]);

  const moreFilters = useMemo(() => (
    filtersList.filter((filter) => moreFiltersKeys && moreFiltersKeys.includes(filter.key))
  ), [filtersList, moreFiltersKeys]);

  const currentMoreFilters = useMemo(() => (
    currentFilters.filter((filter) => moreFiltersKeys && moreFiltersKeys.includes(filter.name))
  ), [currentFilters, moreFiltersKeys]);

  const filterRenderer = useMemo((): Misc.FiltersRenderer => (
    {
      filters: sidebarFilters,
      currentFilters,
      getFilterValue: getFilterValueFunc(currentFilters),
      onChange: handleChangeFilters,
    }
  ), [currentFilters, handleChangeFilters, sidebarFilters]);

  return (
    ReactDOM.createPortal((
      <div className="FiltersSidebar">
        <div className="FiltersSidebar__search">
          <SearchInputAdvanced
            placeholder={t('common:search')}
            currentFilters={currentFilters}
            onChange={handleChangeFilters}
            onRemove={onRemove}
          />
        </div>
        <div className="FiltersSidebar__filters">
          <FilterItems
            filtersSettings={filtersSettings}
            {...filterRenderer}
          />
        </div>
        {moreFilters.length > 0 && (
          <DataTableFilters
            filtersTitle={filtersTitle}
            filtersDescription={filtersDescription}
            filtersList={moreFilters}
            excludedFilter={sidebarFilters.map(({ key }) => key)}
            currentFilters={currentMoreFilters}
            onValidate={() => onApplyFilters(true)}
            onChange={onFilterChange}
            onCancel={onCancel}
          />
        )}
        {exportLink && <Export exportLink={exportLink} />}
        {onExport && (<div className="FiltersSidebar__export-button"><ExportButton onClick={onExport} label={t('common:download')} /></div>)}
      </div>
    ), container)
  );
};

export default DataTableFiltersSidebar;
