import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import type { Payer } from 'types/models';
import type ResponseError from 'utils/errors';
import { SelectionState } from 'hooks/useSelection';
import ListElement from 'components/ListElement';
import DownloadLink from 'components/DownloadLink';
import Icon from 'components/Icon';
import AddressDetails from 'components/AddressDetails';
import DataTable from 'components/DataTable';
import PayerInvoicesColumns from './Columns';

type Props = {
  data: Payer,
  error: ResponseError | null,
  isLoading: boolean,
  onSelectInvoices(select: SelectionState): void,
  onActionDone(message: string): void,
};

const PayerInvoices = (props: Props): JSX.Element => {
  const { data, onSelectInvoices, error, isLoading, onActionDone } = props;
  const { t } = useTranslation();

  const columns = useMemo<Column<Payer>[]>(
    () => PayerInvoicesColumns(t, onActionDone),
    [onActionDone, t],
  );

  const downloadPath = useMemo<string | null>(() => (
    data.invoice ? `${data.id}/${data.token}` : null
  ), [data]);

  const defaultSelected = useMemo<SelectionState>(() => [data.id], [data]);

  return (
    <div className="PayerInvoices">
      <h1 className="PayerInvoices__title">{data?.client.name}</h1>
      <hr className="PayerInvoices__separator" />
      <dl className="PayerInvoices__info">
        <ListElement label={t('payer:payment-to')}>
          {data.organization.corporateName}
        </ListElement>
        <ListElement label={t('payer:referent')}>
          {data.referent?.name || data.organization.sendingEmail || t('common:unknown')}
        </ListElement>
        <ListElement label={t('common:address')}>
          <AddressDetails data={data.organization} />
        </ListElement>
        {data.referent?.email && (
          <ListElement label={t('common:email')}>
            {data.referent.email}
          </ListElement>
        )}
        <ListElement label="" />
        {data.referent?.phone && (
          <ListElement label={t('common:phone')}>
            {data.referent.phone}
          </ListElement>
        )}
      </dl>
      <hr className="PayerInvoices__separator" />
      {downloadPath && (
        <div className="PayerInvoices__download-all">
          <DownloadLink path={downloadPath} withAuthentication={false}>
            <Icon name="download" /> {t('common:download')}
          </DownloadLink>
        </div>
      )}
      <h2 className="PayerInvoices__subtitle">{t('payer:bills-to-pay')}</h2>
      <DataTable<Payer>
        serverPagination={null}
        data={data ? [data] : null}
        isLoading={isLoading}
        defaultSortBy={{ id: 'id', desc: true }}
        fetchData={() => {}}
        error={error}
        columns={columns}
        withActions
        onSelectBulk={onSelectInvoices}
        defaultSelected={defaultSelected}
        noHeader
      />
    </div>
  );
};

export default observer(PayerInvoices);
