import React from 'react';

const useConfirm = () => {
  const [isConfirmShowed, setShowConfirm] = React.useState(false);
  const [confirmTitle, setConfirmTitle] = React.useState<string>('');
  const [confirmText, setConfirmText] = React.useState<string>('');

  const showConfirm = React.useCallback(
    (title: string, text: string) => {
      setShowConfirm(true);
      setConfirmTitle(title);
      setConfirmText(text);
    },
    [],
  );

  const hideConfirm = React.useCallback(
    () => {
      setShowConfirm(false);
    },
    [],
  );

  return {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  };
};

export default useConfirm;
