import './index.scss';
import { useCallback, useState } from 'react';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';
import FormGroupPassword from 'components/FormGroupPassword';

type Props = {
  onSubmit(email: string, password: string): void,
  isLoading?: boolean,
  isInvalid?: boolean,
};

const LoginForm = ({ onSubmit, isLoading = false, isInvalid = false }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = useCallback((value: string) => {
    setEmail(value);
  }, []);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit(email, password);
  };

  return (
    <form className="LoginForm" onSubmit={handleSubmit}>
      <p className="LoginForm__new-user">
        {t('login:new')}
        <a
          className="LoginForm__new-user__link"
          href="https://www.dunforce.com/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('login:contact-us')}
        </a>
      </p>
      <FormGroup controlId="loginEmail" label={t('common:email')}>
        <FormControl
          type="email"
          id="loginEmail"
          placeholder={t('login:enter-email')}
          value={email}
          onChange={handleEmailChange}
          isInvalid={isInvalid}
        />
      </FormGroup>
      <FormGroupPassword
        label={t('common:password')}
        name="loginPassword"
        value={password}
        onChange={setPassword}
      />
      <p className="LoginForm__forgot-password">
        <Link to="/forgotten-password">{t('login:forgot-password')}</Link>
      </p>
      <Button
        variant="black"
        type="submit"
        className="LoginForm__submit"
      >
        {isLoading && <Loading />}
        {!isLoading && t('login:sign-in')}
      </Button>
    </form>
  );
};

export default LoginForm;
