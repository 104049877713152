import type { Eav, EavConstructor } from 'types/models';

interface FormEavValue {
  [eavId: string]: any,
}

const formatEavsFormValues = (eavs: EavConstructor[] | null | undefined, formEav: FormEavValue) => {
  const formatedEavs: Eav[] = [];
  if (!eavs) {
    return [];
  }

  eavs.forEach(({ identifier, required }) => {
    const value = formEav[`eavs[${identifier}]`];
    if (!required && value === '') {
      return;
    }
    formatedEavs.push({ identifier, value });
  });

  return formatedEavs;
};

export default formatEavsFormValues;
