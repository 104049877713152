import './index.scss';
import type { DefaultTFuncReturn } from 'i18next';
import { SyntheticEvent, useCallback, useState } from 'react';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import type { ErrorValidationMessageType } from 'components/ErrorValidationMessage';
import ShowPasswordButton from 'components/ShowPasswordButton';
import FormControl from 'components/FormControl';

type Props = {
  label: DefaultTFuncReturn,
  value: string,
  name: string,
  error?: ErrorValidationMessageType,
  onChange: (newValue: string) => void,
  onShowPassword?: (newValue: boolean) => void,
};

const FormGroupPassword = (props: Props): JSX.Element => {
  const { label, value, name, error, onChange, onShowPassword } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    if (onShowPassword) {
      onShowPassword(!showPassword);
    }
    setShowPassword((prevValue) => !prevValue);
  }, [onShowPassword, showPassword]);

  return (
    <div className="FormGroupPassword">
      <div className="FormGroupPassword__header">
        <label htmlFor="_password-field" className="FormGroupPassword__header__label">
          {label}
        </label>
        <ShowPasswordButton show={showPassword} onClick={handleClickShowPassword} />
      </div>
      <FormControl
        type={showPassword ? 'text' : 'password'}
        id="_password-field"
        name={name}
        autoComplete="off"
        value={value}
        onChange={onChange}
        isInvalid={!!error}
      />
      <ErrorValidationMessage error={error} />
    </div>
  );
};

export default FormGroupPassword;
