import './index.scss';
import Button from 'components/Button';
import Loading from 'components/Loading';

type Props = {
  title: string,
  icon: JSX.Element,
  onClick?(): void,
  isLoading?: boolean,
};

const ActionButton = (props: Props): JSX.Element => {
  const { title, icon, onClick, isLoading } = props;

  return (
    <Button
      variant="list"
      title={title}
      onClick={onClick}
      className="ActionButton"
    >
      {isLoading ? <Loading hasNoText /> : icon}
      {title}
    </Button>
  );
};

export default ActionButton;
