import dayjs from 'dayjs';
import type { EavConstructor } from 'types/models';
import { EAVType } from 'types/models';

interface FormEavValue {
  [eavId: string]: any,
}

export const parseBoolean = (value?: string) => {
  switch (value?.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case 'oui':
    case '1':
      return true;
    case 'false':
    case 'no':
    case 'non':
    case '0':
    case null:
      return false;
    default:
      return Boolean(value);
  }
};

export const transformEavValue = (type: EAVType, value: any) => {
  switch (type) {
    case EAVType.BOOLEAN:
      return parseBoolean(value);
    case EAVType.DATE:
      const date = dayjs(value);
      return date.format('YYYY-MM-DD');
    case EAVType.NUMBER:
      return Number(value);
    case EAVType.STRING:
      return value === '-' ? null : value;
  }
};

const formatEavsValues = (eavs: EavConstructor[] | null | undefined, formEav: FormEavValue) => {
  const formatedEavs: any = {};
  if (!eavs) {
    return formatedEavs;
  }

  eavs.forEach(({ identifier, type }) => {
    const value = formEav[`eavs[${identifier}]`];
    formatedEavs[identifier] = !!value ? transformEavValue(type, value) : null;
  });

  return formatedEavs;
};

export default formatEavsValues;
