import './index.scss';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import apiContacts from 'api/contacts';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import SplitButtonDropdown from 'components/SplitButtonDropdown';

type Props = {
  id: number,
  name: string,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const ContactsRowActions = (props: Props): JSX.Element => {
  const { id, name, onActionDone, onActionError } = props;
  const { t } = useTranslation();
  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('contacts:remove'), t('contacts:actions.confirm.remove'));
  }, [showConfirm, t]);

  const { remove, error, isLoading } = useApiRequest();

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const response = await remove(apiContacts.deleteUrl(id));
    if (response) {
      onActionDone(t('contacts:actions.toast.deleted', { name }));
    }
  }, [hideConfirm, remove, id, onActionDone, t, name]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div className="ContactsRowActions">
      <SplitButtonDropdown
        to={`/contacts/edit/${id}`}
        title={t('common:edit')}
      >
        <Button
          variant="list-danger"
          onClick={handleConfirmDelete}
          title={t('contacts:remove')}
          disabled={isLoading}
        >
          {isLoading && <Loading hasNoText />}
          {!isLoading && t('common:remove')}
        </Button>
      </SplitButtonDropdown>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default observer(ContactsRowActions);
