import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import type { Column } from 'react-table';
import Config from 'config';
import type Misc from 'types/misc';
import type { CreditSummary } from 'types/models';
import type { FetchAllParams } from 'api/credits';
import apiCredits from 'api/credits';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import creditsFilters from 'stores/FilterStores/CreditsFilters';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import FormSelect from 'components/FormSelect';
import LinkSwitch from 'components/LinkSwitch';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import CreditEditPage from '../CreditEdit';
import CreditViewPage from '../Credit';
import CreditsColumns from './Columns';

type RouteParams = {
  modalType?: 'new' | 'edit' | 'view',
  reference?: string,
};

const Credits = (): JSX.Element => {
  const { modalType } = useParams<RouteParams>();
  const history = useHistory();
  const { currentOrganization, selectedBusinessUnitsIdentifier } = organizationStore;
  const { user } = authStore;
  const { id, type } = currentOrganization ?? { type: null };
  const { ct } = useContextualTranslation(type);
  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<CreditSummary>>(
    defaultFetchOptions,
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = creditsFilters;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: selectedBusinessUnitsIdentifier }];
  }, [filters, selectedBusinessUnitsIdentifier]);

  const {
    data,
    serverPagination,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchPaginated<FetchAllParams, CreditSummary>(
    {
      cacheKey: 'credits',
      organizationId: id,
      params: {
        page: fetchOptions.pageIndex,
        organizationReference: currentOrganization?.reference,
        pageSize: fetchOptions.pageSize,
        locale: user?.locale,
        filtering,
        sort: fetchOptions.sort,
      },
    },
    apiCredits.all,
    { enabled: !!id },
  );

  const handleActionDone = useCallback((title: string, message: string) => {
    showToast(message, 'success');
    refetch();
  }, [showToast, refetch]);

  const handleViewEditModalsClose = useCallback(() => {
    history.replace('/credits');
  }, [history]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const handleSelectCreditNotes = useCallback((name: string, value: string | null) => {
    if (value && value !== '/credits') {
      history.replace(value);
    }
  }, [history]);

  const columns = useMemo<Column<CreditSummary>[]>(
    () => CreditsColumns(ct, handleActionDone, handleActionError, history.push),
    [ct, handleActionDone, handleActionError, history],
  );

  const totalResults = useMemo(() => (
    filters.length > 0 ? serverPagination?.totalRecords : null
  ), [filters, serverPagination]);

  useEffect(() => {
    setFetchOptions(fetchOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle.
  /*const exportLink = useMemo<string>(
    () => ExportLink(fetchOptions, filters, reference || null),
    [reference, fetchOptions, filters],
  );*/

  const handleEdit = useCallback((creditId: number) => {
    history.push(`/credits/edit/${creditId}`);
  }, [history]);

  const handleSwitchChange = useCallback((listing: string) => {
    if (!listing.startsWith('credits')) {
      history.push(`/${listing}?${getFiltersQueryString(filters, ['search', 'client'])}`);
    }
  }, [filters, history]);

  const switchListing = (
    <LinkSwitch
      valueLabels={[
        { value: 'debits', label: ct('debits:bills') },
        { value: 'credits', label: ct('debits:payments') },
      ]}
      defaultValue="credits"
      onChange={handleSwitchChange}
    />
  );

  return (
    <PageList
      className="Credits"
      count={totalResults}
      isFetching={isFetching}
      title={ct('credits:title')}
      actions={switchListing}
    >
      <DataTable<CreditSummary>
        columns={columns}
        data={data}
        serverPagination={serverPagination}
        filtering={filters}
        onRemoveFilter={removeFilter}
        onChangeFilters={addOrUpdateFilters}
        fetchData={setFetchOptions}
        isLoading={isLoading}
        error={error}
        statusSelector={(
          <FormSelect
            selectOptions={[
              { label: ct('debits:payments'), value: '/credits' },
              { label: ct('common:credit-notes'), value: '/credit-notes' },
            ]}
            defaultValue="/credits"
            className="Credits__page-select"
            name="select"
            onSelect={handleSelectCreditNotes}
            withClearButton={false}
          />
        )}
        withNoDataDrawing
        withActions={false}
      />
      {modalType && ['new', 'edit'].includes(modalType) && (
        <CreditEditPage onDone={handleActionDone} onClose={handleViewEditModalsClose} />
      )}
      {modalType && modalType === 'view' && (
        <CreditViewPage onClickEdit={handleEdit} onClose={handleViewEditModalsClose} />
      )}
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </PageList>
  );
};

export default observer(Credits);
