import './index.scss';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';

interface Props {
  selection?: (number | string)[],
  onDeleteId: (idToDelete: string[]) => void,
}

const CustomersBulkActions: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { selection, onDeleteId } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(
      t('common:remove'),
      t('imports:confirm.bulk-remove', { count: selection?.length }),
    );
  }, [showConfirm, t, selection]);

  const handleSubmitDelete = useCallback(async () => {
    if (!selection?.length) {
      return;
    }

    onDeleteId(selection as string[]);
    hideConfirm();
  }, [selection, hideConfirm, onDeleteId]);

  if (!selection?.length) {
    return null;
  }

  return (
    <div className="CustomersBulkActions">
      <Button
        variant="outline-light"
        onClick={handleConfirmDelete}
        className="CustomersBulkActions__delete"
      >
        {t('common:remove')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default CustomersBulkActions;
