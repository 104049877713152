import './index.scss';
import { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import formatIntlDate from 'utils/formatIntlDate';
import getI18nActionWebHook from 'utils/getI18nActionWebHook';
import type { WebHookSMS, WebHookEmail, WebHookLetter } from 'types/models';
import { Channel } from 'types/models';
import { hasEventError } from './utils';

type Props = {
  event: WebHookSMS | WebHookEmail | WebHookLetter,
  channel: Channel,
};

const HistoryStepWebHookStatusItem = ({ event, channel }: Props): JSX.Element => {
  const { t } = useTranslation();

  const label = useMemo(() => {
    const { roleName, type } = event;

    const labelText = t(getI18nActionWebHook(channel, event), {
      date: formatIntlDate(event.at, 'dateTimeFullShortMonth'),
      roleName,
    });

    const isLetter = [Channel.LETTER, Channel.LETTER_REGISTERED].includes(channel);
    const hasProof = ['letter.filing_proof', 'letter.delivery_proof'].includes(type);

    if (isLetter && hasProof && (event as WebHookLetter).file) {
      return (
        <a href={(event as WebHookLetter).file} target="_blank" rel="nofollow noreferrer">
          {labelText}
        </a>
      );
    }

    return labelText;
  }, [t, event, channel]);

  const classNames = classnames('HistoryStepWebHookStatusItem', {
    'HistoryStepWebHookStatusItem--error': hasEventError(channel, event),
  });

  return <div className={classNames}>{label}</div>;
};

export default HistoryStepWebHookStatusItem;
