import './index.scss';
import classnames from 'classnames';
import { StepActionEntity } from 'types/models';
import { Channel } from 'types/models';
import ChannelIcon from 'components/ChannelIcon';
import Icon from 'components/Icon';

type Props = {
  entity: StepActionEntity,
  channel: Channel | null,
};

const StepActionIcon = ({ entity, channel }: Props): JSX.Element | null => {
  const classNames = classnames('StepActionIcon', {
    'StepActionIcon--reminder': entity === StepActionEntity.REMINDER,
    'StepActionIcon--task': entity === StepActionEntity.TASK,
    'StepActionIcon--registered-letter': channel === Channel.LETTER_REGISTERED,
  });

  return (
    <div className={classNames}>
      <div className="StepActionIcon__icon">
        {entity === StepActionEntity.REMINDER && <ChannelIcon channel={channel} />}
        {entity === StepActionEntity.TASK && <Icon name="list" />}
      </div>
    </div>
  );
};

export default StepActionIcon;
