import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { ValidationErrors } from 'types/errors';
import type {
  CreditPost,
  Credit,
  Debit,
  CreditType,
  PaymentMethod,
  CreditOrigin,
  ImputationIdentifiers,
} from 'types/models';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import apiUsers from 'api/users';
import apiDebits from 'api/debits';
import apiClients from 'api/clients';
import apiCredits from 'api/credits';
import apiCurrencies from 'api/currencies';
import apiOrganization from 'api/organization';
import type { FetchOneParams } from 'api/credits';
import type { FetchAllForCustomerParams } from 'api/debits';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch2';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ModalFormData } from 'components/ModalForm';
import CreditEditForm from './Form';

type Props = {
  creditId?: Credit['id'],
  customerIdentifier?: number,
  onDone(title: string, message: string): void,
  onClose(): void,
  shouldRedirectToCreatedEntity?: boolean,
  assignedTo?: string,
  maxAmount?: number,
};

const CreditEditModal = (props: Props): JSX.Element => {
  const {
    creditId,
    customerIdentifier,
    assignedTo,
    onDone,
    onClose,
    shouldRedirectToCreatedEntity = false,
    maxAmount,
  } = props;
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { id: organizationId, reference: organizationReference, type } = organizationStore.currentOrganization!;
  const { user } = authStore;
  const { t, ct } = useContextualTranslation(type);
  const cache = useQueryClient();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [selectedCustomerIdentifier, setSelectedCustomerIdentifier] = useState<number | null>(
    customerIdentifier || null,
  );
  const initialData = useRef<CreditPost | null>(null);

  const isNew = useMemo(() => !creditId, [creditId]);

  const { post, put, cancel, error } = useApiRequest();

  const { data: creditData, isLoading } = useFetch<FetchOneParams, Credit>(
    {
      cacheKey: 'credit',
      id: creditId!,
    },
    apiCredits.one,
    { enabled: !!creditId },
  );

  const {
    data: allDebits,
  } = useFetch<FetchAllForCustomerParams, Debit[]>(
    {
      cacheKey: 'customerPossibleDebits',
      id: selectedCustomerIdentifier!,
    },
    apiDebits.allForCustomer,
    { enabled: !!selectedCustomerIdentifier },
  );

  const {
    isLoading: isLoadingPossibleDebits,
    data: possibleDebits,
  } = useFetch<FetchAllForCustomerParams, Debit[]>(
    {
      cacheKey: 'customerDebits',
      id: selectedCustomerIdentifier!,
      // options: { pageIndex: 1, pageSize: 10 }, // TODO : add total items limit
    },
    apiDebits.allForCustomerImputable,
    { enabled: !!selectedCustomerIdentifier },
  );

  useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const mapFormData = useCallback((rawData: ModalFormData) => ({
    identifier: rawData.reference as string,
    organization: apiOrganization.resourceUrl(organizationId),
    createdBy: apiUsers.resourceUrl(user!.id),
    currency: apiCurrencies.resourceUrl(parseInt(rawData.currency as string)),
    client: apiClients.resourceUrl(parseInt(rawData.client as string)),
  }), [organizationId, user]);

  const mapCreditData = useCallback((rawData: ModalFormData) => ({
    ...(mapFormData(rawData)),
    type: rawData.type as CreditType,
    amount: rawData.amount as string,
    label: rawData.subject as string,
    paidAt: rawData.paidAt as string,
    paymentMethod: rawData.method as PaymentMethod,
    origin: rawData.origin as CreditOrigin,
  }), [mapFormData]);

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapCreditData(formData) : null;
  }, [initialData, mapCreditData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
  }, [onClose]);

  useEffect(() => {
    const { code } = error || { code: 0 };
    if (code === 404 || code === 2) {
      closeSelf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const processImputations = async (formData: ModalFormData, id: number, reference: string) => {
    const imputations = Object.keys(formData)
      .filter((keyName) => keyName.startsWith('debits-reference'))
      .map(async (keyName: string, index: number) => {
        const imputationId = possibleDebits?.find(({ reference: ref }) => (
          ref === formData[keyName] as string
        ))?.id || 0;
        const result = await post<ImputationIdentifiers>(apiCredits.createImputationUrl, {
          ...(mapFormData(formData)),
          amount: Number.parseFloat(
            formData[`debits-amountAssigned-${index}`] as string,
          ).toString(),
          credit: apiCredits.resourceUrl(id),
          debit: apiDebits.resourceUrl(imputationId),
          identifier: `${reference}${imputationId}`,
        },
        );
        return result;
      });

    return Promise.all(imputations);
  };

  const handleChange = useCallback((formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    const data = mapCreditData(formData);
    if (!data) {
      return;
    }

    setSelectedCustomerIdentifier(parseInt(formData.client as string));
    const { ...initData } = initialData.current || { users: [] };
    const { ...changedData } = data;
    setHasChanges(!equal(initData, changedData));
  }, [initialData, mapCreditData]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
      return;
    }

    closeSelf();
  }, [hasChanges, closeSelf]);

  const handleSubmit = async (formData: ModalFormData | null) => {
    if (!formData || !organizationReference) {
      return;
    }
    setValidationErrors(null);

    const imputationsTotal = Object.keys(formData)
      .filter((key) => key.startsWith('debits-amountAssigned'))
      .reduce((sum, key) => sum + parseFloat(formData[key] as string), 0);

    if (imputationsTotal > parseFloat(formData.amount as string)) {
      setValidationErrors({ imputation: { code: -1, message: t('credits:errors.greater-than-credit') } });
      return;
    }

    const data = mapCreditData(formData);
    if (!data) {
      return;
    }

    let result;
    if (isNew) {
      result = await post<Credit>(apiCredits.createUrl, data);
    } else if (creditData) {
      result = await put<Credit>(apiCredits.updateUrl(creditData.id), { data, imputations: [] });
    }

    if (result?.id) {
      await processImputations(formData, result?.id, result?.reference);
    }

    cache.invalidateQueries({ queryKey: ['credit'] });
    cache.invalidateQueries({ queryKey: ['credits'] });

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (!result?.reference) {
      return;
    }

    const { id, reference: resultReference } = result;
    if (isNew) {
      onDone(
        ct('credits:toast.created'),
        ct('credits:toast.created-reference', { reference: resultReference }),
      );
    } else {
      onDone(
        ct('credits:toast.modified'),
        ct('credits:toast.modified-reference', { reference: resultReference }),
      );
    }

    closeSelf();

    if (shouldRedirectToCreatedEntity) {
      history.push(`/credits/view/${id}`);
    }
  };

  return (
    <ModalForm
      className="CreditEdit"
      title={isNew ? ct('common:new-payment') : ct('common:edit-payment')}
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isFetched={!isLoading}
      isLoading={isLoading && !isNew}
    >
      {error && <ErrorMessage error={error} />}
      <CreditEditForm
        customerId={selectedCustomerIdentifier}
        defaultData={creditData}
        isCreate={isNew}
        assignedTo={assignedTo}
        maxAmount={maxAmount}
        isLoadingPossibleDebits={isLoadingPossibleDebits}
        possibleDebits={possibleDebits ?? []}
        allDebits={allDebits ?? []}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(CreditEditModal);
