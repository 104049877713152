import './index.scss';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Config from 'config';

export type Props = {
  path: string,
  className?: string,
  withDownload?: boolean,
  withAuthentication?: boolean,
  children: ReactNode,
};

const DownloadLink = (props: Props): JSX.Element => {
  const {
    path,
    className,
    withDownload = true,
    children,
    withAuthentication = true,
  } = props;
  const { t } = useTranslation();

  const url = useMemo(() => {
    const authToken = window.localStorage.getItem('token');
    const params = [];
    if (withAuthentication) {
      params.push(`access_token=${authToken}`);
    }

    return [
      `${Config.API_URL}${withDownload ? 'download/' : ''}`,
      `${path}${path.includes('?') ? '&' : '?'}`,
      params.join('&'),
    ].join('');
  }, [path, withAuthentication, withDownload]);

  const linkClassName = classnames('DownloadLink', className);

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={t<string>('common:download')}
      className={linkClassName}
    >
      {children}
    </a>
  );
};

export default DownloadLink;
