import './index.scss';
import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';

interface Props {
  className?: string,
  max?: number,
  min?: number,
  localStorageKey: string,
  children: ReactNode,
}

const Resizable = ({ className, localStorageKey, children, min, max }: Props) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(
    localStorage.getItem(`${localStorageKey}_open`) !== 'false',
  );
  const [width, setWidth] = useState<number | null>(
    parseInt(localStorage.getItem(localStorageKey) || '0', 10) || null,
  );
  const [initialMouseX, setInitialMouseX] = useState<number | null>(null);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!isDragging || !sidebarRef.current || initialMouseX === null) {
      return;
    }

    setWidth((prevWidth) => {
      const newWidth = (prevWidth || sidebarRef.current!.offsetWidth) + (e.movementX / 2);
      const threshold = min ? min / 2 : 0;

      if (min !== undefined && newWidth < min) {
        if (e.clientX <= initialMouseX - threshold) {
          setSidebarOpen(false);
        }
        return min;
      }

      if (max !== undefined && newWidth > max) {
        return max;
      }

      return newWidth;
    });
  }, [isDragging, min, max, initialMouseX]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    setIsDragging(true);
    setInitialMouseX(e.clientX);
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, `${width}`);
  }, [width, localStorageKey]);

  useEffect(() => {
    localStorage.setItem(`${localStorageKey}_open`, `${isSidebarOpen}`);
  }, [isSidebarOpen, localStorageKey]);

  const classNames = classnames('Resizable', className, {
    'Resizable--close': !isSidebarOpen,
    'Resizable--resizing': isDragging,
  });

  return (
    <Fragment>
      <div
        className={classNames}
        style={width ? { width: `${width}px` } : {}}
        ref={sidebarRef}
      >
        <button
          className="Resizable__toggle"
          onClick={() => setSidebarOpen(true)}
        >
          <Icon name="sidebar-open" />
        </button>
        {children}
      </div>
      <button
        className="Resizable__handler"
        onMouseDown={handleMouseDown}
        aria-label="Resize sidebar"
      />
    </Fragment>
  );
};

export default Resizable;
