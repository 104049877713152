import './index.scss';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import InputModal from 'components/InputModal';
import apiDebits from 'api/debits';
import { DebitStatus } from 'types/models';
import type { Debit } from 'types/models';

type Props = {
  selection: Debit['id'][],
  onLoading(isLoading: boolean): void,
  onDone(message: string): void,
};

const DebitsBulkActionPromiseToPay = (props: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const { selection, onLoading, onDone } = props;

  const { patch, error, isLoading } = useApiRequest();

  const handleSubmit = useCallback(
    async (value: { [field: string]: string }) => {

      const payload = selection
        .map((id) => JSON.stringify({
          id,
          promiseToPayAt: value.date,
          status: DebitStatus.PROMISE,
        }))
        .join('\n');

      const result = await patch<Misc.BulkStatusCode>(
        apiDebits.bulkUpdateUrl,
        payload,
      );

      if (!result) {
        return;
      }

      onDone(t(
        'debits:actions.toast.bulk-promise-to-pay-defined',
        { count: selection.length },
      ));
    },
    [patch, selection, onDone, t],
  );

  useEffect(
    () => { onLoading(isLoading); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const [showInputModal, hideInputModal] = useModal(() => (
    <InputModal
      isShow
      title={t('debits:bulk-edit-promise-to-pay')}
      help={ct('debits:bulk-edit-promise-to-pay-for-count', { count: selection.length })}
      type="date"
      name="date"
      isLoading={isLoading}
      error={error}
      minDate={new Date()}
      onSubmit={handleSubmit}
      onCancel={hideInputModal}
    />
  ), [t, ct, selection, isLoading, error, handleSubmit]);

  return (
    <Button
      variant="outline-light"
      onClick={showInputModal}
      className="DebitsBulkActionPromiseToPay"
    >
      {t('debits:bulk-edit-promise-to-pay', { count: selection.length })}
    </Button>
  );
};

export default observer(DebitsBulkActionPromiseToPay);
