import './index.scss';
import React from 'react';
import FormCheck from 'components/FormCheck';

interface Props {
  filterKey: string,
  title: string,
  isChecked: (key: string) => boolean,
  onCheck: (key: string, checked: boolean) => void,
}

const SelectGroupItem: React.FC<Props> = (props) => {
  const {
    filterKey,
    title,
    isChecked,
    onCheck,
  } = props;

  const handleChange = React.useCallback(
    (checked: boolean) => {
      onCheck(filterKey, checked);
    },
    [onCheck, filterKey],
  );

  return (
    <div className="SelectGroupItem">
      <FormCheck
        id={filterKey}
        onChange={handleChange}
        checked={isChecked(filterKey)}
      />
      <div className="SelectGroupItem__name">{title}</div>
    </div>
  );
};

export default SelectGroupItem;
