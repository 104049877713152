import { Channel } from 'types/models';

const getI18nChannel = (channel: Channel): string => {
  const i18nChannel = {
    [Channel.EMAIL]: 'common:channels-name.email',
    [Channel.SMS]: 'common:channels-name.sms',
    [Channel.LETTER]: 'common:channels-name.letter',
    [Channel.LETTER_REGISTERED]: 'common:channels-name.registered-letter',
  };

  return i18nChannel[channel];
};

export default getI18nChannel;
