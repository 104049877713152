import './index.scss';
import classnames from 'classnames';
import { ReactComponent as DunforceLogoSymbol } from './assets/logo-symbol.svg';
import { ReactComponent as DunforceLogoText } from './assets/logo-text.svg';

type Props = {
  className?: string
};

const BrandLogo = ({ className }: Props): JSX.Element => (
  <div className={classnames('BrandLogo', className)}>
    <DunforceLogoSymbol />
    <DunforceLogoText />
  </div>
);

export default BrandLogo;
