import './index.scss';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreditStatus, RightsCategoryType } from 'types/models';
import type { Credit } from 'types/models';
import ListElement from 'components/ListElement';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';
import getI18nCreditStatus from 'utils/getI18nCreditStatus';
import formatIntlDate from 'utils/formatIntlDate';
import ButtonWithUserRights from 'components/ButtonWithUserRights';

type Props = {
  credit?: Credit | null,
  onClickEdit(id: Credit['id']): void,
};

const CreditViewModalContent = ({ credit, onClickEdit }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const handleEdit = useCallback(() => {
    if (!credit) {
      return;
    }
    onClickEdit(credit.id);
  }, [credit, onClickEdit]);

  if (!credit) {
    return null;
  }

  const {
    client,
    paidAt,
    totalAmountPayment,
    currency,
    paymentMethod,
    status,
    amountNotAssigned,
    subject,
    imputations,
  } = credit;

  return (
    <div className="CreditViewModalContent">
      <dl className="CreditViewModalContent__details">
        <ListElement label={t('common:client')}>
          <Link to={`/customer/${client.id}`}>
            {client.name}
          </Link>
        </ListElement>
        <ListElement label={t('common:date')}>
          {formatIntlDate(paidAt, 'dateFull')}
        </ListElement>
        <ListElement label={t('common:amount')}>
          {formatIntlNumber(totalAmountPayment, currency)}
        </ListElement>
        <ListElement label={t('credits:payment-method-short')}>
          {t(getI18nPaymentMethod(paymentMethod ?? 'OTHER'))}{' '}
        </ListElement>
        <ListElement label={t('credits:to-impute')}>
          {formatIntlNumber(amountNotAssigned, currency)}
        </ListElement>
        <ListElement label={t('credits:subject')}>
          {subject || '--'}
        </ListElement>
      </dl>
        <div className="CreditViewModalContent__assignations">
          <div className="CreditViewModalContent__assignations__title">
            {t('credits:imputation')}
          </div>
          {(!imputations || imputations.length === 0) && (
            <div className="CreditViewModalContent__assignations__no-data">
              {status === CreditStatus.SUCCESS && (
                <ButtonWithUserRights
                  variant="outline"
                  onClick={handleEdit}
                  managers={credit.users}
                  action="CREATE"
                  categoryType={RightsCategoryType.PAYMENTS}
                >
                  {t('credits:to-impute')}
                </ButtonWithUserRights>
              )}
              {status !== CreditStatus.SUCCESS && (
                <span>{t('credits:not-imputable')} ({t(getI18nCreditStatus(status))})</span>
              )}
            </div>
          )}
          {imputations && imputations.length > 0 && (
            <ul className="CreditViewModalContent__assignations__list">
              {imputations.map(({ debitId: id, debitIdentifier: reference, paymentAssignedAmount: amount }) => (
                <li key={reference} className="CreditViewModalContent__assignations__list__item">
                  <Link to={`/debit/${id}`}>
                    {reference}
                  </Link>,{' '}
                  {t('credits:for-amount', {
                    amount: formatIntlNumber(amount, currency),
                  })}
                </li>
              ))}
            </ul>
          )}
        </div>
    </div>
  );
};

export default CreditViewModalContent;
