import config from 'config';
import type { CurrencyCode } from 'types/models';
import authStore from 'stores/Auth';

const formatIntlNumber = (
  value: number | null | undefined,
  currency?: CurrencyCode | null,
  minimumFractionDigits: number = 2,
): string => {
  const options = currency ? {
    style: 'currency',
    minimumFractionDigits,
    currency: currency as string,
  } : undefined;
  const number = (value && minimumFractionDigits === 0) ? Math.round(value) : value;
  const safeValue = (!number || Number.isNaN(number)) ? 0 : number;

  const removeFractionDigits = (safeValue - Math.floor(safeValue) === 0);

  const { locale } = authStore.user || config.DEFAULT_LOCALE;
  const safeLocale = locale.length < 2 ? config.DEFAULT_LOCALE.locale : locale;

  const intlObect = new Intl.NumberFormat(
    safeLocale.replace('_', '-'),
    {
      ...options,
      minimumFractionDigits: removeFractionDigits ? 0 : minimumFractionDigits,
    },
  );

  return intlObect.format(safeValue);
};

export default formatIntlNumber;
