import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import FormDatePicker from 'components/FormDatePicker';
import FormGroup from 'components/FormGroup';
import Button from 'components/Button';
import Icon from 'components/Icon';

interface Props {
  onChange: (value: string | null) => void,
  recentCount?: number,
}

const ButtonDatePicker: React.FC<Props> = ({ onChange, recentCount }) => {
  const { t } = useTranslation();
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (isDisplayed) {
      const formElement: HTMLElement = formRef.current as HTMLElement;
      const datePicker: HTMLElement = formElement.getElementsByClassName('react-date-picker__inputGroup')?.item(0) as HTMLElement;
      datePicker.click();
    }
  }, [isDisplayed]);

  const handleChange = useCallback((name: string, value: string | null) => {
    if (!value) {
      setIsDisplayed(false);
    }
    onChange(value);
  }, [onChange]);

  return (
    <form
      ref={formRef}
      className="ImportReports__filters__input"
    >
      <FormGroup>
        {isDisplayed ? (
          <FormDatePicker name="date" onChange={handleChange} withClearIcon />
        ) : (
          <Button variant="outline" onClick={() => setIsDisplayed(true)}>
            <span className="ImportReports__filters__input__label">
              {t('reports:recent')}<span className="ImportReports__filters__input__count">{recentCount}</span>
            </span>
            <Icon name="caret-down" />
          </Button>
        )}
      </FormGroup>
    </form>
  );
};

export default observer(ButtonDatePicker);
