import './index.scss';
import { useCallback, useMemo } from 'react';
import SelectSearch from 'react-select-search';

import type { SelectSearchOption } from 'react-select-search';
import type Misc from 'types/misc';

type Props = {
  name: string,
  options: Misc.ValueLabel[],
  value?: string,
  onChange(name: string, newValue: string): void,
};

const SmallSelect = ({ name, options, value, onChange }: Props): JSX.Element => {
  const selectOptions = useMemo<SelectSearchOption[]>(() => (
    options.map(({ value: optionValue, label }) => ({
      name: label,
      value: optionValue,
    }))
  ), [options]);

  const handleChange = useCallback((newValue: string | string[]) => {
    onChange(name, `${newValue}`);
  }, [name, onChange]);

  return (
    <div className="SmallSelect">
      <SelectSearch
        options={selectOptions}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};

export default SmallSelect;
