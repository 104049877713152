import './index.scss';
import { TableBodyProps, Row } from 'react-table';
import { SelectionHookResult, SelectionStateItem } from 'hooks/useSelection';
import DataTableBodyRow from './Row';

type Props<DataType extends Record<string, any>> = {
  tableProps: TableBodyProps,
  page: Row<DataType>[],
  prepareRow(row: Row<DataType>): void,
  select: SelectionHookResult,
  withActions: boolean,
  hasActions(row: Row<DataType>): boolean,
  getRowClassName?(row: Row<DataType>): string,
  getRowIdentifier: (row: Row<DataType>) => SelectionStateItem,
};

const DataTableBody = <DataType extends Record<string, any>>(props: Props<DataType>): JSX.Element | null => {
  const {
    tableProps,
    page,
    prepareRow,
    select,
    withActions,
    hasActions,
    getRowClassName,
    getRowIdentifier,
  } = props;

  return (
    <tbody {...tableProps} className="DataTableBody">
      {page.map((row) => {
        prepareRow(row);
        return (
          <DataTableBodyRow<DataType>
            {...row.getRowProps()}
            row={row}
            select={select}
            withActions={withActions}
            hasActions={hasActions}
            getRowClassName={getRowClassName}
            getRowIdentifier={getRowIdentifier}
          />
        );
      })}
    </tbody>
  );
};

export default DataTableBody;
