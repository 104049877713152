import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { User } from 'types/models';
import { RightsCategory, RightsRole } from 'types/models';
import apiCollaborators from 'api/collaborator';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';
import Button from 'components/Button';
import { UserRightsController } from 'components/UserRights';
import ModalEditSignature from 'components/ModalEditSignature';
import CollaboratorEditModal from '../CollaboratorEditModal';

type Props = {
  collaborator: User,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const CollaboratorRowActions = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const isMountedRef = useIsMountedRef();

  const {
    onActionDone,
    onActionError,
    collaborator,
  } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { remove, error, isLoading } = useApiRequest();

  const category = useMemo<RightsCategory>(() => {
    const filteredRoles = collaborator.roles.filter((role) => role !== RightsRole.ROLE_USER);
    const currentRole = filteredRoles.length > 0 ? filteredRoles[0] : null;
    switch (currentRole) {
      case 'ROLE_COORDINATOR':
        return RightsCategory.ORGANIZATION_COLLABORATORS_ROLE_COORDINATOR;
      case 'ROLE_ACCOUNT_MANAGER':
        return RightsCategory.ORGANIZATION_COLLABORATORS_ROLE_ACCOUNT_MANAGER;
      case 'ROLE_ADMINISTRATOR':
        return RightsCategory.ORGANIZATION_COLLABORATORS_ROLE_ADMINISTRATOR;
      default:
        return RightsCategory.ORGANIZATION_COLLABORATORS_ROLE_ADMINISTRATOR;
    }
  }, [collaborator]);

  const [showMyCollaboratorEditModal, hideMyCollaboratorEditModal] = useModal(() => (
    <CollaboratorEditModal
      onClose={hideMyCollaboratorEditModal}
      onActionDone={onActionDone}
      onActionError={onActionError}
      collaborator={collaborator}
    />
  ), [onActionError, onActionDone, collaborator]);

  const [showModalEditSignatureLetter, hideModalEditSignatureLetter] = useModal(() => (
    <ModalEditSignature
      signatureType="letter"
      signature={collaborator.signatureLetter ?? null}
      onClose={hideModalEditSignatureLetter}
      onShowToast={onActionDone}
      collaborator={collaborator}
    />
  ), [onActionDone, collaborator]);

  const [showModalEditSignatureEmail, hideModalEditSignatureeEmail] = useModal(() => (
    <ModalEditSignature
      signatureType="email"
      signature={collaborator.signatureEmail ?? null}
      onClose={hideModalEditSignatureeEmail}
      onShowToast={onActionDone}
      collaborator={collaborator}
    />
  ), [onActionDone, collaborator]);

  const collaboratorName = useMemo(() => (
    `${collaborator.firstName} ${collaborator.lastName}`
  ), [collaborator]);

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('collaborators:remove'), t('collaborators:confirm-remove', { name: collaboratorName }));
  }, [showConfirm, t, collaboratorName]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();

    const response = await remove(apiCollaborators.deleteUrl(collaborator.id));
    if (isMountedRef && response === '') {
      onActionDone(t('collaborators:toast.success.collaborator-deleted', { name: collaboratorName }));
    }
  }, [hideConfirm, onActionDone, t, collaborator, collaboratorName, remove, isMountedRef]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('collaborators:toast.error.collaborator-deleted', { name: collaboratorName }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div>
      <DropdownActions
        actions={[
          <UserRightsController
            action="UPDATE"
            category={category}
          >
            <Button
              variant="list"
              onClick={showMyCollaboratorEditModal}
              title={t('common:edit')}
            >
              {isLoading && <Loading hasNoText />}
              {!isLoading && t('common:edit')}
            </Button>
          </UserRightsController>,
          <UserRightsController
            action="UPDATE"
            category={category}
          >
            <Button
              variant="list"
              onClick={showModalEditSignatureEmail}
              title={t('users:signature-email')}
            >
              {isLoading && <Loading hasNoText />}
              {!isLoading && t('users:signature-email')}
            </Button>
          </UserRightsController>,
          <UserRightsController
            action="UPDATE"
            category={category}
          >
            <Button
              variant="list"
              onClick={showModalEditSignatureLetter}
              title={t('users:signature-letter')}
            >
              {isLoading && <Loading hasNoText />}
              {!isLoading && t('users:signature-letter')}
            </Button>
          </UserRightsController>,
          <UserRightsController
            action="DELETE"
            category={category}
          >
            <Button
              variant="list-danger"
              onClick={handleConfirmDelete}
              title={t('common:remove')}
            >
              {isLoading && <Loading hasNoText />}
              {!isLoading && t('common:remove')}
            </Button>
          </UserRightsController>,
        ]}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={collaboratorName}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default CollaboratorRowActions;
