import './index.scss';
import { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import classNames from 'classnames';
import { RightsRole, ScenarioSummary } from 'types/models';
import type ResponseError from 'utils/errors';
import Misc from 'types/misc';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import customerContext from 'pages/Customer/customerContext';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import ScenarioDetails from 'components/ScenarioDetails/index';
import Button from 'components/Button';
import CustomerMuteButton from 'components/CustomerMuteButton';
import GoBackButton from 'components/GoBackButton';
import CustomerHeaderActions from './Actions';

type Props = {
  isLoading?: boolean,
  error?: ResponseError | null,
  onDone(title: string, message: string): void,
  onActionError(message: string): void,
};

const CustomerHeader = (props: Props): JSX.Element => {
  const { isLoading = false, error, onActionError, onDone } = props;
  const { t } = useTranslation();
  const { data: customer } = useContext(customerContext);
  const { currency } = organizationStore.currentOrganization!;
  const isUserReader = authStore.user?.roles.includes(RightsRole.ROLE_READER);
  const scenarios = useMemo(() => customer?.scenario || null, [customer]);
  const [previewScenario, setPreviewScenario] = useState<ScenarioSummary | null>(null);

  const [showScenarioDetails, hideScenarioDetails] = useModal(() => (
    previewScenario ? (
      <ScenarioDetails
        scenario={previewScenario.id}
        referenceDate={(previewScenario.referenceDate as Misc.DateTimezone | null)?.date}
        referenceDateOrigin={previewScenario.referenceDateOrigin}
        onClose={hideScenarioDetails}
      />
    ) : null
  ), [previewScenario]);

  const showScenarioModal = useCallback((scenario: ScenarioSummary) => {
    setPreviewScenario(scenario);
    showScenarioDetails();
  }, [showScenarioDetails]);

  return (
    <div className="CustomerHeader">
      <div className="CustomerHeader__content">
      <div className="CustomerHeader__links">
        <GoBackButton />
      </div>
      <div className="CustomerHeader__infos">
        {isLoading && <Loading />}
        {error && <ErrorMessage error={error} />}
        {(!isLoading && customer) && (
          <Fragment>
            <h1 className="CustomerHeader__infos__name">
              {customer.denomination}
            </h1>
            <span className="CustomerHeader__infos__reference">
              {customer.identifier}
            </span>
            {/* Cibler le premier scenario qui est considéré sur le group Dettes */}
            {!!scenarios && (scenarios[0].map((scenario) => (
              <Button
                key={scenario.id}
                variant="link"
                className={classNames('CustomerHeader__infos__scenario', {
                  'CustomerHeader__infos__scenario--virtual': scenario.isVirtual,
                })}
                onClick={() => showScenarioModal(scenario)}
              >
                {scenario.name}
              </Button>
            )))}
          </Fragment>
        )}
      </div>
      <div className="CustomerHeader__mute">
        {(customer && customer.mute === true) && (
          <CustomerMuteButton
            customerData={customer}
            onActionError={onActionError}
            onActionDone={onDone}
          />
        )}
      </div>
      {customer?.statistics && (
        <div className="CustomerHeader__totals">
          <h2 className="CustomerHeader__totals__total">
            {formatIntlNumber(customer.statistics.totalToCollect, currency)}
          </h2>
          <div className="CustomerHeader__totals__balance">
            {t('clients:debt-due')}
            <span className="CustomerHeader__totals__amount">
              {formatIntlNumber(customer.statistics.totalDue, currency)}
            </span>
          </div>
        </div>
      )}
      {!isUserReader && (
        <CustomerHeaderActions onActionError={onActionError} />
      )}
      </div>
    </div>
  );
};

export default observer(CustomerHeader);
