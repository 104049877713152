import './index.scss';
import type { ReactNode, MouseEvent } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';

export type ButtonVariant =
  | 'primary'
  | 'danger'
  | 'black'
  | 'success'
  | 'outline'
  | 'outline-white'
  | 'outline-black'
  | 'outline-light'
  | 'outline-primary'
  | 'outline-success'
  | 'outline-danger'
  | 'light'
  | 'list'
  | 'list-primary'
  | 'list-danger'
  | 'link'
  | 'link-danger';

export type ButtonType = 'button' | 'submit';

export type Props = {
  variant?: ButtonVariant,
  type?: ButtonType,
  small?: boolean,
  disabled?: boolean,
  onClick?: (event: MouseEvent<HTMLElement>) => void,
  href?: string,
  className?: string,
  title?: DefaultTFuncReturn,
  withCaretIcon?: boolean,
  children: ReactNode,
};

const Button = (props: Props): JSX.Element => {
  const {
    variant = 'primary',
    type = 'button',
    small = false,
    disabled = false,
    onClick,
    href,
    className,
    title,
    withCaretIcon = false,
    children,
    ...buttonProps
  } = props;

  const classNames = classnames('Button', {
    'Button--primary': variant === 'primary',
    'Button--danger': variant === 'danger',
    'Button--black': variant === 'black',
    'Button--success': variant === 'success',
    'Button--outline': variant === 'outline',
    'Button--outline-white': variant === 'outline-white',
    'Button--outline-black': variant === 'outline-black',
    'Button--outline-danger': variant === 'outline-danger',
    'Button--outline-success': variant === 'outline-success',
    'Button--outline-primary': variant === 'outline-primary',
    'Button--outline-light': variant === 'outline-light',
    'Button--light': variant === 'light',
    'Button--list': variant === 'list',
    'Button--list-primary': variant === 'list-primary',
    'Button--list-danger': variant === 'list-danger',
    'Button--link': variant === 'link',
    'Button--link-danger': variant === 'link-danger',
    'Button--small': small,
    'Button--disabled': disabled,
  }, className);

  if (href && !disabled) {
    return (
      <Link to={href} className={classNames}>
        {children}
      </Link>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames}
      disabled={disabled}
      title={title ?? undefined}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
      {withCaretIcon && (
        <span className="Button__arrow">
          <Icon name="chevron-right-small" />
        </span>
      )}
    </button>
  );
};

export default Button;
