import './index.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  number: number,
  className?: string
};

const CountCharactersHelper = ({ number, className }: Props): JSX.Element => {
  const { t } = useTranslation();

  const classNames = classnames('CountCharactersHelper', className, {});

  return (
    <div className={classNames}>
      {t('common:characters', { count: number })}
    </div>
  );
};

export default CountCharactersHelper;
