import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import authStore from 'stores/Auth';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import { ElementsAuditTrail } from 'types/models';
import ErrorMessage from 'components/ErrorMessage';
import apiClients from 'api/clients';
import DownloadAuditTrailForm from './DownloadAuditTrailForm';

export type ExportAuditTrailPost = {
  dateFrom: string,
  dateTo: string,
  attributes: string[],
  elements: string[],
  format: string,
};

type Props = {
  customerId: number,
  onClose(): void,
  onDone(): void,
};

const ModalDownloadAuditTrail = ({ onClose, onDone, customerId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = authStore;
  const isMountedRef = useIsMountedRef();

  const { post, isLoading, cancel, error } = useApiRequest();

  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);

  const mapFormData = useCallback(
    (rawData: ModalFormData): ExportAuditTrailPost => {
      const checkboxes = ((rawData?.elements as string)?.split(',') ?? []) as ElementsAuditTrail[];

      const valueMapping = {
        [ElementsAuditTrail.REMINDER]: ['reminder_send', 'reminder_failure', 'content'],
        [ElementsAuditTrail.TASK]: ['action'],
        [ElementsAuditTrail.INVOICE_PAYMENT]: ['invoice_payment'],
        [ElementsAuditTrail.NOTE]: ['note'],
        [ElementsAuditTrail.OTHER]: ['other', 'plan'],
      };

      const elements = checkboxes.flatMap((checkbox) => valueMapping[checkbox] ?? []);

      return {
        dateFrom: rawData?.fromDate as string,
        dateTo: rawData?.toDate as string,
        attributes: (rawData?.attribute as string)?.split(',') ?? [],
        format: rawData?.exportFormat as string,
        elements,
      };
    },
    [],
  );

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !user) {
        return;
      }

      const { fromDate, toDate } = formData as { [key: string]: string };

      if (!fromDate) {
        setValidationErrors({ fromDate: { code: 2, message: t('errors:validation.required-field') } });
        return;
      }

      if (!toDate) {
        setValidationErrors({ toDate: { code: 2, message: t('errors:validation.required-field') } });
        return;
      }

      const result = await post(apiClients.exportClientDetailUrl(customerId), mapFormData(formData));

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (result !== null) {
        onDone();
        onClose();
      }
    },
    [user, post, customerId, mapFormData, isMountedRef, onDone, onClose, t],
  );

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <ModalForm
      title={t('clients:modal-audit-trail.title')}
      className="ModalDownloadAuditTrail"
      isOpened
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      saveText={t('common:download')}
    >
      {error && <ErrorMessage error={error} />}
      <DownloadAuditTrailForm errors={validationErrors} />
    </ModalForm>
  );
};

export default ModalDownloadAuditTrail;
