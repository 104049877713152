import './index.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import authStore from 'stores/Auth';
import config from 'config';
import Icon from 'components/Icon';

const TagLink = (): JSX.Element => {
  const { t } = useTranslation();
  const { locale } = authStore?.user ?? config.DEFAULT_LOCALE;
  const supportUrl = config.FRESHDESK_LINKS[locale];

  return (
    <a
      className="TagLink"
      target="__blank"
      href={`${supportUrl}${config.FRESHDESK_TAGS[locale]}`}
      title={t('models:tag-description') as string}
    >
      <Icon name='help-circle' />
    </a>
  );
};

export default observer(TagLink);
