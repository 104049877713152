import axios from 'axios';
import type { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Config from 'config';
import authStore from 'stores/Auth';
import type { RawResponseError } from 'types/errors';
import ResponseError from './errors';

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const { headers } = config;
  if (!headers) {
    return config;
  }

  const authToken = window.localStorage.getItem('token');
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return { ...config, headers };
};

const onResponse = (response: AxiosResponse): AxiosResponse => response.data;

const onResponseError = (error: unknown) => {
  if (axios.isCancel(error)) {
    return Promise.reject();
  }

  if (!axios.isAxiosError(error) || !(error as AxiosError).response) {
    return Promise.reject(error);
  }

  const { status, data } = error.response as AxiosResponse<RawResponseError>;

  if (status === 401) {
    authStore.logout();
  }

  if ([400, 423].includes(status)) {
    return Promise.resolve({ data, status });
  }

  return Promise.reject(new ResponseError(status, 'Unknown error'));
};

const Request = axios.create({
  baseURL: Config.API_URL,
});

Request.interceptors.request.use(onRequest);
Request.interceptors.response.use(onResponse, onResponseError);

export default Request;
