import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import formatIntlDate from 'utils/formatIntlDate';
import type { HistoryActivity } from 'types/models';
import getI18nHistoryActivityDate from './utils/getI18nHistoryActivityDate';
import getI18nHistoryActivityExtraInfo from './utils/getI18nHistoryActivityExtraInfo';

type Props = {
  data: HistoryActivity,
  standalone?: boolean,
};

const HistoryActivityDate = ({ data, standalone = false }: Props): JSX.Element => {
  const { t } = useTranslation();

  const i18nDate = useMemo(() => (
    getI18nHistoryActivityDate(data)
  ), [data]);

  const fullDate = useMemo(() => (
    t('common:date-at', {
      date: formatIntlDate(data.loggedAt, 'dateFull'),
    })
  ), [data, t]);

  const extraInfos = useMemo(() => (
    getI18nHistoryActivityExtraInfo(t, data)
  ), [t, data]);

  return (
    <div className="HistoryActivityDate">
      <p className="HistoryActivityDate__date">
        {standalone ? fullDate : i18nDate}
      </p>
      {(extraInfos !== null) && (
        <div className="HistoryActivityDate__info">
          {extraInfos}
        </div>
      )}
    </div>
  );
};

export default HistoryActivityDate;
