import './index.scss';
import type { ReactNode } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Button from 'components/Button';

type Props = {
  title: string,
  linkHref?: string,
  linkText?: DefaultTFuncReturn,
  count?: number | null,
  isLoading?: boolean,
  children: ReactNode,
};

const BlockSection = (props: Props): JSX.Element => {
  const { title, linkHref, linkText, children, count, isLoading = false } = props;

  return (
    <div className="BlockSection">
      <div className="BlockSection__header">
        <h3 className="BlockSection__header__title">{title}</h3>
        {(!isLoading && !!count) && (
          <div className="BlockSection__header__count">{count}</div>
        )}
        {isLoading && (
          <Loading />
        )}
        {linkHref && (
          <Button variant="link" href={linkHref} className="BlockSection__header__link">
            {linkText}
            <Icon name="chevron-right" />
          </Button>
        )}
      </div>
      {children}
    </div>
  );
};

export default BlockSection;
