import './index.scss';
import { useTranslation } from 'react-i18next';
import authStore from 'stores/Auth';
import Item from './Item';

const NoOrganizationSelected = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = authStore;
  const { organizations } = user || { organizations: [] };

  return (
    <div className="NoOrganizationSelected">
      <div className="NoOrganizationSelected__decoration" />
      <div className="NoOrganizationSelected__content">
        <h2 className="NoOrganizationSelected__title">
          {t('home:no-organization.title')}
        </h2>
        <p className="NoOrganizationSelected__help">
          {t('home:no-organization.help')}
        </p>
        {organizations.length === 0 && (
          <div className="NoOrganizationSelected__no-organization">
            {t('home:no-organization.no-organization-attached-to-account')}
          </div>
        )}
        {organizations.length > 0 && (
          <ul className="NoOrganizationSelected__organizations">
            {organizations.map((organization) => (
              <Item
                key={organization.id}
                organization={organization}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default NoOrganizationSelected;
