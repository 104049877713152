import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import Button from 'components/Button';
import SettingsSection from '../../Section';
import ModalInvite from './ModalInvite';
import CollaboratorsTable from './CollaboratorsTable';

type Props = {
  onShowToast: (message: string, status: ToastStatus) => void,
  onInvitationDone: () => void,
  reloadTick: number,
};

const Collaborators = ({ onShowToast, onInvitationDone, reloadTick }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [showMyModalInvite, hideMyModalInvite] = useModal(() => (
    <ModalInvite
      onClose={hideMyModalInvite}
      onShowToast={onShowToast}
      onInvitationDone={onInvitationDone}
    />
  ), [onShowToast, onInvitationDone]);

  return (
    <SettingsSection
      title={t('collaborators:collaborators')}
      action={(
        <Button onClick={showMyModalInvite} variant="link" withCaretIcon>
          {t('collaborators:invite')}
        </Button>
      )}
    >
      <CollaboratorsTable onShowToast={onShowToast} reloadTick={reloadTick} />
    </SettingsSection>
  );
};

export default Collaborators;
