import './index.scss';
import { useCallback, useState } from 'react';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';

type Props = {
  onSubmit(email: string): void,
  isLoading?: boolean,
  hasError?: boolean,
};

const ForgottenPasswordForm = (props: Props): JSX.Element => {
  const { onSubmit, isLoading = false, hasError = false } = props;

  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const handleEmailChange = useCallback((value: string) => {
    setEmail(value);
  }, []);

  const handleSubmit = useCallback((e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit(email);
  }, [email, isLoading, onSubmit]);

  return (
    <form className="ForgottenPasswordForm" onSubmit={handleSubmit}>
      <FormGroup controlId="forgottenPasswordEmail" label={t('common:email')}>
        <FormControl
          type="email"
          id="forgottenPasswordEmail"
          placeholder={t('login:enter-email')}
          value={email}
          onChange={handleEmailChange}
          isInvalid={hasError}
        />
      </FormGroup>
      {hasError && (
        <h4 className="ForgottenPasswordForm__error">{t('errors:unknown-retry')}</h4>
      )}
      <Button
        variant="black"
        type="submit"
        className="ForgottenPasswordForm__submit"
      >
        {isLoading && <Loading />}
        {!isLoading && t('forgotten-password:reset-password')}
      </Button>
      <div className="ForgottenPasswordForm__back-login-page">
        {t('forgotten-password:back-login-page.part1')} <Link to="/login">{t('forgotten-password:back-login-page.part2')}</Link> ?
      </div>
    </form>
  );
};

export default ForgottenPasswordForm;
