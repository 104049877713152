import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import type { ValidationErrors } from 'types/errors';
import type { DebitPost } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ModalForm from 'components/ModalForm';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import DebitPreview from './Preview';

type Props = {
  onClose(): void,
  finallyRedirectTo?: string,
  onSubmit(): void,
  onStepBack(errors?: ValidationErrors): void,
  data: DebitPost | null,
  customerId?: number,
  receiptName: string | null,
  receipt: string | null,
};

const DebitPreviewModal = (props: Props): JSX.Element => {
  const {
    onSubmit,
    onClose,
    finallyRedirectTo,
    data,
    customerId,
    onStepBack,
    receiptName,
    receipt,
  } = props;
  const history = useHistory();
  const { debitRef } = useParams<{ debitRef?: string }>();
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const isNew = !debitRef || debitRef === 'new';

  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const closeSelf = useCallback(() => {
    onClose();
    if (finallyRedirectTo) {
      setTimeout(() => {
        history.push(finallyRedirectTo);
      }, 300);
    }
  }, [onClose, history, finallyRedirectTo]);

  return (
    <ModalForm
      isOpened
      className="DebitPreviewModal"
      title={isNew ? ct('common:new-bill') : ct('common:edit-bill')}
      headerActions={(
        <Button variant="outline" onClick={() => { onStepBack(); }}>
          {t('common:edit')}
        </Button>
      )}
      onSave={onSubmit}
      onCancel={() => { setShowCancelConfirm(true); }}
    >
      {customerId && (
        <DebitPreview
          data={data}
          customerId={customerId}
          receiptName={receiptName}
          receipt={receipt}
          onError={onStepBack}
        />
      )}
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(DebitPreviewModal);
