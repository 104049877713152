import { Fragment } from 'react';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { Import, ImportExecutions } from 'types/models';
import ImportStatus from 'components/ImportStatus';
import formatIntlDate from 'utils/formatIntlDate';
import getI18nTypeImport from 'utils/getI18nTypeImport';
import RowActions from '../Actions/Row';

const ImportsHistoryColumns = (t: TFunction): Column<Import>[] => [
  {
    Header: t('imports:file') as string,
    accessor: 'fileName',
    id: 'file',
    Cell: ({ value, row }) => (
      <div className="ImportsHistory__columns__name">
        {value}
        <div className="ImportsHistory__columns__ref">
          {t<string>(getI18nTypeImport(row.original.type))}
        </div>
      </div>
    ),
  },
  {
    Header: t('imports:date') as string,
    accessor: 'startAt',
    id: 'date',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <div className="ImportsHistory__columns__date">
        {formatIntlDate(value, 'dateLong')}, {formatIntlDate(value, 'time')}
      </div>
    ),
  },
  {
    Header: t('imports:status') as string,
    accessor: 'status',
    id: 'status',
    Cell: ({ value }) => (
      <ImportStatus status={value} />
    ),
  },
  {
    Header: t('imports:nb-records-created') as string,
    accessor: 'stepExecutions',
    id: 'nbRecordsCreated',
    Cell: ({ value }) => (
      <Fragment>
        {value.reduce((sum: number, file: ImportExecutions) => sum + file.writeCount, 0)}
      </Fragment>
    ),
  },
  {
    Header: t('imports:nb-records-updated') as string,
    accessor: 'stepExecutions',
    id: 'nbRecordsUpdated',
    Cell: ({ value }) => (
      <Fragment>
        {value.reduce((sum: number, file: ImportExecutions) => sum + file.updateCount, 0)}
      </Fragment>
    ),
  },
  {
    Header: t('imports:nb-records-error') as string,
    accessor: 'stepExecutions',
    id: 'nbRecordsError',
    Cell: ({ value }) => (
      <Fragment>
        {value.reduce((sum: number, file: ImportExecutions) =>
          sum + file.errors.length + file.failures.length, 0)}
      </Fragment>
    ),
  },
  {
    Header: t('common:commands') as string,
    accessor: 'presignedUrl',
    disableSortBy: true,
    Cell: ({ value, row }) => (
      row.original.downloadable && <RowActions filePath={value} />
    ),
  },
];

export default ImportsHistoryColumns;
