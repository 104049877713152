import './index.scss';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash/snakeCase';
import type { ValidationErrors } from 'types/errors';
import type { DataBlock, ReminderBlock } from 'types/models';
import { Channel } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import { checkIsAllowed } from 'components/UserRights';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import userRightsStore from 'stores/UserRights';
import Confirm from 'components/Confirm';
import apiTemplateBlocks from 'api/template-blocks';
import { RightsCategory } from 'types/models';
import ModalReminderForm from './Form';

type Props = {
  onDone(message: string): void,
  onError(message: string): void,
  onClose(): void,
  channel: Channel,
  edit?: boolean,
  defaultData?: DataBlock,
  listData?: DataBlock[],
  indexData?: number,
  id?: number,
};

const ModalReminder = (props: Props): JSX.Element => {
  const { onDone, onError, onClose, channel, edit = false, defaultData, listData, indexData, id } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const { rights } = userRightsStore;

  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const { put, isLoading, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const mapFormData = useCallback((rawData: ModalFormData): ReminderBlock => ({
    channel,
    name: rawData.name as string,
    description: rawData.description as string,
    subject: rawData.subject as string || rawData.name as string,
    content: rawData.content as string,
    identifier: snakeCase(rawData.name as string),
  }), [channel]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization || !listData || !id || indexData === undefined) {
      return;
    }

    const data = mapFormData(formData);

    const payloadListData = apiTemplateBlocks.buildPayloadListData(listData, indexData, edit ? 'edit' : 'create', data);
    const result = await put(apiTemplateBlocks.updateUrl(id), { reminders: payloadListData });

    if (!isMountedRef.current) {
      return;
    }

    if (!result) {
      return;
    }

    if (result.errors) {
      setValidationErrors(result.errors);
      return;
    }

    closeSelf();
    onDone(t(
      edit ? 'models:toast.success.edit-model' : 'models:toast.success.create-model',
      { name: formData.name as string },
    ));
  }, [closeSelf, currentOrganization, edit, id, indexData, isMountedRef, listData, mapFormData, onDone, put, t]);

  const handleDelete = useCallback(async () => {
    setShowDeleteConfirm(false);
    if (!currentOrganization || !listData || indexData === undefined || !id) {
      return;
    }

    const payloadListData = apiTemplateBlocks.buildPayloadListData(listData, indexData, 'delete');
    const response = await put(apiTemplateBlocks.updateUrl(id), { tasks: payloadListData });

    if (isMountedRef.current && response) {
      onDone(t('models:toast.success.delete-model', { name: defaultData?.name }));
      return;
    }
    onError(t('models:toast.error.delete-model', { name: defaultData?.name }));
  }, [currentOrganization, defaultData?.name, id, indexData, isMountedRef, listData, onDone, onError, put, t]);

  return (
    <ModalForm
      isOpened
      className="ModalReminder"
      title={t('models:reminder-using-channel', { channel: t(getI18nChannel(channel)) })}
      onSave={handleSubmit}
      onCancel={closeSelf}
      buttonsDisabled={rights === null || !checkIsAllowed('UPDATE', RightsCategory.PREFERENCES_MODELS)}
      isLoading={isLoading}
      hasDeleteButton={edit}
      onDelete={() => { setShowDeleteConfirm(true); }}
    >
      {error && (<ErrorMessage error={error} />)}
      <ModalReminderForm
        channel={channel}
        errors={validationErrors}
        defaultData={defaultData}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
      />
      <Confirm
        titleModal={t('common:remove')}
        text={t('models:confirm-delete')}
        variant="danger"
        confirmButtonText={t('common:remove')}
        cancelButtonText={t('common:cancel')}
        entityName={defaultData?.name}
        isShow={showDeleteConfirm}
        onConfirm={handleDelete}
        onCancel={() => { setShowDeleteConfirm(false); }}
      />
    </ModalForm>
  );
};

export default observer(ModalReminder);
