import './index.scss';
import PercentBar from 'components/PercentBar';
import Misc from 'types/misc';
import getColorPeriod from 'utils/getColorPeriod';

type Props = {
  period: Misc.ListStatItemBarPeriod,
};

const ListStatsByPeriodItemBar = ({ period }: Props): JSX.Element => (
  <div className="ListStatsByPeriodItemBar">
    <span
      className="ListStatsByPeriodItemBar__item-index"
      style={{ color: getColorPeriod(period.id) }}
    >
      {period.count}
    </span>
    <PercentBar percentage={period.percentage} color={getColorPeriod(period.id)} />
  </div>
);

export default ListStatsByPeriodItemBar;
