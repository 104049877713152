import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import type { ScenarioDateReference, ScenarioTemplateReminder, ScenarioTemplateTask } from 'types/models';
import { Channel, StepActionEntity } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';
import StepActionIcon from 'components/StepActionIcon';
import getI18nDayFromReference from 'utils/getI18nDayFromReference';
import Icon from 'components/Icon';

type Props = {
  action: ScenarioTemplateTask | ScenarioTemplateReminder,
  type: Misc.ActionType,
  dateReference: ScenarioDateReference,
};

const ScenarioDetailsActionsItem = (props: Props): JSX.Element => {
  const { action, type, dateReference } = props;
  const { name, channel } = action;
  const { t } = useTranslation();

  const badgeAlias = useMemo(() => {
    return type === 'TASK' ? StepActionEntity.TASK : StepActionEntity.REMINDER;
  }, [type]);

  const needsValidation = useMemo(() => (
    type === 'REMINDER'
      ? (action as ScenarioTemplateReminder).withValidation
      : null
  ), [type, action]);

  return (
    <div className="ScenarioDetailsActionsItem">
      <div className="ScenarioDetailsActionsItem__icon">
          <StepActionIcon
            entity={badgeAlias}
            channel={channel || Channel.EMAIL}
          />
      </div>
      <div className="ScenarioDetailsActionsItem__details">
        <h4 className="ScenarioDetailsActionsItem__details__title">
          {name}
        </h4>
        <p className="ScenarioDetailsActionsItem__details__days">
          {t(
            getI18nDayFromReference(action.day, dateReference),
            { count: Math.abs(action.day || 0) },
          )}
          <span className="ScenarioDetailsActionsItem__details__days__way">
            {action?.channel && `, ${t(getI18nChannel(action?.channel))}`}
          </span>
        </p>
      </div>
      {action.repeat && (
        <div
          title={t('plans:repetitive-action') as string}
          className="ScenarioDetailsActionsItem__to-repeat"
        >
          <Icon name="repeat" />
        </div>
      )}
      {needsValidation && (
        <div className="ScenarioDetailsActionsItem__to-validate">
          {t('common:to-validate')}
        </div>
      )}
    </div>
  );
};

export default ScenarioDetailsActionsItem;
