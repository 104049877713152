import './index.scss';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import organizationStore from 'stores/Organization';
import DownloadLink from 'components/DownloadLink';

const ImportsFileNav: React.FC = () => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const getNavLink = useCallback(
    (type: string) => `${currentOrganization?.reference}/empty/${type}`,
    [currentOrganization],
  );

  if (!currentOrganization) {
    return null;
  }

  return (
    <div className="ImportsFileNav">
      <h2 className="ImportsFileNav__title">{t('imports:nav.title.manage')}</h2>
      <div className="ImportsFileNav__list">
        <NavLink to="/imports/reports" className="ImportsFileNav__list__link">
          {t('imports:nav.link.reports')}
        </NavLink>
        <NavLink to="/imports/history" className="ImportsFileNav__list__link">
          {t('imports:nav.link.history')}
        </NavLink>
      </div>
      <h2 className="ImportsFileNav__title">{t('imports:nav.title.support')}</h2>
      <div className="ImportsFileNav__list ImportsFileNav__list--disabled">
        <DownloadLink path={getNavLink('CLIENT')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-client')}
        </DownloadLink>
        <DownloadLink path={getNavLink('CONTACT')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-contact')}
        </DownloadLink>
        <DownloadLink path={getNavLink('PAY_REQUEST')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-pay-request-create')}
        </DownloadLink>
        <DownloadLink path={getNavLink('PAY_REQUEST_STATUSES')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-pay-request-status')}
        </DownloadLink>
      </div>
    </div>
  );
};

export default ImportsFileNav;
