import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import { FilterValue } from 'react-table';
import type { Scenario, ScenarioGroup } from 'types/models';
import type { FetchAllParams } from 'api/scenarios';
import apiScenarios from 'api/scenarios';
import apiScenarioGroup from 'api/scenarioGroup';
import type { FetchAllGroupsParams } from 'api/scenarioGroup';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetchPaginated from 'hooks/useFetchPaginated2';
import useFetch from 'hooks/useFetch2';
import Config from 'config';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Loading from 'components/Loading';
import { RightsCategory } from 'types/models';
import Pagination from 'components/Pagination';
import FormSelect from 'components/FormSelect';
import SearchInput from 'components/SearchInput';
import Misc from 'types/misc';
import Icon from 'components/Icon';
import SettingsSection from '../Section';
import ModalAdd from './ModalAdd';
import ModalEditYAML from './ModalEditYAML';
import SettingsScenario from './Scenario';
import ModalAddGroup from './ModalAddGroup';

const SettingsScenarios = (): JSX.Element => {
  const { type, id } = organizationStore.currentOrganization!;
  const { ct, t } = useContextualTranslation(type);
  const [pageSize, setPageSize] = useState<number>(Config.PAGE_SIZES[2]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [openDetailsMap, setOpenDetailsMap] = useState<Record<number, boolean>>({});

  const handleToggleDetails = useCallback((scenarioId: number) => {
    setOpenDetailsMap((prevMap) => ({
      ...prevMap,
      [scenarioId]: !prevMap[scenarioId],
    }));
  }, []);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    data: scenarioGroups,
    refetch: refetchGroups,
  } = useFetch<FetchAllGroupsParams, ScenarioGroup[]>(
    {
      cacheKey: 'scenarioGroups',
      organizationId: id,
    },
    apiScenarioGroup.all,
    { enabled: !!id },
  );

  const [scenarioToDuplicate, setScenarioToDuplicate] = useState<string | undefined>();
  const [currentGroup, setCurrentGroup] = useState<ScenarioGroup | undefined>();
  const [currentSearch, setCurrentSearch] = useState<string | null>();

  const selectOptions = useMemo(() => [
    { label: t('selectors:debt'), value: 'debt' },
    ...(scenarioGroups || []).map(({ identifier, name }) => ({ label: name, value: identifier } as Misc.ValueLabel)),
  ], [scenarioGroups, t]);

  const handleCurrentSearch = useCallback((value: string | null) => {
    setCurrentSearch(value?.trim());
  }, []);

  const handleGroupChange = useCallback((_name: string, newValue: string[] | string | null) => {
    setCurrentGroup(
      scenarioGroups?.find(({ identifier }) => identifier === (newValue as string) ),
    );
  }, [scenarioGroups]);

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    serverPagination,
  } = useFetchPaginated<FetchAllParams, Scenario>(
    {
      cacheKey: 'organizationScenarios',
      organizationId: id,
      params: {
        page: pageIndex + 1,
        pageSize: pageSize,
        filtering: [
          { name: 'label', value: currentSearch as FilterValue },
          { name: 'group.id', value: currentGroup?.id ?? 'debt' },
        ],
      },
    },
    apiScenarios.all,
    { enabled: !!id, refetchOnWindowFocus: false },
  );

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    setScenarioToDuplicate(undefined);
    refetchGroups();
    refetch();
  }, [refetch, showToast, refetchGroups]);

  const handleNextPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex + 1);
  }, []);

  const handlePreviousPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex - 1);
  }, []);

  const handleGotoPage = useCallback((page: number) => {
    setPageIndex(page);
  }, []);

  const { totalRecords } = serverPagination;

  const pageCount = useMemo(() => (
    Math.ceil(totalRecords / pageSize)
  ), [totalRecords, pageSize]);

  const [showModalAddGroup, hideModalAddGroup] = useModal(() => (
    <ModalAddGroup
      onClose={hideModalAddGroup}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, showToast]);

  const [showModalUpdateGroup, hideModalUpdateGroup] = useModal(() => (
    <ModalAddGroup
      isEdit={true}
      defaultData={scenarioGroups}
      onClose={hideModalUpdateGroup}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, showToast, scenarioGroups]);

  const [showModalAdd, hideModalAdd] = useModal(() => (
    <ModalAdd
      group={currentGroup ?? { name: t('selectors:debt') } as ScenarioGroup}
      scenarios={data}
      onClose={hideModalAdd}
      toDuplicate={scenarioToDuplicate}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, scenarioToDuplicate, showToast, data, currentGroup, t]);

  const [showModalEditYAML, hideModalEditYAML] = useModal(() => (
    <ModalEditYAML
      onClose={hideModalEditYAML}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, showToast]);

  useEffect(() => {
    if (scenarioToDuplicate) {
      showModalAdd();
    }
  }, [scenarioToDuplicate, showModalAdd]);

  return (
    <div className="Plans">
      <SettingsSection
        title={ct('plans:plans')}
        action={(
          <ButtonWithUserRights
            action="UPDATE"
            category={RightsCategory.PREFERENCES_PLANS}
            onClick={showModalUpdateGroup}
            variant="link"
          >
            {ct('plans:update-group')}
          </ButtonWithUserRights>
        )}
        secondaryAction={(
          <ButtonWithUserRights
            action="CREATE"
            category={RightsCategory.PREFERENCES_PLANS}
            onClick={showModalAddGroup}
            variant="link"
          >
            <Icon name="plus-small" /> {ct('plans:add-group')}
          </ButtonWithUserRights>
        )}
      >
        <p className="Plans__description">{t('plans:plans-description')}</p>
        <header className="Plans__header">
          <div className="Plans__header__group">
            {scenarioGroups && (
              <FormSelect
                selectOptions={selectOptions}
                name="select"
                defaultValue='debt'
                onSelect={handleGroupChange}
                withClearButton={false}
              />
            )}
          </div>
          <div className="Plans__header__search">
            <SearchInput
              placeholder={t('common:search-in-table')}
              onChange={handleCurrentSearch}
            />
          </div>
          <div className="Plans__header__actions">
            <ButtonWithUserRights
              action="CREATE"
              category={RightsCategory.PREFERENCES_PLANS}
              onClick={showModalAdd}
              variant="link"
            >
              <Icon name="plus-small" /> {ct('plans:add-plan')}
            </ButtonWithUserRights>
            <ButtonWithUserRights
              category={RightsCategory.PREFERENCES_PLANS}
              action="CREATE"
              onClick={showModalEditYAML}
              variant="link"
            >
              {t('plans:yaml-edit')}
            </ButtonWithUserRights>
          </div>
        </header>
        {(isLoading || isFetching) && <Loading isOverlay />}
        {data?.map((scenario) => (
          <SettingsScenario
            scenario={scenario}
            onShowModalDuplicatePlan={setScenarioToDuplicate}
            onToggleDetails={() => handleToggleDetails(scenario.id)}
            detailsOpened={openDetailsMap[scenario.id] || false}
            key={`${scenario.identifier}-${scenario.name}`}
            onActionDone={handleActionDone}
            onActionError={(message: string) => { showToast(message, 'error'); }}
          />
        ))}
        {!data || data.length === 0 &&
          <div className="DataTable__message DataTable__message--inline">
            <div className="DataTable__no-data">
              {t('common:no-data-was-found')},
            </div>
            <div className="DataTable__no-data">
              {t('common:try-modify-filters').toLowerCase()}
            </div>
          </div>
        }
        {!isLoading && !isFetching && totalRecords && pageCount > 0 ? (
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < (pageCount - 1)}
            pageCount={pageCount}
            totalRecords={totalRecords}
            nextPage={handleNextPage}
            previousPage={handlePreviousPage}
            onChangePageSize={setPageSize}
            onGotoPage={handleGotoPage}
          />
        ) : ''}
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsScenarios);
