import './index.scss';
import { useTranslation } from 'react-i18next';
import CustomerMainLayout from '../Layout';
import CustomerAttributes from './Attributes';
import CustomerLastActivities from './LastActivities';
import CustomerLastNotes from './LastNotes';
import CustomerInformations from './Informations';
import CustomerStats from './Stats';
import CustomerActions from './Actions';
import CustomerAccountDetails from './AccountDetails';

const CustomerSummary = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <CustomerMainLayout
      sidebar={(
        <div className="CustomerSummary__side-view">
          <CustomerAttributes />
          <CustomerLastNotes />
          <CustomerLastActivities />
          <CustomerInformations />
        </div>
      )}
    >
      <div className="CustomerSummary">
        <h2 className="CustomerSummary__title">
          {t('common:summary')}
        </h2>
        <CustomerStats />
        <CustomerActions />
        <CustomerAccountDetails />
      </div>
    </CustomerMainLayout>
  );
};

export default CustomerSummary;
