import { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { BusinessUnit } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';

type Props = {
  errors?: ValidationErrors | null,
  category?: BusinessUnit,
};

const ModalCategoryForm = (props: Props): JSX.Element => {
  const { category, errors } = props;
  const { t } = useTranslation();
  const [identifier, setIdentifier] = useState<string>(category?.identifier || '');
  const [name, setName] = useState<string>(category?.name || '');

  return (
    <Fragment>
      <div className="ModalCreateCategory">
        <FormControl
            name="id"
            type="hidden"
            value={category?.id}
            readOnly
        />
        <FormFieldset>
          <FormGroup mandatory label={t('perimeters:identifier')} error={errors?.identifier}>
            <FormControl
              name="identifier"
              autoComplete="off"
              type="text"
              isInvalid={!!errors?.identifier}
              value={identifier}
              onChange={setIdentifier}
            />
          </FormGroup>
          <FormGroup mandatory label={t('perimeters:label')} error={errors?.name}>
            <FormControl
              name="name"
              autoComplete="off"
              type="text"
              isInvalid={!!errors?.name}
              value={name}
              onChange={setName}
            />
          </FormGroup>
        </FormFieldset>
      </div>
    </Fragment>
  );
};

export default ModalCategoryForm;
