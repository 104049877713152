export type FileInfo = {
  '@context': string,
  '@id': string,
  '@type': 'FileInfo',
  /**
   * @key À utiliser pour lire le fichier, en contruisant l'URL comme ceci : `/media/download/${key}`.
   */
  key: string,
  originalFilename: string,
  mimeType: string,
  size: number,
  extension: string,
  hash: string,
  storage: string,
};

export enum FileExportType {
  PDF = 'pdf',
  XLS = 'xls',
  CSV = 'csv',
}

export enum ElementsAuditTrail {
  REMINDER = 'reminder',
  TASK = 'task',
  INVOICE_PAYMENT = 'invoice_payment',
  NOTE = 'note',
  OTHER = 'other',
}
