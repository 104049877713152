import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import Button from 'components/Button';

type Props = {
  valueLabels: { value: string, label: string, disabled?: boolean }[],
  defaultValue?: string,
  onChange: (newValue: string) => void,
  className?: string,
};

const LinkSwitch = (props: Props): JSX.Element => {
  const { valueLabels, defaultValue, onChange, className } = props;

  const [currentValue, setCurrentValue] = useState<string>(
    defaultValue || valueLabels[0].value || '',
  );
  const previousValue = useRef<string>(currentValue);

  const handleClick = useCallback((value: string, disabled: boolean) => {
    if (!disabled) {
      setCurrentValue(value);
    }
  }, []);

  useEffect(() => {
    if (currentValue === previousValue.current) {
      return;
    }
    previousValue.current = currentValue;
    setTimeout(() => { onChange(currentValue); }, 150);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const currentLabel = useMemo(() => (
    valueLabels.find(({ value }) => value === currentValue)?.label
  ), [currentValue, valueLabels]);

  const currentLabelStyle = useMemo(() => {
    const width = 100 / valueLabels.length;
    const index = valueLabels.findIndex(({ value }) => value === currentValue);
    return { width: `${width}%`, left: `${width * index}%` };
  }, [currentValue, valueLabels]);

  const classNames = classnames('LinkSwitch', className);

  return (
    <div className={classNames}>
      {valueLabels.map(({ value, label, disabled = false }) => (
        <Button
          variant="light"
          key={value}
          disabled={disabled}
          onClick={() => { handleClick(value, disabled); }}
          className="LinkSwitch__button"
        >
          {label}
        </Button>
      ))}
      <div
        className="LinkSwitch__active-button"
        style={currentLabelStyle}
      >
        {currentLabel}
      </div>
    </div>
  );
};

export default LinkSwitch;
