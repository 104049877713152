import './index.scss';
import { useTranslation } from 'react-i18next';
import { Channel, StepActionEntity } from 'types/models';
import type { DataBlock } from 'types/models';
import Button from 'components/Button';
import getI18nChannel from 'utils/getI18nChannel';
import StepActionIcon from 'components/StepActionIcon';

type Props = {
  model: DataBlock,
  onSelect(model: DataBlock): void,
};

const ModalTemplateBlocksItem = ({ model, onSelect }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button className="ModalTemplateBlocksItem" variant="list" onClick={() => { onSelect(model); }}>
      <div className="ModalTemplateBlocksItem__main">
        <div className="ModalTemplateBlocksItem__main__icon">
          <StepActionIcon
            entity={model?.channel ? StepActionEntity.REMINDER : StepActionEntity.TASK}
            channel={model?.channel || Channel.EMAIL}
          />
        </div>
        <div className="ModalTemplateBlocksItem__main__description">
          <h4 className="ModalTemplateBlocksItem__main__description__title">{model.name}</h4>
          <p className="ModalTemplateBlocksItem__main__description__channel">
            {model.channel ? t(getI18nChannel(model?.channel)) : ''}
          </p>
        </div>
      </div>
    </Button>
  );
};

export default ModalTemplateBlocksItem;
