import { RightsRole } from 'types/models';

const getI18nUserRights = (right: RightsRole): string => {
  const i18nRights = {
    [RightsRole.ROLE_SUPER_ADMINISTRATOR]: 'common:rights.super-admin',
    [RightsRole.ROLE_OWNER]: 'common:rights.owner',
    [RightsRole.ROLE_ADMINISTRATOR]: 'common:rights.admin',
    [RightsRole.ROLE_SUPERVISOR]: 'common:rights.supervisor',
    [RightsRole.ROLE_COORDINATOR]: 'common:rights.coordinator',
    [RightsRole.ROLE_ACCOUNT_MANAGER]: 'common:rights.account-manager',
    [RightsRole.ROLE_READER]: 'common:rights.reader',
    [RightsRole.ROLE_DEMO]: 'common:rights.demo',
    [RightsRole.ROLE_USER]: '',
  };

  return i18nRights[right];
};

export default getI18nUserRights;
