import { useTranslation } from 'react-i18next';
import type { SyntheticEvent } from 'react';
import Button from 'components/Button';
import Icon from 'components/Icon';
import './index.scss';

type Props = {
  show: boolean,
  onClick: (e: SyntheticEvent) => void,
};

const ShowPasswordButton = ({ show, onClick }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button variant="link" className="ShowPasswordButton" onClick={onClick}>
      <Icon name={show ? 'eye-crossed' : 'eye'} className="ShowPasswordButton__icon" />
      <span className="ShowPasswordButton__label">
        {show ? t('common:hide') : t('common:show')}
      </span>
    </Button>
  );
};

export default ShowPasswordButton;
