import requester from 'utils/requester';

export type Notifications = {
  unreadCount: number,
  notifications: {
    id: number,
    actionType: string,
    comment: string | null,
    createdAt: string,
    message: string,
    type: string,
    url: string,
    viewed: boolean,
  }[],
};

const fetchNotifications = async () => {
  const { data } = await requester.get<Notifications>('notification/list');
  return data;
};

const markNotificationAsViewed = async (id: number) => {
  await requester.post(`notification/mark_viewed/${id}`);
};

const deleteNotification = async (id: number) => {
  await requester.delete(`notification/remove/${id}`);
};

const fetchWebSocketTicket = async (): Promise<string> => {
  const { data } = await requester.post('sync/ws/ticket');
  return data.ticket;
};

export default {
  fetchNotifications,
  deleteNotification,
  markNotificationAsViewed,
  fetchWebSocketTicket,
};
