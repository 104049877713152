import './index.scss';
import getUploadImageUrl from 'utils/getUploadImageUrl';
import Notification from 'components/Notification';

type Props = {
  username: string,
  size?: number,
  notificationCount?: number,
  userAvatar?: string | null,
};

const Avatar = ({ username, size = 32, notificationCount = 0, userAvatar }: Props): JSX.Element => (
  <div className="Avatar">
    <div className="Avatar__container" style={{ width: size, height: size }}>
      {userAvatar && (
        <img
          src={getUploadImageUrl(userAvatar)}
          className="Avatar__container__image"
          alt={username}
        />
      )}
      {!userAvatar && (
        <div className="Avatar__container__initials">
          {username[0]} {size > 50 && username[1]}
        </div>
      )}
    </div>
    {!!notificationCount && (
      <Notification count={notificationCount} />
    )}
  </div>
);

export default Avatar;
