import type { Reducer } from 'react';

type AddAction = { action: 'add', value: string };
type SelectManyAction = { action: 'selectMany', value: string[] };
type UnselectManyAction = { action: 'unselectMany', value: string[] };
type RemoveAction = { action: 'remove', value: string };
type Actions = AddAction | SelectManyAction | UnselectManyAction | RemoveAction;
export type TypeFiltersReducer = Reducer<string[], Actions>;

const typeFiltersReducer: TypeFiltersReducer = (prevState, { action, value }) => {
  switch (action) {
    case 'add':
      return [...prevState, value];
    case 'selectMany':
      return [...prevState, ...value];
    case 'unselectMany':
      return prevState.filter((prevValue) => !value.includes(prevValue));
    case 'remove':
      return prevState.filter((prevValue) => prevValue !== value);
    default:
      throw new Error('Unexpected action type used in filters reducer dispatch');
  }
};

export default typeFiltersReducer;
