import { createContext, useCallback, useState } from 'react';
import { Switch } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import type { Import } from 'types/models';
import { AnalyzableRoute } from 'components/Routes';
import ReservedAccess from '../ReservedAccess';
import ImportsFile from './File';
import ImportsHistory from './History';
import Report from './Report';
import Reports from './Reports';
import ImportsFileWizardStep from './File/Wizard/Step';

export const ReportContext = createContext<Import | null>(null);

const Imports = (): JSX.Element => {
  const [report, setReport] = useState<Import | null>(null);

  const handleChangeReport = useCallback((newReport: Import) => {
    setReport(newReport);
  }, []);

  return (
    <ReportContext.Provider value={report}>
      <Switch>
        <AnalyzableRoute path="/imports" exact component={ImportsFile} routeName="Imports" />
        <AnalyzableRoute path="/imports/reports" component={Reports} routeName="Reports listing" />
        <AnalyzableRoute
          path="/imports/report"
          component={(routeProps: RouteComponentProps) => (
            <Report
              {...routeProps}
              onChangeReport={handleChangeReport}
            />
          )}
          routeName="Report detail"
        />
        <AnalyzableRoute path="/imports/history" component={ImportsHistory} routeName="History" />
        <AnalyzableRoute
          path="/imports/new/:type(pdf|csv-xlsx)"
          component={ImportsFileWizardStep}
          routeName="Import create"
        />
        <AnalyzableRoute component={ReservedAccess} routeName="Reserved access" />
      </Switch>
    </ReportContext.Provider>
  );
};

export default Imports;
