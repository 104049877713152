import './index.scss';
import classnames from 'classnames';
import allIcons from './assets';

interface Props {
  name: keyof typeof allIcons,
  className?: string,
  title?: string,
  spin?: boolean,
}

const Icon = (props: Props): JSX.Element => {
  const { name, className, title, spin = false } = props;
  const IconSvg = allIcons[name];
  const classNames = classnames('Icon', className, {
    'Icon--spin': spin,
  });

  return (
    <IconSvg className={classNames} title={title} />
  );
};

export default Icon;
