import './index.scss';
import { observer } from 'mobx-react';
import { RightsRole, type Debit } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import formatIntlDate from 'utils/formatIntlDate';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import useContextualTranslation from 'hooks/useContextualTranslation';
import InfoCard from './Card';
import Plan from './Plan';

type Props = {
  debit: Debit,
  onActionDone(message: string): void,
};

const DebitContentInfos = ({ debit, onActionDone }: Props): JSX.Element => {
  const { type, currency } = organizationStore.currentOrganization!;
  const { t } = useContextualTranslation(type);
  const isUserReader = !!authStore.user?.roles.includes(RightsRole.ROLE_READER);

  return (
    <div className="DebitContentInfos">
      <div className="DebitContentInfos__content">
        <InfoCard
          title={t('common:initial-amount')}
          value={formatIntlNumber(debit.initialCost, debit.currency || currency)}
        />
        <InfoCard
          title={t('common:remaining-balance')}
          value={formatIntlNumber(debit.amount, debit.currency || currency)}
        />
        <InfoCard
          title={t('debits:subject')} tooltip={debit.subject || undefined}
          value={<div className="DebitContentInfos__subject">{debit.subject || '-'}</div>}
        />
        <InfoCard
          title={t('common:deadline')}
          value={(debit.dueAt && formatIntlDate(debit.dueAt, 'dateLong')) || '-'}
        />
        <InfoCard
          title={t('debits:promise-to-pay')}
          value={(debit.promiseToPayAt && formatIntlDate(debit.promiseToPayAt, 'dateLong')) || '-'}
        />
        <InfoCard
          title={t('debits:dunning-plans')}
          value={
            debit.plans &&
            <ul className="DebitContentInfos__plans">
              {debit.plans.map((plan) => (
                <Plan
                  key={plan.name}
                  plan={plan}
                  isUserReader={isUserReader}
                  debit={debit}
                  onActionDone={onActionDone}
                />
              ))}
            </ul>
          }
        />
      </div>
    </div>
  );
};

export default observer(DebitContentInfos);
