import './index.scss';
import { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getOverviewCustomerEavs } from 'utils/getCustomerEavs';
import organizationStore from 'stores/Organization';
import customerContext from 'pages/Customer/customerContext';
import Icon from 'components/Icon';
import apiClients from 'api/clients';
import type { FetchOneParams } from 'api/clients';
import { EAVValue } from 'types/models';
import ErrorMessage from 'components/ErrorMessage';
import useFetch from 'hooks/useFetch2';

const CustomerAttributes = (): JSX.Element | null => {
  const { data: customer } = useContext(customerContext);
  const { attributes: organizationAttributes } = organizationStore;
  const { t } = useTranslation();

  const {
    data: eavsClient,
    error,
  } = useFetch<FetchOneParams, EAVValue[]>(
    {
      cacheKey: 'clientEavs',
      id: customer!.id,
    },
    apiClients.fetchEavs,
    { enabled: !!customer?.id },
  );

  const attributes = useMemo(() => (
    getOverviewCustomerEavs(eavsClient, organizationAttributes)
  ), [eavsClient, organizationAttributes]);

  const managers = useMemo(() => (
    (customer?.users && customer.users.length > 0)
      ? customer.users.map(({ user }) => `${user.firstName} ${user.lastName}`).join(', ')
      : '-'
  ), [customer]);

  if (!customer) {
    return null;
  }

  return (
    <dl className="CustomerAttributes">
      {error && <ErrorMessage error={error} />}
      <div className="CustomerAttributes__header">
        <h3 className="CustomerAttributes__title">
          {t('common:informations')}
        </h3>
        <Link
          to={`/customer/${customer.id}/infos-contacts`}
          className="CustomerAttributes__link"
        >
          {t('common:all-informations')}
          <Icon name="chevron-right-small" className="CustomerAttributes__link__icon" />
        </Link>
      </div>
      <div className="CustomerAttributes__item">
        <dt className="CustomerAttributes__label">
          {t('common:managers')}
        </dt>
        <dd className="CustomerAttributes__value">
          {managers}
        </dd>
      </div>
      <div className="CustomerAttributes__item">
        <dt className="CustomerAttributes__label">
          {t('common:address')}
        </dt>
        <dd className="CustomerAttributes__value">
          {customer.address ? customer.address.split('\n').map( (item, key) =>
            <div key={key}>{item}</div>,
          ) : '-'}
        </dd>
      </div>
      {customer.businessUnit &&
        <div className="CustomerAttributes__item">
          <dt className="CustomerAttributes__label">
            {t('common:perimeter')}
          </dt>
          <dd className="CustomerAttributes__value">
            {customer.businessUnit.label}
          </dd>
        </div>
      }
      {attributes.map(({ id, label, value, useHtml }) => (
        <div key={id} className="CustomerAttributes__item">
          <dt className="CustomerAttributes__label">{label}</dt>
          {useHtml ?
            <dd
              className="CustomerAttributes__value CustomerAttributes__value--html"
              dangerouslySetInnerHTML={{ __html: value }}
            /> :
            <dd className="CustomerAttributes__value">{value}</dd>
          }
        </div>
      ))}
    </dl>
  );
};

export default observer(CustomerAttributes);
