import './index.scss';
import type { Contact } from 'types/models';
import recomposeName from 'utils/recomposeName';
import ListItemButton from 'components/ListItemButton';
import Icon from 'components/Icon';

type Props = {
  data: Contact,
  onShowView(): void,
};

const ListContactsItem = ({ data, onShowView }: Props): JSX.Element => (
  <ListItemButton onShowView={onShowView}>
    <div className="ListContactsItem">
      <div className="ListContactsItem__details">
        <p className="ListContactsItem__name">
          {recomposeName(data)}
        </p>
        <p className="ListContactsItem__role">
          {data.contactRole.label}
        </p>
      </div>
      <div className="ListContactsItem__icons">
        {data.email && (
          <Icon name="email" />
        )}
        {data.address1 && (
          <Icon name="envelope" />
        )}
        {data.phone && (
          <Icon name="phone" />
        )}
        {data.cellPhone && (
          <Icon name="sms" />
        )}
      </div>
    </div>
  </ListItemButton>
);

export default ListContactsItem;
