import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarController,
  BarElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import type { OrganizationAnalytics } from 'types/models';
import { Analytics } from 'types/models';
import Misc from 'types/misc';
import getChartData from '../../utils';
import defaultChartOptions from '../../defaultChartOptions';

type Props = {
  data: OrganizationAnalytics['tasks'],
};

ChartJS.register(BarController, BarElement, PointElement, LinearScale, CategoryScale, Legend, ChartDataLabels);

const TaskRemindersChart = ({ data }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState<Misc.AnalyticsChartData | undefined>(undefined);

  useEffect(() => {
    setChartData(getChartData(
      (key) => [
        Analytics.AUTOMATIC_REMINDERS,
        Analytics.VALIDATION_REMINDERS,
        Analytics.VALIDATED_TASKS,
      ].includes(key as Analytics),
      data,
      t,
    ));
  }, [data, t]);

  if (!chartData) {
    return null;
  }

  return <div className="Analysis__chart">
      <div className="Analysis__chart__content">
        <Chart
          type="bar"
          data={chartData}
          options={{
            ...defaultChartOptions,
            plugins: {
              legend: {
                display: true,
                position:  'right',
              },
              datalabels: { display: false },
              tooltip: {
                mode: 'index',
                intersect: true,
                callbacks: {
                  footer: (items) => {
                    let total = items.reduce((a, b) => a + b.parsed.y, 0);
                    return `${t('common:total')}: ${total}`;
                  },
                },
              },
            },
            scales: {
              x: { stacked: true },
              yAxis: { stacked: true },
            },
          }}
        />
      </div>
    </div>;
};

export default observer(TaskRemindersChart);
