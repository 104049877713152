import { useMemo } from 'react';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import { CreditType, RightsRole } from 'types/models';
import type { Credit } from 'types/models';
import type { FetchOneParams } from 'api/credits';
import apiCredits from 'api/credits';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ErrorMessage from 'components/ErrorMessage';
import useFetch from 'hooks/useFetch2';
import Modal from 'components/Modal';
import Button from 'components/Button';
import CreditViewModalContent from './Content';

type Props = {
  creditId?: Credit['id'],
  onClose(): void,
  onClickEdit(id: Credit['id']): void,
};

const CreditViewModal = ({ creditId, onClose, onClickEdit }: Props): JSX.Element => {
  const { type: organizationType } = organizationStore.currentOrganization!;
  const isUserReader = authStore.user?.roles.includes(RightsRole.ROLE_READER);
  const { t, ct } = useContextualTranslation(organizationType);

  const { data, isLoading, error } = useFetch<FetchOneParams, Credit>(
    {
      cacheKey: 'credit',
      id: creditId!,
    },
    apiCredits.one,
    { enabled: !!creditId },
  );

  const title = useMemo(() => {
    if (!data) {
      return '';
    }
    const { type, reference: ref } = data;
    if (type === CreditType.CREDIT_NOTE) {
      return ct('credits:credit-note', { reference: ref });
    }
    if (type === CreditType.REFUND) {
      return ct('credits:refund', { reference: ref });
    }
    return ct('credits:payment-ref', { reference: ref });
  }, [data, ct]);

  return (
    <Modal
      className="CreditViewModal"
      title={title}
      headerActions={!isUserReader && (
        <Button variant="outline" onClick={() => { onClickEdit(creditId!); }}>
          {t('common:edit')}
        </Button>
      )}
      onClose={onClose}
      isLoading={isLoading}
      isOpened
    >
      <CreditViewModalContent credit={data} onClickEdit={onClickEdit} />
      {error && <ErrorMessage error={error} />}
    </Modal>
  );
};

export default observer(CreditViewModal);
