import './index.scss';
import { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PublicLayout from 'layout/PublicLayout';
import type { FetchAnswerParams } from 'api/collaborator';
import apiCollaborators from 'api/collaborator';
import authStore from 'stores/Auth';
import useFetch from 'hooks/useFetch2';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import type { ValidationErrors } from 'types/errors';
import useApiRequest from 'hooks/useApiRequest';
import AnswerForm from './Form';

export type RouteParams = {
  token: string,
};

export type FormDataAnswer = {
  token: string | null,
  firstName: string,
  lastName: string,
  identifier: string,
  password: string,
  language: string,
};

const Answer = (): JSX.Element => {
  const { t } = useTranslation();
  const { token } = useParams<RouteParams>();
  const history = useHistory();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);

  const { isLoading, data: answerData, error } = useFetch<FetchAnswerParams, string>(
    { cacheKey: 'answerCollaborator', token },
    apiCollaborators.fetchAnswer,
    { retry: false },
  );

  const {
    post,
    error: submitError,
    isLoading: isSubmitLoading,
  } = useApiRequest();

  const handleSubmit = useCallback(async (sendData: FormDataAnswer) => {
    setValidationErrors(null);

    sendData.token = token;
    const result = await post<{ email: string }>(apiCollaborators.createAnswerUrl(), sendData);
    if (!result) {
      return;
    }

    if (result.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (!result.email) {
      return;
    }

    await authStore.login(result.email, sendData.password);

    history.replace('/');
  }, [history, post, token]);

  useEffect(() => {
    if (error?.code === 2 || error?.code === 404) {
      history.replace('/login');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <PublicLayout>
      <div className="Answer">
        <h2 className="Answer__title">{t('answer:title')}</h2>
        {(!isLoading && !error && answerData) && (
          <AnswerForm
            onSubmit={handleSubmit}
            isLoading={isSubmitLoading}
            errors={validationErrors}
          />
        )}
        {isLoading && <Loading hasNoText />}
        <div className="Answer__errors">
          {error && <ErrorMessage error={error} />}
          {submitError && <ErrorMessage error={submitError} />}
        </div>
      </div>
    </PublicLayout>
  );
};

export default Answer;
