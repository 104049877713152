import './index.scss';
import { useContext } from 'react';
import customerContext from 'pages/Customer/customerContext';
import CustomerStatsDebt from './Debt';
import CustomerStatsTodos from './Todos';

const CustomerStats = (): JSX.Element | null => {
  const { data: customer } = useContext(customerContext);
  const { totalPercent } = customer?.statistics ?? { totalPercent: null };

  return (
    <div className="CustomerStats">
      <CustomerStatsDebt totalPercent={totalPercent} />
      <CustomerStatsTodos />
    </div>
  );
};

export default CustomerStats;
