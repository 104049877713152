import './index.scss';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import Icon from 'components/Icon';

interface Props {
  /** @default neutral */
  variant?: 'neutral' | 'info' | 'success' | 'warning' | 'error',
  onClose?: () => void,
  children: ReactNode,
}

const Alert = (props: Props): JSX.Element => {
  const {
    variant = 'neutral',
    onClose,
    children,
  } = props;

  const classNames = classnames('Alert', {
    'Alert--neutral': variant === 'neutral',
    'Alert--info': variant === 'info',
    'Alert--success': variant === 'success',
    'Alert--warning': variant === 'warning',
    'Alert--error': variant === 'error',
  });

  return (
    <div role="alert" className={classNames}>
      {children}
      {onClose && (
        <Button variant="outline" className="Alert__close-button" onClick={onClose}>
          <Icon name="close" />
        </Button>
      )}
    </div>
  );
};

export default Alert;
