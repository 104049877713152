import { CreditType } from 'types/models';

const getI18nCreditType = (creditType: CreditType): string => {
  const i18nCreditType = {
    [CreditType.PAYMENT]: 'credits:type.payment',
    [CreditType.REFUND]: 'credits:type.refund',
    [CreditType.CREDIT_NOTE]: 'credits:type.credit-note',
  };

  return i18nCreditType[creditType] || 'common:unknown';
};

export default getI18nCreditType;
