import parseIsoDate from './parseIsoDate';

const getRelativeDaysCount = (value: string | null | undefined): number => {
  const date = parseIsoDate(value);
  if (!date) {
    return 0;
  }

  const now = new Date();
  const diff = date.getTime() - now.getTime();

  const days = Math.round(diff / (24 * 60 * 60 * 1000));
  return Object.is(days, -0) ? 0 : days;
};

export default getRelativeDaysCount;
