import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Config from 'config';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import type { ValidationErrors } from 'types/errors';
import getI18nUserRights from 'utils/getI18nUserRights';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import { RightsRole } from 'types/models';

type Props = {
  errors?: ValidationErrors | null,
};

const ModalInviteForm = ({ errors }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = authStore;
  const { businessUnits } = organizationStore;
  const [showBusinessUnit, setShowBusinessUnit] = useState(true);
  const [email, setEmail] = useState('');

  const userRightsOptions = useMemo(() => (
    Object.values(RightsRole).filter((userRight) =>
      ![
        RightsRole.ROLE_USER,
        RightsRole.ROLE_SUPER_ADMINISTRATOR,
        RightsRole.ROLE_COORDINATOR,
        RightsRole.ROLE_DEMO,
      ].includes(userRight),
    ).map((right) => ({
      label: t(getI18nUserRights(right)),
      value: right,
    }))
  ), [t]);

  const businessUnitsOptions = useMemo(() => (
    businessUnits.map(({ name, identifier }) => ({
      label: name,
      value: identifier,
    }))
  ), [businessUnits]);

  const handleRoleChange = useCallback(( name: string | null, newValue: string | null ) => {
    setShowBusinessUnit(newValue !== 'ROLE_OWNER');
  }, []);

  return (
    <div className="ModalInviteForm">
      <FormFieldset>
        <FormGroup label={t('collaborators:add-collaborator')} error={errors?.email}>
          <FormControl
            name="email"
            type="email"
            isInvalid={!!errors?.email}
            value={email}
            onChange={(newValue) => {
              setEmail(newValue.toLowerCase());
            }}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('collaborators:invitation-language')} error={errors?.language}>
          <FormSelect
            name="language"
            placeholder={t('common:please-choose')}
            selectOptions={Config.LANGUAGES}
            isInvalid={!!errors?.language}
            defaultValue={user?.locale}
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      <FormGroup label={t('collaborators:level')}>
        <FormSelect
          name="role"
          className="ModalEditCollaboratorsForm"
          placeholder={t('common:please-choose')}
          selectOptions={userRightsOptions}
          onSelect={handleRoleChange}
        />
      </FormGroup>
      {showBusinessUnit && (
        <FormGroup label={t('collaborators:business-unit')}>
          <FormSelect
            name="businessUnit"
            className="ModalEditCollaboratorsForm"
            placeholder={t('common:please-choose')}
            selectOptions={businessUnitsOptions}
            isMultiple
          />
        </FormGroup>
      )}
    </div>
  );
};

export default observer(ModalInviteForm);
