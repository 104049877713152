import { observer } from 'mobx-react';
import type { Scenario } from 'types/models';
import ScenarioDetailsActions from './Actions';

type Props = {
  scenario: Scenario,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const ScenarioDetails = (props: Props): JSX.Element | null => {
  const { scenario, onActionDone, onActionError } = props;

  return (
    <div className="ScenarioDetails">
        <ScenarioDetailsActions
          scenario={scenario}
          onActionDone={onActionDone}
          onActionError={onActionError}
        />
    </div>
  );
};

export default observer(ScenarioDetails);
