import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import authStore from 'stores/Auth';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import apiHistorySteps, { StepActionStatus } from 'api/historySteps';
import { RightsRole, StepActionEntity } from 'types/models';

type Props = {
  selection?: Array<Record<string, string | number>>,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onActionProcess(message: string): void,
  onProcessDone(): void,
};

const ActionsBulkActions = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const isUserReader = authStore.user?.roles.includes(RightsRole.ROLE_READER);
  const {
    selection,
    onActionDone,
    onActionError,
    onActionProcess,
    onProcessDone,
  } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { patch, error, isLoading } = useApiRequest();

  const [confirmVariant, setConfirmVariant] = useState<'primary'>();
  const [confirmTitleButton, setConfirmTitleButton] = useState<string>();
  const [cancelTitleButton, setCancelTitleButton] = useState<string>();
  const [action, setAction] = useState<'validate' | 'cancel'>();

  const handleConfirmValidate = useCallback(() => {
    setConfirmVariant('primary');
    setConfirmTitleButton(t<string>('actions:bulk.validate-actions'));
    setCancelTitleButton(t<string>('actions:confirm.keep'));
    setAction('validate');
    showConfirm(
      t('actions:confirm.title-validate'),
      t('actions:confirm.description-validate'),
    );
  }, [showConfirm, t, setConfirmVariant, setConfirmTitleButton]);

  const handleConfirmCancel = useCallback(() => {
    setConfirmVariant('primary');
    setConfirmTitleButton(t<string>('actions:bulk.remove-actions'));
    setCancelTitleButton(t<string>('actions:confirm.cancel'));
    setAction('cancel');
    showConfirm(
      t('actions:confirm.title-remove'),
      t('actions:confirm.description-remove'),
    );
  }, [showConfirm, t, setConfirmVariant, setConfirmTitleButton]);

  const handleSubmit = useCallback(async () => {
    hideConfirm();

    if (!action || !selection) {
      throw new Error('Action non définie.');
    }

    onActionProcess(
      action === 'cancel' ? t('actions:bulk.remove-process') : t('actions:bulk.validate-process'),
    );

    const payload = selection.map((row) => JSON.stringify({
      uuid: row.code,
      status: action === 'cancel' ? StepActionStatus.CANCEL : (
        row.entity === StepActionEntity.TASK ? StepActionStatus.COMPLETED : StepActionStatus.VALIDATED
      ),
    })).join('\n');
    await patch(apiHistorySteps.bulkUpdateUrl, payload);

    onProcessDone();
    hideConfirm();
    onActionDone(
      action === 'cancel' ? t('actions:bulk.remove-done') : t('actions:bulk.validate-done'),
    );
  }, [hideConfirm, action, selection, t, patch, onActionDone, onActionProcess, onProcessDone]);

  useEffect(() => {
    if (error) {
      hideConfirm();
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (isUserReader || !selection?.length) {
    return null;
  }

  return (
    <div className="CustomersBulkActions">
      <Button
        variant="outline-light"
        onClick={handleConfirmValidate}
        disabled={isLoading}
        className="ActionsBulkActions__validate"
      >
        {t('actions:bulk.validate-actions')}
      </Button>
      <Button
        variant="outline-light"
        onClick={handleConfirmCancel}
        className="ActionsBulkActions__cancel"
        disabled={isLoading}
      >
        {t('actions:bulk.remove-actions')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmit}
        onCancel={hideConfirm}
        variant={confirmVariant}
        confirmButtonText={confirmTitleButton}
        cancelButtonText={cancelTitleButton}
      />
    </div>
  );
};

export default observer(ActionsBulkActions);
