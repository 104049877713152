import './index.scss';
import { forwardRef, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SmallSelect from 'components/SmallSelect';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Filler, ChartDataLabels);

type ChartFilter = {
  defaultValue: string,
  values: string[],
  onChange: (value: string) => void,
};

type Props = {
  title: string,
  chartData?: number[],
  chartLabels?: string[],
  chartFilter?: ChartFilter,
  colors?: string[],
  className?: string,
  children: JSX.Element,
};

const StatBlock = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    title,
    chartData,
    chartLabels,
    chartFilter,
    colors,
    className,
    children,
  } = props;

  const chart = useMemo(() => (
    !chartData ? null : {
      labels: chartLabels,
      datasets: [{
        label: '',
        data: chartData,
        fill: true,
        borderJoinStyle: 'round' as const,
        pointRadius: 0,
        pointHoverRadius: 0,
        pointBorderWidth: 0,
        borderColor: '#e5e5e5',
        borderWidth: 1,
        backgroundColor: '#fafafa',
        tension: 0.3,
      }],
    }
  ), [chartData, chartLabels]);

  const chartOptions = useMemo(() => ({
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
    },
  }), []);

  const chartValues = useMemo(() => (
    chartFilter?.values.map((value) => ({ value, label: value })) || []
  ), [chartFilter]);

  const handleChangeFilter = useCallback((name: string, value: string) => {
    if (chartFilter?.onChange) {
      chartFilter.onChange(value);
    }
  }, [chartFilter]);

  const classNames = classnames('StatBlock', className, {
    'StatBlock--with-colors': colors && colors.length > 0,
  });

  const chartIsEmpty = useMemo(() => (
    chartData?.every((n) => n === 0)
  ), [chartData]);

  return (
    <div className={classNames} ref={ref}>
      <div className="StatBlock__content">
        {(chart && !chartIsEmpty) && (
          <div className="StatBlock__content__chart">
            <Line data={chart} options={chartOptions} />
          </div>
        )}
        <div className="StatBlock__content__header">
          <div className="StatBlock__content__header__title">
            {title}
          </div>
          {chartValues.length > 0 && (
            <div className="StatBlock__content__header__filter">
              <SmallSelect
                name="chartFilter"
                options={chartValues}
                value={chartFilter?.defaultValue}
                onChange={handleChangeFilter}
              />
            </div>
          )}
        </div>
        {children}
      </div>
      {(chartLabels && chartLabels.length > 0) && (
        <div className="StatBlock__labels">
          {chartLabels.map((label) => (
            <div key={label} className="StatBlock__labels__item">
              {label.replace('.', '')}
            </div>
          ))}
        </div>
      )}
      {(colors && colors.length > 0) && (
        <div className="StatBlock__colors">
          {colors.map((color: string) => (
            <div
              key={color}
              className="StatBlock__colors__item"
              style={{ backgroundColor: color }}
            />
          ))}
        </div>
      )}
    </div>
  );
});

export default StatBlock;
