import type { AxiosResponse } from 'axios';
import axios from 'axios';
import requester from 'utils/requester';
import Config from 'config';
import type { ValidationErrors } from 'types/errors';
import ResponseError from 'utils/errors';

export type SignupData = {
  firstName: string,
  lastName: string,
  language: string,
  email: string,
  phone: string,
  password: string,
};

type SignupResponse = {
  id: number,
};

export type SignupValidationErrors = {
  errors: ValidationErrors,
};

type ConfirmationResponse = {
  id: number,
};

export type FetchCheckResetPasswordTokenParams = { token: string };

const signUp = async (signupData: SignupData): Promise<SignupResponse | SignupValidationErrors> => {
  const { data } = await requester.post('user', signupData);
  return data;
};

const confirmEmail = async (email: string): Promise<ConfirmationResponse> => {
  const { data } = await requester.post('user/confirm', { email });
  return data;
};

const resetPassword = async (email: string): Promise<void> => {
  const resetPasswordUrl = `${Config.BASE_URL}/security/user-resetting-password`;
  let status: number | undefined;
  let message: string | undefined;

  try {
    // - Ici, on n'utilise pas le requester mais directement Axios, car on ne veut
    // pas des headers d'authentification insérés par requester, ni la gestion des erreurs standard.
    await axios.post<FormData, AxiosResponse>(resetPasswordUrl, { email });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      status = response?.status;
      message = response?.data['hydra:description'];
    }
    throw new ResponseError(status ?? 500, message ?? 'Unknown error');
  }
};

const confirmPasswordReset = async (
  passwordNew: string,
  passwordNewRepeat: string,
  authorizationCode: string,
  token: string,
): Promise<void> => {
  const confirmResetPasswordUrl = `${Config.BASE_URL}/security/user-resetting-password-confirm`;
  let status: number | undefined;
  let message: string | undefined;

  try {
    // - Ici, on n'utilise pas le requester mais directement Axios, car on ne veut
    // pas des headers d'authentification insérés par requester, ni la gestion des erreurs standard.
    await axios.post<FormData, AxiosResponse>(confirmResetPasswordUrl, {
      passwordNew,
      passwordNewRepeat,
      authorizationCode,
    },
    { params: { token } },
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      status = response?.status;
      message = response?.data['hydra:description'];
    }
    throw new ResponseError(status ?? 500, message ?? 'Unknown error');
  }
};

const checkResetPasswordToken = async (
  { token }: FetchCheckResetPasswordTokenParams,
): Promise<boolean> => {
  const checkResetPasswordTokenUrl = `${Config.BASE_URL}/security/user-resetting-password-token/${token}`;
  let status: number | undefined;
  let message: string | undefined;

  try {
    // - Ici, on n'utilise pas le requester mais directement Axios, car on ne veut
    // pas des headers d'authentification insérés par requester, ni la gestion des erreurs standard.
    const user = await axios.get<FormData, AxiosResponse>(checkResetPasswordTokenUrl);
    return !!user;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      status = response?.status;
      message = response?.data['hydra:description'];
    }
    throw new ResponseError(status ?? 500, message ?? 'Unknown error');
  }
};

export default { signUp, confirmEmail, resetPassword, confirmPasswordReset, checkResetPasswordToken };
