import './index.scss';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';
import apiCredits from 'api/credits';

type Props = {
  id: number,
  reference: string | null,
  name: string | null,
  onActionDone(title: string, message: string): void,
  onActionError(message: string): void,
};

const CustomersRowActions = (props: Props): JSX.Element => {
  const { id, name, onActionDone, onActionError } = props;
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const history = useHistory();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { remove, error, isLoading } = useApiRequest();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(ct('common:remove'), ct('credits:confirm.remove'));
  }, [showConfirm, ct]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const response = await remove(apiCredits.deleteUrl(id));
    if (response === '') {
      onActionDone(
        ct('credits:toast.deleted'),
        ct('credits:toast.deleted-name', { name }),
      );
    }
  }, [hideConfirm, remove, id, onActionDone, ct, name]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
  }, [error, onActionError, t]);

  const actions = [
    <Button
      variant="list"
      onClick={() => history.push(`/credits/edit/${id}`)}
      title={ct('common:edit')}
      disabled={isLoading}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:edit')}
    </Button>,
    <Button
      variant="list-danger"
      onClick={handleConfirmDelete}
      title={ct('common:remove')}
      disabled={isLoading}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:remove')}
    </Button>,
  ];

  return (
    <div className="CreditsRowActions">
      <DropdownActions
        actions={actions}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={ct('common:remove')}
      />
    </div>
  );
};

export default observer(CustomersRowActions);
