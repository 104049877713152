import type Misc from 'types/misc';
import type { BusinessUnit, Debit, DebitCreditEntity, DebitType, Eav, NoteSearch, NoteSummary, ScenarioSummary } from 'types/models';

export enum ClientType {
  COMPANY = 'company',
  INDIVIDUAL = 'individual',
}

export type ClientStatus = 'ACTIVE' | 'ALL';

export type ClientStatsTotalPercent = {
  notDue: {
    totalNotDue: number,
    percentNotDue: number,
    notDueCount: number,
  },
  due: {
    totalDue: number,
    percentDue: number,
    dueCount: number,
  },
  late: {
    totalLate: number,
    percentLate: number,
    lateCount: number,
  },
};

export type ClientStats = {
  pendingActionsCount: number,
  recentActivityCount: number,
  recentStepCount: number,
  totalToCollect: number,
  totalPercent: ClientStatsTotalPercent,
  progression: number,
  totalCollected: number,
  debtTotal: number,
  totalUnpaidDebit: number,
  dueCount: number, // Anciennement `nbPayRequestDue`
  notDueCount: number, // Anciennement `nbPayRequestNotDue`
  totalNotDue: number, // Anciennement `totalAmountPayRequestNotDue`
  totalDue: number, // Anciennement `totalAmountPayRequestDue`
  nextSending: string | null, // Anciennement `nextSendPr`
  lastSending: string | null, // Anciennement `lastSentPr`
};

export type ClientAmounts = {
  totalAmount: number,
  totalAmountDue: number,
};

export type ClientUserData = Misc.IdentifierName & {
  id: number,
  firstName: string,
  lastName: string,
};

export type ClientUser = {
  user: ClientUserData,
};

export type Client = {
  id: number,
  type: ClientType,
  address?: string,
  identifier: string, // - Anciennement `reference`.
  denomination: string | null, // - Anciennement `firstName`, `lastName`, et `corporateName`
  attachment: boolean | null,
  businessUnit: BusinessUnit | null,
  hasFutureDebitsOrCredits: boolean | null,
  lastCustomerNote: NoteSummary | null,
  eavs: Eav[] | null,
  users: ClientUser[] | null,
  // Chaque sous-array représente un groupe de scénarios avec un scénario normal et un scénario virtuel
  scenario: ScenarioSummary[][] | null,
  statistics: ClientStats | null,
  mute: boolean,
  createdAt: string,
  updatedAt: string,
  hasDunningAssignment: boolean,
  lastMutedAt: Misc.DateTimezone | null,
  dunningNextAction: Misc.DateTimezone | null,
  dunningPastAction: Misc.DateTimezone | null,
};

export type ClientIdentifiers = {
  id: Client['id'],
  type: Client['type'],
  identifier: Client['identifier'],
  denomination: Client['denomination'],
};

export type UserIdentifiers = {
  id: string | number,
  identifier: string,
  firstname: string,
  lastname: string,
};

export type ClientSummary = {
  id: Client['id'],
  identifier: Client['identifier'],
  code: Client['identifier'],
  denomination: Client['denomination'],
  type: Client['type'],
  enabled: boolean | null,
  mute: Client['mute'],
  organizationName: string | null,
  assignedUser: UserIdentifiers[] | null,
  scenario: ScenarioSummary[][] | null,
  lastClientNote: NoteSearch | null,
  lastMutedAt: Client['lastMutedAt'],
  values: Client['eavs'],
  statistics: ClientSummaryStats | null,
  dunningNextAction: Misc.DateTimezone | null,
  dunningPastAction: Misc.DateTimezone | null,
  createdAt: Misc.DateTimezone,
  updatedAt: Misc.DateTimezone,
};

export type ClientSummaryStats = {
  totalToCollect: number,
  totalCollected: number,
  totalUnpaidDebit: number,
  totalDueUnpaidDebit: number,
  totalDue: number,
  progression: number,
};

export type ClientPost = {
  organization: string,
  type: ClientType,
  identifier: string,
  denomination: string,
  eavs: Eav[],
  users: string[],
};

export type ClientDebit = {
  entity: DebitCreditEntity.DEBIT,
  id: Debit['id'],
  reference: Debit['reference'],
  date: Debit['issueAt'],
  // TODO Trouver dans la v2 ce qui remplace balance
  balance: number,
  amount: Debit['initialCost'],
  amountNotAssigned: Debit['amount'],
  dueAt: Debit['dueAt'],
  litigatedAt: Debit['litigatedAt'],
  subject: Debit['subject'],
  currency: Debit['currency'],
  muted: boolean,
  status: Debit['status'],
  phase: Debit['phase'],
  type: DebitType,
};
