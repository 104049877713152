import './index.scss';
import { useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';
import { observer } from 'mobx-react';
import i18n from 'config/i18n';
import config from 'config';
import BrandLogo from 'components/BrandLogo';
import FormSelect from 'components/FormSelect';
import Links from './Links';

type Props = {
  withLanguageSelection?: boolean,
  children: ReactNode,
};

const PublicLayout = ({ withLanguageSelection = false, children }: Props): JSX.Element => {
  const currentYear = (new Date()).getFullYear();

  const langOptions = useMemo(() => (
    config.LANGUAGES.map(({ value, label }) => ({
      value: value.substring(0, 2),
      label,
    }))
  ), []);

  const handleChangeLanguage = useCallback((name: string, newValue: string | null) => {
    i18n.changeLanguage(newValue ?? i18n.language);
  }, []);

  return (
    <div className="PublicLayout">
      <div className="PublicLayout__intro">
        <BrandLogo />
        <Links />
        <div className="PublicLayout__intro__footer">
          <p className="PublicLayout__intro__footer__copyright">
            ©{currentYear}&nbsp;Dunforce
          </p>
          <p className="PublicLayout__intro__footer__version">
            v{config.APP_VERSION}
          </p>
          {withLanguageSelection && (
            <FormSelect
              name="language"
              withClearButton={false}
              selectOptions={langOptions}
              onSelect={handleChangeLanguage}
              defaultValue={i18n.language ?? 'en'}
            />
          )}
        </div>
      </div>
      <div className="PublicLayout__container">
        {children}
      </div>
    </div>
  );
};

export default observer(PublicLayout);
