import './index.scss';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { Channel } from 'types/models';

type Props = {
  channel: Channel | null,
};

const ChannelIcon = ({ channel }: Props): JSX.Element | null => {
  const className = classNames('ChannelIcon', {
    'ChannelIcon--registered-letter': channel === Channel.LETTER_REGISTERED,
  });

  if (!channel) {
    return null;
  }

  const channelsIcons = {
    [Channel.EMAIL]: 'email' as const,
    [Channel.LETTER]: 'envelope' as const,
    [Channel.LETTER_REGISTERED]: 'envelope' as const,
    [Channel.SMS]: 'sms' as const,
  };

  if (!channelsIcons[channel]) {
    return null;
  }

  return <Icon name={channelsIcons[channel]} className={className} />;
};

export default ChannelIcon;
