import { useCallback, useState } from 'react';

export type ToastStatus = 'primary' | 'info' | 'success' | 'warning' | 'error' | 'process';

const useToast = () => {
  const [isToastShowed, setShowToast] = useState(false);
  const [toastText, setToastText] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<ToastStatus>('success');

  const showToast = useCallback(
    (text: string, status: ToastStatus) => {
      setShowToast(true);
      setToastStatus(status);
      setToastText(text);
    },
    [],
  );

  const hideToast = useCallback(
    () => {
      setShowToast(false);
    },
    [],
  );

  return {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  };
};

export default useToast;
