import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BillsInProgress from './BillsInProgress';

interface Props {
  data: { sumUnpaidDebit: number, countUnpaidDebit: number } | null,
}

const HomeHeader: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <header className="HomeHeader">
      <h1 className="HomeHeader__title">{t('home:title')}</h1>
      {data && (
        <BillsInProgress
          amount={data.sumUnpaidDebit}
          count={data.countUnpaidDebit}
        />
      )}
    </header>
  );
};

export default HomeHeader;
