import Request from 'utils/request';

export type FetchOneParams = {
  token: string,
};

const fetchOne = async ({ token }: FetchOneParams) => {
  const result = await Request.get<{}>(`/account/confirm/${token}`);
  return result;
};

export { fetchOne };
