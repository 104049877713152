import './index.scss';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import customerContext from 'pages/Customer/customerContext';
import CustomerEditNote from 'pages/Customer/Modals/EditNote';
import Button from 'components/Button';
import Icon from 'components/Icon';

type Props = {
  variant: 'list' | 'outline',
};

const CustomerActionCreateNote = ({ variant }: Props): JSX.Element => {
  const { data: customer, onActionDone } = useContext(customerContext);
  const { t } = useTranslation();

  const handleActionDone = useCallback((title: string, message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showEditNote, hideEditNote] = useModal(() => (
    <CustomerEditNote
      show
      customer={customer}
      onClose={hideEditNote}
      onDone={handleActionDone}
    />
  ), [customer, handleActionDone]);

  return (
    <Button variant={variant} onClick={showEditNote} className="CustomerActionCreateNote">
      <Icon name="comment" className="CustomerActionCreateNote__icon" />
      {t('notes:create')}
    </Button>
  );
};

export default CustomerActionCreateNote;
