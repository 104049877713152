import './index.scss';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import Button from 'components/Button';
import { DefaultValue } from 'components/FormSelect/utils';
import Icon from 'components/Icon';

type Props = {
  name: string,
  defaultValue?: DefaultValue,
  onChange?(reference: string): void,
  debitId?: string,
  isInvalid?: boolean,
  defaultOptions?: SelectSearchOption[],
};

const SelectDebitForCustomer = (props: Props): JSX.Element => {
  const {
    name,
    defaultValue,
    onChange,
    defaultOptions,
    isInvalid = false,
  } = props;
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<string | string[]>((defaultValue as string) || '');
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!defaultValue || currentValue.length > 0) {
      return;
    }

    setCurrentValue(defaultValue as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleChange = useCallback((selectedValue: string | string[]) => {
    setCurrentValue(selectedValue);

    const newValue = (Array.isArray(selectedValue)) ? selectedValue.join(',') : selectedValue;

    if (onChange) {
      onChange(newValue);
    }

    if (!inputRef?.current) {
      return;
    }
    // - Ceci permet de déclencher un onChange au niveau du <form> parent
    const inputValSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    )?.set;
    inputValSetter?.call(inputRef.current, newValue);
    inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  }, [onChange]);

  const handleClear = useCallback(() => {
    setCurrentValue('');
    setIsFocus(false);
  }, []);

  const classNames = classnames('SelectDebitForCustomer', {
    'SelectDebitForCustomer--has-value': currentValue.length > 0,
    'SelectDebitForCustomer--is-invalid': isInvalid,
    'SelectDebitForCustomer --with-flying-label-opened': isFocus,
  });

  return (
    <div
      className={classNames}
      onFocus={() => { setIsFocus(true); }}
      onBlur={() => { setIsFocus(false); }}
    >
      <SelectSearch
        onChange={handleChange}
        value={currentValue}
        options={defaultOptions || []}
        search
        placeholder={t<string>('common:search-in-select')}
        multiple={false}
        printOptions="on-focus"
      />
      <input
        type="text"
        value={currentValue}
        name={name}
        ref={inputRef}
        onChange={() => {}}
        className="SelectDebitForCustomer__hidden-field"
      />
      {currentValue.length > 0 && (
        <Button
          variant="light"
          onClick={handleClear}
          className="SelectDebitForCustomer__clear"
        >
          <Icon name="close-small" />
        </Button>
      )}
    </div>
  );
};

export default observer(SelectDebitForCustomer);
