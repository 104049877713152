import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import type Misc from 'types/misc';
import type { Contact, EAVValue, Eav } from 'types/models';
import recomposeName from 'utils/recomposeName';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch2';
import { FetchOneParams } from 'api/contacts';
import apiContacts from 'api/contacts';
import { getAllEavsValues } from 'utils/getCustomerEavs';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import FormSelectCustomer from 'components/FormSelectCustomer';
import Loading from 'components/Loading';
import FormSelectCountry from 'components/FormSelectCountry';
import FormEavs from 'components/FormEavs';

type Props = {
  defaultData?: Contact | null,
  isCreate: boolean,
  customerId?: number,
  allRoles: Misc.IdIdentifierValue[] | null,
  errors?: ValidationErrors | null,
  defaultCustomerId?: number,
  defaultRole?: string,
};

const ContactForm = (props: Props): JSX.Element => {
  const { attributes: eavs, currentOrganization } = organizationStore;
  const { type, country } = currentOrganization!;
  const { t, ct } = useContextualTranslation(type);

  const {
    allRoles,
    isCreate,
    defaultData = null,
    errors = null,
    defaultCustomerId,
    defaultRole,
  } = props;

  const defaultRoleId = useMemo(() => (
    allRoles?.find((role) => role.identifier === defaultRole)?.id
  ), [allRoles, defaultRole]);

  const rolesOptions = useMemo(() => (
    allRoles?.map(({ id, identifier, value }) => ({
      label: value ? value : identifier,
      value: id,
    }))
  ), [allRoles]);

  const {
    data: eavsContact,
  } = useFetch<FetchOneParams, EAVValue[]>(
    {
      cacheKey: 'contactAllEavs',
      id: defaultData?.id!,
    },
    apiContacts.fetchEavs,
    { enabled: !!defaultData?.id },
  );

  const defaultEavs = useMemo(() => (
    getAllEavsValues(eavsContact, eavs.contact)
  ), [eavsContact, eavs]);

  if (!isCreate && !defaultData) {
    return <Loading />;
  }

  return (
    <div className="ContactForm">
      <FormFieldset>
        <FormGroup label={ct('contacts:associated-customer')} mandatory error={errors?.client}>
          {isCreate && (
            <FormSelectCustomer
              name="client"
              defaultValue={defaultCustomerId}
              isInvalid={!!errors?.client}
            />
          )}
          {!isCreate && (
            <FormControl
              type="text"
              value={defaultData?.client ? (recomposeName(defaultData.client) ?? '') : ''}
              readOnly
            />
          )}
        </FormGroup>
        <FormGroup label={t('contacts:role')} mandatory error={errors?.contactRole}>
          <FormSelect
            name="contactRole"
            placeholder={t('common:please-choose')}
            selectOptions={rolesOptions}
            defaultValue={defaultRoleId || defaultData?.contactRole?.id}
            isInvalid={!!errors?.contactRole}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          className="ContactForm__civility"
          label={t('common:civility')}
          error={errors?.civility}
        >
          <FormControl
            type="text"
            name="civility"
            autoComplete="off"
            defaultValue={defaultData?.civility || ''}
            isInvalid={!!errors?.civility}
          />
        </FormGroup>
        <FormGroup label={t('common:first-name')} error={errors?.firstName}>
          <FormControl
            type="text"
            name="firstName"
            autoComplete="off"
            defaultValue={defaultData?.firstName || ''}
            isInvalid={!!errors?.firstName}
          />
        </FormGroup>
        <FormGroup label={t('common:last-name')} error={errors?.lastName}>
          <FormControl
            type="text"
            name="lastName"
            autoComplete="off"
            defaultValue={defaultData?.lastName || ''}
            isInvalid={!!errors?.lastName}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:phone')} error={errors?.phone} shouldDisplayErrorUnderInput>
          <FormControl
            type="text"
            name="phone"
            autoComplete="off"
            defaultValue={defaultData?.phone || ''}
            isInvalid={!!errors?.phone || !!errors?.channel}
          />
        </FormGroup>
        <FormGroup label={t('common:cell-phone')} error={errors?.cellPhone} shouldDisplayErrorUnderInput>
          <FormControl
            type="text"
            name="cellPhone"
            autoComplete="off"
            defaultValue={defaultData?.cellPhone || ''}
            isInvalid={!!errors?.cellPhone || !!errors?.channel}
          />
        </FormGroup>
        <FormGroup
          className="ContactForm__email"
          label={t('common:email')}
          error={errors?.email}>
          <FormControl
            name="email"
            type="email"
            autoComplete="off"
            defaultValue={defaultData?.email || ''}
            isInvalid={!!errors?.email || !!errors?.channel}
          />
        </FormGroup>
      </FormFieldset>
      <hr className="ContactForm__separator" />
      <FormFieldset>
        <FormGroup label={t('contacts:mail-recipient')} error={errors?.nameAddress}>
          <FormControl
            type="text"
            name="nameAddress"
            autoComplete="off"
            placeholder={t('contacts:different-contact')}
            defaultValue={defaultData?.nameAddress ?? ''}
            isInvalid={!!errors?.nameAddress}
          />
        </FormGroup>
        <FormGroup label={t('contacts:company-name')} error={errors?.companyAddress}>
          <FormControl
            type="text"
            name="companyAddress"
            autoComplete="off"
            defaultValue={defaultData?.companyAddress ?? ''}
            isInvalid={!!errors?.companyAddress}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={`${t('contacts:adresse-ligne')} 1`} error={errors?.address1}>
          <FormControl
            type="text"
            name="address1"
            autoComplete="off"
            defaultValue={defaultData?.address1 ?? ''}
            isInvalid={!!errors?.address1 || !!errors?.channel}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={`${t('contacts:adresse-ligne')} 2`} error={errors?.address2}>
          <FormControl
            type="text"
            name="address2"
            autoComplete="off"
            defaultValue={defaultData?.address2 ?? ''}
            isInvalid={!!errors?.address2}
          />
        </FormGroup>
        <FormGroup label={`${t('contacts:adresse-ligne')} 3`} error={errors?.address3}>
          <FormControl
            type="text"
            name="address3"
            autoComplete="off"
            defaultValue={defaultData?.address3 ?? ''}
            isInvalid={!!errors?.address3}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          className="ContactForm__city"
          label={t('common:city')}
          error={errors?.city}
        >
          <FormControl
            type="text"
            name="city"
            autoComplete="off"
            defaultValue={defaultData?.city ?? ''}
            isInvalid={!!errors?.city}
          />
        </FormGroup>
        <FormGroup label={t('common:postal-code')} error={errors?.postalCode}>
          <FormControl
            type="text"
            name="postalCode"
            autoComplete="off"
            defaultValue={defaultData?.postalCode ?? ''}
            isInvalid={!!errors?.postalCode}
          />
        </FormGroup>
        <FormGroup label={t('common:country')} error={errors?.country}>
          <FormSelectCountry
            name="country"
            placeholder={t('common:please-choose')}
            defaultValue={defaultData?.country ?? country}
            isInvalid={!!errors?.country}
          />
        </FormGroup>
      </FormFieldset>
      <hr className="ContactForm__separator" />
      {eavs && eavs.contact?.length > 0 && (
        <FormEavs
          eavs={eavs.contact}
          defaultValues={defaultEavs?.map(({ identifier, value }) => (
            { identifier, value }
          )) as Eav[]}
          errors={errors?.eav as unknown as ValidationErrors}
        />
      )}
    </div>
  );
};

export default observer(ContactForm);
