import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import type Misc from 'types/misc';
import type { ValidationErrors } from 'types/errors';
import type { DebitPlan } from 'types/models';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ErrorMessage from 'components/ErrorMessage';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import DebitEditPlanForm from './Form';

type Props = {
  onDone(message: string): void,
  onClose(): void,
  plan: DebitPlan,
  payrequestId: number,
  payrequestReference: string,
};

const DebitEditPlan = (props: Props): JSX.Element => {
  const { onDone, onClose, plan, payrequestReference, payrequestId } = props;
  const { type } = organizationStore.currentOrganization!;
  const { ct, t } = useContextualTranslation(type);
  const isMountedRef = useIsMountedRef();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const { put, isLoading, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(
    () => (hasChanges ? setShowCancelConfirm(true) : closeSelf()),
    [hasChanges, closeSelf],
  );

  const mapFormData = useCallback(
    (rawData: ModalFormData): { newPlan: string } => ({
      newPlan: rawData.plan as string,
    }),
    [],
  );

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    const data = mapFormData(formData);
    const result = await put<Misc.IdName>(`pay-requests/manage/${payrequestId}/switch-plan`, data);

    if (!isMountedRef.current || !result) {
      return;
    }
    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    closeSelf();
    onDone(t('debits:dunning-plan-toast.edited', { name: result?.name }));
  }, [mapFormData, isMountedRef, closeSelf, onDone, t, put, payrequestId]);

  return (
    <ModalForm
      isOpened
      title={ct('debits:dunning-plan-edit-title', { bill: payrequestReference })}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && (<ErrorMessage error={error} />)}
      <DebitEditPlanForm
        defaultData={plan.name}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(DebitEditPlan);
