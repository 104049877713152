import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultNoDataGraph } from './defaultNoData.svg';
import { ReactComponent as ActivityNoDataGraph } from './activityNoData.svg';

interface Props {
  variant?: 'default' | 'activity',
}

const NoDataGraph: React.FC<Props> = ({ variant }) => {
  const { t } = useTranslation();

  return (
    <div className="NoData">
      <p className="NoData__text">{t('common:no-data-was-found')}</p>
      {variant !== 'activity' ? <DefaultNoDataGraph /> : <ActivityNoDataGraph />}
    </div>
  );
};

export default NoDataGraph;
