import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import currenciesStore from 'stores/Currencies';
import type { Currency, CurrencyCode } from 'types/models';
import type { ValidationErrorItem } from 'types/errors';
import FormGroup from 'components/FormGroup';
import InputGroup from 'components/InputGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import ErrorValidationMessage from 'components/ErrorValidationMessage';

type Props = {
  amountFormName?: string,
  currencyFormName?: string,
  defaultCurrency?: CurrencyCode | null,
  defaultAmount?: string | number | string[],
  maxAmount?: number,
  amount?: string | number,
  currency?: CurrencyCode,
  onChangeAmount?(amount: string): void,
  onChangeCurrency?(currency: CurrencyCode): void,
  hasCurrencyDisabled?: boolean,
  className?: string,
  currencyError?: ValidationErrorItem | null,
  amountError?: ValidationErrorItem | null,
  shouldDisplayErrorUnderInput?: boolean,
};

const FormAmountCurrency = (props: Props): JSX.Element => {
  const {
    currencyFormName = 'currency',
    amountFormName = 'amount',
    defaultAmount,
    defaultCurrency,
    onChangeAmount,
    onChangeCurrency,
    hasCurrencyDisabled = false,
    amount,
    currency,
    className,
    amountError,
    maxAmount,
    currencyError,
    shouldDisplayErrorUnderInput = false,
  } = props;
  const { t } = useTranslation();
  const { currencies, currenciesOptions, isBusy, fetchAll } = currenciesStore;

  const [activeCurrency, setActiveCurrency] = useState<CurrencyCode | null>(
    currency || defaultCurrency || null,
  );
  const hasRefetched = useRef<boolean>(false);

  useMemo(() => {
    if (!currenciesOptions && !isBusy && !hasRefetched.current) {
      fetchAll();
      hasRefetched.current = true;
    }
  }, [currenciesOptions, fetchAll, isBusy]);

  const currentCurrency = useMemo<Currency | undefined>(
    () => currencies?.find(
      (currencyData: Currency) => currencyData.code === activeCurrency,
    ),
    [activeCurrency, currencies],
  );

  useEffect(() => {
    if (currency) {
      setActiveCurrency(currency);
    }
  }, [currency]);

  const classNames = useMemo(() => (
    classnames('FormAmountCurrency', className, {
      'FormAmountCurrency--with-error-under-input': shouldDisplayErrorUnderInput
        && (currencyError || amountError),
    })
  ), [amountError, className, currencyError, shouldDisplayErrorUnderInput]);

  const isAmountBigger = useMemo(() => (
    (amount !== undefined && maxAmount !== undefined && parseInt(amount.toString(), 10) > maxAmount)
    || false
  ), [amount, maxAmount]);

  const handleChangeCurrency = useCallback(
    (noopname: string, currencyId: string | null) => {
      let newValue = currencies?.find(
        (currencyData: Currency) => currencyData.id === parseInt(currencyId!),
      )?.code ?? null;
      setActiveCurrency(newValue);
      if (onChangeCurrency) {
        onChangeCurrency(newValue as CurrencyCode);
      }
    },
    [onChangeCurrency, currencies],
  );

  const handleChangeAmount = useCallback((newValue: string) => {
    if (onChangeAmount) {
      onChangeAmount(newValue);
    }
  }, [onChangeAmount]);

  return (
    <div className={classNames}>
      <div className="FormAmountCurrency__container">
        {!shouldDisplayErrorUnderInput && (
          <>
            <ErrorValidationMessage error={amountError} />
            <ErrorValidationMessage error={currencyError} />
          </>
        )}
        <div className="FormAmountCurrency__container__forms">
          <FormGroup
            label={t('common:amount')}
            mandatory
            className="FormAmountCurrency__container__forms__amount"
          >
            <InputGroup
              helper={currentCurrency?.symbol}
              helperPosition={currentCurrency && currentCurrency.isBefore ? 'left' : 'right'}
              isInvalid={!!amountError}
            >
              <FormControl
                type="number"
                step={0.01}
                name={amountFormName}
                value={amount === undefined ? undefined : amount}
                autoComplete="off"
                onChange={handleChangeAmount}
                defaultValue={amount !== undefined ? undefined : defaultAmount}
                isInvalid={!!amountError}
                hasWarning={isAmountBigger}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="FormAmountCurrency__container__forms__currency">
            {currenciesOptions && (
              <FormSelect
                className="FormAmountCurrency__container__forms__currency__field"
                name={currencyFormName}
                value={currentCurrency?.id}
                defaultValue={currentCurrency?.id}
                onSelect={handleChangeCurrency}
                withClearButton={false}
                disabled={hasCurrencyDisabled}
                selectOptions={currenciesOptions}
                isInvalid={!!currencyError}
              />
            )}
          </FormGroup>
        </div>
        {isAmountBigger && (
          <p className="FormAmountCurrency__container__warning">{t('credits:amount-bigger')}</p>
        )}
      </div>
      {shouldDisplayErrorUnderInput && (
        <>
          <ErrorValidationMessage error={amountError} />
          <ErrorValidationMessage error={currencyError} />
        </>
      )}
    </div>
  );
};

export default observer(FormAmountCurrency);
