import './index.scss';
import type { ReactNode, UIEvent } from 'react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import authStore from 'stores/Auth';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import organizationStore from 'stores/Organization';
import { PUBLIC_ROUTES } from '../AppRoutes';
import Header from './Header';

type Props = {
  children: ReactNode,
};

const Layout = ({ children }: Props): JSX.Element => {
  const { isLogged, isFetched } = authStore;
  const { t } = useTranslation();
  const storedUser = window.localStorage.getItem('user');
  const mayBeLogged = !!storedUser || (isFetched && isLogged);
  const { pathname } = useLocation();
  const { notifications, currentOrganization } = organizationStore;
  const previousScroll = useRef<number>(0);
  const [isScrollDown, setIsScrollDown] = useState<boolean>(false);

  const defaultImportsCounts = {
    inProgress: 0,
    finalized: { success: 0, error: 0, critical: 0 },
  };
  const { inProgress, finalized } = notifications?.imports || defaultImportsCounts;
  const oldImportCount = useRef({ finalized });
  const oldOrganization = useRef(currentOrganization);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  useEffect(() => {
    if (currentOrganization !== oldOrganization.current) {
      oldOrganization.current = currentOrganization;
      return;
    }
    if (finalized.critical !== oldImportCount.current.finalized.critical) {
      showToast(t('reports:notifications.file-error'), 'error');
      oldImportCount.current.finalized.critical = finalized.critical;
    }
    if (finalized.error !== oldImportCount.current.finalized.error) {
      showToast(t('reports:notifications.file-warning'), 'warning');
      oldImportCount.current.finalized.error = finalized.error;
    }
    if (finalized.success !== oldImportCount.current.finalized.success) {
      showToast(t('reports:notifications.file-success'), 'success');
      oldImportCount.current.finalized.critical = finalized.critical;
    }
  }, [inProgress, finalized, showToast, t, currentOrganization]);

  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget;
    setIsScrollDown(scrollTop > previousScroll.current);
    previousScroll.current = scrollTop;
  }, [previousScroll]);

  const isPublicRoute = useMemo(() => (
    PUBLIC_ROUTES.find((route) => pathname.startsWith(route)) !== undefined
  ), [pathname]);

  const classNames = classnames('Layout', {
    'Layout--no-header': !mayBeLogged,
    'Layout--scrolling-down': isScrollDown,
  });

  if (isPublicRoute) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <div className={classNames} onScroll={handleScroll}>
      {mayBeLogged && <Header />}
      <main className="Layout__main">
        {children}
      </main>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(Layout);
