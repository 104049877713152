import './index.scss';
import { useTranslation } from 'react-i18next';
import type { Contact, ContactSummary } from 'types/models';
import Icon from 'components/Icon';

type Props = {
  contact: Contact | ContactSummary,
};

const ContactChannelsIcons = ({ contact }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { address1, postalCode, city, email, cellPhone } = contact;

  return (
    <div className="ContactChannelsIcons">
      {(address1 && postalCode && city) && (
        <Icon key="icon-address" name="envelope" title={t<string>('common:postal-address')} />
      )}
      {email && (
        <Icon key="icon-email" name="email" title={t<string>('common:email')} />
      )}
      {cellPhone && (
        <Icon key="icon-phone" name="cellphone" title={t<string>('common:cell-phone')} />
      )}
    </div>
  );
};

export default ContactChannelsIcons;
