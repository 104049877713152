import classnames from 'classnames';
import { Cell } from 'react-table';
import { SelectionStateItem } from 'hooks/useSelection';

type Props<DataType extends object = {}> = {
  cell: Cell<DataType, any>,
  showCheck: boolean,
  isSelected(rowId: SelectionStateItem): boolean,
  rowIdentifier: SelectionStateItem,
};

const DataTableBodyCell = <DataType extends object = {}>(props: Props<DataType>): JSX.Element => {
  const { cell, showCheck, isSelected, rowIdentifier } = props;

  const classNames = classnames('DataTableBody__cell', {
    'DataTableBody__cell--no-check': !showCheck,
    'DataTableBody__cell--selected': isSelected(rowIdentifier),
  });

  return (
    <td {...cell.getCellProps()} className={classNames}>
      {cell.render('Cell')}
    </td>
  );
};

export default DataTableBodyCell;
