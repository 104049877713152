import './index.scss';
import type { ReactElement } from 'react';
import Icon from 'components/Icon';

type Props = {
  icon: ReactElement,
  title: string,
  link: string,
};

const Option = ({ icon, title, link }: Props): JSX.Element => (
  <a className="FirstTimeOption" href={link}>
    <div className="FirstTimeOption__icon-box">
      {icon}
    </div>
    <p className="FirstTimeOption__title">{title}</p>
    <Icon name="chevron-right" className="FirstTimeOption__next" />
  </a>
);

export default Option;
