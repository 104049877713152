import './index.scss';
import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

type Props = {
  label: string,
  fullWidth?: boolean,
  action?: ReactElement,
  children?: ReactNode,
};

const PreviewListElement = (props: Props): JSX.Element => {
  const {
    label,
    children,
    fullWidth = false,
    action,
  } = props;
  const className = classNames('PreviewListElement', {
    'PreviewListElement--full-width': fullWidth,
  });

  return (
    <div className={className}>
      <dt className="PreviewListElement__label">
        <div className="PreviewListElement__label__content">
          {label}
          {action}
        </div>
      </dt>
      <dd className="PreviewListElement__content">
        {children}
      </dd>
    </div>
  );
};

export default observer(PreviewListElement);
