import './index.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import type { ValidationErrors } from 'types/errors';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import type { RoleAlias } from 'types/models';
import organizationStore from 'stores/Organization';

export type UserOrganizationForm = {
  identifier: string,
  roleAlias?: RoleAlias['id'],
};

type Props = {
  defaultData?: UserOrganizationForm,
  errors?: ValidationErrors | null,
};

const MyOrganizationEditForm = ({ defaultData, errors }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { roleAliases } = organizationStore;

  const rolesOptions = useMemo(() => (
    roleAliases?.map((roleAlias) => ({
      label: roleAlias.label,
      value: roleAlias.id,
    }))
  ), [roleAliases]);

  return (
    <div className="MyOrganizationEditForm">
      <FormFieldset>
        <FormGroup label={t('users:identifier')} error={errors?.identifier}>
          <FormControl
            name="identifier"
            autoComplete="off"
            defaultValue={defaultData?.identifier || ''}
            isInvalid={!!errors?.identifier}
          />
        </FormGroup>
        <FormGroup label={t('contacts:role')} error={errors?.occupations}>
          <FormSelect
            name="occupation"
            placeholder={t('common:please-choose')}
            selectOptions={rolesOptions}
            defaultValue={defaultData?.roleAlias || ''}
            isInvalid={!!errors?.occupations}
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default MyOrganizationEditForm;
