import type { ReactElement, ReactNode } from 'react';
import { Children, isValidElement } from 'react';
import { observer } from 'mobx-react';
import type { RightsAction, ManagerType } from 'types/models';
import { RightsCategory, RightsRole, RightsCategoryType } from 'types/models';
import userRightsStore from 'stores/UserRights';
import authStore from 'stores/Auth';
import getCategoryFromCategoryType from './utils';

type ControllerProps = {
  children: JSX.Element | JSX.Element[] | string,
  category: RightsCategory,
  action: RightsAction,
  displayedWhenNotAllowed?: boolean,
};

type ManagerControllerProps = {
  children: JSX.Element | JSX.Element[] | string,
  categoryType: RightsCategoryType,
  action: RightsAction,
  displayedWhenNotAllowed?: boolean,
  managers?: ManagerType[] | null,
};

export const checkIsAllowed = (action: RightsAction, category: RightsCategory) => {
  const { rights } = userRightsStore;
  const { user } = authStore;

  if (!user || !user.roles.length || category === RightsCategory.UNKNOWN_UNASSIGNED) {
    return false;
  }

  const userRole = user.roles.find((role) => role !== RightsRole.ROLE_USER);
  const actionRights = rights?.find((right) => right.categories.includes(category))?.rights;

  if (!userRole || !actionRights) {
    return false;
  }

  return actionRights[action]?.includes(userRole);
};

type UserRightsControllerType = (props: ControllerProps) => JSX.Element | null;
type UserRightsManagerControllerType = (props: ManagerControllerProps) => JSX.Element | null;

export const UserRightsManagerController: UserRightsManagerControllerType = observer((props) => {
  const { children, action, categoryType, managers, displayedWhenNotAllowed = false } = props;
  const { id: userIdentifier } = authStore.user ?? { id: null };
  const category = getCategoryFromCategoryType(managers, userIdentifier, categoryType);
  const isAllowed = checkIsAllowed(action, category);

  return (isAllowed || displayedWhenNotAllowed) ? <>{children}</> : null;
});

export const UserRightsController: UserRightsControllerType = observer((props) => {
  const {
    children,
    action,
    category,
    displayedWhenNotAllowed = false,
  } = props;

  const isAllowed = checkIsAllowed(action, category);

  return (isAllowed || displayedWhenNotAllowed) ? <>{children}</> : null;
});

type SwitchProps = {
  children: Array<ReactElement<
  ControllerProps & ManagerControllerProps,
  UserRightsControllerType & UserRightsManagerControllerType
  >>,
};

export const UserRightsSwitch = observer(({ children }: SwitchProps): JSX.Element | null => {
  let element: ReactNode = null;
  let matched = false;

  const { id: userIdentifier } = authStore.user ?? { id: null };

  Children.forEach(children, (child) => {
    if (!matched && isValidElement(child) && child.type === UserRightsController) {
      element = child;
      matched = child.props.displayedWhenNotAllowed || checkIsAllowed(
        child.props.action,
        child.props.category || getCategoryFromCategoryType(
          child.props.managers,
          userIdentifier,
          child.props.categoryType,
        ),
      );
    }
  });

  return matched ? element : null;
});
