import type Misc from 'types/misc';
import type { ActivityPhase } from './Phase';
import type { DebitSummaryStep } from './Debit';
import type { CreditIdentifiers } from './Credit';
import type { ClientIdentifiers, ClientAmounts } from './Client';
import type { ContactSummaryStep } from './Contact';
import type { ManagerType } from './UserRights';
import type { ScenarioGroup, ScenarioSummary } from './Scenario';
import { UserSummary } from './User';

export enum Channel {
  SMS = 'sms',
  EMAIL = 'email',
  LETTER = 'letter',
  LETTER_REGISTERED = 'registered',
}

export enum StepActionEntity {
  REMINDER = 'reminder',
  TASK = 'task',
  FREE_TASK = 'free_task',
  NOTE = 'note',
  NOTE_HISTORY = 'note_history',
  ACTIVITY = 'activity',
}

export enum HistoryStepType {
  REMINDER = 'reminder',
  TASK = 'task',
  NOTE = 'note_history',
  ALERT = 'alert',
  OTHER = 'other',
}

export enum HistoryStepStatus {
  COMPLETED = 'completed',
  ERROR = 'error',
  WARNING = 'warning',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
}

export enum StepActionPeriodFilter {
  LAST_15_DAYS = 'last15days',
  LAST_15_AND_45_DAYS = 'last15and45days',
  LAST_45_AND_75_DAYS = 'last45and75days',
  UNTIL_TODAY = 'untiltoday',
  UPCOMING = 'upcoming',
  NEXT_15_DAYS = 'next15days',
  NEXT_15_AND_45_DAYS = 'next15and45days',
  CUSTOMIZED = 'customized',
}

export enum StepActionTypeFilter {
  TASK_CANCELLED = 'task_cancelled',
  TASK_COMPLETED = 'task_completed',
  TASK_PENDING = 'task_pending',
  REMINDER_CANCELLED = 'reminder_cancelled',
  REMINDER_VALIDATED = 'reminder_validated',
  REMINDER_COMPLETED_AUTO = 'reminder_completed_auto',
  REMINDER_COMPLETED_MANUAL = 'reminder_completed_manual',
  REMINDER_ERROR = 'reminder_error',
  REMINDER_PENDING = 'reminder_pending',
  REMINDER_TO_VALIDATE = 'reminder_to_validate',
  REMINDER_AUTO = 'reminder_auto',
  REMINDER_TO_COMPLETE = 'reminder_to_complete',
  // filtres d'historique
  TASK_PROCESSED = 'task_processed',
  TASK_CANCELED = 'task_canceled',
  REMINDER_SENT = 'reminder_sent',
  REMINDER_SENT_ERROR = 'reminder_sent_error',
  REMINDER_CANCELED = 'reminder_canceled',
}

export enum StepActionElasticSearchType {
  REMINDER = 'reminder',
  TASK = 'task',
}

export type WebHookSMS = {
  at: string,
  type: 'sms.mt.status_update',
  apiName: string,
  roleName: string,
  status: Misc.ActionWebHookSMS,
  message: string,
};

export type WebHookLetter = {
  at: string,
  type: Misc.ActionWebHookLetter,
  apiName: string,
  roleName: string,
  file: string,
  message: string,
  postage_type: string,
  page_count: number,
  NPAI: boolean,
};

export type WebHookEmail = {
  at: string,
  type: Misc.ActionWebHookEmail,
  apiName: string,
  roleName: string,
  message: string,
};

export type HistoryStep = {
  id: number,
  translation?: string | null,
  loggedAt: string,
  author?: string,
  parameters?: HistoryParameters,
  badgeAlias: Misc.HistoryStepEntity | null,
  channel: Channel | null,
  name: string | null,
  description: string | null,
  content: string | null,
  subject: string | null,
  at: Misc.DateNameOffset | null,
  doneAt: string | null,
  isActive: boolean | null,
  isInitialActive: boolean | null,
  isEditable: boolean | null,
  phase: ActivityPhase | null,
  customer: ClientIdentifiers & ClientAmounts,
  scenario: ScenarioSummary | null,
  debit: DebitSummaryStep | null,
  payment: CreditIdentifiers | null,
  validation: Misc.DateName | null,
  remove: Misc.DateName | null,
  mute: Misc.DateName | null,
  unmute: Misc.DateName | null,
  contacts: ContactSummaryStep[] | null,
  missingContactRoles: string[] | null,
  managers: ManagerType[] | null,
  errorMessage: string | null,
};

export type HistoryActivityParameters = {
  identifier: string,
  old_status: string,
  new_status: string,
};

export type HistoryActivity = {
  id: number,
  author?: string | null,
  user?: UserSummary | null,
  client?: Misc.IdRef | null,
  debit?: Misc.IdRef | null,
  key?: string | null,
  content: string | null,
  translation: string | null,
  parameters?: HistoryActivityParameters,
  loggedAt: string,
  timestamp: string | null,
  entity: StepActionEntity,
  type: HistoryActivityType,
  // TODO Changer avec les differents type possible pour options
  options: any,
  tag: HistoryActivityTag,
};

export enum HistoryActivityType {
  DEBIT = 'debit',
  CLIENT = 'client',
  CONTACT = 'contact',
  NOTE_HISTORY = 'note_history',
  NOTE = 'note',
  CREDIT = 'credit',
}

export enum HistoryActivityTag {
  CLIENT_UNMUTE_ALERT = 'ClientUnMuteAlert',
  CLIENT_MUTE_ALERT = 'ClientMuteAlert',
  UNKNOWN = 'Unknown',
  CONTACT_UPDATE_ALERT = 'ContactUpdateAlert',
  NOTE_CREATE_ALERT = 'NoteCreateAlert',
  NOTE_DELETE_ALERT = 'NoteDeleteAlert',
  SWITCH_PLAN_ALERT = 'SwitchPlanAlert',
  SWITCH_REFERENCE_DATE_ALERT = 'SwitchReferenceDateAlert',
}

type HistoryParameters = {
  identifier: string
};

export type HistoryStepSMS = HistoryStep & {
  channel: Channel.SMS,
  history?: WebHookSMS[] | null,
};

export type HistoryStepLetter = HistoryStep & {
  channel: Channel.LETTER | Channel.LETTER_REGISTERED,
  history?: WebHookLetter[] | null,
};

export type HistoryStepEmail = HistoryStep & {
  channel: Channel.EMAIL | null,
  history?: WebHookEmail[] | null,
};

export type HistoryStepWebHook = (HistoryStepSMS | HistoryStepLetter | HistoryStepEmail);
export type ActionWebHookEvents = Misc.ActionWebHookEmail | Misc.ActionWebHookLetter | Misc.ActionWebHookSMS;

export type HistoryStepSummary = {
  id: HistoryStep['id'],
  badgeAlias: HistoryStep['badgeAlias'],
  channel: HistoryStep['channel'],
  name: HistoryStep['name'],
  isActive: HistoryStep['isActive'],
  client: ClientIdentifiers,
  debit: HistoryStep['debit'],
};

export type HistoryStepPreviewContent = {
  subject: string | null,
  content: string | null,
  presignUrl: string | null,
  errors: {
    exit_description: string,
  },
  contact: {
    identifier?: string,
    contactRole: string,
    contactRoleLabel: string,
    fullName: string,
    senderEmail: string,
  }[],
};

export type HistoryStepConvertContent = {
  name: string | null,
  description: string | null,
  subject?: string | null,
  content?: string | null
};

export type HistoryStepActionSMS = StepAction & {
  channel: Channel.SMS,
  history?: WebHookSMS[] | null,
};

export type HistoryStepActionLetter = StepAction & {
  channel: Channel.LETTER | Channel.LETTER_REGISTERED,
  history?: WebHookLetter[] | null,
};

export type HistoryStepActionEmail = StepAction & {
  channel: Channel.EMAIL | null,
  history?: WebHookEmail[] | null,
};

export type HistoryStepActionbWebHook = (HistoryStepActionSMS | HistoryStepActionLetter | HistoryStepActionEmail);

export type StepAction = {
  id: number,
  day: number | null,
  channel: Channel | null,
  name: string | null,
  description: string | null,
  content: string | null,
  subject: string | null,
  doneAt: string | null,
  isActive: boolean | null,
  isInitialActive: boolean | null,
  isCreatedByDunforce: boolean | null,
  isImmediatelySentByUser: boolean | null,
  status: HistoryStepStatus,
  entity: StepActionEntity,
  at: Misc.DateNameOffset,
  code: string | null,
  badgeAlias: Misc.HistoryStepEntity | null,
  debit: DebitSummaryStep | null,
  phase: ActivityPhase | null,
  isEditable: boolean | null,
  payment: CreditIdentifiers | null,
  sent: Misc.DateName | null,
  validation: Misc.DateName | null,
  cancel: Misc.DateName | null,
  delay: Misc.DateName | null,
  modify: Misc.DateName | null,
  mute: Misc.DateName | null,
  unmute: Misc.DateName | null,
  contacts: ContactSummaryStep[] | null,
  missingContactRoles: string[] | null,
  incompleteContactRoles?: string[] | [],
  errorMessage: string | null,
  client: ClientIdentifiers & ClientAmounts,
  scenario: ScenarioSummary | null,
  isStatic: boolean,
  withValidation: boolean,
  author?: Author,
  loggedAt: string,
  scenarioGroup?: ScenarioGroup,
};

export type ClientHistory = StepAction | HistoryActivity;

export const isStepAction = (obj: ClientHistory): obj is StepAction => {
  const item = (obj as StepAction);
  return [StepActionEntity.REMINDER, StepActionEntity.TASK].includes(item.entity);
};

export const isHistoryActivity = (obj: ClientHistory): obj is HistoryActivity => {
  const item = (obj as HistoryActivity);
  return [StepActionEntity.ACTIVITY, StepActionEntity.NOTE_HISTORY, StepActionEntity.NOTE].includes(item.entity);
};

type Author = {
  id: number,
  identifier: string,
  fullName: string,
};
