import queryString from 'query-string';
import { Collection } from 'types/api';
import type Misc from 'types/misc';
import type { Import, ImportLine, ImportLineStatus, Organization } from 'types/models';
import Request from 'utils/request';
import requester from 'utils/requester';

export type FetchAllImportsReports = {
  id: Organization['id'] | undefined,
  params?: FetchAllImportsReportsParam | undefined
};

export type FetchAllImportsReportsParam = {
  pageSize: number,
  date: string | null,
};

export type FetchAllImportsHistory = {
  page?: number | undefined,
  pageSize?: number,
  filtering?: Misc.Filter[],
};

export type FetchAllLinesFromFileParams = {
  fileId: number,
  fetchOptions: Misc.PaginatedFetchArgs<ImportLine>,
  status?: ImportLineStatus,
};

type PutResponseData = { success: boolean, code: number, data: number };

/**
 * Récupère une liste de rapports des imports.
 *
 * @param id ID d'organisation.
 * @returns Liste de rapports.
 */
const allImportsReports = async (
  { id, params }: FetchAllImportsReports,
): Promise<Import[]> => {
  if (!id) {
    throw new Error('FetchAllImportsReports: Missing organization.');
  }

  const { pageSize, date } = params ?? {
    pageSize: 50,
    date: null,
  };

  const queryData = new URLSearchParams();

  if (date) {
    queryData.append('from', date);
    queryData.append('to', date);
  }

  if (pageSize) {
    queryData.append('itemsPerPage', pageSize.toString());
  }

  const { data } = await requester.get<Collection<Import>>(
    `organizations/${id}/reports?${queryData.toString()}`,
  );
  return data['hydra:member'];
};

/**
 * Récupère une liste de l'historique des imports.
 *
 * @param id ID d'organisation.
 * @returns Liste des imports.
 */
const allImportsHistory = async (
  id: Organization['id'] | undefined,
  params: FetchAllImportsHistory | undefined,
): Promise<Collection<Import>> => {
  if (!id) {
    throw new Error('FetchAllImportsHistory: Missing organization.');
  }

  const { page = 0, filtering = [], pageSize } = params ?? { page: 0, filtering: [] };

  const queryData = new URLSearchParams();
  queryData.append('page', (page + 1 ?? 1).toString());
  queryData.append('itemsPerPage', (pageSize ?? 25).toString());

  if (filtering) {
    filtering.forEach((filter) =>
      filter.value && queryData.append(filter.name, filter.value.toString()),
    );
  }

  const { data } = await requester.get<Collection<Import>>(
    `organizations/${id}/imports/history?${queryData.toString()}`,
  );
  return data;
};

const fetchAllLinesFromFile = async ({ fileId, fetchOptions, status }: FetchAllLinesFromFileParams) => {
  const { pageIndex, pageSize } = fetchOptions;
  const params = {
    recordsPerPage: pageSize,
    page: pageIndex,
    status: status ?? undefined,
  };

  const query = queryString.stringify(params);

  const result = await Request.get<Misc.Listing<ImportLine>>(
    `import/lines/${fileId}?${query}`,
  );
  return result;
};

const cancelImport = async (importLogId: number) => {
  const result = await Request.put<undefined, PutResponseData>(
    `import/manage/import-to-error/${importLogId}`,
  );
  return result;
};

/**
 * Récupère l'URL de la ressource d'API pour l'import.
 *
 * @returns URL du POST.
 */
const allImportsData = 'job_executions/import';

export { fetchAllLinesFromFile, cancelImport };
export default { allImportsReports, allImportsHistory, allImportsData };
