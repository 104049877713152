import requester from 'utils/requester';
import type { Collection } from 'types/api';
import type { BusinessUnit, Organization } from 'types/models';

export type FetchAllParams = {
  id: Organization['id'] | undefined,
  page?: number,
};

const all = async (
  { id, page }: FetchAllParams,
) => {
  if (!id) {
    throw new Error('FetchAllBusinessUnits: Missing organization.');
  }

  const queryData = new URLSearchParams();

  if (page) {
    queryData.append('page', (page ?? 1).toString());
  }

  const { data } = await requester.get<Collection<BusinessUnit>>(
    `/organizations/${id}/business_units?${queryData.toString()}`,
  );
  return data['hydra:member'];
};

/**
 * URL de la ressource d'API pour la création d'une Business Unit (POST).
 */
const createUrl = 'business_units';

/**
 * Récupère l'URL de la ressource d'API pour modifier la business unit.
 *
 * @param id L'ID de la business unit
 * @returns URL du PUT.
 */
const updateUrl = (id: BusinessUnit['id']) => `business_units/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour mapper une association de business unit.
 *
 * @param id L'ID de la business unit
 * @returns URL de l'association.
 */
const resourceUrl = (id: BusinessUnit['id']) => `/api/business_units/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour supprimer le business unit.
 *
 * @param id L'ID du business unit
 * @returns URL du DELETE.
 */
const deleteUrl = (id: BusinessUnit['id']) => `business_units/${id}`;

export default { all, createUrl, updateUrl, resourceUrl, deleteUrl };
