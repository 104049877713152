import { useEffect, useRef } from 'react';

type CallbackFunction = () => (() => void) | void;

type DebouncedData = {
  firstTime: boolean,
  clearFunc: null | (() => void) | void,
};

export function useDebouncedEffect(callback: CallbackFunction, delay: number, deps: any[] = []) {
  const data = useRef<DebouncedData>({ firstTime: true, clearFunc: null });

  useEffect(() => {
    const { firstTime, clearFunc } = data.current;

    if (firstTime) {
      data.current.firstTime = false;
      return undefined;
    }

    const handler = setTimeout(() => {
      if (clearFunc) {
        clearFunc();
      }
      data.current.clearFunc = callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...deps]);
}

export default useDebouncedEffect;
