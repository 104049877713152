import type { TFunction } from 'i18next';
import DataTableFilterItem from 'components/DataTable/Filters/Item';
import type Misc from 'types/misc';
import getI18nActionSelectStatus from 'utils/getI18nActionSelectStatus';
import { StepActionPeriodFilter, StepActionTypeFilter } from 'types/models';
import ActionsDoneFilter, { ACTIONS_FILTERS } from './ActionsFilter';

const showAllOrFilters = (
  filter: Misc.Filter,
  badgeAllValues: string[],
  filterValues: string[],
  label: string,
  allSelectedText: string,
  key: string,
  t: TFunction,
): Misc.CurrentFilterItem | null => {
  const badgeValues = [...filterValues.filter(
    (filterValue) => badgeAllValues.includes(filterValue),
  )];

  const badgeLabel = Array.isArray(filter.value)
    ? filter.value?.map((value) => t(getI18nActionSelectStatus(value))).join(', ')
    : t(getI18nActionSelectStatus(filter.value || StepActionTypeFilter.REMINDER_TO_VALIDATE));

  if (badgeAllValues.every((value) => badgeValues.includes(value))) {
    return {
      value: allSelectedText,
      name: filter.name,
      getRemovedValue: () => [...filterValues.filter(
        (filterValue) => !badgeAllValues.includes(filterValue),
      )],
      key: `${filter.name}-${key}`,
    };
  }

  if (badgeAllValues.some((value) => badgeValues.includes(value))) {
    return {
      label,
      value: badgeLabel,
      name: filter.name,
      getRemovedValue: () => [...filterValues.filter(
        (filterValue) => !badgeAllValues.includes(filterValue),
      )],
      key: `${filter.name}-${key}`,
    };
  }

  return null;
};

const actionsFiltersSettings: Misc.FiltersSettings = {
  sideKeys: [
    'channel',
    'scenario',
    'scenario_group',
    'client_total_debt',
    'client_due_debt',
    'attributes',
  ],
  renderFilters: (filtersRenderer) => {
    const { filters, getFilterValue } = filtersRenderer;

    const showDate = getFilterValue('period') === StepActionPeriodFilter.CUSTOMIZED;
    const typeFilters = filters.find((filter) => filter.key === 'type');

    if (!typeFilters) {
      return <></>;
    }

    const filtersRendered = [
      (
        <ActionsDoneFilter
          key={`${typeFilters.key}-reminder`}
          filter={typeFilters}
          {...filtersRenderer}
        />
      ),
      ...filters.filter(({ key }) => (
        ((key !== 'periodA' && key !== 'periodB') || showDate) && key !== 'type'
      )).map((filter) => (
        <DataTableFilterItem
          key={filter.key}
          filter={filter}
          {...filtersRenderer}
        />
      )),
    ];

    return <>{filtersRendered}</>;
  },

  getCurrentFilters: (getFilterLabel, filtersList, activeFilters, t) => {
    const badges = activeFilters
      .filter(({ name }) => !['type'].includes(name))
      .map(({ name, value, id, operator }): Misc.CurrentFilterItem => ({
        id,
        value,
        name,
        operator,
        key: name,
        label: `${getFilterLabel(name)}`,
        getRemovedValue: () => null,
      }));

    const typeFilter = activeFilters.find(({ name }) => name === 'type');

    if (!typeFilter) {
      return badges;
    }

    const typeValues = Array.isArray(typeFilter.value) ? typeFilter.value : [typeFilter.value ?? ''];

    const typeBadge = showAllOrFilters(
      typeFilter,
      ACTIONS_FILTERS,
      typeValues,
      t('actions:type'),
      t('actions:actions-all'),
      'type',
      t,
    );

    if (typeBadge) {
      badges.push(typeBadge);
    }
    return badges;
  },
};

export default actionsFiltersSettings;
