import type { HistoryStepActionbWebHook } from 'types/models';
import HistoryStepWebHookStatusItem from './Item';

type Props = {
  data: HistoryStepActionbWebHook,
};

const HistoryStepWebHookStatus = ({ data: { channel, history } }: Props): JSX.Element | null => {
  if (!channel) {
    return null;
  }

  return (
    <div className="HistoryStepWebHookStatus">
      {history && history.map((historyEvent: any) => (
        <HistoryStepWebHookStatusItem
          key={`${historyEvent.type}${historyEvent.at}`}
          event={historyEvent}
          channel={channel}
        />
      ))}
    </div>
  );
};

export default HistoryStepWebHookStatus;
