import { Analytics } from 'types/models';

const getI18nAnalytics = (key: Analytics): string => {
  const i18nAnalytics = {
    [Analytics.SMS]: 'analysis:key.sms',
    [Analytics.EMAILS]: 'analysis:key.emails',
    [Analytics.LETTERS]: 'analysis:key.letters',
    [Analytics.REGISTERED_LETTERS]: 'analysis:key.registered_letters',
    [Analytics.AUTOMATIC_REMINDERS]: 'analysis:key.automatic_reminders',
    [Analytics.VALIDATION_REMINDERS]: 'analysis:key.validation_reminders',
    [Analytics.PLANNED_TASKS]: 'analysis:key.planned_tasks',
    [Analytics.VALIDATED_TASKS]: 'analysis:key.validated_tasks',
    [Analytics.CANCELLED_TASKS]: 'analysis:key.cancelled_tasks',
    [Analytics.PENDING_TASKS]: 'analysis:key.pending_tasks',
    [Analytics.AVERAGE_DEBT]: 'analysis:key.average_debt',
    [Analytics.BT_1_AD3]: 'analysis:key.bt_1_ad3',
    [Analytics.BT_AD3_AD2]: 'analysis:key.bt_ad3_ad2',
    [Analytics.BT_AD2_AD]: 'analysis:key.bt_ad2_ad',
    [Analytics.BT_AD_ADX2]: 'analysis:key.bt_ad_adx2',
    [Analytics.SUP_ADX2]: 'analysis:key.sup_adx2',
    [Analytics.NON_ECHUE_OVER_30D]: 'analysis:key.non_echue_over_30d',
    [Analytics.NON_ECHUE_UNDER_30D]: 'analysis:key.non_echue_under_30d',
    [Analytics.ECHUE_UNDER_30D]: 'analysis:key.echue_under_30d',
    [Analytics.ECHUE_30_60D]: 'analysis:key.echue_30_60d',
    [Analytics.ECHUE_60_90D]: 'analysis:key.echue_60_90d',
    [Analytics.ECHUE_90_120D]: 'analysis:key.echue_90_120d',
    [Analytics.ECHUE_OVER_120D]: 'analysis:key.echue_over_120d',
    [Analytics.RECOVERY_RATE]: 'analysis:finance.recoveryRate.title',
    [Analytics.MONTANT]: 'analysis:key.montant',
    [Analytics.CLIENT]: 'analysis:key.client',
    [Analytics.DEBIT]: 'analysis:key.debit',
    [Analytics.DAYS]: 'analysis:key.days',
  };

  return i18nAnalytics[key];
};

export default getI18nAnalytics;
