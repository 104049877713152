import './index.scss';
import React from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import type { ImportItemType } from 'types/models';

interface Props {
  value: ImportItemType,
  title: string,
  breakLine?: boolean,
  onSelectType: (choice: ImportItemType) => () => void,
  active: boolean,
}

const ImportsFileWizardStepChoiceTypeButton: React.FC<Props> = (props) => {
  const {
    value,
    title,
    breakLine,
    onSelectType,
    active,
  } = props;

  const classNamesButtonActive = classnames('ImportsFileWizardStepChoiceTypeButton__item', {
    'ImportsFileWizardStepChoiceTypeButton__item--active': active,
  });

  return (
    <>
      <Button
        onClick={onSelectType(value)}
        variant="link"
        className={classNamesButtonActive}
      >
        {title}
      </Button>
      {breakLine && (
        <div className="ImportsFileWizardStepChoiceTypeButton__break" />
      )}
    </>
  );
};

export default ImportsFileWizardStepChoiceTypeButton;
