import type { BusinessUnit } from 'types/models';

export enum CategoryActions {
  ADD_CATEGORY = 'addCategory',
  DELETE_CATEGORY = 'deleteCategory',
  SET_VALUES = 'setValues',
}

type ActionAdd = {
  type: CategoryActions.ADD_CATEGORY,
  category: BusinessUnit,
};

type ActionDelete = {
  type: CategoryActions.DELETE_CATEGORY,
  categoryId: BusinessUnit['id']
};

type ActionSetValues = {
  type: CategoryActions.SET_VALUES,
  categoryId: BusinessUnit['id']
  values: BusinessUnit[]
};

type CategoryReducerAction = ActionAdd | ActionDelete | ActionSetValues;

const categoryReducer = (state: BusinessUnit[], action: CategoryReducerAction): BusinessUnit[] => {
  const { type } = action;
  switch (type) {
    case CategoryActions.ADD_CATEGORY:
      return [...state, { ...action.category }];
    case CategoryActions.DELETE_CATEGORY:
      return state.filter(({ id }) => id !== action.categoryId);
    case CategoryActions.SET_VALUES:
      return state.map((category) => (
        category.id === action.categoryId
          ? { ...category, values: action.values }
          : category
      ));
    default:
      throw new Error(`Unknown action type ${type} in categoryReducer.`);
  }
};

export default categoryReducer;
