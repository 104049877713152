import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { UserOccupation } from 'types/models';
import type { ValidationErrors } from 'types/errors';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import apiOccupations from 'api/occupations';
import apiRoleAliases from 'api/roleAliases';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import MyOrganizationEditForm from './MyOrganizationEditForm';

export type UserCurrentOrganization = {
  identifier: string,
  roleAlias: string | null,
};

type Props = {
  onClose(): void,
  onShowToast(message: string, type: ToastStatus): void,
};

const ModalEditMyOrganization = ({ onClose, onShowToast }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currentId, currentOrganization } = organizationStore;
  const { user } = authStore;
  const { occupations } = user!;

  const occupationId = useMemo(() => (
    user?.occupations.find((userOccupation) => userOccupation.organization.id === currentId)?.roleAlias?.id
  ), [currentId, user?.occupations]);

  const userIdentifier = useMemo(() => (
    user?.occupations.find((userOccupation) => userOccupation.organization.id === currentId)?.identifier ?? ''
  ), [currentId, user?.occupations]);

  const isMountedRef = useIsMountedRef();
  const initialData = useRef<UserCurrentOrganization | null>(null);

  const mapFormData = useCallback(
    (rawData: ModalFormData): UserCurrentOrganization => ({
      identifier: rawData?.identifier as string,
      roleAlias: rawData?.occupation ? apiRoleAliases.resourceUrl(rawData.occupation as string) : null,
    }),
    [],
  );

  const formToPass = useMemo(() => ({
    identifier: userIdentifier,
    roleAlias: occupationId,
  }), [userIdentifier, occupationId]);

  const currentOccupation = useMemo(() => (
    occupations?.find((occupation) => occupation.organization.id === currentId)), [currentId, occupations],
  );

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(!!formData && !equal(initialData.current, mapFormData(formData)));
    },
    [initialData, mapFormData],
  );

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const {
    put,
    isLoading,
    error,
  } = useApiRequest();

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization || !user || !currentOccupation) {
        return;
      }

      const result = await put<UserOccupation>(apiOccupations.updateUrl(currentOccupation.id), mapFormData(formData));

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (result?.id) {
        await authStore.fetchUser();
        onShowToast(t('users:toast.success.settings-update'), 'success');
        onClose();
      }
    },
    [currentOrganization, user, currentOccupation, put, mapFormData, isMountedRef, onShowToast, t, onClose],
  );

  return (
    <ModalForm
      title={t('users:in-my-organization')}
      className="ModalEditMyOrganization"
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <MyOrganizationEditForm
        defaultData={formToPass}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalEditMyOrganization);
