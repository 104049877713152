import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';
import { ReactComponent as ScienceBro } from './assets/Science-bro.svg';

const ReservedAccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="ReservedAccess">
      <div className="ReservedAccess__back">
        <GoBackButton />
      </div>
      <div className="ReservedAccess__content">
        <h1 className="ReservedAccess__content__title">{t('common:page-reserved-access')}</h1>
        <p className="ReservedAccess__content__text">
          {t('common:page-is-access-reserved')}
        </p>
        <ScienceBro className="ReservedAccess__content__image" />
      </div>
    </div>
  );
};

export default ReservedAccess;
