import './index.scss';
import { useCallback, useEffect, useMemo, useContext } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { StepActionPeriodFilter } from 'types/models';
import type { StepAction } from 'types/models';
import apiHistorySteps from 'api/historySteps';
import type { FetchAllForCustomerParams } from 'api/historySteps';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch2';
import Collapse from 'components/Collapse';
import BlockSection from 'components/BlockSection';
import ErrorMessage from 'components/ErrorMessage';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import HistoryStep from 'components/HistoryStep';
import { Collection } from 'types/api';
import customerContext from '../../../customerContext';

const CustomerActions = (): JSX.Element => {
  const { data, reloadTick, onActionDone } = useContext(customerContext);
  const customerId = data?.id;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    data: futureSteps,
    refetch: refetchFuture,
    isFetching: isFetchingFuture,
    error: errorFuture,
  } = useFetch<FetchAllForCustomerParams, Collection<StepAction>>(
    {
      cacheKey: 'clientFutureSteps',
      id: customerId,
    },
    apiHistorySteps.allFutureStepsForCustomer,
    { enabled: !!customerId },
  );

  const {
    data: pendingSteps,
    refetch: refetchPending,
    isFetching: isFetchingPending,
    error: errorPending,
  } = useFetch<FetchAllForCustomerParams, Collection<StepAction>>(
    {
      cacheKey: 'clientWaitingSteps',
      id: customerId,
    },
    apiHistorySteps.allPastStepsForCustomer,
    { enabled: !!customerId },
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  useEffect(() => {
    refetchFuture();
    refetchPending();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, currentOrganization, reloadTick]);

  const handleActionDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
    refetchFuture();
    refetchPending();
  }, [onActionDone, refetchFuture, refetchPending]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const linkToPendingActions = useMemo(() => (
    queryString.stringify({
      client: customerId,
      period: StepActionPeriodFilter.UNTIL_TODAY,
    }, { arrayFormat: 'comma' })
  ), [customerId]);

  const linkToFuturesActions = useMemo(() => (
    queryString.stringify({
      client: customerId,
      period: StepActionPeriodFilter.UPCOMING,
    }, { arrayFormat: 'comma' })
  ), [customerId]);

  return (
    <div className="CustomerActions">
      <Collapse
        isDefaultOpen={pendingSteps ? pendingSteps['hydra:totalItems'] > 0 : false}
        updateOpenWhenDefaultIsUpdated
        header={(
          <BlockSection
            title={t('clients:pending-actions')}
            count={pendingSteps ? pendingSteps['hydra:totalItems'] : 0}
            isLoading={isFetchingPending}
          >
            <Link
              to={`/actions-future?${linkToPendingActions}`}
              className="CustomerActions__link"
            >
              {t('common:see-all-actions')}
            </Link>
          </BlockSection>
        )}
      >
        {errorPending && <ErrorMessage error={errorPending} />}
        <ul className="CustomerActions__list">
          {/* FIXME: limiter le résultat de la requête API */}
          {pendingSteps && pendingSteps['hydra:member'].slice(0, 5).map((step) => (
            <HistoryStep
              data={step}
              key={step.id}
              customerId={customerId}
              showRelativeDateRef
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          ))}
          {!isFetchingPending && pendingSteps && pendingSteps['hydra:totalItems'] === 0 && (
            <li className="CustomerActions__no-data">
              {t('debits:no-data-actions')}
            </li>
          )}
        </ul>
      </Collapse>
      <Collapse
        isDefaultOpen={false}
        updateOpenWhenDefaultIsUpdated
        header={(
          <BlockSection
            title={t('clients:future-actions')}
            count={futureSteps ? futureSteps['hydra:totalItems'] : 0}
            isLoading={isFetchingFuture}
          >
            <Link to={`/actions-future?${linkToFuturesActions}`} className="CustomerActions__link">
              {t('common:see-all-actions')}
            </Link>
          </BlockSection>
        )}
      >
        {errorFuture && <ErrorMessage error={errorFuture} />}
        <ul className="CustomerActions__list">
          {/* FIXME: limiter le résultat de la requête API */}
          {futureSteps && futureSteps['hydra:member'].slice(0, 5).map((step) => (
            <HistoryStep
              data={step}
              key={step.id}
              customerId={customerId}
              showRelativeDateRef
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          ))}
          {!isFetchingFuture && futureSteps && futureSteps['hydra:totalItems'] === 0 && (
            <li className="CustomerActions__no-data">
              {t('debits:no-data-actions')}
            </li>
          )}
        </ul>
      </Collapse>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(CustomerActions);
