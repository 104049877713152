import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { parse } from 'yaml';
import FileDownload from 'js-file-download';
import { ACCEPT_FILES_TYPE } from 'config';
import organizationStore from 'stores/Organization';
import apiAttributes from 'api/attributes';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import ModalForm from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import Dropzone from 'components/Dropzone';
import Button from 'components/Button';
import FormCheck from 'components/FormCheck';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import Loading from 'components/Loading';
import useTooltip from 'components/Tooltip/useTooltip';
import Icon from 'components/Icon';

type Props = {
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const AttributesModalEditYAML = (props: Props): JSX.Element | null => {
  const { onClose, onActionDone, onActionError } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const [replace, setReplace] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [attrIsValid, setAttrIsValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>();

  const { post, isLoading } = useApiRequest();

  const tooltipTarget = useTooltip<HTMLSpanElement>(
    <div className="AttributesModalEditYAML__tooltip">
      {t('attributes:yaml-warning')}
    </div>,
  );

  const handleDrop = useCallback((files: File[]) => {
    setFile(files[0]);
    setAttrIsValid(false);
    setErrors([]);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!currentOrganization || !file) {
      return;
    }
    setErrors([]);
    setAttrIsValid(false);

    if (!replace) {
      // regarde si le yaml est valide seulement
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (e.target && e.target.result) {
          try {
            parse(e.target.result.toString());
            setAttrIsValid(true);
            setErrors([]);
          } catch (exception) {
            setErrors([exception as string]);
          }
        }
      };
      reader.readAsText(file);
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('organization', currentOrganization.reference);
    const result = await post(apiAttributes.importUrl, formData);

    if (!isMountedRef.current) {
      return;
    }
    if (!result || result.errors) {
      onActionError(t('attributes:toast.error.yaml-attributes'));
      onClose();
      return;
    }

    onActionDone(t('attributes:toast.success.yaml-attributes'));
    onClose();
  }, [
    currentOrganization,
    file,
    isMountedRef,
    onActionDone,
    onActionError,
    onClose,
    post,
    replace,
    t,
  ]);

  const onDownloadTemplateClick = useCallback(async () => {
    if (!currentOrganization) {
      return;
    }
    const { data } = await apiAttributes.getTemplate(currentOrganization.reference);
    FileDownload(data, 'attributes.yaml');
  }, [currentOrganization]);

  if (!currentOrganization) {
    return null;
  }

  return (
    <ModalForm
      isOpened
      className="AttributeModalEditYAML"
      onSave={handleSubmit}
      onCancel={onClose}
      title={t('attributes:attributes-via-yaml')}
    >
      <p className="AttributesModalEditYAML__description">{t('attributes:description-yaml')}</p>
      <Button className="AttributesModalEditYAML__download" onClick={onDownloadTemplateClick}>
        {t('attributes:download-current-file')}
      </Button>
      <p className="AttributesModalEditYAML__title">{t('attributes:new-yaml-file')}</p>
      <Dropzone
        onDrop={handleDrop}
        accept={ACCEPT_FILES_TYPE.YAML}
        maxFiles={1}
        withInstructions={false}
        filename={file?.name}
      />
      <ErrorValidationMessage error={errors ? errors[0] : null} />
      {isLoading && <Loading hasNoText />}
      {attrIsValid && (<p className="AttributesModalEditYAML__valid">{t('attributes:upload-is-valid', { name: file?.name })}</p>)}
      <FormFieldset>
        <FormCheck onChange={setReplace} checked={replace} />
        <p className="AttributesModalEditYAML__check">{t('attributes:replace-current-attributes')}</p>
        <div ref={tooltipTarget}>
          <Icon name="help-circle" />
        </div>
      </FormFieldset>
    </ModalForm>
  );
};

export default observer(AttributesModalEditYAML);
