import { runInAction, makeAutoObservable } from 'mobx';
import type { Currency } from 'types/models';
import ResponseError from 'utils/errors';
import apiCurrencies from 'api/currencies';

class CurrenciesStore {
  currencies: Currency[] | null = null;

  isFetched: boolean = false;

  isBusy: boolean = false;

  error: ResponseError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get currenciesOptions() {
    return this.currencies?.map((currencyData) => ({
      label: currencyData.code,
      value: currencyData.id,
    }));
  }

  fetchAll = async () => {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.error = null;

    try {
      const data = await apiCurrencies.all();
      data.reverse();

      runInAction(() => {
        this.currencies = data;
        this.isFetched = true;
      });
    } catch (error) {
      const { code, message, details } = error as ResponseError;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
      });
    }
  };
}

export default new CurrenciesStore();
export { CurrenciesStore };
