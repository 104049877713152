import { ImportItemStep } from 'types/models';

const getI18nReportStep = (status: ImportItemStep): string => {
  const i18nStatus = {
    [ImportItemStep.VALIDATION]: 'reports:step.validation',
    [ImportItemStep.PARSE]: 'reports:step.parse',
    [ImportItemStep.PROCESS]: 'reports:step.process',
  };

  return i18nStatus[status];
};

export default getI18nReportStep;
