import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import Config from 'config';
import type { ValidationErrors } from 'types/errors';
import type { Client, Contact as ContactType, CurrencyCode, DebitPost } from 'types/models';
import currenciesStore from 'stores/Currencies';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch2';
import formatIntlDate from 'utils/formatIntlDate';
import apiContacts from 'api/contacts';
import apiClients from 'api/clients';
import type { FetchOneParams } from 'api/clients';
import type { FetchAllForCustomerParams } from 'api/contacts';
import formatIntlNumber from 'utils/formatIntlNumber';
import Button from 'components/Button';
import PDFViewer from 'components/PDFViewer';
import ContactEditModal from 'components/ContactEditModal';
import Loading from 'components/Loading';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import ListElement from 'components/ListElement';
import Contact from './Contact';

type Props = {
  data: DebitPost | null,
  customerId: number,
  receiptName: string | null,
  receipt: string | null,
  onError(errors: ValidationErrors): void,
};

const DebitPreview = (props: Props): JSX.Element => {
  const { data, customerId, receiptName, receipt } = props;
  const { attributes } = organizationStore;
  const { type } = organizationStore.currentOrganization!;
  const { currenciesOptions } = currenciesStore;
  const { t, ct } = useContextualTranslation(type);

  const {
    showToast,
    isToastShowed,
    toastStatus,
    toastText,
    hideToast,
  } = useToast();

  const { data: customer } = useFetch<FetchOneParams, Client>(
    { cacheKey: 'client', id: customerId },
    apiClients.one,
  );

  const {
    data: contacts,
    refetch: refetchContacts,
    status: contactsStatus,
    isFetching: isFetchingContacts,
  } = useFetch<FetchAllForCustomerParams, ContactType[]>(
    {
      cacheKey: 'contacts',
      id: customerId,
      params: {
        pageSize: Config.DEFAULT_PAGE_SIZE,
      },
    },
    apiContacts.allForCustomer,
    { enabled: !!customerId },
  );

  const [showCreateModal, hideCreateModal] = useModal(() => (
    <ContactEditModal
      editType="new"
      defaultCustomerId={customerId}
      customerId={customerId}
      onClose={hideCreateModal}
      onDone={(message) => { showToast(message, 'success'); refetchContacts(); }}
    />
  ), [refetchContacts, showToast, customerId]);

  const labelledEavs = useMemo(() => {
    if (data?.eavs) {
      return Object.entries(data.eavs).map(([key, value]) => ({
        label: attributes?.debit.find(
          ({ identifier }) => identifier === key,
        )?.label || key,
        value: value as any,
        identifier: key,
      }));
    }
    return [];
  }, [attributes?.debit, data?.eavs]);

  const currency = useMemo((): CurrencyCode | null => {
    if (!data) {
      return null;
    }
    return currenciesOptions?.find((option) =>
      option.value === parseInt(data.currency.replace(/^\D+/g, '')),
    )?.label ?? null;
  }, [data, currenciesOptions]);

  return (
    <dl className="DebitPreview">
      <ListElement label={ct('common:client')}>
        <span className="DebitPreview__client">
          {customer?.denomination}
          {customer && customer.denomination !== customer.identifier && (
            <span className="DebitPreview__client__reference">
              ({customer.identifier})
            </span>
          )}
        </span>
      </ListElement>
      <ListElement label={t('debits:ref')}>{data?.identifier}</ListElement>
      <ListElement label={t('common:amount')}>
        {formatIntlNumber(data ? Number(data.amount) : null, currency)}
      </ListElement>
      <ListElement label={t('debits:issued-on')}>
        {formatIntlDate(data?.issueDate, 'dateLong')}
      </ListElement>
      <ListElement label={t('debits:subject')}>{data?.label}</ListElement>
      <ListElement label={t('common:due-at')}>
        {formatIntlDate(data?.dueDate, 'dateLong')}
      </ListElement>
      <hr className="DebitPreview__divider" />
      <ListElement
        label={t('common:contacts')}
        fullWidth
        action={<Button variant="link" onClick={showCreateModal}>{t('common:create')}</Button>}
      >
        {contactsStatus === 'loading' && isFetchingContacts && <Loading hasNoText />}
        {contacts?.map((contact) => (
          <Contact
            key={contact.id}
            contact={contact}
            onActionDone={(message) => { refetchContacts(); showToast(message, 'success'); }}
            onActionError={(message) => { showToast(message, 'error'); }}
          />
        ))}
        {contactsStatus !== 'loading' && !isFetchingContacts && contacts !== null && contacts?.length === 0 && (
          <p className="DebitPreview__no-contacts">{t('contacts:no-data-customer')}</p>
        )}
      </ListElement>
      <ListElement label={t('debits:receipt')} fullWidth>
        <p>{receiptName || t('common:none')}</p>
        {receipt && <PDFViewer base64PDF={receipt} scale={0.4} />}
      </ListElement>
      <ListElement label={t('common:attributes')} fullWidth>
        {labelledEavs.map((eav) => (
          <div key={eav.identifier} className="DebitPreview__attributes">
            <span className="DebitPreview__attributes__label">{eav.label}</span>
            <span className="DebitPreview__attributes__value">{eav.value || '-'}</span>
          </div>
        ))}
      </ListElement>
      <ToastNotification
        isShow={isToastShowed}
        status={toastStatus}
        text={toastText}
        onClose={hideToast}
      />
    </dl>
  );
};

export default observer(DebitPreview);
