import './index.scss';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import type { DefaultTFuncReturn } from 'i18next';
import { DebitStatus, ImportItemStatus, PhaseId } from 'types/models';
import type Misc from 'types/misc';

type Props = {
  variant: PhaseId | ImportItemStatus | Misc.BillingStatusId | DebitStatus | 'INACTIVE' | 'GROUP' | 'RECENT' | 'NOTE' | 'CUSTOM',
  /** Pour afficher une infobulle (native) au survol de la souris */
  title?: DefaultTFuncReturn,
  isRecent?: boolean,
  children: ReactNode,
  color?: string,
};

const Badge = ({ variant, title, isRecent = false, children, color }: Props): JSX.Element => {
  const classNames = classnames('Badge', {
    'Badge--upcoming': variant === PhaseId.UPCOMING,
    'Badge--pending': variant === PhaseId.PENDING,
    'Badge--almost-due': variant === PhaseId.ALMOST_DUE,
    'Badge--due': variant === PhaseId.DUE,
    'Badge--overdue': variant === PhaseId.OVERDUE,
    'Badge--late': variant === PhaseId.LATE,
    'Badge--default': variant === PhaseId.DEFAULT,
    'Badge--file-processed': variant === ImportItemStatus.IMPORTED,
    'Badge--file-created': variant === ImportItemStatus.COMPLETED,
    'Badge--file-processing': variant === ImportItemStatus.EXECUTING,
    'Badge--file-error': variant === ImportItemStatus.FAILED,
    'Badge--billing-litigated': variant === 'LITIGATED',
    'Badge--billing-paid': variant === 'PAID',
    'Badge--billing-cancelled': variant === 'CANCELLED',
    'Badge--inactive': variant === 'INACTIVE',
    'Badge--group': variant === 'GROUP',
    'Badge--recent': variant === 'RECENT' || isRecent,
    'Badge--note': variant === 'NOTE',
    'Badge--custom': variant === 'CUSTOM',
  });

  return (
    <span
      className={classNames}
      title={title ?? undefined}
      style={color ? { ['--custom-color' as string]: color } : undefined}
    >
      {children}
    </span>
  );
};

export default Badge;
