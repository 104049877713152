import { Fragment } from 'react';
import classNames from 'classnames';
import type Misc from 'types/misc';
import FilterItem from '../Item';
import FilterAdvancedField from './AdvancedField';

interface Props extends Misc.FiltersRenderer {
  filtersSettings?: Misc.FiltersSettings,
  excludedFilter?: string[],
  isModal?: boolean,
}

const DataTableFilterItems = (props: Props) => {
  const {
    filters,
    currentFilters,
    getFilterValue,
    onChange,
    excludedFilter,
    filtersSettings,
    isModal = false,
  } = props;
  const RenderFilters = filtersSettings?.renderFilters;
  const filterAdvanced: boolean = filters.some((item) => 'operators' in item);

  if (RenderFilters) {
    return (
      <RenderFilters
        filters={filters}
        currentFilters={currentFilters}
        getFilterValue={getFilterValue}
        onChange={onChange}
      />
    );
  }

  if (filterAdvanced) {
    return (
      <FilterAdvancedField
        filters={filters.filter(({ isHidden }) => !isHidden)}
        currentFilters={currentFilters.filter((obj) =>
          !filters.some(({ key, isHidden }) => key === obj.name && isHidden),
        )}
        excludedFilter={excludedFilter}
        getFilterValue={getFilterValue}
        onChange={onChange}
      />
    );
  }

  return (
    <Fragment>
      {filters.map((filter) => {
        if (filter.isHidden) {
          return null;
        }

        const itemClassName = classNames({
          'DataTableFilters__modal__container__filter': isModal,
          'DataTableFilters__modal__container__filter--full-width': isModal && filter.isFullWidth,
        });

        return (
          <div className={itemClassName} key={filter.key}>
            <FilterItem
              filter={filter}
              currentFilters={currentFilters}
              getFilterValue={getFilterValue}
              onChange={onChange}
            />
          </div>
        );
      })}
    </Fragment>
  );
};

export default DataTableFilterItems;
