import type { Collection } from 'types/api';
import type { Organization } from 'types/models';
import requester from 'utils/requester';

export type FetchAllOptions = {
  isAsync: boolean,
  fetchEntity: string | undefined,
  hasFetchOrganizationPrefix: boolean,
  fetchEntityQuerystring: string,
};

export type FetchAllParams = {
  organization: Organization['id'] | undefined,
  options: FetchAllOptions,
};

const all = async <TResult extends {}>({ organization, options }: FetchAllParams): Promise<TResult[]> => {
  const {
    isAsync,
    fetchEntity,
    hasFetchOrganizationPrefix,
    fetchEntityQuerystring,
  } = options;

  if (!organization) {
    throw new Error('FetchAllOptions: Missing organization.');
  }

  if (!isAsync || !fetchEntity) {
    throw new Error('FetchAllOptions: Must be async, and/or missing entity to fetch.');
  }

  let url = `${fetchEntity}?${fetchEntityQuerystring}`;
  if (hasFetchOrganizationPrefix) {
    url = `organizations/${organization}/${fetchEntity}?${fetchEntityQuerystring}`;
  }

  const { data } = await requester.get<Collection<TResult>>(url);
  return data['hydra:member'];
};

export default { all };
