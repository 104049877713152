import React from 'react';
import { useTranslation } from 'react-i18next';
import type { DebitStatus, PhaseId } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import getI18nPhase from 'utils/getI18nPhase';
import getI18nStatus from 'utils/getI18nStatus';
import Badge from 'components/Badge';

type Props = {
  date: string,
  phaseId: PhaseId,
  statusId?: DebitStatus,
};

const PhaseDate = ({ date, phaseId, statusId }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="PhaseDate">
      <Badge
        variant={statusId ? 'INACTIVE' : phaseId}
        title={statusId ? t(getI18nStatus(statusId)) : t(getI18nPhase(phaseId))}
      >
        {formatIntlDate(date, 'dateLong')}
      </Badge>
    </div>
  );
};

export default PhaseDate;
