import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import authStore from 'stores/Auth';
import type Misc from 'types/misc';
import FormGroup from 'components/FormGroup';
import FormSwitch from 'components/FormSwitch';
import FormSelectManager from 'components/FormSelectManager';

const ActionsManagerFilter = (props: Misc.FilterCustomComponent): JSX.Element => {
  const { filter, getFilterValue, onChange } = props;
  const { entityValue } = filter;
  const { t } = useTranslation();
  const { user } = authStore;
  const [isMyActions, setIsMyActions] = useState(false);

  const managerValue = useMemo(() => (
    getFilterValue(filter.key)
  ), [getFilterValue, filter]);

  useEffect(() => {
    if (managerValue === null) {
      setIsMyActions(false);
      return;
    }

    if ((entityValue === 'id' && managerValue.toString() === user?.id.toString()) ||
        (entityValue === 'reference' && managerValue.toString() === user?.reference.toString())) {
      setIsMyActions(true);
    }
  }, [entityValue, managerValue, user]);

  const handleToggleMyActions = useCallback((newValue: boolean) => {
    setIsMyActions(newValue);
    const value = newValue ? ((entityValue === 'id' ? user?.id.toString() : user?.reference.toString()) ?? null) : null;
    onChange({ name: filter.key, value });
  }, [entityValue, user?.id, user?.reference, onChange, filter.key]);

  const handleChangeManager = useCallback((name: string, value: string | null) => {
    onChange({ name, value });
  }, [onChange]);

  return (
    <FormGroup className="ActionsManagerFilter">
      <FormSwitch
        value={isMyActions}
        onChange={handleToggleMyActions}
        label={t('actions:filters.my-actions-only')}
        className="ActionsManagerFilter__my-actions"
      />
      {!isMyActions && (
        <FormSelectManager
          name={filter.key}
          value={managerValue}
          onSelect={handleChangeManager}
          placeholder={filter.emptyText}
          entityValue={entityValue || 'id'}
          withClearButton
        />
      )}
    </FormGroup>
  );
};

export default ActionsManagerFilter;
