import { useRef, useEffect, useState, useCallback, Fragment } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import apiScenarioGroup from 'api/scenarioGroup';
import { getAllEavsValues } from 'utils/getCustomerEavs';
import apiClients from 'api/clients';
import apiUsers from 'api/users';
import type Misc from 'types/misc';

type Props = {
  filtersList: Misc.FilterDeclarationItem[],
  name: string,
  value: Misc.FilterValue,
};

const FilterValue = (props: Props): JSX.Element => {
  const { filtersList, name, value } = props;
  const { t } = useTranslation();
  const { currentOrganization, attributes } = organizationStore;
  const defaultValue = Array.isArray(value) ? value.join(', ') : (value || '');
  const [displayValue, setDisplayValue] = useState<string | JSX.Element[]>(defaultValue);
  const clientValueRequested = useRef<string | null>(null);
  const managerValueRequested = useRef<string | null>(null);
  const optionsValueRequested = useRef<string | null>(null);
  const { get, cancel, isLoading } = useApiRequest();

  const getSelectOption = useCallback((filterDeclared: Misc.FilterDeclarationItem) => {
    if (!filterDeclared || !filterDeclared.selectData) {
      return;
    }
    const label = filterDeclared.selectData.find(
      ({ value: selectValue }) => value?.toString() === selectValue,
    );
    if (!label) {
      setDisplayValue(defaultValue);
      return;
    }
    setDisplayValue(label?.label);
  }, [setDisplayValue, defaultValue, value]);

  const getAsyncOptions = useCallback(async (filter: Misc.FilterDeclarationItem) => {
    if (!currentOrganization || Array.isArray(value) || !value) {
      return;
    }
    if (optionsValueRequested.current === value) {
      return;
    }

    const results = await get<Misc.IdRefName[]>(
      `organization/${currentOrganization.reference}/${filter.fetchEntity}?search=${value}`,
    );
    if (!results) {
      return;
    }

    setDisplayValue(results.find(({ id, reference }) => (
      (id.toString() === value.toString()) || (reference === value.toString())
    ))?.name ?? '--');

    optionsValueRequested.current = value;
  }, [get, currentOrganization, value]);

  const getClientOption = useCallback(async (entityValue: 'id' | 'reference' | undefined) => {
    if (entityValue === 'reference') {
      setDisplayValue(defaultValue || '--');
      return;
    }
    if (clientValueRequested.current === defaultValue) {
      return;
    }
    cancel();

    if (!currentOrganization || !defaultValue || isLoading) {
      return;
    }
    clientValueRequested.current = defaultValue;
    const result = await apiClients.one({ id: Number(defaultValue) });

    setDisplayValue(result?.denomination || defaultValue || '--');
  }, [cancel, currentOrganization, defaultValue, isLoading]);

  const getScenarioGroupOption = useCallback(async () => {
    if (clientValueRequested.current === defaultValue) {
      return;
    }
    cancel();

    if (!currentOrganization || !defaultValue || isLoading) {
      return;
    }

    clientValueRequested.current = defaultValue;

    if (defaultValue === 'debt') {
      setDisplayValue(t('selectors:debt') as string);
    }

    const result = !!Number(defaultValue)
      ? await apiScenarioGroup.one({ id: Number(defaultValue) })
      : { name: defaultValue };

    setDisplayValue(result?.name || defaultValue || '--');
  }, [cancel, currentOrganization, defaultValue, isLoading, t]);

  const getManagerOption = useCallback(async (entityValue: 'id' | 'reference' | undefined) => {
    if (entityValue === 'reference') {
      setDisplayValue(defaultValue || '--');
      return;
    }
    if (managerValueRequested.current === defaultValue) {
      return;
    }
    cancel();

    if (!currentOrganization || !defaultValue || isLoading) {
      return;
    }
    managerValueRequested.current = defaultValue;
    const result = await apiUsers.all({ organizationId: currentOrganization.id });
    const manager = result?.find((user) => user.id === Number(defaultValue));

    setDisplayValue(`${manager?.firstName} ${manager?.lastName}` || defaultValue || '--');
  }, [cancel, currentOrganization, defaultValue, isLoading]);

  const getAttributOption = useCallback((entityValue: string | undefined) => {
    if (!entityValue) {
      return;
    }

    const filter = Array.isArray(entityValue) ? entityValue : entityValue.split(',');
    const values = filter.map((item) => {
      const [code, data] = item.split(':');
      return { code, data };
    });

    const eavs = getAllEavsValues(values, attributes.client)
      .filter(({ identifier }) => values.some((obj) => obj.code === identifier))
      .map(({ label, value: eav }, index) => (
        <Fragment key={`${label}-${index}`}>
          <span className="FilterValueEAV">{label}</span>
          <span>{eav}</span>
        </Fragment>
      ));

    setDisplayValue(eavs);
  }, [attributes.client]);

  const getFilterValue = useCallback(() => {
    const filterDeclared = filtersList.find(({ key }) => key === name);
    if (!filterDeclared) {
      setDisplayValue(defaultValue);
      return;
    }

    switch (filterDeclared.type) {
      case 'selectAsync':
      case 'customAsync':
        getAsyncOptions(filterDeclared);
        break;
      case 'selectClient':
        getClientOption(filterDeclared.entityValue);
        break;
      case 'selectManager':
        getManagerOption(filterDeclared.entityValue);
        break;
      case 'selectAttributes':
        getAttributOption(value as string);
        break;
      case 'selectScenarioGroup':
        getScenarioGroupOption();
        break;
      case 'select':
        getSelectOption(filterDeclared);
        break;
      case 'onOff':
        setDisplayValue(value?.toString() ?? '');
        break;
      case 'yesNo':
        setDisplayValue(value === '1' ? t<string>('common:yes') : t<string>('common:no'));
        break;
      case 'custom':
        if (filterDeclared.customType === 'manager') {
          getManagerOption(filterDeclared.entityValue);
          break;
        }
        setDisplayValue(Array.isArray(value) ? value.join(', ') : value as string);
        break;
      default:
        setDisplayValue(Array.isArray(value) ? value.join(', ') : value as string);
        break;
    }
  }, [
    filtersList,
    name,
    defaultValue,
    getAsyncOptions,
    getClientOption,
    getManagerOption,
    getSelectOption,
    getAttributOption,
    getScenarioGroupOption,
    value,
    t,
  ]);

  useEffect(() => {
    getFilterValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isLoading]);

  return <>{displayValue}</>;
};

export default observer(FilterValue);
