import './index.scss';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import type { Scenario } from 'types/models';
import Action from './Action';

type Props = {
  onActionDone(message: string): void,
  onActionError(message: string): void,
  scenario: Scenario,
};

const ScenarioDetailsActions = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { scenario, onActionError, onActionDone } = props;
  const { tasks, reminders } = scenario;
  const [activePopover, setActivePopover] = useState<{ code?: string | null, type?: string }>();

  const orderedActions = useMemo(() => (
    [
      ...tasks.map((task, index) => ({ ...task, type: 'TASK' as Misc.ActionType, index })),
      ...reminders.map((reminder, index) => ({ ...reminder, type: 'REMINDER' as Misc.ActionType, index })),
    ].sort((elem1, elem2) => elem1.day - elem2.day)
  ), [reminders, tasks]);

  return (
    <div className="ScenarioDetailsActions">
      <div className="ScenarioDetailsActions__section">
        {!tasks.length && !reminders.length && (
          <p className="ScenarioDetailsActions__section__no-data">{t('common:none_f')}</p>
        )}
        {orderedActions.map((action) => (
          <Action
            key={action.code}
            index={action.index}
            action={action}
            type={action.type as Misc.ActionType}
            scenario={scenario}
            activePopover={activePopover && activePopover.code === action.code ? activePopover.type! : null}
            setActiveAction={(code, type) => setActivePopover({ code, type })}
            onActionDone={onActionDone}
            onActionError={onActionError}
          />
        ))}
      </div>
    </div>
  );
};

export default ScenarioDetailsActions;
