import { SelectSearchOption } from 'react-select-search';
import type Misc from 'types/misc';

export type DefaultValue = string | number | Misc.Identifier[] | null;

export const getDefaultValue = (
  defaultValue: DefaultValue | undefined,
  options: SelectSearchOption[],
): string | string[] => {
  if (!defaultValue) {
    return '';
  }
  if (Array.isArray(defaultValue)) {
    return defaultValue
      .filter((val) => options.findIndex((option) => option.value === val.identifier) >= 0)
      .map((val) => val.identifier);
  }
  return `${defaultValue}`;
};

export const getMultipleIdentifiers = (selection: string | undefined) => {
  if (!selection || selection.length === 0) {
    return [];
  }
  return selection.split(',').map((user) => ({ identifier: user }));
};
