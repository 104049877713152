import './index.scss';
import React from 'react';
import Loading from 'components/Loading';

type ListData = {
  label: string,
  value: string | React.ReactNode,
  help?: React.ReactNode,
  isLoading?: boolean,
};

type Props = {
  data: ListData[],
};

const SettingsList = ({ data }: Props): JSX.Element => (
  <div className="SettingsList">
    <dl className="SettingsList__item">
      {data.map(({ label, value, isLoading, help }) => (
        <div className="SettingsList__item__delimiter" key={label}>
          <dt className="SettingsList__item__label">
            {label}{help}
            {isLoading && <Loading hasNoText />}
          </dt>
          <dd className="SettingsList__item__value">
            {value}
          </dd>
        </div>
      ))}
    </dl>
  </div>
);

export default SettingsList;
