import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import equal from 'deep-equal';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import type { ValidationErrors } from 'types/errors';
import type Misc from 'types/misc';
import type { ContactPost, Contact } from 'types/models';
import type { FetchOneParams as FetchOneContactParams } from 'api/contacts';
import apiClients from 'api/clients';
import apiContacts from 'api/contacts';
import apiContactRoles from 'api/contactRoles';
import apiOrganization from 'api/organization';
import useFetch from 'hooks/useFetch2';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import recomposeName from 'utils/recomposeName';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import ModalForm from 'components/ModalForm';
import ResponseError from 'utils/errors';
import type { ModalFormData } from 'components/ModalForm';
import formatEavsValues from 'utils/formatEavsValues';
import { PHONE_REGEXP } from 'utils/validator';
import ContactForm from './Form';

type Props = {
  editType: 'new-contact' | 'new' | 'edit' | undefined,
  id?: number | null,
  defaultCustomerId?: number,
  onDone(title: string, message: string): void,
  onClose(): void,
  finallyRedirectTo?: string,
  customerId?: number,
  defaultRole?: string,
};

const ContactEditModal = (props: Props): JSX.Element => {
  const {
    editType,
    id,
    defaultCustomerId,
    onDone,
    onClose,
    finallyRedirectTo,
    defaultRole,
    customerId,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization, attributes } = organizationStore;
  const cache = useQueryClient();

  const isNew = useMemo<boolean>(
    () => ((!!editType && (editType === 'new-contact' || editType === 'new')) || customerId !== undefined),
    [editType, customerId],
  );

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const initialData = useRef<ContactPost | null>(null);

  const {
    isFetching: contactIsFetching,
    data: contactData,
    error: contactError,
  } = useFetch<FetchOneContactParams, Contact>(
    {
      cacheKey: 'contact',
      id: id!,
    },
    apiContacts.one,
    { refetchOnWindowFocus: false, enabled: !!id, cacheTime: 0 },
  );

  const mapFormData = useCallback(
    (rawData: ModalFormData): ContactPost => {
      const clientId = isNew ? rawData.client as string : (contactData?.client.id ?? '');
      const eavsResult = formatEavsValues(attributes.contact ?? [], rawData);
      return {
        role: apiContactRoles.resourceUrl(rawData.contactRole as string),
        firstName: rawData.firstName as string,
        lastName: rawData.lastName as string,
        civility: rawData.civility as string,
        primaryAddress: rawData.address1 as string,
        secondaryAddress: rawData.address2 as string,
        thirdAddress: rawData.address3 as string,
        postalCode: rawData.postalCode as string,
        city: rawData.city as string,
        countryCode: rawData.country as Misc.Country,
        email: rawData.email as string,
        phone: rawData.phone as string,
        cellPhone: rawData.cellPhone as string,
        nameAddress: rawData.nameAddress as string,
        companyAddress: rawData.companyAddress as string,
        eavs: eavsResult,
        client: apiClients.resourceUrl(clientId),
        organization: isNew && currentOrganization ? apiOrganization.resourceUrl(currentOrganization.id) : undefined,
        identifier: `${clientId}_${nanoid(10)}`,
      };
    },
    [contactData, currentOrganization, isNew, attributes.contact],
  );

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
    if (finallyRedirectTo) {
      setTimeout(() => {
        history.replace(finallyRedirectTo);
      }, 300);
    }
  }, [onClose, history, finallyRedirectTo]);

  const {
    post,
    put,
    error: saveError,
  } = useApiRequest();

  useEffect(() => {
    const { code } = saveError || { code: 0 };
    if (code === 404 || code === 2) {
      closeSelf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveError]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(
        !!formData && !equal(initialData.current, mapFormData(formData)),
      );
    },
    [initialData, mapFormData],
  );

  const handleCancel = useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const handleEmailError = useCallback(() => {
    setValidationErrors({
      email: { code: 4, message: t('email-not-valid') },
    });
  }, [t]);

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization || isSaving) {
        return;
      }

      setValidationErrors(null);

      const { phone, cellPhone, address1, email, contactRole } = formData as { [key: string]: string };

      if (!contactRole) {
        setValidationErrors({ contactRole: { code: 2, message: t('errors:validation.required-field') } });
        return;
      }

      if (!address1 && !cellPhone && !email && !phone) {
        setValidationErrors({ channel: { code: -1, message: t('validators:at-least-one-channel-filled') } });
        return;
      }

      if (cellPhone && !PHONE_REGEXP.test(cellPhone)) {
        setValidationErrors({ cellPhone: { code: -1, message: t('validators:phone.not-valid') } });
        return;
      }

      if (address1) {
        const missingField = ['postalCode', 'country', 'city'].find((field) => !(formData[field] as string));
        if (missingField) {
          setValidationErrors({ [missingField]: { code: 2, message: t('errors:validation.required-field') } });
          return;
        }
      }

      setIsSaving(true);

      const data = mapFormData(formData);
      if (!data) {
        return;
      }

      let result;
      let resultEavs;
      let haveEavsToUpdate = data.eavs && Object.keys(data.eavs).length > 0;
      // d'abord on met à jour le client sans les eavs
      if (isNew) {
        result = await post<Contact>(apiContacts.createUrl, data);
      } else if (contactData) {
        result = await put<Contact>(apiContacts.updateUrl(contactData.id), data);
      }

      if (haveEavsToUpdate && result?.id) {
        // ensuite on met à jour les eavs
        resultEavs = await put<Contact>(
          apiContacts.updateContactEavsUrl(result?.id),
          { values: data.eavs },
        );
      }

      if (!isMountedRef.current) {
        return;
      }

      setIsSaving(false);

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (customerId) {
        cache.invalidateQueries({
          queryKey: ['contactsForCustomer', currentOrganization.reference, customerId],
        });
      }

      if (result?.id) {
        cache.invalidateQueries({ queryKey: ['contactAllEavs', result?.id] });

        if (haveEavsToUpdate ? resultEavs?.id : true) {
          if (isNew) {
            onDone(
              t('contacts:actions.toast.created'),
              t('contacts:actions.toast.created-name', { name: recomposeName(data) }),
            );
          } else {
            onDone(
              t('contacts:actions.toast.modified'),
              t('contacts:actions.toast.modified-name', { name: recomposeName(data) }),
            );
          }

          closeSelf();
        }
      }
    },
    [
      currentOrganization,
      isNew,
      isSaving,
      post,
      put,
      mapFormData,
      contactData,
      isMountedRef,
      onDone,
      t,
      cache,
      customerId,
      closeSelf,
    ],
  );

  return (
    <ModalForm
      isOpened
      className="ContactEdit"
      title={isNew ? t('common:new-contact') : t('common:edit-contact')}
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onEmailError={handleEmailError}
      onCancel={handleCancel}
      isFetched={!contactIsFetching || editType !== 'edit'}
      isLoading={contactIsFetching || isSaving}
    >
      {validationErrors?.channel && (
        <ErrorMessage error={validationErrors.channel as ResponseError} />
      )}
      {(editType === 'edit' && contactError) && (
        <ErrorMessage error={contactError} />
      )}
      {saveError && <ErrorMessage error={saveError} />}
      <ContactForm
        defaultData={editType === 'edit' ? contactData : undefined}
        isCreate={isNew}
        allRoles={currentOrganization?.contactRoles ?? []}
        errors={validationErrors}
        defaultCustomerId={defaultCustomerId}
        customerId={customerId}
        defaultRole={defaultRole}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t<string>('common:close-form')}
        cancelButtonText={t<string>('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ContactEditModal);
