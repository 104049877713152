import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import apiBusinessUnits from 'api/business-units';
import type { FetchAllParams } from 'api/business-units';
import type { BusinessUnit } from 'types/models';
import organizationStore from 'stores/Organization';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import Button from 'components/Button';
import useFetch from 'hooks/useFetch2';
import SettingsSection from '../../Section';
import ModalCreateCategory from './ModalCreateCategory';
import PerimetersTable from './CategoriesTable';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
  onReload(): void,
  reloadTick: number,
};

const SettingsCollaboratorsPerimeters = (props: Props): JSX.Element => {
  const { onShowToast, reloadTick, onReload } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const [editCategory, setEditCategory] = useState<BusinessUnit | undefined>();

  const {
    data: businessUnits,
    isFetching,
    refetch,
  } = useFetch<FetchAllParams, BusinessUnit[]>(
    {
      cacheKey: 'businessUnits',
      id: currentOrganization?.id,
    },
    apiBusinessUnits.all,
    { enabled: !!currentOrganization },
  );

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const [showModalCategory, hideModalCategory] = useModal(() => {
    return <ModalCreateCategory
      category={editCategory}
      categoryData={businessUnits}
      onClose={hideModalCategory}
      onShowToast={onShowToast}
      onReload={onReload}
    />;
  }, [onShowToast, businessUnits, editCategory, onReload]);

  return (
    <SettingsSection
      title={t('perimeters:perimeters')}
      action={(
        <Button onClick={() => { setEditCategory(undefined); showModalCategory(); }} variant="link" withCaretIcon>
          {t('perimeters:create')}
        </Button>
      )}
    >
      <p className='SelectorsListing__description'>{t('perimeters:definition')}</p>
      <PerimetersTable
        categories={businessUnits}
        isLoading={isFetching}
        reloadTick={reloadTick}
        showCategoryModal={showModalCategory}
        setEditCategory={setEditCategory}
        onShowToast={onShowToast}
        onReload={onReload}
      />
    </SettingsSection>
  );
};

export default observer(SettingsCollaboratorsPerimeters);
