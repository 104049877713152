import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';

type Props = {
  defaultData?: string,
  errors?: ValidationErrors | null,
};

const DebitEditPlanForm = ({ defaultData, errors }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormFieldset>
      <FormGroup label={t('debits:dunning-plan-edit')} error={errors?.plan}>
        <FormSelect
          name="plan"
          placeholder={t('debits:dunning-plan-edit')}
          isAsync
          fetchEntity="scenarios"
          defaultValue={defaultData}
        />
      </FormGroup>
    </FormFieldset>
  );
};

export default DebitEditPlanForm;
