import './index.scss';
import { useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import currenciesStore from 'stores/Currencies';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';

type Props = {
  filter: Misc.FilterDeclarationItem,
  defaultValue: string | null,
  label: string | null,
  onChange(name: string, value: string): void,
  className?: string,
};

const FilterNumberInput = (props: Props): JSX.Element => {
  const { filter, defaultValue, onChange, className, label } = props;
  const { key, withCurrencyAddon } = filter;
  const { currentOrganization } = organizationStore;
  const { currency } = currentOrganization ?? {};
  const { currencies } = currenciesStore;

  const [inputValue, setInputValue] = useState<string>(defaultValue || '');

  const currencySymbol = useMemo(() => (
    currencies?.find(({ code }) => currency === code)?.symbol
  ), [currency, currencies]);

  const handleChange = useCallback((newValue: string) => {
    setInputValue(newValue);
  }, []);

  useDebouncedEffect(() => {
    onChange(key, inputValue);
  }, 300, [inputValue]);

  const classNames = classnames(['DataTableFiltersItemNumberInput', className]);

  return (
    <FormGroup className={classNames} label={label}>
      <FormControl
        type="number"
        aria-label={label}
        name={key}
        value={inputValue}
        onChange={handleChange}
      />
      {(withCurrencyAddon && currency) && (
        <div className="DataTableFiltersItemNumberInput__currency">
          <div className="DataTableFiltersItemNumberInput__currency__symbol">
            {currencySymbol}
          </div>
          <div className="DataTableFiltersItemNumberInput__currency__code">
            {currency}
          </div>
        </div>
      )}
    </FormGroup>
  );
};

export default observer(FilterNumberInput);
