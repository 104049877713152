import './index.scss';
import React, { ReactNode } from 'react';

interface Props {
  title: string,
  description: string,
  children: ReactNode,
}

const GraphSection: React.FC<Props> = (props) => {
  const {
    title,
    description,
    children,
  } = props;

  return (
    <div className="GraphSection">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="GraphSection__chart">
        {children}
      </div>
    </div>
  );
};

export default GraphSection;
