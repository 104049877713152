import { useMemo, useState, useCallback, useRef } from 'react';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import type ResponseError from 'utils/errors';
import type { ValidationErrors } from 'types/errors';
import type { Debit, DebitPost } from 'types/models';
import organizationStore from 'stores/Organization';
import formatEavsValues from 'utils/formatEavsValues';
import authStore from 'stores/Auth';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import apiOrganization from 'api/organization';
import apiCurrencies from 'api/currencies';
import apiClients from 'api/clients';
import apiUsers from 'api/users';
import formatEavsFormValues from 'utils/formatEavsFormValues';
import DebitForm from './Form';

type Props = {
  isLoading: boolean,
  data?: DebitPost,
  apiError: ResponseError | null,
  finallyRedirectTo?: string,
  customerId?: number,
  validationErrors: ValidationErrors | null,
  receipt: string | null,
  receiptName: string | null,
  onChangeReceipt(newReceipt: string | null): void,
  onChangeReceiptName(name: string | null): void,
  onChangeCustomer(newId: number): void,
  onSubmit(data: DebitPost): void,
  onClose(): void,
};

const DebitEditModal = (props: Props): JSX.Element => {
  const history = useHistory();
  const { debitRef } = useParams<{ debitRef?: string }>();
  const { id: organizationId, type } = organizationStore.currentOrganization!;
  const { attributes: eavs } = organizationStore;
  const { t, ct } = useContextualTranslation(type);
  const { user } = authStore;
  const {
    onClose,
    finallyRedirectTo,
    onSubmit,
    isLoading,
    validationErrors,
    apiError,
    data,
    onChangeCustomer,
    receiptName,
    onChangeReceiptName,
    receipt,
    customerId,
    onChangeReceipt,
  } = props;

  const isNew = useMemo(() => !debitRef || debitRef === 'new', [debitRef]);

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const [debitData] = useState<Debit | null>(null);
  const initialData = useRef<DebitPost | null>(null);

  const mapFormData = useCallback((rawData: ModalFormData): DebitPost | null => {
    const newCustomerId = Number.parseInt(rawData.customerId as string, 10);
    onChangeCustomer(newCustomerId);

    return {
      organization: apiOrganization.resourceUrl(organizationId),
      client: apiClients.resourceUrl(newCustomerId),
      createdBy: apiUsers.resourceUrl(user!.id),
      identifier: rawData.reference as string,
      label: rawData.subject as string,
      amount: rawData.amount as string,
      currency: apiCurrencies.resourceUrl(parseInt(rawData.currency as string)),
      dueDate: rawData.dueAt as string,
      issueDate: rawData.issueAt as string,
      isActive: true,
      muted: false,
      promiseToPayAt: rawData.promiseToPayAt as string,
      invoice: receipt || '',
      // eavs pour l'envoi api
      eavs: formatEavsValues(eavs?.debit, rawData),
      // eavsForm pour le formulaire
      eavsForm: formatEavsFormValues(eavs?.debit, rawData),
    };
  }, [organizationId, onChangeCustomer, receipt, eavs, user]);

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
    if (finallyRedirectTo) {
      setTimeout(() => {
        history.push(finallyRedirectTo);
      }, 300);
    }
  }, [onClose, history, finallyRedirectTo]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(
        !!formData && !equal(initialData.current, mapFormData(formData)),
      );
    },
    [initialData, mapFormData],
  );

  const handleCancel = useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    const formattedData = mapFormData(formData);
    if (!formattedData) {
      return;
    }

    onSubmit(formattedData);
  }, [mapFormData, onSubmit]);

  return (
    <ModalForm
      isOpened
      className="DebitEdit"
      title={isNew ? ct('common:new-bill') : ct('common:edit-bill')}
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      buttonsDisabled={!customerId}
      saveText={t('models:preview')}
    >
      {apiError && (
        <ErrorMessage error={apiError} />
      )}
      <DebitForm
        defaultData={debitData || undefined}
        data={data}
        eav={eavs}
        errors={validationErrors}
        customerId={customerId}
        receipt={receipt}
        receiptName={receiptName}
        onChangeReceipt={onChangeReceipt}
        onChangeReceiptName={onChangeReceiptName}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(DebitEditModal);
