import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { Invitation } from 'types/models/Invitation';
import organizationStore from 'stores/Organization';
import apiInvitations from 'api/invitations';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import type { ModalFormData } from 'components/ModalForm';
import ModalForm from 'components/ModalForm';
import Confirm from 'components/Confirm';
import type { ValidationErrors } from 'types/errors';
import ErrorMessage from 'components/ErrorMessage';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import ModalInviteForm from './Form';

type Props = {
  onClose(): void,
  onShowToast(message: string, type: ToastStatus): void,
  onInvitationDone(): void
};

const ModalInvite = ({ onClose, onShowToast, onInvitationDone }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const { post, error, isLoading } = useApiRequest();

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(!!formData);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      const result = await post<Invitation>(
        apiInvitations.createUrl,
        {
          email: formData.email,
          locale: formData.language,
          role: formData.role,
          businessUnits: formData.businessUnit ? (formData.businessUnit as string).split(',') : null,
          organization: currentOrganization.reference,
        },
      );

      if (!isMountedRef.current) {
        return;
      }

      if (!result || result?.errors) {
        setValidationErrors(result?.errors || null);
        return;
      }

      onShowToast(t('collaborators:toast.success.collaborator-add', { name: formData.email }), 'success');
      onInvitationDone();
      onClose();
    },
    [currentOrganization, post, isMountedRef, onShowToast, t, onClose, onInvitationDone],
  );

  return (
    <ModalForm
      title={t('collaborators:invite')}
      className="ModalInviteForm"
      isOpened
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <ModalInviteForm
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalInvite);
