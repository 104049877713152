import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import equal from 'deep-equal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type Misc from 'types/misc';
import type { Debit, UnMutedCreditsDebits, UnmutedScenario } from 'types/models';
import { DebitAction } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import ModalForm from 'components/ModalForm';
import type { ModalFormData } from 'components/ModalForm';
import getDaysDiff from 'utils/getDaysDiff';
import apiDebits from 'api/debits';
import UnMutedForm from './Form';

type Props = {
  onDone(action: DebitAction): void,
  onClose(): void,
  finallyRedirectTo?: string,
  isShow: boolean,
  title: string,
  text: string,
  reference?: string,
  id?: number,
  mutedAt?: string | null,
  hasGroup?: boolean,
  bulk?: Debit['id'][]
};

const DebitsRowActionsUnMuted = (props: Props): JSX.Element => {
  const {
    onDone,
    onClose,
    finallyRedirectTo,
    isShow,
    title,
    text,
    reference,
    id,
    mutedAt,
    hasGroup,
    bulk,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const initialData = useRef<UnMutedCreditsDebits | null>(null);

  const mutedDays = useMemo(() => {
    if (!mutedAt) {
      return null;
    }

    const dateA = new Date();
    const daysDiff = getDaysDiff(dateA.toISOString(), mutedAt);

    return parseInt(daysDiff);
  }, [mutedAt]);

  const mapFormData = useCallback(
    (rawData: ModalFormData): UnMutedCreditsDebits => ({
      scenario: rawData.scenario as UnmutedScenario,
    }),
    [],
  );

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    if (isMountedRef.current) {
      setShowCancelConfirm(false);
      setHasChanges(false);
    }

    onClose();

    if (finallyRedirectTo) {
      setTimeout(() => {
        history.push(finallyRedirectTo);
      }, 300);
    }
  }, [isMountedRef, onClose, history, finallyRedirectTo]);

  const { put, patch, error: saveError } = useApiRequest();

  useEffect(() => {
    const { code } = saveError || { code: 0 };
    if (code === 404 || code === 2) {
      closeSelf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveError]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(
        !!formData && !equal(initialData.current, mapFormData(formData)),
      );
    },
    [initialData, mapFormData],
  );

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
      return;
    }
    closeSelf();
  }, [hasChanges, closeSelf]);

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData) {
        return;
      }

      const data = mapFormData(formData);
      let result;

      if (bulk) {
        const payload = bulk
          .map((identifier) => JSON.stringify({
            id: identifier,
            muted: false,
            scenario: data.scenario,
          }))
          .join('\n');

        result = await patch<Misc.BulkStatusCode>(
          apiDebits.bulkUpdateUrl,
          payload,
        );
      } else {
        result = await put<Debit>(
          apiDebits.updateUrl(id!),
          { muted: false, ...data },
        );
      }

      if (result && isMountedRef.current) {
        onDone(DebitAction.UNMUTE);
        closeSelf();
      }
    },
    [mapFormData, bulk, isMountedRef, put, patch, id, onDone, closeSelf],
  );

  return (
    <ModalForm
      isOpened={isShow}
      className="UnMuted"
      title={title}
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      helpHeaderText={hasGroup ? t('debits:actions.confirm.unmute-group-tooltip') : undefined}
    >
      {saveError && <ErrorMessage error={saveError} />}
      <UnMutedForm text={text} reference={reference} mutedDays={mutedDays} />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default DebitsRowActionsUnMuted;
