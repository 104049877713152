import './index.scss';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreditStatus, DebitCreditEntity, DebitType } from 'types/models';
import type { DebitOrCredit } from 'types/models';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import useTooltip from 'components/Tooltip/useTooltip';
import Icon from 'components/Icon';
import Badge from 'components/Badge';

type Props = {
  data: DebitOrCredit,
  showCreditModal(id: number): void,
};

const CustomerAccountDetailsDocumentCell = ({ data, showCreditModal }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { id, entity, date, reference, status, type } = data;
  const isMuted = entity === DebitCreditEntity.DEBIT && !!data.muted;
  const isRecent = useMemo(() => dateIsLastTwoDays(date), [date]);
  const isRejected = useMemo(() => (status === CreditStatus.FAILED || type === DebitType.REJECT), [status, type]);

  const tooltipDebit = useTooltip(
    <div className="CustomerAccountDetailsDocumentCell__tooltip">
      {t('common:open-invoice')}<br />{reference}
    </div>,
    'center',
    reference.length < 30,
  );

  const tooltipCredit = useTooltip(
    <div className="CustomerAccountDetailsDocumentCell__tooltip">
      {t('common:open-payment')}<br />{reference}
    </div>,
    'center',
    reference.length < 30,
  );

  return (
    <div className="CustomerAccountDetailsDocumentCell">
      <div className="CustomerAccountDetailsDocumentCell__reference">
        {isMuted && <Icon name="bell-muted" />}
        {entity === DebitCreditEntity.DEBIT && (
          <Link
            to={`/debit/${id}${type === DebitType.SETTLEMENT ? `?type=${type.toLocaleLowerCase()}` : ''}`}
            className="CustomerAccountDetailsDocumentCell__link"
            ref={tooltipDebit}
          >
            {reference}
          </Link>
        )}
        {entity === DebitCreditEntity.CREDIT && (
          <a
            role="button"
            onClick={() => { showCreditModal(id); }}
            className="CustomerAccountDetailsDocumentCell__link"
            ref={tooltipCredit}
          >
            {reference}
          </a>
        )}
      </div>
      {entity === DebitCreditEntity.DEBIT && (
        <div className="CustomerAccountDetailsDocumentCell__pay-request">
          {isRecent && <Badge variant="RECENT">{t('common:recent')}</Badge>}
          {isRejected ? t('common:reject') : t('common:debit')}
        </div>
      )}
      {entity === DebitCreditEntity.CREDIT && (
        <div className="CustomerAccountDetailsDocumentCell__credit">
          {isRecent && <Badge variant="RECENT">{t('common:recent')}</Badge>} {isRejected ? t('common:reject') : t('common:credit')}
        </div>
      )}
    </div>
  );
};

export default CustomerAccountDetailsDocumentCell;
