import type { TFunction } from 'i18next';
import type { StepAction } from 'types/models';
import { StepActionEntity } from 'types/models';

/**
 * Fonction qui retourne statut de l'action.
 *
 * Voici l'ordre de priorité où la première est la plus prioritaire :
 * 1. En attente
 * 2. Annulé
 * 3. Relance à valider (withValidation non validé)
 * 4. Le dernier est pris de [validation, delay, modify]
 * 5. Relance automatique (isInitialActive)
 * 6. Créé par / À faire
 *
 * @param t Fonction de traduction
 * @param step Action
 * @returns Statut de l'action.
 */
const getI18nHistoryStepExtraInfo = (t: TFunction, step: StepAction): string | null => {
  const {
    at,
    validation,
    withValidation,
    isInitialActive,
    isEditable,
    cancel,
    entity,
    author,
    delay,
    modify,
  } = step;

  const atDate = new Date(at?.at);
  const tonight = new Date();
  // - Pour éviter de mettre du jaune partout le jour des envois
  tonight.setHours(0, 0, 0, 0);

  const isWaiting = (
    isEditable === true
    && validation === null
    && cancel === null
    && isInitialActive === false
    && atDate < tonight
  );

  // 1. En attente
  if (isWaiting) {
    return t(
      entity === StepActionEntity.REMINDER ?
        'actions:history-step-type.reminder-waiting' :
        'actions:history-step-type.task-waiting',
    );
  }

  // 2. Annulé
  if (cancel !== null) {
    return t('by-name', { name: cancel.userName });
  }

  //  3. Relance à valider
  if (
    entity === StepActionEntity.REMINDER &&
    withValidation &&
    !validation?.at
  ) {
    return t('actions:history-step-type.reminder-to-validate');
  }

  // 4. Le dernier est pris de [validation, delay, modify]
  const latest = [
    { date: validation?.at, author: validation?.userName },
    { date: delay?.at, author: delay?.userName },
    { date: modify?.at, author: modify?.userName },
  ].sort((a, b) => {
    const dateA = a.date ? new Date(a.date).getTime() : 0;
    const dateB = b.date ? new Date(b.date).getTime() : 0;
    return dateB - dateA;
  })[0];

  if (latest.date) {
    return t('by-name', { name: latest.author });
  }

  if (entity === StepActionEntity.REMINDER) {
    // 5. Relance automatique (isInitialActive)
    if (isInitialActive) {
      return t('actions:history-step-type.automated-reminder');
    }

    // 6. Créé par / À faire
    if (!!author) {
      return t('created-by', { name: author.fullName });
    }
  }

  // 6. Créé par / À faire
  if (entity === StepActionEntity.TASK) {
    return t('actions:history-step-type.task-to-do');
  }

  return null;
};

export default getI18nHistoryStepExtraInfo;
