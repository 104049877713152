import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import CreditViewModal from 'components/CreditViewModal';

export type RouteParams = {
  editType: 'view',
  creditId: string
};

type Props = {
  onClose(): void,
  onClickEdit(id: number): void,
};

const CreditView = ({ onClose, onClickEdit }: Props): JSX.Element | null => {
  const { creditId } = useParams<RouteParams>();

  const [showModal, hideModal] = useModal(() => {
    const handleClose = () => {
      hideModal();
      onClose();
    };

    return (
      <CreditViewModal
        creditId={parseInt(creditId)}
        onClose={handleClose}
        onClickEdit={onClickEdit}
      />
    );
  }, [creditId, onClose, onClickEdit]);

  useEffect(() => {
    showModal();
    return () => {
      hideModal();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CreditView;
