import './index.scss';
import { Fragment, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import apiOrganization from 'api/organization';
import type { FetchStatsParams } from 'api/organization';
import useFetch from 'hooks/useFetch2';
import type { OrganizationStats } from 'types/models';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading/index';
import ErrorMessage from 'components/ErrorMessage';
import HomeHeader from './Header';
import HomeContent from './Content';
import NoOrganizationSelected from './NoOrganizationSelected';

const Home = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization, selectedBusinessUnitsIdentifier } = organizationStore;

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return [];
    }
    return [{ name: 'businessUnitCodes', value: selectedBusinessUnitsIdentifier }];
  }, [selectedBusinessUnitsIdentifier]);

  const {
    data: statsData,
    error,
    isLoading,
  } = useFetch<FetchStatsParams, OrganizationStats>(
    {
      cacheKey: 'organizationStat',
      id: currentOrganization?.id,
      filtering,
    },
    apiOrganization.getStats,
    { enabled: !!currentOrganization?.id },
  );

  useEffect(() => {
    localStorage.removeItem('sentConfirmations');
  }, []);

  return (
    <div className="Home">
      {error && currentOrganization && <ErrorMessage error={error} />}
      {(isLoading && currentOrganization?.corporateName) && (
        <Loading
          text={t(
            'home:loading-stats',
            { organizationName: currentOrganization?.corporateName },
          )}
        />
      )}
      {!currentOrganization && <NoOrganizationSelected />}
      {(!isLoading && !error && statsData) && (
        <Fragment>
          <HomeHeader data={statsData} />
          <HomeContent stats={statsData} />
        </Fragment>
      )}
    </div>
  );
};

export default observer(Home);
