import './index.scss';
import { useContext, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { Contact } from 'types/models';
import type { FetchAllForCustomerParams } from 'api/contacts';
import apiContacts from 'api/contacts';
import ListContacts from 'components/ListContacts';
import useFetch from 'hooks/useFetch2';
import { clientUserToManagerType } from 'utils/mapping/user-manager';
import customerContext from '../../customerContext';
import CustomerMainLayout from '../Layout';
import CustomerInfos from './Infos';

const CustomerInfosContacts = (): JSX.Element | null => {
  const { data: customer, refetch: fetchCustomer, onActionDone, reloadTick } = useContext(customerContext);
  const { t } = useTranslation();
  const customerId = customer?.id;
  const {
    isLoading,
    data: contacts,
    refetch,
    error,
  } = useFetch<FetchAllForCustomerParams, Contact[]>(
    {
      cacheKey: 'contactsForCustomer',
      id: customerId,
    },
    apiContacts.allForCustomer,
    { enabled: !!customerId },
  );

  const handleRefetch = useCallback(() => {
    if (fetchCustomer) {
      fetchCustomer();
    }
    refetch();
  }, [fetchCustomer, refetch]);

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const managers = useMemo(() => {
    return customer?.users ? customer.users.map((user) => clientUserToManagerType(user)) : [];
  }, [customer?.users]);

  if (!customer) {
    return null;
  }

  return (
    <CustomerMainLayout
      sidebar={(
        <div className="CustomerInfosContacts__side-view">
          <ListContacts
            customerId={customer.id}
            contactsCount={contacts?.length ?? 0}
            contacts={contacts}
            fetchContacts={handleRefetch}
            error={error}
            isLoading={isLoading}
            onActionDone={onActionDone}
            managers={managers}
          />
        </div>
      )}
    >
      <div className="CustomerInfosContacts">
        <h2 className="CustomerInfosContacts__title">
          {t('common:informations')}
        </h2>
        <CustomerInfos data={customer} />
      </div>
    </CustomerMainLayout>
  );
};

export default observer(CustomerInfosContacts);
