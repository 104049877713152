import { useCallback, useContext, useEffect, useRef } from 'react';
import { useModal } from 'react-modal-hook';
import type { Note } from 'types/models';
import type { FetchAllForCustomerParams } from 'api/notes';
import apiNotes from 'api/notes';
import useFetch from 'hooks/useFetch2';
import customerContext from 'pages/Customer/customerContext';
import CustomerEditNote from 'pages/Customer/Modals/EditNote';
import ListNotes from 'components/ListNotes';

const CustomerLastNotes = (): JSX.Element | null => {
  const { data: customer, onActionDone, reloadTick } = useContext(customerContext);
  const editData = useRef<Note | null | undefined>();

  const { isLoading, data: notes, error, refetch } = useFetch<FetchAllForCustomerParams, Note[]>(
    {
      cacheKey: 'notesForCustomer',
      id: customer?.id,
    },
    apiNotes.allForCustomer,
    { enabled: !!customer },
  );

  const handleOnDone = useCallback((message: string) => {
    refetch();
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone, refetch]);

  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTick]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <CustomerEditNote
      show
      editData={editData.current}
      customer={customer}
      onClose={hideEditModal}
      onDone={handleOnDone}
    />
  ), [editData, customer, handleOnDone]);

  const handleOpenEdit = useCallback((id?: number) => {
    editData.current = notes!.find((note) => note.id === id);
    showEditModal();
  }, [editData, notes, showEditModal]);

  return (
    <div className="CustomerLastNotes">
      <ListNotes
        notes={notes || []}
        error={error}
        resetError={() => {}}
        isLoading={isLoading}
        onOpenEditModal={handleOpenEdit}
        showAllLinkTo={`/customer/${customer?.id}/history?type=note`}
        withInvoice
      />
    </div>
  );
};

export default CustomerLastNotes;
