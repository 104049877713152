import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import CreateReminder from 'components/CreateReminder';
import Icon from 'components/Icon';
import type Misc from 'types/misc';
import { Channel, Scenario } from 'types/models';
import { RightsCategory } from 'types/models';
import ModalAddTask from '../ModalAddTask';
import ModalAddAction from '../ModalAddAction';

type Props = {
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  scenario: Scenario,
};

const SettingsPlan = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const [channel, setChannel] = useState<Channel>(Channel.EMAIL);

  const {
    scenario,
    onActionDone,
    onActionError,
  } = props;

  const [showModalAddTask, hideModalAddTask] = useModal(() => (
    <ModalAddTask
      onActionError={onActionError}
      onActionDone={onActionDone}
      onClose={hideModalAddTask}
      task={null}
      scenario={scenario}
    />
  ), [onActionDone, onActionError, scenario]);

  const [showModalAddAction, hideModalAddAction] = useModal(() => (
    <ModalAddAction
      onActionDone={onActionDone}
      onActionError={onActionError}
      onClose={hideModalAddAction}
      channel={channel}
      action={null}
      scenario={scenario}
    />
  ), [channel, onActionDone, onActionError, scenario]);

  const handleSelectedChannel = useCallback((newChannel: Channel) => {
    setChannel(newChannel);
    showModalAddAction();
  }, [showModalAddAction]);

  const handleSelectType = useCallback((selectedType: Misc.ActionType | Channel) => {
    if (selectedType === 'TASK') {
      showModalAddTask();
      return;
    }
    handleSelectedChannel(selectedType as Channel);
  }, [showModalAddTask, handleSelectedChannel]);

  const [showModalChooseActionType, hideModalChooseActionType] = useModal(() => (
    <CreateReminder
      onDone={onActionDone}
      onSelectAction={handleSelectType}
      onClose={hideModalChooseActionType}
    />
  ), [handleSelectType, onActionDone]);

  return (
    <ButtonWithUserRights
      action="CREATE"
      category={RightsCategory.PREFERENCES_PLANS}
      variant="link"
      className="PlanAddActionButton"
      onClick={showModalChooseActionType}
    >
      <Icon name="plus-small" /> {t('plans:add-action')}
    </ButtonWithUserRights>
  );
};

export default SettingsPlan;
