import type { AllOperators } from 'types/models';

const getI18nOperatorComparison = (operator: AllOperators): string => {
  const i18nOperators = [
    { name: '==', translation: 'selectors:operators.equals' },
    { name: '<', translation: 'selectors:operators.inferior-to' },
    { name: '<=', translation: 'selectors:operators.strictly-inferior-to' },
    { name: '>', translation: 'selectors:operators.superior-to' },
    { name: '>=', translation: 'selectors:operators.strictly-superior-to' },
    { name: '!=', translation: 'selectors:operators.different-from' },
    { name: 'BEFORE_MONTHS', translation: 'selectors:operators.inferior-to' },
    { name: 'AFTER_MONTHS', translation: 'selectors:operators.superior-to' },
    { name: 'BEFORE_DAYS', translation: 'selectors:operators.inferior-to' },
    { name: 'AFTER_DAYS', translation: 'selectors:operators.superior-to' },
    { name: 'BEFORE_DAYS_OR_EQUAL', translation: 'selectors:operators.strictly-inferior-to' },
    { name: 'AFTER_DAYS_OR_EQUAL', translation: 'selectors:operators.strictly-superior-to' },
    { name: 'NOT_IN', translation: 'selectors:operators.different-from' },
    { name: 'IN', translation: 'selectors:operators.equals' },
    { name: 'FILTER_BY', translation: 'selectors:operators.filter-by' },
  ];

  return i18nOperators.find(({ name }) => name === operator)?.translation || 'common:unknown';
};

export default getI18nOperatorComparison;
