import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { Scenario } from 'types/models';
import type { FetchOneParams } from 'api/scenarios';
import apiScenarios from 'api/scenarios';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch2';
import Modal from 'components/Modal';
import Loading from 'components/Loading';
import Alert from 'components/Alert';
import Actions from './Actions';
import Infos from './Infos';

type Props = {
  scenario: Scenario['id'],
  referenceDate?: string | null,
  referenceDateOrigin?: string | null,
  onClose(): void,
};

const ScenarioDetails = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { scenario, onClose, referenceDate, referenceDateOrigin } = props;
  const { currentOrganization } = organizationStore;

  const {
    data,
    isLoading,
    error,
  } = useFetch<FetchOneParams, Scenario>(
    {
      cacheKey: 'organizationPlanTemplate',
      organization: currentOrganization?.reference,
      id: scenario,
    },
    apiScenarios.one,
  );

  return (
    <Modal
      className="ScenarioDetails"
      title={data?.name ?? ''}
      subTitle={data?.identifier}
      onClose={onClose}
      isOpened
    >
      {isLoading && <Loading />}
      {error && <Alert variant="error">{t('errors:unknown-retry')}</Alert>}
      {data && (
        <Fragment>
          <Infos
            referenceDate={referenceDate}
            referenceDateOrigin={referenceDateOrigin}
          />
          <Actions data={data} />
        </Fragment>
      )}
    </Modal>
  );
};

export default ScenarioDetails;
