import requester from 'utils/requester';
import type { User, Locale } from 'types/models';
import apiLocales from 'api/locales';

const currentUser = async (): Promise<User | null> => {
  const { data } = await requester.get<User>('userprofile');
  return data;
};

const setLanguage = async (userId: User['id'], locale: Locale['id']): Promise<User> => {
  const { data } = await requester.put<User>(
    `users/${userId}`,
    { uiLocale: apiLocales.resourceUrl(locale) },
  );
  return data;
};

const setAvatar = async (userId: User['id'], formData: FormData): Promise<User> => {
  const { data } = await requester.post<User>(
    `users/${userId}/avatar`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return data;
};

export default { currentUser, setLanguage, setAvatar };
