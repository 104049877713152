import './index.scss';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { EavConstructor } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormCheck from 'components/FormCheck';
import FormatForm from './FormatForm';

type Props = {
  errors?: ValidationErrors | null,
  defaultData?: EavConstructor,
};

const AttributesForm = ({ errors, defaultData }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="AddEditAttributesForm">
      <FormFieldset>
        <FormGroup label={t('common:identifier')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="identifier"
            autoComplete="off"
            defaultValue={defaultData?.identifier}
            isInvalid={!!errors?.identifier}
          />
        </FormGroup>
        <FormGroup label={t('common:name')} mandatory error={errors?.label}>
          <FormControl
            type="text"
            name="label"
            autoComplete="off"
            defaultValue={defaultData?.label}
            isInvalid={!!errors?.label}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormatForm
          errors={errors}
          defaultData={defaultData}
        />
        <FormGroup
          className="AddEditAttributesForm__required"
          label={t('attributes:list-headings.required')}
        >
          <FormCheck name="mandatory" defaultChecked={defaultData?.required} />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default AttributesForm;
