import './index.scss';
import PublicLayout from 'layout/PublicLayout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryStringParser from 'query-string';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import apiBoarding from 'api/boarding';
import type ResponseError from 'utils/errors';
import replaceOrAddEmail from './utils';
import type { StoredEmail } from './utils';
import ConfirmEmailForm from './Form';

const ConfirmEmail = (): JSX.Element => {
  const { t } = useTranslation();
  const { search: locationSearch } = useLocation();
  const { email: defaultEmail } = queryStringParser.parse(locationSearch);

  const [email, setEmail] = useState<string>(
    Array.isArray(defaultEmail) ? defaultEmail.join('') : defaultEmail || '',
  );
  const [cooldown, setCooldown] = useState<number>(0);
  const [isAllowedResend, setIsAllowedResend] = useState<boolean>(true);
  const [error, setError] = useState<ResponseError | null>(null);

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const checkIfAllowed = useCallback(() => {
    const storedEmails = JSON.parse(localStorage.getItem('sentConfirmations') || '{}');
    if (!storedEmails || !Array.isArray(storedEmails)) {
      setIsAllowedResend(true);
      return;
    }
    const matchingEmail = storedEmails.find(
      ({ email: storedEmail }: StoredEmail) => storedEmail === email,
    );
    if (!matchingEmail || !matchingEmail.time) {
      setIsAllowedResend(true);
      return;
    }
    const isAllowed = Date.now() > matchingEmail.time + config.CONFIRMATION_EMAIL_COOLDOWN;
    setCooldown(Math.round(
      (config.CONFIRMATION_EMAIL_COOLDOWN - (Date.now() - matchingEmail.time)) / 1000 / 60,
    ));
    setIsAllowedResend(isAllowed);
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    if (!isAllowed) {
      timeout.current = setTimeout(checkIfAllowed, 10 * 1000);
    }
  }, [email]);

  useEffect(() => {
    checkIfAllowed();
  }, [checkIfAllowed, email]);

  useEffect(() => (
    () => {
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    }
  ), []);

  const handleSubmit = useCallback(async () => {
    if (!isAllowedResend || !email) {
      return;
    }
    try {
      await apiBoarding.confirmEmail(email);
    } catch (err) {
      setError(err as ResponseError);
      return;
    }

    const confirmationsSent = JSON.parse(localStorage.getItem('sentConfirmations') || '{}');
    if (!confirmationsSent || !Array.isArray(confirmationsSent)) {
      localStorage.setItem('sentConfirmations', JSON.stringify(
        [{ email, time: Date.now() }],
      ));
    } else {
      localStorage.setItem('sentConfirmations', JSON.stringify(
        replaceOrAddEmail(confirmationsSent, email),
      ));
    }
    checkIfAllowed();
  }, [checkIfAllowed, email, isAllowedResend]);

  return (
    <PublicLayout>
      <div className="ConfirmEmail">
        <div className="ConfirmEmail__form">
          <h2 className="ConfirmEmail__title">{t('confirm-email:title')}</h2>
          <div className="ConfirmEmail__description">
            <p className="ConfirmEmail__description__text">
              {t('confirm-email:please-verify')}
            </p>
            <p className="ConfirmEmail__description__text">
              {t('confirm-email:if-not-received')}
            </p>
          </div>
          <ConfirmEmailForm
            onSubmit={handleSubmit}
            disabled={!isAllowedResend}
            email={email}
            onChange={setEmail}
            cooldown={cooldown}
            error={error}
          />
        </div>
      </div>
    </PublicLayout>
  );
};

export default observer(ConfirmEmail);
