import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import SelectSearch, { fuzzySearch, SelectSearchOption } from 'react-select-search';
import type { User } from 'types/models';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch2';
import useIsMountedRef from 'hooks/useIsMountedRef';
import apiUsers from 'api/users';
import type { FetchAllParams } from 'api/users';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { DefaultValue, getDefaultValue } from './utils';

export type Props = {
  name: string,
  onSelect?(name: string, newValue: string | null): void,
  placeholder?: DefaultTFuncReturn,
  defaultValue?: DefaultValue,
  value?: string | string[] | null,
  entityValue?: 'id' | 'reference',
  disabled?: boolean,
  className?: string,
  isMultiple?: boolean,
  isInvalid?: boolean,
  withClearButton?: boolean,
  onItemFirstOption?(firstOption?: string | null | number): void,
};

const FormSelectManager = (props: Props): JSX.Element => {
  const {
    name,
    onSelect,
    placeholder,
    defaultValue,
    value: controlValue,
    disabled = false,
    className,
    isMultiple = false,
    isInvalid = false,
    withClearButton = true,
    entityValue = 'reference',
    onItemFirstOption,
  } = props;
  const { id } = organizationStore.currentOrganization ?? { id: undefined };
  const isMountedRef = useIsMountedRef();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState<string | string[]>('');
  const [cleared, setCleared] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectSearchOption[] | null>(null);

  const isSearchable = useMemo(() => (
    (options && options.length > 3) || false
  ), [options]);

  const { isLoading, data } = useFetch<FetchAllParams, User[]>(
    { cacheKey: 'managersList', organizationId: id },
    apiUsers.all,
    {
      enabled: !!id,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    if ((!data || data.length === 0) && !isLoading && !options) {
      setOptions([{ name: '', value: '', disabled: true }]);
      return;
    }

    if (!!data) {
      const optionsToSet = data.map(({ id: userId, reference, firstName, lastName }: User) => ({
        name: `${firstName} ${lastName}`,
        value: entityValue === 'reference' ? reference : userId.toString(),
      }));
      setOptions(optionsToSet);
      if (onItemFirstOption) {
        onItemFirstOption(optionsToSet?.[0]?.value);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  useEffect(() => {
    if (defaultValue && value.length === 0) {
      const initValue = getDefaultValue(defaultValue, options || []);
      setValue(initValue);
      if (onSelect) {
        onSelect(name, Array.isArray(initValue) ? initValue.join(',') : initValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  useEffect(() => {
    if (controlValue !== undefined && value !== controlValue) {
      if (cleared) {
        setCleared(false);
      }
      setValue(controlValue === null ? '' : controlValue);
    }
  }, [value, controlValue, cleared]);

  const handleChange = useCallback(
    (selectedValue: string | string[]) => {
      setValue(selectedValue);
      const newValue = (Array.isArray(selectedValue)) ? selectedValue.join(',') : selectedValue;

      if (inputRef?.current) {
        // - Ceci permet de déclencher un onChange au niveau du <form> parent
        const inputValSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        inputValSetter?.call(inputRef.current, newValue);
        inputRef.current.dispatchEvent(
          new Event('change', { bubbles: true }),
        );
      }

      if (onSelect) {
        onSelect(name, newValue);
      }
    },
    [name, onSelect],
  );

  const handleClear = useCallback(() => {
    setCleared(true);
    setValue('');
    if (onSelect) {
      onSelect(name, null);
    }
  }, [name, onSelect]);

  const classNames = classnames('FormSelectManager', className, {
    'FormSelectManager--has-value': withClearButton && value,
    'FormSelectManager--is-loading': isLoading,
    'FormSelectManager--is-invalid': isInvalid,
  });

  return (
    <div
      className={classNames}
    >
      {options === null && (
        <Loading />
      )}
      {options !== null && (
        <SelectSearch
          onChange={handleChange}
          disabled={disabled}
          value={value}
          options={options}
          search={isSearchable}
          placeholder={placeholder ?? undefined}
          multiple={isMultiple}
          printOptions="on-focus"
          filterOptions={fuzzySearch}
        />
      )}
      <input
        type="text"
        value={value}
        name={name}
        ref={inputRef}
        onChange={() => {}}
        className="FormSelectManager__hidden-field"
      />
      {withClearButton && value && (
        <Button
          variant="light"
          onClick={handleClear}
          className="FormSelectManager__clear"
        >
          <Icon name="close-small" />
        </Button>
      )}
    </div>
  );
};

export default observer(FormSelectManager);
