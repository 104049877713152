import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Misc from 'types/misc';
import apiCredits from 'api/credits';
import { CreditType } from 'types/models';
import type { CreditSummary, DebitStatus } from 'types/models';
import type { FetchAllParams } from 'api/credits';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import creditNotesFilters from 'stores/FilterStores/CreditNotesFilters';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import PageList from 'components/PageList';
import FormSelect from 'components/FormSelect';
import DataTable from 'components/DataTable';
import CreditNotesColumns from './Columns';

export type RouteParams = {
  status?: DebitStatus,
  debitRef?: string,
};

const CreditNotes = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentOrganization, selectedBusinessUnitsIdentifier } = organizationStore;
  const { user } = authStore;
  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'paidAt', desc: true },
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<CreditSummary>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
  } = creditNotesFilters;

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return [
        ...filters,
        { name: 'type', value: CreditType.CREDIT_NOTE },
      ];
    }
    return [...filters, { name: 'type', value: CreditType.CREDIT_NOTE }, { name: 'categories', value: selectedBusinessUnitsIdentifier }];
  }, [filters, selectedBusinessUnitsIdentifier]);

  const {
    data,
    serverPagination,
    error,
    isLoading,
  } = useFetchPaginated<FetchAllParams, CreditSummary>(
    {
      cacheKey: 'credits',
      organizationId: currentOrganization?.id,
      params: {
        page: fetchOptions.pageIndex,
        organizationReference: currentOrganization?.reference,
        pageSize: fetchOptions.pageSize,
        locale: user?.locale,
        filtering,
        sort: fetchOptions.sort,
      },
    },
    apiCredits.all,
    { enabled: !!currentOrganization?.id },
  );

  const columns = useMemo<Column<CreditSummary>[]>(
    () => CreditNotesColumns(t),
    [t],
  );

  const handleSelectPaymentRequests = useCallback((name: string, value: string | null) => {
    if (value && value !== '/credit-notes') {
      history.push(value);
    }
  }, [history]);

  return (
    <PageList
      className="CreditNotes"
      count={serverPagination?.totalRecords}
      title={t('credit-notes:title')}
    >
      <DataTable<CreditSummary>
        columns={columns}
        data={data}
        statusSelector={(
          <FormSelect
            selectOptions={[
              { label: t('common:credits'), value: '/credits' },
              { label: t('common:credit-notes'), value: '/credit-notes' },
            ]}
            defaultValue="/credit-notes"
            name="select"
            className="CreditNotes__page-select"
            onSelect={handleSelectPaymentRequests}
            withClearButton={false}
          />
        )}
        serverPagination={serverPagination}
        defaultSortBy={defaultFetchOptions.sort}
        fetchData={setFetchOptions}
        filtering={filters}
        onChangeFilters={addOrUpdateFilters}
        onRemoveFilter={removeFilter}
        isLoading={isLoading}
        noDataFoundMessage={t('debits:no-data')}
        error={error}
        withNoDataDrawing
        withActions={false}
      />
    </PageList>
  );
};

export default observer(CreditNotes);
