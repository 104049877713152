import './index.scss';
import { useTranslation } from 'react-i18next';
import { BusinessUnit } from 'types/models';
import DropdownActions from 'components/DropdownActions';

type Props = {
  perimeter: BusinessUnit[],
  actions?: React.ReactNode[],
};

const Perimeter = ({ perimeter, actions }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="Perimeter">
      <div className="Perimeter__categories">
        {perimeter.map(({ id, name }, index) => (
          <div key={id} className="Perimeter__category">
            <div className="Perimeter__category__operator">{index > 0 && t('common:and')}</div>
            <div className="Perimeter__category__title">{name}</div>
          </div>
        ))}
      </div>
      {actions && (
        <div className="Perimeter__menu">
          <DropdownActions actions={actions} />
        </div>
      )}
    </div>
  );
};

export default Perimeter;
