export enum EavCategory {
  CLIENT = 'client',
  CONTACT = 'contact',
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export type OrganizationEAVs = {
  client: EavConstructor[],
  contact: EavConstructor[],
  debit: EavConstructor[],
  credit: EavConstructor[],
};

export type EavConstructor = {
  id: number,
  label: string,
  identifier: string,
  type: EAVType,
  required: boolean,
  overviewDisplay: boolean,
  position: number | null,
  isRemovable: boolean,
  useHtml?: boolean,
};

export type Eav = {
  identifier: EavConstructor['identifier'],
  value: any,
};

export enum EAVType {
  BOOLEAN = 'eav_attr_boolean',
  STRING = 'eav_attr_text',
  DATE = 'eav_attr_date',
  NUMBER = 'eav_attr_number',
}

export type EAVValue = {
  code: string,
  data: any,
};
