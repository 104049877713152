import { Buffer } from 'buffer';

export const getContentBase64String = (value: string) => {
  const base64EncodedString = value.split(';base64,').pop();
  return Buffer.from(base64EncodedString!, 'base64');
};

export const extractMimeTypeFromBase64 = (base64String: string): string | null => {
  // Regular expression to match the MIME type within the Data URL scheme
  const mimeTypeRegex = /^data:(\w+\/[-+.\w]+);base64,/;
  const match = base64String.match(mimeTypeRegex);

  return match ? match[1] : null;
};

// Function to convert base64 string to a Blob
export const base64ToBlob = (base64: string, mimeType: string): Blob => {
  // Decode the base64 string to binary data
  let byteCharacters = atob(base64.split(',')[1]); // Removing base64 prefix (`data:image/png;base64,`) if present
  let byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    let slice = byteCharacters.slice(offset, offset + 512);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // Create a Blob from the binary data
  let blob = new Blob(byteArrays, { type: mimeType });
  return blob;
};
