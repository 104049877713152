import type { DefaultTFuncReturn } from 'i18next';
import './index.scss';

type Props = {
  label?: DefaultTFuncReturn,
  children: React.ReactNode,
};

const HistoryStepDetailsItem = ({ label, children }: Props): JSX.Element => (
  <dl className="HistoryStepDetailsItem">
    <dt className="HistoryStepDetailsItem__label">
      {label}
    </dt>
    <dd className="HistoryStepDetailsItem__value">
      {children}
    </dd>
  </dl>
);

export default HistoryStepDetailsItem;
