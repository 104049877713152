import './index.scss';
import { uniq } from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import { FetchAllSearchHistoryParams } from 'api/users';
import apiUsers from 'api/users';
import useFetch from 'hooks/useFetch2';
import FilterStore from 'stores/Filter';
import getI18nOperatorFilter from 'utils/getI18nOperatorFilter';
import FilterValue from 'components/DataTable/ActiveFilters/FilterValue';
import Misc from 'types/misc';
import Icon from 'components/Icon';
import Button from 'components/Button';

type Props = {
  excludedFilter?: string[],
  filtersList: Misc.FilterDeclarationItem[],
};
const FilterAdvancedFieldHistory = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { filtersList, excludedFilter } = props;
  const { currentOrganization } = organizationStore;

  const { data } = useFetch<FetchAllSearchHistoryParams, string[]>(
    {
      cacheKey: 'searchHistory',
      organizationReference: currentOrganization?.reference,
    },
    apiUsers.allSearchLog,
    { enabled: !!currentOrganization, retry: false, refetchOnMount: 'always' },
  );

  const getFilterLabel = useCallback((name: string) => {
    const foundFilter = filtersList.find(({ key }) => key === name);
    return foundFilter?.title || name;
  }, [filtersList]);

  const searchHistory = useMemo(() => {
    const currentQueryString = window.location.search.split('?')[1];
    const history = uniq(data).filter((string) => string !== currentQueryString);
    return history?.map((queryString, key) => {
      const filterStore = new FilterStore('searchHistory', false);
      const urlFilters = filterStore.getUrlFilters(`?${queryString}`);

      return (
        <Button
          variant="link"
          key={queryString}
          className="FilterAdvancedFieldHistory__link"
          onClick={() => {
            window.location.href = `${location.pathname}?${queryString}`;
          }}
        >
          <Icon name="search" />
          <div
            className="FilterAdvancedFieldHistory__text"
            key={`${key}-${queryString}`}
          >
            {urlFilters.map(({ name, value, operator }, index) => {
              if (excludedFilter?.includes(name)) {
                return;
              }

              return (
                <Fragment key={index}>
                  <div className="FilterAdvancedFieldHistory__text__part">
                    {getFilterLabel(name)}
                    <span>
                      {operator ? t(getI18nOperatorFilter(operator)) : ':'}
                    </span>
                    <FilterValue
                      name={name}
                      value={value}
                      filtersList={filtersList}
                    />
                  </div>
                  <span>
                    {t('selectors:operators.and')}
                  </span>
                </Fragment>
              );
            })}
          </div>
        </Button>
      );
    });
  }, [data, filtersList, getFilterLabel, excludedFilter, t]);

  if (!data || data.length === 0 || searchHistory.length === 0) {
    return null;
  }

  return (
    <div className="FilterAdvancedFieldHistory">
      <p className="FilterAdvancedField__label">
        {t('common:history')}
      </p>
      {searchHistory}
    </div>
  );
};

export default FilterAdvancedFieldHistory;
