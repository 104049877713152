import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import type { FetchAllParams } from 'api/invitations';
import apiInvitations from 'api/invitations';
import DataTable from 'components/DataTable';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import useFetch from 'hooks/useFetch2';
import organizationStore from 'stores/Organization';
import type { Collection } from 'types/api';
import type { Invitation } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import InvitedRowActions from './RowActions';
import './index.scss';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
  reloadTick: number,
};

const CollaboratorsInvitedTable = ({ onShowToast, reloadTick }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    isLoading,
    data: collaborators,
    refetch,
  } = useFetch<FetchAllParams, Collection<Invitation>>(
    {
      cacheKey: 'organizationCollaborators',
      id: currentOrganization?.id,
    },
    apiInvitations.all,
    { enabled: !!currentOrganization },
  );

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const onActionDone = useCallback((message: string) => {
    refetch();
    onShowToast(message, 'success');
  }, [refetch, onShowToast]);

  const columns: Column<Invitation>[] = [
    {
      Header: t<string>('common:sent-at'),
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className="CollaboratorsInvitedTable__created-at">
          {formatIntlDate(value)}
        </div>
      ),
    },
    {
      Header: t<string>('common:email'),
      accessor: 'email',
      disableSortBy: true,
    },
    {
      Header: t<string>('common:commands'),
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row, value: id }) => (
        <div className="CollaboratorsInvitedTable__actions">
          <InvitedRowActions
            id={id}
            reference={id.toString()}
            name={row.original.email}
            onActionDone={onActionDone}
            onActionError={(message: string) => onShowToast(message, 'error')}
          />
        </div>
      ),
    },
  ];

  if (!currentOrganization) {
    return null;
  }

  return (
    <div className="CollaboratorsInvitedTable">
      {(!collaborators || collaborators['hydra:totalItems'] === 0) && (
        <p>{t('collaborators:no-collaborator-invitations')}</p>
      )}
      {(collaborators && collaborators['hydra:totalItems'] > 0) && (
        <DataTable<Invitation>
          columns={columns}
          data={collaborators['hydra:member']}
          serverPagination={null}
          defaultSortBy={{ id: 'id', desc: false }}
          fetchData={() => { }}
          isLoading={isLoading}
          error={null}
          noHeader
          withActions={false}
        />
      )}
    </div>
  );
};

export default observer(CollaboratorsInvitedTable);
