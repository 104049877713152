import type { Collection } from 'types/api';
import type { Occupation } from 'types/models';
import requester from 'utils/requester';

const all = async (): Promise<Occupation[]> => {
  const { data } = await requester.get<Collection<Occupation>>('occupations');
  return data['hydra:member'];
};

/**
 * Récupère l'URL de la ressource d'API pour mapper une association d'occupation.
 *
 * @param id L'ID de l'occupation
 * @returns URL de l'occupation.
 */
const resourceUrl = (id: string | number) => `/api/occupations/${id}`;

/**
 * Récupère l'URL de la ressource API pour modifier l'occupation.
 *
 * @param id L'ID de l'occupation
 * @returns URL du PUT.
 */
const updateUrl = (id: Occupation['id']) => `occupations/${id}`;

export default { all, resourceUrl, updateUrl };
