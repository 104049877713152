import React from 'react';

export type TooltipData = {
  show: boolean,
  x: number,
  y: number,
  isCentered: boolean,
};

export type TooltipContextType = {
  showTooltip: (x: number, y: number, content: JSX.Element | null, isCentered?: boolean) => void
  hideTooltip: () => void
};

const invariantViolation = () => {
  throw new Error(
    'Attempted to call useTooltip outside of tooltip context. '
    + 'Make sure your app is rendered inside TooltipProvider.',
  );
};

const TooltipContext = React.createContext<TooltipContextType>({
  showTooltip: invariantViolation,
  hideTooltip: invariantViolation,
});

export default TooltipContext;
