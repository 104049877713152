import './index.scss';
import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import Misc from 'types/misc';
import Button from 'components/Button';
import FilterAdvancedFieldHistory from './History';
import FilterAdvancedFieldItem from './Items';

type Props = {
  filters: Misc.FilterDeclarationItem[],
  currentFilters: Misc.Filter[],
  excludedFilter?: string[],
  onChange: (filter: Misc.Filter) => void,
  getFilterValue: (filterKey: string) => string | string[] | null,
};

const FilterAdvancedField = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { filters, getFilterValue, currentFilters, onChange, excludedFilter } = props;
  const [items, setItems] = useState<Misc.Filter[]>(
    !!currentFilters.length ? currentFilters : [{ id: nanoid(10), name: '', value: null }],
  );

  const handleItemChange = useCallback((id: string, value: Misc.Filter) => {
    const newValue = { id, ...value };
    onChange(newValue);
    setItems((prev) => prev.map((item) => item.id === id ? newValue : item));
  }, [onChange]);

  const handleAddItem = useCallback(() => {
    setItems((prev) => [
      ...prev, { id: nanoid(10), name: '', value: null },
    ]);
  }, []);

  const handleRemoveItem = useCallback((filterToRemove: Misc.Filter) => {
    onChange({ ...filterToRemove, value: null });
    setItems((prev) => prev.filter((item) => item.id !== filterToRemove.id));
  }, [onChange]);

  return (
    <div className="FilterAdvancedField">
      <p className="FilterAdvancedField__label">
        {t('common:criteria')}
      </p>

      {items.map((value, index) => (
        <Fragment key={`${value.name}-${value.id}`}>
          <FilterAdvancedFieldItem
            filters={filters}
            defaultValue={value}
            removable={items.length > 1}
            onRemove={() => handleRemoveItem(value)}
            onChange={(newValue) => handleItemChange(value.id!, newValue)}
            getFilterValue={getFilterValue}
          />
          {index !== items.length - 1 && (
            <p className="FilterAdvancedField__and">{t('selectors:operators.and')}</p>
          )}
        </Fragment>
      ))}

      <Button variant="link" className="FilterAdvancedField__add" onClick={handleAddItem}>
        {t('common:add-criterion')}
      </Button>
      <FilterAdvancedFieldHistory
        filtersList={filters}
        excludedFilter={excludedFilter}
      />
    </div>
  );
};

export default FilterAdvancedField;
