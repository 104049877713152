const parseIsoDate = (rawValue: string | null | undefined): Date | null => {
  if (!rawValue) {
    return null;
  }

  let value = rawValue;
  if (typeof value !== 'string') {
    value = rawValue.toString();
  }

  const [dateString, hourString] = value.includes('T') ? value.split('T') : value.split(' ');
  if (dateString.length !== 10 || !dateString.includes('-')) {
    return null;
  }

  const [yearString, monthString, dayString] = dateString.split('-');
  const [year, month, day] = [Number(yearString), Number(monthString), Number(dayString)];
  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return null;
  }

  const [hoursString, minutesString, secondsString] = hourString ? hourString.split(':') : ['00', '00', '00'];
  let hours = Number(hoursString);
  if (Number.isNaN(hours)) {
    hours = 0;
  }

  let minutes = Number(minutesString);
  if (Number.isNaN(minutes)) {
    minutes = 0;
  }

  let seconds = Number(secondsString?.substring(0, 2));
  if (Number.isNaN(seconds)) {
    seconds = 0;
  }

  return new Date(year, month - 1, day, hours, minutes, seconds);
};

export default parseIsoDate;
