import { useMemo } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import formatIntlDate from 'utils/formatIntlDate';
import { SelectorsRefDate, SelectorsRefDateEAV } from 'types/models';
import { getReferenceDate } from 'pages/Settings/Selectors/Columns/utils';

type Props = {
  referenceDate?: string | null,
  referenceDateOrigin?: string | null,
};

const ScenarioDetailsInfos = ({ referenceDateOrigin, referenceDate }: Props): JSX.Element => {
  const { t } = useTranslation();

  const calculationMethod = useMemo(() => {
    if (!referenceDateOrigin ) {
      return null;
    }
    const { attribute } = getReferenceDate(referenceDateOrigin);

    let attributeText;
    if (SelectorsRefDateEAV.hasOwnProperty(attribute)) {
      attributeText = SelectorsRefDateEAV[attribute as keyof typeof SelectorsRefDateEAV];
    } else {
      attributeText = SelectorsRefDate[attribute as keyof typeof SelectorsRefDate];
    }

    return attributeText && t(`selectors:selector-types.${attributeText}`);
  }, [referenceDateOrigin, t]);

  return (
    <div className="ScenarioDetailsInfos">
      <div className="ScenarioDetailsInfos__group">
        <h4 className="ScenarioDetailsInfos__group__title">
          {t('common:reference')}
        </h4>
        <div className="ScenarioDetailsInfos__group__container">
          {calculationMethod}
          {calculationMethod && referenceDate && ' - '}
          {referenceDate && formatIntlDate(referenceDate, 'dateLong')}
        </div>
      </div>
    </div>
  );
};

export default ScenarioDetailsInfos;
