import './index.scss';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import classnames from 'classnames';

type Props = {
  helper: string | JSX.Element | null | undefined,
  helperPosition?: 'left' | 'right',
  className?: string,
  isInvalid?: boolean,
  children: ReactNode,
};

const InputGroup = (props: Props): JSX.Element => {
  const {
    helper,
    helperPosition = 'left',
    className,
    isInvalid = false,
    children,
  } = props;

  const classNames = useMemo(() => (
    classnames('InputGroup', className, {
      'InputGroup--helper-left': !!helper && helperPosition === 'left',
      'InputGroup--helper-right': !!helper && helperPosition === 'right',
      'InputGroup--not-valid': isInvalid,
    })
  ), [className, helper, helperPosition, isInvalid]);

  const Helper = helper ? (
    <div className="InputGroup__helper">
      {helper}
    </div>
  ) : null;

  return (
    <div className={classNames}>
      {helperPosition === 'left' && Helper}
      {children}
      {helperPosition === 'right' && Helper}
    </div>
  );
};

export default InputGroup;
