import './index.scss';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import type { FetchAllParams, SearchResultsData } from 'api/globalSearch';
import fetchAll from 'api/globalSearch';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch2';
import organizationStore from 'stores/Organization';
import ErrorMessage from 'components/ErrorMessage';
import authStore from 'stores/Auth';
import ResultClients from './Clients';

type Props = {
  search: string,
  onLoading(loading: boolean): void,
  onEmptySearchChange(isEmpty: boolean): void,
};

const HeaderSearchResults = (props: Props): JSX.Element => {
  const { onEmptySearchChange, onLoading, search } = props;
  const { currentOrganization, selectedBusinessUnitsIdentifier } = organizationStore;
  const { type } = currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const { user } = authStore;

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return [];
    }
    return [{ name: 'categories', value: selectedBusinessUnitsIdentifier }];
  }, [selectedBusinessUnitsIdentifier]);

  const {
    data: searchResults,
    isLoading,
    error,
  } = useFetch<FetchAllParams, SearchResultsData>({
    cacheKey: 'globalSearch',
    organizationId: currentOrganization?.id,
    organizationReference: currentOrganization?.reference,
    locale: user?.locale,
    searchTerm: search,
    filtering,
  }, fetchAll, {
    enabled: !!currentOrganization,
    retry: false,
  });

  const isEmptySearchResults = useMemo(() => (
    (!error && (
      !searchResults
      || (
        searchResults.clients.length === 0
        // FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle.
        /* && searchResults.debits.length === 0
        && searchResults.credits.length === 0
        && searchResults.contacts.length === 0 */
      )
    ))
  ), [error, searchResults]);

  useEffect(() => {
    onLoading(isLoading);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    onEmptySearchChange(isEmptySearchResults);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptySearchResults]);

  return (
    <div className="HeaderSearchResults">
      {isEmptySearchResults && (
        <p className="HeaderSearchResults__no-result">{t('common:no-result')}.</p>
      )}
      {error && <ErrorMessage error={error} />}
      {searchResults && searchResults.clients.length > 0 && (
        <div className="HeaderSearchResults__clients">
          <h3 className="HeaderSearchResults__title">
            {ct('common:customers')}
            <span className="HeaderSearchResults__count">
              ({searchResults.clientsCount})
            </span>
          </h3>
          <ResultClients data={searchResults.clients} search={search} />
        </div>
      )}
      {/* FIXME: Mis en commentaire pour le moment car il n'est pas nécessaire dans l'implémentation actuelle. */}
      {/* {searchResults && searchResults.contacts.length > 0 && (
        <div className="HeaderSearchResults__contacts">
          <h3 className="HeaderSearchResults__title">
            {ct('common:contacts')}
            <span className="HeaderSearchResults__count">
              ({searchResults.contactsCount})
            </span>
          </h3>
          <ResultContacts data={searchResults.contacts} search={search} />
        </div>
      )}
      {searchResults && searchResults.debits.length > 0 && (
        <div className="HeaderSearchResults__pay-requests">
          <h3 className="HeaderSearchResults__title">
            {ct('common:bills')}
            <span className="HeaderSearchResults__count">
              ({searchResults.debitsCount})
            </span>
          </h3>
          <ResultDebits data={searchResults.debits} search={search} />
        </div>
      )}
      {searchResults && searchResults.credits.length > 0 && (
        <div className="HeaderSearchResults__pay-requests">
          <h3 className="HeaderSearchResults__title">
            {ct('common:credits')}
            <span className="HeaderSearchResults__count">
              ({searchResults.creditsCount})
            </span>
          </h3>
          <ResultCredits data={searchResults.credits} search={search} />
        </div>
      )} */}
    </div>
  );
};

export default observer(HeaderSearchResults);
