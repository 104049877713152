import './index.scss';
import type { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  title?: string | null,
  filter?: React.ReactElement,
  action?: React.ReactElement,
  secondaryAction?: React.ReactElement,
  className?: string,
  children: ReactNode,
};

const SettingsSection = (props: Props): JSX.Element => {
  const { title, filter, action, secondaryAction, children, className } = props;
  const sectionClassName = classNames('SettingsSection', className);

  return (
    <div className={sectionClassName}>
      <div className="SettingsSection__header">
        <h2 className="SettingsSection__header__title">
          {title}
          {filter}
        </h2>
        <div className="SettingsSection__header__link-manage">
          {action}
        </div>
        <div className="SettingsSection__header__link-manage">
          {secondaryAction}
        </div>
      </div>
      <div className="SettingsSection__infos">
        {children}
      </div>
    </div>
  );
};

export default SettingsSection;
