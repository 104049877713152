import './index.scss';
import { observer } from 'mobx-react';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import apiScenarios from 'api/scenarios';
import Badge from 'components/Badge';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import StepActionIcon from 'components/StepActionIcon';
import { UserRightsController } from 'components/UserRights';
import useApiRequest from 'hooks/useApiRequest';
import getI18nChannel from 'utils/getI18nChannel';
import type Misc from 'types/misc';
import type { Scenario, ScenarioTemplateReminder, ScenarioTemplateTask } from 'types/models';
import { Channel, PhaseId, RightsCategory, StepActionEntity } from 'types/models';
import ModalAddAction from '../../../ModalAddAction';
import ModalAddTask from '../../../ModalAddTask';
import ScenarioDetailsActionCollapse from './Details';
import ScenarioDetailsActionPopover from './Popover';

type Props = {
  index?: number,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  setActiveAction(code?: string | null, type?: string): void,
  action: ScenarioTemplateReminder | ScenarioTemplateTask,
  type: Misc.ActionType,
  scenario: Scenario,
  activePopover: string | null
};

const ScenarioDetailsAction = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [openAction, setOpenAction] = useState<boolean>(false);
  const { put, isLoading } = useApiRequest();
  const {
    index,
    action,
    type,
    scenario,
    onActionError,
    onActionDone,
    setActiveAction,
    activePopover,
  } = props;

  const isTask = type.toUpperCase() === 'TASK';

  const handleToggleValidation = useCallback(async () => {
    if (!action) {
      return;
    }

    const result = await put(apiScenarios.updateUrl(scenario.id), {
      reminders: apiScenarios.buildPayloadListData(
        scenario.reminders,
        index || 0,
        'edit',
        {
          ...action,
          withValidation: !action.withValidation,
        },
      ),
    });

    if (!result) {
      onActionError(t('plans:toast.error.edit-action'));
      return;
    }
    setShowDeleteConfirm(false);
    onActionDone(t('plans:toast.success.edited-action', { name: action.name }));
  }, [action, scenario.reminders, scenario.id, index, put, onActionDone, t, onActionError]);

  const handleDeleteConfirm = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const handleDuplicateAction = useCallback(async () => {
    if (!action) {
      return;
    }

    const { code, ...cloned } = action;
    let result;
    if (isTask) {
      result = await put(apiScenarios.updateUrl(scenario.id), {
        tasks: apiScenarios.buildPayloadListData(scenario.tasks, index || 0, 'create', cloned),
      });
    } else {
      result = await put(apiScenarios.updateUrl(scenario.id), {
        reminders: apiScenarios.buildPayloadListData(scenario.reminders, index || 0, 'create', cloned),
      });
    }

    if (!result) {
      onActionError(t('plans:toast.error.add-action'));
      return;
    }
    setShowDeleteConfirm(false);
    onActionDone(t('plans:toast.success.added-action', { name: action.name }));
  }, [action, scenario.tasks, scenario.reminders, scenario.id, index, put, onActionDone, t, onActionError, isTask]);

  const handleSubmitDelete = useCallback(async () => {
    if (!action) {
      return;
    }

    let result;
    if (isTask) {
      result = await put(apiScenarios.updateUrl(scenario.id), {
        tasks: apiScenarios.buildPayloadListData(scenario.tasks, index || 0, 'delete'),
      });
    } else {
      result = await put(apiScenarios.updateUrl(scenario.id), {
        reminders: apiScenarios.buildPayloadListData(scenario.reminders, index || 0, 'delete'),
      });
    }

    if (!result) {
      onActionError(t('plans:toast.error.deleted-action'));
      return;
    }
    setShowDeleteConfirm(false);
    onActionDone(t('plans:toast.success.deleted-action', { name: action.name }));
  }, [action, scenario.tasks, scenario.reminders, scenario.id, index, put, onActionDone, t, onActionError, isTask]);

  const [showModalAddAction, hideModalAddAction] = useModal(() => (
    <ModalAddAction
      index={index}
      onActionDone={onActionDone}
      onActionError={onActionError}
      onClose={hideModalAddAction}
      onDelete={handleDeleteConfirm}
      channel={action.channel || Channel.EMAIL}
      action={{ ...action, type }}
      scenario={scenario}
      edit
    />
  ), [action, index, onActionDone, onActionError, scenario, type, handleDeleteConfirm]);

  const [showModalAddTask, hideModalAddTask] = useModal(() => (
    <ModalAddTask
      index={index}
      onActionDone={onActionDone}
      onActionError={onActionError}
      onClose={hideModalAddTask}
      onDelete={handleDeleteConfirm}
      task={{ ...action, type }}
      scenario={scenario}
      edit
    />
  ), [action, index, onActionDone, onActionError, type, scenario, handleDeleteConfirm]);

  const handleShowModal = useCallback(() => {
    if (isTask) {
      showModalAddTask();
      return;
    }

    showModalAddAction();
  }, [showModalAddAction, showModalAddTask, isTask]);

  const phase = useMemo(() => {
    return action.day < -15 ? PhaseId.UPCOMING
      : action.day <= -5 ? PhaseId.PENDING
        : action.day < 0 ? PhaseId.ALMOST_DUE
          : action.day <= 15 ? PhaseId.DUE
            : action.day <= 30 ? PhaseId.OVERDUE
              : action.day <= 60 ? PhaseId.LATE
                : PhaseId.DEFAULT;
  }, [action.day]);

  return (
    <div className="ScenarioDetailsAction">
      <div className="ScenarioDetailsAction__offset">
        <div onClick={() => setActiveAction(action.code, 'offset')}>
          <Badge variant={phase}>
            {`J${action.day < 0 ? '-' : '+'}${Math.abs(action.day || 0)}`}
          </Badge>
        </div>
        {activePopover === 'offset' &&
          <ScenarioDetailsActionPopover
            fieldset='offset'
            index={index}
            action={action}
            type={type}
            scenario={scenario}
            setActiveAction={setActiveAction}
            onActionDone={onActionDone}
            onActionError={onActionError}
          />
        }
      </div>

      <div className="ScenarioDetailsAction__line">
        {/* Icone */}
        <div
          className="ScenarioDetailsAction__icon"
          onClick={handleShowModal}
        >
          <StepActionIcon
            entity={isTask ? StepActionEntity.TASK : StepActionEntity.REMINDER}
            channel={action.channel || Channel.EMAIL}
          />
        </div>

        {/* Titre */}
        <div className="ScenarioDetailsAction__header">
          <div className="ScenarioDetailsAction__header__title">
            <span
              onClick={() => setActiveAction(action.code, 'title')}
              className="ScenarioDetailsAction__header__title__name"
            >
              {action.name}
            </span>
            {activePopover === 'title' &&
              <ScenarioDetailsActionPopover
                fieldset='title'
                index={index}
                action={action}
                type={type}
                scenario={scenario}
                setActiveAction={setActiveAction}
                onActionDone={onActionDone}
                onActionError={onActionError}
              />
            }
          </div>
          <Badge variant="NOTE">
            {isTask ? t('common:task') : t(getI18nChannel(action?.channel!))}
          </Badge>
        </div>

        {/* Boutons */}
        <div className="ScenarioDetailsAction__buttons">
          <UserRightsController
            action="UPDATE"
            category={RightsCategory.PREFERENCES_PLANS}
          >
            <Fragment>
              {/* A valider */}
              {!isTask &&
                <Button
                  small
                  data-title={t(`plans:${action.withValidation ? 'need-validation' : 'without-validation'}`)}
                  variant={action.withValidation ? 'outline-primary' : 'outline'}
                  onClick={handleToggleValidation}
                  className="ScenarioDetailsAction__button"
                >
                  <Icon name="check" />
                </Button>
              }

              {/* Répétition */}
              <div className="ScenarioDetailsAction__buttons__with-popup">
                <Button
                  small
                  variant={action.repeat ? 'outline-success' : 'outline'}
                  data-title={t('plans:repetitive-action')}
                  className="ScenarioDetailsAction__button"
                  onClick={() => setActiveAction(action.code, 'repeat')}
                >
                  <Icon name="repeat-action" />
                </Button>

                {activePopover === 'repeat' &&
                  <ScenarioDetailsActionPopover
                    fieldset='repeat'
                    index={index}
                    action={action}
                    type={type}
                    scenario={scenario}
                    setActiveAction={setActiveAction}
                    onActionDone={onActionDone}
                    onActionError={onActionError}
                  />
                }
              </div>

              {/* Dupliquer */}
              <Button
                small
                variant="outline"
                data-title={t('plans:duplicate-action')}
                className="ScenarioDetailsAction__button"
                onClick={handleDuplicateAction}
              >
                <Icon name="clone" />
              </Button>

              {/* Supprimer */}
              <Button
                small
                variant="outline"
                data-title={t('plans:delete-action')}
                className="ScenarioDetailsAction__button ScenarioDetailsAction__button--delete"
                onClick={handleDeleteConfirm}
              >
                <Icon name="delete" />
              </Button>

              {/* Ouvrir la modale */}
              <Button
                small
                variant="outline"
                data-title={t('common:edit')}
                className="ScenarioDetailsAction__button"
                onClick={handleShowModal}
              >
                <Icon name="edit" />
              </Button>
            </Fragment>
          </UserRightsController>

          {/* Voir détails */}
          <Button
            variant="light"
            onClick={() => setOpenAction(!openAction)}
            className="ScenarioDetailsAction__collapse-toggle"
            aria-expanded={openAction}
          >
            {!openAction ? <Icon name="caret-down" /> : <Icon name="caret-up" />}
          </Button>
        </div>

        {/* Détails */}
        {openAction && (
          <ScenarioDetailsActionCollapse
            type={type}
            onShowEdit={handleShowModal}
            action={action as ScenarioTemplateReminder}
            scenario={scenario}
          />
        )}

        {isLoading && <Loading isOverlay />}
        <Confirm
          titleModal={t('common:remove')}
          entityName={action?.name}
          text={t('plans:confirm-remove-action')}
          variant="danger"
          confirmButtonText={t('common:remove')}
          cancelButtonText={t('common:cancel')}
          isShow={showDeleteConfirm}
          onConfirm={handleSubmitDelete}
          onCancel={() => { setShowDeleteConfirm(false); }}
        />
      </div>
    </div>
  );
};

export default observer(ScenarioDetailsAction);
