import type { Collection } from 'types/api';
import type Misc from 'types/misc';
import type {
  Occupation,
  Organization,
  OrganizationStats,
  StepEvent,
  EavConstructor,
  Client,
  OrganizationAnalytics,
  AnalyticsType,
  OrganizationPeriodicTasks,
} from 'types/models';
import Request from 'utils/request';
import requester from 'utils/requester';

export type PeriodicTasksParams = {
  organization: Organization['id'],
};

export type FetchStatsParams = {
  id: Organization['id'] | undefined,
  filtering: Misc.Filter[],
};

export type FetchClientParams = {
  id: Organization['id'] | undefined,
  filtering: Misc.Filter[],
  limit?: number,
};

export type FetchOrganizationHistoryParams = {
  organization: string | undefined,
  fetchOptions: Misc.PaginatedFetchArgs<StepEvent>,
};

export type FetchOrganizationLastHistoryParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
};

export type FetchOrganizationCountHistoryParams = {
  organization: string | undefined,
};

export type ResourceAttribute = 'organization' | 'client' | 'contact' | 'debit' | 'credit' | 'imputation';

/**
 * Récupère les données d'une organization.
 *
 * @param params Un objet contenant l'ID de l'organization à récupérer.
 * @returns Les données d'une organization.
 */
const getOne = async (id: Organization['id']) => {
  const { data } = await requester.get<Organization>(`organizations/${id}`);
  return data;
};

/**
 * URL de la ressource d'API pour la création d'une organization (POST).
 */
const createUrl = 'organizations';

/**
 * Récupère l'URL de la ressource d'API pour modifier l'organization.
 *
 * @param id L'ID de l'organization
 * @returns URL du PUT.
 */
const updateUrl = (id: Organization['id']) => `organizations/${id}`;

/**
 * Récupère la configuration des envois.
 *
 * @param params Paramètres de la requête (organization ID).
 * @returns le configuration des envois.
 */
const getPeriodicTasks = async ({ organization }: PeriodicTasksParams): Promise<OrganizationPeriodicTasks> => {
  if (!organization) {
    throw new Error('getPeriodicTasks: Missing organization ID.');
  }

  const { data } = await requester.get<OrganizationPeriodicTasks>(`organizations/${organization}/periodic_task`);
  return data;
};

/**
 * Récupère l'URL de la ressource d'API pour personnalisation des Envois.
 *
 * @param id L'ID de l'organization
 * @returns URL du PUT.
 */
const updatePeriodicTasksUrl = (id: Organization['id']) => `organizations/${id}/periodic-tasks`;

/**
 * Récupère l'URL de la ressource d'API pour l'envoi immédiat.
 *
 * @param id L'ID de l'organization
 * @returns URL du PUT.
 */
const executePeriodicTasks = (id: Organization['id']) => `organizations/${id}/execute_periodic_tasks`;

/**
 * Récupère l'URL de la ressource API pour le statut de l'envoi immédiat.
 *
 * @param id L'ID de le job
 * @returns URL du GET.
 */
const periodicTasksJobStatus = (id: number) => `job_executions/${id}/healthcheck`;

/**
 * Récupère l'URL de la ressource d'API pour l'indexation.
 *
 * @returns URL du POST.
 */
const executeIndexation = 'organizations/execute_indexation';

/**
 * Récupère l'URL de la ressource d'API pour ajouter un fichier pour l'organization.
 *
 * @param id L'ID de l'organization
 * @returns URL de l'ajout du fichier.
 */
const addFileUrl = (id: Organization['id']) => `organizations/${id}/files`;

const getOccupation = async (id: Organization['id']) => {
  const { data } = await requester.get<Occupation>(`organizations/${id}/occupation`);
  return data;
};

const getAttributes = async (id: Organization['id'], resourceName: ResourceAttribute): Promise<EavConstructor[]> => {
  const { data } = await requester.get<Collection<EavConstructor>>(`organizations/${id}/attributes/${resourceName}?order[sortOrder]=asc`,
  );
  return data['hydra:member'];
};

const getStats = async ({ id, filtering }: FetchStatsParams) => {
  if (!id) {
    throw new Error('GetStats: Missing organization.');
  }

  const queryData = new URLSearchParams();
  queryData.append('withChildren', 'true');

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      if (name === 'businessUnitCodes') {
        queryData.append(
          name,
          (Array.isArray(value) ? value.join(',') : value) || '',
        );
      }
    });
  }

  const { data } = await requester.get<OrganizationStats>(
    `organizations/${id}/stats?${queryData.toString()}`,
  );
  return data;
};

const getAnalytics = async ({ id, filtering }: FetchStatsParams) => {
  if (!id) {
    throw new Error('GetAnalytics: Missing organization.');
  }

  const queryData = new URLSearchParams();
  let type : AnalyticsType;

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      if (name === 'businessUnitCodes') {
        queryData.append(
          name,
          (Array.isArray(value) ? value.join(',') : value) || '',
        );
      }
      if (name === 'type') {
        type = value as AnalyticsType;
      }
    });
  }

  const { data } = await requester.get<OrganizationAnalytics>(
    `organizations/${id}/analytics/${type!}?${queryData.toString()}`,
  );
  return data;
};

const getClientsOrganization = async ({ id, filtering, limit = 10 }: FetchClientParams) => {
  if (!id) {
    throw new Error('GetStats: Missing organization.');
  }

  const queryData = new URLSearchParams();
  queryData.append('limit', limit.toString());

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        name,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const { data } = await requester.get<Collection<Client>>(
    `organizations/${id}/clients?${queryData.toString()}`,
  );
  return data['hydra:member'];
};

export const fetchHistory = async ({ organization, fetchOptions }: FetchOrganizationHistoryParams) => {
  if (!organization) {
    throw new Error('FetchHistory: Missing organization.');
  }

  const { pageIndex, pageSize } = fetchOptions;
  const queryData = new URLSearchParams();
  queryData.append('recordsPerPage', pageSize.toString());
  queryData.append('page', (pageIndex + 1).toString());

  const result = await Request.get<StepEvent[]>(
    `organization/${organization}/events?${queryData.toString()}`,
  );
  return result;
};

export const lastHistory = async ({ organization, filtering }: FetchOrganizationLastHistoryParams) => {
  if (!organization) {
    throw new Error('FetchHistory: Missing organization.');
  }

  const queryData = new URLSearchParams();

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const result = await Request.get<StepEvent[]>(
    `organization/${organization}/last-events`,
  );
  return result;
};

export const countHistory = async ({ organization }: FetchOrganizationCountHistoryParams) => {
  if (!organization) {
    throw new Error('FetchHistory: Missing organization.');
  }

  const result = await Request.get<number>(
    `organization/${organization}/last-events/count`,
  );
  return result;
};

/**
 * Récupère l'URL de la ressource d'API pour mapper une association d'organization.
 *
 * @param id L'ID de l'organization
 * @returns URL de l'association.
 */
const resourceUrl = (id: Organization['id']) => `/api/organizations/${id}`;

export default {
  getOne,
  getOccupation,
  getStats,
  getAnalytics,
  fetchHistory,
  lastHistory,
  countHistory,
  createUrl,
  updateUrl,
  addFileUrl,
  resourceUrl,
  getAttributes,
  getPeriodicTasks,
  executeIndexation,
  executePeriodicTasks,
  periodicTasksJobStatus,
  updatePeriodicTasksUrl,
  getClientsOrganization,
};
