import './index.scss';
import { useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import config from 'config';
import type { ValidationErrors } from 'types/errors';
import type { Client, ReminderPut, StepAction } from 'types/models';
import { Channel, StepActionEntity } from 'types/models';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import StepActionIcon from 'components/StepActionIcon';
import ErrorMessage from 'components/ErrorMessage';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import FormSwitch from 'components/FormSwitch';
import apiHistorySteps from 'api/historySteps';
import apiScenarioGroup from 'api/scenarioGroup';
import ReminderForm from './Form';

type Props = {
  reminder: StepAction,
  customerId?: Client['id'] | null,
  onDone: (title: string, message: string) => void,
  onClose(): void,
};

const ReminderEdit = ({ reminder, onDone, onClose, customerId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [immediateDispatch, setImmediateDispatch] = useState<boolean>(false);
  const { patch, post, isLoading, error } = useApiRequest();

  const roleDefaultValue = useMemo<string[]>(() => {
    const initialContactRoles = reminder.contacts?.map(({ contactRole }) => contactRole) || [];

    if (!reminder.missingContactRoles) {
      return initialContactRoles;
    }

    const initialContactMissingRoles = reminder.missingContactRoles?.map((role) => role) || [];

    return [...initialContactRoles, ...initialContactMissingRoles];
  }, [reminder]);

  const initialData = useRef<ReminderPut>({
    startAt: reminder.at?.at,
    name: reminder.name,
    description: reminder.description,
    subject: reminder.subject || '',
    content: reminder.content || '',
    roles: roleDefaultValue.map((role) => ({ identifier: role })),
    withValidation: reminder.withValidation ?? false,
    static: reminder.isStatic ?? false,
    group: reminder.scenarioGroup ? String(reminder.scenarioGroup.id) : undefined,
    type: StepActionEntity.REMINDER,
  });

  const mapFormData = useCallback((rawData: ModalFormData): ReminderPut | null => {
    if (!currentOrganization) {
      return null;
    }

    const initValidation = reminder.withValidation && rawData.withValidation !== '1' && reminder.validation !== null;

    const data: ReminderPut = {
      name: rawData.name as string,
      description: rawData.description as string,
      subject: reminder.channel === Channel.EMAIL ? rawData.subject as string : '',
      content: rawData.content as string,
      roles: (rawData.roles as string).split(',').map((role) => ({ identifier: role })),
      group: rawData.group && rawData.group !== '0' ? apiScenarioGroup.resourceUrl(parseInt(rawData.group as string, 10)) : undefined,
      withValidation: immediateDispatch ? false : (rawData.withValidation === '1' || reminder.withValidation),
      static: immediateDispatch ? false : rawData.static === '1',
      type: StepActionEntity.REMINDER,
      ...( initValidation && { status: 'pending' }),
    };

    if (rawData.withMove === 'move') {
      data.nbPropagationDay = Number(rawData.daysShift);
    } else {
      data.startAt = rawData.startAt as string;
    }

    return data;
  }, [currentOrganization, reminder, immediateDispatch]);

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    const newData = formData ? mapFormData(formData) : null;
    setHasChanges(!equal(initialData.current, newData));
  }, [initialData, mapFormData]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    setValidationErrors(null);

    const roles = formData.roles as string;
    const content = formData.content as string;
    const title = formData.name as string;

    if (!title) {
      setValidationErrors({ name: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (roles.length === 0) {
      setValidationErrors({ roles: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (!content) {
      setValidationErrors({ content: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    const result = await patch<StepAction>(apiHistorySteps.updateUrl(reminder.code), data);

    if (!isMountedRef.current) {
      return;
    }

    if (immediateDispatch) {
      await post<StepAction>(apiHistorySteps.sendReminder(reminder.code), {});
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result === null) {
      return;
    }

    closeSelf();
    const { name } = result;
    onDone(
      t('reminders:actions.toast.edited'),
      t('reminders:actions.toast.edited-name', { name }),
    );
  }, [
    currentOrganization,
    immediateDispatch,
    patch,
    post,
    mapFormData,
    isMountedRef,
    onDone,
    closeSelf,
    reminder,
    t,
  ]);

  return (
    <ModalForm
      isOpened
      className="ReminderEdit"
      title={t('reminders:actions.edit')}
      subTitle={reminder.name ?? ''}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      icon={<StepActionIcon entity={StepActionEntity.REMINDER} channel={reminder?.channel || Channel.EMAIL} />}
      helpHeaderLink={config.FRESHDESK_ACTIONS_FORMS}
      footerActions={
        <FormSwitch
          className="CreateReminderEdit__ImmediateDispatch"
          name="ImmediateDispatch"
          onChange={(value) => setImmediateDispatch(value)}
          label={t('customization:list-sending.send-now')}
        />
      }
    >
      {error && (<ErrorMessage error={error} />)}
      <ReminderForm
        defaultData={reminder}
        errors={validationErrors}
        immediateDispatch={immediateDispatch}
        customerId={customerId}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ReminderEdit);
