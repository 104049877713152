import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import type { Credit, Currency, ImputationIdentifiers } from 'types/models';
import { CreditType, PaymentMethod } from 'types/models';
import apiCredits from 'api/credits';
import apiOrganization from 'api/organization';
import apiUsers from 'api/users';
import authStore from 'stores/Auth';
import apiCurrencies from 'api/currencies';
import apiDebits from 'api/debits';
import apiClients from 'api/clients';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import Button from 'components/Button';
import Icon from 'components/Icon';
import DebitsRowActionCreditNoteForm from './Form';

type Props = {
  id: number,
  reference: string,
  customerId: number,
  currency: Currency | undefined,
  onLoadingChange(isLoading: boolean): void,
  onDone(title: string, message: string): void,
};

const DebitsRowActionCreditNote = (props: Props): JSX.Element => {
  const { id, customerId, reference, currency, onLoadingChange, onDone } = props;
  const { id: organizationId, type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const isMountedRef = useIsMountedRef();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const { post, error, isLoading } = useApiRequest();
  const { user } = authStore;

  const mapFormData = useCallback((rawData: ModalFormData) => ({
    identifier: rawData.reference as string,
    amount: rawData.amount as string,
    organization: apiOrganization.resourceUrl(organizationId),
    createdBy: apiUsers.resourceUrl(user!.id),
    currency: apiCurrencies.resourceUrl(parseInt(rawData.currency as string)),
    client: apiClients.resourceUrl(customerId),
  }), [organizationId, user, customerId]);

  const mapCreditData = useCallback((rawData: ModalFormData) => ({
    ...(mapFormData(rawData)),
    type: CreditType.CREDIT_NOTE,
    label: rawData.mean as string,
    paidAt: (new Date()).toISOString().split('T')[0],
    paymentMethod: PaymentMethod.DIRECT_DEBIT,
  }), [mapFormData]);

  const mapImputationData = useCallback((rawData: ModalFormData, creditId: number) => ({
    ...(mapFormData(rawData)),
    credit: apiCredits.resourceUrl(creditId),
    debit: apiDebits.resourceUrl(id),
  }), [id, mapFormData]);

  const handleInputAction = useCallback(
    () => { setIsOpened(true); },
    [],
  );

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData) {
        return;
      }

      const data = mapCreditData(formData);
      if (!data) {
        return;
      }

      const result = await post<Credit>(apiCredits.createUrl, data);

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (!result?.id) {
        return;
      }

      const imputationData = mapImputationData(formData, result?.id);
      const imputation = await post<ImputationIdentifiers>(apiCredits.createImputationUrl, imputationData);
      if (!imputation?.id) {
        return;
      }

      setIsOpened(false);
      onDone(t('debits:credit-note-added'), ct('debits:actions.toast.credit-note-added', { reference }));
    },
    [
      post,
      onDone,
      t,
      ct,
      reference,
      isMountedRef,
      mapCreditData,
      mapImputationData,
    ],
  );

  const handleCancel = useCallback(
    () => { setIsOpened(false); },
    [],
  );

  useEffect(
    () => { onLoadingChange(isLoading); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  return (
    <>
      <Button
        variant="list"
        onClick={handleInputAction}
        className="DebitsRowActionCreditNote"
      >
        <Icon name="bookmark" />
        {t('debits:add-credit-note')}
      </Button>
      <ModalForm
        isOpened={isOpened}
        onSave={handleSubmit}
        onCancel={handleCancel}
        title={t('debits:add-credit-note')}
        isLoading={isLoading}
      >
        <DebitsRowActionCreditNoteForm
          currency={currency}
          validationErrors={validationErrors}
          error={error}
        />
      </ModalForm>
    </>
  );
};

export default observer(DebitsRowActionCreditNote);
