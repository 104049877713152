import './index.scss';
import classnames from 'classnames';
import DatePicker from 'react-date-picker';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEavValueFormattedForForm } from 'utils/getCustomerEavs';
import type { ValidationErrorItem } from 'types/errors';
import type { EavConstructor } from 'types/models';
import { EAVType } from 'types/models';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import Icon from 'components/Icon';
import dateFormatIso from 'utils/dateFormatIso';

type Props = {
  eav: EavConstructor,
  defaultValue: any | null,
  error?: ValidationErrorItem | null,
  label?: string,
  onChange?(value: string | null): void,
};

const FormEavItem = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { eav, defaultValue, error, label, onChange } = props;
  const [date, setDate] = useState<Date | [Date, Date] | undefined>();

  useEffect(() => {
    if (!defaultValue) {
      setDate(undefined);
      return;
    }

    if (/^(\d{4}-\d{2}-\d{2})$/.test(defaultValue)) {
      setDate(new Date(defaultValue));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleChange = useCallback(
    (newValue: string | null) => {
      if (onChange && newValue) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const field = useMemo(() => {

    const fieldProps = {
      name: `eavs[${eav.identifier}]`,
      defaultValue: defaultValue !== null ? getEavValueFormattedForForm(eav.type, defaultValue) : null,
      isInvalid: !!error,
    };

    switch (eav.type) {
      case EAVType.BOOLEAN :
        return <FormSelect
          {...fieldProps}
          selectOptions={[
            { value: 'true', label: t('common:yes') },
            { value: 'false', label: t('common:no') },
          ]}
          withClearButton={false}
          onSelect={(_name: string, newValue: string | null) => {
            handleChange(newValue);
          }}
        />;
      case EAVType.STRING :
        return <FormControl
          {...fieldProps}
          type="text"
          autoComplete="off"
          onChange={(newValue: string) => { handleChange(newValue); }}
        />;
      case EAVType.NUMBER :
        return <FormControl
          {...fieldProps}
          type="number"
          step={0.01}
          onChange={(newValue: string) => { handleChange(newValue); }}
        />;
      case EAVType.DATE :
        return <DatePicker
          {...fieldProps}
          value={date}
          onChange={(newDate: Date) => {
            setDate(newDate);
            handleChange(dateFormatIso(newDate));
          }}
          calendarIcon={<Icon name="calendar" />}
          clearIcon={<Icon name="close" />}
        />;
    }
  }, [eav, defaultValue, error, date, t, handleChange]);

  const classNames = classnames('FormEavItem', {
    'FormEavItem--mandatory': eav.required,
  });

  return (
    <FormGroup
      className={classNames}
      label={label ?? eav.label}
      mandatory={eav.required}
      error={error}
    >
      {field}
    </FormGroup>
  );
};

export default FormEavItem;
