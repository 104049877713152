import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';
import Misc from 'types/misc';
import type { ValidationErrors } from 'types/errors';
import type { DataBlock, Debit, StepAction } from 'types/models';
import { Channel, StepActionEntity } from 'types/models';
import config from 'config';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import getI18nChannel from 'utils/getI18nChannel';
import apiScenarioGroup from 'api/scenarioGroup';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import ModalTemplateBlocks from 'components/ModalTemplateBlocks';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { JobExecution } from 'utils/webSocket';
import StepActionIcon from 'components/StepActionIcon';
import Confirm from 'components/Confirm';
import Button from 'components/Button';
import FormSwitch from 'components/FormSwitch';
import apiHistorySteps from 'api/historySteps';
import apiClients, { getCustomerFilter } from 'api/clients';
import apiDebits from 'api/debits';
import ReminderEditForm from './Form';

type Props = {
  filters?: Misc.Filter[],
  totalRecords?: number,
  debitData?: Debit | null,
  customerId?: number | null,
  channel: Channel,
  onDone(message: string): void,
  onResponse?(response: JobExecution): void,
  onClose(): void,
};

const CreateReminderEdit = (props: Props): JSX.Element => {
  const { channel, debitData, customerId, onDone, onClose, onResponse, filters, totalRecords } = props;
  const { t } = useTranslation();
  const { user } = authStore;
  const isMountedRef = useIsMountedRef();
  const { currentOrganization, attributes } = organizationStore;
  const isBulkCreate: boolean = !!filters;
  const [formDataTemp, setFormDataTemp] = useState<ModalFormData | null>();
  const [showBulkConfirm, setShowBulkConfirm] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [immediateDispatch, setImmediateDispatch] = useState<boolean>(false);
  const [model, setModel] = useState<DataBlock | undefined>();
  const { post, isLoading, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const [showModalTemplateBlocks, hideModalTemplateBlocks] = useModal(() => (
    <ModalTemplateBlocks
      type="reminder"
      onClose={hideModalTemplateBlocks}
      onSelect={setModel}
      channel={channel}
    />
  ), [channel]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    setValidationErrors(null);

    const roles = formData.roles as string;
    const content = formData.content as string;
    const title = formData.name as string;

    if (!title) {
      setValidationErrors({ name: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (roles.length === 0) {
      setValidationErrors({ roles: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (!content) {
      setValidationErrors({ content: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (isBulkCreate && !formDataTemp) {
      setFormDataTemp(formData);
      setShowBulkConfirm(true);
      return;
    }

    const data = {
      channel,
      roles: roles.split(',').map((role) => ({ identifier: role })),
      name: formData.name as string,
      description: formData.description as string,
      startAt: formData.startAt as string,
      subject: formData.subject as string,
      content,
      static: immediateDispatch ? false : formData.static === '1',
      withValidation: immediateDispatch ? false : formData.withValidation === '1',
      debit: debitData ? apiDebits.resourceUrl(debitData.id) : undefined,
      group: formData.group ? apiScenarioGroup.resourceUrl(parseInt(formData.group as string, 10)) : undefined,
      client: customerId ? apiClients.resourceUrl(customerId) : undefined,
    };

    let result;
    if (isBulkCreate && onResponse) {
      result = await post<StepAction>(
        apiClients.bulkUrl,
        {
          channel: currentOrganization?.reference,
          locale: user?.locale,
          filters: getCustomerFilter(attributes.client, filters),
          actionType: 'create_reminder',
          actionData: data,
        },
      );
      onResponse(result as unknown as JobExecution);
    } else {
      result = await post<StepAction>(apiHistorySteps.createReminderUrl, data);
    }

    if (!isMountedRef.current) {
      return;
    }

    if (immediateDispatch && result?.code) {
      await post<StepAction>(apiHistorySteps.sendReminder(result.code), {});
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result !== null && !isBulkCreate) {
      const { name } = result;
      onDone(t('reminders:reminder.toast.created', { name }));
    }

    if (result === null) {
      return;
    }

    closeSelf();
  }, [
    channel,
    onResponse,
    debitData,
    customerId,
    immediateDispatch,
    post,
    isMountedRef,
    closeSelf,
    onDone,
    filters,
    isBulkCreate,
    formDataTemp,
    attributes.client,
    currentOrganization?.reference,
    user?.locale,
    t,
  ]);

  const handleBulkConfirm = useCallback(async () => {
    if (formDataTemp) {
      setShowBulkConfirm(false);
      await handleSubmit(formDataTemp);
    }
  }, [handleSubmit, formDataTemp]);

  const handleBulkCancel = useCallback(() => {
    setShowBulkConfirm(false);
    setFormDataTemp(null);
  }, []);

  const title = useMemo(() => (
    t('reminders:reminder-by-channel', { channel: t(getI18nChannel(channel)) })
  ), [t, channel]);

  return (
    <ModalForm
      isOpened
      className="CreateReminderEdit"
      title={title}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      totalRecords={
        totalRecords ? t('clients:total-records', { count: totalRecords }) as string : undefined
      }
      icon={<StepActionIcon entity={StepActionEntity.REMINDER} channel={channel} />}
      headerActions={
        <Button
          variant="link"
          className="CreateReminderEdit__models"
          onClick={showModalTemplateBlocks}>{t('models:import')}
        </Button>
      }
      footerActions={isBulkCreate ? undefined :
        <FormSwitch
          className="CreateReminderEdit__ImmediateDispatch"
          name="ImmediateDispatch"
          onChange={(value) => setImmediateDispatch(value)}
          label={t('customization:list-sending.send-now')}
        />
      }
      helpHeaderLink={config.FRESHDESK_ACTIONS_FORMS}
    >
      <ReminderEditForm
        channel={channel}
        customerId={customerId}
        errors={validationErrors}
        immediateDispatch={immediateDispatch}
        template={model}
      />
      {error && (
        <div className="CreateReminderEdit__error">
          <ErrorMessage error={error} />
        </div>
      )}
      <Confirm
        titleModal={title}
        text={t('clients:actions.confirm.bulk.add-reminder', { count: totalRecords })}
        isShow={showBulkConfirm}
        onConfirm={handleBulkConfirm}
        onCancel={handleBulkCancel}
        variant="danger"
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t<string>('common:close-form')}
        cancelButtonText={t<string>('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default CreateReminderEdit;
