import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import config from 'config';
import SettingsList from 'pages/Settings/List';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch2';
import apiOrganization from 'api/organization';
import type { PeriodicTasksParams } from 'api/organization';
import type { OrganizationPeriodicTasks } from 'types/models';
import HelpLink from 'components/HelpLink';
import SettingsSection from '../../Section';
import SendingActions from './Actions';
import SendingCustomerPortal from './CustomerPortal';
import SendingMode from './SendingMode';
import SendingSchedule from './SendingSchedule';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
};

const Sending = ({ onShowToast }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const {
    data: periodicTasks,
  } = useFetch<PeriodicTasksParams, OrganizationPeriodicTasks>(
    {
      cacheKey: 'OrganizationPeriodicTasks',
      organization: currentOrganization!.id,
    },
    apiOrganization.getPeriodicTasks,
    { enabled: !!currentOrganization!.id },
  );

  const listData = useMemo(() => {
    const {
      sendingEmail,
      letterHead,
      signature,
      signatureEmail,
      signatureLetter,
      sendViewPayer,
    } = currentOrganization!;

    return [
      {
        label: t('customization:list-sending.sending-email'),
        value: sendingEmail || '-',
      },
      {
        label: t('customization:list-sending.letter-head'),
        value: <SendingActions
          data={letterHead}
          genreTranslate="m"
          type="pdf"
          title={t('customization:list-sending.letter-head')}
          name="letterHead"
          onShowToast={onShowToast}
        />,
      },
      {
        label: t('customization:list-sending.customer-portal'),
        value: <SendingCustomerPortal
          data={!!sendViewPayer}
          title={t('customization:list-sending.customer-portal')}
          name="sendViewPayer"
          onShowToast={onShowToast}
        />,
      },
      {
        label: t('customization:list-sending.handwritten-signature'),
        value: <SendingActions
          data={signature}
          type="img"
          title={t('customization:list-sending.handwritten-signature')}
          name="handwrittenSignature"
          onShowToast={onShowToast}
        />,
      },
      {
        label: t('customization:list-sending.letter-signature'),
        value: <SendingActions
          data={signatureLetter}
          type="text"
          title={t('customization:list-sending.letter-signature')}
          name="signatureLetter"
          onShowToast={onShowToast}
        />,
      },
      {
        label: t('customization:list-sending.email-signature'),
        value: <SendingActions
          data={signatureEmail}
          type="text"
          title={t('customization:list-sending.email-signature')}
          name="signatureEmail"
          onShowToast={onShowToast}
        />,
      },
    ];
  }, [currentOrganization, t, onShowToast]);

  const listDataSendingSchedules = useMemo(() => {
    if (!periodicTasks) {
      return [];
    }

    return [
      {
        label: t('customization:list-sending.sending-mode'),
        value: (
          <SendingMode
            timeZone={periodicTasks.timezone}
            defaultValue={periodicTasks.mode!}
            lastExecutedAt={periodicTasks.lastExecutedAt!}
            onShowToast={onShowToast}
          />
        ),
      },
      {
        label: t('customization:list-sending.schedule-email-sms'),
        help: <HelpLink article={config.FRESHDESK_SCHEDULE} />,
        value: (
          <SendingSchedule
            data={periodicTasks}
            onShowToast={onShowToast}
          />
        ),
      },
    ];
  }, [periodicTasks, t, onShowToast]);

  return (
    <SettingsSection title={t('customization:sending')}>
      <div className="Sending__list">
        <div className="Sending__list__items">
          <SettingsList data={listData.slice(0, 3)} />
        </div>
        <div className="Sending__list__items">
          <SettingsList data={listData.slice(3, 6)} />
        </div>
      </div>
      <div className="Sending__list">
        <div className="Sending__list__items">
          <SettingsList data={listDataSendingSchedules} />
        </div>
      </div>
    </SettingsSection>
  );
};

export default observer(Sending);
