import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import FormGroup from 'components/FormGroup';
import FormFieldset from 'components/FormFieldset';
import FormDatePicker from 'components/FormDatePicker';
import FormRadios from 'components/FormRadios';
import FormSelect from 'components/FormSelect';
import organizationStore from 'stores/Organization';
import { FileExportType } from 'types/models';

interface Props {
  errors?: ValidationErrors | null,
}

const DownloadCustomerDetailsForm = ({ errors }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { attributes } = organizationStore;

  const radioItems = useMemo(() => [
    {
      value: FileExportType.PDF,
      label: t('common:export-format.pdf'),
      id: 'pdf',
    },
    {
      value: FileExportType.XLS,
      label: t('common:export-format.xls'),
      id: 'xls',
    },
    {
      value: FileExportType.CSV,
      label: t('common:export-format.csv'),
      id: 'csv',
    },
  ], [t]);

  const attributesOptions = useMemo(() => attributes.client.map((attribute) => ({
    value: attribute.identifier,
    label: attribute.label,
  })), [attributes.client]);

  return (
    <div className="DownloadCustomerDetailsForm">
      <FormFieldset>
        <FormGroup label={t('common:interval-from')} error={errors?.fromDate} mandatory>
          <FormDatePicker
            name="fromDate"
            defaultValue={null}
            isInvalid={!!errors?.fromDate}
          />
        </FormGroup>
        <FormGroup label={t('common:interval-to')} error={errors?.toDate} mandatory>
          <FormDatePicker
            name="toDate"
            defaultValue={null}
            isInvalid={!!errors?.toDate}
          />
        </FormGroup>
      </FormFieldset>
      <FormGroup label={t('common:export-format.title')} error={errors?.exportFormat}>
        <FormRadios
          name="exportFormat"
          items={radioItems}
          defaultValue="pdf"
        />
      </FormGroup>
      <FormGroup label={t('common:attribute-to-include')} error={errors?.attribute}>
        <FormSelect
          name="attribute"
          placeholder={t('common:please-choose')}
          selectOptions={attributesOptions}
          isInvalid={!!errors?.attribute}
          isMultiple
        />
      </FormGroup>
    </div>
  );
};

export default observer(DownloadCustomerDetailsForm);
