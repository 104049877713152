import './index.scss';
import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import type { HistoryStepConvertContent, StepAction } from 'types/models';
import { StepActionEntity } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';
import organizationStore from 'stores/Organization';
import apiHistorySteps from 'api/historySteps';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Button from 'components/Button';
import Alert from 'components/Alert';
import Loading from 'components/Loading';
import HistoryStepDetailsItem from './Item';
import HistoryStepDetailsContacts from './Contacts';
import HistoryStepDetailsDebit from './Debit';
import HistoryStepDetailsHistory from './History';

type Props = {
  data: StepAction,
  onShowEdit(): void,
  onShowReminder(): void,
};

const HistoryStepDetails = ({ data, onShowEdit, onShowReminder }: Props): JSX.Element => {
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [convertContent, setConvertContent] = useState<HistoryStepConvertContent>();
  const { code, isEditable, content } = data;

  useEffect(() => {
    const fetchData = async () => {
      if (!isEditable || !code) {
        return;
      }

      setLoading(true);

      try {
        const response = await apiHistorySteps.fetchConvertContent(code);
        if (response) {
          setConvertContent(response);
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const {
    description,
    channel,
    debit,
    subject,
    doneAt,
    contacts,
    missingContactRoles,
    entity,
  } = data;

  return (
    <div className="HistoryStepDetails">
      {isLoading && <Loading hasNoText isOverlay />}
      {error && (
        <Alert variant="warning">
          {t('reminders:converting-content-error')}
        </Alert>
      )}
      {!isLoading && (
        <Fragment>
          <div className="HistoryStepDetails__infos">
            <HistoryStepDetailsItem label={t('reminders:description')}>
              {convertContent?.description ?? description}
            </HistoryStepDetailsItem>
            {(entity === StepActionEntity.REMINDER && !!channel) && (
              <HistoryStepDetailsItem label={t('reminders:channel')}>
                {t(getI18nChannel(channel))}
              </HistoryStepDetailsItem>
            )}
            {!!debit && (
              <HistoryStepDetailsItem label={ct('reminders:bill', { count: 1 })}>
                <HistoryStepDetailsDebit data={debit} />
              </HistoryStepDetailsItem>
            )}
          </div>
          {(entity === StepActionEntity.TASK && isEditable) && (
            <div className="HistoryStepDetails__actions">
              <Button
                variant="light"
                small
                title={t('common:edit')}
                onClick={onShowEdit}
              >
                {t('common:edit')}
              </Button>
            </div>
          )}
          {entity === StepActionEntity.REMINDER && (
            <Fragment>
              <div className="HistoryStepDetails__infos">
                {(subject || convertContent?.subject) && (
                  <HistoryStepDetailsItem label={t('reminders:subject')}>
                    {convertContent?.subject ?? subject}
                  </HistoryStepDetailsItem>
                )}
                {content && (
                  <Fragment>
                    <HistoryStepDetailsItem label={t('reminders:content')}>
                      {/* eslint-disable-next-line react/no-danger */}
                      <div dangerouslySetInnerHTML={{ __html: convertContent?.content ?? content }} />
                    </HistoryStepDetailsItem>
                    <div className="HistoryStepDetails__actions">
                      {isEditable && (
                        <Button
                          variant="light"
                          small
                          title={t('common:edit')}
                          onClick={onShowEdit}
                        >
                          {t('common:edit')}
                        </Button>
                      )}
                      {(!!contacts && !!contacts[0]) && (
                        <Button variant="light" small onClick={onShowReminder}>
                          {t('common:preview')}
                        </Button>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
              <div className="HistoryStepDetails__infos">
                <HistoryStepDetailsItem
                  label={doneAt ? t('reminders:sent-to') : t('reminders:recipients')}
                >
                  <HistoryStepDetailsContacts
                    contacts={contacts}
                    channel={channel}
                    missingRoles={missingContactRoles}
                    entity={entity}
                    action={doneAt ? 'sent-to' : 'recipients'}
                  />
                </HistoryStepDetailsItem>
                <HistoryStepDetailsItem label={t('reminders:missing-roles')}>
                  <HistoryStepDetailsContacts
                    contacts={contacts}
                    missingRoles={missingContactRoles}
                    channel={channel}
                    entity={entity}
                    action="missing-roles"
                  />
                </HistoryStepDetailsItem>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
      <div className="HistoryStepDetails__history">
        <HistoryStepDetailsItem label={t('common:history')}>
          <HistoryStepDetailsHistory data={data} />
        </HistoryStepDetailsItem>
      </div>
    </div>
  );
};

export default observer(HistoryStepDetails);
