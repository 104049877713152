import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import getI18nTypeImport from 'utils/getI18nTypeImport';
import GoBackButton from 'components/GoBackButton';

interface Props {
  type: string,
  choices: Misc.ImportWizardChoices | null,
  countFiles: number,
}

const ImportsFileWizardStepHeader: React.FC<Props> = ({ type, choices, countFiles }) => {
  const { t } = useTranslation();

  return (
    <div className="ImportsFileWizardStepHeader">
      <div className="ImportsFileWizardStepHeader__wrapper">
        <div className="ImportsFileWizardStepHeader__links">
          <GoBackButton />
        </div>
        <div className="ImportsFileWizardStepHeader__infos">
          <h1 className="ImportsFileWizardStepHeader__infos__name">
            {type === 'csv-xlsx' ? t('imports:wizard.import-csv-xlsx') : t('imports:wizard.import-pdf')}
          </h1>
          <span className="ImportsFileWizardStepHeader__infos__subtitles">
            {choices?.type && (
              <h5 className="ImportsFileWizardStepHeader__infos__subtitles__item">
                {t('imports:type')}: {t(getI18nTypeImport(choices.type))}
              </h5>
            )}
            {countFiles !== 0 && (
              <h5 className="ImportsFileWizardStepHeader__infos__subtitles__item">
                {t('reports:file', { count: countFiles })}
              </h5>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImportsFileWizardStepHeader;
