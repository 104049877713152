import type { HistoryActivity } from 'types/models';
import parseIsoDate from 'utils/parseIsoDate';
import formatIntlDate from 'utils/formatIntlDate';

const getI18nHistoryActivityDate = (
  data: HistoryActivity,
): string => {
  const { loggedAt } = data;

  const getIntlFormat = (dateReference: string) => {
    const yearNow = new Date().getFullYear();
    return parseIsoDate(dateReference)?.getFullYear() !== yearNow ? 'dayMonthYearShort' : 'dayMonthShort';
  };

  return loggedAt ? formatIntlDate(loggedAt, getIntlFormat(loggedAt)) : '';
};

export default getI18nHistoryActivityDate;
