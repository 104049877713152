import './index.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { HelperTextRule } from 'hooks/usePasswordValidator';

type Props = {
  validations: HelperTextRule[],
  valid: boolean,
};

const PasswordHelper = ({ valid, validations }: Props): JSX.Element => {
  const { t } = useTranslation();

  if (valid) {
    return (
      <div className="PasswordHelper">
        <div className="PasswordHelper__feedback-block" role="banner">
          {t('validators:password:all-correct')}
        </div>
      </div>
    );
  }

  return (
    <div className="PasswordHelper">
      <ul className="PasswordHelper__helper-list" role="alert">
        {validations.map((item) => {
          const itemClassNames = classnames('PasswordHelper__helper-text', {
            'PasswordHelper__helper-text--valid': item.valid,
          });
          return (
            <li className={itemClassNames} key={item.id}>
              {item.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PasswordHelper;
