import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import useMountedEffect from 'hooks/useMountedEffect';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import FormControl from 'components/FormControl';
import FormSelectCustomer from 'components/FormSelectCustomer';
import FormSelectScenario from 'components/FormSelectScenario';
import FormSelectScenarioGroup from 'components/FormSelectScenarioGroup';
import FormSwitch from 'components/FormSwitch';
import FormSelectManager from 'components/FormSelectManager';
import FilterDateInput from './DateInput';
import FilterNumberInput from './NumberInput';
import FilterYesNo from './YesNo';
import FilterDateRangeInput from './DateRangeInput';
import FilterAttributes from './Attributes';

type Props = {
  filter: Misc.FilterDeclarationItem,
  currentFilters?: Misc.Filter[],
  currentValue?: Misc.FilterValue,
  getFilterValue(filterName: string): Misc.FilterValue,
  onChange(filter: Misc.Filter): void,
  showLabel?: boolean,
  firstIsDefault?: boolean,
};

const DataTableFilterItem = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { filter, currentFilters, getFilterValue, onChange, showLabel = true, currentValue, firstIsDefault } = props;
  const { key, type, title, emptyText, selectData, isNotRemovable, custom: FilterCustom, entityValue } = filter;
  const [firstOption, setFirstOption] = useState<string | null | number | undefined>();
  const filterAdvanced = 'operators' in filter;

  const defaultValue = useMemo(() => {
    return filterAdvanced ? currentValue as Misc.FilterValue : getFilterValue(key);
  }, [filterAdvanced, currentValue, getFilterValue, key]);

  const [value, setValue] = useState<Misc.FilterValue>(defaultValue);

  useMountedEffect(() => {
    if (filterAdvanced && currentValue) {
      setValue(currentValue);
      return;
    }

    const index = (currentFilters ?? []).findIndex(({ name }) => name === key);
    if (index === -1 || currentFilters![index].value === defaultValue) {
      return;
    }

    setValue(currentFilters![index].value);
  }, [currentFilters]);

  useEffect(() => {
    if (firstIsDefault && !defaultValue && firstOption) {
      const initialValue = firstOption ? firstOption as string : null;
      setValue(initialValue);
      onChange({ name: key, value: initialValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstOption]);

  const handleItemChange = useCallback((name: string, newValue: string[] | string | null) => {
    setValue(newValue);
    onChange({ name, value: newValue });
  }, [onChange]);

  switch (type) {
    case 'select':
      return (
        <FormGroup className="DataTableFiltersItem" label={title}>
          <FormSelect
            name={key}
            // Ici on sait que defaultValue sera toujours une string
            defaultValue={defaultValue as string}
            value={value}
            onSelect={handleItemChange}
            placeholder={emptyText}
            selectOptions={selectData}
            withClearButton={!isNotRemovable}
            onItemFirstOption={setFirstOption}
          />
        </FormGroup>
      );
    case 'yesNoSelect':
      return (
        <FormGroup className="DataTableFiltersItem" label={title}>
          <FormSelect
            name={key}
            defaultValue={defaultValue as string}
            value={value}
            onSelect={handleItemChange}
            placeholder={emptyText}
            onItemFirstOption={setFirstOption}
            selectOptions={[
              { value: 'true', label: t('common:yes') },
              { value: 'false', label: t('common:no') },
            ]}
            withClearButton={!isNotRemovable}
          />
        </FormGroup>
      );
    case 'selectAsync':
      return (
        <FormGroup className="DataTableFiltersItem" label={showLabel ? filter.title : null}>
          <FormSelect
            name={filter.key}
            defaultValue={defaultValue as string}
            value={value}
            onSelect={handleItemChange}
            placeholder={filter.emptyText}
            fetchEntity={filter.fetchEntity}
            isAsync
          />
        </FormGroup>
      );
    case 'selectClient':
      return (
        <FormGroup className="DataTableFiltersItem" label={showLabel ? filter.title : null}>
          <FormSelectCustomer
            name={filter.key}
            defaultValue={filter.defaultValue as string}
            value={value}
            onSelect={handleItemChange}
            placeholder={filter.emptyText}
            withId={entityValue === 'id'}
          />
        </FormGroup>
      );
    case 'selectScenario':
      return (
        <FormGroup className="DataTableFiltersItem" label={showLabel ? filter.title : null}>
          <FormSelectScenario
            name={filter.key}
            defaultValue={filter.defaultValue as string}
            value={value}
            placeholder={filter.emptyText}
            onSelect={handleItemChange}
            entityValue={entityValue}
            onItemFirstOption={setFirstOption}
            withClearButton={!isNotRemovable}
          />
        </FormGroup>
      );
    case 'selectScenarioGroup':
      return (
        <FormGroup className="DataTableFiltersItem" label={showLabel ? filter.title : null}>
          <FormSelectScenarioGroup
            name={filter.key}
            defaultValue={filter.defaultValue as string}
            value={value}
            placeholder={filter.emptyText}
            onSelect={handleItemChange}
            entityValue={entityValue}
            onItemFirstOption={setFirstOption}
            withClearButton={!isNotRemovable}
          />
        </FormGroup>
      );
    case 'selectManager':
      return (
        <FormGroup className="DataTableFiltersItem" label={showLabel ? filter.title : null}>
          <FormSelectManager
            name={filter.key}
            defaultValue={filter.defaultValue as string}
            value={value}
            placeholder={filter.emptyText}
            onSelect={handleItemChange}
            entityValue={entityValue}
            onItemFirstOption={setFirstOption}
            withClearButton={!isNotRemovable}
          />
        </FormGroup>
      );
    case 'dateInput':
      return (
        <FilterDateInput
          filter={filter}
          defaultValue={defaultValue as string}
          value={value?.toString() ?? ''}
          onChange={handleItemChange}
          className="DataTableFiltersItem"
          maxDateToday={false}
        />
      );
    case 'dateRange':
      return (
        <FilterDateRangeInput
          filter={filter}
          onChange={handleItemChange}
          className="DataTableFiltersItem"
        />
      );
    case 'textInput':
      return (
        <FormGroup label={showLabel ? filter.title : null} className="DataTableFiltersItem">
          <FormControl
            type="text"
            aria-label={filter.title}
            name={filter.key}
            value={value?.toString() ?? ''}
            onChange={(newValue) => { handleItemChange(filter.key, newValue); }}
          />
        </FormGroup>
      );
    case 'numberInput':
      return (
        <FilterNumberInput
          filter={filter}
          label={showLabel ? filter.title as string : null}
          defaultValue={defaultValue as string}
          onChange={handleItemChange}
          className="DataTableFiltersItem"
        />
      );
    case 'onOff':
      return (
        <FormSwitch
          value={value?.toString() === '1'}
          onChange={(newValue: boolean) => { handleItemChange(filter.key, newValue ? '1' : '0'); }}
          label={showLabel ? filter.title : null}
        />
      );
    case 'yesNo':
      return (
        <FilterYesNo
          filter={filter}
          defaultValue={defaultValue as string | null}
          onChange={handleItemChange}
          className="DataTableFiltersItem"
        />
      );
    case 'selectAttributes':
      return (
        <FilterAttributes
          filter={filter}
          defaultValue={defaultValue as string[] | null}
          onItemFirstOption={setFirstOption}
          onChange={handleItemChange}
          value={value}
        />
      );
    case 'custom':
    case 'customAsync':
      return FilterCustom ? (
        <div className="FormGroup DataTableFiltersItem">
          {showLabel && filter.title && (
            <div className="FormGroup__header">
              <label className="FormGroup__header__label">{filter.title}</label>
            </div>
          )}
          <FilterCustom
            filter={filter}
            getFilterValue={getFilterValue}
            onChange={onChange}
          />
        </div>
      ) : null;
    default:
      return null;
  }
};

export default DataTableFilterItem;
