import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionPost, Scenario, ScenarioTemplateReminder, ScenarioTemplateTask } from 'types/models';
import type Misc from 'types/misc';
import apiScenarios from 'api/scenarios';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import useApiRequest from 'hooks/useApiRequest';
import ActionRepeatFieldset from 'pages/Settings/Scenarios/Scenario/ActionRepeatFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';

type Props = {
  index?: number,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  setActiveAction(id: string | null): void,
  action: ScenarioTemplateReminder | ScenarioTemplateTask,
  type: Misc.ActionType,
  scenario: Scenario,
  fieldset: string,
};

const ScenarioDetailsActionPopover = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { index, action, type, scenario, fieldset, onActionDone, onActionError, setActiveAction } = props;
  const [repeatValue, setRepeatValue] = useState<ActionPost['repeat']>(action.repeat);
  const [days, setDays] = useState<number | null>(action.day !== undefined ? Math.abs(action.day) : null);
  const [date, setDate] = useState<string | null>(action.day < 0 ? 'before' : 'after');
  const [name, setName] = useState<string>(action.name);
  const { put, isLoading } = useApiRequest();

  const isTask = type.toUpperCase() === 'TASK';

  const handleSubmit = useCallback(async () => {
    if (!action) {
      return;
    }

    let payload;
    switch (fieldset) {
      case 'repeat':
        payload = { repeat: repeatValue };
        break;
      case 'title':
        if (!name) {
          onActionError(t('errors:validation.required-field'));
          return;
        }
        payload = { name };
        break;
      case 'offset':
        if (!date || !days) {
          onActionError(t('errors:validation.required-field'));
          return;
        }
        payload = { day: date === 'before' ? -days : days };
        break;
    }

    let result;
    if (isTask) {
      result = await put(apiScenarios.updateUrl(scenario.id), {
        tasks: apiScenarios.buildPayloadListData(
          scenario.tasks,
          index || 0,
          'edit',
          { ...action, ...payload },
        ),
      });
    } else {
      result = await put(apiScenarios.updateUrl(scenario.id), {
        reminders: apiScenarios.buildPayloadListData(
          scenario.reminders,
          index || 0,
          'edit',
          { ...action, ...payload },
        ),
      });
    }

    if (!result) {
      onActionError(t('plans:toast.error.edit-action'));
      return;
    }
    onActionDone(t('plans:toast.success.edited-action', { name: action.name }));
    setActiveAction(null);

  }, [
    scenario.tasks,
    scenario.reminders,
    scenario.id,
    onActionDone,
    onActionError,
    setActiveAction,
    repeatValue,
    fieldset,
    isTask,
    action,
    index,
    date,
    days,
    name,
    put,
    t,
  ]);

  const fields = useMemo(() => {
    switch (fieldset) {
      case 'repeat':
        return (
          <ActionRepeatFieldset
            defaultData={{ ...action, type }}
            onChange={setRepeatValue}
          />
        );
      case 'title':
        return (
          <FormGroup label={isTask ? t('reminders:task-name') : t('reminders:short-description')} mandatory>
            <FormControl
              type="text"
              name="name"
              autoComplete="off"
              defaultValue={action.name}
              placeholder={t('reminders:placeholder.short-description')}
              onChange={setName}
            />
          </FormGroup>
        );
      case 'offset':
        return (
          <FormGroup label={t('plans:planned')}>
            <div className="ScenarioModalAddActionForm__days">
              <FormControl
                type="number"
                min={0}
                name="days"
                defaultValue={action.day !== undefined ? Math.abs(action.day) : undefined}
                onChange={(newValue: string) => setDays(newValue ? parseInt(newValue, 10) : null)}
              />
              <FormSelect
                name="date"
                selectOptions={[
                  {
                    label: t('plans:after-reference-date'),
                    value: 'after',
                  },
                  {
                    label: t('plans:before-reference-date'),
                    value: 'before',
                  },
                ]}
                withClearButton={false}
                defaultValue={action.day < 0 ? 'before' : 'after'}
                onSelect={(_, newValue: string | null) => setDate(newValue)}
              />
            </div>
          </FormGroup>
        );
    }
  }, [fieldset, action, type, isTask, t]);

  return (
    <div className="ScenarioDetailsActionPopover">
      <button
        className="ScenarioDetailsActionPopover__close"
        onClick={()=> setActiveAction(null)}
        >
        <Icon name="close" />
      </button>
      {fields}
      <div className="ScenarioDetailsActionPopover__actions">
        <Button
          variant="outline"
          onClick={()=> setActiveAction(null)}
        >
          {t('common:cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
        >
          {t('common:validate')}
        </Button>
      </div>
      {isLoading && <Loading isOverlay />}
    </div>
  );
};

export default ScenarioDetailsActionPopover;
