import { observer } from 'mobx-react';
import type { Contact } from 'types/models';
import type { FetchAllForCustomerParams } from 'api/contacts';
import apiContacts from 'api/contacts';
import ListContacts from 'components/ListContacts';
import useFetch from 'hooks/useFetch2';

type Props = {
  nbContacts?: number,
  customerId: number,
};

const DebitSideContacts = ({ nbContacts, customerId }: Props): JSX.Element | null => {
  const {
    data: contacts,
    refetch,
    error,
    isLoading,
  } = useFetch<FetchAllForCustomerParams, Contact[]>(
    {
      cacheKey: 'debitContacts',
      id: customerId,
    },
    apiContacts.allForCustomer,
    { enabled: !!customerId },
  );

  if (!customerId) {
    return null;
  }

  return (
    <ListContacts
      customerId={customerId}
      contactsCount={nbContacts || contacts?.length || 0}
      contacts={contacts}
      fetchContacts={refetch}
      error={error}
      isLoading={isLoading}
    />
  );
};

export default observer(DebitSideContacts);
