import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StepAction } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import HistoryStepWebHookStatus from './WebHookStatus';

type Props = {
  data: StepAction,
};

const HistoryStepDetailsHistory = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    at,
    sent,
    validation,
    cancel,
    author,
    loggedAt,
    delay,
    modify,
    scenario,
    isEditable,
    isImmediatelySentByUser,
    isCreatedByDunforce,
  } = data;

  const offset = useMemo(() => {
    if (!at || isEditable) {
      return null;
    }

    const days = at.offset;
    if (!days) {
      return days === 0
        ? `(${t('days-first-letter')})`
        : null;
    }
    return (days > 0)
      ? `(${t('days-first-letter')}+${days})`
      : `(${t('days-first-letter')}−${Math.abs(days)})`;
  }, [at, t, isEditable]);

  const history = useMemo(() => {
    const log: {
      date: string | null;
      label: string;
      author?: string;
      extraInfo?: string;
      offset?: string | null;
    }[] = [];

    if (at) {
      log.push({
        date: at?.at,
        label: 'common:planned-at-date',
        offset: !author ? offset : null,
        extraInfo: !isEditable && isCreatedByDunforce && scenario ? t('common:by-scenario', {
          scenario: `${scenario.name}${scenario.identifier ? ` (${scenario.identifier})` : ''}`,
        }) as string : '',
      });
    }
    if (validation) {
      log.push({ date: validation.at, label: 'common:validated-at-date-by', author: validation.userName });
    }
    if (cancel) {
      log.push({ date: cancel.at, label: 'common:remove-at-date-by', author: cancel.userName });
    }
    if (delay) {
      log.push({ date: delay.at, label: 'common:delayed-at-date-by', author: delay.userName });
    }
    if (modify) {
      log.push({ date: modify.at, label: 'common:modified-at-date-by', author: modify.userName });
    }
    if (isImmediatelySentByUser && sent) {
      log.push({ date: sent.at, label: 'common:dispatched-at-date-by', author: sent.userName });
    }

    log.sort((a, b) => {
      const dateA = a.date ? new Date(a.date).getTime() : 0;
      const dateB = b.date ? new Date(b.date).getTime() : 0;
      return dateA - dateB;
    });

    if (!!author) {
      log.unshift({ date: loggedAt, label: 'created-by-at', author: author.fullName, offset });
    }

    return log;
  }, [
    at,
    sent,
    validation,
    cancel,
    author,
    loggedAt,
    delay,
    modify,
    scenario,
    offset,
    isEditable,
    isCreatedByDunforce,
    isImmediatelySentByUser,
    t,
  ]);

  return (
    <div className="HistoryStepDetailsHistory">
      {history.map((step, index) => (
        <div key={index} className="HistoryStepDetailsHistory__date">
          {t(step.label, {
            date: `${formatIntlDate(step.date, 'dateTimeFullShortMonth')}${step.offset ? ` ${step.offset}` : ''}`,
            name: step.author ?? '',
          })}
          {step.extraInfo && <p className="HistoryStepDetailsHistory__date__extraInfo">{step.extraInfo}</p>}
        </div>
      ))}
      <HistoryStepWebHookStatus data={data} />
    </div>
  );
};

export default HistoryStepDetailsHistory;
