import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import { EavCategory, RightsCategory } from 'types/models';
import type { EavConstructor } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import apiAttributes from 'api/attributes';
import Loading from 'components/Loading';
import Button from 'components/Button';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import DropdownActions from 'components/DropdownActions';
import ModalEdit from '../../ModalEdit';

type Props = {
  id: string,
  attributesData: EavConstructor,
  type: EavCategory,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const AttributesRowActions = (props: Props): JSX.Element => {
  const { id, type, attributesData, onActionDone, onActionError } = props;
  const { currentOrganization, refresh: refetchOrganization, attributes } = organizationStore;
  const { type: organizationType } = currentOrganization!;
  const { t, ct } = useContextualTranslation(organizationType);
  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();
  const { error, isLoading: isDeleting, put, remove } = useApiRequest();
  const [isSaving, setIsSaving] = useState(false);
  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleConfirmDelete = useCallback(() => {
    if (attributesData.isRemovable) {
      const text = ct('attributes:confirm-remove', { name: attributesData.label });
      showConfirm(t('common:remove'), text);
    } else {
      showToast(t('attributes:cannot-be-deleted'), 'warning');
    }
  }, [showConfirm, showToast, t, ct, attributesData]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    if (!currentOrganization) {
      return;
    }
    const result = await remove(
      apiAttributes.deleteUrl(attributesData.id),
    );
    if (result !== null) {
      // on enleve l'item du store parce que le refresh met un peu de temps, ca enlevera l'item de suite
      attributes[type] = attributes[type].filter((attribute) => attribute.id !== attributesData.id);
      onActionDone(ct('attributes:toast.success.deleted', { id }));
      organizationStore.refresh();
    }
  }, [hideConfirm, currentOrganization, remove, attributesData.id, attributes, type, onActionDone, ct, id]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('attributes:toast.error.deleted', { id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [showModal, hideModal] = useModal(() => (
    <ModalEdit
      type={type}
      onClose={hideModal}
      onActionDone={onActionDone}
      onActionError={onActionError}
      defaultData={attributesData}
      isEdit
    />
  ), [attributesData, onActionDone, onActionError, type]);

  const handleEdit = useCallback(() => {
    showModal();
  }, [showModal]);

  const handleToggleOverviewDisplay = useCallback(async () => {
    if (!currentOrganization) {
      return;
    }

    setIsSaving(true);
    const { id: attributeId, overviewDisplay, label } = attributesData;

    try {
      const response = await put(
        apiAttributes.updateUrl(attributeId),
        {
          overviewDisplay: !overviewDisplay,
        },
      );
      if (!response) {
        throw new Error();
      }
      await refetchOrganization();
      onActionDone(t('attributes:toast.success.edit', { id: label }));
    } catch {
      onActionError(t('attributes:toast.error.edit', { id: label }));
    } finally {
      setIsSaving(false);
    }
  }, [onActionError, onActionDone, attributesData, put, currentOrganization, refetchOrganization, t]);

  const buttons: ReactNode[] = useMemo(() => {
    const toggleOverviewText = attributesData.overviewDisplay ?
      t('attributes:dont-display-on-overview') :
      t('attributes:display-on-overview');

    const buttonChoices = [
      <ButtonWithUserRights
        action="UPDATE"
        category={RightsCategory.PREFERENCES_ATTRIBUTES}
        variant="list"
        onClick={handleEdit}
        title={t('common:edit')}
      >
        {isDeleting && <Loading hasNoText />} {t('common:edit')}
      </ButtonWithUserRights>,
    ];
    if (type === EavCategory.CLIENT) {
      buttonChoices.push(
        <Button
          variant="list"
          onClick={handleToggleOverviewDisplay}
          title={toggleOverviewText}
        >
          {isSaving && <Loading hasNoText />} {toggleOverviewText}
        </Button>,
      );
    }
    buttonChoices.push(
      <ButtonWithUserRights
        action="DELETE"
        category={RightsCategory.PREFERENCES_ATTRIBUTES}
        variant="list-danger"
        onClick={handleConfirmDelete}
        title={t('common:remove')}
      >
        {isDeleting ? <Loading hasNoText /> : t('common:remove')}
      </ButtonWithUserRights>,
    );
    return buttonChoices;
  }, [attributesData.overviewDisplay, t, handleEdit, isDeleting, type, handleConfirmDelete, handleToggleOverviewDisplay, isSaving]);

  return (
    <div className="AttributesRowActions">
      <DropdownActions actions={buttons} />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={id}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(AttributesRowActions);
