import { HistoryActivityTag } from 'types/models';

const getHistoryActivityAlert = (historyActivityTag: HistoryActivityTag): boolean => {
  if (!historyActivityTag) {
    return false;
  }

  const alertEntities: HistoryActivityTag[] = [
    HistoryActivityTag.CONTACT_UPDATE_ALERT,
    HistoryActivityTag.CLIENT_MUTE_ALERT,
    HistoryActivityTag.CLIENT_UNMUTE_ALERT,
    HistoryActivityTag.SWITCH_PLAN_ALERT,
    HistoryActivityTag.SWITCH_REFERENCE_DATE_ALERT,
  ];
  return alertEntities.includes(historyActivityTag);
};

export default getHistoryActivityAlert;
