import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import type { Scenario, ScenarioSummary } from 'types/models';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import apiScenarios from 'api/scenarios';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import type { FetchAllParams } from 'api/scenarios';
import useFetchPaginated from 'hooks/useFetchPaginated2';
import { DefaultValue, getDefaultValue } from './utils';

export type Props = {
  name: string,
  onSelect?(name: string, newValue: string | null): void,
  placeholder?: DefaultTFuncReturn,
  defaultValue?: DefaultValue,
  value?: string | string[] | null,
  disabled?: boolean,
  className?: string,
  isMultiple?: boolean,
  isInvalid?: boolean,
  withClearButton?: boolean,
  entityValue?: 'id' | 'reference',
  onItemFirstOption?(firstOption?: string | null | number): void,
};

const FormSelectScenario = (props: Props): JSX.Element => {
  const {
    name,
    onSelect,
    placeholder,
    defaultValue,
    value: controlValue,
    disabled = false,
    className,
    isMultiple = false,
    isInvalid = false,
    withClearButton = true,
    entityValue = 'reference',
    onItemFirstOption,
  } = props;
  const { id } = organizationStore.currentOrganization ?? { id: undefined };
  const isMountedRef = useIsMountedRef();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState<string | string[]>('');
  const [cleared, setCleared] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectSearchOption[] | null>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const { isLoading, data } = useFetchPaginated<FetchAllParams, Scenario>(
    {
      cacheKey: 'organizationScenarios',
      organizationId: id,
    },
    apiScenarios.all,
    { enabled: !!id, refetchOnWindowFocus: false, retry: 1 },
  );

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    if ((!data || data.length === 0) && !isLoading && !options) {
      setOptions([{ name: '', value: '', disabled: true }]);
      return;
    }

    if (!!data) {
      const optionsToSet = data.map(({ id: planId, identifier, name: planName }: ScenarioSummary) => ({
        name: `${planName} (${identifier})`,
        value: entityValue === 'reference' ? identifier : planId,
      }));
      setOptions(optionsToSet);

      if (onItemFirstOption) {
        onItemFirstOption(optionsToSet?.[0]?.value);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  useEffect(() => {
    if (defaultValue && value.length === 0) {
      const initValue = getDefaultValue(defaultValue, options || []);
      setValue(initValue);
      if (onSelect) {
        onSelect(name, Array.isArray(initValue) ? initValue.join(',') : initValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  useEffect(() => {
    if (controlValue !== undefined && value !== controlValue) {
      if (cleared) {
        setCleared(false);
      }
      setValue(controlValue === null ? '' : controlValue);
    }
  }, [value, controlValue, cleared]);

  const handleChange = useCallback(
    (selectedValue: string | string[]) => {
      setValue(selectedValue);
      const newValue = (Array.isArray(selectedValue)) ? selectedValue.join(',') : selectedValue;

      if (inputRef?.current) {
        // - Ceci permet de déclencher un onChange au niveau du <form> parent
        const inputValSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        inputValSetter?.call(inputRef.current, newValue);
        inputRef.current.dispatchEvent(
          new Event('change', { bubbles: true }),
        );
      }

      if (onSelect) {
        onSelect(name, newValue);
      }
    },
    [name, onSelect],
  );

  const handleClear = useCallback(() => {
    setCleared(true);
    setIsFocus(false);
    setValue('');
    if (onSelect) {
      onSelect(name, null);
    }
  }, [name, onSelect]);

  const classNames = classnames('FormSelectScenario', className, {
    'FormSelectScenario--has-value': withClearButton && value,
    'FormSelectScenario--is-loading': isLoading,
    'FormSelectScenario--is-invalid': isInvalid,
    'FormSelectScenario--with-flying-label-opened': isFocus,
  });

  const customFuzzySearch = useCallback((
    filterOptions: SelectSearchOption[],
  ) => (query: string) => {
    const searchWords = query.trim().toLowerCase().split(/\s+/);

    return filterOptions.filter((option) => {
      const optionName = option.name.toLowerCase();
      return searchWords.every((word) => optionName.includes(word));
    });
  }, []);

  return (
    <div
      className={classNames}
      onFocus={() => { setIsFocus(true); }}
      onBlur={() => { setIsFocus(false); }}
    >
      {options === null && (
        <Loading />
      )}
      {options !== null && (
        <SelectSearch
          onChange={handleChange}
          disabled={disabled}
          value={value}
          options={options}
          search={true}
          placeholder={placeholder ?? undefined}
          multiple={isMultiple}
          printOptions="on-focus"
          filterOptions={customFuzzySearch}
        />
      )}
      <input
        type="text"
        value={value}
        name={name}
        ref={inputRef}
        onChange={() => {}}
        className="FormSelectScenario__hidden-field"
      />
      {withClearButton && value && (
        <Button
          variant="light"
          onClick={handleClear}
          className="FormSelectScenario__clear"
        >
          <Icon name="close-small" />
        </Button>
      )}
    </div>
  );
};

export default observer(FormSelectScenario);
