import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import useApiRequest from 'hooks/useApiRequest';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useIsMountedRef from 'hooks/useIsMountedRef';
import type { ValidationErrors } from 'types/errors';
import type { Debit, DebitPost } from 'types/models';
import organizationStore from 'stores/Organization';
import apiDebits from 'api/debits';
import { base64ToBlob, extractMimeTypeFromBase64 } from 'utils/base64';
import DebitEditModal from './EditModal';
import DebitPreviewModal from './PreviewModal';

type Props = {
  onDone(title: string, message: string): void,
  onClose(): void,
  finallyRedirectTo?: string,
  customerId?: number,
  shouldRedirectToCreatedEntity?: boolean,
};

const DebitEdit = (props: Props): JSX.Element | null => {
  const {
    onDone,
    finallyRedirectTo,
    onClose,
    shouldRedirectToCreatedEntity,
    customerId,
  } = props;
  const isMountedRef = useIsMountedRef();
  const history = useHistory();
  const { type } = organizationStore.currentOrganization!;
  const { ct } = useContextualTranslation(type);

  const {
    post,
    postFormData,
    put,
    isLoading,
    cancel,
    error,
  } = useApiRequest();

  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [currentCustomerId, setCurrentCustomerId] = useState<number | undefined>(customerId);
  const [dataToPost, setDataToPost] = useState<DebitPost | null>(null);
  const [receiptName, setReceiptName] = useState<string | null>(null);
  const [receipt, setReceipt] = useState<string | null>(null);
  const [step, setStep] = useState<'form' | 'preview'>('form');

  useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const handleSubmit = useCallback((data: DebitPost) => {
    setDataToPost(data);
    setStep('preview');
  }, []);

  const handleValidate = useCallback(async (hidePreviewModal: () => void) => {
    if (!dataToPost) {
      return;
    }
    const payload = { ...dataToPost, eavs: undefined, eavsForm: undefined, invoice: undefined };
    const result = await post<Debit>(apiDebits.createUrl, payload);

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      setStep('form');
      return;
    }

    if (result?.id) {
      let resultInvoice;
      if (dataToPost.invoice) {
        // on upload la facture
        const formDataFile = new FormData();
        const mimeType = extractMimeTypeFromBase64(dataToPost.invoice) ?? 'application/pdf';
        const contentFile = base64ToBlob(dataToPost.invoice, mimeType);
        formDataFile.append('invoice', contentFile, receiptName ?? `invoice-${result.id}.pdf`);
        resultInvoice = await postFormData<Debit>(apiDebits.uploadDebitInvoiceUrl(result.id), formDataFile);
      }

      let resultEavs;
      if (Object.keys(dataToPost.eavs).length > 0) {
        // on met à jour les eavs
        resultEavs = await put<Debit>(apiDebits.updateDebitEavsUrl(result.id), { values: dataToPost.eavs });
      }

      if (resultEavs === null && !resultInvoice?.id) {
        return;
      }

      const { reference } = result;
      onDone(
        ct('debits:actions.toast.created'),
        ct('debits:actions.toast.created-ref', { reference }),
      );
      if (finallyRedirectTo && !shouldRedirectToCreatedEntity) {
        setTimeout(() => {
          history.replace(finallyRedirectTo);
        }, 300);
      }
      hidePreviewModal();
      if (shouldRedirectToCreatedEntity) {
        history.replace(`/debit/${result.id}`);
      }
      return;
    }
    setStep('form');
  }, [ct, dataToPost, finallyRedirectTo, history, isMountedRef, onDone, post, postFormData, put, receiptName, shouldRedirectToCreatedEntity]);

  const handleChangeCustomer = useCallback((newId: number) => {
    if (Number.isNaN(newId)) {
      return;
    }
    setCurrentCustomerId(newId);
  }, []);

  const [showEditModal, hideEditModal] = useModal(() => (
    <DebitEditModal
      customerId={currentCustomerId}
      onChangeCustomer={handleChangeCustomer}
      finallyRedirectTo={finallyRedirectTo}
      onClose={() => {
        hideEditModal();
        onClose();
      }}
      onSubmit={(data: DebitPost) => {
        handleSubmit(data);
        hideEditModal();
      }}
      data={dataToPost || undefined}
      validationErrors={validationErrors}
      isLoading={isLoading}
      apiError={error}
      receiptName={receiptName}
      onChangeReceiptName={setReceiptName}
      receipt={receipt}
      onChangeReceipt={setReceipt}
    />
  ), [
    onClose,
    currentCustomerId,
    handleChangeCustomer,
    finallyRedirectTo,
    dataToPost,
    validationErrors,
    isLoading,
    error,
    receiptName,
    handleSubmit,
    receipt,
  ]);

  const handleStepBack = useCallback((errors?: ValidationErrors) => {
    if (errors) {
      setValidationErrors(errors);
    }

    setStep('form');
  }, []);

  const [showPreviewModal, hidePreviewModal] = useModal(() => (
    <DebitPreviewModal
      finallyRedirectTo={finallyRedirectTo}
      data={dataToPost}
      customerId={currentCustomerId}
      onStepBack={handleStepBack}
      onClose={() => { hidePreviewModal(); onClose(); }}
      receiptName={receiptName}
      receipt={receipt}
      onSubmit={() => { handleValidate(hidePreviewModal); }}
    />
  ), [
    dataToPost,
    finallyRedirectTo,
    handleStepBack,
    handleValidate,
    onClose,
    receipt,
    receiptName,
    currentCustomerId,
  ]);

  useEffect(() => {
    if (step === 'form') {
      showEditModal();
      hidePreviewModal();
    } else if (step === 'preview') {
      showPreviewModal();
      hideEditModal();
    } else {
      throw new Error('Unknown Step');
    }

    return () => {
      hideEditModal();
      showPreviewModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return null;
};

export default DebitEdit;
