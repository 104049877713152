import { EAVType } from 'types/models';

const getEavTypeLabel = (eavFormat: EAVType) => {
  const eavFormatTable = {
    [EAVType.NUMBER]: 'common:number',
    [EAVType.DATE]: 'common:date-format-ymd',
    [EAVType.STRING]: 'common:string',
    [EAVType.BOOLEAN]: 'common:boolean',
  };

  return eavFormatTable[eavFormat];
};

export default getEavTypeLabel;
