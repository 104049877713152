import './index.scss';
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';
import { usePdf } from '@mikecousins/react-pdf';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import DownloadLink from 'components/DownloadLink';

interface Props {
  base64PDF?: string,
  url?: string,
  scale?: number,
  onRemove?: () => void,
  isClickable?: boolean,
  downloadUrl?: string,
  withAuthentication?: boolean,
}

const PDFViewer: React.FC<Props> = (props) => {
  const [page, setPage] = React.useState<number>(1);
  const { t } = useTranslation();
  const canvasRef = React.useRef(null);

  const {
    base64PDF,
    url,
    scale = 1,
    onRemove,
    isClickable = false,
    downloadUrl,
    withAuthentication = true,
  } = props;

  const file = useMemo(() => {
    if (base64PDF) {
      return base64PDF.startsWith('data:application/pdf;base64')
        ? base64PDF
        : `data:application/pdf;base64${base64PDF}`;
    }
    return url || '';
  }, [base64PDF, url]);

  const { pdfDocument } = usePdf({
    file,
    page,
    canvasRef,
    scale,
  });

  const className = classNames('PDFViewer__content', {
    'PDFViewer__content--without-nav': pdfDocument && pdfDocument.numPages === 1,
    'PDFViewer__content--locked': !isClickable,
  });

  const [showModalZoomedPDF, hideModalZoomedPDF] = useModal(() => (
    <Modal
      onClose={hideModalZoomedPDF}
      isOpened
      title={t('common:pdf-viewer')}
      headerActions={downloadUrl && (
        <Button variant="link" className="PDFViewer__modal__download-link">
          <DownloadLink path={downloadUrl} withAuthentication={withAuthentication}>
            <Icon name="download" />{t('common:download')}
          </DownloadLink>
        </Button>
      )}
    >
      <PDFViewer {...props} isClickable={false} scale={1.1} />
    </Modal>
  ), [t, downloadUrl, withAuthentication, props]);

  const handleClick = useCallback(() => {
    if (!isClickable) {
      return;
    }
    showModalZoomedPDF();
  }, [isClickable, showModalZoomedPDF]);

  const handleKeyDown = useCallback((key: React.KeyboardEvent) => {
    if (key.key === 'Enter') {
      handleClick();
    }
  }, [handleClick]);

  return (
    <div className="PDFViewer">
      {!pdfDocument && <Loading />}
      {pdfDocument && pdfDocument.numPages && (
        <div
          className={className}
          onClick={handleClick}
          onKeyPress={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          {onRemove !== undefined && (
            <Button onClick={onRemove} variant="outline-danger" className="PDFViewer__content__close">
              <Icon name="close" />
            </Button>
          )}
          <div className="PDFViewer__content__pdf-nav">
            {pdfDocument.numPages !== 1 && (
              <Button
                variant="outline-primary"
                disabled={page === 1}
                onClick={(e) => { e.stopPropagation(); setPage(page - 1); }}
                className="PDFViewer__content__pdf-nav__left"
              >
                <Icon name="chevron-left" />
              </Button>
            )}
            <canvas ref={canvasRef} className="PDFViewer__content__pdf-nav__pdf" />
            {pdfDocument.numPages !== 1 && (
              <Button
                variant="outline-primary"
                className="PDFViewer__content__pdf-nav__right"
                disabled={page === pdfDocument.numPages}
                onClick={(e) => { e.stopPropagation(); setPage(page + 1); }}
              >
                <Icon name="chevron-right" />
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
