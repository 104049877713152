import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFuncKey, TOptions, StringMap, TFunction, TOptionsBase } from 'i18next';
import { OrganizationType } from 'types/models';

const useContextualTranslation = (type: OrganizationType | null) => {
  const { t, i18n } = useTranslation();

  const ct = useCallback((key: TFuncKey, args: TOptions<StringMap>) => {
    if (!key) {
      return '';
    }

    const options: TOptionsBase = { defaultValue: '', ...args };

    if (type !== OrganizationType.INDIVIDUAL) {
      return t(key, options);
    }

    const prefix = 'lessor';

    const keyMainParts = key.split(':');
    const [domain, path] = keyMainParts;
    if (!path) {
      // - Si la partie `path` n'existe pas, c'est qu'on utilise
      //   une chaîne de caractère non-standard i18n, il faut donc renvoyer
      //   directement cette chaîne qui n'a pas à être traduite.
      return domain;
    }

    const keyParts = path.split('.');
    const lastIndex = keyParts.length - 1;
    const lastKey = `${prefix}_${keyParts[lastIndex]}`;
    keyParts.splice(lastIndex, 1, lastKey);

    const withContextKey = `${domain}:${keyParts.join('.')}`;
    if (!i18n.exists(withContextKey)) {
      return t(key, options);
    }

    return t(withContextKey, options);
  }, [i18n, type, t]) as TFunction;

  return { t, ct };
};

export default useContextualTranslation;
