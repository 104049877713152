import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PublicLayout from 'layout/PublicLayout';
import type { ValidationErrors } from 'types/errors';
import apiBoarding from 'api/boarding';
import type { SignupData, SignupValidationErrors } from 'api/boarding';
import authStore from 'stores/Auth';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import SignUpForm from './Form';

const SignUp = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLogged, isBusy } = authStore;
  const [validationError, setValidationError] = useState<ValidationErrors | null>(null);
  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  useEffect(() => {
    if (isLogged) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const handleSubmit = useCallback(async (signupData: SignupData | null) => {
    setValidationError(null);
    if (!signupData) {
      return;
    }

    try {
      const result = await apiBoarding.signUp(signupData);

      if ((result as SignupValidationErrors).errors) {
        setValidationError((result as SignupValidationErrors).errors);
        return;
      }

      history.push(`/confirm-email?email=${signupData?.email}`);
    } catch (error) {
      showToast(t('errors:unknown-retry'), 'error');
    }
  }, [history, t, showToast]);

  return (
    <PublicLayout withLanguageSelection>
      <div className="SignUp">
        <div className="SignUp__form">
          <h2 className="SignUp__title">{t('register:title')}</h2>
          <p className="SignUp__login">
            {t('register:already-have-account')}
            <Link to="/login" className="SignUp__login__link">
              {t('register:login')}
            </Link>
          </p>
          <ToastNotification
            text={toastText}
            status={toastStatus}
            isShow={isToastShowed}
            onClose={hideToast}
          />
          <SignUpForm
            onSubmit={handleSubmit}
            isLoading={isBusy}
            errors={validationError}
          />
        </div>
      </div>
    </PublicLayout>
  );
};

export default observer(SignUp);
