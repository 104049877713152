import { ImportItemStatus } from 'types/models';

const getI18nStatusImport = (status: ImportItemStatus): string => {
  const i18nStatusImport = {
    [ImportItemStatus.COMPLETED]: 'imports:file-status.file-created',
    [ImportItemStatus.IMPORTED]: 'imports:file-status.file-processed',
    [ImportItemStatus.EXECUTING]: 'imports:file-status.file-processing',
    [ImportItemStatus.FAILED]: 'imports:file-status.file-error',
  };

  return i18nStatusImport[status];
};

export default getI18nStatusImport;
