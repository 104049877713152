import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { ValidationErrors } from 'types/errors';
import type { Client, TaskPost } from 'types/models';
import dateFormatIso from 'utils/dateFormatIso';
import getDaysDiff from 'utils/getDaysDiff';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormDatePicker from 'components/FormDatePicker';
import Icon from 'components/Icon';
import FormGroupWithActions from 'components/FormGroupWithActions';
import ScenarioGroupDropdown from 'components/ScenarioGroupDropdown';
import FormCheck from 'components/FormCheck';
import AskMoveScenarioModal from '../../AskMoveScenario';

type Props = {
  defaultData: TaskPost | null,
  errors?: ValidationErrors | null,
  customerId?: Client['id'] | null,
};

const TaskForm = ({ defaultData, errors, customerId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<string>(defaultData?.startAt ?? '');
  const [dateMoveScenario, setDateMoveScenario] = useState<'none' | 'move'>('none');
  const [daysShift, setDaysShift] = useState<number>(0);
  const [name, setName] = useState<string>(defaultData?.name || '');
  const [description, setDescription] = useState<string>(defaultData?.description || '');

  useEffect(() => {
    setName(defaultData?.name || '');
    setDescription(defaultData?.description || '');
  }, [defaultData]);

  const [showAskMoveScenario, hideAskMoveScenario] = useModal(() => (
    <AskMoveScenarioModal
      daysCount={daysShift}
      onChange={(scenario: 'none' | 'move') => { setDateMoveScenario(scenario); }}
      onResetDate={() => { setStartDate(defaultData?.startAt ?? ''); }}
      onClose={hideAskMoveScenario}
    />
  ), [daysShift, defaultData]);

  const handleChangeStartDate = useCallback((noop: string, newDate: string) => {
    setStartDate(newDate);

    if (defaultData && dateFormatIso(new Date(defaultData.startAt)) !== newDate) {
      showAskMoveScenario();
      setDaysShift(Number.parseInt(getDaysDiff(newDate, defaultData?.startAt), 10));
    }
  }, [defaultData, showAskMoveScenario]);

  return (
    <div className="TaskForm">
      <FormFieldset>
        <FormGroupWithActions
          label={t('reminders:task-name')}
          mandatory
          error={errors?.name}
        >
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            value={name}
            onChange={setName}
            placeholder={t('reminders:placeholder.task-name')}
            isInvalid={!!errors?.name}
          />
        </FormGroupWithActions>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:planned-at')} mandatory error={errors?.startAt}>
          <FormDatePicker
            name="startAt"
            value={startDate}
            onChange={handleChangeStartDate}
            defaultValue={defaultData?.startAt}
            isInvalid={!!errors?.startAt}
          />
          <input type="hidden" name="withMove" value={dateMoveScenario} />
          <input type="hidden" name="daysShift" value={daysShift} />
        </FormGroup>
        <FormGroup
          className="TaskForm__static"
          label={t('reminders:is-static')}
          error={errors?.static}
        >
          <FormCheck
            defaultChecked={defaultData?.isStatic}
            name="static"
            value="1"
          />
        </FormGroup>
        <ScenarioGroupDropdown customerId={customerId} />
      </FormFieldset>
      {dateMoveScenario === 'move' && daysShift > 0 && (
        <p className="TaskForm__daysShift">
          <Icon name="warning-circle" />
          {t('reminders:shifting-other-steps', { count: daysShift })}
        </p>
      )}
      <FormFieldset>
        <FormGroupWithActions
            label={t('reminders:task-description')}
            error={errors?.description}
          >
          <FormControl
            type="textarea"
            name="description"
            autoComplete="off"
            value={description}
            placeholder={t('reminders:placeholder.task-description')}
            onChange={setDescription}
            isInvalid={!!errors?.description}
          />
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default TaskForm;
