import './index.scss';
import { useMemo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { RightsAction, ManagerType } from 'types/models';
import { RightsCategory, RightsCategoryType } from 'types/models';
import authStore from 'stores/Auth';
import userRightsStore from 'stores/UserRights';
import Button, { Props as ButtonProps } from 'components/Button';
import { checkIsAllowed } from 'components/UserRights';
import useTooltip from 'components/Tooltip/useTooltip';
import getCategoryFromCategoryType from 'components/UserRights/utils';

type Props = ButtonProps & {
  category?: RightsCategory,
  action: RightsAction,
  displayedWhenNotAllowed?: boolean,
  managers?: ManagerType[] | null,
  categoryType?: RightsCategoryType,
};

type InternalProps = Props & { children: ReactNode };

const ButtonWithUserRights = (props: InternalProps): JSX.Element => {
  const {
    action,
    category,
    displayedWhenNotAllowed = false,
    children,
    managers,
    categoryType,
  } = props;
  const { id: userIdentifier } = authStore.user ?? { id: null };
  const { rights } = userRightsStore;
  const { t } = useTranslation();

  const isAllowed = useMemo(() => {
    if (!rights) {
      return false;
    }

    if (!managers && category) {
      return checkIsAllowed(action, category);
    }

    if (!categoryType) {
      return false;
    }

    return checkIsAllowed(
      action,
      getCategoryFromCategoryType(managers, userIdentifier, categoryType),
    );
  }, [action, category, categoryType, managers, userIdentifier, rights]);

  const tooltip = useTooltip(
    <span className="ButtonWithUserRights__tooltip">{t('common:not-allowed')}</span>,
    'clamped',
    isAllowed || !displayedWhenNotAllowed,
  );

  return (
    <div ref={tooltip} className="ButtonWithUserRights">
      <Button {...props} disabled={!isAllowed}>
        {children}
      </Button>
    </div>
  );
};

export default observer(ButtonWithUserRights);
