import './index.scss';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  to: string,
  disabled?: boolean,
  className?: string,
  children: ReactNode,
}

const LinkOrNot = (props: Props): JSX.Element => {
  const { to, disabled = false, className, children } = props;
  const classNames = classnames('LinkOrNot', className, {
    'LinkOrNot--disabled': disabled,
  });

  if (!disabled) {
    return <Link className={classNames} to={to}>{children}</Link>;
  }

  return <span className={classNames}>{children}</span>;
};

export default LinkOrNot;
