import { createContext } from 'react';
import type { Client } from 'types/models';

export type CustomerContextValue = {
  data: Client | null,
  refetch?(): void,
  onActionDone?(message: string): void,
  reloadTick: number,
};

const defaultValue: CustomerContextValue = {
  data: null,
  refetch: undefined,
  onActionDone: undefined,
  reloadTick: 1,
};

const customerContext = createContext<CustomerContextValue>(defaultValue);

export default customerContext;
