export enum PhaseId {
  UPCOMING = 'upcoming',
  PENDING = 'pending',
  ALMOST_DUE = 'almost_due',
  DUE = 'due',
  OVERDUE = 'overdue',
  LATE = 'late',
  DEFAULT = 'default',
  INACTIVE = 'inactive',
}

export type DetailPhases = {
  id: string,
  count: number,
  amount: number,
  percentage: number,
};

export type DetailTotalByPhase = {
  phases: DetailPhases[],
  total: number,
  count: number,
  percentage: number,
};

export type ActivityPhase = {
  id: PhaseId,
  smiley: string,
  position: number,
};

export type AmountByPhase = {
  id: string,
  cost: number,
  nb: number,
};

export type AmountPhase = {
  id: PhaseId,
  cost: number,
  color: string,
  nb: number,
  position: number,
};
