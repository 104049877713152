import './index.scss';
import { useCallback, useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import { Channel } from 'types/models';
import type { ValidationErrors } from 'types/errors';
import type { Client, StepAction } from 'types/models';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import dateFormatIso from 'utils/dateFormatIso';
import getDaysDiff from 'utils/getDaysDiff';
import getContactRole from 'utils/getContactRole';
import getI18nChannel from 'utils/getI18nChannel';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Icon from 'components/Icon';
import Button from 'components/Button';
import FormCheck from 'components/FormCheck';
import FormSelect from 'components/FormSelect';
import FormDatePicker from 'components/FormDatePicker';
import FormGroupWithActions from 'components/FormGroupWithActions';
import HistoryStepContentPreview from 'components/HistoryStepContentPreview';
import ScenarioGroupDropdown from 'components/ScenarioGroupDropdown';
import FormControlSMSContent from 'components/FormControlSMSContent';
import TagLink from 'components/TagLink';
import AskMoveScenarioModal from '../../AskMoveScenario';
import ReminderEditFormContentEdit from './Content';

type Props = {
  defaultData?: StepAction | null,
  errors?: ValidationErrors | null,
  customerId?: Client['id'] | null,
  immediateDispatch: boolean,
};

const ReminderEditForm = (props: Props): JSX.Element => {
  const { defaultData, errors, customerId, immediateDispatch } = props;
  const { t } = useTranslation();
  const { contactRoles } = organizationStore.currentOrganization!;
  const [content, setContent] = useState<string>(defaultData?.content ?? '');
  const [startDate, setStartDate] = useState<string>(defaultData?.at?.at ?? '');
  const [dateMoveScenario, setDateMoveScenario] = useState<'none' | 'move'>('none');
  const [daysShift, setDaysShift] = useState<number>(0);

  const roleDefaultValue = useMemo<Misc.Identifier[]>(() => {
    if (!defaultData || !contactRoles) {
      return [];
    }

    const defaultContactRoles = defaultData.contacts?.map(({ contactRole }) => (
      ({
        label: getContactRole(contactRole!, contactRoles),
        identifier: contactRole!,
        value: contactRoles,
      })
    )) || [];

    if (!defaultData.missingContactRoles) {
      return defaultContactRoles;
    }

    const defaultMissingContactRoles = defaultData.missingContactRoles?.map((role) => (
      ({
        label: getContactRole(role, contactRoles),
        identifier: role,
        value: contactRoles,
      })
    )) || [];

    return [...defaultContactRoles, ...defaultMissingContactRoles];
  }, [defaultData, contactRoles]);

  const subjectLabel = defaultData?.channel === Channel.EMAIL
    ? t('reminders:email-subject')
    : t('reminders:subject');

  const [showAskMoveScenario, hideAskMoveScenario] = useModal(() => (
    <AskMoveScenarioModal
      daysCount={daysShift}
      onChange={(scenario: 'none' | 'move') => { setDateMoveScenario(scenario); }}
      onResetDate={() => { setStartDate(defaultData?.at?.at ?? ''); }}
      onClose={hideAskMoveScenario}
    />
  ), [daysShift, defaultData]);

  const handleChangeStartDate = useCallback((noop: string, newDate: string) => {
    setStartDate(newDate);

    if (defaultData && dateFormatIso(new Date(defaultData.at?.at)) !== newDate) {
      showAskMoveScenario();
      setDaysShift(Number.parseInt(getDaysDiff(newDate, defaultData?.at?.at), 10));
    }
  }, [defaultData, showAskMoveScenario]);

  const [showPreviewContentModal, hidePreviewContentModal] = useModal(() => (
    (defaultData?.code && <HistoryStepContentPreview
      code={defaultData.code}
      name={defaultData.name}
      description={defaultData.description}
      subject={defaultData.subject}
      content={content}
      channel={defaultData?.channel || Channel.EMAIL}
      onClose={hidePreviewContentModal}
    />)
  ), [content, defaultData]);

  return (
    <div className="ReminderEditForm">
      <div className="CreateReminderEditForm__main">
        <FormFieldset>
          <FormGroup label={t('reminders:sending-date')} mandatory error={errors?.startAt}>
            <FormDatePicker
              name="startAt"
              value={startDate}
              onChange={handleChangeStartDate}
              isInvalid={!!errors?.startAt}
            />
            <input type="hidden" name="withMove" value={dateMoveScenario} />
            <input type="hidden" name="daysShift" value={daysShift} />
          </FormGroup>
          <FormGroup label={t('reminders:short-description')} error={errors?.name}>
            <FormControl
              type="text"
              name="name"
              autoComplete="off"
              defaultValue={defaultData?.name ?? ''}
              placeholder={t('reminders:placeholder.short-description')}
              isInvalid={!!errors?.name}
            />
          </FormGroup>
        </FormFieldset>
        {dateMoveScenario === 'move' && daysShift > 0 && (
          <p className="ReminderEditForm__daysShift">
            <Icon name="warning-circle" />
            {t('reminders:shifting-other-steps', { count: daysShift })}
          </p>
        )}
        <FormFieldset>
          <FormGroup label={t('reminders:description')} error={errors?.description}>
            <FormControl
              type="text"
              name="description"
              autoComplete="off"
              defaultValue={defaultData?.description ?? ''}
              placeholder={t(
                'reminders:placeholder.description',
                { channel: t(getI18nChannel(defaultData?.channel || Channel.SMS)) },
              )}
              isInvalid={!!errors?.description}
            />
          </FormGroup>
        </FormFieldset>
        <div className="ReminderEditForm__fieldset">
          {!immediateDispatch && (
            <Fragment>
              <FormGroup
                className="ReminderEditForm__need-validation"
                label={t('reminders:need-validation')}
                error={errors?.withValidation}
              >
                <FormCheck
                  disabled={defaultData?.withValidation}
                  defaultChecked={defaultData?.withValidation}
                  name="withValidation"
                  value="1"
                />
              </FormGroup>
              <FormGroup
                className="ReminderEditForm__static"
                label={t('reminders:is-static')}
                error={errors?.static}
              >
                <FormCheck
                  defaultChecked={defaultData?.isStatic}
                  name="static"
                  value="1"
                />
              </FormGroup>
            </Fragment>
          )}
          <ScenarioGroupDropdown
            defaultValue={defaultData?.scenarioGroup?.id}
            customerId={customerId}
          />
        </div>
      </div>
      <FormFieldset>
        <FormGroup label={t('reminders:recipients')} mandatory error={errors?.roles}>
          <FormSelect
            name="roles"
            placeholder={t('common:please-choose')}
            selectOptions={contactRoles?.map(
              ({ identifier, value }) => ({ label: value, value: identifier }),
            )}
            defaultValue={roleDefaultValue}
            isInvalid={!!errors?.roles}
            isMultiple
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      {(defaultData?.subject || defaultData?.channel === Channel.EMAIL) && (
        <FormFieldset>
          <FormGroup label={subjectLabel} error={errors?.subject}>
            <FormControl
              type="text"
              name="subject"
              autoComplete="off"
              defaultValue={defaultData?.subject ?? ''}
              isInvalid={!!errors?.subject}
              placeholder={t('reminders:placeholder.subject')}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormFieldset>
        <FormGroupWithActions
          error={errors?.content}
          label={defaultData?.channel === Channel.EMAIL ? t('reminders:email-content') : t('reminders:content')}
          mandatory
          className="ReminderEditForm__content"
          secondaryAction={<TagLink />}
          action={(
            <Button
              variant="link"
              onClick={showPreviewContentModal}
            >
              {t('models:preview')}
            </Button>
          )}
        >
          {defaultData && (
            <Fragment>
              {defaultData.channel !== Channel.SMS && (
                <ReminderEditFormContentEdit
                  reminder={defaultData}
                  onChange={setContent}
                />
              )}
              {defaultData.channel === Channel.SMS && (
                <FormControlSMSContent
                  name="content"
                  value={content}
                  onChange={setContent}
                />
              )}
            </Fragment>
          )}
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default observer(ReminderEditForm);
