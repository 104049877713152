import type { TFunction } from 'i18next';
import Config from 'config';
import type Misc from 'types/misc';
import getI18nChannel from 'utils/getI18nChannel';
import getI18nActionSelectStatus from 'utils/getI18nActionSelectStatus';
import { Channel } from 'types/models';
import ActionsManagerFilter from './ManagerFilter';

const ActionsFilters = (
  ct: TFunction,
  filters: Misc.Filter[],
): Misc.FilterDeclarationItem[] => [
  {
    title: `${ct('common:type')}:`,
    emptyText: ct('common:filters-selected.all-tasks'),
    key: 'type',
    type: 'select',
    selectData: Config.STATUSES_ID.map((status) => (
      { value: status, label: ct(getI18nActionSelectStatus(status)) }
    )),
    isNotRemovable: true,
  },
  {
    title: ct('common:period'),
    emptyText: ct('common:filters-selected.all-periods'),
    key: 'period',
    type: 'select',
    selectData: Config.FUTURE_PERIODS_ID
      .map((period) => (
        { value: period, label: ct(`common:select-period.${period}`) }
      )),
    isNotRemovable: true,
  },
  {
    title: ct('common:select-period.from'),
    key: 'periodA',
    type: 'dateInput',
  },
  {
    title: ct('common:select-period.to'),
    key: 'periodB',
    type: 'dateInput',
  },
  {
    title: ct('common:client'),
    emptyText: ct('clients:all-clients'),
    key: 'client',
    type: 'selectClient',
    entityValue: 'reference',
    defaultValue: filters.find(({ name }) => name === 'client')?.value || undefined,
  },
  {
    title: ct('common:manager'),
    emptyText: ct('debits:filters.all-managers'),
    key: 'manager',
    type: 'custom',
    customType: 'manager',
    entityValue: 'reference',
    custom: ActionsManagerFilter,
  },
  {
    title: ct('common:channel'),
    emptyText: ct('common:filters-selected.all-channels'),
    key: 'channel',
    type: 'select',
    selectData: Object.values(Channel).map((status) => (
      { value: status, label: ct(getI18nChannel(status)) }
    )),
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: ct('clients:filters.dunning-plans'),
    key: 'scenario',
    type: 'selectScenario',
    entityValue: 'reference',
    emptyText: ct('clients:filters.all-dunning-plans'),
    isNotRemovable: false,
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: ct('clients:filters.dunning-plans-group'),
    key: 'scenario_group',
    type: 'selectScenarioGroup',
    entityValue: 'reference',
    emptyText: ct('clients:filters.all-dunning-plans-group'),
    isNotRemovable: true,
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: ct('actions:filters.total-debt'),
    key: 'client_total_debt',
    type: 'numberInput',
    withCurrencyAddon: true,
    operators: [
      '=',
      '!=',
      '>',
      '<',
    ],
  },
  {
    title: ct('actions:filters.due-debt'),
    key: 'client_due_debt',
    type: 'numberInput',
    withCurrencyAddon: true,
    operators: [
      '=',
      '!=',
      '>',
      '<',
    ],
  },
  {
    title: ct('clients:filters.attribute'),
    key: 'attributes',
    type: 'selectAttributes',
    emptyText: ct('common:please-choose'),
    operators: [
      '=',
      '!=',
    ],
  },
  {
    title: ct('common:search-types.include'),
    key: 'include',
    type: 'custom',
    isHidden: true,
  },
  {
    title: ct('common:search-types.exclude'),
    key: 'exclude',
    type: 'custom',
    isHidden: true,
  },
];

export default ActionsFilters;
