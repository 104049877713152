import './index.scss';
import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { Contact, EAVValue } from 'types/models';
import { RightsCategoryType } from 'types/models';
import recomposeName from 'utils/recomposeName';
import formatIntlDate from 'utils/formatIntlDate';
import useApiRequest from 'hooks/useApiRequest';
import useFetch from 'hooks/useFetch2';
import apiContacts from 'api/contacts';
import type { FetchOneParams } from 'api/contacts';
import organizationStore from 'stores/Organization';
import Modal from 'components/Modal';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import AddressDetails from 'components/AddressDetails';
import ContactEditModal from 'components/ContactEditModal';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import SplitButtonDropdown from 'components/SplitButtonDropdown';
import { getAllEavsValues } from 'utils/getCustomerEavs';

type Props = {
  contactId: number,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onClose(): void,
};

const ListContactsView = (props: Props): JSX.Element | null => {
  const { contactId, onActionDone, onActionError, onClose } = props;
  const { attributes: organizationAttributes } = organizationStore;
  const { t } = useTranslation();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { remove, error, isLoading } = useApiRequest();

  const {
    data: contactData,
    refetch,
  } = useFetch<FetchOneParams, Contact>(
    {
      cacheKey: 'contact',
      id: contactId,
      filtering: [{
        name: 'withClient',
        value: '1',
      }],
    },
    apiContacts.one,
  );

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('contacts:remove'), t('contacts:actions.confirm.remove'));
  }, [showConfirm, t]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    if (!contactData) {
      return;
    }

    const response = await remove(apiContacts.deleteUrl(contactId));
    if (response === '') {
      onActionDone(t('contacts:actions.toast.deleted', { name: recomposeName(contactData) }));
      onClose();
      refetch();
    }
  }, [hideConfirm, contactData, remove, contactId, onActionDone, onClose, refetch, t ]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const {
    data: eavsContact,
  } = useFetch<FetchOneParams, EAVValue[]>(
    {
      cacheKey: 'contactAllEavs',
      id: contactData?.id!,
    },
    apiContacts.fetchEavs,
    { enabled: !!contactData?.id },
  );

  const attributes = useMemo(() => (
    getAllEavsValues(eavsContact, organizationAttributes.contact)
  ), [eavsContact, organizationAttributes]);

  const [showModal, hideModal] = useModal(() => (
    <ContactEditModal
      editType="edit"
      id={contactId}
      onClose={hideModal}
      onDone={(message) => { onActionDone(message); refetch(); }}
    />
  ), [contactId, onActionDone, refetch]);

  const actions = useMemo(() => (
    <SplitButtonDropdown
      title={t('common:edit')}
      onClick={showModal}
      disabled={isLoading}
      action="UPDATE"
      categoryType={RightsCategoryType.CONTACTS}
    >
      <ButtonWithUserRights
        variant="list-danger"
        onClick={handleConfirmDelete}
        disabled={isLoading}
        action="DELETE"
        categoryType={RightsCategoryType.CONTACTS}
      >
        {isLoading && <Loading hasNoText />}
        {!isLoading && t('common:remove')}
      </ButtonWithUserRights>
    </SplitButtonDropdown>
  ), [handleConfirmDelete, isLoading, showModal, t]);

  if (!contactData) {
    return null;
  }

  return (
    <>
      <Modal
        isOpened
        onClose={onClose}
        className="ListContactsView"
        title={recomposeName(contactData, { showCivility: true }) ?? ''}
        headerActions={actions}
      >
        <dl className="ListContactsView__infos">
          <div className="ListContactsView__infos__group">
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:role')}
              </dt>
              <dd className="ListContactsView__infos__value ListContactsView__infos__value--contact-role">
                {contactData.contactRole.label}
              </dd>
            </div>
          </div>
          <div className="ListContactsView__infos__group">
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:client')}
              </dt>
              <dd className="ListContactsView__infos__value">
                {recomposeName(contactData.client)}
              </dd>
            </div>
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:reference')}
              </dt>
              <dd className="ListContactsView__infos__value">
                {contactData.client.identifier}
              </dd>
            </div>
          </div>
        </dl>

        <dl className="ListContactsView__infos">
          <div className="ListContactsView__infos__group">
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:email')}
              </dt>
              <dd className="ListContactsView__infos__value ListContactsView__infos__value--email">
                {contactData.email && (
                  <a
                    title={contactData.email.toLowerCase()}
                    href={`mailto:${contactData.email.toLowerCase()}`}
                  >
                    {contactData.email.toLowerCase()}
                  </a>
                )}
              </dd>
            </div>
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:cell-phone')}
              </dt>
              <dd className="ListContactsView__infos__value">
                {contactData.cellPhone ? contactData.cellPhone : '-'}
              </dd>
            </div>
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:phone')}
              </dt>
              <dd className="ListContactsView__infos__value">
                {contactData.phone ? contactData.phone : '-'}
              </dd>
            </div>

          {attributes.map(({ id, label, value }) => (
            <div className="ListContactsView__infos__line" key={id}>
              <dt className="ListContactsView__infos__label">
                {label}
              </dt>
              <dd className="ListContactsView__infos__value">
                {value ?? '-'}
              </dd>
            </div>
          ))}

          </div>
          <div className="ListContactsView__infos__group">
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:address')}
              </dt>
              <dd className="ListContactsView__infos__value">
                <AddressDetails data={contactData} />
              </dd>
            </div>
          </div>
        </dl>

        <dl className="ListContactsView__infos">
          <div className="ListContactsView__infos__group">
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:created-at')}
              </dt>
              <dd className="ListContactsView__infos__value">
                {contactData.createdAt ? formatIntlDate(contactData.createdAt, 'dateLong') : '-'}
              </dd>
            </div>
          </div>
          <div className="ListContactsView__infos__group">
            <div className="ListContactsView__infos__line">
              <dt className="ListContactsView__infos__label">
                {t('common:updated-at')}
              </dt>
              <dd className="ListContactsView__infos__value">
                {contactData.updatedAt ? formatIntlDate(contactData.updatedAt, 'dateLong') : '-'}
              </dd>
            </div>
          </div>
        </dl>
      </Modal>

      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={recomposeName(contactData)}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </>
  );
};

export default observer(ListContactsView);
