import type { Collection } from 'types/api';
import type { Invitation, Organization } from 'types/models';
import requester from 'utils/requester';

export type FetchAllParams = { id: Organization['id'] | undefined };

/**
 * Récupère une liste des Invitations.
 *
 * @param params Paramètres de la requête (organization ID).
 * @returns Une collection d'Invitations.
 */
const all = async ({ id }: FetchAllParams): Promise<Collection<Invitation>> => {
  if (!id) {
    throw new Error('FetchAllInvitations: Missing organization ID.');
  }

  const { data } = await requester.get<Collection<Invitation>>(
    `organizations/${id}/invitations`,
  );
  return data;
};

/**
 * URL de la ressource d'API pour la création d'une Invitation (POST).
 */
const createUrl = 'invitations';

/**
 * Récupère l'URL de la ressource d'API pour supprimer l'Invitation.
 *
 * @param id L'ID d'Invitation
 * @returns URL du DELETE.
 */
const deleteUrl = (id: Invitation['id']) => `invitations/${id}`;

export default { all, createUrl, deleteUrl };
