import type { ClientDebit, ClientCredit, UnmutedScenario } from 'types/models';

export enum DebitCreditEntity {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export type DebitOrCredit = ClientDebit | ClientCredit;

export type UnMutedCreditsDebits = {
  scenario: UnmutedScenario,
};
