import parseIsoDate from 'utils/parseIsoDate';

type ValueInput = string | null | undefined;

const getDaysDiff = (value: ValueInput, dueAt: ValueInput): string => {
  const date = parseIsoDate(value);
  const dueAtDate = parseIsoDate(dueAt);

  if (!date || !dueAtDate) {
    return '0';
  }

  date.setHours(0, 0);
  dueAtDate.setHours(0, 0);

  const diff = date.getTime() - dueAtDate.getTime();
  const days = Math.round(diff / (24 * 60 * 60 * 1000));
  const dayDiff = Object.is(days, -0) ? 0 : days;

  return dayDiff > 0 ? `+${dayDiff}` : `${dayDiff}`;
};

export default getDaysDiff;
