import './index.scss';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';

type Props = {
  selection?: (number | string)[],
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const DebitsToCompleteBulkActions = (props: Props): JSX.Element | null => {
  const { selection, onActionDone, onActionError } = props;
  const { reference } = organizationStore.currentOrganization!;
  const { t } = useTranslation();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { put, error, isLoading } = useApiRequest();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(
      t('common:remove'),
      t('payrequests-draft:actions.confirm.bulk-remove', { count: selection?.length }),
    );
  }, [showConfirm, t, selection]);

  const handleSubmitDelete = useCallback(async () => {
    if (!selection?.length) {
      return;
    }

    const result = await put(
      `import/manage/${reference}/bulk/disable-draft`,
      { selection: Array.isArray(selection) ? selection : [selection] },
    );
    if (result?.errors) {
      onActionError('errors:unknown-retry');
    }
    onActionDone(t('payrequests-draft:actions.toast.deleted'));
    hideConfirm();
  }, [selection, hideConfirm, put, reference, onActionDone, onActionError, t]);

  useEffect(() => {
    if (error) {
      hideConfirm();
      onActionError(error.message || t('errors:unknown-retry'));
    }
  }, [error, hideConfirm, onActionError, t]);

  if (!selection?.length) {
    return null;
  }

  return (
    <div className="DebitsToCompleteBulkActions">
      {isLoading && <Loading text={t('common:saving')} isOverlay />}
      <Button
        variant="outline-light"
        onClick={handleConfirmDelete}
        className="DebitsToCompleteBulkActions__delete"
        disabled={isLoading}
      >
        {t('common:remove')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default observer(DebitsToCompleteBulkActions);
