import './index.scss';
import type { DefaultTFuncReturn } from 'i18next';
import Icon from 'components/Icon';

type Props = {
  count: number,
  title?: DefaultTFuncReturn,
  variant?: 'in-progress' | 'success' | 'error' | 'critical',
};

const ReportsCount = ({ count, title, variant = 'in-progress' }: Props): JSX.Element => {
  if (variant !== 'in-progress') {
    return (
      <div className={`ReportsCount ReportsCount--${variant}`} title={title ?? undefined}>
        {count}
      </div>
    );
  }

  return (
    <div className={`ReportsCount ReportsCount--${variant}`} title={title ?? undefined}>
      <Icon name="loading-line" spin />
      <span className="ReportsCount__count">{count}</span>
    </div>
  );
};

export default ReportsCount;
