import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import sanitizeHtmlConfig from 'config/sanitizeHtml';
import { Channel } from 'types/models';
import type { HistoryStepPreviewContent } from 'types/models';
import type { FetchPreviewParams } from 'api/historySteps';
import apiHistorySteps from 'api/historySteps';
import Modal from 'components/Modal';
import useFetch from 'hooks/useFetch2';
import PDFViewer from 'components/PDFViewer';
import Loading from 'components/Loading';
import CountCharactersHelper from 'components/CountCharactersHelper';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';

type Props = {
  code: string,
  channel: Channel,
  name?: string | null,
  description?: string | null,
  subject?: string | null,
  content?: string | null,
  onClose(): void,
};

const HistoryStepContentPreview = ({ onClose, channel, code, name, description, subject, content }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [currentReminder, setCurrentReminder] = useState<HistoryStepPreviewContent>();

  const { data, isLoading } = useFetch<FetchPreviewParams, HistoryStepPreviewContent[] | null>(
    {
      cacheKey: 'previewContent',
      code,
      name,
      description,
      subject,
      content,
    },
    apiHistorySteps.fetchPreviewContent,
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setCurrentReminder(data[0]);
    }
  }, [data]);

  const defaultContactRole = useMemo(() => (
    currentReminder && currentReminder.contact.length > 0 ?
      currentReminder.contact[0].contactRole : ''
  ), [currentReminder]);

  const sanitizedData = useMemo(() => (
    (currentReminder && currentReminder.content) ? sanitizeHtml(currentReminder.content, sanitizeHtmlConfig) : ''
  ), [currentReminder]);

  const contactRoleList: { value: string; label: string; }[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.flatMap(({ contact }) => (
      contact.map(({ contactRole, contactRoleLabel }) => ({
        value: contactRole,
        label: contactRoleLabel,
      }))
    ));
  }, [data]);

  const handleContactRoleChange = useCallback((oldName: string, newValue: string | null) => {
    const newCurrentReminder = data?.find((reminderPreview) =>
      reminderPreview.contact.some((contact) => contact.contactRole === newValue),
    );
    if (newCurrentReminder) {
      setCurrentReminder(newCurrentReminder);
    }
  }, [data]);

  return (
    <Modal
      isOpened
      className="HistoryStepContentPreview"
      title={`${t('common:preview')}`}
      onClose={onClose}
    >
      <p className="HistoryStepContentPreview__warning">
        {t('reminders:warning-missing-parsing')}
      </p>
      {isLoading && <Loading />}
      {!isLoading && currentReminder && (
        <FormGroup label={t('reminders:recipients')}>
          <FormSelect
            name="recipients"
            defaultValue={defaultContactRole}
            onSelect={handleContactRoleChange}
            selectOptions={contactRoleList}
            withClearButton={false}
          />
        </FormGroup>
      )}
      {(channel === Channel.EMAIL || channel === Channel.SMS) && (
        <div
          className="HistoryStepContentPreview__email-sms"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizedData }}
        />
      )}
      {(channel === Channel.LETTER_REGISTERED || channel === Channel.LETTER) && currentReminder && currentReminder.content && (
        <PDFViewer base64PDF={currentReminder.content} scale={1.1} />
      )}
      {channel === Channel.SMS && currentReminder && (
        <CountCharactersHelper
          number={currentReminder.content?.length ?? 0}
          className="HistoryStepContentPreview__helper"
        />
      )}
    </Modal>
  );
};

export default observer(HistoryStepContentPreview);
