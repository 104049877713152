import type { ManagerType, User } from 'types/models';
import { RightsCategory, RightsCategoryType } from 'types/models';

const getCategoryFromCategoryType = (
  managers: ManagerType[] | null | undefined,
  userIdentifier: User['id'] | null,
  categoryType: RightsCategoryType,
): RightsCategory => {
  const isNotAssigned = !managers || !managers.length;
  const isAssignedToUser = managers && managers.length > 0 && managers.some(
    ({ id }) => id === userIdentifier,
  );

  switch (categoryType) {
    case RightsCategoryType.CLIENTS:
      if (isNotAssigned) {
        return RightsCategory.CLIENTS_UNASSIGNED;
      }
      return isAssignedToUser ? RightsCategory.CLIENTS_ASSIGNED_TO_ME : RightsCategory.CLIENTS_ASSIGNED_SOMEONE;
    case RightsCategoryType.ACTIONS:
      if (isNotAssigned) {
        return RightsCategory.ACTIONS_UNASSIGNED;
      }
      return isAssignedToUser ? RightsCategory.ACTIONS_ASSIGNED_TO_ME : RightsCategory.ACTIONS_ASSIGNED_SOMEONE;
    case RightsCategoryType.CONTACTS:
      if (isNotAssigned) {
        return RightsCategory.CONTACTS_UNASSIGNED;
      }
      return isAssignedToUser ? RightsCategory.CONTACTS_ASSIGNED_TO_ME : RightsCategory.CONTACTS_ASSIGNED_SOMEONE;
    case RightsCategoryType.PAYMENTS:
      if (isNotAssigned) {
        return RightsCategory.PAYMENTS_UNASSIGNED;
      }
      return isAssignedToUser ? RightsCategory.PAYMENTS_ASSIGNED_TO_ME : RightsCategory.PAYMENTS_ASSIGNED_SOMEONE;
    case RightsCategoryType.PAYREQUESTS:
      if (isNotAssigned) {
        return RightsCategory.PAYREQUESTS_UNASSIGNED;
      }
      return isAssignedToUser ? RightsCategory.PAYREQUESTS_ASSIGNED_TO_ME : RightsCategory.PAYREQUESTS_ASSIGNED_SOMEONE;
    default:
      return RightsCategory.UNKNOWN_UNASSIGNED;
  }
};

export default getCategoryFromCategoryType;
