import './index.scss';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import FormRadios from 'components/FormRadios';
import { FormRadioValue } from 'components/FormRadios/Item';

interface Props {
  text: string,
  reference?: string,
  mutedDays: number | null,
}

const DebitsUnMutedForm = ({ text, reference, mutedDays }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [scenario, setScenario] = useState<string | number>('standard');

  const handleChangeScenario = useCallback(
    (newValue: FormRadioValue) => {
      setScenario(newValue);
    },
    [],
  );

  const radioItems = [
    {
      label: mutedDays ? t('debits:unmuted-actions.move-days', { mutedDays }) : t('debits:unmuted-actions.move'),
      value: 'move',
      id: 'unmuted-scenarios-move',
      disabled: (mutedDays && mutedDays < 1) ? true : false,
    },
    {
      label: t('debits:unmuted-actions.standard'),
      value: 'standard',
      id: 'unmuted-scenarios-standard',
    },
  ];

  return (
    <div className="DebitsUnMutedForm">
      <p className="DebitsUnMutedForm__text">{text}</p>
      {reference && <p className="DebitsUnMutedForm__entity-name">{reference}</p>}
      <FormRadios
        name="scenario"
        items={radioItems}
        value={scenario}
        onChange={handleChangeScenario}
      />
    </div>
  );
};

export default DebitsUnMutedForm;
