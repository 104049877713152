import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import { EavCategory } from 'types/models';
import type { EavConstructor } from 'types/models';
import organizationStore from 'stores/Organization';
import apiOrganization from 'api/organization';
import apiAttributes from 'api/attributes';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import getEavAddEditTitleLabel from 'utils/getEavAddEditTitleLabel';
import Form from './Form';

type Props = {
  type: EavCategory,
  defaultData?: EavConstructor,
  isEdit?: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onClose(): void,
};

const ModalAttributesEdit = (props: Props): JSX.Element => {
  const {
    type,
    defaultData,
    isEdit = false,
    onActionDone,
    onActionError,
    onClose,
  } = props;
  const { type: organizationType, id: organizationId } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(organizationType);
  const [errors, setErrors] = useState<ValidationErrors>();
  const isMountedRef = useIsMountedRef();
  const { post, put } = useApiRequest();

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    const body = {
      code: formData.identifier,
      label: formData.label,
      resourceName: isEdit ? undefined : type,
      required: formData.mandatory !== undefined ? true : false,
      type: formData.format,
      overviewDisplay: isEdit ? undefined : true,
      position: isEdit ? undefined : 0,
      organization: isEdit ? undefined : apiOrganization.resourceUrl(organizationId),
    };

    let result;
    if (isEdit && defaultData) {
      result = await put(apiAttributes.updateUrl(defaultData.id), body);
    } else {
      result = await post(apiAttributes.createUrl, body);
    }

    if (!isMountedRef.current || result?.errors || result === null) {
      onActionError(t(isEdit ? 'attributes:toast.error.edit' : 'attributes:toast.error.add', {
        id: formData.label,
      }));
      setErrors(result?.errors);
      return;
    }

    setErrors(undefined);
    onClose();
    onActionDone(t(isEdit ? 'attributes:toast.success.edit' : 'attributes:toast.success.add', {
      id: formData.label,
    }));
  }, [isEdit, type, organizationId, defaultData, isMountedRef, onClose, onActionDone, t, put, post, onActionError]);

  const title = useMemo(() => {
    return ct(getEavAddEditTitleLabel(type, isEdit));
  }, [type, isEdit, ct]);

  return (
    <ModalForm
      isOpened
      onSave={handleSubmit}
      onCancel={onClose}
      title={title}
    >
      <Form defaultData={defaultData} errors={errors} />
    </ModalForm>
  );
};

export default observer(ModalAttributesEdit);
