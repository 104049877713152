export type StoredEmail = {
  email: string,
  time: number,
};

const replaceOrAddEmail = (emails: StoredEmail[], emailToAdd: string) => {
  let newEmails = emails;
  if (newEmails.find(({ email }) => emailToAdd === email)) {
    newEmails.splice(
      newEmails.findIndex(({ email }) => email === emailToAdd),
      1,
    );
  }
  newEmails = [...newEmails, { email: emailToAdd, time: Date.now() }];
  return newEmails;
};

export default replaceOrAddEmail;
