import type { TFunction } from 'i18next';
import type { HistoryActivity } from 'types/models';
import { HistoryActivityType } from 'types/models';

const getI18nHistoryActivityExtraInfo = (t: TFunction, activity: HistoryActivity): string | null => {
  const { type, author, user } = activity;

  if ([HistoryActivityType.NOTE, HistoryActivityType.NOTE_HISTORY].includes(type)) {
    return t(
      'done-by-name',
      { name: user ? `${user.firstName} ${user.lastName}` : author },
    );
  }

  return null;
};

export default getI18nHistoryActivityExtraInfo;
