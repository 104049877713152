import './index.scss';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import type { CurrencyCode, Debit, DebitPost, OrganizationEAVs } from 'types/models';
import { ACCEPT_FILES_TYPE } from 'config';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelectCustomer from 'components/FormSelectCustomer';
import FormDatePicker from 'components/FormDatePicker';
import FormEavs from 'components/FormEavs';
import Dropzone from 'components/Dropzone';
import PDFViewer from 'components/PDFViewer';
import FormAmountCurrency from 'components/FormAmountCurrency';

type Props = {
  defaultData?: Debit,
  data?: DebitPost,
  customerId?: number,
  eav?: OrganizationEAVs | null,
  errors?: ValidationErrors | null,
  receipt: string | null,
  receiptName: string | null,
  onChangeReceipt(base64: string | null): void,
  onChangeReceiptName(name: string | null): void,
};

const DebitEditForm = ((props: Props): JSX.Element => {
  const {
    defaultData,
    customerId,
    eav,
    errors = null,
    onChangeReceipt,
    data,
    receiptName,
    onChangeReceiptName,
    receipt,
  } = props;

  const { currency: organizationCurrency, type } = organizationStore.currentOrganization!;

  const { t, ct } = useContextualTranslation(type);

  const handleRemove = useCallback(() => {
    onChangeReceiptName(null);
    onChangeReceipt(null);
  }, [onChangeReceipt, onChangeReceiptName]);

  const getBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onChangeReceiptName(!reader.result ? null : file.name);
      onChangeReceipt(!reader.result ? null : reader.result as string);
    };
  };

  return (
    <div className="DebitEditForm">
      <FormFieldset>
        <FormGroup label={ct('common:client')} mandatory error={errors?.client}>
          <FormSelectCustomer
            name="customerId"
            defaultValue={customerId}
            isInvalid={!!errors?.client}
          />
        </FormGroup>
        <FormGroup label={t('debits:reference')} mandatory error={errors?.reference}>
          <FormControl
            name="reference"
            autoComplete="off"
            defaultValue={data?.identifier || defaultData?.reference || ''}
            isInvalid={!!errors?.reference}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormAmountCurrency
          defaultAmount={data?.amount || defaultData?.amount || ''}
          defaultCurrency={(data?.currency as CurrencyCode) || defaultData?.currency || organizationCurrency}
          amountError={errors?.amount || null}
          currencyError={errors?.currency || null}
        />
        <FormGroup label={t('common:due-at')} mandatory error={errors?.dueDate}>
          <FormDatePicker
            name="dueAt"
            defaultValue={data?.dueDate || defaultData?.dueAt}
            isInvalid={!!errors?.dueDate}
          />
        </FormGroup>
        <FormGroup label={t('debits:issued-on')} error={errors?.issueDate}>
          <FormDatePicker
            name="issueAt"
            defaultValue={data?.issueDate || defaultData?.issueAt}
            isInvalid={!!errors?.issueDate}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('debits:subject')} error={errors?.subject}>
          <FormControl
            name="subject"
            autoComplete="off"
            defaultValue={data?.label || ''}
            isInvalid={!!errors?.subject}
          />
        </FormGroup>
      </FormFieldset>
      <FormGroup label={t('debits:receipt')} error={errors?.receipt}>
        {!receipt && (
          <Dropzone
            maxFiles={1}
            onDrop={(files: File[]) => getBase64(files[0])}
            accept={ACCEPT_FILES_TYPE.PDF}
            customInstructions={receiptName || t('common:dropzone.bill-content')}
          />
        )}
        {receipt && (
          <PDFViewer base64PDF={receipt} scale={0.3} onRemove={handleRemove} />
        )}
      </FormGroup>
      {eav && eav.debit?.length > 0 && (
        <FormEavs
          eavs={eav.debit}
          defaultValues={data?.eavsForm}
          errors={errors?.eav as unknown as ValidationErrors}
        />
      )}
    </div>
  );
});

export default observer(DebitEditForm);
