import './index.scss';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import type { StepAction, HistoryStepActionbWebHook } from 'types/models';
import { HistoryStepStatus, RightsRole, StepActionEntity } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import authStore from 'stores/Auth';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import Button from 'components/Button';
import Icon from 'components/Icon';
import StepActionIcon from 'components/StepActionIcon';
import HistoryStepDate from './Date';
import HistoryStepActions from './Actions';
import HistoryStepDetails from './Details';
import ReminderPreview from './ReminderPreview';
import ReminderEdit from './ReminderEdit';
import FreeTaskEdit from './FreeTaskEdit';
import HistoryStepInfosHeader from './InfosHeader';
import { haveHistoryEventsError } from './Details/History/WebHookStatus/Item/utils';

type Props = {
  data: StepAction | HistoryStepActionbWebHook,
  customerId?: number,
  showClientName?: boolean,
  showRelativeDateRef?: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  shouldChangePlannedToValidated?: boolean,
  showStepActions?: boolean,
};

const HistoryStep = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    data,
    showClientName = false,
    showRelativeDateRef = false,
    customerId,
    shouldChangePlannedToValidated = false,
    onActionDone,
    onActionError,
    showStepActions = true,
  } = props;
  const isUserReader = authStore.user?.roles.includes(RightsRole.ROLE_READER);
  const [openAction, setOpenAction] = useState<boolean>(false);

  const {
    id,
    name,
    status,
    debit,
    contacts,
    channel,
    entity,
    doneAt,
    missingContactRoles,
    incompleteContactRoles,
    validation,
    cancel,
    isInitialActive,
    at,
    errorMessage,
    code,
    isEditable,
  } = data;

  const {
    isConfirmShowed,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { put, error } = useApiRequest();
  const cache = useQueryClient();

  const handleOpenAction = useCallback(() => {
    if (openAction) {
      setOpenAction(false);
    } else {
      setOpenAction(true);
    }
  }, [openAction]);

  const handleActionDone = useCallback((message: string) => {
    // - Invalide le cache de toutes les actions
    const cacheToInvalidate = [
      'actionsFuture',
      'actionsDone',
      'debitHistorySteps',
      'notesForDebit',
      'reminderContent',
      'previewContent',
      'customerHistory',
      'customerActions',
      'notesForCustomer',
      'organizationHistorySteps',
      'organizationLastHistory',
      'myActions',
    ];
    cacheToInvalidate.forEach((cacheKey: string) => {
      cache.invalidateQueries({ queryKey: [cacheKey] });
    });

    onActionDone(message);
  }, [cache, onActionDone]);

  const handleSubmitCancelReminder = useCallback(async () => {
    hideConfirm();

    const response = await put(`task/manage/${id}/remove`);
    if (response) {
      const messageProps = {
        reminder: name,
        reference: debit?.identifier,
      };

      handleActionDone(
        t('clients:actions.toast.history.remove-reminder', messageProps),
      );
    }
  }, [hideConfirm, name, debit, id, t, put, handleActionDone]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [showEditModal, hideEditModal] = useModal(() => {
    switch (entity) {
      case StepActionEntity.TASK:
        return (
          <FreeTaskEdit
            task={data}
            customerId={data.client.id}
            onDone={handleActionDone}
            onClose={hideEditModal}
          />
        );
      case StepActionEntity.REMINDER:
        return (
          <ReminderEdit
            reminder={data}
            customerId={data.client.id}
            onDone={handleActionDone}
            onClose={hideEditModal}
          />
        );
      default:
        return null;
    }
  }, [entity, data, handleActionDone]);

  const [showPreviewModal, hidePreviewModal] = useModal(() => (
    code ? (
      <ReminderPreview
        reminder={{ code, channel, isEditable }}
        onClose={hidePreviewModal}
      />
    ) : null
  ), [code, channel, isEditable]);

  const isWaiting = useMemo(() => {
    const atDate = new Date(at?.at);
    const tonight = new Date();
    // - Pour éviter de mettre du jaune partout le jour des envois
    tonight.setHours(0, 0, 0, 0);

    return (
      [StepActionEntity.REMINDER, StepActionEntity.TASK].includes(entity)
      && isEditable === true
      && validation === null
      && cancel === null
      && isInitialActive === false
      && atDate < tonight
    );
  }, [entity, validation, cancel, isEditable, isInitialActive, at?.at]);

  const hasMissingContact = useMemo(() => {
    if (entity !== StepActionEntity.REMINDER) {
      return false;
    }
    if (contacts === null || contacts.length === 0) {
      return true;
    }
    if (!!doneAt && !contacts.find(({ sentAt }) => sentAt !== null)) {
      return true;
    }
    if (!!missingContactRoles && missingContactRoles.length > 0) {
      return contacts.every((contact) => (
        missingContactRoles.includes(contact.contactRole)
      ));
    }
    return false;
  }, [entity, contacts, doneAt, missingContactRoles]);

  const hasIncompleteContactRoles = useMemo(() => {
    if (entity !== StepActionEntity.REMINDER) {
      return false;
    }
    return !!incompleteContactRoles
      && incompleteContactRoles.length > 0;
  }, [entity, incompleteContactRoles]);

  const isFailedSent = useMemo(() => (
    entity === StepActionEntity.REMINDER
    && doneAt !== null
    && contacts !== null
    && !contacts.some((contact) => (
      contact.sentAt !== null && contact.errorMessage === null
    ))
  ), [entity, doneAt, contacts]);

  const hasError = useMemo(() => (
    status === HistoryStepStatus.ERROR && errorMessage !== null
  ), [status, errorMessage]);

  const actionWebHookFailed = haveHistoryEventsError(data);

  const classNames = classnames('HistoryStep', {
    'HistoryStep--is-waiting': isWaiting,
    'HistoryStep--is-error': hasError || hasIncompleteContactRoles || hasMissingContact || isFailedSent || actionWebHookFailed,
  });

  return (
    <li className={classNames}>
      <div className="HistoryStep__infos">
        <StepActionIcon entity={entity} channel={channel} />
        <HistoryStepInfosHeader
          data={data}
          showCustomer={showClientName}
          onActionDone={handleActionDone}
          onActionError={onActionError}
        />
        <HistoryStepDate
          data={data}
          isWaiting={isWaiting}
          isMissingContact={hasMissingContact}
          incompleteContactRoles={hasIncompleteContactRoles}
          isFailedSent={isFailedSent}
          failSentReason={errorMessage}
          isEvent={false}
          showRelativeDateRef={showRelativeDateRef}
          shouldChangePlannedToValidated={shouldChangePlannedToValidated}
        />
        <div className="HistoryStep__infos__actions">
          {!isUserReader && showStepActions && (
            <HistoryStepActions
              data={data}
              customerId={customerId}
              isMissingContact={hasMissingContact}
              hasIncompleteContactRoles={hasIncompleteContactRoles}
              isRemoved={cancel !== null}
              onActionDone={handleActionDone}
              onActionError={onActionError}
              onShowEdit={showEditModal}
            />
          )}
          <Button
            variant="light"
            onClick={handleOpenAction}
            className="HistoryStep__collapse-toggle"
            aria-controls={`open-action-collapse-${id}`}
            aria-expanded={openAction}
          >
            {!openAction ? <Icon name="caret-down" /> : <Icon name="caret-up" />}
          </Button>
        </div>
      </div>
      {(openAction) && (
        <div className="HistoryStep__collapse">
          <HistoryStepDetails
            data={data}
            onShowEdit={showEditModal}
            onShowReminder={showPreviewModal}
          />
        </div>
      )}
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={debit?.identifier}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitCancelReminder}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:cancel')}
        key={id}
      />
    </li>
  );
};

export default observer(HistoryStep);
