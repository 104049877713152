import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import InputModal from 'components/InputModal';
import Icon from 'components/Icon';
import authStore from 'stores/Auth';
import type { Note } from 'types/models';
import apiNotes from 'api/notes';
import apiClients from 'api/clients';
import apiUsers from 'api/users';
import apiDebits from 'api/debits';

type Props = {
  id: number,
  client: number,
  reference: string,
  onLoadingChange(isLoading: boolean): void,
  onDone(title: string, message: string): void,
};

const DebitsRowActionNote = (props: Props): JSX.Element => {
  const { id, client, reference, onLoadingChange, onDone } = props;
  const { type } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(type);
  const { user } = authStore;
  const { post, error, isLoading } = useApiRequest();

  const handleSubmit = useCallback(
    async (inputModalValue: { [field: string]: string }) => {
      const result = await post<Note>(apiNotes.createUrl, {
        client: apiClients.resourceUrl(client),
        debit: apiDebits.resourceUrl(id),
        user: apiUsers.resourceUrl(user!.id),
        ...inputModalValue,
        type: 'public',
      });

      if (!result) {
        return;
      }

      onDone(
        t('notes:toast.created'),
        ct('notes:toast.created-invoice', { reference }),
      );
    },
    [post, id, client, user, onDone, t, ct, reference],
  );

  useEffect(
    () => { onLoadingChange(isLoading); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const [showInputModal, hideInputModal] = useModal(() => (
    <InputModal
      isShow
      title={t('debits:add-note')}
      help={ct('debits:add-note-for-ref', { reference })}
      type="textarea"
      name="comment"
      isLoading={isLoading}
      error={error}
      onSubmit={(field) => {
        handleSubmit(field);
        hideInputModal();
      }}
      onCancel={hideInputModal}
    />
  ), [t, ct, reference, isLoading, error, handleSubmit]);

  return (
    <Button
      variant="list"
      onClick={showInputModal}
      className="DebitsRowActionNote"
    >
      <Icon name="comment" />
      {t('debits:add-note')}
    </Button>
  );
};

export default observer(DebitsRowActionNote);
