import { FilterValue } from 'react-table';
import { EAVType, EavConstructor, OrganizationEAVs, SelectorsEntity } from 'types/models';

/**
 * Récupère l'identifiant d'EAV.
 * @param value La valeur à partir de laquelle extraire l'identifiant EAV.
 * @param entityType Le type d'entité.
 * @returns L'identifiant EAV extrait.
 */
const getEAVIdentifier = (value: string, entityType: string): string => {
  return (value.match(new RegExp(`{EAV_${entityType}_(.*?)}`)) || [])[1] ?? '';
};

/**
 * Récupère le nom de l'entité à partir du EAV.
 * @param value la valeur EAV.
 * @returns Le nom de l'entité.
 */
const getEAVEntityName = (value: string): string => {
  if (value.startsWith('{EAV_DEBIT_')) {
    return SelectorsEntity.DEBIT;
  } else if (value.startsWith('{EAV_CREDIT_')) {
    return SelectorsEntity.CREDIT;
  } else if (value.startsWith('{EAV_CONTACT_')) {
    return SelectorsEntity.CONTACT;
  }
  return SelectorsEntity.CLIENT;
};

/**
 * Récupère EAV à partir de l'identifier.
 * @param value La valeur EAV à partir de laquelle extraire l'attribut.
 * @param eavs Les attributs de l'organisation.
 * @returns L'attribut extrait.
 */
const getAttributeFromEAVValue = (value: string | null | undefined, eavs: OrganizationEAVs, isId: boolean = false ): EavConstructor | null => {
  if (!value) {
    return null;
  }
  const entityType = getEAVEntityName(value);
  const entity = eavs[entityType.toLowerCase() as keyof OrganizationEAVs];
  const id = isId ? value : getEAVIdentifier(value, entityType);
  return entity.find(({ identifier }) => identifier === id) ?? null;
};

/**
 * Obtient le type d'EAV.
 * @param value La valeur de filtrage.
 * @param eavs Les attributs de l'organisation.
 * @returns Le type d'EAV obtenu.
 */
const getEAVType = (value: FilterValue | undefined, eavs: OrganizationEAVs) => {
  if (!value) {
    return 'textInput';
  }
  const attributeType = getAttributeFromEAVValue(value, eavs, true)?.type;
  switch (attributeType) {
    case EAVType.BOOLEAN:
      return 'yesNoSelect';
    case EAVType.NUMBER:
      return 'numberInput';
    case EAVType.DATE:
      return 'dateInput';
    default:
      return 'textInput';
  }
};

export {
  getAttributeFromEAVValue,
  getEAVEntityName,
  getEAVIdentifier,
  getEAVType,
};
