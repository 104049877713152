import './index.scss';
import { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import Config from 'config';
import type Misc from 'types/misc';
import { isHistoryActivity, type ClientHistory, type StepAction, HistoryActivity, isStepAction, StepActionTypeFilter } from 'types/models';
import type { FetchAllHistoryForCustomerParams } from 'api/historySteps';
import apiHistorySteps from 'api/historySteps';
import dateIsToday from 'utils/dateIsToday';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import formatIntlDate from 'utils/formatIntlDate';
import useFetchPaginated from 'hooks/useFetchPaginated2';
import customerContext from 'pages/Customer/customerContext';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import Pagination from 'components/Pagination';
import ExportButton from 'components/ExportButton';
import CustomerMainLayout from '../Layout';
import Filters from './Filters';
import CustomerHistoryDayList from './DayList';
import ModalDownloadAuditTrail from './ModalDownloadAuditTrail';

type ItemByDate = {
  title: string,
  items: ClientHistory[],
};

const DEFAULT_FILTERS = {
  type: [StepActionTypeFilter.REMINDER_SENT, StepActionTypeFilter.TASK_COMPLETED, StepActionTypeFilter.TASK_CANCELED, StepActionTypeFilter.REMINDER_CANCELED, StepActionTypeFilter.REMINDER_SENT_ERROR, 'pay_request', 'payment', 'note', 'data'],
};

const getDefaultTypeFilters = (search: string): string[] => {
  const queryFilters = new URLSearchParams(search.replace('?', ''));
  const filters = queryFilters.getAll('type');
  if (filters.length > 0) {
    return filters;
  }
  return DEFAULT_FILTERS.type;
};

const CustomerHistory = (): JSX.Element => {
  const { data: customer, reloadTick, onActionDone } = useContext(customerContext);
  const customerId = customer?.id;
  const { t } = useTranslation();
  const { search } = useLocation();
  const [pageSize, setPageSize] = useState<number>(Config.DEFAULT_PAGE_SIZE);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [filtering, setFiltering] = useState<Misc.Filter[]>(() => [{
    name: 'type',
    value: getDefaultTypeFilters(search),
  }]);

  const {
    isLoading,
    data,
    error,
    refetch,
    serverPagination,
  } = useFetchPaginated<FetchAllHistoryForCustomerParams, ClientHistory>(
    {
      cacheKey: 'customerHistory',
      params: {
        customerId,
        page: pageIndex + 1,
        pageSize: pageSize,
        filtering,
      },
    },
    apiHistorySteps.allHistoryForCustomer,
    { enabled: !!customerId, staleTime: 5000 },
  );

  const handleNextPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex + 1);
  }, []);

  const handlePreviousPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex - 1);
  }, []);

  const handleGotoPage = useCallback((page: number) => {
    setPageIndex(page);
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const handleChangeFilters = useCallback((filters: Misc.Filter[]) => {
    setFiltering(filters);
  }, []);

  const handleActionDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showDownloadAuditTrailModal, hideDownloadAuditTrailModal] = useModal(() => (
    <ModalDownloadAuditTrail
      customerId={customerId!}
      onDone={() => handleActionDone(t('common:export-success'))}
      onClose={hideDownloadAuditTrailModal}
    />
  ), [customerId, handleActionDone, t]);

  const { totalRecords } = serverPagination;

  const pageCount = useMemo(() => (
    Math.ceil(totalRecords / Config.DEFAULT_PAGE_SIZE)
  ), [totalRecords]);

  const itemsByDate = useMemo(() => {
    const itemsMap = new Map<string, ItemByDate>();
    if (!data) {
      return itemsMap;
    }

    data?.map((item) => {
      const day = formatIntlDate(item.loggedAt);

      let title;
      if (dateIsToday(day)) {
        title = t('common:today');
      } else if (dateIsLastTwoDays(day)) {
        title = t('common:yesterday');
      } else {
        title = formatIntlDate(item.loggedAt, 'dateLong');
      }

      if (!itemsMap.has(day)) {
        itemsMap.set(day, { title, items: [item] });
      } else {
        itemsMap.get(day)!.items.push(item);
      }
    });

    return itemsMap;
  }, [data, t]);

  const recentItemsCount = useMemo(() => (
    data?.reduce((prevCount, item) => {
      let usedDate = '';
      const activityItem = isHistoryActivity(item) ? item as HistoryActivity : null;
      const stepActionItem = isStepAction(item) ? item as StepAction : null;
      if (stepActionItem) {
        const { doneAt, cancel, validation, at } = stepActionItem;
        usedDate = doneAt ?? cancel?.at ?? validation?.at ?? at?.at;
      } else if (activityItem) {
        usedDate = activityItem.loggedAt;
      }

      return dateIsLastTwoDays(usedDate) ? prevCount + 1 : prevCount;
    }, 0) ?? 0
  ), [data]);

  return (
    <CustomerMainLayout
      sidebar={(
        <Filters
          onChange={handleChangeFilters}
          defaultFilters={getDefaultTypeFilters(search)}
        />
      )}
    >
      <div className="CustomerHistory">
        <div className="CustomerHistory__header">
          <h2 className="CustomerHistory__title">
            {t('common:history')}
          </h2>
          <div className="CustomerHistory__links">
            <ExportButton label={t('common:audit-trail')} onClick={showDownloadAuditTrailModal} />
          </div>
          {recentItemsCount > 0 && (
            <div className="CustomerHistory__recent-count">
              {t('common:count-recent-events', { count: recentItemsCount })}
            </div>
          )}
        </div>
        {isLoading && <Loading />}
        {data && Array.from(itemsByDate).map(([day, { title, items }]) => (
          <CustomerHistoryDayList
            key={day}
            title={title}
            items={items}
            onActionDone={handleActionDone}
            onActionError={handleActionDone}
          />
        ))}
        {(!isLoading && (!data || totalRecords === 0)) && (
          <p className="CustomerHistory__no-data">
            {t('common:no-data-was-found')}
          </p>
        )}
        {(data && totalRecords && pageCount > 1) ? (
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < (pageCount - 1)}
            pageCount={pageCount}
            totalRecords={totalRecords}
            nextPage={handleNextPage}
            previousPage={handlePreviousPage}
            onChangePageSize={setPageSize}
            onGotoPage={handleGotoPage}
          />
        ) : ''}
        {error && <ErrorMessage error={error} />}
      </div>
    </CustomerMainLayout>
  );
};

export default CustomerHistory;
