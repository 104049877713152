import './index.scss';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ImportItemStatus, ImportLineVariant } from 'types/models';
import type { Import } from 'types/models';
import apiImports from 'api/imports';
import type { FetchAllImportsReports } from 'api/imports';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch2';
import Loading from 'components/Loading';
import GoBackButton from 'components/GoBackButton';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import SearchInput from 'components/SearchInput';
import ButtonDatePicker from './ButtonDatePicker';
import Item from './Item';

type RouterState = {
  doneUploading?: number,
};

const Reports = (): JSX.Element => {
  const { t } = useTranslation();
  const { state: routerState } = useLocation<RouterState | undefined>();
  const { doneUploading } = routerState || { doneUploading: 0 };
  const { currentOrganization, notifications } = organizationStore;

  const [dateFilter, setDateFilter] = useState<string | null>(null);
  const [currentSearch, setCurrentSearch] = useState<string | null>(null);

  const {
    isLoading,
    data,
    refetch,
  } = useFetch<FetchAllImportsReports, Import[]>(
    {
      cacheKey: 'reports',
      id: currentOrganization?.id,
      params: {
        pageSize: dateFilter ? 0 : 15,
        date: dateFilter || null,
      },
    },
    apiImports.allImportsReports,
    { enabled: !!currentOrganization },
  );

  const { isToastShowed, showToast, hideToast, toastStatus, toastText } = useToast();

  useEffect(() => {
    refetch();
  }, [notifications, refetch]);

  useEffect(() => {
    if (doneUploading) {
      showToast(t('imports:file-started-processing', { count: doneUploading }), 'primary');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doneUploading]);

  const handleChange = useCallback((value: string | null) => {
    setDateFilter(value);
  }, []);

  const handleCurrentSearch = useCallback((value: string | null) => {
    setCurrentSearch(value && value.length >= 3 ? value.trim().toLowerCase() : null);
  }, []);

  const getStatus = useCallback((report: Import) => {
    const { status, stepExecutions: files } = report;
    if (status === ImportItemStatus.EXECUTING) {
      return ImportLineVariant.IN_PROGRESS;
    }
    if (
      status === ImportItemStatus.FAILED ||
      files[0]?.failures.length ||
      files[0]?.errors.length
    ) {
      return ImportLineVariant.ERROR;
    }
    if (files[0]?.warnings.length) {
      return ImportLineVariant.WARNING;
    }
    return ImportLineVariant.DEFAULT;
  }, []);

  const filteredReports = useMemo(() => (
    currentSearch ? data?.filter(({ importType, label }) => (
      importType.toLowerCase().includes(currentSearch)
        || label.toLowerCase().includes(currentSearch))) : data
  ), [currentSearch, data]);

  const inProgressReports = useMemo(() => (
    filteredReports?.filter((report) => getStatus(report) === ImportLineVariant.IN_PROGRESS)
      .sort((report1, report2) => Date.parse(report2.startAt) - Date.parse(report1.startAt))
  ), [filteredReports, getStatus]);

  const finishedReports = useMemo(() => (
    filteredReports?.filter((report) => getStatus(report) !== ImportLineVariant.IN_PROGRESS)
      .sort((report1, report2) => Date.parse(report2.endAt) - Date.parse(report1.endAt))
  ), [filteredReports, getStatus]);

  return (
    <div className="ImportReports">
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
      <div className="ImportReports__header">
        <div className="ImportReports__header__links">
          <GoBackButton />
        </div>
        <h1 className="ImportReports__header__title">{t('reports:reports')}</h1>
      </div>
      <div className="ImportReports__filters">
        <ButtonDatePicker onChange={handleChange} recentCount={data?.length} />
        <SearchInput
          placeholder={t('common:search-in-table')}
          onChange={handleCurrentSearch}
        />
      </div>
      {(inProgressReports?.length !== 0) && (
        <h2 className="ImportReports__section">{t('reports:in-progress')}</h2>
      )}
      {inProgressReports?.map((report, index) => {
        const { startAt, fileName, type } = report;
        return (
          <Item
            key={`${startAt}-${fileName}-${type}-${index}`}
            variant={getStatus(report)}
            report={report}
          />
        );
      })}
      <h2 className="ImportReports__section">
        {t('reports:report-history')}
      </h2>
      {isLoading && <Loading />}
      {(finishedReports?.length === 0 && !isLoading) && (
        <p className="ImportReports__no-data">
          {dateFilter ? t('reports:no-filtered-report') : t('reports:no-recent-report')}
        </p>
      )}
      {finishedReports?.map((report) => {
        const { endAt, fileName, type } = report;
        return (
          <Item
            key={`${endAt}-${fileName}-${type}`}
            variant={getStatus(report)}
            report={report}
          />
        );
      })}
    </div>
  );
};

export default observer(Reports);
