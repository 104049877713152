import { useCallback, useEffect, useRef, useState } from 'react';
import type Misc from 'types/misc';
import type { BaseFetchOptions, KeyParams } from 'hooks/useFetch';
import type { RequestFunction } from 'hooks/useFetch';
import useFetchPaginated from 'hooks/useFetchPaginated';
import type { FetchPaginatedConfig } from 'hooks/useFetchPaginated';

type FetchAllOptions<FetchOptions extends BaseFetchOptions> = FetchOptions & { fetchOptions: Misc.PaginatedFetchArgs };

const useInfiniteScroll = <FetchOptions extends BaseFetchOptions, TData extends {}>(
  cacheParams: KeyParams<FetchOptions>,
  pageSize: number,
  requestFunction: RequestFunction<Misc.Listing<TData>>,
  config?: FetchPaginatedConfig<TData>,
) => {
  const loader = useRef(null);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [infiniteData, setInfiniteData] = useState<TData[]>([]);

  const {
    data,
    isLoading,
    isFetching,
    serverPagination,
  } = useFetchPaginated<FetchAllOptions<FetchOptions>, TData>(
    { ...cacheParams, fetchOptions: { pageSize, pageIndex } },
    requestFunction,
    config,
  );

  const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
    if (isFetching) {
      return;
    }

    const target = entries[0];
    if (!target.isIntersecting) {
      return;
    }

    if ((serverPagination?.totalPages ?? 1) <= pageIndex) {
      return;
    }

    setPageIndex((prevPageIndex) => prevPageIndex + 1);
  }, [isFetching, serverPagination, pageIndex]);

  useEffect(() => {
    if (!loader.current) {
      return;
    }

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    });
    observer.observe(loader.current);

    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  useEffect(() => {
    setInfiniteData((prevData) => [...prevData, ...(data ?? [])]);
  }, [data]);

  const reset = useCallback(() => {
    setInfiniteData([]);
  }, []);

  return { loader, pageIndex, isLoading, isFetching, infiniteData, reset };
};

export default useInfiniteScroll;
