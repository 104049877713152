import './index.scss';
import { useMemo, Fragment, useState, useCallback } from 'react';
import type { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type { BusinessUnit, RoleUser, User } from 'types/models';
import { RightsRole } from 'types/models';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import Button from 'components/Button';
import Perimeter from 'components/Perimeter';
import FormPerimeter from 'components/FormPerimeter';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import apiCollaborators from 'api/collaborator';
import getI18nUserRights from 'utils/getI18nUserRights';

type Props = {
  collaborator: User,
  perimeter: BusinessUnit[],
  availableCategories: BusinessUnit[],
  roles: RoleUser[],
  onCanSave(canSave: boolean): void,
  onCategoriesUpdate(updatedCategories: BusinessUnit[]): void,
  onActionDone(test: string): void,
  onActionError(test: string): void,
};

const ModalEditCollaboratorsForm = (props: Props): JSX.Element => {
  const {
    collaborator,
    perimeter,
    availableCategories,
    roles,
    onCanSave,
    onCategoriesUpdate,
    onActionError,
    onActionDone,
  } = props;

  const { t } = useTranslation();
  const [showPerimeterForm, setShowPerimeterForm] = useState(false);
  const { put } = useApiRequest();
  const isMountedRef = useIsMountedRef();
  const {
    showConfirm,
    hideConfirm,
    isConfirmShowed,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const userRightsOptions = useMemo(() => (
    roles?.filter(({ role }) =>
      ![
        RightsRole.ROLE_USER,
        RightsRole.ROLE_SUPER_ADMINISTRATOR,
        RightsRole.ROLE_COORDINATOR,
        RightsRole.ROLE_DEMO,
      ].includes(role),
    ).map(({ role }) => ({
      label: t(getI18nUserRights(role)),
      value: role,
    }))
  ), [roles, t]);

  const allRoles = useMemo(() => (
    collaborator?.roles.filter((userRight) => userRight !== RightsRole.ROLE_USER).map((role) => ({
      identifier: role,
    }))
  ), [collaborator]);

  const defaultIdentifier = useMemo(() => (
    collaborator?.occupations.map((occupation) => (occupation.identifier))
  ), [collaborator]);

  const isSuperAdmin = useMemo(() => (
    collaborator?.roles.findIndex((role) => (role === RightsRole.ROLE_SUPER_ADMINISTRATOR)) >= 0
  ), [collaborator]);

  const handleShowPerimeterForm = useCallback((isShown: boolean) => {
    setShowPerimeterForm(isShown);
    onCanSave(isShown);
  }, [onCanSave]);

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('perimeters:confirm.perimeter-delete-title'), t('perimeters:confirm.perimeter-delete'));
  }, [showConfirm, t]);

  const collaboratorName = useMemo(() => (
    collaborator ? `${collaborator.firstName} ${collaborator.lastName}` : ''
  ), [collaborator]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const result = await put<User>(
      apiCollaborators.updateUrl(collaborator.id),
      { ...collaborator, businessUnits: [] },
    );

    if (!isMountedRef) {
      return;
    }

    if (!result) {
      onActionError(t('collaborators:toast.error.collaborator-edit', { name: collaboratorName }));
      return;
    }

    onActionDone(t('collaborators:toast.success.collaborator-edit', { name: collaboratorName }));
    onCategoriesUpdate([]);
  }, [
    hideConfirm,
    put,
    collaborator,
    isMountedRef,
    onActionDone,
    t,
    collaboratorName,
    onCategoriesUpdate,
    onActionError,
  ]);

  const perimeterActions = [
    <Button variant='list' onClick={() => { handleShowPerimeterForm(true); }}>{t('common:edit')}</Button>,
    <Button variant='list-danger' onClick={() => { handleConfirmDelete(); }}>{t('common:remove')}</Button>,
  ];

  const showAddPerimeter = (availableCategories && availableCategories.length > 0) && perimeter.length === 0;
  const showEditPerimeter = (availableCategories && availableCategories.length > 0) && perimeter.length > 0;

  return (
    <Fragment>
      <FormFieldset>
        <FormGroup label={t('common:first-name')}>
          <FormControl
            name="firstName"
            defaultValue={collaborator.firstName || ''}
          />
        </FormGroup>
        <FormGroup label={t('common:last-name')}>
          <FormControl
            name="lastName"
            defaultValue={collaborator.lastName || ''}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('collaborators:identifier')}>
          <FormControl
            name="identifier"
            defaultValue={defaultIdentifier}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => { e.stopPropagation(); }}
            autoComplete="off"
          />
        </FormGroup>
        <FormGroup label={t('collaborators:level')}>
          {userRightsOptions && userRightsOptions.length > 0 && (
            <FormSelect
              name="roles"
              placeholder={t('common:please-choose')}
              selectOptions={isSuperAdmin ? [
                {
                  label: t(getI18nUserRights(RightsRole.ROLE_SUPER_ADMINISTRATOR)),
                  value: RightsRole.ROLE_SUPER_ADMINISTRATOR,
                },
                ...userRightsOptions,
              ] : userRightsOptions}
              defaultValue={allRoles}
            />
          )}
        </FormGroup>
      </FormFieldset>
      <div className="ModalEditCollaboratorsForm__perimeter">
        <div className="ModalEditCollaboratorsForm__perimeter__header">
          <div className="ModalEditCollaboratorsForm__perimeter__title">
            {t('perimeters:perimeters')}
          </div>
          {showEditPerimeter && (
            <Button variant="link" className="ModalEditCollaboratorsForm__perimeter__link" onClick={() => { handleShowPerimeterForm(true); }}>
              {t('perimeters:modify-perimeter')}
            </Button>
          )}
          {showAddPerimeter && (
            <Button variant="link" className="ModalEditCollaboratorsForm__perimeter__link" onClick={() => { handleShowPerimeterForm(true); }}>
              {t('perimeters:add-perimeter')}
            </Button>
          )}
        </div>
        {!availableCategories || availableCategories.length === 0 && (
          <div className="ModalEditCollaboratorsForm__perimeter__association_error">
            {t('perimeters:errors.no-association-possible-title')}
            <div className="ModalEditCollaboratorsForm__perimeter__association_error__content">
              {t('perimeters:errors.no-association-possible-content')}
            </div>
          </div>
        )}
        {perimeter.length > 0 && !showPerimeterForm && (
          <Perimeter perimeter={perimeter} actions={perimeterActions} />
        )}
        {showPerimeterForm && (
          <FormPerimeter
            availableCategories={availableCategories}
            perimeter={perimeter}
            onClose={() => { handleShowPerimeterForm(false); }}
            onChange={onCategoriesUpdate}
          />
        )}
        <Confirm
          titleModal={confirmTitle}
          text={confirmText}
          entityName={collaboratorName}
          isShow={isConfirmShowed}
          onConfirm={handleSubmitDelete}
          onCancel={hideConfirm}
          variant="danger"
          confirmButtonText={t('common:remove')}
        />
      </div>
      <FormFieldset>
        <FormGroup label={t('common:phone')}>
          <FormControl
            name="phone"
            autoComplete="off"
            defaultValue={collaborator?.phone || ''}
          />
        </FormGroup>
        <FormGroup label={t('common:email')}>
          <FormControl
            name="email"
            type="email"
            autoComplete="off"
            defaultValue={collaborator?.email || ''}
          />
        </FormGroup>
      </FormFieldset>
    </Fragment>
  );
};

export default ModalEditCollaboratorsForm;
