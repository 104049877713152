import './index.scss';
import { Fragment } from 'react';
import { DebitType, type Debit } from 'types/models';
import DebitSideActions from './Actions';
import DebitNotes from './Notes';
import DebitContacts from './Contacts';
import DebitInfos from './Infos';

export type Props = {
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  debitData: Debit | null | undefined,
  reloadTick: number,
};

const DebitSide = (props: Props): JSX.Element => {
  const { onActionDone, onActionError, debitData, reloadTick } = props;
  const isVirtual = debitData?.type === DebitType.SETTLEMENT;

  return (
    <div className="DebitSide">
      {debitData && (
        <Fragment>
          {!isVirtual &&
            <DebitSideActions
              data={debitData}
              onError={onActionError}
              onDone={onActionDone}
            />
          }
          <DebitContacts customerId={debitData.client.id} />
          {!isVirtual &&
            <DebitNotes
              paymentRef={debitData.reference}
              reloadTick={reloadTick}
              clientId={debitData.client.id}
              paymentId={debitData.id}
              onDone={onActionDone}
            />
          }
          <DebitInfos data={debitData} />
        </Fragment>
      )}
    </div>
  );
};

export default DebitSide;
