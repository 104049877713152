import type { Collection } from 'types/api';
import type { Organization, RoleAlias } from 'types/models';
import requester from 'utils/requester';

export type FetchAllParams = { id: Organization['id'] | undefined };

/**
 * Récupère une liste des Role Alias.
 *
 * @param params Paramètres de la requête (organization ID).
 * @returns Une collection de Role Alias.
 */
const all = async ({ id }: FetchAllParams): Promise<RoleAlias[]> => {
  if (!id) {
    throw new Error('FetchAllInvitations: Missing organization ID.');
  }

  const { data } = await requester.get<Collection<RoleAlias>>('role_aliases');
  return data['hydra:member'].filter((roleAlias) => roleAlias.organization.id === id);
};

/**
 * Récupère l'URL de la ressource d'API pour mapper une association de role alias.
 *
 * @param id L'ID du role alias
 * @returns URL du role alias.
 */
const resourceUrl = (id: string | number) => `/api/role_aliases/${id}`;

export default { all, resourceUrl };
