import { SortingRule } from 'react-table';
import type Misc from 'types/misc';
import { SortDirection } from 'types/api';
import type { Collection, Filter, SearchResult } from 'types/api';
import type { Organization, DebitDraft, Debit, Client, User, DebitSummary, EAVValue } from 'types/models';
import { DebitType } from 'types/models';
import { DebitStatus } from 'types/models';
import requester from 'utils/requester';

export type FetchAllParams = {
  page?: number | undefined,
  pageSize?: number,
  organizationReference?: Organization['reference'] | undefined,
  locale?: User['locale'] | undefined,
  filtering?: Misc.Filter[],
  sort?: SortingRule<DebitSummary>,
};

export type FetchAllForCustomerParams = {
  id: Client['id'] | undefined,
  params?: FetchAllParams | undefined
};

export type FetchOneParams = {
  id: Debit['id'],
  debitType: DebitType,
};

export type FetchEAVValues = {
  id: Debit['id'],
};

export type DebitsCount = {
  active: number;
  paid: number;
  disabled: number;
  litigated: number;
  draft: number;
  all: number;
};

export type FetchCountParams = {
  filtering?: Misc.Filter[],
  organizationReference?: Organization['reference'] | undefined,
};

const getMappingFromFormToApi = (name: string) => {
  const map = {
    search: 'full_text',
    status: 'status',
    notStatus: 'status',
    users: 'owner',
    dunningPlan: 'scenario',
    mute: 'is_muted',
    deadline_from: 'due_date',
    deadline_to: 'due_date',
    amount_from: 'amount',
    amount_to: 'amount',
    client: 'client',
    is_paid: 'is_paid',
    enabled: 'enabled',
    is_litigated: 'is_litigated',
    categories: 'business_units',
  } as Record<string, string>;

  return map[name] ?? '';
};

const getFilterValue = (name: string, value: string | string[]): Filter['value'] => {
  const currentValue = name !== 'categories' && (Array.isArray(value) && value.length > 0) ? value[0] : value;
  switch (name) {
    case 'is_paid':
    case 'enabled':
    case 'is_litigated':
    case 'mute':
      return currentValue === '1' ? true : false;
    case 'deadline_from':
    case 'deadline_to':
      return `${currentValue.toString()} 00:00:00`;
    case 'search':
    case 'categories':
      return currentValue;
    case 'amount_from':
    case 'amount_to':
      return Number(currentValue);
    case 'users':
    case 'status':
    case 'notStatus':
    case 'dunningPlan':
    case 'client':
      return [currentValue] as string[];
    default:
      return null;
  }
};

const getFilterOperator = (name: string): Filter['operator'] => {
  switch (name) {
    case 'status':
    case 'users':
    case 'dunningPlan':
    case 'client':
    case 'categories':
      return 'IN';
    case 'notStatus':
      return 'NOT IN';
    case 'amount_from':
      return '>=';
    case 'deadline_from':
      return '>';
    case 'amount_to':
      return '<=';
    case 'deadline_to':
      return '<';
    default:
      return '=';
  }
};

const getFilter = (name: string, value: any): Filter => ({
  field: getMappingFromFormToApi(name),
  operator: getFilterOperator(name),
  value: getFilterValue(name, value),
  context: [],
});

const getBetweenFilter = (filtering: Misc.Filter[], fieldName: string): Filter | null => {
  const filters: Misc.Filter[] = filtering ?
    filtering.filter(({ name }) => name === `${fieldName}_from` || name === `${fieldName}_to`) : [];

  const fromFilter = filters.find(({ name }) => name === `${fieldName}_from`);
  const toFilter = filters.find(({ name }) => name === `${fieldName}_to`);
  if (!fromFilter || !toFilter) {
    return null;
  }

  const fromValue = (Array.isArray(fromFilter.value) && fromFilter.value.length > 0)
    ? fromFilter.value[0]
    : fromFilter.value;
  const toValue = (Array.isArray(toFilter.value) && toFilter.value.length > 0)
    ? toFilter.value[0]
    : toFilter.value;

  if (fromValue && toValue) {
    const isDate = fieldName === 'deadline';
    return {
      field : isDate ? 'due_date' : 'amount',
      operator: 'BETWEEN',
      value: isDate ? [
        `${fromValue.toString()} 00:00:00`,
        `${toValue.toString()} 23:59:59`,
      ] : [Number(fromValue), Number(toValue)],
      context: [],
    };
  }
  return null;
};

const transformSortForEndpoint = (sort: SortingRule<DebitSummary>) => {
  let field = sort.id;
  switch (sort.id) {
    case 'code':
      field = 'code';
      break;
    case 'client':
      field = 'client_denomination';
      break;
    case 'amount':
      field = 'amount';
      break;
    case 'dueDate':
      field = 'due_date';
      break;
    case 'createdAt':
      field = 'created_at';
      break;
  }
  return {
    field,
    direction: sort.desc ? SortDirection.DESC : SortDirection.ASC,
  };
};

/**
 * Récupère une liste des débits.
 *
 * @param id Id de l'organization.
 * @param params Paramètres de la requête (pagination, filres...).
 * @returns Une collection de débits.
 */
const all = async (
  id: Organization['id'] | undefined,
  params: FetchAllParams | undefined,
): Promise<SearchResult<DebitSummary>> => {
  if (!id) {
    throw new Error('GetAllDebits: Missing organization ID.');
  }

  if (!params) {
    throw new Error('FetchAllDebits: Missing params');
  }

  const defaultParams = {
    page: 0,
    filtering: [{ name: 'status', value: DebitStatus.IN_PROGRESS }],
  };

  const {
    page = defaultParams.page,
    filtering = defaultParams.filtering,
    locale,
    organizationReference,
    pageSize,
    sort,
  } = params;

  if (!organizationReference) {
    throw new Error('FetchAllDebits: Missing organization reference.');
  }

  const filterNames = new Set(filtering.map(({ name }) => name));
  const isDeadlineFilter = filterNames.has('deadline_from') && filterNames.has('deadline_to');
  const isAmountFilter = filterNames.has('amount_from') && filterNames.has('amount_to');
  const isCompositeFilter = (name: string, filters: string[]) => filters.includes(name);
  const createFilter = ({ name, value }: Misc.Filter) => getFilter(name, value);

  const filters: Filter[] = filtering
    ? filtering
      .filter(({ name, value }) =>
        !(
          isCompositeFilter(name, ['deadline_from', 'deadline_to']) && isDeadlineFilter ||
          isCompositeFilter(name, ['amount_from', 'amount_to']) && isAmountFilter ||
          (name === 'status' && value === 'ALL')
        ),
      )
      .map(createFilter)
    : [];

  if (isDeadlineFilter) {
    const filtersForDeadlineValue = getBetweenFilter(filtering, 'deadline');

    if (filtersForDeadlineValue) {
      filters.push(filtersForDeadlineValue);
    }
  }

  if (isAmountFilter) {
    const filtersForAmountValue = getBetweenFilter(filtering, 'amount');

    if (filtersForAmountValue) {
      filters.push(filtersForAmountValue);
    }
  }

  const { data } = await requester.put<SearchResult<DebitSummary>>(
    'debits',
    {
      channel: organizationReference,
      locale,
      filters,
      page,
      size: pageSize,
      sort: sort ? transformSortForEndpoint(sort) : undefined,
    },
  );
  return data;
};

/**
 * Récupère une liste des débits (Draft).
 *
 * @param id Id de l'organization.
 * @param params Paramètres de la requête (pagination, filres...).
 * @returns Une collection de débits (Draft).
 */
const allDrafts = async (
  id: Organization['id'] | undefined,
  params: FetchAllParams | undefined,
): Promise<Collection<DebitDraft>> => {
  if (!id) {
    throw new Error('GetAllDebits: Missing organization ID.');
  }

  const { page, filtering } = params ?? {
    page: 1,
    filtering: null,
  };

  const queryData = new URLSearchParams();
  queryData.append('page', (page ?? 1).toString());
  queryData.append('status', 'draft');

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }: Misc.Filter) => {
      queryData.append(name, (Array.isArray(value) ? value.join(',') : value) ?? '');
    });
  }

  const { data } = await requester.get<Collection<DebitDraft>>(
    `organizations/${id}/debits?${queryData.toString()}`,
    // TODO: Ce lien n'a pas encore migré du côté serveur
    // en v1 c'était pay-requests/{organization}/importFailed
  );
  return data;
};

/**
 * Retourne le nombre de débits de l'organisation par status.
 *
 * @param filtering Les filtres à appliquer à la recherche.
 * @param organizationReference La référence de l'organisation.
 * @returns Nombre de débits.
 */
const count = async ({ filtering, organizationReference }: FetchCountParams): Promise<DebitsCount> => {
  if (!organizationReference) {
    throw new Error('GetCountDebits: Missing organization.');
  }

  const queryData = new URLSearchParams();
  queryData.append('organization', organizationReference);

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        name,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const { data } = await requester.get<DebitsCount>(
    `/debits/search_result_count?${queryData.toString()}`,
  );

  return data;
};

/**
 * Récupère l'URL de la ressource d'API pour récupère une liste des débits du client.
 *
 * @param id L'ID du client
 * @returns URL.
 */
const allForCustomerUrl = (id: Client['id']) => `clients/${id}/debits`;

/**
 * Récupère une liste des débits imputables du client.
 *
 * @param params Paramètres de la requête (id, pagination, filres...).
 * @returns Une collection de débits imputables.
 */
const allForCustomerImputable = async (
  { id }: FetchAllForCustomerParams,
): Promise<Debit[]> => {
  if (!id) {
    throw new Error('allForCustomerDebit: Missing customer ID.');
  }
  const { data } = await requester.get<Collection<Debit>>(
    `${allForCustomerUrl(id)}/imputable`,
  );
  return data['hydra:member'];
};

/**
 * Récupère une liste des débits du client.
 *
 * @param params Paramètres de la requête (id, pagination, filres...).
 * @returns Une collection de débits.
 */
const allForCustomer = async (
  { id }: FetchAllForCustomerParams,
): Promise<Debit[]> => {
  if (!id) {
    throw new Error('allForCustomerDebit: Missing customer ID.');
  }
  const { data } = await requester.get<Collection<Debit>>(
    allForCustomerUrl(id),
  );
  return data['hydra:member'];
};

/**
 * Récupère les données d'un débit.
 *
 * @param id ID du débit.
 * @returns Les données de débit.
 */
const one = async ({ id, debitType }: FetchOneParams) => (
  (await requester.get<Debit>(
    `${debitType === DebitType.SETTLEMENT ? 'settlement_plans_demat' : 'debits'}/${id}`,
  )).data
);

/**
 * Récupère l'URL de la ressource API pour mapper une association de débit.
 *
 * @param id L'ID de débit
 * @returns URL de l'association.
 */
const resourceUrl = (id: Debit['id']) => `/api/debits/${id}`;

/**
 * URL de la ressource API pour la création d'un débit
 */
const createUrl = 'debits';

/**
 * Récupère l'URL de la ressource API pour supprimer le débit.
 *
 * @param id L'ID de débit.
 * @returns URL du DELETE.
 */
const deleteUrl = (id: Debit['id']) => `debits/${id}`;

/**
 * Récupère l'URL de la ressource API pour modifier le débit.
 *
 * @param id L'ID de débit
 * @returns URL du PUT.
 */
const updateUrl = (id: Debit['id']) => `debits/${id}`;

/**
 * Récupère l'URL de la ressource API pour modifier le statut de débit.
 *
 * @param id L'ID de débit
 * @returns URL du PUT.
 */
const updateStatusUrl = (id: Debit['id']) => `debits/${id}/statuses`;

/**
 * URL de la ressource API pour modifier des débits (bulk).
 */
const bulkUpdateUrl = 'debits';

/**
 * Récupère l'URL de la ressource d'API pour supprimer list des débits (Bulk).
 *
 * @param ids L'ID des débits
 * @returns URL du DELETE.
 */
const bulkDeleteUrl = (ids: Client['id'][]) => {
  const queryData = new URLSearchParams();
  queryData.append('ids', ids.join(','));
  return `debits?${queryData.toString()}`;
};

/**
 * Récupère l'URL de la ressource API pour updater les eavs d'un débit.
 *
 * @param id L'ID de débit
 * @returns URL du PUT.
 */
const updateDebitEavsUrl = (id: Debit['id']) => `debits/${id}/values`;

/**
 * Récupère les eavs d'un débit.
 *
 * @param id Id dy débit.
 * @returns les eavs d'un débit.
 */
const fetchEavs = async ({ id }: FetchEAVValues): Promise<EAVValue[]> => {
  const { data } = await requester.get<Collection<EAVValue>>(`debits/${id}/values`);
  return data['hydra:member'];
};

/**
 * Récupère l'URL de la ressource API pour upload l'invoice d'un débit.
 *
 * @param id L'ID de débit
 * @returns URL du POST.
 */
const uploadDebitInvoiceUrl = (id: Debit['id']) => `debits/${id}/invoice`;

export default {
  one,
  all,
  count,
  createUrl,
  deleteUrl,
  resourceUrl,
  updateUrl,
  updateStatusUrl,
  bulkUpdateUrl,
  bulkDeleteUrl,
  allDrafts,
  fetchEavs,
  allForCustomer,
  allForCustomerUrl,
  updateDebitEavsUrl,
  allForCustomerImputable,
  uploadDebitInvoiceUrl,
};
