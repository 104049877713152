import './index.scss';
import { useMemo, useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import customerContext from 'pages/Customer/customerContext';
import Icon from 'components/Icon';

type StatEmotionTodos = 'ok' | 'looking' | 'studious';

const CustomerStatsTodos = (): JSX.Element | null => {
  const { data: customer } = useContext(customerContext);
  const { t } = useTranslation();

  const count = useMemo<number>(() => {
    if (!customer?.statistics) {
      return 0;
    }
    const { pendingActionsCount, recentActivityCount } = customer.statistics;
    return (pendingActionsCount ?? 0) + (recentActivityCount ?? 0);
  }, [customer]);

  const actionsCount = useMemo<number>(() => (
    customer?.statistics?.pendingActionsCount ?? 0
  ), [customer]);

  const activityCount = useMemo<number>(() => (
    customer?.statistics?.recentActivityCount ?? 0
  ), [customer]);

  const emotionTodos = useMemo<StatEmotionTodos>(() => {
    if (actionsCount >= 1 && actionsCount <= 2) {
      return 'looking';
    }
    if (actionsCount > 2) {
      return 'studious';
    }
    if (count < 3) {
      return 'ok';
    }
    if (count >= 3 && count <= 5) {
      return 'looking';
    }
    return 'studious';
  }, [actionsCount, count]);

  return (
    <div className="CustomerStatsTodos">
      <div className="CustomerStatsTodos__icon">
        <Icon name={`emoji-blue-${emotionTodos}`} />
      </div>
      <div className="CustomerStatsTodos__details">
        {count === 0 && (
          <p className="CustomerStatsTodos__count">
            {t('clients:statistics.no-element-to-review')}
          </p>
        )}
        {count > 0 && (
          <Fragment>
            <p className="CustomerStatsTodos__count">
              {t('clients:statistics.count-elements-to-review', { count })}
            </p>
            <p className="CustomerStatsTodos__counts">
              {activityCount > 0 && (
                t('clients:statistics.count-recent-events-last-2-days', { count: activityCount })
              )}
              {actionsCount > 0 && activityCount > 0 && ', '}
              {actionsCount > 0 && (
                t('clients:statistics.count-pending-actions', { count: actionsCount })
              )}
            </p>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default CustomerStatsTodos;
