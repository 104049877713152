type NamedEntity = {
  type?: 'individual' | 'company',
  reference?: string,
  firstName?: string | null,
  lastName?: string | null,
  corporateName?: string | null,
  civility?: string | null,
};

type RecomposeNameOptions = {
  showCivility?: boolean,
  showReference?: boolean,
};

const recomposeName = (entity: NamedEntity, options?: RecomposeNameOptions): string | null => {
  const {
    type,
    reference,
    corporateName,
    firstName,
    lastName,
    civility,
  } = entity;

  const defaultOptions = {
    showCivility : false,
    showReference: false,
  };
  const { showCivility, showReference } = options ?? defaultOptions;

  if (type === 'company') {
    const withReferenceCompany = (
      showReference &&
      !!reference &&
      reference.toLowerCase() !== (corporateName ?? '').toLowerCase()
    );

    return withReferenceCompany ? `${corporateName} (${reference})` : corporateName ?? null;
  }

  let displayName = lastName ?? corporateName ?? reference ?? '';

  if (firstName) {
    displayName = `${firstName} ${displayName}`;
  }

  if (showCivility && civility) {
    displayName = `${civility} ${displayName}`;
  }

  const withReference = (
    showReference &&
    !!reference &&
    reference.toLowerCase() !== displayName.toLowerCase()
  );

  return withReference ? `${displayName} (${reference})` : displayName;
};

export default recomposeName;
