import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import type { ValidationErrors } from 'types/errors';
import type { Credit, Debit, Imputation, Currency, CurrencyCode } from 'types/models';
import { CreditType } from 'types/models';
import Config from 'config';
import currenciesStore from 'stores/Currencies';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Loading from 'components/Loading';
import FormSelect from 'components/FormSelect';
import FormDatePicker from 'components/FormDatePicker';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nCreditType from 'utils/getI18nCreditType';
import Button from 'components/Button';
import Icon from 'components/Icon';
import FormSelectCustomer from 'components/FormSelectCustomer';
import FormAmountCurrency from 'components/FormAmountCurrency';
import ErrorMessage from 'components/ErrorMessage';
import ResponseError from 'utils/errors';
import ImputationItem from './ImputationItem';

type Props = {
  defaultData?: Credit | null,
  isCreate?: boolean,
  assignedTo?: string,
  maxAmount?: number,
  customerId: number | null,
  isLoadingPossibleDebits?: boolean,
  possibleDebits?: Debit[],
  allDebits?: Debit[],
  errors?: ValidationErrors | null,
};

const CreditEditForm = (props: Props): JSX.Element => {
  const {
    isCreate = true,
    defaultData = null,
    customerId,
    assignedTo,
    maxAmount,
    isLoadingPossibleDebits = false,
    possibleDebits,
    allDebits,
    errors = null,
  } = props;
  const { currencies } = currenciesStore;
  const { currency: organizationCurrency, type: organizationType } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(organizationType);

  const [currency, setCurrency] = useState<CurrencyCode | null>(
    defaultData?.currency ?? organizationCurrency ?? null,
  );
  const [amount, setAmount] = useState<number | null>(
    defaultData?.totalAmountPayment ?? maxAmount ?? null,
  );
  const [imputations, setImputations] = useState<Imputation[]>(
    defaultData?.imputations ?? [],
  );

  const oldAmount = useRef<number | null>(amount);

  useEffect(() => {
    if (isCreate || !defaultData) {
      return;
    }

    const { totalAmountPayment, imputations: defaultDebits } = defaultData;
    if (totalAmountPayment && !amount) {
      setAmount(totalAmountPayment);
    }

    if (defaultDebits && defaultDebits.length > 0) {
      setImputations((prevImputations) => {
        const filteredImutations = defaultDebits.filter(({ reference }) => (
          !prevImputations.map(({ reference: ref }) => ref).includes(reference)
        ));
        return [...prevImputations, ...filteredImutations];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate, defaultData]);

  useEffect(() => {
    if (!assignedTo) {
      return;
    }
    setImputations([{ reference: assignedTo, paymentAssignedAmount: amount ?? 0 }]);
  }, [amount, assignedTo]);

  useEffect(() => {
    const hasFirstImputationChanged = (
      imputations.length === 1
      && imputations[0].paymentAssignedAmount === oldAmount.current
      && oldAmount.current !== amount
    );
    if (!hasFirstImputationChanged) {
      return;
    }

    const firstImputation: Imputation = {
      reference: imputations[0]?.reference || '',
      paymentAssignedAmount: amount ?? 0,
    };
    setImputations([firstImputation]);
    oldAmount.current = amount;
  }, [amount, imputations]);

  const currentCurrency = useMemo(() => (
    currencies?.find((currencyData: Currency) => currencyData.code === currency)
  ), [currencies, currency]);

  const handleChangeAmount = useCallback((newAmount: string) => {
    setAmount(Number.parseFloat(newAmount));
  }, []);

  const handleAddImputation = useCallback(() => {
    if (imputations.length === 0) {
      oldAmount.current = amount;
    }

    const newImputation: Imputation = {
      reference: '',
      paymentAssignedAmount: imputations.length === 0 ? (amount ?? 0) : 0,
    };
    setImputations([...imputations, newImputation]);
  }, [imputations, amount]);

  const handleRemoveDebit = useCallback((refOrindex: string | number) => {
    setImputations((prevDebits) => [...prevDebits].filter(
      ({ reference, debitIdentifier }, index) => {
        if (typeof refOrindex === 'string') {
          return (reference || debitIdentifier) !== refOrindex;
        }
        return index !== refOrindex;
      },
    ));
  }, []);

  const handleImputations = useCallback(
    (value: string, type: string, index: number) => {
      const tmpImputations = [...imputations];

      if (tmpImputations && type === 'reference') {
        tmpImputations[index].reference = value;
      }

      if (tmpImputations && type === 'amount') {
        tmpImputations[index].paymentAssignedAmount = Number.parseFloat(value);
      }
      setImputations(tmpImputations);
    }, [imputations],
  );

  const creditTypes = useMemo(() => (
    Config.CREDIT_TYPES.filter((type) => type !== CreditType.REFUND)
  ), []);

  const suggestedImputations = useMemo(() => (
    possibleDebits?.filter(({ reference }: Debit) => (
      !imputations.map(({ debitIdentifier, reference: ref }) => ref || debitIdentifier).includes(reference)
    )) || []
  ), [possibleDebits, imputations]);

  const handleClickSuggestedDebit = useCallback((debit: Debit) => {
    const { id, reference, amount: paymentAssignedAmount } = debit;
    const newImputation: Imputation = { debitId: id, reference: reference, paymentAssignedAmount };
    setImputations([...imputations, newImputation]);
  }, [imputations]);

  if (!isCreate && !defaultData) {
    return <Loading />;
  }

  const classNames = classnames('CreditEditForm', {
    'CreditEditForm--no-customer': !!assignedTo,
    'CreditEditForm--no-pay-requests': !!assignedTo,
  });

  return (
    <div className={classNames}>
      <FormFieldset>
        <div className="CreditEditForm__customer">
          <FormGroup label={ct('common:client')} mandatory error={errors?.client}>
            <FormSelectCustomer
              name="client"
              disabled={!!customerId}
              defaultValue={defaultData?.client.id || customerId || undefined}
              isInvalid={!!errors?.client}
            />
          </FormGroup>
        </div>
        <FormGroup
          label={t('credits:payment-reference')}
          mandatory
          error={errors?.reference}
        >
          <FormControl
            name="reference"
            autoComplete="off"
            defaultValue={defaultData?.reference || ''}
            isInvalid={!!errors?.reference}
            readOnly={!isCreate}
            disabled={!isCreate}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormAmountCurrency
          onChangeAmount={handleChangeAmount}
          onChangeCurrency={setCurrency}
          amount={amount || ''}
          maxAmount={maxAmount}
          defaultCurrency={defaultData?.currency || currentCurrency?.code}
          amountError={errors?.amount || null}
          currencyError={errors?.currency || null}
        />
        <div className="CreditEditForm__date-type">
          <FormGroup label={t('credits:date')} mandatory error={errors?.paidAt}>
            <FormDatePicker
              name="paidAt"
              defaultValue={defaultData?.paidAt}
              isInvalid={!!errors?.paidAt}
            />
          </FormGroup>
          <FormGroup label={t('credits:payment-type')} mandatory error={errors?.type}>
            <FormSelect
              name="type"
              defaultValue={defaultData?.type || 'PAYMENT'}
              placeholder={t('common:please-choose')}
              selectOptions={creditTypes.map((value) => (
                { value, label: t(getI18nCreditType(value)) }
              ))}
              isInvalid={!!errors?.type}
              withClearButton={false}
            />
          </FormGroup>
        </div>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:label')} error={errors?.subject}>
          <FormControl
            name="subject"
            autoComplete="off"
            defaultValue={defaultData?.subject || ''}
            placeholder={t('credits:example-subject')}
            isInvalid={!!errors?.subject}
          />
        </FormGroup>
        <FormGroup label={t('credits:payment-method')} mandatory error={errors?.method}>
          <FormSelect
            name="method"
            defaultValue={defaultData?.paymentMethod}
            placeholder={t('common:please-choose')}
            selectOptions={Config.PAYMENT_METHODS.map((value) => (
              { value, label: t(getI18nPaymentMethod(value)) }
            ))}
            isInvalid={!!errors?.method}
          />
        </FormGroup>
      </FormFieldset>
      {!assignedTo && (
        <div className="CreditEditForm__imputation">
          <div className="CreditEditForm__imputation__title">
            {t('credits:imputation')}
          </div>
          {!!errors?.imputations && (
            <p className="CreditEditForm__imputation__error">
              {ct('credits:please-choose-at-least-one-invoice')}
            </p>
          )}
          <Button variant="link" onClick={handleAddImputation}>
            <Icon name="plus-circle" />
          </Button>
        </div>
      )}
      <div className="CreditEditForm__pay-requests">
        <div className="CreditEditForm__pay-requests__suggestions">
          {isLoadingPossibleDebits && <Loading />}
          {suggestedImputations.length > 0 && (
            <>
              <p className="CreditEditForm__pay-requests__suggestions__title">
                {ct('common:suggested-invoices')} ({t('common:click-to-add')})
              </p>
              <div className="CreditEditForm__pay-requests__suggestions__list">
                {suggestedImputations.map((debit) => (
                  <Button
                    key={debit.id}
                    variant="outline"
                    small
                    onClick={() => { handleClickSuggestedDebit(debit); }}
                  >
                    {debit.reference} ({formatIntlNumber(debit.amount, debit.currency || currency)})
                  </Button>
                ))}
              </div>
            </>
          )}
        </div>
        {imputations.map((imputation, index) => (
          <ImputationItem
            key={imputation.reference}
            index={index}
            allDebits={allDebits}
            defaultValue={imputation}
            amount={imputation.paymentAssignedAmount}
            currentCurrency={currentCurrency}
            onChangeDebit={handleImputations}
            onRemoveDebit={handleRemoveDebit}
            errors={errors}
          />
        ))}
        {!!errors?.imputation && <ErrorMessage error={errors.imputation as ResponseError} />}
      </div>
    </div>
  );
};

export default observer(CreditEditForm);
