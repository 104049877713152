import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { CurrencyCode, Client, ScenarioSummary } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import ListHistorySummary from 'components/ListHistorySummary';
import Button from 'components/Button';

const MainDebtorsColumns = (
  ct: TFunction,
  currency: CurrencyCode | null,
  showScenarioModal: (scenario: ScenarioSummary) => void,
): Column<Client>[] => [
  {
    Header: ct<string>('common:client'),
    accessor: 'denomination',
    disableSortBy: true,
    Cell: ({ row, value }) => (
      <div className="MainDebtors__columns__name">
        <Link to={`/customer/${row.original.id}`}>
          {value}
        </Link>
        {row.original.identifier !== value && (
          <div className="Customers__columns__ref">{row.original.identifier}</div>
        )}
      </div>
    ),
  },
  {
    Header: ct<string>('common:total-outstanding-amount'),
    accessor: 'statistics',
    id: 'totalToCollect',
    disableSortBy: true,
    Cell: ({ value }) => (
      <div className="MainDebtors__columns__in-progress">
        {formatIntlNumber(value?.totalToCollect, currency)}
        <div className="MainDebtors__columns__count">
          {ct(
            'common:bills-count',
            { count: (value?.totalUnpaidDebit || 0) },
          )}
        </div>
      </div>
    ),
  },
  {
    Header: ct<string>('common:past-due'),
    accessor: 'statistics',
    id: 'totalNotDue',
    disableSortBy: true,
    Cell: ({ value }) => (
      <Fragment>
        {formatIntlNumber(value?.totalDue, currency)}
      </Fragment>
    ),
  },
  {
    Header: ct<string>('common:scenario-debt'),
    accessor: 'scenario',
    disableSortBy: true,
    Cell: ({ value }) => (
      <Fragment>
        {/* Cibler le premier scenario qui est considéré sur le group Dettes */}
        {!!value && value[0]?.filter(({ isVirtual }) => !isVirtual).map((scenario) => {
          return (
            <Button
              key={scenario.id}
              variant="link"
              className="Customers__columns__scenario"
              onClick={() => showScenarioModal(scenario)}
            >{scenario.name}</Button>
          );
        })}
      </Fragment>
    ),
  },
  {
    Header: ct<string >('common:history'),
    accessor: 'statistics',
    disableSortBy: true,
    Cell: ({ row }) => {
      const { dunningNextAction, dunningPastAction, mute } = row.original;
      return (
        <ListHistorySummary
          data={{
            nextSending: dunningNextAction?.date ?? null,
            lastSending: dunningPastAction?.date ?? null,
            mute,
          }}
        />
      );
    },
  },
];

export default MainDebtorsColumns;
