import { useCallback, useMemo, useReducer } from 'react';
import type { Reducer } from 'react';
import { Row } from 'react-table';

export type SelectionArrayRecord = Record<string, string | number>;
export type SelectionStateItem = number | string | SelectionArrayRecord;
export type SelectionState = SelectionStateItem[];

type ReducerAction = {
  type: 'toggleRow' | 'toggleAll' | 'reset',
  allRows?: SelectionState,
  rowId?: SelectionStateItem,
};

type SelectionReducer = Reducer<SelectionState, ReducerAction>;

const selectionReducer: SelectionReducer = (prevState, { type, allRows, rowId }) => {
  switch (type) {
    case 'reset':
      return [];
    case 'toggleAll':
      if (allRows === undefined) {
        throw new Error('allRows must be defined in order to toggle all selection');
      }
      return prevState.length === allRows.length ? [] : allRows;
    case 'toggleRow':
      if (!rowId) {
        throw new Error('Missing row ID');
      }

      const isIdInPrevState = typeof rowId === 'object'
        ? prevState.some((item) => {
          const rowIdObj = rowId as SelectionArrayRecord;
          return (item as SelectionArrayRecord).id === rowIdObj.id;
        })
        : prevState.includes(rowId);

      if (isIdInPrevState) {
        return prevState.filter((item) => {
          if (typeof rowId === 'object') {
            const rowIdObj = rowId as SelectionArrayRecord;
            return (item as SelectionArrayRecord).id !== rowIdObj.id;
          }
          return item !== rowId;
        });
      }
      return [...prevState, rowId];
    default:
      throw new Error('Unhandled action type');
  }
};

export type SelectionHookResult = {
  selection: SelectionState,
  toggleSelectAll(): void,
  toggleSelect(rowId: SelectionStateItem): (() => void),
  clearSelection(): void,
  isSelected(rowId: SelectionStateItem): boolean,
  hasSelection: boolean,
  isAllSelected: boolean,
};

const useSelection = <DataType extends Record<string, any>>(
  rows: Row<DataType>[],
  hasAction: (row: Row<DataType>) => boolean,
  getRowIdentifier: (row: Row<DataType>) => SelectionStateItem,
): SelectionHookResult => {
  const [selection, updateSelection] = useReducer(selectionReducer, []);

  const selectableList = rows.filter((row) => hasAction(row)).map((row) => (
    getRowIdentifier(row)
  ));

  const toggleSelectAll = useCallback(() => {
    updateSelection({ type: 'toggleAll', allRows: selectableList });
  }, [selectableList]);

  const toggleSelect = useCallback((rowId: SelectionStateItem) => (
    () => { updateSelection({ type: 'toggleRow', rowId }); }
  ), []);

  const clearSelection = useCallback(
    () => { updateSelection({ type: 'reset' }); },
    [],
  );

  const isSelected = useCallback((rowId: SelectionStateItem) => {
    if (typeof rowId === 'object') {
      return selection.some((obj) => (obj as SelectionArrayRecord).id === rowId.id);
    }
    return selection.includes(rowId);
  }, [selection]);

  const hasSelection = useMemo(
    () => selection.length > 0,
    [selection],
  );

  const isAllSelected = useMemo(
    () => selection.length === selectableList.length && selectableList.length !== 0,
    [selection, selectableList],
  );

  return {
    selection,
    toggleSelectAll,
    toggleSelect,
    clearSelection,
    isSelected,
    hasSelection,
    isAllSelected,
  };
};

export default useSelection;
