import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import ContactEditModal from 'components/ContactEditModal';

export interface RouteParams {
  editType?: 'new-contact' | 'new' | 'edit',
  contactId?: string,
}

interface Props {
  onDone: (title: string, message: string) => void,
  finallyRedirectTo: string,
  defaultCustomerId?: number,
  customerId?: number,
  defaultRole?: string,
}

const ContactEdit = (props: Props): JSX.Element | null => {
  const { editType, contactId } = useParams<RouteParams>();
  const id = contactId ? Number.parseInt(contactId, 10) : null;

  const [showModal, hideModal] = useModal(() => (
    <ContactEditModal
      {...props}
      editType={editType}
      id={id}
      onClose={hideModal}
    />
  ), [editType, id, props]);

  useEffect(() => {
    showModal();

    return () => {
      hideModal();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ContactEdit;
