import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import apiScenarios from 'api/scenarios';
import apiOrganization from 'api/organization';
import apiScenarioGroup from 'api/scenarioGroup';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import FormControl from 'components/FormControl';
import type { Scenario, ScenarioGroup } from 'types/models';
import Misc from 'types/misc';

type Props = {
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  toDuplicate?: string,
  group?: ScenarioGroup,
  scenarios: Scenario[] | null | undefined,
};

const ScenarioModalAdd = (props: Props): JSX.Element => {
  const { onClose, onActionDone, onActionError, toDuplicate, scenarios, group } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const [scenariosOptions, setScenariosOptions] = useState<Misc.ValueLabel[]>([]);

  const { post } = useApiRequest();

  useEffect(() => {
    if (scenarios) {
      const scenariosLabelValues: Misc.ValueLabel[] = scenarios.map(({ name, identifier }) => ({ label: name, value: identifier }));
      setScenariosOptions(scenariosLabelValues);
    }
  }, [scenarios]);

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization || !isMountedRef.current) {
        return;
      }

      const { name, models } = formData;
      const label = name as string;

      const { tasks = [], reminders = [], isDefault = false, sortOrder = 10 } = models
        ? scenarios?.find((scenario) => scenario.identifier === models) ?? {}
        : {};

      const result = await post(apiScenarios.createUrl, {
        label,
        sortOrder,
        isDefault,
        tasks,
        reminders,
        organization: apiOrganization.resourceUrl(currentOrganization.id),
        group: group?.id ? apiScenarioGroup.resourceUrl(group?.id) : null,
      });
      if (!result) {
        onActionError(t('plans:toast.error.add-plan'));
        return;
      }

      onActionDone(t('plans:toast.success.added-plan', { name: formData.name }));
      onClose();
    },
    [currentOrganization, isMountedRef, onActionDone, onActionError, onClose, post, scenarios, group, t],
  );

  return (
    <ModalForm
      isOpened
      className="PlansScenarioModalAdd"
      onSave={handleSubmit}
      onCancel={onClose}
      title={t('plans:title-add-plan', { group: group?.name })}
    >
      <FormFieldset>
        <FormGroup>
          <FormControl
            placeholder={t('plans:placeholder.name')}
            name="name"
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('plans:duplicate-existing-plan')}>
          <FormSelect
            name="models"
            selectOptions={scenariosOptions}
            hasFetchOrganizationPrefix={false}
            defaultValue={toDuplicate}
            placeholder={t('plans:choose-plan-to-duplicate')}
          />
        </FormGroup>
      </FormFieldset>
    </ModalForm>
  );
};

export default observer(ScenarioModalAdd);
