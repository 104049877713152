import { SortingRule } from 'react-table';
import { SortDirection, type Filter, type SearchResult } from 'types/api';
import type { Credit, Organization, CreditSummary, User } from 'types/models';
import type Misc from 'types/misc';
import requester from 'utils/requester';

export type FetchAllParams = {
  page?: number | undefined,
  pageSize?: number,
  organizationReference?: Organization['reference'] | undefined,
  locale?: User['locale'] | undefined,
  filtering?: Misc.Filter[],
  sort?: SortingRule<CreditSummary>,
};

export type FetchOneParams = {
  id: Credit['id'],
};

const getMappingFromFormToApi = (name: string) => {
  const map = {
    search: 'full_text',
    type: 'type',
    categories: 'business_units',
  } as Record<string, string>;

  return map[name] ?? '';
};

const getFilterValue = (name: string, value: string | string[]): Filter['value'] => {
  const currentValue = name !== 'categories' && (Array.isArray(value) && value.length > 0) ? value[0] : value;
  switch (name) {
    case 'search':
    case 'categories':
      return currentValue;
    case 'type':
      return [currentValue] as string[];
    default:
      return null;
  }
};

const getFilterOperator = (name: string): Filter['operator'] => {
  switch (name) {
    case 'type':
    case 'categories':
      return 'IN';
    default:
      return '=';
  }
};

const getFilter = (name: string, value: any): Filter => ({
  field: getMappingFromFormToApi(name),
  operator: getFilterOperator(name),
  value: getFilterValue(name, value),
  context: [],
});

const transformSortForEndpoint = (sort: SortingRule<CreditSummary>) => {
  let field = sort.id;
  switch (sort.id) {
    case 'code':
      field = 'code';
      break;
    case 'client':
      field = 'client_denomination';
      break;
    case 'paidAt':
    case 'createdAt':
      field = 'created_at';
      break;
    case 'paymentMethod':
      field = 'payment_method';
      break;
  }
  return {
    field,
    direction: sort.desc ? SortDirection.DESC : SortDirection.ASC,
  };
};

/**
 * Récupère une liste des credits.
 *
 * @param id Id de l'organization.
 * @param params Paramètres de la requête (pagination, filres...).
 * @returns Une collection de credits.
 */
const all = async (
  id: Organization['id'] | undefined,
  params: FetchAllParams | undefined,
): Promise<SearchResult<CreditSummary>> => {
  if (!id) {
    throw new Error('FetchAllCredits: Missing organization.');
  }

  const { page = 0, filtering = [], locale, organizationReference, pageSize, sort } = params ?? { page: 0, filtering: [] };

  if (!organizationReference) {
    throw new Error('FetchAllCredits: Missing organization reference.');
  }

  const filters: Filter[] = filtering ?
    filtering.map(({ name, value }) => getFilter(name, value)) : [];

  const { data } = await requester.put<SearchResult<CreditSummary>>(
    'credits',
    {
      channel: organizationReference,
      locale,
      filters,
      page,
      size: pageSize,
      sort: sort ? transformSortForEndpoint(sort) : undefined,
    },
  );
  return data;
};

/**
 * Récupère les données d'un credit.
 *
 * @param id ID du credit.
 * @returns Les données de credit.
 */
const one = async ({ id }: FetchOneParams) => (
  (await requester.get<Credit>(`credits/${id}`)).data
);

/**
 * Récupère l'URL de la ressource API pour mapper une association de client.
 *
 * @param id L'ID du client
 * @returns URL de l'association.
 */
const resourceUrl = (id: Credit['id']) => `/api/credits/${id}`;

/**
 * URL de la ressource API pour la création d'un credit
 */
const createUrl = 'credits';

/**
 * URL de la ressource API pour la création d'une imputation pour un crédit
 */
const createImputationUrl = 'imputations';

/**
 * Récupère l'URL de la ressource API pour modifier le credit.
 *
 * @param id L'ID de credit
 * @returns URL du PUT.
 */
const updateUrl = (id: Credit['id']) => `credits/${id}`;

/**
 * Récupère l'URL de la ressource API pour supprimer le credit.
 *
 * @param id L'ID de credit.
 * @returns URL du DELETE.
 */
const deleteUrl = (id: Credit['id']) => `credits/${id}`;

export default {
  all,
  one,
  createUrl,
  updateUrl,
  resourceUrl,
  deleteUrl,
  createImputationUrl,
};
