import './index.scss';
import { useMemo } from 'react';
import type Misc from 'types/misc';
import { ImportItemType } from 'types/models';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import ImportsFileWizardStepChoiceTypeButton from './Button';

type Props = {
  onSelectType(choice: ImportItemType): () => void,
  type: 'pdf' | 'csv-xlsx',
  choices: Misc.ImportWizardChoices | null,
};

const ImportsFileWizardStepChoiceType = ({ onSelectType, type, choices }: Props): JSX.Element => {
  const { type: organizationType } = organizationStore.currentOrganization!;
  const { t, ct } = useContextualTranslation(organizationType);

  const types = useMemo(() => {
    if (type === 'csv-xlsx') {
      return [
        {
          value: ImportItemType.CLIENT,
          title: t('imports:type-import.client'),
          active: choices?.type === ImportItemType.CLIENT,
        },
        {
          value: ImportItemType.CONTACT,
          title: t('imports:type-import.contact'),
          active: choices?.type === ImportItemType.CONTACT,
        },
        {
          value: ImportItemType.DEBIT,
          title: ct('imports:type-import.debit'),
          breakLine: true,
          active: choices?.type === ImportItemType.DEBIT,
        },
        {
          value: ImportItemType.CREDIT,
          title: t('imports:type-import.credit'),
          active: choices?.type === ImportItemType.CREDIT,
        },
        {
          value: ImportItemType.PLAN,
          title: t('imports:type-import.plan'),
          active: choices?.type === ImportItemType.PLAN,
        },
        {
          value: ImportItemType.EXTRA,
          title: t('imports:type-import.extra'),
          active: choices?.type === ImportItemType.EXTRA,
        },
      ];
    }

    return [
      {
        value: ImportItemType.DEBIT,
        title: t('imports:type-import.debit'),
        active: choices?.type === ImportItemType.DEBIT,
      },
    ];
  }, [t, ct, type, choices]);

  return (
    <div className="ImportsFileWizardStepChoiceType">
      {types.map((
        {
          value,
          title,
          breakLine,
          active,
        },
      ) => (
        <ImportsFileWizardStepChoiceTypeButton
          key={value}
          value={value}
          title={title}
          onSelectType={onSelectType}
          breakLine={breakLine}
          active={active}
        />
      ))}
    </div>
  );
};

export default ImportsFileWizardStepChoiceType;
