import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { Client, EAVValue } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import organizationStore from 'stores/Organization';
import { getAllEavsValues } from 'utils/getCustomerEavs';
import type { FetchOneParams } from 'api/clients';
import apiClients from 'api/clients';
import useFetch from 'hooks/useFetch2';
import ErrorMessage from 'components/ErrorMessage';

type Props = {
  data: Client,
};

const CustomerInfos = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { attributes: organizationAttributes } = organizationStore;

  const {
    data: eavsClient,
    error,
  } = useFetch<FetchOneParams, EAVValue[]>(
    {
      cacheKey: 'clientAllEavs',
      id: data.id,
    },
    apiClients.fetchEavs,
    { enabled: !!data.id },
  );

  const attributes = useMemo(() => (
    getAllEavsValues(eavsClient, organizationAttributes.client)
  ), [eavsClient, organizationAttributes.client]);

  return (
    <dl className="CustomerInfos">
      {error && <ErrorMessage error={error} />}
      <div className="CustomerInfos__item">
        <dt className="CustomerInfos__label">
          {t('common:managers')}
        </dt>
        <dd className="CustomerInfos__value">
          {data.users && data.users.length > 0
            ? data.users.map(({ user }) => `${user.firstName} ${user.lastName}`).join(', ')
            : '-'}
        </dd>
      </div>
      {attributes.map(({ identifier, label, value, useHtml }) => (
        <div className="CustomerInfos__item" key={identifier}>
          <dt className="CustomerInfos__label">{label}</dt>
          {useHtml ?
            <dd
              className="CustomerInfos__value CustomerInfos__value--html"
              dangerouslySetInnerHTML={{ __html: value }}
            /> :
            <dd className="CustomerInfos__value">{value ?? '-'}</dd>
          }
        </div>
      ))}
      {data.createdAt && (
        <div className="CustomerInfos__item">
          <dt className="CustomerInfos__label">
            {t('common:created-at')}
          </dt>
          <dd className="CustomerInfos__value">
            {formatIntlDate(data.createdAt, 'dateLong')}
          </dd>
        </div>
      )}
      {data.updatedAt && (
        <div className="CustomerInfos__item">
          <dt className="CustomerInfos__label">
            {t('common:updated-at')}
          </dt>
          <dd className="CustomerInfos__value">
            {formatIntlDate(data.updatedAt, 'dateLong')}
          </dd>
        </div>
      )}
    </dl>
  );
};

export default observer(CustomerInfos);
