import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import organizationStore from 'stores/Organization';
import apiOrganization from 'api/organization';
import apiScenarioGroup from 'api/scenarioGroup';
import ModalForm from 'components/ModalForm';
import useApiRequest from 'hooks/useApiRequest';
import { ScenarioGroup } from 'types/models';
import useConfirm from 'components/Confirm/useConfirm';
import Confirm from 'components/Confirm';
import ScenarioGroupForm from './Form';

type Props = {
  isEdit?: boolean,
  defaultData?: ScenarioGroup[] | null,
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const DEFAULT_COLOR = config.SCENARIO_COLORS.at(-1);

const ScenarioModalAddGroup = (props: Props): JSX.Element => {
  const {
    onClose,
    onActionDone,
    onActionError,
    isEdit = false,
    defaultData,
  } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { t } = useTranslation();
  const { post, put, remove } = useApiRequest();

  const { id: organizationId } = organizationStore.currentOrganization!;
  const [formData, setFormData] = useState<ScenarioGroup[] | null>(defaultData ?? []);
  const [removedGroup, setRemovedGroup] = useState<ScenarioGroup | null>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const defaultGroup: ScenarioGroup = { identifier: 'dettes', name: t('selectors:debt'), color: '#fff' };

  const handleSubmit = useCallback(async () => {
    if (!formData) {
      return;
    }
    setIsSaving(true);

    try {
      const promises = formData.map((group) => {
        if (isEdit) {
          return put(apiScenarioGroup.updateUrl(group.id), { name: group.name, color: group.color ?? DEFAULT_COLOR });
        } else {
          return post(
            apiScenarioGroup.createUrl,
            {
              identifier: group.identifier,
              name: group.name,
              color: group.color,
              organization: apiOrganization.resourceUrl(organizationId),
            },
          );
        }
      });

      const results = (await Promise.all(promises)).filter((result) => result !== undefined);
      const hasErrors = results.some((result) => !result);

      if (hasErrors) {
        setIsSaving(false);
        onActionError(t(isEdit ? 'plans:toast.error.edit-group' : 'plans:toast.error.add-group'));
        return;
      }

      onClose();
      onActionDone(t(isEdit ? 'plans:toast.success.edit-group' : 'plans:toast.success.add-group', {
        name: formData[0].name,
      }));
    } finally {
      setIsSaving(false);
    }
  }, [formData, isEdit, onClose, onActionDone, onActionError, setIsSaving, post, put, t, organizationId]);

  const handleGroupChange = useCallback((index: number, newData: ScenarioGroup | null) => {
    if (!newData) {
      return;
    }
    setFormData((prevData) => {
      if (!prevData) {
        return prevData;
      }
      const updatedData = [...prevData];
      updatedData[index] = newData;
      return updatedData;
    });
  }, [setFormData]);

  const handleGroupDelete = useCallback((group: ScenarioGroup) => {
    setRemovedGroup(group);
    showConfirm(t('plans:remove-group'), t('plans:confirm-remove-group', { name: group.identifier }));
  }, [showConfirm, t]);

  const handleSubmitDelete = useCallback(async () => {
    if (!removedGroup) {
      return;
    }

    hideConfirm();

    const response = await remove(apiScenarioGroup.deleteUrl(removedGroup.id));
    if (response === '') {
      onActionDone(t('plans:toast.success.group-deleted', { name: removedGroup.identifier }));
      setRemovedGroup(null);
      setFormData((prevData) => {
        if (!prevData) {
          return prevData;
        }

        return prevData.filter((group) => group.id !== removedGroup.id);
      });
    }
  }, [hideConfirm, onActionDone, t, remove, removedGroup]);

  return (
    <div className="PlansScenarioModalAddGroup">
      <ModalForm
        isOpened

        onSave={handleSubmit}
        onCancel={onClose}
        isLoading={isSaving}
        title={isEdit ? t('plans:title-edit-plan-group') : t('plans:title-add-plan-group')}
        saveText={t('common:validate')}
      >
        {!isEdit && (
          <ScenarioGroupForm
            showLabel
            onChange={(newData) => { setFormData(newData ? [newData] : null); }}
          />
        )}
        {isEdit && <Fragment>
          <ScenarioGroupForm
            showLabel
            isDisabled
            defaultData={defaultGroup}
          />
          {formData?.map((group, index) => (
            <ScenarioGroupForm
              isEdit
              defaultData={group}
              key={group.identifier}
              canRemove={!group?.scenarios?.length}
              onDelete={(data) => { handleGroupDelete(data); }}
              onChange={(newData) => { handleGroupChange(index, newData); }}
            />
          ))}
        </Fragment>}
      </ModalForm>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        entityName={removedGroup?.identifier}
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default ScenarioModalAddGroup;
