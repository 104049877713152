import './index.scss';
import { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import { EavCategory } from 'types/models';
import getEavCategoryTitleLabel from 'utils/getEavCategoryTitleLabel';
import getEavAddAttributeLabel from 'utils/getEavAddAttributeLabel';
import { RightsCategory } from 'types/models';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import SettingsSection from '../Section';
import ModalEdit from './ModalEdit';
import AttributesModalEditYAML from './ModalEditYAML';
import AttributesListing from './AttributesListing';

const SettingsAttributes = (): JSX.Element => {
  const { type: organizationType } = organizationStore.currentOrganization!;
  const { ct } = useContextualTranslation(organizationType);
  const [attributesRenderTick, setAttributesRenderTick] = useState(1);
  const [type, setType] = useState(EavCategory.CLIENT);
  const [isLoading, setLoading] = useState(false);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback(async (message: string) => {
    setLoading(true);
    await organizationStore.fetchAttributes(type);
    setLoading(false);
    setAttributesRenderTick((lastTick) => lastTick + 1);
    showToast(message, 'success');
  }, [showToast, type]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const [showModal, hideModal] = useModal(() => (
    <ModalEdit
      type={type}
      onClose={hideModal}
      onActionDone={handleActionDone}
      onActionError={handleActionError}
    />
  ), [handleActionDone, handleActionError, type]);

  const [showModalEditYAML, hideModalEditYAML] = useModal(() => (
    <AttributesModalEditYAML
      onClose={hideModalEditYAML}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, showToast]);

  const handleTypeChange = useCallback((newValue: string | null) => {
    const eavCategory = newValue as EavCategory;
    setType(eavCategory);
  }, []);

  return (
    <div className="Attributes">
      <SettingsSection
        filter={(
          <Dropdown
            variant="link"
            className="Attributes__dropdown"
            toggle={ct(getEavCategoryTitleLabel(type))}
          >
            {Object.values(EavCategory).map((eavCategory) => (
              <Button
                key={eavCategory}
                variant="list"
                className={['Attributes__dropdown__item', eavCategory === type && 'Attributes__dropdown__item--active'].join(' ')}
                onClick={() => handleTypeChange(eavCategory)}>
                {ct(getEavCategoryTitleLabel(eavCategory))}
              </Button>
            ))}
          </Dropdown>
        )}
        action={(
          <ButtonWithUserRights
            onClick={showModal}
            variant="link"
            action="CREATE"
            category={RightsCategory.PREFERENCES_ATTRIBUTES}
          >
            {ct(getEavAddAttributeLabel(type))}
          </ButtonWithUserRights>
        )}
        secondaryAction={(
          <ButtonWithUserRights
            onClick={showModalEditYAML}
            variant="link"
            action="CREATE"
            category={RightsCategory.PREFERENCES_ATTRIBUTES}
          >
            {ct('attributes:yaml-edit')}
          </ButtonWithUserRights>
        )}
      >
        <AttributesListing
          type={type}
          key={attributesRenderTick}
          isLoading={isLoading}
          onActionDone={handleActionDone}
          onActionError={handleActionError}
        />
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsAttributes);
