import type { Collection } from 'types/api';
import type { ScenarioGroup } from 'types/models';
import requester from 'utils/requester';

export type FetchAllGroupsParams = {
  organizationId: number | undefined,
};

export type FetchOneParams = {
  id: ScenarioGroup['id'],
};

/**
 * URL de la ressource API pour la liste des groupes de scénarios.
 */
const all = async (
  { organizationId }: FetchAllGroupsParams,
): Promise<ScenarioGroup[]> => {
  if (!organizationId) {
    throw new Error('FetchAllScenarioGroups: Missing organization ID.');
  }

  const { data } = await requester.get<Collection<ScenarioGroup>>(`organizations/${organizationId}/scenario_groups`);
  return data['hydra:member'];
};

/**
 * Récupère les données d'un Groupe Scenario.
 *
 * @param params Un objet contenant l'ID du Groupe Scenario à récupérer.
 * @returns Les données du Groupe Scenario.
 */
const one = async ({ id }: FetchOneParams) => (
  (await requester.get<ScenarioGroup>(`scenario_groups/${id}`)).data
);

/**
 * URL de la ressource d'API pour la création d'un Groupe Scenario (POST).
 */
const createUrl = 'scenario_groups';

/**
 * Récupère l'URL de la ressource d'API pour modifier le groupe scenario.
 *
 * @param id L'ID du groupe
 * @returns URL du PUT.
 */
const updateUrl = (id: ScenarioGroup['id']) => `scenario_groups/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour supprimer le groupe scenario.
 *
 * @param id L'ID du groupe
 * @returns URL du DELETE.
 */
const deleteUrl = (id: ScenarioGroup['id']) => `scenario_groups/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour mapper une association de groupe scenario.
 *
 * @param id L'ID du groupe scenario
 * @returns URL du groupe scenario.
 */
const resourceUrl = (id?: ScenarioGroup['id']) => `/api/scenario_groups/${id}`;

export default {
  all,
  one,
  deleteUrl,
  updateUrl,
  createUrl,
  resourceUrl,
};
