import './index.scss';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Button from 'components/Button';
import Icon from 'components/Icon';

interface Props {
  step: number,
  onNavigateTo: (step: number) => void,
  hasSelectedType: boolean,
  hasSelectedFiles: boolean,
  type: 'pdf' | 'csv-xlsx',
}

const MiddleNavButtons: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    step,
    onNavigateTo,
    hasSelectedType,
    hasSelectedFiles,
    type,
  } = props;

  const isDisabled = useCallback(
    (stepToNavigate: number) => (stepToNavigate === 2 && !hasSelectedType)
      || (stepToNavigate === 3 && !hasSelectedFiles),
    [hasSelectedType, hasSelectedFiles],
  );

  const classNames = (linkStep: number) => classnames('MiddleNavButtons__box__underline', {
    'MiddleNavButtons__box__underline--active': linkStep === step,
  });

  return (
    <div className="MiddleNavButtons">
      {type !== 'pdf' && (
        <>
          <div className="MiddleNavButtons__button">
            <Button variant="link" onClick={() => onNavigateTo(1)}>
              {t('imports:wizard.nav.type')}
            </Button>
            <div className={classNames(1)} />
          </div>
          <Icon name="chevron-right" className="MiddleNavButtons__icon" />
          <div className="MiddleNavButtons__button">
            <Button variant="link" onClick={() => onNavigateTo(2)} disabled={isDisabled(2)}>
              {t('imports:wizard.nav.upload')}
            </Button>
            <div className={classNames(2)} />
          </div>
        </>
      )}
    </div>
  );
};

export default MiddleNavButtons;
