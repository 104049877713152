import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import { ValidationErrorItem } from 'types/errors';
import dateFormatIso from 'utils/dateFormatIso';
import Icon from 'components/Icon';
import FormSelect from 'components/FormSelect';
import { SelectorsRefDate, SelectorsRefDateEAV, SelectorsRefDateType } from 'types/models';
import { eavMaping, getReferenceDate } from 'pages/Settings/Selectors/Columns/utils';

type Props = {
  error?: ValidationErrorItem | null,
  defaultValue?: string,
};

const ReferenceDate = ({ error, defaultValue }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [referenceDateOrigin, setReferenceDateOrigin] = useState<string>('');
  const [attribute, setAttribute] = useState<SelectorsRefDateType | null>();
  const [eav, setEav] = useState<string | null>();
  const [date, setDate] = useState<Date | [Date, Date] | undefined>();
  const eavMap = eavMaping();

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    const selectorsRef = getReferenceDate(defaultValue);

    setReferenceDateOrigin(defaultValue);
    setAttribute(selectorsRef.attribute);
    setDate(selectorsRef.date);
    setEav(selectorsRef.eav);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const refDateOptions = useMemo(() => (
    [
      ...Object.entries(SelectorsRefDate),
      ...Object.entries(SelectorsRefDateEAV)
        .filter(([key]) => eavMap[key].length > 0),
    ].map(([key, value]) => ({
      value: key,
      label: t(`selectors:selector-types.${value}`),
    }))
  ), [t, eavMap]);

  const eavOptions = useMemo(() => {
    if (!attribute) {
      return;
    }

    return (eavMap[attribute] || []).map(({ identifier, label }) => ({
      value: identifier,
      label,
    }));
  }, [attribute, eavMap]);

  const isManual = useMemo(() => attribute === 'DATE_MANUAL', [attribute]);

  const hasSecondOption = useMemo(() => {
    if (!attribute) {
      return false;
    }

    return Object.keys(SelectorsRefDateEAV).includes(attribute)
      || isManual;
  }, [attribute, isManual]);

  const handelSelectChange = useCallback((_name: string, newValue: string | null) => {
    if (!newValue) {
      return;
    }

    const withSecondOption = newValue === 'DATE_MANUAL' ||
      Object.keys(SelectorsRefDateEAV).includes(newValue);

    setDate(undefined);
    setAttribute(newValue as SelectorsRefDateType);
    setReferenceDateOrigin(withSecondOption ? '' : newValue.toLowerCase());
  }, []);

  const handelDateChange = useCallback((newDate: Date) => {
    setDate(newDate);
    setReferenceDateOrigin(
      newDate ? `${attribute!.toLowerCase()}_${dateFormatIso(newDate)}` : '',
    );
  }, [attribute]);

  const handelEAVChange = useCallback((_name: string, newValue: string | null) => {
    setReferenceDateOrigin(
      newValue ? `date_eav_${newValue}` : '',
    );
  }, []);

  return (
    <div className='ReferenceDate'>
      <FormFieldset>
        <FormGroup
          className="ReferenceDate__Select"
          label={t('plans:reference-date')}
          mandatory
        >
          <FormSelect
            name='attribute'
            placeholder={t('selectors:choose-option')}
            selectOptions={refDateOptions}
            defaultValue={attribute}
            withClearButton={false}
            onSelect={handelSelectChange}
          />
        </FormGroup>
        {hasSecondOption &&
          <FormGroup
            className="ReferenceDate__Picker"
            shouldDisplayErrorUnderInput
            error={error}
            mandatory
          >
            {isManual &&
              <DatePicker
                value={date}
                onChange={handelDateChange}
                calendarIcon={<Icon name="calendar" />}
                clearIcon={<Icon name="close" />}
              />
            }
            {!isManual &&
              <FormSelect
                name='eav'
                withClearButton={false}
                placeholder={t('selectors:choose-attribute')}
                selectOptions={eavOptions}
                defaultValue={eav}
                onSelect={handelEAVChange}
                isInvalid={!!error}
              />
            }
          </FormGroup>
        }
        <input type="hidden" name="referenceDateOrigin" value={referenceDateOrigin} />
      </FormFieldset>
    </div>
  );
};
export default ReferenceDate;
