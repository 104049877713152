import './index.scss';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { User } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';

type Props = {
  defaultData?: User | null,
  errors?: ValidationErrors | null,
};

const MyProfileEditForm = ({ defaultData, errors }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="MyProfileEditForm">
      <FormFieldset>
        <FormGroup
          className="MyProfileEditForm__civility"
          label={t('common:civility')}
          error={errors?.civility}
        >
          <FormControl
            name="civility"
            autoComplete="off"
            defaultValue={defaultData?.civility || ''}
            isInvalid={!!errors?.civility}
          />
        </FormGroup>
        <FormGroup label={t('common:first-name')} error={errors?.firstName}>
          <FormControl
            name="firstName"
            autoComplete="off"
            defaultValue={defaultData?.firstName || ''}
            isInvalid={!!errors?.firstName}
          />
        </FormGroup>
        <FormGroup label={t('common:last-name')} error={errors?.lastName}>
          <FormControl
            name="lastName"
            autoComplete="off"
            defaultValue={defaultData?.lastName || ''}
            isInvalid={!!errors?.lastName}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:phone')} error={errors?.phone} shouldDisplayErrorUnderInput>
          <FormControl
            name="phone"
            autoComplete="off"
            defaultValue={defaultData?.phone || ''}
            isInvalid={!!errors?.phone}
          />
        </FormGroup>
        <FormGroup label={t('common:email')} error={errors?.email}>
          <FormControl
            name="email"
            type="email"
            autoComplete="off"
            defaultValue={defaultData?.email || ''}
            isInvalid={!!errors?.email}
            disabled
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default MyProfileEditForm;
