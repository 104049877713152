import { Channel } from 'types/models';

const normalizeActionName = (channel: Channel | null) => {
  if (!channel) {
    return null;
  }

  return channel === Channel.LETTER_REGISTERED ? Channel.LETTER : channel.toLowerCase();
};

export default normalizeActionName;
