import './index.scss';
import { useTranslation } from 'react-i18next';

const NoOrganizationMessage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="NoOrganizationMessage">
      {t('errors:no-organization-selected')}
    </div>
  );
};

export default NoOrganizationMessage;
