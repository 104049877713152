import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { CreditSummary } from 'types/models';
import { CreditStatus, RightsCategoryType } from 'types/models';
import organizationStore from 'stores/Organization';
import formatIntlDate from 'utils/formatIntlDate';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';
import getI18nCreditStatus from 'utils/getI18nCreditStatus';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import { UserRightsManagerController } from 'components/UserRights';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import RowActions from '../Actions/Row';

const CreditsColumns = (
  ct: TFunction,
  onActionDone: (title: string, message: string) => void,
  onActionError: (message: string) => void,
  onEdit: (reference: string) => void,
): Column<CreditSummary>[] => [
  {
    Header: ct<string>('credits:reference'),
    accessor: 'code',
    Cell: ({ row, value }) => (
      <>
        {value && (
          <Link className="Credits__columns__reference" to={`/credits/view/${row.original.id}`}>
            {value}
          </Link>
        )}
        <div className="Credits__columns__mean">
          {ct(getI18nPaymentMethod(row.original.paymentMethod))}
        </div>
      </>
    ),
  },
  {
    Header: ct<string>('common:client'),
    accessor: 'client',
    Cell: ({ value }) => (
      <div className="Credits__columns__client">
        <Link to={`/customer/${value.id}`}>
          {value.denomination}
        </Link>
        <div className="Credits__columns__client__ref">
          {value.identifier}
        </div>
      </div>
    ),
  },
  {
    Header: ct<string>('credits:received-the'),
    accessor: 'paidAt',
    Cell: ({ value }) => <Fragment>{formatIntlDate(value.date, 'dateLong')}</Fragment>,
  },
  {
    Header: ct<string>('common:amount'),
    accessor: 'amount',
    disableSortBy: true,
    Cell: ({ row, value }) => (
      <Fragment>
        {formatIntlNumber(value, row.original.currency?.code ?? organizationStore.currentOrganization!.currency)}
      </Fragment>
    ),
  },
  /* TODO a remettre plus tard quand le backend aura le champ
  {
    Header: ct<string>('credits:to-impute'),
    accessor: 'amountNotAssigned',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      if (!value) {
        return null;
      }
      return (
        <Fragment>
          {formatIntlNumber(value, row.original.currency?.code ?? organizationStore.currentOrganization!.currency)}
        </Fragment>
      );
    },
  },
  */
  {
    Header: ct<string>('credits:associated-invoices'),
    accessor: 'imputations',
    disableSortBy: true,
    Cell: ({ value: imputations, row }) => (
      <div className="Credits__columns__imputations">
        {imputations?.map(({ identifier, id, amount, debitId }, index) => (
          <div
            key={`${row.original.id}-${identifier}-${amount}-${id}`}
            className="Credits__columns__imputations__item"
          >
            <Link to={`/debit/${debitId ?? id}`}>
              {identifier}
            </Link>
            {index < imputations.length - 1 && ', '}
          </div>
        ))}
        {(!imputations || imputations.length === 0) && (
          <Fragment>
            {row.original.status !== CreditStatus.SUCCESS && (
              <span className="Credits__columns__imputations__failed">
                {ct('credits:not-imputable')} ({ct(getI18nCreditStatus(row.original.status))})
              </span>
            )}
            {row.original.status === CreditStatus.SUCCESS && (
              <ButtonWithUserRights
                variant="outline"
                onClick={() => onEdit(`credits/edit/${row.original.identifier}`)}
                managers={[]}
                action="CREATE"
                categoryType={RightsCategoryType.PAYMENTS}
              >
                {ct('credits:to-impute')}
              </ButtonWithUserRights>
            )}
          </Fragment>
        )}
      </div>
    ),
  },
  {
    Header: ct<string>('credits:subject'),
    accessor: 'label',
    disableSortBy: true,
    Cell: ({ value }) => (
      <div className="Credits__columns__subject">
        {value}
      </div>
    ),
  },
  {
    Header: ct<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row, value: id }) => (
      <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
        <UserRightsManagerController action="UPDATE" categoryType={RightsCategoryType.PAYMENTS}>
          <RowActions
            id={id}
            name={row.original.label || row.original.identifier}
            reference={row.original.identifier}
            onActionDone={onActionDone}
            onActionError={onActionError}
          />
        </UserRightsManagerController>
      </ExternallyMonitoredController>
    ),
  },
];

export default CreditsColumns;
