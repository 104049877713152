import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import FileDownload from 'js-file-download';
import { parse } from 'yaml';
import apiScenarios from 'api/scenarios';
import { ACCEPT_FILES_TYPE } from 'config';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import ModalForm from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import Dropzone from 'components/Dropzone';
import Button from 'components/Button';
import FormCheck from 'components/FormCheck';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import Loading from 'components/Loading';
import useTooltip from 'components/Tooltip/useTooltip';
import Icon from 'components/Icon';
import requester from 'utils/requester';

type Props = {
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const ModalEditYAML = (props: Props): JSX.Element => {
  const { onClose, onActionDone, onActionError } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const [replace, setReplace] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [planIsValid, setPlanIsValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>();

  const { post, isLoading } = useApiRequest();

  const tooltipTarget = useTooltip<HTMLSpanElement>(
    <div className="PlansModalEditYAML__tooltip">
      {t('plans:yaml-warning')}
    </div>,
  );

  const handleDrop = useCallback((files: File[]) => {
    setFile(files[0]);
    setPlanIsValid(false);
    setErrors([]);
  }, []);

  const handleSubmit = useCallback(
    async () => {
      if (!currentOrganization || !file) {
        return;
      }

      setErrors([]);
      setPlanIsValid(false);

      if (!replace) {
        // regarde si le yaml est valide seulement
        const reader = new FileReader();
        reader.onload = async (e) => {
          if (e.target && e.target.result) {
            try {
              parse(e.target.result.toString());
              setPlanIsValid(true);
              setErrors([]);
            } catch (exception) {
              setErrors([exception as string]);
            }
          }
        };
        reader.readAsText(file);
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('organization', currentOrganization.reference);
      const result = await post(apiScenarios.importUrl, formData);

      if (!isMountedRef.current) {
        return;
      }
      if (!result || result.errors) {
        onActionError(t('plans:toast.error.yaml-plans'));
        onClose();
        return;
      }
      onActionDone(t('plans:toast.success.yaml-plans'));
      onClose();
    },
    [
      currentOrganization,
      file,
      isMountedRef,
      onActionDone,
      onActionError,
      onClose,
      post,
      replace,
      t,
    ],
  );

  const onDownloadTemplateClick = useCallback(async () => {
    const { data } = await requester.get(
      apiScenarios.exportUrl(currentOrganization?.reference),
      { responseType: 'blob' },
    );
    FileDownload(data, 'plans.yaml');
  }, [currentOrganization]);

  return (
    <ModalForm
      isOpened
      className="PlansModalEditYAML"
      onSave={handleSubmit}
      onCancel={onClose}
      title={t('plans:plan-via-yaml')}
    >
      <p className="PlansModalEditYAML__description">{t('plans:description-yaml')}</p>
      <Button className="PlansModalEditYAML__download" onClick={onDownloadTemplateClick}>{t('plans:download-current-file')}</Button>
      <p className="PlansModalEditYAML__title">{t('plans:new-yaml-file')}</p>
      <Dropzone
        onDrop={handleDrop}
        accept={ACCEPT_FILES_TYPE.YAML}
        maxFiles={1}
        withInstructions={false}
        filename={file?.name}
      />
      <ErrorValidationMessage error={errors ? errors[0] : null} />
      {isLoading && <Loading hasNoText />}
      {planIsValid && (<p className="PlansModalEditYAML__valid">{t('plans:upload-is-valid', { name: file?.name })}</p>)}
      <FormFieldset>
        <FormCheck onChange={setReplace} checked={replace} />
        <p className="PlansModalEditYAML__check">{t('plans:replace-current-plans')}</p>
        <div ref={tooltipTarget}>
          <Icon name="help-circle" />
        </div>
      </FormFieldset>
    </ModalForm>
  );
};

export default observer(ModalEditYAML);
