import './index.scss';
import { useTranslation } from 'react-i18next';
import ImportsFileWizardOption from './Option';

const ImportsFileWizard = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="ImportsFileWizard">
      <ImportsFileWizardOption type="csv" description={t('imports:wizard.option.csv-xlsx.description')} />
      <ImportsFileWizardOption type="pdf" description={t('imports:wizard.option.pdf.description')} />
    </div>
  );
};

export default ImportsFileWizard;
