import './index.scss';
import { useCallback, useState } from 'react';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';
import usePasswordValidator from 'hooks/usePasswordValidator';
import PasswordHelper from 'components/PasswordHelper';
import FormGroupPassword from 'components/FormGroupPassword';

type Props = {
  onSubmit(password: string, repeatPassword: string): void,
  isLoading?: boolean,
  error: string | null,
};

const ResetPasswordForm = ({ onSubmit, isLoading = false, error }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { helperText, valid, password, setPassword } = usePasswordValidator();
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const canSubmit = !!password && !!password2 && valid && password === password2;

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit(password, password2);
  };

  const onShowPassowrd = useCallback((newValue: boolean) => {
    setShowPassword(newValue);
  }, []);

  return (
    <form className="ResetPasswordForm" onSubmit={handleSubmit}>
      <FormGroupPassword
        label={t('forgotten-password:new-password')}
        name="resetPassword1"
        value={password}
        onChange={setPassword}
        error={error}
        onShowPassword={onShowPassowrd}
      />
      <FormGroup controlId="resetPassword2" label={t('forgotten-password:new-password-confirm')}>
        <FormControl
          type={showPassword ? 'text' : 'password'}
          id="resetPassword2"
          placeholder={t('login:enter-password')}
          value={password2}
          onChange={setPassword2}
          isInvalid={error !== null}
        />
      </FormGroup>
      {error !== null && (
        <h4 className="ResetPasswordForm__error">{error}</h4>
      )}
      <PasswordHelper validations={helperText} valid={valid} />
      <Button
        variant="black"
        type="submit"
        className="ResetPasswordForm__submit"
        disabled={!canSubmit}
      >
        {isLoading && <Loading />}
        {!isLoading && t('forgotten-password:reset-password')}
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
