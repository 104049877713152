import config from 'config';
import authStore from 'stores/Auth';
import parseIsoDate from './parseIsoDate';

type Mode = 'dateNumeric' | 'dateNumericCondensed' | 'monthYear' |
'dateTimeShort' | 'dateTimeLong' | 'dateLong' | 'month' | 'dateFull' | 'dateHour' |
'time' | 'dayMonthShort' | 'dayMonthYearShort' | 'monthLong' | 'dateTimeFullShortMonth';

const formatIntlDate = (value: string | null | undefined, mode: Mode = 'dateNumeric'): string => {
  const date = parseIsoDate(value);

  if (!date) {
    return '';
  }

  let intlOptions: Intl.DateTimeFormatOptions | undefined = undefined;
  switch (mode) {
    case 'dateNumeric':
      intlOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      break;
    case 'dateNumericCondensed':
      intlOptions = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
      };
      break;
    case 'dateTimeShort':
      intlOptions = {
        day: '2-digit',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
      };
      break;
    case 'dateHour':
      intlOptions = {
        day: '2-digit',
        month: 'long',
        hour: '2-digit',
      };
      break;
    case 'dateTimeLong':
      intlOptions = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      break;
    case 'dateLong':
      intlOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      };
      break;
    case 'dateTimeFullShortMonth':
      intlOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      break;
    case 'dateFull':
      intlOptions = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      };
      break;
    case 'month':
      intlOptions = {
        month: 'short',
      };
      break;
    case 'monthLong':
      intlOptions = {
        month: 'long',
      };
      break;
    case 'time':
      intlOptions = {
        hour: '2-digit',
        minute: '2-digit',
      };
      break;
    case 'dayMonthShort':
      intlOptions = {
        day: '2-digit',
        month: 'short',
      };
      break;
    case 'dayMonthYearShort':
      intlOptions = {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
      };
      break;
    case 'monthYear':
      intlOptions = {
        month: 'long',
        year: 'numeric',
      };
      break;
    default:
      break;
  }

  const { locale } = authStore.user || config.DEFAULT_LOCALE;
  const safeLocale = locale.length < 2 ? config.DEFAULT_LOCALE.locale : locale;
  const intlObject = new Intl.DateTimeFormat(safeLocale.replace('_', '-'), intlOptions);

  return intlObject.format(date);
};

export default formatIntlDate;
