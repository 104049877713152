import './index.scss';
import type { NoteSearch } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import Icon from 'components/Icon';
import useTooltip from 'components/Tooltip/useTooltip';

type Props = {
  note: NoteSearch,
};

const NoteIcon = ({ note }: Props): JSX.Element => {
  const tooltipTarget = useTooltip<HTMLSpanElement>(
    <div className="NoteIcon__tooltip">
      <div className="NoteIcon__tooltip__header">
        {formatIntlDate(note.at?.date, 'dateTimeShort')}
      </div>
      <div className="NoteIcon__tooltip__content">
        {note.message}
      </div>
    </div>,
    'note',
  );

  return (
    <span ref={tooltipTarget} className="NoteIcon">
      <Icon name="comment" />
    </span>
  );
};

export default NoteIcon;
