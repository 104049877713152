import Misc from 'types/misc';

const getI18nPeriod = (periodId: string): string => {
  const periodsI18n = {
    INF90: 'periods:more-than-90-days',
    INF9060: 'periods:less-than-90-to-60-days',
    INF6030: 'periods:less-than-60-to-30-days',
    INF3010: 'periods:less-than-30-to-10-days',
    INF100: 'periods:less-than-10-days',
    SUP010: 'periods:more-than-0-to-10-days',
    SUP1030: 'periods:more-than-10-to-30-days',
    SUP30: 'periods:more-than-30-days',
    DEFAULT: 'periods:default',
  };

  if (!Object.keys(periodsI18n).includes(periodId)) {
    return 'periods:unknown-period';
  }

  return periodsI18n[periodId as Misc.PeriodId];
};

export default getI18nPeriod;
