import resources from 'locales';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  supportedLngs: ['en', 'fr', 'es'],
  fallbackLng: 'en',
  ns: [],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  detection: {
    order: ['localStorage', 'cookie', 'navigator'],
    caches: ['localStorage', 'cookie'],
    lookupLocalStorage: 'i18nextLang',
    lookupCookie: 'i18nextLang',
    convertDetectedLanguage: (locale: string) => locale.substring(0, 2),
  },
});

export default i18n;
