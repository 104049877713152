import './index.scss';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams, useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { useModal } from 'react-modal-hook';
import Config from 'config';
import type Misc from 'types/misc';
import { RightsRole } from 'types/models';
import type { ClientSummary, ScenarioSummary } from 'types/models';
import organizationStore from 'stores/Organization';
import userRightsStore from 'stores/UserRights';
import authStore from 'stores/Auth';
import type { FetchAllParams } from 'api/clients';
import apiCustomers from 'api/clients';
import useFetchPaginated from 'hooks/useFetchSearchPaginated';
import customerFiltersStore from 'stores/FilterStores/CustomersFilters';
import useContextualTranslation from 'hooks/useContextualTranslation';
import PageList from 'components/PageList';
import { checkIsAllowed } from 'components/ExternallyMonitored';
import DataTable from 'components/DataTable';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import ScenarioDetails from 'components/ScenarioDetails';
import LinkSwitch from 'components/LinkSwitch';
import Button from 'components/Button';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import CustomerEditPage from '../CustomerEdit';
import StatusSelect from './StatusSelect';
import CustomersFilters from './Filters';
import CustomersColumns from './Columns';
import BulkActions from './Actions/Bulk';

type RouteParams = {
  modalType?: 'new' | 'edit' | 'scenario',
};

const Customers = (): JSX.Element => {
  const history = useHistory();
  const { modalType } = useParams<RouteParams>();
  const { currentOrganization, isExternallyMonitored, selectedBusinessUnitsIdentifier, attributes } = organizationStore;
  const [previewScenario, setPreviewScenario] = useState<ScenarioSummary | null>(null);
  const { currency, type } = currentOrganization!;
  const { ct } = useContextualTranslation(type);
  const { rights } = userRightsStore;
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);
  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes(RightsRole.ROLE_SUPER_ADMINISTRATOR)));

  const {
    filters,
    addOrUpdateFilters,
    resetAllFilters,
    removeFilter,
  } = customerFiltersStore;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
  };

  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<ClientSummary>>(
    defaultFetchOptions,
  );

  const filtering = useMemo(() => {
    if (selectedBusinessUnitsIdentifier.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: selectedBusinessUnitsIdentifier }];
  }, [filters, selectedBusinessUnitsIdentifier]);

  const {
    data,
    serverPagination,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchPaginated<FetchAllParams, ClientSummary>(
    {
      cacheKey: 'customers',
      organizationId: currentOrganization?.id,
      params: {
        page: fetchOptions.pageIndex,
        organizationReference: currentOrganization?.reference,
        pageSize: fetchOptions.pageSize,
        locale: user?.locale,
        filtering,
        attributes: attributes.client,
        sort: fetchOptions.sort,
      },
    },
    apiCustomers.all,
    { enabled: !!currentOrganization, retry: 1 },
  );

  const [actionsCount, setActionsCount] = useState<number>(0);

  const handleActionDone = useCallback(
    (message: string) => {
      showToast(message, 'success');
      setActionsCount(actionsCount + 1);
      refetch();
    },
    [showToast, refetch, setActionsCount, actionsCount],
  );

  const handleActionError = useCallback(
    (message: string) => { showToast(message, 'error'); },
    [showToast],
  );

  const handleOnExport = useCallback(
    async () => {
      const result = apiCustomers.exportUrl({
        organizationReference: currentOrganization?.reference,
        locale: user?.locale,
        filtering,
        attributes: attributes.client,
        sort: fetchOptions.sort,
      });
      if (!result) {
        handleActionError(ct('error:unknown-retry'));
      } else {
        handleActionDone(ct('common:export-success'));
      }
    },
    [
      attributes.client,
      currentOrganization?.reference,
      fetchOptions.sort,
      user?.locale,
      filtering,
      handleActionDone,
      handleActionError,
      ct,
    ],
  );

  const [showScenarioDetails, hideScenarioDetails] = useModal(() => (
    previewScenario ? (
      <ScenarioDetails
        scenario={previewScenario.id}
        referenceDate={(previewScenario.referenceDate as Misc.DateTimezone | null)?.date}
        referenceDateOrigin={previewScenario.referenceDateOrigin}
        onClose={hideScenarioDetails}
      />
    ) : null
  ), [previewScenario]);

  const showScenarioModal = useCallback((scenario: ScenarioSummary) => {
    setPreviewScenario(scenario);
    showScenarioDetails();
  }, [showScenarioDetails]);

  const columns = useMemo<Column<ClientSummary>[]>(
    () => CustomersColumns(ct, currency, handleActionDone, handleActionError, showScenarioModal),
    [ct, currency, handleActionDone, handleActionError, showScenarioModal],
  );

  const filtersList = useMemo<Misc.FilterDeclarationItem[]>(
    () => {
      return CustomersFilters(ct);
    },
    [ct],
  );

  const handleSwitchList = useCallback((listing: string) => {
    if (!listing.startsWith('customers')) {
      history.push(`/${listing}?${getFiltersQueryString(filters)}`);
    }
  }, [filters, history]);

  const handleStatusSelector = useCallback((newStatus: 'ACTIVE' | 'ALL') => {
    addOrUpdateFilters([{ name: 'active', value: newStatus === 'ACTIVE' ? '1' : '0' }]);
  }, [addOrUpdateFilters]);

  const StatusSelectValue = useMemo(() => (
    filters?.find(({ name, value }) => name === 'active' && value === '1') ? 'ACTIVE' : 'ALL'
  ), [filters]);

  const handleAssignedManager = useCallback(() => {
    addOrUpdateFilters([{ name: 'assigned_user', value: user!.email }]);
  }, [addOrUpdateFilters, user]);

  const switchListing = (
    modalType ? null : (
      <LinkSwitch
        valueLabels={[
          { value: 'customers', label: ct('common:customers') },
          { value: 'contacts', label: ct('common:contacts') },
        ]}
        defaultValue="customers"
        onChange={handleSwitchList}
      />
    )
  );

  const totalResults = useMemo(() => (
    filters.length > 0 ? serverPagination?.totalRecords : null
  ), [filters, serverPagination]);

  return (
    <>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
      <PageList
        className="Customers"
        count={totalResults}
        isFetching={isFetching}
        title={ct('common:customers')}
        actions={switchListing}
      >
        <DataTable<ClientSummary>
          columns={columns}
          defaultSortBy={fetchOptions.sort}
          filtersList={filtersList}
          filtersTitle={ct('clients:modal-filter-title')}
          filtersDescription={ct('clients:modal-filter-description')}
          filtering={filters}
          key={actionsCount}
          statusSelector={(
            <StatusSelect
              status={StatusSelectValue}
              onSetFilters={handleStatusSelector}
              refreshTick={actionsCount}
            />
          )}
          data={data}
          serverPagination={serverPagination}
          fetchData={setFetchOptions}
          isLoading={isLoading}
          onRemoveFilter={removeFilter}
          onChangeFilters={addOrUpdateFilters}
          error={error}
          noDataFoundMessage={ct('clients:no-data-was-found')}
          withNoDataDrawing
          bulkGender="m"
          withActions={
            rights !== null && checkIsAllowed(false, isExternallyMonitored, isSuperAdmin)
          }
          bulkActions={(
            <BulkActions
              filters={filtering}
              pageSize={fetchOptions.pageSize}
              totalRecords={serverPagination?.totalRecords}
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          )}
          extraActions={(
            <Button variant="link" onClick={handleAssignedManager}>
              {ct('clients:my-clients')}
            </Button>
          )}
          onExport={handleOnExport}
        />
        {modalType === 'edit' && (
          <CustomerEditPage
            onDone={handleActionDone}
            finallyRedirectTo="/customers"
          />
        )}
      </PageList>
    </>
  );
};

export default observer(Customers);
