import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import { EAVType } from 'types/models';
import type { EavConstructor } from 'types/models';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import getEavTypeLabel from 'utils/getEavTypeLabel';

type Props = {
  errors?: ValidationErrors | null,
  defaultData?: EavConstructor,
};

const FormatForm = (props: Props): JSX.Element => {
  const { errors, defaultData } = props;
  const { t } = useTranslation();

  return (
    <FormGroup
      className="FormatForm"
      label={t('common:format')}
      mandatory
      error={errors?.format}
    >
      <FormSelect
        name="format"
        selectOptions={[...Object.values(EAVType).map((eavFormat) => (
          { value: eavFormat, label: t(getEavTypeLabel(eavFormat)) }
        ))]}
        value={defaultData?.type}
        defaultValue={defaultData?.type}
      />
    </FormGroup>
  );
};

export default FormatForm;
