import { useCallback } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import InputModal from 'components/InputModal';
import useContextualTranslation from 'hooks/useContextualTranslation';
import authStore from 'stores/Auth';
import type { Note } from 'types/models';
import apiNotes from 'api/notes';
import apiClients from 'api/clients';
import apiUsers from 'api/users';
import apiDebits from 'api/debits';

type Props = {
  paymentRef: string,
  paymentId: number,
  clientId: number,
  onClose(): void,
  onDone(title: string, messsage: string): void,
};

const DebitSideNotesCreate = (props: Props): JSX.Element => {
  const { paymentRef, paymentId, clientId, onClose, onDone } = props;
  const { type } = organizationStore.currentOrganization!;
  const { ct, t } = useContextualTranslation(type);
  const { post, error, isLoading } = useApiRequest();
  const { user } = authStore;

  const handleSubmit = useCallback(async (inputModalValue: Record<string, string>) => {
    const result = await post<Note>(apiNotes.createUrl, {
      client: apiClients.resourceUrl(clientId),
      debit: apiDebits.resourceUrl(paymentId),
      user: apiUsers.resourceUrl(user!.id),
      ...inputModalValue,
      type: 'public',
    });

    if (!result) {
      return;
    }

    onDone(
      t('notes:toast.created'),
      ct('notes:toast.created-invoice', { reference: paymentRef }),
    );
    onClose();
  }, [post, user, clientId, paymentId, onDone, t, ct, paymentRef, onClose]);

  return (
    <InputModal
      isShow
      title={t('debits:add-note')}
      help={ct('debits:add-note-for-ref', { reference: paymentRef })}
      type="textarea"
      name="comment"
      isLoading={isLoading}
      error={error}
      onSubmit={handleSubmit}
      onCancel={onClose}
    />
  );
};

export default DebitSideNotesCreate;
